import React from 'react'
import { Row, Col, Image } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
const CardsSkeleton = () => {
    return (
        <>
            <div className="category-cards for-des">
                <Row>
                    {Array(3).fill(0).map((item, idx) => (<Col key={idx} md={4} lg={4}>
                        <div className="category-card skeleton-cards">
                            <div className="card" >
                                <div>
                                    <Skeleton width={72} height={72} circle />
                                </div>
                                <div className="card-item">
                                    <span className="default-regular-h4 cs-neutral-90" >  <Skeleton width={63} /></span>
                                    <h3 className="default-semi-bold-h2 cs-neutral-100" >
                                        <Skeleton width={158} />
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </Col>))}
                </Row>
            </div>

            <div className="category-cards for-mob">
                <Row>
                    {Array(3).fill(0).map((item, idx) => (<Col key={idx} md={4} lg={4}>
                        <div className="category-card skeleton-cards">
                            <div className="card" >
                                <div>
                                    <Skeleton width={50} height={50} circle />
                                </div>
                                <div className="card-item">
                                    <span className="default-regular-h4 cs-neutral-90" >  <Skeleton width={55} /></span>
                                    <h3 className="default-semi-bold-h2 cs-neutral-100" >
                                        <Skeleton width={100} />
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </Col>))}
                </Row>
            </div>
        </>
    )
}

export default CardsSkeleton