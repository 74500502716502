import React from "react";
import { Link } from "react-router-dom";
import CapsyncIcon from "./CapsyncIcon";

const CapsyncBreadcrumb = ({
  mainTitle,
  subTitle,
  mainUrl,
  multiLevelFlag,
  mainLevelArr,
}) => {
  return (
    <>
      {mainTitle && (
        <>
          {multiLevelFlag === "true" ? (
            mainLevelArr &&
            mainLevelArr.map((items) => {
              return (
                <>
                  <Link
                    key={items}
                    to={items.url}
                    className="default-light-body-text-s cs-neutral-80"
                  >
                    {items.title}
                  </Link>
                  <span className="cs-neutral-70">
                    <CapsyncIcon title="chevron-right-outlined" size="12" />
                  </span>
                </>
              );
            })
          ) : (
            <>
              <Link
                to={mainUrl}
                className="default-light-body-text-s cs-neutral-80"
              >
                {mainTitle}
              </Link>
              <span className="cs-neutral-70">
                <CapsyncIcon title="chevron-right-outlined" size="12" />
              </span>
            </>
          )}

          <span className="default-light-body-text-s cs-neutral-100">
            {subTitle}
          </span>
        </>
      )}
    </>
  );
};

export default CapsyncBreadcrumb;
