import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";


const BackToSignin = (props) => {
  const { isNewUser } = props;
  const navigate = useNavigate();

  function redirectToSignin() {
    if (isNewUser) {
      navigate("/signup");
      return;
    }
    navigate("/");
    // localStorage.removeItem("user");
  }
  return (
    <>
      <span className="back-to-sign-in">
        <Button
          variant="link"
          onClick={redirectToSignin}
          className="cs-btn-tertiary lg-btn default-regular-h5"
        >
          {isNewUser ? 'Click here to Sign Up' : 'Back to sign in'}
        </Button>
      </span>
    </>
  );
};

export default BackToSignin;
