import React, { Fragment } from "react";
import { Table } from "react-bootstrap";
import AccountDetails from "./AccountDetails";

const PlaidDataBlock = ({ newAcc, duplicateAcc, tableHead }) => {
  return (
    <>
      {(newAcc.length > 0 || duplicateAcc.length > 0) && (
        <Table responsive className="cs-table">
          <thead className="cs-thead">
            <tr>
              <th
                className="default-medium-sub-heading-xs cs-neutral-90"
                colspan="3"
              >
                {tableHead}
              </th>
            </tr>
          </thead>
          <tbody className="cs-tbody default-regular-sub-heading-xs">
            {newAcc.map(([account, balance], index) => {
              return (
                <Fragment key={index}>
                  <AccountDetails
                    account={account}
                    balance={balance}
                    type="new"
                  />
                </Fragment>
              );
            })}
            {duplicateAcc.map(([account, balance], index) => {
              return (
                <Fragment key={index}>
                  <AccountDetails
                    account={account}
                    balance={balance}
                    type="duplicate"
                  />
                </Fragment>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default PlaidDataBlock;
