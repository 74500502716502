import React from 'react'
import Skeleton from 'react-loading-skeleton'
import CardsSkeleton from '../../individual/dashboard/individual_dashboard/CardsSkeleton';
import { Row, Col } from "react-bootstrap";
import SkeletonCategoryTable from '../../individual/assets/asset_dashboard/SkeletonCategoryTable';


const SkeletonDashboard = () => {
    return (
        <div className="cs-section-topbar">
            <div className="cs-title wm-skeleton-margin">
                <h1 className="default-semi-bold-h1 cs-neutral-100"><Skeleton width={200} /></h1>
            </div>
            <div className="category-cards for-des">
                <Row>
                    {Array(3).fill(0).map((item, idx) => (<Col key={idx} md={4} lg={4}>
                        <div className="category-card skeleton-cards">
                            <div className="card" >
                                <div>
                                    <Skeleton width={72} height={72} circle />
                                </div>
                                <div className="card-item">
                                    <span className="default-regular-h4 cs-neutral-90" >  <Skeleton width={158} /></span>
                                    <h3 className="default-semi-bold-h2 cs-neutral-100" >
                                        <Skeleton width={63} />
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </Col>))}
                </Row>
            </div>

            <div className="category-cards for-mob">
                <Row>
                    {Array(3).fill(0).map((item, idx) => (<Col key={idx} md={4} lg={4}>
                        <div className="category-card skeleton-cards">
                            <div className="card" >
                                <div>
                                    <Skeleton width={50} height={50} circle />
                                </div>
                                <div className="card-item">
                                    <span className="default-regular-h4 cs-neutral-90" >  <Skeleton width={55} /></span>
                                    <h3 className="default-semi-bold-h2 cs-neutral-100" >
                                        <Skeleton width={100} />
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </Col>))}
                </Row>
            </div>

            <div className='skeleton-manage-container wm-skeleton-tabs-container for-des'>
                <div className="skeleton-tabs-container">
                    <h1 className="skeleton-tab-1"><Skeleton width={200} /></h1>
                    <h1 className="skeleton-tab-2"><Skeleton width={200} /></h1>
                </div>
                <div><Skeleton width={100} /></div>
            </div>
            <div className='skeleton-manage-container wm-skeleton-tabs-container financial-tab-skeleton for-mob'>
                <div className="skeleton-tabs-container">
                    <h1 className="skeleton-tab-1"><Skeleton width={125} height={30} /></h1>
                    <h1 className="skeleton-tab-2"><Skeleton width={125} height={30} /></h1>
                </div>
                <div className='skeleton-recently'><Skeleton width={100} /></div>
            </div>
            <div className='skeleton-table'>
                <SkeletonCategoryTable tableData={[1, 2, 3]} />
            </div>
        </div>
    )
}

export default SkeletonDashboard