import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LogoImage from "../logoImage";
import LeftPart from "./LeftPart";
import BackToSignin from "./BackToSignin";
import CapsyncIcon from "../../commonComponent/CapsyncIcon";

const SignInMFAuthSetup = () => {
  const navigate = useNavigate();

  const mfOptionPhoneSetup = () => {
    navigate("/signup-mf-auth-phone-setup");
  };

  const mfOptionGoogleSetup = () => {
    navigate("/signup-mf-auth-google-setup");
  };

  return (
    <section className="auth-section">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={6}>
            <LeftPart
              page="mfa-phone-pin"
              img="auth-image.svg"
              title="Your New Immersive Digital Finance Hub."
              text="Sync your real-time digital Personal Financial Statement with CapSync."
            />
          </Col>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <div className="auth-right-section">
                  <LogoImage />
                  <div className="cs-auth-form">
                    <h1 className="default-semi-bold-h1 cs-neutral-100">
                      Setup multi-factor authentication
                    </h1>
                    <hr className="head-underline" />
                    <p className="cs-neutral-80 default-regular-h5 ">
                      Choose your preferred authentication method
                    </p>
                    <div>
                      <div
                        className="auth-mfa-item"
                        onClick={() => mfOptionPhoneSetup()}
                      >
                        <div className="auth-mfa-inner">
                          <div className="auth-mfa-options cs-radio-btn">
                            <input
                              type="radio"
                              name="mfOption-phone"
                              id="mobile"
                              value="2"
                              className="radio-button"
                            />
                          </div>
                          <div className="auth-mfa-options">
                            <h4 className="cs-neutral-100 default-regular-h4">
                              Mobile verification
                            </h4>
                            <span className="default-regular-h6 cs-neutral-60">
                              Get a code sent to your phone via SMS
                            </span>
                          </div>
                        </div>
                        <div className="mfa-options">
                          <CapsyncIcon title="mobile-outlined" size="22" />
                        </div>
                      </div>
                      <div
                        className="auth-mfa-item"
                        onClick={() => mfOptionGoogleSetup()}
                      >
                        <div className="auth-mfa-inner">
                          <div className="auth-mfa-options cs-radio-btn">
                            <input
                              type="radio"
                              name="mfOption-google"
                              id="auth"
                              value="3"
                              className="radio-button"
                            />
                          </div>
                          <div className="auth-mfa-options">
                            <h4 className="cs-neutral-100 default-regular-h4">
                              Authentication app
                            </h4>
                            <span className="default-regular-h6 cs-neutral-60">
                              Connect your authenticator app
                            </span>
                          </div>
                        </div>
                        <div className="mfa-options">
                          <CapsyncIcon title="qr-code-outlined" size="22" />
                        </div>
                      </div>
                    </div>
                    <BackToSignin />
                  </div>
                  {/* {message && (
                  <div className="cap-error text-center cap-messageArea">
                    <BsFillInfoCircleFill className="iconAlert" />
                    {message}
                  </div>
                )} */}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SignInMFAuthSetup;
