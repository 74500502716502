import React, { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import { useUploadProgress } from "../../../slices/fileManagement.slice";

const UploadStatus = () => {
  const [hideProgress, setHideProgress] = useState(false);
  const uploadProgress = useUploadProgress();
  if (!uploadProgress || !uploadProgress.data || !uploadProgress.data.length) {
    return null;
  }
  return (
    <div className={`file-upload-status ${hideProgress && "hide-status"}`}>
      <h4
        className="default-regular-h4 cs-neutral-100"
        onClick={() => setHideProgress(!hideProgress)}
      >
        {`Uploading ${uploadProgress.totalFilesCount || 0} items`}
        <span className="cs-arrow cs-neutral-80">
          <CapsyncIcon title="chevron-down-outlined" size="16" />
        </span>
      </h4>
      <div className="file-upload-row">
        {uploadProgress.data.map((val, index) => {
          return (
            <div className="cs-storage" key={index}>
              <div className="storage-info">
                <span className="cs-neutral-100 default-regular-sub-heading-xxs">
                  {val.files[0]}
                </span>
                <span className="cs-primary default-regular-sub-heading-xxs">
                  {`${val.progress}%`}
                </span>
              </div>
              <div className="cs-progress-bar">
                <ProgressBar now={val.progress} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UploadStatus;
