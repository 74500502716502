import React from "react";
import CapsyncToolTip from "../../../../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import "./CommonMethodBlocks.css"

const LegalNameBlock = () => {
  return (
    <div className="accreditation-common-tooltip">
      <p>
        <span className="default-regular-body-text-l cs-neutral-70"> Please enter or confirm the legal name of the person or entity being verified. The name entered here will be referenced in the verification letter and generally should match how you intend to invest.</span> <br />
        <span className="default-regular-body-text-l cs-danger"> Do not enter your own personal name if you are investing as a joint couple, trust, or entity. WARNING: Under certain cases, there will be a $35 charge to change the legal name post-review. </span>
      </p>

      <CapsyncToolTip
        Child={
          <u className="default-regular-body-text-l cs-neutral-90">See here for examples.</u>
        }
        placement={"right"}
        width="468px"
        message={
          <div>
            If investing as:
            <ul className="text-start">
              <li>
                An individual, enter your first and last name (e.g. John Doe).
              </li>
              <li>
                A married couple, enter the first and last names of each person
                (e.g. John Doe &amp; Jane Doe).
              </li>
              <li>
                A trust, enter the full legal name of the trust (e.g. Smith
                Living Trust or John Doe, Trustee for Doe Living Trust).
              </li>
              <li>
                A company, enter the full legal name of the company name (e.g.
                ABC Company, LLC or XYZ Company, Inc.)
              </li>
              <li>
                An IRA that is an LLC, enter the full legal name of the LLC.
              </li>
              <li>
                An IRA that is not a LLC, enter the full legal name of the IRA.
              </li>
            </ul>
          </div>
        }
        type="text"
      />
    </div>
  );
};

export default LegalNameBlock;
