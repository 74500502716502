import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";

import "../../settings/CommonHeader/common_setting.css";
import { userPermissionStandard } from "../../../../config/authUser";

const CommonSettingHeading = (props) => {
  const { user } = useSelector((state) => state.auth);
  const pfsAccessId = window.localStorage.getItem("pfs_access_id");
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const isMySettings = pathname?.split("/")?.[2] === "my-settings";

  function goToTab(params) {
    navigate(params);
  }

  // if (props.editAccessUser === false) {
  //   return <div className="settings-tab-btn cs-tabs">
  //     <Button
  //       onClick={() => goToTab("/individual/audit-logs")}
  //       className={
  //         window.location.pathname === "/individual/audit-logs"
  //           ? "cs-stroke-bottom-tab default-regular-h5 cs-stroke-bottom-active"
  //           : "cs-stroke-bottom-tab default-regular-h5"
  //       }
  //       id='audit-logs'
  //     >
  //       Audit Logs
  //     </Button>
  //   </div>
  // }

  return (
    <>
      <div className="settings-tab-btn cs-tabs">
        <Button
          onClick={() =>
            goToTab(
              isMySettings
                ? "/individual/my-settings/account-details"
                : "/individual/account-details"
            )
          }
          className={
            window.location.pathname ===
            (isMySettings
              ? "/individual/my-settings/account-details"
              : "/individual/account-details")
              ? "cs-stroke-bottom-tab default-regular-h5 cs-stroke-bottom-active"
              : "cs-stroke-bottom-tab default-regular-h5"
          }
          id="account-details"
        >
          Account Details
        </Button>
        {/* {(pfsAccessId === null || isMySettings) && (
          <Button
            onClick={() =>
              goToTab(
                isMySettings
                  ? "/individual/my-settings/file-encryption"
                  : "/individual/file-encryption"
              )
            }
            className={
              window.location.pathname ===
              (isMySettings
                ? "/individual/my-settings/file-encryption"
                : "/individual/file-encryption")
                ? "cs-stroke-bottom-tab default-regular-h5 cs-stroke-bottom-active"
                : "cs-stroke-bottom-tab default-regular-h5"
            }
            id="file-encryption"
          >
            File Encryption
          </Button>
        )} */}
        {(pfsAccessId === null || isMySettings) && (
          <Button
            onClick={() =>
              goToTab(
                isMySettings
                  ? "/individual/my-settings/mfa-setup"
                  : "/individual/mfa-setup"
              )
            }
            className={
              window.location.pathname ===
              (isMySettings
                ? "/individual/my-settings/mfa-setup"
                : "/individual/mfa-setup")
                ? "cs-stroke-bottom-tab default-regular-h5 cs-stroke-bottom-active"
                : "cs-stroke-bottom-tab default-regular-h5"
            }
            id="mfa-setup"
          >
            MFA Setup
          </Button>
        )}
        {user.user.is_beta_user === false &&
          props.editAccessUser &&
          !isMySettings && (
            <Button
              onClick={() => goToTab("/individual/billing-subscription")}
              className={
                window.location.pathname === "/individual/billing-subscription"
                  ? "cs-stroke-bottom-tab default-regular-h5 cs-stroke-bottom-active"
                  : "cs-stroke-bottom-tab default-regular-h5"
              }
              id="billing-subscription"
            >
              Billing & Subscriptions
            </Button>
          )}
        <Button
          onClick={() =>
            goToTab(
              isMySettings
                ? "/individual/my-settings/audit-logs"
                : "/individual/audit-logs"
            )
          }
          className={
            window.location.pathname ===
            (isMySettings
              ? "/individual/my-settings/audit-logs"
              : "/individual/audit-logs")
              ? "cs-stroke-bottom-tab default-regular-h5 cs-stroke-bottom-active"
              : "cs-stroke-bottom-tab default-regular-h5"
          }
          id="audit-logs"
        >
          Audit Logs
        </Button>
      </div>
    </>
  );
};

export default CommonSettingHeading;
