import React, { useEffect } from "react";
import { socket } from "../../../../config/Socket";
import Avatar from "../../../../commonComponent/Avatar";
import { capitalizeName, nameInitials } from "../../../../config/finance";
import { Image } from "react-bootstrap";

const MultipleUserDropDown = ({
  itr,
  handleResponseFromIndividualDecision,
  i,
  srcUrl,
}) => {
  const returnSockets = () => {
    socket.off("get_all_details_emit", handleResponseFromIndividualDecision);
    socket.off(
      "response_from_individual_decision_to_main_user",
      handleResponseFromIndividualDecision
    );
    socket.off(
      "response_from_individual_decision_to_other_wealth_managers",
      handleResponseFromIndividualDecision
    );
    socket.off(
      "response_from_individual_decision_to_other_individuals",
      handleResponseFromIndividualDecision
    );
  };

  useEffect(() => returnSockets(), []);
  return (
    <div className="multiple-user-list">
      <div className="active-user-profile">
        <div className="multiple-user-item">
          <div className="profile-image">
            {/* <span className="user-text default-regular-h6 cs-neutral-80"> */}
            {itr.profileImageUrl == "avtar-6" || itr.profileImageUrl == null ? (
              <span className="user-text default-regular-h6 cs-neutral-80">
                {nameInitials(itr.firstName, itr.lastName)}
              </span>
            ) : itr.profileImageUrl == "avtar-1" ? (
              <Avatar title="avtar-1" size="38" />
            ) : itr.profileImageUrl == "avtar-2" ? (
              <Avatar title="avtar-2" size="38" />
            ) : itr.profileImageUrl == "avtar-3" ? (
              <Avatar title="avtar-3" size="38" />
            ) : itr.profileImageUrl == "avtar-4" ? (
              <Avatar title="avtar-4" size="38" />
            ) : itr.profileImageUrl == "avtar-5" ? (
              <Avatar title="avtar-5" size="38" />
            ) : (
              <Image
                src={srcUrl[i]}
                alt="Profile"
                className="avtar-6"
                width={40}
                height={40}
              />
            )}
            {/* </span> */}
          </div>
          <span className="active-dot cs-success"></span>
          <div className="profile-info">
            <span className="default-regular-sub-heading-xs cs-neutral-90">
              {capitalizeName(itr.firstName) +
                " " +
                capitalizeName(itr.lastName)}
            </span>
            <span className="default-regular-body-text-xs cs-neutral-60">
              {itr.professional_role == 3
                ? itr.businessName
                : itr.professional_role == 2
                ? "Financial Advisor"
                : "Individual"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleUserDropDown;
