import React from 'react'
import { Button, Image, Modal } from 'react-bootstrap'
import errorValidation from "../../../../../../assets/images/other/error-validation.svg"

const WarningModal_VI = ({ showWarning, onClose, onButtonClick }) => {
  return (
    <Modal
      show={showWarning}
      onHide={!showWarning}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          Warning
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delete-modal-content">
          <Image
            src={errorValidation}
            alt="Error Validation"
            width={100}
            height={100}
          />
          <p className="default-light-body-text-m cs-neutral-90">
            The Special Situation verification method is rarely applicable as it either requires that either the individual be notably wealthy, such as being on the Forbes Wealthiest individuals list, or for the company being verified to be a publicly-traded company. If you believe you meet these criteria, please contact accreditation@capsync.com for guidance, if not, then kindly please select another verification method.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="cs-modal-btn cs-center-btn">
          <Button className="cs-btn-secondary md-btn default-regular-h5" onClick={onClose}>
            Change Method</Button>
          <Button className="cs-btn-primary md-btn default-regular-h5" onClick={onButtonClick}>
            Select this Method</Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default WarningModal_VI