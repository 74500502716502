/* eslint-disable */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import AuthService from "../services/auth.service";
import { localDataRemove } from "../config/finance";
import { headerAddToken } from "../util/common";
import API from "../util/api";
import { useNavigate } from "react-router-dom";
import pfs_access_service from "../services/pfs_access.service";
import { boolean } from "yup";
import { getLastSyncDate } from "../config/plaidFunctions";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const user = window.localStorage.getItem("user");

export const signup = createAsyncThunk(
  "auth/signup",
  async (values, thunkAPI) => {
    try {
      if (values.roleId === 2) {
        const licenseDetail = await AuthService.verifyLicenseNumber(values);
        if (licenseDetail.data.res === 1 && licenseDetail.data.data != null) {
          const response = await AuthService.signup(values);
          if (response.data.data) {
            thunkAPI.dispatch(
              setMessage(response.data.data.emailVerificationCode)
            );
            return response.data;
          } else {
            return response.data;
          }
        } else {
          throw { licenseissue: "invalid" };
        }
      } else {
        const response = await AuthService.signup(values);
        if (response.data.data) {
          thunkAPI.dispatch(
            setMessage(response.data.data.emailVerificationCode)
          );
          return response.data;
        } else {
          return response.data;
        }
      }
    } catch (error) {
      const message = error.response && error.response.data.message;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getWmDataByLicenseNumber = createAsyncThunk(
  "auth/getWmDataByLicenseNumber",
  async (values, thunkAPI) => {
    try {
      const licenseDetail = await AuthService.verifyLicenseNumber(values);
      if (licenseDetail.data.res === 1 && licenseDetail.data.data != null) {
        return licenseDetail.data.data;
      } else {
        throw { licenseissue: "invalid" };
      }
    } catch (error) {
      if (error.licenseissue !== undefined || error.licenseissue !== null) {
        return { licenseissue: "invalid" };
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const forgotpassword = createAsyncThunk(
  "auth/forgotpassword",
  async ({ email }, thunkAPI) => {
    try {
      const response = await AuthService.forgotpassword(email);
      return response.data;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resetpassword = createAsyncThunk(
  "auth/resetpassword",
  async (formValue, thunkAPI) => {
    try {
      const response = await AuthService.resetpassword(formValue);
      return response.data;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const signin = createAsyncThunk(
  "auth/signin",
  async ({ email, password, device_info }, thunkAPI) => {
    try {
      const data = await AuthService.signin(email, password, device_info);
      if (data.user) {
        return { user: data };
      } else {
        return data;
      }
    } catch (error) {
      const message =
        error.response && error.response.data && error.response.data.message;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const mfaEmailAuth = createAsyncThunk(
  "auth/mfaEmailAuth",
  async ({ email }, thunkAPI) => {
    try {
      const response = await AuthService.mfaEmailAuth(email);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const mfaEmailAuthPin = createAsyncThunk(
  "auth/mfaEmailAuthPin",
  async ({ email, id, MFAEmailOtp }, thunkAPI) => {
    try {
      const response = await AuthService.mfaEmailAuthPin(
        email,
        id,
        MFAEmailOtp
      );
      return response.data;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const mfaGoogleAuth = createAsyncThunk(
  "auth/mfaGoogleAuth",
  async ({ email }, thunkAPI) => {
    try {
      const response = await AuthService.mfaGoogleAuth(email);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
//wealth manager
export const wmmfaGoogleAuth = createAsyncThunk(
  "auth/mfaGoogleAuth",
  async ({ email }, thunkAPI) => {
    try {
      const response = await AuthService.mfaGoogleAuth(email);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const mfaGoogleAuthSetup = createAsyncThunk(
  "auth/mfaGoogleAuthSetup",
  async ({ email }, thunkAPI) => {
    try {
      const response = await AuthService.mfaGoogleAuthSetup(email);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
//wealthmanager'

export const wmmfaGoogleAuthSetup = createAsyncThunk(
  "auth/mfaGoogleAuthSetup",
  async ({ email }, thunkAPI) => {
    try {
      const response = await AuthService.mfaGoogleAuthSetup(email);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const mfaGoogleAuthFirstPin = createAsyncThunk(
  "auth/mfaGoogleAuthFirstPin",
  async ({ email, id, MFAGoogleOtp }, thunkAPI) => {
    try {
      const response = await AuthService.mfaGoogleAuthFirstPin(
        email,
        id,
        MFAGoogleOtp
      );
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message =
          error.response && error.response.data && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const mfaGoogleAuthPin = createAsyncThunk(
  "auth/mfaGoogleAuthPin",
  async ({ email, id, MFAGoogleOtp }, thunkAPI) => {
    try {
      const response = await AuthService.mfaGoogleAuthPin(
        email,
        id,
        MFAGoogleOtp
      );
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message =
          error.response && error.response.data && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
//wealth manager
export const wmmfaGoogleAuthPin = createAsyncThunk(
  "auth/mfaGoogleAuthPin",
  async ({ email, id, MFAGoogleOtp }, thunkAPI) => {
    try {
      const response = await AuthService.mfaGoogleAuthPin(
        email,
        id,
        MFAGoogleOtp
      );
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const mfaPhoneAuth = createAsyncThunk(
  "auth/mfaPhoneAuth",
  async (values, thunkAPI) => {
    try {
      const response = await AuthService.mfaPhoneAuth(values);

      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const verify_phone_otp_send = createAsyncThunk(
  "auth/verify_phone_otp_send",
  async (values, thunkAPI) => {
    try {
      const response = await AuthService.verify_phone_otp_send(values);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const update_password = createAsyncThunk(
  "auth/update_password",
  async (values, thunkAPI) => {
    try {
      const response = await AuthService.update_password(values);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const mfaPhoneAuthSetup = createAsyncThunk(
  "auth/mfaPhoneAuthSetup",
  async ({ id, email, phoneNo }, thunkAPI) => {
    try {
      const response = await AuthService.mfaPhoneAuthSetup(id, email, phoneNo);
      //thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const mfaPhoneAuthPin = createAsyncThunk(
  "auth/mfaPhoneAuthPin",
  async ({ email, id, MFAPhoneOtp }, thunkAPI) => {
    try {
      const response = await AuthService.mfaPhoneAuthPin(
        email,
        id,
        MFAPhoneOtp
      );
      return response.data;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const mfaPhoneAuthFirstPin = createAsyncThunk(
  "auth/mfaPhoneAuthFirstPin",
  async ({ email, id, MFAPhoneOtp }, thunkAPI) => {
    try {
      const response = await AuthService.mfaPhoneAuthFirstPin(
        email,
        id,
        MFAPhoneOtp
      );
      return response.data;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : error.message;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

//wealth-manager
export const wmmfaPhoneAuthPin = createAsyncThunk(
  "wealthmanager/wmmfaPhoneAuthPin",
  async ({ id, MFAPhoneOtp }, thunkAPI) => {
    try {
      const response = await AuthService.wmmfaPhoneAuthPin(id, MFAPhoneOtp);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        //const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(error.response.data));
        //return error.response.data;
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const sendVerificationEmail = createAsyncThunk(
  "auth/sendVerificationEmail",
  async ({ emailVerificationCode }, thunkAPI) => {
    try {
      const response = await AuthService.sendVerificationEmail(
        emailVerificationCode
      );
      thunkAPI.dispatch(setMessage(response.data));
      return response.data;
    } catch (error) {
      const message = error.response && error.response.data;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const wmsendVerificationEmail = createAsyncThunk(
  "auth/sendVerificationEmail",
  async ({ emailVerificationCode }, thunkAPI) => {
    try {
      const response = await AuthService.sendVerificationEmail(
        emailVerificationCode
      );
      thunkAPI.dispatch(setMessage(response.data));
      return response.data;
    } catch (error) {
      const message = error.response && error.response.data;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const confirmEmailVerification = createAsyncThunk(
  "auth/confirmEmailVerification",
  async ({ emailVerificationCode }, thunkAPI) => {
    try {
      const response = await AuthService.confirmEmailVerification(
        emailVerificationCode
      );
      thunkAPI.dispatch(setMessage(response.data));
      return response.data;
    } catch (error) {
      const message = error.response && error.response.data;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const confirmEmailOtp = createAsyncThunk(
  "auth/confirmemail/otp",
  async (values, thunkAPI) => {
    try {
      const response = await AuthService.confirmemailOtp(values);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data;
    } catch (error) {
      const message = error.response && error.response.data;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const signout = createAsyncThunk(
  "auth/signout",
  async (values, thunkAPI) => {
    try {
      const response = await AuthService.signout(values);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data;
    } catch (error) {
      const message = error.response && error.response.data;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const userInitialModalSetup = createAsyncThunk(
  "/user/initial_modal_setup",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        `/user/initial_modal_setup`,
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));

      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const userFindRecord = createAsyncThunk(
  "/user/find/",
  async ({ id }, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.get(`/user/find/${id}`, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const loginUserFindRecord = createAsyncThunk(
  "login/user_record/find/",
  async ({ id }, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.get(`/user/find/${id}`, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const userFindRecordForRouting = createAsyncThunk(
  "/user/find/",
  async ({ id }, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.get(`/user/find/${id}`, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_managers_findone = createAsyncThunk(
  "/financial-advisor/single",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post("/wealthmanager/single", values, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const access_indiPFS_fromWealth = createAsyncThunk(
  "/invitation/fetch_user",
  async (values, thunkAPI) => {
    try {
      const response = await pfs_access_service.access_indiPFS_fromWealth(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const authUserUpdated = createAsyncThunk(
  "/authuser",
  async (authData, thunkAPI) => {
    try {
      let {
        user,
        initialSetup,
        isSubscribe,
        MFAPhone,
        MFAGoogle,
        stripePriceId,
        addressobj,
      } = authData;

      return authData;
    } catch (error) {
      console.log(error);
    }
  }
);

export const userUpdateRecord = createAsyncThunk(
  "/user/update",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(`/user/update`, values, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return { ...response.data, values };
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const authUserPFSData = createAsyncThunk(
  "/pfs/update",
  async (values, thunkAPI) => {
    try {
      return values;
    } catch (error) {}
  }
);

export const checkPhoneNumber = createAsyncThunk(
  "user/check_phone_number",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        `user/check_phone_number`,
        values,
        header
      );

      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const userLastSyncDate = createAsyncThunk(
  "/user/plaidLastSyncDate/",
  async ({ userId }, thunkAPI) => {
    try {
      const response = await getLastSyncDate(userId);
      thunkAPI.dispatch(setMessage(response.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

const initialState = {
  user: JSON.parse(user),
  authUser: JSON.parse(user),
  pfsAccessDetails: {},
  pfsAccessUser: {
    id: "",
    role: "",
    companyId: "",
    companyName: "",
  },
  loginSubscriptionFlag: false,
  lastSyncDate: null,
  subscribePermission: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRemoveAuthUser(state) {
      state.authUser = {};
      state.user = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userUpdateRecord.fulfilled, (state, action) => {
        state.user.token = action.payload.data;
        if (
          state.authUser &&
          state.authUser.user &&
          state.user.user.id === state.authUser.user.id
        ) {
          state.authUser.token = action.payload.data;
          state.authUser.user = {
            ...state.authUser.user,
            ...action.payload.values,
          };
        } else if (
          state.authUser &&
          state.authUser.id &&
          state.user.user.id === state.authUser.id
        ) {
          state.authUser = { ...state.authUser, ...action.payload.values };
        }
      })
      .addCase(wealth_managers_findone.fulfilled, (state, action) => {
        state.user.user = action.payload;
        state.user.user_role.role = action.payload.professional_role;
        state.authUser = action.payload;
        state.authUser.role = action.payload.professional_role;
      })
      .addCase(userFindRecord.fulfilled, (state, action) => {
        state.user.user = action.payload;
        if (!state.authUser) {
          state.authUser = action.payload;
          state.authUser.role = 1;
        }
        state.user.user_role.role = 1;
        state.subscribePermission = action.payload.permission;
      })
      .addCase(access_indiPFS_fromWealth.fulfilled, (state, action) => {
        // state.pfsAccessUser.id = action.payload.user_id;
        // state.pfsAccessUser.role = action.payload.role;
      })
      .addCase(userInitialModalSetup.fulfilled, (state, action) => {
        state.user = { ...state.user, user: action.payload.res_data.user };
        if (window.localStorage.getItem("pfs_access_id") === null) {
          state.authUser = {
            ...state.authUser,
            user: action.payload.res_data.user,
          };
          localStorage.setItem("user", JSON.stringify(state.user));
        }
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.authUser = action.payload.user;
        state.loginSubscriptionFlag = true;
      })
      .addCase(signin.rejected, (state, action) => {
        state.user = null;
      })
      .addCase(signout.fulfilled, (state, action) => {
        state.user = null;
        state.authData = null;
        state.pfsAccessDetails = {};
        state.pfsAccessUser = {
          id: "",
          role: "",
          companyId: "",
          companyName: "",
        };
        state.loginSubscriptionFlag = false;
      })
      .addCase(authUserUpdated.fulfilled, (state, action) => {
        // state.user = action.payload.user;
        if (typeof action.payload.MFAPhone === "boolean") {
          state.user = {
            ...state.user,
            user: { ...state.user.user, MFAPhone: action.payload.MFAPhone },
          };
          state.authUser = {
            ...state.user,
            user: { ...state.user.user, MFAPhone: action.payload.MFAPhone },
          };
        }
        if (typeof action.payload.MFAGoogle === "boolean") {
          state.user = {
            ...state.user,
            user: { ...state.user.user, MFAGoogle: action.payload.MFAGoogle },
          };
          state.authUser = {
            ...state.user,
            user: { ...state.user.user, MFAGoogle: action.payload.MFAGoogle },
          };
        }
        if (action.payload.isSubscribe) {
          state.user = {
            ...state.user,
            user: {
              ...state.user.user,
              is_subscribe: action.payload.isSubscribe,
            },
          };
        }
        if (action.payload.initialSetup) {
          state.user = {
            ...state.user,
            user: {
              ...state.user.user,
              initial_setup_done: action.payload.initialSetup,
            },
          };
        }
        if (
          action.payload.stripePriceId ||
          action.payload.stripePriceId === null
        ) {
          state.user = {
            ...state.user,
            user: {
              ...state.user.user,
              stripe_price_id: action.payload.stripePriceId,
            },
          };
        }
        if (action.payload.addressobj) {
          state.user = {
            ...state.user,
            user: { ...state.user.user, address: action.payload.addressobj },
          };
          state.authUser = {
            ...state.user,
            user: { ...state.user.user, address: action.payload.addressobj },
          };
        }
        if (action.payload.profileImageUrl) {
          state.user = {
            ...state.user,
            user: {
              ...state.user.user,
              profileImageUrl: action.payload.profileImageUrl,
            },
          };
        }
        if (action.payload.fileEncryption) {
          state.user = {
            ...state.user,
            user: {
              ...state.user.user,
              fileEncryption: action.payload.fileEncryption,
            },
          };
          state.authUser = {
            ...state.user,
            user: {
              ...state.user.user,
              fileEncryption: action.payload.fileEncryption,
            },
          };
        }
        window.localStorage.getItem("pfs_access_id") === null &&
          localStorage.setItem("user", JSON.stringify(state.user));
      })
      .addCase(authUserPFSData.fulfilled, (state, action) => {
        state.authUser = { ...state.authUser, pfs_access: action.payload };
      })
      .addCase(userLastSyncDate.fulfilled, (state, action) => {
        state.lastSyncDate = action.payload.data.lastSyncData;
      })
      .addCase(loginUserFindRecord.fulfilled, (state, action) => {
        state.authUser = {
          ...state.user,
          user: action.payload,
        };
      });
  },
});
export const { setRemoveAuthUser } = authSlice.actions;
const { reducer } = authSlice;
export default reducer;
const selectUser = (state) => state.auth.user;

export const useUser = () => {
  const user = useSelector(selectUser);
  return useMemo(() => user, [user]);
};
