import commonNames from "./commonNames";

const amortizationCategory = [
  {
    category: "assets",
    type: "accountnotesreceviable",
    title: "Accounts & Notes Receivable",
  },
  // {
  //   category: "liabilities",
  //   type: "accountpayable",
  //   title: "Accounts Payable"
  // },
  {
    category: "liabilities",
    type: "installmentsauto",
    title: "Installment Account (Auto)",
  },
  {
    category: "liabilities",
    type: "installmentsaccount",
    title: "Installment Account (Other)",
  },
  {
    category: "liabilities",
    type: "notespayabletobanksandothers",
    title: "Notes Payable to Banks and Others",
  },
  {
    category: "liabilities",
    type: "mortgagesonrealestate",
    title: "Mortgages on Real Estate",
  },
];

const portfolioCategory = [
  {
    category: "assets",
    type: "retirementaccount",
    title: "IRA or Other Retirement Account",
  },
  {
    category: "assets",
    type: "nonretirementbrokerage",
    title: "Non-Retirement Brokerage Accounts (Stocks & Bonds)",
  },
];

const quickBooksReportCategory = [
  {
    category: "assets",
    type: "business",
    title: "Businesses",
  },
];

const transactionCategory = [
  {
    category: "assets",
    type: "cashonhand",
    title: "Cash on Hand & In Banks",
  },
  {
    category: "assets",
    type: "savingaccount",
    title: "Savings Accounts",
  },
  {
    category: "assets",
    type: "nonretirementbrokerage",
    title: "Non-Retirement Brokerage Accounts (Stocks & Bonds)",
  },
  {
    category: "assets",
    type: "retirementaccount",
    title: "IRA or Other Retirement Account",
  },
  {
    category: "liabilities",
    type: "accountpayable",
    title: "Accounts Payable",
  },
  {
    category: "liabilities",
    type: "otherliabilities",
    title: "Other Liabilities",
  },
  {
    category: "liabilities",
    type: "mortgagesonrealestate",
    title: "Mortgages on Real Estate",
  },
  {
    category: "liabilities",
    type: "installmentsauto",
    title: "Installment Account (Auto)",
  },
  {
    category: "liabilities",
    type: "notespayabletobanksandothers",
    title: "Notes Payable to Banks and Others",
  },
];

const tableHeader = {
  assets: {
    cashonhand: [
      {
        title: commonNames.Institution,
        class: "default-medium-sub-heading-m",
        width: "420",
        pfsWidth: "300",
        tooltip: false,
        data: "institution",
        filter: false,
        change: false,
        fieldName: "institution",
        fieldType: "string",
      },
      {
        title: commonNames.BalanceDue,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        data: "balance",
        filter: false,
        change: false,
        fieldName: "balance",
        fieldType: "number",
      },
      {
        title: commonNames.Ownership_per,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "120",
        tooltip: false,
        data: "ownership",
        filter: false,
        change: false,
        fieldName: "ownership",
        fieldType: "string",
      },
      {
        title: commonNames.ShareValue,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        data: "share_value",
        filter: false,
        change: false,
        fieldName: "share_value",
        fieldType: "number",
      },
      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        pfsClass: "default-medium-sub-heading-m text-end",
        // width: "220",
        width: "220",
        pfsWidth: "126",
        tooltip: false,
        data: "date_updated",
        filter: false,
        change: false,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    savingaccount: [
      {
        title: commonNames.Institution,
        class: "default-medium-sub-heading-m",
        width: "420",
        pfsWidth: "300",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "institution",
        fieldType: "string",
      },
      {
        title: commonNames.BalanceDue,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "balance",
        fieldType: "number",
      },
      {
        title: commonNames.Ownership_per,
        class: "default-medium-sub-heading-m  text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "120",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "ownership",
        fieldType: "string",
      },
      {
        title: commonNames.ShareValue,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "share_value",
        fieldType: "number",
      },
      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    retirementaccount: [
      {
        title: commonNames.Institution,
        class: "default-medium-sub-heading-m",
        width: "300",
        pfsWidth: "300",
        tooltip: false,
        filter: false,
        fieldName: "institution",
        draggable: false,
        fieldType: "string",
      },
      {
        title: commonNames.Type,
        class: "default-medium-sub-heading-m",
        width: "180",
        pfsWidth: "200",
        tooltip: false,
        filter: false,
        fieldName: "type",
        draggable: true,
        fieldType: "string",
      },
      {
        title: commonNames.BalanceDue,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: true,
        fieldName: "balance",
        draggable: true,
        fieldType: "number",
      },
      {
        title: commonNames.LoanOnBalance,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: true,
        fieldName: "loan_on_balance",
        draggable: true,
        fieldType: "number",
      },
      {
        title: commonNames.TotalBalance,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        fieldName: "total",
        draggable: true,
        fieldType: "number",
      },
      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        width: "240",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "date_updated",
        draggable: true,
        fieldType: "date",
      },
    ],
    accountnotesreceviable: [
      {
        title: commonNames.NameOFDebtor_Property,
        class: "default-medium-sub-heading-m",
        width: "300",
        pfsWidth: "300",
        tooltip: false,
        filter: false,
        draggable: false,
        fieldName: "property_name",
        fieldType: "string",
      },
      {
        title: commonNames.LoanAmount,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "loan_amount",
        fieldType: "number",
      },
      {
        title: commonNames.Payment,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: true,
        draggable: true,
        fieldName: "payment",
        fieldType: "number",
      },
      {
        title: commonNames.Frequency,
        class: "default-medium-sub-heading-m",
        width: "150",
        pfsWidth: "104",
        tooltip: false,
        filter: true,
        draggable: true,
        fieldName: "frequency",
        fieldType: "string",
      },
      {
        title: commonNames.MortgageBalanceDue,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "balance_due",
        fieldType: "number",
      },
      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        width: "200",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    lifeinsurance: [
      {
        title: commonNames.Company,
        class: "default-medium-sub-heading-m",
        width: "340",
        pfsWidth: "300",
        tooltip: false,
        filter: false,
        change: false,
        draggable: false,
        fieldName: "company",
        fieldType: "string",
      },
      {
        title: commonNames.LoanAmount,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "300",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        change: false,
        draggable: true,
        fieldName: "loan_amount",
        fieldType: "number",
      },
      {
        title: commonNames.Beneficiary,
        class: "default-medium-sub-heading-m",
        width: "300",
        pfsWidth: "180",
        tooltip: false,
        filter: true,
        change: true,
        draggable: true,
        fieldName: "beneficiary",
        fieldType: "string",
      },
      {
        title: commonNames.CashValue,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "300",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        change: false,
        draggable: true,
        fieldName: "cash_value",
        fieldType: "number",
      },
      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        width: "200",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        change: false,
        draggable: true,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    nonretirementbrokerage: [
      {
        title: commonNames.Institution,
        class: "default-medium-sub-heading-m",
        width: "420",
        pfsWidth: "300",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "institution",
        fieldType: "string",
      },
      // {
      //   title: commonNames.Type,
      //   class: "default-medium-sub-heading-m",
      //   width: "410",
      //   tooltip: false,
      //   filter: false,
      //   change: false,
      //   fieldName: "type",
      //   fieldType: "string"
      // },
      {
        title: commonNames.BalanceDue,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "balance",
        fieldType: "number",
      },
      {
        title: commonNames.Ownership_per,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "120",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "ownership",
        fieldType: "string",
      },
      {
        title: commonNames.ShareValue,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        // width: "220",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        data: "share_value",
        filter: false,
        change: false,
        fieldName: "share_value",
        fieldType: "number",
      },

      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        width: "220",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    automobile: [
      {
        title: commonNames.Description,
        class: "default-medium-sub-heading-m",
        width: "340",
        pfsWidth: "300",
        tooltip: false,
        filter: false,
        fieldName: "description",
        draggable: false,
        fieldType: "string",
        isLinkedTooltip: true,
      },
      {
        title: commonNames.NameOfCreditor,
        class: "default-medium-sub-heading-m",
        width: "260",
        pfsWidth: "200",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "creditor_name",
        fieldType: "string",
      },
      {
        title: commonNames.MortgageBalanceDue,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "204",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "balance_due",
        fieldType: "number",
      },
      {
        title: commonNames.Payment,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: true,
        draggable: true,
        fieldName: "payment",
        fieldType: "number",
      },
      {
        title: commonNames.MarketValue,
        class: "default-medium-sub-heading-m text-end automobile-market",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "market_value",
        fieldType: "number",
      },
      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        width: "220",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        change: false,
        draggable: true,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    business: [
      {
        title: commonNames.EntityName,
        class: "default-medium-sub-heading-m",
        width: "350",
        pfsWidth: "300",
        tooltip: false,
        filter: false,
        draggable: false,
        fieldName: "entity_name",
        fieldType: "string",
      },
      {
        title: commonNames.Industry,
        class: "default-medium-sub-heading-m",
        width: "220",
        pfsWidth: "200",
        tooltip: false,
        filter: true,
        draggable: true,
        fieldName: "industry",
        fieldType: "string",
      },
      {
        title: commonNames.MarketValue,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "350",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "balance",
        fieldType: "number",
      },
      {
        title: commonNames.Ownership_per,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "200",
        pfsWidth: "120",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "ownership",
        fieldType: "string",
      },
      {
        title: commonNames.ShareMarketValue,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "share_market_value",
        fieldType: "number",
      },
      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        width: "220",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    personalproperty: [
      {
        title: commonNames.Description,
        class: "default-medium-sub-heading-m",
        width: "732",
        pfsWidth: "300",
        tooltip: false,
        filter: false,
        fieldName: "description",
        fieldType: "string",
        isLinkedTooltip: true,
      },
      {
        title: commonNames.PropertyName,
        class: "default-medium-sub-heading-m",
        width: "280",
        pfsWidth: "200",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "property_name",
        fieldType: "string",
      },
      {
        title: commonNames.MarketValue,
        class:
          "default-medium-sub-heading-m market-value-th text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        filter: false,
        change: false,
        fieldName: "market_value",
        fieldType: "number",
      },
      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        width: "220",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    realestate: [
      {
        title: commonNames.Address,
        class: "default-medium-sub-heading-m",
        width: "359",
        pfsWidth: "520",
        tooltip: false,
        filter: false,
        fieldName: "address",
        draggable: false,
        fieldType: "string",
        isLinkedTooltip: true,
      },
      {
        title: commonNames.MarketValue,
        class: "default-medium-sub-heading-m text-end market-value-verified-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "market_value",
        fieldType: "number",
      },
      {
        title: commonNames.Ownership_per,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "200",
        pfsWidth: "120",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "ownership",
        fieldType: "string",
      },
      {
        title: commonNames.AnnualIncome,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: true,
        draggable: true,
        fieldName: "annual_income",
        fieldType: "number",
      },
      {
        title: commonNames.ShareMarketValue,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "share_market_value",
        fieldType: "number",
      },
      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        width: "204",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    otherassets: [
      {
        title: commonNames.Description,
        class: "default-medium-sub-heading-m",
        width: "710",
        pfsWidth: "300",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "description",
        fieldType: "string",
      },
      {
        title: commonNames.AssetType,
        class: "default-medium-sub-heading-m",
        width: "280",
        pfsWidth: "200",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "property_name",
        fieldType: "string",
      },
      {
        title: commonNames.MarketValue,
        class:
          "default-medium-sub-heading-m market-value-th text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        // tooltip: true,
        filter: false,
        change: false,
        fieldName: "market_value",
        fieldType: "number",
      },
      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        width: "220",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "date_updated",
        fieldType: "date",
      },
      // {
      //   title: commonNames.Actions,
      //   class: "default-medium-sub-heading-m",
      //   width: "100",
      //   tooltip: false,
      //   filter: false,
      //   change: false,
      // },
    ],
  },
  liabilities: {
    accountpayable: [
      {
        title: commonNames.NameOfCreditor,
        class: "default-medium-sub-heading-m",
        width: "300",
        pfsWidth: "300",
        tooltip: false,
        filter: false,
        draggable: false,
        fieldName: "creditor_name",
        fieldType: "string",
      },
      {
        title: commonNames.Type,
        class: "default-medium-sub-heading-m",
        width: "180",
        pfsWidth: "200",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "type",
        fieldType: "string",
      },
      {
        title: commonNames.APRPercentage,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "120",
        tooltip: false,
        filter: true,
        draggable: true,
        fieldName: "apr_percentage",
        fieldType: "string",
      },
      {
        title: commonNames.MinimumPayment,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: true,
        draggable: true,
        fieldName: "minimum_payment",
        fieldType: "number",
      },
      {
        title: commonNames.MortgageBalanceDue,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "balance_due",
        fieldType: "number",
      },
      {
        title: commonNames.UpdatedDate,
        class: "default-medium-sub-heading-m",
        width: "180",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    installmentsaccount: [
      {
        title: commonNames.NameOfCreditor,
        class: "default-medium-sub-heading-m",
        width: "314",
        pfswidth: "220",
        tooltip: false,
        filter: false,
        change: false,
        draggable: false,
        fieldName: "creditor_name",
        fieldType: "string",
      },
      {
        title: commonNames.MaturityDate,
        class: "default-medium-sub-heading-m",
        width: "180",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        change: false,
        draggable: true,
        fieldName: "maturity_date",
        fieldType: "date",
      },
      {
        title: commonNames.LoanAmount,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: true,
        draggable: true,
        fieldName: "loan_amount",
        fieldType: "number",
      },
      {
        title: commonNames.Payment,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: true,
        draggable: true,
        fieldName: "payment",
        fieldType: "number",
      },
      {
        title: commonNames.MortgageBalanceDue,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        change: false,
        draggable: true,
        fieldName: "balance_due",
        fieldType: "number",
      },
      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        width: "180",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        change: false,
        draggable: true,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    installmentsauto: [
      {
        title: commonNames.NameOfCreditor,
        class: "default-medium-sub-heading-m",
        width: "314",
        pfsWidth: "280",
        tooltip: false,
        filter: false,
        draggable: false,
        fieldName: "creditor_name",
        fieldType: "string",
        isLinkedTooltip: true,
      },
      {
        title: commonNames.MaturityDate,
        class: "default-medium-sub-heading-m",
        width: "180",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "maturity_date",
        fieldType: "date",
      },
      {
        title: commonNames.LoanAmount,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: true,
        draggable: true,
        fieldName: "loan_amount",
        fieldType: "number",
      },
      {
        title: commonNames.Payment,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: true,
        draggable: true,
        fieldName: "payment",
        fieldType: "number",
      },
      {
        title: commonNames.MortgageBalanceDue,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "balance_due",
        fieldType: "number",
      },
      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        width: "180",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    lifeinsurance: [
      {
        title: commonNames.Company,
        class: "default-medium-sub-heading-m",
        width: "620",
        pfsWidth: "300",
        tooltip: false,
        filter: false,
        draggable: false,
        fieldName: "company",
        fieldType: "string",
      },
      {
        title: commonNames.Beneficiary,
        class: "default-medium-sub-heading-m",
        width: "300",
        pfsWidth: "180",
        tooltip: false,
        filter: true,
        draggable: true,
        fieldName: "beneficiary",
        fieldType: "string",
      },
      {
        title: commonNames.MortgageBalanceDue,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "300",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "loan_amount",
        fieldType: "number",
      },
      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        width: "220",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    notespayabletobanksandothers: [
      {
        title: commonNames.NameOfCreditor,
        class: "default-medium-sub-heading-m",
        width: "231",
        pfswidth: "220",
        tooltip: false,
        filter: false,
        draggable: false,
        fieldName: "creditor_name",
        fieldType: "string",
      },
      {
        title: commonNames.MaturityDate,
        class: "default-medium-sub-heading-m",
        width: "220",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "maturity_date",
        fieldType: "date",
      },
      {
        title: commonNames.LoanAmount,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: true,
        draggable: true,
        fieldName: "loan_amount",
        fieldType: "number",
      },
      {
        title: commonNames.Payment,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: true,
        draggable: true,
        fieldName: "payment",
        fieldType: "number",
      },
      {
        title: commonNames.MortgageBalanceDue,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "balance_due",
        fieldType: "number",
      },
      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        width: "220",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    otherliabilities: [
      {
        title: commonNames.Description,
        class: "default-medium-sub-heading-m",
        width: "900",
        pfsWidth: "300",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "description",
        fieldType: "string",
      },
      {
        title: commonNames.MortgageBalanceDue,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "balance_due",
        fieldType: "number",
      },
      {
        title: commonNames.UpdatedDate,
        class: "default-medium-sub-heading-m",
        width: "220",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    unpaidtaxes: [
      {
        title: commonNames.Description,
        class: "default-medium-sub-heading-m",
        width: "780",
        pfsWidth: "300",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "description",
        fieldType: "string",
      },
      {
        title: commonNames.TaxYear,
        class: "default-medium-sub-heading-m",
        width: "220",
        pfsWidth: "88",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "year",
        fieldType: "string",
      },
      {
        title: commonNames.MortgageBalanceDue,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "balance_due",
        fieldType: "number",
      },
      {
        title: commonNames.DateUpdated,
        class: "default-medium-sub-heading-m",
        width: "220",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        change: false,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
    mortgagesonrealestate: [
      {
        title: commonNames.NameOfCreditor,
        class: "default-medium-sub-heading-m",
        width: "316",
        pfsWidth: "220",
        tooltip: false,
        filter: false,
        draggable: false,
        fieldName: "creditor_name",
        fieldType: "string",
        isLinkedTooltip: true,
      },
      {
        title: commonNames.MaturityDate,
        class: "default-medium-sub-heading-m",
        width: "180",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "maturity_date",
        fieldType: "date",
      },
      {
        title: commonNames.MortgageBalance,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "mortgage_balance",
        fieldType: "number",
      },
      {
        title: commonNames.Ownership_per,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "120",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "ownership",
        fieldType: "string",
      },
      // {
      //   title: commonNames.LoanAmount,
      //   class: "default-medium-sub-heading-m",
      //   width: "300",
      //   tooltip: false,
      //   filter: true,
      //   draggable: true,
      //   fieldName: "loan_amount",
      //   fieldType: "number"
      // },
      {
        title: commonNames.MonthlyPayment,
        class: "default-medium-sub-heading-m text-end",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: true,
        draggable: true,
        fieldName: "payment_monthly",
        fieldType: "number",
      },
      {
        title: commonNames.MortgageBalanceDue,
        class: "default-medium-sub-heading-m text-end share-value-th",
        pfsClass: "default-medium-sub-heading-m text-end",
        width: "220",
        pfsWidth: "162",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "balance_due",
        fieldType: "number",
      },
      {
        title: commonNames.BalanceAs,
        class: "default-medium-sub-heading-m",
        width: "180",
        pfsWidth: "126",
        tooltip: false,
        filter: false,
        draggable: true,
        fieldName: "date_updated",
        fieldType: "date",
      },
    ],
  },
};

const manageColumns = [
  {
    category: "assets",
    title: "IRA or Other Retirement Account",
    type: "retirementaccount",
  },
  {
    category: "assets",
    title: "Life Insurance",
    type: "lifeinsurance",
  },
  {
    category: "assets",
    title: "Business",
    type: "business",
  },
  {
    category: "assets",
    title: "Accounts & Notes Receivable",
    type: "accountnotesreceviable",
  },
  {
    category: "assets",
    title: "Real Estate",
    type: "realestate",
  },
  {
    category: "assets",
    title: "Automobile",
    type: "automobile",
  },
  {
    category: "liabilities",
    title: "Loan(s) Against Life Insurance",
    type: "lifeinsurance",
  },
  {
    category: "liabilities",
    title: "Installment Account (Auto)",
    type: "installmentsauto",
  },
  {
    category: "liabilities",
    title: "Accounts Payable",
    type: "accountpayable",
  },
  {
    category: "liabilities",
    title: "Notes Payable to Banks and Others",
    type: "notespayabletobanksandothers",
  },
  {
    category: "liabilities",
    title: "Installment Account (Other)",
    type: "installmentsaccount",
  },
  {
    category: "liabilities",
    title: "Mortgages on Real Estate",
    type: "mortgagesonrealestate",
  },
];

export {
  tableHeader,
  amortizationCategory,
  portfolioCategory,
  transactionCategory,
  manageColumns,
  quickBooksReportCategory,
};
