import React from "react";
import NumberFormat from "react-currency-format";
import commonNames from "../../../config/commonNames";

const NetWorthDashboard = ({ netWorth }) => {
  return (
    <>
      <div className="total-networth">
        <h3 className="net-worth-heading default-semi-bold-h3 cs-neutral-100">{commonNames.NetWorth}</h3>
        <div className="dollar-value-gap net-worth-value default-semi-bold-h3 cs-neutral-100">
          <span>$&nbsp;</span>
          <NumberFormat
            value={Math.round(netWorth).toFixed(0)}
            displayType={"text"}
            thousandSeparator={true}
          />
        </div>
      </div>
    </>
  );
};

export default NetWorthDashboard;
