import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LeftPart from "./LeftPart";
import QRCode from "qrcode";
import { clearMessage } from "../../slices/message";
import { mfaGoogleAuth, mfaGoogleAuthSetup } from "../../slices/auth";
import CapsyncLoaderSpin from "../../../assets/images/capsync-loader.gif";
import CapsyncIcon from "../../commonComponent/CapsyncIcon";
import LogoImage from "../logoImage";

const SignInMFAuthGoogleSetup = () => {
  const [qr, setQr] = useState("");
  const [msg, setMsg] = useState("");
  const [contentFlag, setContentFlag] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = user && user.user.email;
  const userId = user && user.user.id;
  const qrLbl = user && user.user_role.role === 1 ? `CapSync USER-${userId}` : `CapSync FA-${userId}`;

  useEffect(() => {
    setContentFlag(false);
    dispatch(clearMessage());
    dispatch(mfaGoogleAuth({ email }))
      .then((data) => {
        if (data.payload.code === 200) {
          const srt = data.payload.res_data.secret;
          const qrCompLabel = process.env.REACT_APP_TITLE;
          const otpauth_url =
            "otpauth://totp/" + qrCompLabel + ": " + email + "?secret=" + srt;
          QRCode.toDataURL(otpauth_url, (err, image_data) => {
            setQr(image_data);
          });
          setContentFlag(true);
        } else {
          setContentFlag(false);
        }
      })
      .catch((err) => {
        setContentFlag(false);
      });
  }, [dispatch]);

  const mfaGoogleAuthConfirm = () => {
    dispatch(mfaGoogleAuthSetup({ email }))
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          setMsg(res.message);
          navigate("/mf-auth-googlepin");
        } else {
          setMsg(res.message);
        }
      })
      .catch(() => { });
    setContentFlag(true);
  };

  return (
    <section className="auth-section">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={6}>
            <LeftPart
              page="mfagooglesetup"
              img="auth-image.svg"
              title="Your New Immersive Digital Finance Hub."
              text="Sync your real-time digital Personal Financial Statement with CapSync."
            />
          </Col>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <div className="auth-right">
                  {contentFlag ? (
                    <div className="auth-right-section">
                      <LogoImage />
                      <h1 className="default-semi-bold-h1">
                        Authenticator app verification
                      </h1>
                      <hr className="head-underline" />
                      <div className="default-regular-sub-heading-s">
                        <p className="cs-neutral-80">
                          Scan the below QR code with your authenticator app.
                        </p>
                        <div className="qrcode">
                          {
                            <Image
                              src={`${qr}`}
                              alt="Qr Code"
                              width={244}
                              height={244}
                            />
                          }
                        </div>
                      </div>
                      <Button
                        type="button"
                        onClick={mfaGoogleAuthConfirm}
                        className="cs-btn-primary lg-btn default-regular-h5"
                      >
                        Next
                      </Button>
                    </div>
                  ) : (
                    <div className="auth-right-section">
                      <h1 className="default-semi-bold-h1">
                        Authenticator app verification
                      </h1>
                      <hr className="head-underline" />
                      <div className="text-center  initial-modal-loader">
                        <Image
                          src={CapsyncLoaderSpin}
                          alt="CapsyncLoaderSpin"
                          className="w-25"
                        />
                      </div>
                    </div>
                  )}
                  {msg && (
                    <div className="cs-msg default-regular-body-text-s cs-danger">
                      <span className="icon">
                        <CapsyncIcon title="info-filled" />
                      </span>
                      <span className="formik-form-error"> {msg}</span>
                    </div>
                  )}

                  {msg && (
                    <div className="cs-msg default-regular-body-text-s cs-danger">
                      <span className="icon">
                        <CapsyncIcon title="info-filled" />
                      </span>
                      <span className="formik-form-error"> {msg}</span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SignInMFAuthGoogleSetup;
