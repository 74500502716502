import React from "react";
import { Accordion, Row, Col, Table, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import moment from "moment";
import { requestTableHeader } from "../../../config/request";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import { requestStatusFilter } from "../../../util/request";
import { roleName } from "../../../config/commonRoleName";
import CapsyncToolTip from "../../../commonComponent/capsyncTooltip/CapsyncToolTip";
const RequestTable = ({
  tabValue,
  user_email,
  filteredIndividuals,
  onSignatureDetails,
  sharePFSModal,
  handleDownload,
  // shareicon,
  // DarkDownloadIcon,
  status,
  accountAccessList,
  rowStartIdx,
  rowsPerPage
}) => {

  return (
    <Row>
      <Col>
        <div className="table amortization-main-listing">
          {requestStatusFilter(accountAccessList, status).length === 0 ? (
            <span className="cs-common-no-record">
              There are no records to display
            </span>
          ) : (
            <Table className="cs-table">
              <thead id="table-head-details-wm" className="cs-thead">
                <tr>
                  {requestTableHeader.accountAccess.map((header) => (
                    <th
                      key={header.title}
                      className={header.class}
                      width={header.width}
                      scope="col"
                    >
                      {header.title}
                    </th>
                  ))}
                </tr>
              </thead>

              <tbody className="request-dashboard cs-tbody default-regular-sub-heading-m">
                {requestStatusFilter(accountAccessList, status).slice(rowStartIdx, rowStartIdx + rowsPerPage).map(
                  (data, index) => {

                    const { request_send_by_role, request_send_by_displayName, request_type,companyName, createdAt, access_permission } = data;
                    return (
                      <tr key={index}>
                        <td>{request_type}</td>
                        <td>
                          {/* {request_send_by_displayName} */}
                          {request_send_by_displayName.length > 20 ? (
                            <CapsyncToolTip
                              Child={request_send_by_displayName.slice(0, 20) + "..."}
                              placement={"top"}
                              message={request_send_by_displayName}
                              type="text"
                            />
                          ) : (
                            request_send_by_displayName
                          )}
                        </td>
                        <td>{request_send_by_role == roleName.individual ? "Individual" : request_send_by_role == roleName.financial_advisor ? "Financial Advisor" : "Professional"}</td>
                           <td>{companyName ? companyName : "-"}</td>
                        <td>{moment(createdAt).format("MM/DD/YYYY")}</td>
                        <td>
                          {access_permission === 1
                            ? "View Only"
                            : "Standard"}
                        </td>
                        <td>
                          <div
                            className="cs-detail-btn"
                            onClick={() =>
                              onSignatureDetails(
                                data.request_send_by_email,
                                data,
                                data.signature_hello_id,
                                data.request_id
                              )
                            }
                          >
                            <span className="default-light-sub-heading-s cs-neutral-90">Details</span>
                            <span className="icon cs-neutral-80">
                              <CapsyncIcon
                                title="chevron-right-outlined"
                                size="18"
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </Table>
          )}
        </div>
      </Col>
    </Row>
  );
};
export default RequestTable;
