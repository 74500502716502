/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Modal, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { useSelector } from "react-redux";
import { usePlaidLink } from "react-plaid-link";

import "../../../../../app/pages/individual/dashboard/InitialSetup/initial-setup.css";
import { backAppURl } from "../../../../applicationMode";
import PlaidProgressBar from "./PlaidProgressBar";
import ConnectedAccBlock from "./ConnectedAccBlock";
import ErrorPopup from "./ErrorPopup";
import BankImages from "../../../../../assets/images/other/plaid_bank_images.svg";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import { chase_and_charles_schwab, plaidRefreshArr } from "../../../../config/finance";
import PlaidUpdateMode from "./PlaidUpdateMode";
import { CategoryAccountsLength, accountsGet, checkUserAccounts, create_link_token, fetchInstitution, itemRemoveException } from "../../../../config/plaidFunctions";
import PlaidAccountsList from "./PlaidAccountsList";
import { useDispatch } from "react-redux";
import { get_user_storage } from "../../../../slices/fileManagement.slice";

const InitialSetup1 = (props) => {
  const {
    changeDisplay, plaid_link_token, changeLinkToken,
    plaidCategories, changePlaidCategories, handlePlaidError,
    plaidErrorDisplay, changeCategoryData, errorAccounts,
    refreshAsset, refreshLiability, changeFilterStatus,
    mainFilterStatus, hideNotNow, closeTheModal,
    changeCounter, dataAsset, dataLiability, next,
    changeCounter4, showModal, display
  } = props;
  const [progessPlaid, setProgessPlaid] = useState(1);
  const [fetchlinktokenIndicator, setFetchlinktokenIndicator] = useState(false);
  const [duplicateAcc, setDuplicateAcc] = useState({
    duplicate_installmentAuto: [],
    duplicate_accoutPayable: [],
    duplicate_otherLiability: [],
    duplicate_realEstateMortgage: [],
    duplicate_notesPayable: [],
    duplicate_asset_cash_on_hands: [],
    duplicate_asset_savings_account: [],
    duplicate_asset_retirement_accounts: [],
    duplicate_asset_stocks_bonds: [],
  });

  const [newAcc, setNewAcc] = useState({
    new_installmentAuto: [],
    new_accoutPayable: [],
    new_otherLiability: [],
    new_realEstateMortgage: [],
    new_notesPayable: [],
    new_asset_cash_on_hands: [],
    new_asset_savings_account: [],
    new_asset_retirement_accounts: [],
    new_asset_stocks_bonds: [],
  });

  const [showDuplicateHeading, setShowDuplicateHeading] = useState(false);
  const [showNewHeading, setShowNewHeading] = useState(false);
  const [apiName, setApiName] = useState("");
  const [updateModeSetup, setUpdateModeSetup] = useState(false);
  const [institution, setInstitution] = useState({});
  const [totalAccounts, setTotalAccounts] = useState(undefined);
  const [updateInstitutionId, setUpdateInstitutionId] = useState('');
  const [updateIsInvestment, setUpdateIsInvestment] = useState(false);
  const [publicTokenLink, setPublicTokenLink] = useState('');
  const [updateFlag, setUpdateFlag] = useState(false);
  const dispatch = useDispatch()

  const openModal = useRef(null);
  const { user } = useSelector((state) => state.auth);

  let id;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }
  const getStorage = async () => {
    await dispatch(get_user_storage({ id })).unwrap();
  }
  useEffect(() => {
    localStorage.removeItem("isMySetting");
    create_link_token(id, plaidErrorDisplay, setFetchlinktokenIndicator, changeLinkToken, setPublicTokenLink, secureLocalStorage);
  }, []);

  useEffect(() => {
    getStorage()
    if (totalAccounts === findSum()) {
      changePlaidCategories(false);
      setShowDuplicateHeading(true);
      setShowNewHeading(true);
      plaidRefreshArr.forEach((itr) => changeCategoryData(itr));
      refreshAsset();
      // refreshLiability();
      changeFilterStatus(!mainFilterStatus);
      errorAccounts()
    }
  }, [totalAccounts, findSum()]);

  const tryAgain = () => {
    changeModal();
  };

  function navigateToDashboard() {
    if (hideNotNow !== undefined) {
      hideNotNow();
    }
    if (closeTheModal !== undefined) {
      closeTheModal();
    }
  }

  function storeNewAccount(category, data) {
    setNewAcc((prev) => ({
      ...prev,
      [category]: data,
    }));
  }
  function storeDuplicateAccount(category, data) {
    setDuplicateAcc((prev) => ({
      ...prev,
      [category]: data,
    }));
  }

  async function startUpdate() {
    if (chase_and_charles_schwab.includes(updateInstitutionId)) {
      let returnedVal = await itemRemoveException(id, updateInstitutionId);
      setUpdateFlag(false);
      setUpdateModeSetup(false);
      if (returnedVal.data.status === 200) {
        create_link_token(id, plaidErrorDisplay, setFetchlinktokenIndicator, changeLinkToken, setPublicTokenLink, secureLocalStorage);
        setTimeout(() => {
          openModal.current.click();
        }, 2000);
      }
    } else {
      axios
        .post(`${backAppURl}/api/plaid/update_mode`, { institution_id: updateInstitutionId, user_id: id }).then((data) => {
          setUpdateFlag(true);
          setUpdateModeSetup(false);
          setApiName(`We're retrieving your financial data from ${institution.name}`)
          setPublicTokenLink(data.data.link_token);
          setTimeout(() => {
            openModal.current.click();
          }, 2000);
        }).catch((err) => {
          console.log(err);
        })
    }
  }

  const config = {
    token: publicTokenLink,
    onSuccess: async (public_token, metadata) => {
      console.log("metadata:- ", metadata);
      changePlaidCategories(true);
      secureLocalStorage.setItem("public_token", public_token);
      secureLocalStorage.setItem("ins_id", metadata.institution.institution_id);
      fetchInstitution(metadata.institution.institution_id, id, setInstitution);

      let storeUserAccData = await checkUserAccounts(id, metadata);

      if (updateFlag) {
        changeDisplay("block");
        accountsGet(metadata, id, storeDuplicateAccount, storeNewAccount, plaidErrorDisplay, setProgessPlaid, setTotalAccounts, handlePlaidError, investmentHolding, updateFlag, updateIsInvestment);
      }
      else {
        if (storeUserAccData.status) {
          changeDisplay("block");
          setUpdateModeSetup(storeUserAccData.status);
          setUpdateIsInvestment(storeUserAccData.is_investment);
          setUpdateInstitutionId(storeUserAccData.institution_id);
        }
        else {
          axios
            .post(`${backAppURl}/api/plaid/set_access_token`, {
              token: public_token,
              user_id: id,
              institution_id: metadata.institution.institution_id,
              accounts: metadata.accounts,
            })
            .then((response) => {
              changeDisplay("block");
              if (Object.keys(response.data).includes("error_code") === true) {
                plaidErrorDisplay(true, response.data.error_message);
              } else {
                setApiName("We're fetching your accounts.");
                accountsGet(
                  metadata, id, storeDuplicateAccount, storeNewAccount, plaidErrorDisplay, setProgessPlaid, setTotalAccounts, handlePlaidError, investmentHolding, updateFlag, updateIsInvestment
                );
                setTimeout(() => {
                  props.setCategoryAccountAdded('plaid')
                },2000)
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    onExit: (err, metadata) => {
      if (updateFlag) {
        changePlaidCategories(false);
        closeTheModal(false);
      }
      else {
        if (hideNotNow !== undefined) {
          setUpdateFlag(false);
          changePlaidCategories(false);
          changeCounter(2);
          changeDisplay("block");
        }
        if (closeTheModal !== undefined) {
          setUpdateFlag(false);
          changePlaidCategories(false);
          changeCounter(1);
        }
      }
    },
    onEvent: (eventName, metadata) => { },
  };

  const { open, ready } = usePlaidLink(config);

  const changeModal = () => {
    changeDisplay("d-none");
    if (ready) {
      open();
    }
  };

  function investmentHolding() {
    axios
      .get(
        `${backAppURl}/api/plaid/holdings/${id}/${secureLocalStorage.getItem(
          "ins_id"
        )}`
      )
      .then((response) => {
        console.log("Investments holdings:- ", response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function findSum() {
    return CategoryAccountsLength(newAcc, duplicateAcc, 'assets') + CategoryAccountsLength(newAcc, duplicateAcc, 'liabilities');
  }

  const handleLogout = () => {
    props.CapsyncLogoutModal(true);
  }

  return (
    <>

      {handlePlaidError.status === true ? (
        <>
          <ErrorPopup
            params={handlePlaidError.message}
            tryAgain={tryAgain}
            navigateToDashboard={navigateToDashboard}
          />
        </>
      ) : (
        <>
          {plaid_link_token === false ? (
            <div className="cs-loader"></div>
          ) : (
            <>
              <button
                onClick={() => changeModal()}
                ref={openModal}
                style={{ display: "none" }}
              />
              <Modal
                show={showModal}
                // onHide={() => (dataAsset !== "0.00" || dataLiability !== "0.00") ? closeTheModal(false) : handleLogout()}
                size="cs-s-modal"
                backdrop="static"
                id="connect-your-institutions"
                aria-labelledby="contained-modal-title-vcenter"
                className={
                  handlePlaidError.status === true
                    ? "cap-dash-main-modal plaid-modal error-display"
                    : plaidCategories === false
                      ? `cap-dash-main-modal plaid-modal ${display}`
                      : `cap-dash-main-modal plaid-modal plaid-progress-modal ${display}`
                }
                centered
                keyboard={false}
                dialogClassName="cs-s-modal"
              >

                {plaidCategories === false && (
                  <Modal.Header
                  // closeButton
                  >
                    <Modal.Title className="default-semi-bold-h3 cs-neutral-100 ">
                      Add New Account
                    </Modal.Title>
                    <span
                      className="cs-neutral-70 cs-close-btn"
                      onClick={() => {
                        if (dataAsset !== "0.00" || dataLiability !== "0.00") {
                          closeTheModal(false);
                        } else {
                          handleLogout();
                        }
                      }}
                    >
                      <CapsyncIcon title="close-outlined" size="20" />
                    </span>
                  </Modal.Header>
                )}

                {plaidCategories === false ? (
                  <>
                    <Modal.Body
                      className={
                        showNewHeading === true || showDuplicateHeading === true
                          ? "account-popup-setup1 plaid-account-data"
                          : "account-popup-setup1"
                      }
                    >
                      {fetchlinktokenIndicator === true && (
                        <>
                          <div className="add-account-head default-regular-body-text-m cs-neutral-90">
                            {showNewHeading === false &&
                              showDuplicateHeading === false && (
                                <>
                                  <Image
                                    className="plaid-banks-img"
                                    src={BankImages}
                                    width="343px"
                                    height="187px"
                                    alt="Bank Images"
                                  />
                                  {/* <h4 className="default-medium-sub-heading-m cs-neutral-100">
                                    Manage Your Finances
                                  </h4> */}
                                </>
                              )}
                            {(showNewHeading === true ||
                              showDuplicateHeading === true) &&
                              totalAccounts === findSum() && (
                                <>
                                  <ConnectedAccBlock
                                    imgpath_3={institution.logo}
                                    headerMsg={`You have successfully linked your ${institution.name} account(s)`}
                                  />
                                </>
                              )}
                            {/* <span className="default-regular-sub-heading-xs cs-neutral-90">
                              Easily manage your finances by choosing to securely
                              connect your existing accounts or manually add new
                              ones
                            </span> */}
                          </div>
                        </>
                      )}

                      <div className="cs-accoutns-details cs-scroller">
                        {totalAccounts === findSum() && (
                          <>
                            {CategoryAccountsLength(newAcc, duplicateAcc, 'assets') !== 0 && (
                              <>
                                <div className="cs-accoutns-list assets-accoutns-list">
                                  {
                                    (showNewHeading === true || showDuplicateHeading === true) && (
                                      <div className="finance-data-container">
                                        <span className="finance-heading heading-asset default-medium-sub-heading-m cs-neutral-100">
                                          Assets
                                        </span>
                                      </div>
                                    )
                                  }
                                  <PlaidAccountsList
                                    newAcc={newAcc}
                                    duplicateAcc={duplicateAcc}
                                    type='assets'
                                  />
                                </div>
                              </>
                            )}
                            {CategoryAccountsLength(newAcc, duplicateAcc, 'liabilities') !== 0 && (
                              <>
                                <div className="cs-accoutns-list liability-accoutns-list">
                                  {(showNewHeading === true || showDuplicateHeading === true) && (
                                    <div className="finance-data-container">
                                      <span className="finance-heading heading-liability default-medium-sub-heading-m cs-neutral-100">
                                        Liabilities
                                      </span>
                                    </div>
                                  )}
                                  <PlaidAccountsList
                                    newAcc={newAcc}
                                    duplicateAcc={duplicateAcc}
                                    type='liabilities'
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>

                      {showNewHeading === false &&
                        showDuplicateHeading === false && (
                          <>
                            {fetchlinktokenIndicator === true && (
                              <>
                                <div className="add-account-main-button">
                                  <Button
                                    onClick={() => changeModal()}
                                    className="cs-btn-secondary default-regular-h5"
                                  >
                                    Link your account
                                  </Button>
                                  <div className="cs-divider">
                                    <span className="default-regular-body-text-s cs-neutral-50">
                                      OR
                                    </span>
                                  </div>
                                  <Button
                                    onClick={next}
                                    className="cs-btn-secondary default-regular-h5"
                                  >
                                    Add manual account
                                  </Button>
                                </div>
                                <div className="plaid-add-popup-note">
                                  <span className="default-regular-sub-heading-xs cs-neutral-70">
                                    By continuing, you agree to CapSync's
                                  </span>
                                  <Link
                                    to="https://capsync.com/privacy-policy"
                                    target="_blank"
                                    className="default-regular-h5 cs-neutral-100"
                                  >
                                    Privacy Policy
                                  </Link>
                                </div>
                              </>
                            )}
                          </>
                        )}
                    </Modal.Body>
                    <Modal.Footer className="plain-footer">
                      {(showNewHeading === true ||
                        showDuplicateHeading === true) && (
                          <>
                            <div className="cs-modal-btn">
                              <div className="plaid-text default-regular-sub-heading-xs cs-neutral-100">
                                <span className="plaid-icon cs-success">
                                  <CapsyncIcon title="check-outlined" size="18" />
                                </span>
                                Account(s) linked to CapSync
                              </div>
                              <Button
                                onClick={changeCounter4}
                                className="cs-btn-secondary md-btn default-regular-h5"
                              >
                                Continue
                              </Button>
                            </div>
                          </>
                        )}
                    </Modal.Footer>
                  </>
                ) :
                  updateModeSetup ?
                    (
                      <PlaidUpdateMode
                        handleUpdate={startUpdate}
                        navigateToDashboard={navigateToDashboard}
                        imgpath_3={institution.logo}
                        headerMsg={institution.name}
                      />
                    )
                    :
                    (
                      <Modal.Body>
                        <PlaidProgressBar
                          updateFlagClassName={updateFlag && 'default-regular-sub-heading-s cs-neutral-100'}
                          apiName={apiName}
                          progessPlaid={progessPlaid}
                        />
                      </Modal.Body>
                    )}
              </Modal>
            </>
          )}
        </>
      )}
    </>
  );
};

export default InitialSetup1;