import { useEffect, useState } from "react";
import { wealth_managers_findone } from "../slices/auth";
import { useDispatch, useSelector } from "react-redux";

const UseUserRecordWm = () => {
  const [wmUserData, setWmUserData] = useState({});
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let id;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  useEffect(() => {
    dispatch(wealth_managers_findone({ wm_id: id }))
      .unwrap()
      .then((response) => {
        setWmUserData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  return { wmUserData };
};

export default UseUserRecordWm;
