import React from 'react'
import Skeleton from 'react-loading-skeleton';
import {
    Row,
    Col,
    Card,
} from "react-bootstrap";
import SkeletonCategoryTable from "./../../assets/asset_dashboard/SkeletonCategoryTable";
const SkeletonBillingSubscription = () => {
    return (
        <div className="billing-subscription-page">
            <div className="plan-text-cards">
                <div className="plan-heading">
                    <p className="default-regular-sub-heading-l cs-neutral-100">
                        <Skeleton width={84} />
                    </p>
                    <div className='for-des'>
                        <div className="plan-toggle">
                            <label className="default-regular-sub-heading-l cs-neutral-80">
                                <Skeleton width={125} />
                            </label>
                            <label className='skeleton-billing-toggle' >
                                <Skeleton width={60} />
                            </label>
                            <label className="default-regular-sub-heading-l cs-neutral-80">
                                <Skeleton width={130} />
                            </label>
                        </div>
                    </div>
                    <div className='for-mob'>
                        <div className="plan-toggle">
                            <label className="default-regular-sub-heading-l cs-neutral-80">
                                <Skeleton width={100} />
                            </label>
                            <label className='skeleton-billing-toggle' >
                                <Skeleton width={50} />
                            </label>
                            <label className="default-regular-sub-heading-l cs-neutral-80">
                                <Skeleton width={100} />
                            </label>
                        </div>
                    </div>
                </div>

                <div className="subscription-plan-cards skeleton-subscription-plan-cards">

                    {Array(2).fill(0).map((item, index) => (
                        <Card key={index}>
                            <Card.Body>
                                <div className="plan-icons">
                                    <div className="plan-amounts">
                                        <h2 className="default-semi-bold-h1 cs-neutral-100">
                                            <Skeleton width={150} />
                                        </h2>
                                    </div>
                                    <div className="card-spacing">
                                        <div className='skeleton-card-title-container'>
                                            <h2 className="default-semi-bold-h1 cs-neutral-100">
                                                <Skeleton width={150} />
                                            </h2>
                                            <p className="default-regular-sub-heading-l cs-neutral-80">
                                                <Skeleton />
                                            </p>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        {Array(7).fill(0).map((subItem, subIndex) => (
                                            <div key={subIndex} className="plan-lists skeleton-plan-lists">
                                                <Skeleton />
                                            </div>
                                        ))}
                                    </Card.Text>
                                </div>
                                <div className="subscription-plan-buttons">
                                    <p className='skeleton-card-btn'>
                                        <Skeleton height={40} />
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    ))}

                </div>
            </div>

            <Row>
                <Col lg={4}>
                    <div className="payment-cards">
                        <div className="payment-method">
                            <span className="default-regular-sub-heading-l cs-neutral-100">
                                <Skeleton width={150} />
                            </span>
                            <div className="text-button cursor-pointer">
                                <Skeleton width={150} />
                            </div>
                        </div>
                        <div className='skeleton-payment-box'>
                            <p className='skeleton-payment-name'><Skeleton width={150} /></p>
                            <p><Skeleton width={200} /></p>
                        </div>
                    </div>
                </Col>
            </Row>

            <div className="billing-history">
                <p className="default-regular-sub-heading-l cs-neutral-100">
                    <Skeleton width={150} />
                </p>
                <SkeletonCategoryTable tableData={[1, 2, 3]} />
            </div>
        </div>
    )
}

export default SkeletonBillingSubscription