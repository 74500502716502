import { createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { localDataRemove } from "../config/finance";
import API from "../util/api";
import { headerAddToken } from "../util/common";

export const quickBooksAuthentication = createAsyncThunk(
  "/quickbooks/auth",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.get("/quickbooks/auth", values, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        console.log("error",error);
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const refreshTokenPresent = createAsyncThunk(
  "/quickbooks/tokenpresent",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post("/quickbooks/tokenpresent",values, header);
      if(response && (response.data.status === 200 || response.data.status === 204)) {
        thunkAPI.dispatch(setMessage(response.data.data));
        return response.data.data;
      }
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
        console.log("error",error);
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
)

export const quickBooksFetchCategoryData = createAsyncThunk(
  "/quickbooks/reports",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post("/quickbooks/reports", values, header);

      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        console.log("error",error);
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const quickBooksDisconnectReport = createAsyncThunk(
  "/quickbooks/disconnect/reports",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        "/quickbooks/disconnect/reports",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const quickBooksDataAdd = createAsyncThunk(
  "/quickbooks/add",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post("/quickbooks/add", values, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      console.log("quickBooksDataAdd catch:- ", error);
      if (error.response.data.code === 401) {
        throw { quickbooksissue: "invalid" };
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const quickBooksReportFilter = createAsyncThunk(
  "/quickbooks/report/run",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post("/quickbooks/report/run", values, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        console.log("error",error);
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const quickBooksFetchReportFilter = createAsyncThunk(
  "/quickbooks/report/filters",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        "/quickbooks/report/filters",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        console.log("error",error);
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const quickBooksShareReport = createAsyncThunk(
  "/quickbooks/report/share",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        "/quickbooks/report/share",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        console.log("error",error);
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);