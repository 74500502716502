import React, { useState, useEffect } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import ErrorValidation from "../../../../../src/assets/images/other/error-validation.svg";
import CapsyncLoader from "../../CapsyncLoader";

const PaymentFailed = (props) => {
  const { showPaymentFailed, failureReason, setShowPaymentFailed } = props;

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(false);
  });

  const cancel = () => {
    setShowLoader(true);
    setShowPaymentFailed(false);
  };

  return (
    <>
      <Modal
        show={showPaymentFailed}
        onHide={() => !showPaymentFailed}
        centered
        size="md"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="cs-md-modal payment-success"
      >
        {showLoader ? (
          <div>
            <CapsyncLoader />
          </div>
        ) : (
          <>
            <Modal.Header>
              <Modal.Title className="default-semi-bold-h3">
                Payment Failed!
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="success-head">
                <span>
                  <Image
                    src={ErrorValidation}
                    alt="Error Validation"
                    width={100}
                    height={100}
                  />
                </span>
                <p className="default-light-body-text-m cs-neutral-90">
                  {failureReason}
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="cs-btn-secondary lg-btn default-regular-h5"
                onClick={() => cancel()}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default PaymentFailed;
