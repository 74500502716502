/* eslint-disable */
import API from "../util/api";
import { headerAddToken } from "../util/common";

const wealth_managers_findone = async (values) => {
  let header = await headerAddToken();
  return API.post("/wealthmanager/single", values, header);
};
const wealth_managers_image_update = async (values) => {
  let header = await headerAddToken();
  return API.post("/wealthmanager/imageupdate", values, header);
};
const wealth_managers_update = async (values) => {
  let header = await headerAddToken();
  return API.post("/wealthmanager/update", values, header);
};
const wealth_managers_avtar_update = async (values) => {
  let header = await headerAddToken();
  return API.post("/wealthmanager/wealth_managers_avtar_update", values, header);
};

const wealth_managers_mfaPhoneUpdateRecord = async (values) => {
  let header = await headerAddToken();
  return API.post("/wealthmanager/mfaphoneupdate", values, header);
};
const wm_setreset_phone_mfa = async (values) => {
  let header = await headerAddToken();
  return API.post("/wealthmanager/wmphoneupdatemfa", values, header);
};

const wealth_managers_mfaPhoneAuthPin = async (values) => {
  let header = await headerAddToken();
  return API.post("/wealthmanager/mfaSetupPhoneAuthPinWm", values, header);
};

const wealth_managers_findall = async (values) => {
  let header = await headerAddToken();
  return API.post("/wealthmanager/all", values, header);
};

const wmmfaEmailUpdateRecord = (values) => {
  return API.post(`/wealthmanager/wmmfaemailupdate`, values);
};
const wmChangePhoneNumber = (values) => {
  return API.post(`/wealthmanager/wmchangephonenumber`, values);
};

const wealth_companies_add = async (values) => {
  let header = headerAddToken();
  return API.post("/wealthmanager/companies/add", values, header);
};
const wealth_companies_update = async (values) => {
  let header = await headerAddToken();
  return API.post(`/wealthmanager/companies/update`, values, header);
};
const wealth_companies_add_partner = async (values) => {
  let header = await headerAddToken();
  return API.post(`/wealthmanager/companies/add_partner`, values, header);
};

const wealth_companies_list = async (values) => {
  let header = headerAddToken();
  return API.post("/wealthmanager/companies/list", values, header);
};

const company_user_delete = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/wealthmanager/companies/company_user_delete",
    values,
    header
  );
};

const wealth_companies_remove = async (values) => {
  let header = headerAddToken();
  return API.post("/wealthmanager/companies/delete", values, header);
};

const wealth_companies_count = async (values) => {
  let header = headerAddToken();
  return API.post("/wealthmanager/companies/count", values, header);
};

const wealth_companies_partners = async (values) => {
  let header = headerAddToken();
  return API.post("/wealthmanager/companies/partners", values, header);
};

const wealth_manager_decrypt = async (values) => {
  let header = headerAddToken();
  return API.post("/wealthmanager/companies/decrypt", values, header);
};

const wealth_manager_fetchIndividuals = async (values) => {
  let header = headerAddToken();
  return API.post("/wealthmanager/companies/fetchIndividuals", values, header);
};

const wealth_manager_fetchIndividuals_count = async (values) => {
  let header = headerAddToken();
  return API.post(
    "/wealthmanager/companies/fetchIndividuals_count",
    values,
    header
  );
};

const wealth_individuals_delete = async (values) => {
  let header = headerAddToken();
  return API.post("/wealthmanager/companies/delete/individual", values, header);
};

const wealth_individuals_exist = async (values) => {
  let header = headerAddToken();
  return API.post(
    "/wealthmanager/companies/check_exist/individual",
    values,
    header
  );
};

const wealth_find_search = async (values) => {
  let header = headerAddToken();
  return API.post("/wealthmanager/companies/search/company", values, header);
};

const wealth_manage_files = async (values) => {
  let header = headerAddToken();
  return API.post("/wealthmanager/companies/manage_files", values, header);
};

const wealth_manage_files_remove = async (values) => {
  let header = headerAddToken();
  return API.post(`/wealthmanager/companies/file/remove`, values, header);
};

const pending_company_user_delete = async (values) => {
  let header = headerAddToken();
  let { comp_id, request_id } = values;
  return API.delete(
    `/wealthmanager/companies/pending_company_user_delete/${comp_id}/${request_id}`,
    header
  );
};

const wealth_submission_history_add = async (values) => {
  let header = await headerAddToken();
  return API.post("/wealthmanager/submission/add", values, header);
};

const wealth_submission_history_list = async (values) => {
  let header = await headerAddToken();
  return API.get(
    `/wealthmanager/submission/findAll/${values.user_id}/${values.wm_id}`,
    header
  );
};

const wealth_submission_history_delete = async (values) => {
  let header = await headerAddToken();
  return API.post("/wealthmanager/submission/delete", values, header);
};

const comomon_partner_delete = async (values) => {
  let header = await headerAddToken();
  const { commonPartnerDelete ,delete_from} = values;
  const { comp_id, request_id, check_status, wm_id } = commonPartnerDelete;
  console.log(values)
  return API.delete(
    `/wealthmanager/companies/pending_company_user_delete/${comp_id}/${request_id}/${check_status}/${wm_id}/${delete_from}`,
    // values,
    header
  );
};

const wealth_submission_history_update = async (values) => {
  let header = await headerAddToken();
  return API.post("/wealthmanager/submission/update", values, header);
};

const wealth_submission_history_findone = async (values) => {
  let header = await headerAddToken();
  return API.post("/wealthmanager/submission/findone", values, header);
};

const wealth_manager_service = {
  wmmfaEmailUpdateRecord,
  wealth_managers_findone,
  wealth_managers_update,
  wealth_managers_image_update,
  wealth_managers_mfaPhoneUpdateRecord,
  wealth_managers_mfaPhoneAuthPin,
  wealth_managers_findall,
  wm_setreset_phone_mfa,
  wmChangePhoneNumber,
  // wealth_companies_add,
  // wealth_companies_update,
  // wealth_companies_add_partner,
  // wealth_companies_list,
  // wealth_companies_remove,
  // wealth_companies_count,
  // wealth_companies_partners,
  // wealth_manager_decrypt,
  // wealth_manager_fetchIndividuals,
  // wealth_manager_fetchIndividuals_count,
  // wealth_individuals_delete,
  // company_user_delete,
  // wealth_individuals_exist,
  // wealth_find_search,
  // wealth_manage_files,
  wealth_manage_files_remove,
  pending_company_user_delete,
  wealth_submission_history_add,
  wealth_submission_history_list,
  wealth_submission_history_update,
  wealth_submission_history_delete,
  wealth_submission_history_findone,
  comomon_partner_delete,
  wealth_managers_avtar_update
};
export default wealth_manager_service;
