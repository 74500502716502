import React, { useState } from "react";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
// import deleteicon from "../../../../assets/icons/svg/outlined/delete-outlined.svg";
// import uploadIconset from "../../../../assets/icons/svg/outlined/upload-outlined.svg";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";

export default function ExternalAttachments({
  addFields,
  inputFields,
  attachmentUpload,
  changeDocumentInputVal,
  ext_attachment,
  removeInputField,
}) {
  const [documentName, setDocumentName] = useState("");
  const [comments, setComments] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const handleDocumentNameChange = (e) => {
    const inputValue = e.target.value;
    setDocumentName(inputValue);
  };
  const handleCommentsChange = (e) => {
    const inputValue = e.target.value;
    setComments(inputValue);
  };
  const handleFileUpload = (e) => {
    changeDocumentInputVal(e.target.files);
    setIsFileUploaded(e.target.files && e.target.files.length > 0);
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <h2 className="pfs-h2-title default-regular-h2 cs-neutral-100">
              Other Attachments
            </h2>
            {/* <div>
            </div> */}
          </Col>
        </Row>
        <div className="other-attachment-container">
          <Row>
            <Col lg={6} md={6}>
              <div className="attachment-row">
                <Row>
                  <Col lg={5}>
                    <Form.Group className="cs-form-group">
                      <Form.Label>Document Name</Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        placeholder="Optional"
                        name="Document Name"
                        // onChange={(e) =>
                        //   changeDocumentInputVal(e.target.value, "name", i)
                        // }
                        // value={itr.statement}
                        className={documentName ? "cs-input-field-active" : ""}
                        onChange={handleDocumentNameChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={5}>
                    <Form.Group className="cs-form-group">
                      <Form.Label>Upload Files</Form.Label>
                      <div className="cs-choose-file">
                        <div
                          className="cs-choose-file-box"
                          title={
                            (ext_attachment.length && ext_attachment[0].name) ||
                            "Choose file"
                          }
                        >
                          <Form.Control
                            type="file"
                            accept="image/*"
                            // onChange={(e) =>
                            //   changeDocumentInputVal(e.target.files)

                            // }
                            onChange={handleFileUpload}
                            className={
                              isFileUploaded ? "cs-input-field-active" : ""
                            }
                          />
                          <span className="cs-icon cs-neutral-10">
                            <CapsyncIcon title="upload-outlined" size="18" />
                          </span>
                        </div>
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Button
                      className="cs-btn-primary md-btn default-regular-h5"
                      onClick={attachmentUpload}
                    >
                      Upload
                    </Button>
                  </Col>
                </Row>
                <>
                  <Row>
                    <Col lg={12}>
                      <div className="attachment-files cs-files">
                        {ext_attachment.map((itr, i) => {
                          return (
                            <div className="cs-selected-files">
                              <div className="cs-fileicon cs-neutral-100">
                                <span className="cs-icon cs-neutral-10">
                                  <CapsyncIcon
                                    title="file-icon-outlined"
                                    size="18"
                                  />
                                </span>
                              </div>
                              <div className="cs-files-names">
                                <span className="download-filename default-regular-sub-heading-xs cs-neutral-100">
                                  {itr.name}
                                </span>
                              </div>
                              <div
                                className="cs-file-icon icons"
                                onClick={() => removeInputField(i)}
                              >
                                <span className="cs-icon-badge-danger cs-danger cursor-pointer">
                                  <CapsyncIcon
                                    title="delete-outlined"
                                    size="18"
                                  />
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                </>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="attachment-row attachment-comments-box">
                <Form.Group className="cs-form-group">
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Optional"
                    // className="cs-textarea"
                    // className={documentName ? 'cs-input-field-active' : ''}
                    className={`cs-textarea ${
                      comments ? "cs-input-field-active" : ""
                    } `}
                    onChange={handleCommentsChange}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
