import React from "react";

import { Button, Image, Modal } from "react-bootstrap";
import { toast } from "react-toastify";

// COMPONENT IMPORTS
import ErrorValidation from "../../../../assets/images/other/error-validation.svg";

// API
import { fileDownload, fileSizeCalc } from "../../../util/common";

// HOOKS
import UseDownload from "../../../customHooks/UseDownload";

const DownloadConfirmationModal = ({
  downloadConfirmationModalOpen,
  closeModal,
  downloadItem,
  categoryItem = null,
  isBigFile = false,
}) => {
  const onSubmit = () => {
    if (categoryItem) {
      UseDownload(
        `${categoryItem.url}/api/${categoryItem.main_category}/${categoryItem.category}/file_download`,
        categoryItem.params,
        categoryItem.categoryItemId,
        () => toast.success("Your file has been downloaded successfully.")
      );
      closeModal();
    } else {
      fileDownload(downloadItem[0]);
      closeModal();
    }
  };

  return (
    <Modal
      show={downloadConfirmationModalOpen}
      onHide={closeModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal manage-files file-management-modal"
      className="cs-common-modal-overlay"
      // backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          {isBigFile ? "Can't scan file for viruses" : "Are you sure?"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delete-modal-content">
          <Image
            src={ErrorValidation}
            alt="error-validation"
            className="action-icon"
          />
          {isBigFile ? (
            <p className="default-light-body-text-m cs-neutral-90">
              <strong>"{downloadItem[0].name}"</strong> (
              {fileSizeCalc(downloadItem[0].file_size)}) exceeds the maximum
              file size that we can scan. This file might harm your computer, so
              only download this file if you understand the risks.
            </p>
          ) : (
            <React.Fragment>
              <p className="default-light-body-text-m cs-neutral-90">
                Are you sure you want to download this item?
              </p>
              <p className="default-light-body-text-m cs-neutral-90">
                <strong>"{downloadItem[0].name}"</strong> is infected with a
                virus.
              </p>
            </React.Fragment>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="cs-modal-btn">
        <div className="cs-modal-btn cs-center-btn">
          <Button
            variant="secondary"
            className="cs-btn-secondary md-btn default-regular-h5"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            className="cs-btn-primary md-btn default-regular-h5"
            onClick={() => onSubmit()}
          >
            {isBigFile ? "Download anyway" : "Download"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DownloadConfirmationModal;
