import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { clearMessage } from "../../slices/message";
import { resetpassword } from "../../slices/auth";
import LeftPart from "./LeftPart";
import LogoImage from "../logoImage";
import { useFormik } from "formik";
import * as yup from "yup";
import BackToSignin from "./BackToSignin";
import CapsyncIcon from "../../commonComponent/CapsyncIcon";
import PasswordToolTip from "../../commonComponent/passwordTooltip/PasswordToolTip";
import AuthHead from "./AuthHead";
import { toast } from "react-toastify";
import CapsyncLoader from "./../../commonComponent/CapsyncLoader";
import PasswordRequirement from "../../commonComponent/PasswordRequirement";
import { uniValidation } from "../individual/liabilities/categoryForms/validationSchema";

const ResetPassword = (props) => {
  const [showPwd, setShowPwd] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validateError, setValidateError] = useState("");
  const [showPwdRequirements, setShowPwdRequirements] = useState(false);
  const { resetPasswordToken } = useParams();
  const navigate = useNavigate();
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(clearMessage());
  // }, [dispatch]);

  const schema = yup.object().shape({
    password: yup
      .string()
      .required(uniValidation.password.require).test("is-not-only-spaces", uniValidation.password.require, (value) => {
        return value.trim() !== "";
      })
      .matches(/^\S*$/, uniValidation.password.comman)
      .matches(/[0-9]/, uniValidation.password.comman)
      .matches(/[a-z]/, uniValidation.password.comman)
      .matches(/[A-Z]/, uniValidation.password.comman)
      .matches(/[^A-Za-z0-9]/, uniValidation.password.comman)
      .min(8, uniValidation.password.comman)
      .max(20, uniValidation.password.comman),
    confirmPassword: yup
      .string()
      .required(uniValidation.confirm_password.require).test("is-not-only-spaces",uniValidation.confirm_password.require, (value) => {
        return value.trim() !== "";
      })
      .matches(/^\S*$/, uniValidation.confirm_password.comman)
      .oneOf([yup.ref("password"), null], uniValidation.confirm_password.match)
      .max(20, uniValidation.confirm_password.comman),
  });

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: schema,
      onSubmit: async (formValue) => {
        dispatch(clearMessage());
        setLoading(true);
        formValue.resetPasswordToken = resetPasswordToken;
        dispatch(resetpassword(formValue))
          .unwrap()
          .then((response) => {
            if (response.code === 200) {
              toast.success("Your password has been updated successfully.");
              props.CapsyncMessage(
                "Success",
                "Password reset successfully!",
                "success"
              );
              setTimeout(() => {
                navigate("/");
                window.location.reload(true);
              }, 1000);
            } else {
              setValidateError(response.message);
              setLoading(false);
            }
          })
          .catch((e) => {
            setLoading(false);
          });
      },
    });

  const errorMessage = () => {
    dispatch(clearMessage());
    setValidateError("");
  };

  return (
    <section className="auth-section">
      {loading && <CapsyncLoader />}

      <Container fluid>
        <Row className="align-items-center">
          <Col lg={6}>
            <LeftPart
              page="resetpassword"
              img="auth-image.svg"
              title="Your New Immersive Digital Finance Hub."
              text="Sync your real-time digital Personal Financial Statement with CapSync."
            />
          </Col>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <div className="auth-right-section">
                  {/* <LogoImage /> */}
                  <AuthHead title="Reset password" />

                  <div className="cs-auth-form">
                    {/* <h2 className="default-semi-bold-h1">Reset Password</h2>
                    <hr className="head-underline" /> */}
                    {/* <span className="default-regular-sub-heading-s cs-neutral-80">Create a new password</span> */}
                    <Form
                      onSubmit={handleSubmit}
                      className="auth-form initial-reset-password"
                    >
                      <Row>
                        <Col lg={12}>
                          <Form.Group
                            className={`cs-form-group ${showPwdRequirements && "last-password-feild"
                              }`}
                          >
                            <Form.Control
                              type={showPwd ? "text" : "password"}
                              placeholder="New password"
                              autoComplete="off"
                              name="password"
                              onKeyDown={(e) => {
                                if (e.key === " " && e.target.selectionStart === 0) {
                                  e.preventDefault();
                                }
                              }}
                              value={values.password}
                              className={
                                values.password && "cs-input-field-active"
                              }
                              maxLength={20}
                              onChange={handleChange}
                              onBlur={() => setShowPwdRequirements(false)}
                              onFocus={() => setShowPwdRequirements(true)}
                            // onKeyDown={errorMessage}
                            />
                            <span
                              onClick={(e) => {
                                setShowPwd(!showPwd);
                              }}
                              onMouseDown={(e) => {
                                e.preventDefault();
                              }}
                              className="input-field-icon cursor-pointer cs-neutral-80"
                            >
                              {showPwd ? (
                                <CapsyncIcon
                                  title="show-password-outlined"
                                  size="18"
                                />
                              ) : (
                                <CapsyncIcon
                                  title="hide-password-outlined"
                                  size="18"
                                />
                              )}
                            </span>
                            {!showPwdRequirements &&
                              errors.password &&
                              touched.password ? (
                              <span className="form-error-msg default-light-body-text-s cs-danger">
                                {errors.password}
                              </span>
                            ) : null}
                          </Form.Group>
                          {showPwdRequirements && (
                            <PasswordRequirement password={values.password} />
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <Form.Group className="cs-form-group">
                            <Form.Control
                              type={showConfirmPwd ? "text" : "password"}
                              placeholder="Confirm password"
                              autoComplete="off"
                              name="confirmPassword"
                              onKeyDown={(e) => {
                                if (e.key === " " && e.target.selectionStart === 0) {
                                  e.preventDefault();
                                }
                              }}

                              value={values.confirmPassword}
                              className={
                                values.confirmPassword &&
                                "cs-input-field-active"
                              }
                              maxLength={20}
                              onChange={handleChange}
                            // onBlur={handleBlur}
                            // onKeyDown={errorMessage}
                            />
                            {errors.confirmPassword &&
                              touched.confirmPassword ? (
                              <span className="form-error-msg default-light-body-text-s cs-danger">
                                {errors.confirmPassword}
                              </span>
                            ) : null}
                            <span
                              onClick={() => setShowConfirmPwd(!showConfirmPwd)}
                              className="input-field-icon cursor-pointer cs-neutral-80"
                            >
                              {showConfirmPwd ? (
                                <CapsyncIcon
                                  title="show-password-outlined"
                                  size="18"
                                />
                              ) : (
                                <CapsyncIcon
                                  title="hide-password-outlined"
                                  size="18"
                                />
                              )}
                            </span>
                          </Form.Group>
                        </Col>
                      </Row>

                      {validateError && (
                        <div className="cs-msg default-regular-body-text-s cs-danger">
                          <span className="icon">
                            <CapsyncIcon title="info-filled" />
                          </span>
                          <span className="formik-form-error">
                            {validateError}
                          </span>
                        </div>
                      )}

                      {message && (
                        <div className="cs-msg default-light-body-text-m cs-danger">
                          <span className="icon">
                            <CapsyncIcon title="declined-outlined" />
                          </span>
                          <span className="txt">
                            {message.message || message}
                          </span>
                        </div>
                      )}

                      <Button
                        type="submit"
                        className="cs-btn-primary lg-btn default-regular-h5"
                        disabled={loading}
                      >
                        Reset password
                      </Button>
                      <div>
                        <BackToSignin />
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ResetPassword;
