import React, { useState } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import deleteIcon from "../../../../assets/images/other/delete-validation.svg";
import successIcon from "../../../../assets/images/other/success-validation.svg";
import "../deleteModal/DeleteModal.css";

const DeleteModal = ({ deleteStatus, CapsyncDeleteMessage }) => {
  const [checkCard, setCheckCard] = useState(false);
  const confirmDeletion = () => {
    deleteStatus.custom();
    CapsyncDeleteMessage(false, "", "", "");
    setCheckCard(!checkCard);
  };

  const confirmNotDeletion = () => {
    CapsyncDeleteMessage(false, "", "", "");
  };

  return (
    <>
      <Modal
        show={deleteStatus.show}
        onHide={confirmNotDeletion}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal cs-delete-modal"
        className="cs-common-modal-overlay"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            {deleteStatus.message === "DeleteUpgrade"
              ? "Upgrade plan"
              : deleteStatus.message === "DeleteDowngrade"
                ? "Downgrade plan"
                : "Are you sure?"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-modal-content">
            <Image
              src={(deleteStatus.message === "DeleteUpgrade" || deleteStatus.message === "DeleteDowngrade") ? successIcon : deleteIcon}
              alt="popup_delete"
              className="action-icon"
            />
            <p className="default-light-body-text-m cs-neutral-90">
              {deleteStatus.title === ""
                ? "Are you sure you want to delete this item? Once deleted, this action cannot be reversed."
                : deleteStatus.title}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="cs-modal-btn cs-center-btn">
            <Button
              onClick={confirmNotDeletion}
              className="cs-btn-secondary md-btn default-regular-h5"
            >
              Cancel
            </Button>
            <Button
              className="cs-btn-primary md-btn default-regular-h5"
              onClick={confirmDeletion}
            >
              {deleteStatus.message === "DeleteUpgrade" ? "Upgrade" : deleteStatus.message === "DeleteDowngrade" ? "Downgrade" : "Delete"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteModal;
