import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { DecryptReact } from "../../customHooks/EncryptDecryptReact";
import LeftPart from "./LeftPart";
import BackToSignin from "./BackToSignin";
import LogoImage from "../logoImage";

const ManageUserStatus = () => {
  return (
    <>
      <section className="auth-section">
        <Container fluid>
          <Row className="align-items-center">
            <Col lg={6}>
              <LeftPart
                page="invitation-response"
                img="auth-image.svg"
                title="Your New Immersive Digital Finance Hub."
                text="Sync your real-time digital Personal Financial Statement with CapSync."
              />
            </Col>
            <Col lg={6}>
              <Row>
                <Col>
                  <div className="auth-right-section">
                    <LogoImage />
                    <div className="cs-auth-form">
                      <h2 className="default-semi-bold-h2 cs-neutral-100">
                        {DecryptReact(
                          window.localStorage.getItem("manage_action")
                        )}
                      </h2>
                      <BackToSignin isNewUser={true} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ManageUserStatus;
