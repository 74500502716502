import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { localDataRemove } from "../config/finance";
import { headerAddToken } from "../util/common";
import API from "../util/api";

export const getAllAuditData = createAsyncThunk(
  "audit/findall/",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.get(
        `audit/findall/${values.id}/${values.module}`,
        header
      );
      thunkAPI.dispatch(setMessage(response.data));
      return response;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const wealth_manager_getAllAuditData = createAsyncThunk(
  "audit/findAll/",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.get(
        `wealthmanager/findAll/${values.id}/${values.email}`,
        header
      );
      thunkAPI.dispatch(setMessage(response.data));
      return response;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const wealth_manager_individual_getAllAuditData = createAsyncThunk(
  "audit/findall/",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.get(
        `wealthmanager/findAll/${values.id}/${values.email}/${values.from_id}`,
        header
      );
      thunkAPI.dispatch(setMessage(response.data));
      return response;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const wmAuditLogs = createAsyncThunk(
  "/audit/fetchauditlogs",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post("/audit/fetchauditlogs", values, header);
      thunkAPI.dispatch(setMessage(response.data));
      return response;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const add_audit_log = createAsyncThunk(
  "/audit/add_audit_log",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post("/audit/add_audit_log", values, header);
      thunkAPI.dispatch(setMessage(response.data));
      return response;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

const initialState = {
  auditLogs: [],
};

const auditLogsSlice = createSlice({
  name: "auditLogs",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(wmAuditLogs.fulfilled, (state, action) => {
      state.auditLogs = action.payload.data.res_data;
    });
  },
});
const { reducer } = auditLogsSlice;
export default reducer;
