import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const SkeletonMethodsDashboard = (props) => {
    return (
        <>
            <div className="cs-section-topbar">
                <div className='amortization-skeleton-breadcrumb'>
                    <div className='skeleton-breadcrumb-1'> <Skeleton width={50} /></div>
                    <div className='skeleton-breadcrumb-2'> <Skeleton width={20} /></div>
                    <div className='skeleton-breadcrumb-3'><Skeleton width={150} /></div>
                </div>
                <div className="cs-title">
                    <h1 className="default-semi-bold-h1 cs-neutral-100"><Skeleton width={200} /></h1>
                </div>
            </div>
            <div className="accredited-title">
                <h3 className="default-semi-bold-h3 cs-neutral-90">
                    <Skeleton width={170} />
                </h3>
                <h4 className="default-regular-h4 cs-neutral-80">
                    <Skeleton width={200} />
                </h4>
            </div>
            <div className="investor-type-row dropdown cs-form-dropdown dropdown">
                <Row>
                    <Col lg={3}>
                        <Skeleton width={130} />
                        <Skeleton width={200} height={40} />
                    </Col>
                </Row>

                <div className="accredited-tabbing-row default-regular-body-text-l cs-neutral-80">
                    <h3 className="default-semi-bold-h3 cs-neutral-90"> <Skeleton width={120} /></h3>
                    <p><Skeleton width={200} /></p>
                </div>
                {props.investorType === "individualMethod" && (
                    <div className='skeleton-manage-container for-des'>
                        <div className="skeleton-tabs-container">
                            <h2 className="skeleton-tab-1"><Skeleton width={200} /></h2>
                            <h2 className="skeleton-tab-2"><Skeleton width={200} /></h2>
                            <h2 className="skeleton-tab-2"><Skeleton width={200} /></h2>
                        </div>
                    </div>
                )}
                {props.investorType === "individualMethod" && (
                    <div className='skeleton-manage-container for-mob'>
                        <div className="skeleton-tabs-container">
                            <h2 className="skeleton-tab-1"><Skeleton width={95} height={30} /></h2>
                            <h2 className="skeleton-tab-2"><Skeleton width={95} height={30} /></h2>
                            <h2 className="skeleton-tab-2"><Skeleton width={95} height={30} /></h2>
                        </div>
                    </div>
                )}
                <Skeleton width={200} />
            </div>
            <div className="verification-method-box">
                <div className="accredited-method">
                    <Row>
                        <Col lg={12}>
                            <Skeleton width={200} />
                        </Col>
                    </Row>
                </div>
                <div className="vi-innerArea for-des">
                    {Array(7).fill(0).map((item) => (
                        <Row>
                            <Col xl={11} md={9}>
                                <div className="default-regular-sub-heading-m cs-neutral-90"><Skeleton width={500} /></div>
                            </Col>
                            <Col xl={1} md={3} >
                                <Skeleton width={100} height={40} />
                            </Col>
                            <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50"><Skeleton width={10} /></span></div>
                        </Row>
                    ))}
                </div>
                <div className="vi-innerArea for-mob">
                    {Array(7).fill(0).map((item) => (
                        <Row>
                            <Col xl={11} md={9}>
                                <div className="default-regular-sub-heading-m cs-neutral-90"><Skeleton width="100%" height={80} /></div>
                            </Col>
                            <Col xl={1} md={3} >
                                <Skeleton width="100%" height={30} />
                            </Col>
                            <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50"><Skeleton width={10} /></span></div>
                        </Row>
                    ))}
                </div>
            </div>
            <p className="default-regular-sub-heading-m cs-neutral-80">
                <Skeleton width={200} />
            </p>
        </>
    )
}

export default SkeletonMethodsDashboard
