import React from "react";
import { useLocation } from "react-router-dom";

const SubMenuHeader = () => {
  const location = useLocation();
  const pathname = location.pathname.split("/");

  return (
    <>
      <div className="investor-accreditation-description">
        <h2 className="default-semi-bold-h1 cs-neutral-100 ">
          { pathname && pathname[4] === "individuals" || pathname[4] === "entities" || pathname[4] === "trusts" 
            ? "Accredited Investor Verification"
            : pathname[4] === "qualifiedpurchaser"
            ? "Qualified Purchaser Verification"
            : "Qualified Client Verification"}
        </h2>
      </div>
    </>
  );
};

export default SubMenuHeader;
