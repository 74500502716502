import axios from "axios";
import moment from "moment/moment";
import FileDownload from "js-file-download";
import moment_timezone from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Dropdown, Image } from "react-bootstrap";

import {
  quickBooksArr,
  quickBooksMainLevelArr,
  quickBooksReportPeriodFilterArr,
} from "../../config/finance";
import Categories from "./Categories";
import { quickBooksFetchCategoryData } from "../../slices/quickbooks.slice";
import CapsyncBreadcrumb from "../CapsyncBreadcrumb";
import QuickBooksFilters from "./QuickBooksFilters";
import CapsyncIcon from "../CapsyncIcon";
import { backAppURl } from "../../applicationMode";
import QuickBooksModal from "./QuickBooksModal";
import ShareReportModal from "./ShareReportModal";
import SkeletonQuickBook from "./SkeletonQuickBook";
import QuickbooksIcon from "../../../assets/images/other/Intuit_QuickBooks_logo.svg";
import { quickbooksReportDownload } from "../../util/common";
import { toast } from "react-toastify";

function CardComponent({ data, fetchType, currentCategory }) {
  return (
    <React.Fragment key={data.title}>
      <Col md={3} lg={3}>
        <div
          className={`category-card cs-qbo-card ${currentCategory === data.type && "cs-qbo-active"
            }`}
        >
          <div className="card" onClick={fetchType}>
            <div className="card-icon">
              <span>
                <CapsyncIcon title={data.img_name} size="38" />
              </span>
            </div>
            <div className="card-item">
              <span className="default-semi-bold-h2 cs-neutral-100">
                {data.title}
              </span>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
}

const QuickBooksReports = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { business_id } = useParams();
  const { user } = useSelector((state) => state.auth);
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const [currentCategory, setCategory] = useState(quickBooksArr[0].type);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [quickBooksModalShow, setQuickBooksModalShow] = useState(false);
  const [quickbooksDataCounter, setQuickbooksDataCounter] = useState(0);
  const [downloadButton, setDownloadButton] = useState(false);
  const [shareModalShow, setShareModalShow] = useState(false);
  const [anotherPeriod, setAnotherPeriod] = useState({
    profit_loss: [],
    balance_sheet: [],
    cash_flow: [],
    trial_balance: [],
  });
  const [quickBooksData, setQuickBooksData] = useState({
    data: [],
    heading: [],
    main_header: [],
    trial_balance: [],
  });
  const [dataCounter, setDataCounter] = useState({
    profit_loss: [],
    balance_sheet: [],
    cash_flow: [],
    trial_balance: [],
  });
  const [summarizeColumnBy, setSummarizeColumnBy] = useState("");
  const [conditionalSummarizeCoulmn, setConditionalSummarizeCoulmn] = useState({
    profit_loss: "",
    balance_sheet: "",
    cash_flow: "",
    trial_balance: "",
  });
  const [customDate, setCustomDate] = useState({
    profit_loss: {
      start_date: moment_timezone()
        .tz("America/New_York")
        .startOf("year")
        .format("YYYY-MM-DD"),
      end_date: moment_timezone().tz("America/New_York").format("YYYY-MM-DD"),
    },
    balance_sheet: {
      start_date: moment_timezone()
        .tz("America/New_York")
        .startOf("year")
        .format("YYYY-MM-DD"),
      end_date: moment_timezone().tz("America/New_York").format("YYYY-MM-DD"),
    },
    cash_flow: {
      start_date: moment_timezone()
        .tz("America/New_York")
        .startOf("year")
        .format("YYYY-MM-DD"),
      end_date: moment_timezone().tz("America/New_York").format("YYYY-MM-DD"),
    },
    trial_balance: {
      start_date: moment_timezone()
        .tz("America/New_York")
        .startOf("year")
        .format("YYYY-MM-DD"),
      end_date: moment_timezone().tz("America/New_York").format("YYYY-MM-DD"),
    },
  });
  const [headerDate, setHeaderDate] = useState({
    profit_loss: {},
    balance_sheet: {},
    cash_flow: {},
    trial_balance: {},
  });

  const [isLoader, setIsLoader] = useState(false);
  const [categoryHeaderLoader, setCategoryHeaderLoader] = useState(false);

  const [reportFilter, setReportFilter] = useState({
    profit_loss: {},
    balance_sheet: {},
    cash_flow: {},
    trial_balance: {},
  });

  useEffect(() => {
    fetchType(currentCategory);
  }, []);

  function changeQuickBookModal(params, business_id) {
    setQuickBooksModalShow(params);
    if (business_id === 0) {
      window.localStorage.removeItem("business_id");
    } else {
      window.localStorage.setItem("business_id", business_id);
    }
  }

  function fetchType(params) {
    if (params !== selectedCategory) {
      setSelectedCategory(params);
      setCategoryHeaderLoader(true);
      setIsLoader(true);
      dispatch(
        quickBooksFetchCategoryData({
          user_id: id,
          business_id: Number(business_id),
          type: params,
        })
      )
        .unwrap()
        .then((response) => {
          if (response.status === 200) {
            // window.localStorage.removeItem("quickbooks_redirect");
            console.log("quickBooksFetchCategoryData:- ", response);
            setQuickbooksDataCounter(response.dataCounter);
            setCompanyName(response.companyName);
            setCategory(params);
            setCategoryHeaderLoader(false);
            setIsLoader(false);
            setQuickBooksData(response);
          } else {
            navigate("/individual/assets/business");
          }
        })
        .catch((err) => {
          console.log("fetchType:- ", err);
          return false;
        });
    }
  }

  const downloadReport = (type) => {
    if (type === "excel") {
      setDownloadButton(true);
      axios({
        url: `${backAppURl}/api/quickbooks/excel/download`,
        method: "POST",
        data: {
          business_id: Number(business_id),
          user_id: id,
          type: currentCategory,
        },
        responseType: "blob",
      })
        .then((response) => {
          setDownloadButton(false);
          quickbooksReportDownload(response.data, `${currentCategory}`, type)
          toast.success("You have successfully downloaded the Excel report.");
        })
        .catch((err) => {
          toast.error("Failed to download the Excel report.");
          console.log("downloadReport:- ", err);
        });
    } else {
      axios({
        url: `${backAppURl}/api/quickbooks/pdf/download`,
        method: "POST",
        data: {
          business_id: Number(business_id),
          user_id: id,
          type: currentCategory,
          summary: summarizeColumnBy,
        },
        responseType: "blob",
      })
        .then((response) => {
          setDownloadButton(false);
          quickbooksReportDownload(response.data, `${currentCategory}`, type)
          toast.success("You have successfully downloaded the PDF report.");
        })
        .catch((err) => {
          toast.error("Failed to download the PDF report.");
          console.log("downloadReport:- ", err);
        });
    }
  };

  const shareReport = (params) => {
    setShareModalShow(params);
  };

  let startAndEndDate =
    moment(headerDate[currentCategory].start_date).format("MMM DD, YYYY") +
    " - " +
    moment(headerDate[currentCategory].end_date).format("MMM DD, YYYY");

  return (
    <section className="transaction-main-section report-main-section">
      <Container fluid>
        {categoryHeaderLoader ? (
          <SkeletonQuickBook />
        ) : (
          <>
            <div className="cs-section-topbar">
              <CapsyncBreadcrumb
                mainTitle="Assets"
                subTitle="Reports"
                multiLevelFlag="true"
                mainLevelArr={quickBooksMainLevelArr}
              />
              <div className="cs-title">
                <h1 className="default-semi-bold-h1 cs-neutral-100">Reports</h1>
                <div className="qbo-disconnect">
                  <Button
                    className="default-regular-h5 cs-btn-icon-tertiary"
                    onClick={() =>
                      changeQuickBookModal(true, Number(business_id))
                    }
                  >
                    <span className="cs-primary">
                      <CapsyncIcon title="unlink-outlined" size="18" />
                    </span>
                    Disconnect
                  </Button>
                  <Image
                    width="88"
                    height="40"
                    src={QuickbooksIcon}
                    alt="QuickbooksIcon"
                  />
                </div>
              </div>
            </div>
            <Row>
              {quickBooksArr.map((itr, i) => {
                return (
                  <CardComponent
                    data={itr}
                    fetchType={() => fetchType(itr.type)}
                    currentCategory={currentCategory}
                  />
                );
              })}
            </Row>

            <QuickBooksFilters
              currentCategory={currentCategory}
              setQuickBooksData={setQuickBooksData}
              customDate={customDate}
              setCustomDate={setCustomDate}
              setHeaderDate={setHeaderDate}
              setIsLoader={setIsLoader}
              anotherPeriod={anotherPeriod}
              setAnotherPeriod={setAnotherPeriod}
              setDataCounter={setDataCounter}
              setConditionalSummarizeCoulmn={setConditionalSummarizeCoulmn}
              setQuickbooksDataCounter={setQuickbooksDataCounter}
              setSummarizeColumnBy={setSummarizeColumnBy}
              summarizeColumnBy={summarizeColumnBy}
            />
          </>
        )}

        {isLoader ? (
          <span className="cs-common-spinner cs-primary-main m-auto">
            <CapsyncIcon title="loading-outlined" size="96" />
          </span>
        ) : (
          <div
            className={`report-canvas ${quickBooksData.heading.length > 3 && "cs-qbo-full-width-container"
              }`}
          >
            <div className="report-top-bar">
              <button
                className="cs-btn-icon-primary"
                onClick={() => shareReport(true)}
              >
                <span className="cs-neutral-80">
                  <CapsyncIcon title="share-outlined" size="22" />
                </span>
              </button>

              <div className="cs-common-add-dropdown qbo-download-dropdown">
                <Dropdown className="qbo-drop-down">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <button
                      className="cs-btn-icon-primary"
                      disabled={downloadButton}
                    >
                      <span className="cs-neutral-80">
                        <CapsyncIcon title="download-outlined" size="22" />
                      </span>
                    </button>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => downloadReport("excel")}>
                      Export to Excel
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => downloadReport("pdf")}>
                      Export to PDF
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="report-container">
              <div className="title text-center">
                <h2>{companyName}</h2>

                <h3 className="default-semi-bold-h3 cs-neutral-100">
                  {
                    quickBooksArr.filter((x) => x.type === currentCategory)[0]
                      .title
                  }
                </h3>
                <span className="default-light-body-text-xl cs-neutral-80">
                  {headerDate[currentCategory].date_macro
                    ? quickBooksReportPeriodFilterArr[0]
                    : currentCategory === "trial_balance" ||
                      currentCategory === "balance_sheet"
                      ? "As of " +
                      moment(
                        headerDate[currentCategory].end_date,
                        "YYYY-MM-DD"
                      ).format("MMMM D, YYYY")
                      : moment(headerDate[currentCategory].start_date).format(
                        "YYYY-MM-DD"
                      ) ===
                        moment(headerDate[currentCategory].end_date).format(
                          "YYYY-MM-DD"
                        )
                        ? moment(
                          headerDate[currentCategory].start_date,
                          "YYYY-MM-DD"
                        ).format("MMMM D, YYYY")
                        : moment(
                          headerDate[currentCategory].start_date,
                          "YYYY-MM-DD"
                        ).format("MMMM D, YYYY") +
                        " - " +
                        moment(
                          headerDate[currentCategory].end_date,
                          "YYYY-MM-DD"
                        ).format("MMMM D, YYYY")}
                </span>
              </div>
              {quickbooksDataCounter === 0 ? (
                <span className="default-light-body-text-m qbo-no-record">
                  This report does not contain any data.
                </span>
              ) : (
                <Categories
                  category={currentCategory}
                  quickBooksData={quickBooksData}
                  anotherPeriod={anotherPeriod}
                  dataCounter={dataCounter}
                  conditionalSummarizeCoulmn={conditionalSummarizeCoulmn}
                />
              )}
            </div>
          </div>
        )}
      </Container>
      <QuickBooksModal
        show={quickBooksModalShow}
        modalState={changeQuickBookModal}
        connect={false}
      />
      <ShareReportModal
        show={shareModalShow}
        modalState={shareReport}
        category={currentCategory}
        companyName={companyName}
      />
    </section>
  );
};

export default QuickBooksReports;
