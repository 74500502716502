/* eslint-disable */
import React, { useCallback, useState } from "react";
import { Modal, Form, Row, Col, Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import DatePicker from "react-date-picker";
import NumberFormat from "react-currency-format";
import { useFormik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";

import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import validationObj from "./validationSchema";
import { adjustLength } from "../../../../customHooks/adjustLength";
import {
  categoryAdd,
  categoryUpdateRecord,
} from "../../../../slices/category.slice";
import commonNames from "../../../../config/commonNames";
import { useEffect } from "react";
import { validateDuplicateFileName } from "../../../../config/validateDuplicateFileName";
import {
  get_user_storage,
  useProgressUploadDetails,
  useUploadStorage,
} from "../../../../slices/fileManagement.slice";

const OtherRetirement = (props) => {
  const { onFormClose, onFormSubmit, record, showModal } = props;
  const storageData = useUploadStorage();
  const progressUploadDetails = useProgressUploadDetails();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const retireType = [
    "Individual Retirement Account (IRA)",
    "Roth IRA",
    "401(k) Plan",
    "SIMPLE 401(k) Plan",
    "403(b) Plan",
    "SIMPLE IRA Plan (Savings Incentive Match Plans for Employees)",
    "SEP Plan (Simplified Employee Pension)",
    "SARSEP Plan (Salary Reduction Simplified Employee Pension)",
    "Payroll Deduction IRA",
    "Profit-Sharing Plan",
    "Defined Benefit Plan",
    "Money Purchase Plan",
    "Employee Stock Ownership Plan (ESOPs)",
    "Governmental Plan",
    "457 Plan",
    "Other",
  ];

  const [loading, setLoading] = useState(false);
  const [storeAcceptedFiles, setStoreAcceptedFiles] = useState([]);
  const [type, setType] = useState(retireType[0]);
  // const [type, setType] = useState("");
  const [initialValues, setInitialValues] = useState({
    user_id: id,
    institution: "",
    balance: "",
    loan_on_balance: "0",
    total: "",
    date_updated: moment().format(),
  });
  const [duplicateFileError, setDuplicateFileError] = useState("");
  const [storageError, setStorageError] = useState("");

  const checkType = (event) => {
    setType(event);
  };

  function checkValueType(val) {
    if (typeof val == "string") {
      return Number(val.replace(/,/g, ""));
    } else {
      return val;
    }
  }

  useEffect(() => {
    if (!!record) {
      setInitialValues({
        user_id: record.user_id,
        institution: record.institution,
        balance: record.balance && record.balance.toLocaleString("en-US"),
        loan_on_balance:
          record.loan_on_balance &&
          record.loan_on_balance.toLocaleString("en-US"),
        total: record.total && record.total.toLocaleString("en-US"),
        date_updated: new Date(
          moment(record.date_updated).format("MM/DD/YYYY")
        ),
        notes: record.notes == null ? "" : record.notes,
      });
      setType(record && record.type ? record.type : type);
    }
  }, [record]);

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: validationObj.asset_retirementAccount_validationSchema,
      onSubmit: async (values) => {
        setLoading(true);
        record && (values.id = record.id);
        values.user_id = id;
        values.pfs_access_id = id ? id : 0;
        values.type = type;
        const inProgressData = progressUploadDetails.length
          ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
          : 0;
        const used_space = storageData.usedSpace + inProgressData;

        const formdata = new FormData();
        formdata.append("user_details", JSON.stringify(values));
        formdata.append("user_id", values.user_id);
        formdata.append("used_space", used_space);
        formdata.append("institution", values.institution);
        for (let i = 0; i < storeAcceptedFiles.length; i++) {
          formdata.append("attachments", storeAcceptedFiles[i]);
        }
        let data = {
          category: "retirementaccount",
          main_category: "assets",
          used_space: used_space,
        };

        if (!!record) {
          data = { ...data, newData: values };
          dispatch(categoryUpdateRecord(data))
            .unwrap()
            .then((response) => {
              if (response.code === 200) {
                onFormSubmit("retirementaccount");
                toast.success(response.message);
                props.dashboardAPi();
              }
              setLoading(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
            });
        } else {
          data = { ...data, formdata: formdata };
          dispatch(categoryAdd(data))
            .unwrap()
            .then(async (response) => {
              if (response.code === 200) {
                const response = await dispatch(
                  get_user_storage({ id })
                ).unwrap();
                onFormSubmit("retirementaccount");
                // toast.success(response.message);
                props.dashboardAPi();
              } else if (response.code === 500) {
                setStorageError(response.message);
              }
              setLoading(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
            });
        }
      },
    });

  const handleChange = (e) => {
    setLoading(false);
    setFieldValue(e.target.name, e.target.value);
    setDuplicateFileError("");
  };

  // Upload File Handler
  const removeAcceptedFile = (params) => {
    let store = [...storeAcceptedFiles];
    store.splice(params, 1);
    setStoreAcceptedFiles(store);
    setDuplicateFileError("");
    setStorageError("");
  };
  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setDuplicateFileError("");
      setStorageError("");
      if (
        validateDuplicateFileName(
          acceptedFiles,
          storeAcceptedFiles,
          setDuplicateFileError
        )
      ) {
        setStoreAcceptedFiles([...acceptedFiles, ...storeAcceptedFiles]);
      }
    },
    [storeAcceptedFiles]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    disabled: loading,
  });
  const files = storeAcceptedFiles.map((file, i) =>
    adjustLength(file.path, () => removeAcceptedFile(i))
  );

  return (
    <Modal
      show={showModal}
      backdrop="static"
      id="connect-your-institutions"
      aria-labelledby="contained-modal-title-vcenter"
      className="cap-dash-main-modal"
      centered
      keyboard={false}
      dialogClassName="cs-lg-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          {commonNames.OtherRetirement}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <Row>
            <Col lg={12}>
              <Form.Group
                className={
                  record && record.api_provider === "plaid"
                    ? "cs-form-group cs-disabled"
                    : "cs-form-group"
                }
              >
                <Form.Label>Institution</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  name="institution"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.institution}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  className={values.institution && "cs-input-field-active"}
                  disabled={record && record.api_provider === "plaid"}
                />
                {errors.institution && touched.institution ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.institution}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <Form.Group
                className={
                  record && record.api_provider === "plaid"
                    ? "cs-form-group cs-disabled"
                    : "cs-form-group"
                }
              >
                <Form.Label>Type</Form.Label>
                <div className="cs-common-add-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle
                      disabled={record && record.api_provider === "plaid"}
                    >
                      {type === ""
                        ? "Individual Retirement Arrangements"
                        : type.length > 20
                        ? type.slice(0, 30) + "..."
                        : type}
                      <span className="cs-neutral-90">
                        <CapsyncIcon title="chevron-down-outlined" size="18" />
                      </span>
                    </Dropdown.Toggle>
                    {/* <Dropdown.Toggle disabled={record && record.api_provider === "plaid"}>
                                            {type === "" ? "Choose Type" : type.length > 20 ? type.slice(0, 30) + "..." : type}
                                            <span className="cs-neutral-90">
                                                <CapsyncIcon title="chevron-down-outlined" size="18" />
                                            </span>
                                        </Dropdown.Toggle> */}
                    <Dropdown.Menu>
                      {retireType.map((retireItr) => (
                        <Dropdown.Item
                          className={
                            retireItr === type ? "cs-selected-dropdown" : ""
                          }
                          key={retireItr}
                          onClick={() => {
                            setLoading(false);
                            checkType(retireItr);
                          }}
                        >
                          {retireItr}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group
                className={
                  record && record.api_provider === "plaid"
                    ? "cs-form-group cs-disabled"
                    : "cs-form-group"
                }
              >
                <Form.Label>Balance</Form.Label>
                <NumberFormat
                  className={
                    values.balance
                      ? "dollar-symbol text-md-end form-control cs-input-field-active"
                      : "text-md-end form-control"
                  }
                  thousandSeparator={true}
                  autoComplete="off"
                  placeholder=""
                  name="balance"
                  onKeyDown={(e) => {
                    if (e.key === "." && e.target.value === "") {
                      e.preventDefault(); // Prevent typing another dot if there's already one
                    }
                  }}
                  maxLength={12}
                  value={values.balance}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  // onKeyUp={(e) => {
                  //     setTotalBalanceRet(checkValueType(values.balance) - checkValueType(values.loan_on_balance))
                  // }}
                  allowNegative={false}
                  decimalScale={2}
                  disabled={record && record.api_provider === "plaid"}
                />
                {errors.balance && touched.balance ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.balance}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Loan on Balance</Form.Label>
                <NumberFormat
                  thousandSeparator={true}
                  autoComplete="off"
                  placeholder=""
                  name="loan_on_balance"
                  value={values.loan_on_balance}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  maxLength={12}
                  className={
                    values.loan_on_balance || values.loan_on_balance === 0
                      ? "dollar-symbol text-md-end form-control cs-input-field-active"
                      : "text-md-end form-control"
                  }
                  thousand
                  allowNegative={false}
                  decimalScale={2}

                  // onKeyUp={(e) => {
                  //     setTotalBalanceRet(checkValueType(values.balance) - checkValueType(values.loan_on_balance))
                  // }}
                />
                {errors.loan_on_balance && touched.loan_on_balance ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.loan_on_balance}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group cs-disabled">
                <Form.Label>Total Balance</Form.Label>
                <NumberFormat
                  disabled={true}
                  thousandSeparator={true}
                  decimalScale={2}
                  autoComplete="off"
                  placeholder=""
                  name="total"
                  className={
                    values.balance || values.loan_on_balance
                      ? "dollar-symbol text-md-end form-control cs-input-field-active"
                      : "text-md-end form-control"
                  }
                  value={
                    (values.total = values.balance
                      ? parseFloat(
                          checkValueType(values.balance) -
                            checkValueType(values.loan_on_balance)
                        ).toFixed(2)
                      : "")
                  }
                />
                {errors.total ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.total}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Date Updated</Form.Label>
                <DatePicker
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  name="date_updated"
                  value={values.date_updated}
                  onChange={(e) => {
                    setLoading(false);
                    setFieldValue("date_updated", e);
                  }}
                  // onBlur={handleBlur}
                  clearIcon={null}
                  className={
                    values.date_updated
                      ? "cs-input-field-active select-date-range cs-date-picker-calender"
                      : "cs-date-picker-calender"
                  }
                  calendarIcon={
                    <span className="cs-neutral-90">
                      <CapsyncIcon title="calender-outlined" size="18" />
                    </span>
                  }
                  format="MM/dd/y"
                  maxDate={new Date()}
                />
                {errors.date_updated && touched.date_updated ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.date_updated}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Notes</Form.Label>
                <textarea
                  autoComplete="off"
                  type="text"
                  placeholder="Optional"
                  name="notes"
                  value={values.notes}
                  className={
                    values.notes
                      ? "cs-input-field-active cs-textarea"
                      : "cs-textarea"
                  }
                  onChange={handleChange}
                  // onBlur={handleBlur}
                ></textarea>
              </Form.Group>
            </Col>
          </Row>
          {!record && (
            <>
              <Row>
                <Col>
                  <Form.Label className="default-regular-sub-heading-xs cs-neutral-100">
                    Upload Files
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="cs-upload-box">
                    <div
                      {...getRootProps({})}
                      className={`cs-upload-files cursor-pointer ${
                        loading && "cs-neutral-60 disabled"
                      }`}
                    >
                      <input
                        {...getInputProps()}
                        disabled={loading}
                        name="attachments"
                      />
                      <span className="cs-primary">
                        <CapsyncIcon title="upload-files-filled" size="28" />
                      </span>
                      <span className="default-regular-sub-heading-s cs-neutral-90">
                        <span className="cs-primary">Browse</span>&nbsp;or drag
                        file here
                      </span>
                      <p className="default-regular-sub-heading-xxs">
                        Supports PDF, PNG, JPG and JPEG format
                      </p>
                    </div>
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {storageError || duplicateFileError}
                    </span>
                    <aside className="category-attachments-files">
                      <ul className="attachment-files cs-files">{files}</ul>
                    </aside>
                  </div>
                </Col>
              </Row>
            </>
          )}

          <div className="cs-modal-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={onFormClose}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="cs-btn-primary md-btn default-regular-h5"
              disabled={loading}
            >
              Save
              {loading && (
                <span className="cs-common-spinner cs-primary-main">
                  <CapsyncIcon title="loading-outlined" size="16" />
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default OtherRetirement;
