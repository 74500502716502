import React from "react";
import { useSelector } from "react-redux";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import CapsyncLogo from "../../assets/images/capsync-logo.svg";

const LogoImage = () => {
  const { user } = useSelector((state) => state.auth);
  const user_role = user && user.user_role && user.user_role.role;
  return (
    <div className="cs-logo">
      {!user ? (
        <Image
          width="132"
          src={CapsyncLogo}
          className="capsync-logo"
          alt="Capsync Logo"
        />
      ) : (
        <Link
          id="capsync-logo"
          to={
            user_role === 1
              ? "/individual"
              : user_role === 2
              ? "/financial-advisor"
              : "/professional"
          }>
          <Image
            width="132"
            src={CapsyncLogo}
            className="capsync-logo"
            alt="Capsync Logo"
          />
        </Link>
      )}
    </div>
  );
};

export default LogoImage;
