import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const ChangeLegalNameBlock = (props) => {
  return (
    <div className="row-spacing">
      <Row>
        <Col lg={5}>
          <Form.Group className="cs-form-group" controlId="validationFormik01">
            <Form.Label>
              <p>
                <span className="default-regular-sub-heading-s cs-neutral-90"> Legal Name </span>
                <span className="default-regular-sub-heading-s cs-danger"> Please click in the box below to edit the name as needed. </span>
              </p>
            </Form.Label>
            <Form.Control

              type="text"
              placeholder="Name"
              autoComplete="off"
              name="Name"
              // onKeyPress={(e) =>
              //   !/[a-zA-z]/.test(e.key) && e.preventDefault()
              // }
              onKeyDown={(e) => {
                if (e.key === " " && e.target.selectionStart === 0) {
                  e.preventDefault();
                }
              }}
              value={props.legalName}
              className={props.legalName && "cs-input-field-active"}
              onChange={(event) =>
                props.changeLegalName(event.target.value)
              }
              maxLength={50}
            />
            <span className="form-error-msg default-light-body-text-s cs-danger">{props.nameAlert}</span>
          </Form.Group>
        </Col>
      </Row>
      <div className="cs-divider"></div>
    </div>
  )
}

export default ChangeLegalNameBlock;
