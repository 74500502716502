import API from "../util/api";
import { headerAddToken } from "../util/common";

const fetch_user_from_email = async (values) => {
  let header = await headerAddToken();
  return API.post("/userinfo/fetch_user_from_email", values, header);
};

const CommonUsersService = {
  fetch_user_from_email,
};

export default CommonUsersService;
