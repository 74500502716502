import React from "react";
import { Form, Row, Col, Dropdown } from "react-bootstrap";
import SubMenuHeader from "../SubMenuHeader";
import CapsyncToolTip from "../../../../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import SubMethodsList from "../../SubMethodsList";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import CapsyncIcon from "../../../../../../commonComponent/CapsyncIcon";
import { Link } from "react-router-dom";
import NumberFormat from "react-currency-format";
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";

// sequence No. two (Asmita date-29th June => Do not remove this line)
const MethodIdTwentySix = (props) => {


  let investorTypeArray = [
    "Individual",
    "Company"
  ];
  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Qualified Client",
      url: `/individual/verifyinvestor/accreditedInvestor/qualifiedclient/${props.unique_code}`,
    },
  ];



  return (
    <>
      {props.is_loader.assets === true || props.is_loader.liabilities === true ? <SkeletonAllSubMethods /> :
        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="Net Worth"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>


              <SubMenuHeader />
            </div>
            <div className="method-section method-twenty-six">
              <div className="default-regular-body-text-l cs-neutral-70">
                <SubMethodsList methodId={28} />
              </div>

              <div className="cs-divider"></div>
              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert}
                />
                <div className="investor-type-row">
                  <Row>
                    <Col lg={5}>
                      <span className="default-regular-sub-heading-s">
                        Are you investing as an individual or company?
                      </span>
                      <div className={props.selectInvestorError ? "investor-type-dropdown investor-error" : "investor-type-dropdown"}>
                        <Dropdown className="dropdown cs-form-dropdown">
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic-accredited"
                            className="dropdown-toggle default-regular-sub-heading-s"
                          >
                            {props.selectInverstorType}
                            <span className="input-field-icon cs-neutral-90">
                              <CapsyncIcon title="chevron-down-outlined" size="16" />
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {investorTypeArray.map((item) => (
                              <Dropdown.Item
                                className={
                                  item === props.selectInverstorType
                                    ? "dropdown-item dropdown-item-active default-regular-sub-heading-s "
                                    : "default-regular-sub-heading-s cs-content-title "
                                }
                                key={item}
                                onClick={() => props.handleInvestorType(item)}
                              >
                                {item}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                          {props.selectInvestorError && <span className="default-regular-sub-heading-xs cs-danger">Select an option whether you are investing as an individual or a company</span>}

                        </Dropdown>
                      </div>
                    </Col>
                  </Row>
                </div>
                {props.selectInverstorType === "Company" &&
                  <>
                    <Form.Check
                      type="checkbox"
                      id="company-checkbox"
                      aria-label="option 1"
                      name="companyCheckbox"
                      className={props.checkInvestorError ? "cs-form-check-box investor-error" : "cs-form-check-box"}
                      feedbackType="invalid"
                      checked={props.checkInvestor}
                      onChange={props.handleCheckInvester}
                      label={
                        <span className="default-regular-sub-heading-s cs-neutral-90">
                          Investor is not an investment company and is not an entity that would be an investment company but for the exemption offered under Section 3(c)(1) of the Investment Company Act.
                        </span>
                      }
                    />
                    {props.checkInvestorError && <span className="default-regular-sub-heading-xs cs-danger"> Check the box to make the representation that the investor meets the requirements indicated.</span>}
                  </>

                }
                <div className="row-spacing">
                  <p className="default-regular-body-text-l cs-neutral-70">
                    In order to verify your accreditation we need you to upload evidence. Please upload the appropriate files below and black-out any sensitive information.
                  </p>
                  <p className="default-regular-body-text-xl cs-neutral-90">
                    {props.selectInverstorType === "Individual" || props.selectInverstorType === "Select option" ? (
                      "Individual Net Worth Assessment") : ("Net Worth Assessment")}
                  </p>
                </div>

                <div className="cs-divider"></div>

                {props.selectInverstorType === "Individual" || props.selectInverstorType === "Select option" ? (
                  <div>
                    <div className="upload-credit-report">
                      <p className="default-regular-body-text-l cs-neutral-90">
                        Upload Recent Credit Report
                      </p>
                      <p className="default-regular-body-text-l cs-neutral-70">
                        <u>Why do I need this?</u>
                        <span className="cs-danger"> This does not affect your credit score or security freezes on your credit.</span>
                      </p>
                      <p className="default-regular-body-text-l cs-neutral-70">  You may obtain your credit report from one of the following options:</p>
                      <ol className="default-regular-body-text-l cs-neutral-70">
                        <li >
                          Order one free credit report once a year from any of the credit reporting bureaus from <Link to={"https://www.annualcreditreport.com/"} target="_blank"> <u className="default-regular-body-text-l cs-neutral-90">https://www.annualcreditreport.com.</u></Link> On that site, we recommend requesting your report from either Equifax or TransUnion where you can download a PDF copy.
                        </li>
                        {/* <li >
                      For a faster, simpler process, authorize VerifyInvestor.com to generate a credit report from TransUnion.<span className="cs-neutral-90"> Note this is for U.S. residents only.</span> This service is available for a $5 fee, payable when you select this option. To pull all credit reports, please click <Link to={"https://verifyinvestor-staging.herokuapp.com/investor/verification_requests/40429/credit-report-auth"} target="_blank"> <u className="cs-neutral-90">here.</u></Link>
                    </li> */}
                        <li >Order credit report from <Link to={"https://connect.experian.com/register/personal.html?c=ptnr-vrfyinv&sg=bs&m=lk&a=verify-investor"} target="_blank"><u className="cs-neutral-90">Experian Connect℠.</u></Link></li>
                      </ol>
                    </div>

                    <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                      Upload your credit report below from options 1 or 2
                    </Form.Label>
                    <UploadFileBlock
                      files={props.files}
                      setOpenModal={props.setOpenModal}
                      mediaAlert={props.mediaAlert}
                      deleteFile={props.deleteFile}
                      showFile={props.showFile}
                      pfsLink={props.pfsLink}
                      pfsName={props.pfsName}
                      pfsDelete={props.pfsDelete}
                      showDeleteSpinner={props.showDeleteSpinner}
                      showPfsLink={props.showPfsLink}
                      uploadError={props.uploadError}
                    />

                    <u className="default-regular-body-text-l cs-neutral-70">
                      If a US credit report exists for you, you must provide it. Most people that live in the US or have assets in the US will have a US credit report. If you do not have a US credit report, upload a credit report equivalent from your own country. If verifying together with a spouse or spousal equivalent, both individuals must provide a credit report.
                    </u>

                    <Form.Check
                      type="checkbox"
                      id="credit-checkbox"
                      aria-label="option 2"
                      name="terms"
                      className={props.isCheckedError ? "cs-form-check-box investor-error" : "cs-form-check-box"}
                      feedbackType="invalid"
                      checked={props.isChecked}
                      onChange={props.handleCheckbox}
                      label={
                        <span className="default-regular-sub-heading-s cs-neutral-90">
                          I certify that no credit report is available.
                        </span>
                      }
                    />
                    {props.isCheckedError && <span className="default-regular-sub-heading-xs cs-danger">Upload a recent credit report, or certify that no credit report is available.</span>}
                    <div className="cs-divider"></div>
                  </div >) : (
                  <>
                    <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                      Please upload the appropriate files below
                    </Form.Label>
                    <UploadFileBlock
                      files={props.files}
                      setOpenModal={props.setOpenModal}
                      mediaAlert={props.mediaAlert}
                      deleteFile={props.deleteFile}
                      showFile={props.showFile}
                      pfsLink={props.pfsLink}
                      pfsName={props.pfsName}
                      pfsDelete={props.pfsDelete}
                      showDeleteSpinner={props.showDeleteSpinner}
                      showPfsLink={props.showPfsLink}
                      uploadError={props.uploadError}
                    />

                  </>
                )
                }

                {
                  props.selectInverstorType === "Company" &&
                  <div>
                    <Form.Check
                      type="checkbox"
                      id="liblity-checkbox"
                      aria-label="option 3"
                      name="liblity"
                      className={props.checkLiblityError ? "cs-form-check-box investor-error" : "cs-form-check-box"}
                      feedbackType="invalid"
                      checked={props.checkLiblity}
                      onChange={props.handleCheckLiblity}
                      label={
                        <span className="default-regular-sub-heading-s cs-neutral-90">
                          All outstanding liabilities of the entity have been disclosed.
                        </span>
                      }
                    />
                    {props.checkLiblityError && <span className="default-regular-sub-heading-xs cs-danger"> Check the box to indicate that you have disclosed all outsanding liabilities of the entity.</span>}

                    <div className="cs-divider"></div>
                  </div>
                }

                <div className="row-spacing net-worth-asset-libility">
                  <p className="default-regular-body-text-xl cs-neutral-90">
                    Net Worth:  $<NumberFormat
                      value={(props.dataAssetTotal - props.dataLiabilityTotal).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    /> <span>USD</span>
                    {/* $ {props.dataAssetTotal - props.dataLiabilityTotal} USD */}
                    <CapsyncToolTip
                      Child={"info-outlined"}
                      placement={"bottom"}
                      type="icon"
                      size="18"
                      message={"Please Refer to your portfolio for more details on net worth."}
                    />
                  </p>
                  {props.uploadFileOneError && <p className="default-regular-sub-heading-xs cs-danger">{props.showError}</p>}

                  <p className="default-regular-body-text-xl cs-neutral-90">Net Worth (for Verification): $<NumberFormat
                    value={(props.dataAssetTotal - props.dataLiabilityTotal).toFixed(2)}
                    displayType={"text"}
                    thousandSeparator={true}
                  /> <span>USD</span>
                    <CapsyncToolTip
                      Child={"info-outlined"}
                      placement={"bottom"}
                      type="icon"
                      size="18"
                      message={"Please Refer to your portfolio for more details on net worth."}
                    />
                  </p>
                </div>

                <NotesBlock setNotesValue={props.setNotesValue} />
              </Form>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default MethodIdTwentySix;
