import { createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import verifyInvestorService from "../services/verifyInvestor.service";
import { localDataRemove } from "../config/finance";

export const verify_investor_add = createAsyncThunk(
  "/verify_investor/add",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.verify_investor_add(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const verify_investor_find = createAsyncThunk(
  "/verify_investor/find",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.verify_investor_findUser(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const vi_findUser_by_UniqueID = createAsyncThunk(
  "/verify_investor/findUser_by_UniqueID",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.vi_findUser_by_UniqueID(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const upload_media = createAsyncThunk(
  "/verify_investor/upload_media",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.upload_media(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else if (error.response.data.code === 500) {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const update_investor_type = createAsyncThunk(
  "/verify_investor/update_investor_type",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.update_investor_type(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const add_update_verification_methodId = createAsyncThunk(
  "/verify_investor/add_update_verification_methodId",
  async (values, thunkAPI) => {
    try {
      const response =
        await verifyInvestorService.add_update_verification_methodId(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const fetch_vi_methods_typewise = createAsyncThunk(
  "/verify_investor/fetch_vi_methods_typewise",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.fetch_vi_methods_typewise(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const verify_request_and_send = createAsyncThunk(
  "/verify_investor/verify_request_and_send",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.verify_request_and_send(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const download_certificate_from_vi = createAsyncThunk(
  "/verify_investor/download_certificate_from_vi",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.download_certificate_from_vi(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const upload_required_document = createAsyncThunk(
  "/verify_investor/upload_req_doc",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.upload_required_document(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const remove_pending_req = createAsyncThunk(
  "/verify_investor/delete_vi_request",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.remove_pending_req(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const fetch_attachments = createAsyncThunk(
  "/verify_investor/fetch_attachments",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.vi_fetch_attachments(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const find_legal_name = createAsyncThunk(
  "/verify_investor/find_legal_name",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.vi_legal_name(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const store_pfs_link = createAsyncThunk(
  "/verify_investor/store_pfs_link",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.store_pfs_link(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else if (error.response.data.code === 500) {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const store_notes_value = createAsyncThunk(
  "/verify_investor/store_notes_value",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.store_notes_value(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else if (error.response.data.code === 500) {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const vi_fetch_media = createAsyncThunk(
  "/verify_investor/fetch_media",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.vi_fetch_media(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const vi_remove_attachment = createAsyncThunk(
  "/verify_investor/remove_attachment",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.vi_remove_attachment(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else if (error.response.data.code === 500) {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const submit_investors_api = createAsyncThunk(
  "/verify_investor/submit_investors_api",
  async (values, thunkAPI) => {
    try {
      const response = await verifyInvestorService.submit_investors_api(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else if (error.response.data.code === 500) {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);