import React, { useState } from "react";
import CapsyncIcon from "../CapsyncIcon";
import { currencyFormat } from "../../util/common";
import { quickBooksColumnFilterArr } from "../../config/finance";

const Parent = ({ data, pl, currentCategory, main_header, heading, dataCounter, conditionalSummarizeCoulmn }) => {
    const [expandedList, setExpandList] = useState([]);

    const handleUpdateExtendedList = (key) => {
        if (expandedList.includes(key)) {
            setExpandList(expandedList.filter(data => data !== key))
        } else {
            setExpandList([
                ...expandedList,
                key
            ])
        }
    }

    return (
        <>
            {data.map((itr) => {
                let valueData = [], isSummary = false;

                [...dataCounter[currentCategory], ''].forEach((data_itr, i) => {
                    valueData.push(i);
                    if (
                        itr[`summary-${i}`] &&
                        itr[`summary-${i}`].length > 0
                    ) {
                        if(currentCategory === "balance_sheet" && (itr[`title-${i}`].length === itr[`title-${i}`].filter(x => x === '').length > 1) || itr[`title-${i}`].length < 1 ){
                            isSummary = true
                        }
                        else if(currentCategory !== "balance_sheet" && itr[`title-${i}`].length === itr[`title-${i}`].filter(x => x === '' ).length || itr[`title-${i}`].length < 1) {
                            isSummary = true;
                        }
                    }
                });

                let arrValue = [];
                if (isSummary || itr.child.length > 0) {
                    for (
                        let index = 0;
                        index <= Math.max(...valueData);
                        index++
                    ) {
                        if (itr[`summary-${index}`]) {
                            arrValue.push(itr[`summary-${index}`]);
                        }
                        else {
                            arrValue.push(Array(main_header.length).fill(''));
                        }
                    }
                } else {
                    for (
                        let index = 0;
                        index <= Math.max(...valueData);
                        index++
                    ) {
                        if (itr[`title-${index}`]) {
                            arrValue.push(itr[`title-${index}`]);
                        }
                        else {
                            arrValue.push(Array(main_header.length).fill(''));
                        }
                    }
                }

                const finalVal = [];
                if (arrValue[0].length) {
                    arrValue[0] = arrValue[0]
                } else {
                    arrValue[0] = ['']
                }
                for (let i = 0; i < arrValue[0].length; i++) {
                    finalVal.push(arrValue[0][i])
                    for (let j = 1; j < arrValue.length; j++) {
                        finalVal.push(arrValue[j][i])
                    }
                }

                let finalHeaderArr = []
                if (itr.child.length > 0) {
                    let tempArrValue = []
                    for (
                        let index = 0;
                        index <= Math.max(...valueData);
                        index++
                    ) {
                        if (itr[`title-${index}`]) {
                            tempArrValue.push(itr[`title-${index}`]);
                        }
                        else {
                            tempArrValue.push(Array(main_header.length).fill(''));
                        }
                    }
                    for (let i = 0; i < tempArrValue[0].length; i++) {
                        finalHeaderArr.push(tempArrValue[0][i])
                        for (let j = 1; j < tempArrValue.length; j++) {
                            finalHeaderArr.push(tempArrValue[j][i])
                        }
                    }
                }

                return (
                    <React.Fragment key={itr.name}>
                        {itr.child.length > 0 ?
                            (
                                <React.Fragment>
                                    <tr className="cursor-pointer" onClick={(() => handleUpdateExtendedList(itr.name))}>
                                        <td className="cs-pl" style={{ paddingLeft: `${pl}px` }}>
                                            <div className="cs-qbo-td">
                                                <span className="cs-accordion-icon cs-neutral-90">
                                                    <CapsyncIcon title={!expandedList.includes(itr.name) ? 'chevron-up-outlined' : 'chevron-down-outlined'} size="16" />
                                                </span>
                                                <span className="cs-qbo-title" title={itr.name}>{itr.name}</span>
                                            </div>
                                        </td>

                                        {finalVal.map((itr_value, i) => (
                                            <React.Fragment key={`${itr.name}_${i}`}>
                                                <td key={`${itr.name}_${i}`}>
                                                    &nbsp;
                                                    {itr_value !== "" && expandedList.includes(itr.name) ?
                                                        <strong style={{whiteSpace: "nowrap"}}>{itr_value ? `$${currencyFormat(itr_value)}` : ''}</strong>
                                                        :
                                                        `${i >= finalVal.length - Number(dataCounter[currentCategory].length + 1) && conditionalSummarizeCoulmn[currentCategory] !== quickBooksColumnFilterArr[0] && !!finalHeaderArr[i] ? '$' : ''}${!!finalHeaderArr[i] ? finalHeaderArr[i] : ''}`
                                                    }
                                                </td>
                                            </React.Fragment>
                                        ))}
                                    </tr>
                                    {
                                        !expandedList.includes(itr.name) &&
                                        <React.Fragment>
                                            <Parent
                                                data={itr.child}
                                                pl={pl + 25}
                                                currentCategory={currentCategory}
                                                main_header={main_header}
                                                heading={heading}
                                                dataCounter={dataCounter}
                                                conditionalSummarizeCoulmn={conditionalSummarizeCoulmn}
                                            />
                                                <tr className="cs-accordion-total default-regular-body-text-l cs-neutral-100">
                                                <td className="cs-pl" style={{ paddingLeft: `${pl + 26}px` }}>
                                                    <span className="cs-qbo-title" title={itr["summary-name"]}>
                                                        {itr["summary-name"]}
                                                    </span>
                                                </td>
                                                {
                                                    finalVal.map((itr_value, i) => (
                                                        <React.Fragment key={`${itr["summary-name"]}_${i}`}>
                                                            <td>{itr_value !== '' && itr_value && '$'}{itr_value ? currencyFormat(itr_value) : ''}</td>
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </tr>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            ) :
                            (
                                <React.Fragment>
                                    {finalVal.filter(val =>  val !== "" && val !== undefined).length > 0 &&
                                    <tr className={`default-light-body-text-l cs-neutral-90 ${itr.name === "TOTAL" ? "cs-accordion-total":"" }`} >
                                        <td className="cs-pl" style={{ paddingLeft: `${pl}px` }}>
                                            <span className="cs-qbo-title" title={itr.name}>{isSummary ? itr.name.toString().toUpperCase() : itr.name}</span>
                                        </td>
                                        {finalVal.map((itr_value, i) => (
                                            <React.Fragment key={`${itr.name}_${i}`}>
                                                <td key={`${itr.name}_${i}`}>
                                                    {itr_value !== ""  ?
                                                        (isSummary) || itr.child.length > 0 ?
                                                            <strong style={{whiteSpace: "nowrap"}}>{itr_value ? `$${currencyFormat(itr_value)}` : ''}</strong>
                                                            :
                                                            `${i >= finalVal.length - Number(dataCounter[currentCategory].length + 1) && conditionalSummarizeCoulmn[currentCategory] === quickBooksColumnFilterArr[0] && itr_value !== undefined && isSummary === true ? '$' : ''}${itr_value ? currencyFormat(itr_value) : ''}`
                                                        : ""
                                                    }
                                                </td>
                                            </React.Fragment>
                                        ))}
                                    </tr>
                                }
                                    {itr['summary-name'] !== '' &&
                                        <tr className="cs-accordion-total">
                                            <td className="cs-pl" style={{ paddingLeft: `${pl}px` }}>
                                                <span>
                                                    <strong>
                                                        {isSummary ? itr['summary-name'].toString().toUpperCase() : itr['summary-name']}
                                                    </strong>
                                                </span>
                                            </td>
                                            {finalVal.map((itr_value, i) => (
                                                <React.Fragment key={`${itr.name}_${i}`}>
                                                    <td key={`${itr.name}_${i}`}>
                                                        {itr_value !== "" ?
                                                            (isSummary) || itr.child.length > 0 ?
                                                                <strong style={{whiteSpace: "nowrap"}}>{itr_value ? `$${currencyFormat(itr_value)}` : ''}</strong>
                                                                :
                                                                `${i >= finalVal.length - Number(dataCounter[currentCategory].length + 1) && conditionalSummarizeCoulmn[currentCategory] !== quickBooksColumnFilterArr[0] ? '$' : ''}${itr_value ? currencyFormat(itr_value) : ''}`
                                                            : ""
                                                        }
                                                    </td>
                                                </React.Fragment>
                                            ))}
                                        </tr>
                                    }
                                </React.Fragment>
                            )
                        }
                    </React.Fragment>
                );
            })}
        </>
    );
};

const Categories = (props) => {
    const { quickBooksData, category, dataCounter, conditionalSummarizeCoulmn } = props;
    let colSpan = (quickBooksData.heading.length - 1) / quickBooksData.main_header.length;
    return (
        <div className={`cs-qbo-container ${quickBooksData.heading.length > 3 && 'cs-qbo-full-width-container'}`}>
            <table>
                <thead className={`${quickBooksData.main_header.length > 0 && 'cs-main-title'}`}>
                    {quickBooksData.main_header.length > 0 &&
                        <tr className="cs-qbo-total">
                            <th className="default-regular-h4 cs-neutral-100"></th>
                            {quickBooksData.main_header.map((itr) => {
                                return (
                                    <th className="default-regular-h4 cs-neutral-100 text-center" colSpan={colSpan}>
                                        {itr}
                                    </th>
                                );
                            })}
                        </tr>
                    }
                    <tr className="cs-qbo-total">
                        {quickBooksData.heading.map((itr) => {
                            return (
                                <th className="default-regular-h4 cs-neutral-100">
                                    {itr}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody className="cs-qbo-body  default-light-body-text-l cs-neutral-90">
                    <Parent data={quickBooksData.data} main_header={quickBooksData.main_header} conditionalSummarizeCoulmn={conditionalSummarizeCoulmn} currentCategory={category} heading={quickBooksData.heading} dataCounter={dataCounter} pl={20} />
                </tbody>
            </table>
        </div>
    );
};

export default Categories;
