let backAppURl = process.env.REACT_APP_BACKEND;
let advisorSearchAPI = process.env.REACT_APP_BACKEND_ADVISOR_SEARCH;

// if (process.env.REACT_APP_MODE === "LOCAL") {
//   backAppURl = process.env.REACT_APP_BACKEND_LOCAL;
// } else if (process.env.REACT_APP_MODE === "DEV") {
//   backAppURl = process.env.REACT_APP_BACKEND_DEV;
// } else if (process.env.REACT_APP_MODE === "STAGE") {
//   backAppURl = process.env.REACT_APP_BACKEND_STAGE;
// } else if (process.env.REACT_APP_MODE === "UAT") {
//   backAppURl = process.env.REACT_APP_BACKEND_UAT;
// } else if (process.env.REACT_APP_MODE === "PROD") {
//   backAppURl = process.env.REACT_APP_BACKEND;
// }

export { backAppURl, advisorSearchAPI };
