import React from 'react'
import { Button, Modal } from "react-bootstrap";

const SubMethodPopup = (props) => {

  const { showSeeMore, setShowSeeMore } = props;

  return (
    <Modal
      show={showSeeMore}
      onHide={!showSeeMore}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-lg-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3 ">
          Verify account investor
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="accredited-content-box default-regular-sub-heading-s cs-neutral-90">
          {props.showMethod}
        </div>
      </Modal.Body>
      <Modal.Footer className='cs-modal-btn'>
        <Button onClick={
          () => setShowSeeMore(false)
        } className="cs-btn-secondary md-btn default-regular-h5">
          Close
        </Button>

      </Modal.Footer>
    </Modal>
  )
}

export default SubMethodPopup
