import React from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton';
import { amortizationHeader } from '../../../config/finance';

const SkeletonAmortizationTable = () => {
    return (
        <div className="amortization-table-container">
            <Row>
                <Col lg={12}>
                    <div className="assets-listing amortization-main-listing">
                        <Table className="cs-table skeleton-table">
                            <thead className="cs-thead">
                                <tr>
                                    {amortizationHeader.map((itr) => {
                                        return (
                                            <>
                                                <th width={itr.width} className={itr.class}>
                                                    <span>
                                                        <Skeleton width={100} />
                                                    </span>
                                                </th>
                                            </>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody className="cs-tbody default-regular-sub-heading-m">
                                <tr>
                                    {Array(9).fill(0).map((item) => (
                                        <td>
                                            <Skeleton width={100} />
                                        </td>
                                    ))}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default SkeletonAmortizationTable
