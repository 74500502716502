/* eslint-disable */
import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";

import commonNames from "../../../config/commonNames";
import ManageFilesUpload from "../../../commonComponent/ManageFilesUpload";
import {
  categoryFilesRemove,
  categoryGetAll,
  categoryFilesUpdate,
} from "../../../slices/category.slice";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import { tableHeader } from "../../../config/categoryTable";
import CategoryTableData from "../../../commonComponent/CategoryTableData";
import { totalBalance } from "../../../config/financeCalculation";
import { totalBalanceColumn } from "../../../util/finance";
import { get_user_storage, useProgressUploadDetails, useUploadStorage } from "../../../slices/fileManagement.slice";

const LiabilityCategoryAllData = ({
  closeModal,
  toastMessage,
  url,
  title,
  category,
  ...props
}) => {
  const { user } = useSelector((state) => state.auth);
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }
  const storageData = useUploadStorage()
  const progressUploadDetails =  useProgressUploadDetails();
  const dispatch = useDispatch();

  const [fileModal, setFileModal] = useState(false);
  const [storeFiles, listFiles] = useState([]);
  const [storeAllFiles, setAllFiles] = useState("");
  const [is_deleted, setIs_deleted] = useState(false);
  const [manualEntries, setManualEntries] = useState([]);
  const [totalCount, setTotalCount] = useState({
    balanceDue: 0,
    balance: 0,
    loanAmount: 0,
    payment: 0
  });

  const getTableList = () => {
    dispatch(
      categoryGetAll({
        id: id,
        category: category,
        main_category: "liabilities",
      })
    )
      .unwrap()
      .then((response) => {
        setManualEntries(response.entries);
        // setExternalApiEntries(response.external_api);
      })
      .catch((err) => {
        toastMessage(err.message, "error");
      });
  };

  const changeStatus = (showModal, r_id) => {
    // window.localStorage.setItem("row_id", r_id);
    setFileModal(showModal);
    if (attachments !== null) {
      // const allFiles = attachments;
      // setAllFiles(allFiles);
      // const collectFiles = allFiles.split(",");
      // listFiles(collectFiles);
    } else {
      const allFiles = attachments;
      setAllFiles("");
      listFiles([]);
    }
  };
  function fileAttachment(params) {
    const allFiles = params;
    setAllFiles(allFiles);
    const collectFiles = allFiles.split(",");
    listFiles(collectFiles);
  }

  const onDropAccepted = useCallback((acceptedFiles) => {
    setIs_deleted(true);
    const emptyfileArr = [];
    acceptedFiles.forEach((file) => {
      emptyfileArr.push(file);
    });
    const inProgressData = progressUploadDetails.length
    ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
    : 0;
const used_space = storageData.usedSpace + inProgressData
    const formdata = new FormData();

    for (let i = 0; i < emptyfileArr.length; i++) {
      const img_name = emptyfileArr[i];
      formdata.append("attachments", img_name);
    }
    let rowId = window.localStorage.getItem("row_id");
    formdata.append("row_id", rowId);
    formdata.append("used_space", used_space);
    let newData = {
      formdata: formdata,
      category: category,
      main_category: "liabilities",
    };
    dispatch(categoryFilesUpdate(newData))
      .unwrap()
      .then(async(response) => {
        await dispatch(get_user_storage({ id })).unwrap();
        fileAttachment(response.data);
        getTableList();
        setIs_deleted(false);
        acceptedFiles.length = 0;
        toastMessage("Your file has been uploaded successfully.", "success");
      })
      .catch((err) => {
        setIs_deleted(false);
        toastMessage(err.message, "error");
      });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf'],
    },
    onDropAccepted,
  });

  const removeFile = (params) => {
    let rowId = window.localStorage.getItem("row_id");
    setIs_deleted(true);

    dispatch(
      categoryFilesRemove({
        rowId: rowId,
        fileName: params,
        category: category,
        main_category: "liabilities",
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          fileAttachment(response.data);
          setIs_deleted(false);
          toastMessage("Your file has been deleted successfully.", "success");
          getTableList();
        } else {
        }
      })
      .catch((err) => {
        setIs_deleted(false);
        toastMessage(err.message, "error");
      });
  };

  const { categoryTitle, liabilitiesColumnsListArr, categoryType, displayArr } = props;
  const columnCount = liabilitiesColumnsListArr.length;
  const tableFooterRow = totalBalanceColumn(columnCount + 1);

  const isDisplayBalanceDue =
    ["notespayabletobanksandothers", "installmentsauto", "installmentsaccount",
      "mortgagesonrealestate", "unpaidtaxes", "otherliabilities", "accountpayable",
    ].includes(categoryType);
  const isDisplayLoanAmount = ["lifeinsurance"].includes(categoryType);
  const isDsiplayPayment = [].includes(categoryType);

  useEffect(() => {
    props.displayArr && props.displayArr.length && setTotalCount({
      balanceDue: totalBalance(props.displayArr, "balance_due"),
      loanAmount: totalBalance(props.displayArr, "loan_amount"),
      payment: totalBalance(props.displayArr, "payment"),
      originalBalance: totalBalance(props.displayArr, "original_balance")
    });
  }, [props.displayArr])



  return (
    <>
      <div className="pfs-table-container">
        <ManageFilesUpload
          changeStatus={changeStatus}
          fileModal={fileModal}
          storeFiles={storeFiles}
          storeAllFiles={storeAllFiles}
          getTableList={getTableList}
          toastMessage={toastMessage}
          fileAttachment={fileAttachment}
          user_id={id}
          acceptedFiles={acceptedFiles}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          removeFile={removeFile}
          is_deleted={is_deleted}
        />
        <>
          <Row>
            <Col lg={12}>
              <div className="pfs-common-title">
                <h4 className="default-regular-h4 cs-neutral-100">
                  {categoryTitle}
                  {/* <Link
                    // to={`/individual/liabilities/${categoryType}`}
                    className="custom-table-element"
                  // onClick={closeModal}
                  >
                    <span className="cs-liability icon">
                      <CapsyncIcon title="redirect-outlined" size="24px" />
                    </span>
                  </Link> */}
                </h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="pfs-share-worksheet-listing">
                <Table responsive className={`cs-table ${categoryType}-table category-cs-table-container`}
                >
                  <thead id="table-head-details" className="cs-thead">
                    <tr>
                      {liabilitiesColumnsListArr && liabilitiesColumnsListArr.map((liabilitiesTdHead) => {
                        return (
                          <>
                            <th className={liabilitiesTdHead.pfsClass} width={liabilitiesTdHead.pfsWidth}>
                              {liabilitiesTdHead.title}
                            </th>
                          </>
                        );
                      })}
                      <th className="default-medium-sub-heading-m" width="111">
                        {commonNames.Attachment}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="cs-tbody">
                    {displayArr && displayArr.map((liabilitiesDisplay, indx) => {
                      return (
                        <tr key={indx}>
                          {
                            liabilitiesColumnsListArr && liabilitiesColumnsListArr.map((liabilitiesRowField, index) => {
                              const { fieldType, fieldName } = liabilitiesRowField;
                              let displayValue = liabilitiesDisplay[fieldName];
                              if (fieldName === "ownership" || fieldName === "apr_percentage") {
                                displayValue = displayValue + '  %';
                              }
                              if (fieldName === "address") {
                                displayValue = setAddressString(displayValue);
                              }
                              return (
                                <td className={fieldName === "ownership" || fieldName === "apr_percentage" ? "text-end" : ""} width={liabilitiesRowField.pfsWidth}>
                                  <CategoryTableData
                                    component={displayValue}
                                    type={fieldType}
                                    classValue={`${fieldName === "ownership" ? 'text-end' : ''}`}
                                    item={liabilitiesDisplay}
                                    fieldName={fieldName}
                                    isSharePFS={true}
                                  // is_display_tooltip={liabilitiesDisplay.is_display_tooltip}
                                  />
                                </td>
                              )
                            })
                          }
                          <td>
                            <div className="other-action-td">
                              <CategoryTableData
                                type="attachments"
                                changeStatus={() => { }}
                                item={liabilitiesDisplay}
                                isSharePFS={true}
                              />
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                    <tr className="cs-tfoot-liability default-medium-sub-heading-m">
                      <td colSpan={tableFooterRow.length - 3}>{commonNames.Total}</td>
                      <td colSpan={1}>
                        {isDisplayBalanceDue && (
                          <CategoryTableData
                            type="number"
                            component={totalCount.balanceDue}
                          />
                        )}
                        {isDisplayLoanAmount && (
                          <CategoryTableData
                            type="number"
                            component={totalCount.loanAmount}
                          />
                        )}
                        {isDsiplayPayment && (
                          <CategoryTableData
                            type="number"
                            component={totalCount.payment}
                          />
                        )}
                      </td>
                      <td colSpan={2}>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </>
      </div>
    </>
  );
};

export default LiabilityCategoryAllData;
