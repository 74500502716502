/* eslint-disable */
import React, { useCallback, useState, useEffect } from "react";
import { Modal, Form, Row, Col, Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import DatePicker from "react-date-picker";
import NumberFormat from "react-currency-format";
import { useFormik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import validationObj from "./validationSchema";
import { adjustLength } from "../../../../customHooks/adjustLength";
import {
  categoryAdd,
  categoryUpdateRecord,
} from "../../../../slices/category.slice";
import commonNames from "../../../../config/commonNames";
import {
  NotesPlaceholder,
  addTimePeriod,
  calculatePaymentPeriod,
  checkPhoneValid,
  combine,
  loanTermType,
  phoneHandleChange,
} from "../../../../util/common";
import { isValidPhoneNumber } from "react-phone-number-input";
import { validateDuplicateFileName } from "../../../../config/validateDuplicateFileName";
import {
  handleInterestValue,
  handleOwnershipKeyDown,
  handleOwnershipPaste,
  handleOwnershipValue,
} from "../../../../config/handleOwnershipValue";
import {
  get_user_storage,
  useProgressUploadDetails,
  useUploadStorage,
} from "../../../../slices/fileManagement.slice";

const InstallmentAmountOther = (props) => {
  const { onFormClose, onFormSubmit, record, showModal } = props;

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const [loading, setLoading] = useState(false);
  const [storeAcceptedFiles, setStoreAcceptedFiles] = useState([]);
  const [amortization, setAmortization] = useState(false);
  const [initialValues, setInitialValues] = useState({
    user_id: id,
    creditor_name: "",
    creditor_email: "",
    creditor_phonenumber: "",
    loan_amount: "",
    date_Loan: moment().format(),
    loan_period: "",
    frequency: loanTermType[0],
    annual_rate: "",
    maturity_date: "",
    payment: "",
    balance_due: "",
    date_updated: moment().format(),
    loan_type: "",
    collateral: "",
    notes: "",
    is_manual: true,
  });
  const storageData = useUploadStorage();
  const progressUploadDetails = useProgressUploadDetails();
  const [phoneNo, setPhoneNo] = useState("");
  const [msg, setMsg] = useState("");
  const [newDollar, setDollar] = useState(false);
  const [newDollar1, setDollar1] = useState(false);
  const [newDollar2, setDollar2] = useState(false);
  const [newDollar3, setDollar3] = useState(false);
  const [duplicateFileError, setDuplicateFileError] = useState("");
  const [storageError, setStorageError] = useState("");

  const check = (para, a) => {
    const common = combine(para, a);
    if (common.value === "dollar") {
      setDollar(common.status);
    } else if (common.value === "dollar_one") {
      setDollar1(common.status);
    } else if (common.value === "dollar_two") {
      setDollar2(common.status);
    } else if (common.value === "dollar_three") {
      // setDollar3(common.status);
    }
  };

  useEffect(() => {
    if (!!record) {
      setInitialValues({
        user_id: record.user_id || "",
        creditor_name: record.creditor_name || "",
        creditor_email: record.creditor_email || "",
        creditor_phonenumber: record.creditor_phonenumber || "",
        loan_amount:
          (record.loan_amount && record.loan_amount.toLocaleString("en-US")) ||
          "",
        date_Loan: record.date_Loan
          ? new Date(moment(record.date_Loan).format("MM/DD/YYYY"))
          : moment().format(),
        annual_rate:
          (record.annual_rate && record.annual_rate.toLocaleString("en-US")) ||
          "",
        maturity_date: record.maturity_date
          ? new Date(moment(record.maturity_date).format("MM/DD/YYYY"))
          : "",
        loan_period:
          (record.loan_period && record.loan_period.toLocaleString("en-US")) ||
          "",
        frequency: record.frequency || "",
        collateral: record.collateral || "",
        // maturity_date: new Date(
        //   moment(record.maturity_date).format("MM/DD/YYYY")
        // ) || "",
        // payment: record.payment && record.payment.toLocaleString("en-US") || "",
        balance_due:
          (record.balance_due && record.balance_due.toLocaleString("en-US")) ||
          "",
        date_updated: record.date_updated
          ? new Date(moment(record.date_updated).format("MM/DD/YYYY"))
          : "",
        loan_type: record.loan_type || "",
        collateral: record.collateral || "",
        notes: record.notes || "",
        is_manual: record.is_manual || "",
      });
      setAmortization(record.is_amortization_link_enable || false);
      record.creditor_phonenumber && setPhoneNo(record.creditor_phonenumber);
    }
  }, [record]);

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema:
        validationObj.liability_installment_other_validationSchema,
      onSubmit: async (values) => {
        if (phoneNo && (!isValidPhoneNumber(phoneNo) || msg)) {
          setMsg("Enter a valid phone number");
          return true;
        }
        setLoading(true);
        record && (values.id = record.id);
        values.user_id = id;
        values.creditor_phonenumber = phoneNo;
        values.pfs_access_id = id ? id : 0;
        values.annual_rate && values.annual_rate.toLocaleString("en-US");
        values.is_amortization_link_enable = amortization;
        values.payment = calculatePaymentPeriod(
          values.loan_amount,
          values.annual_rate,
          values.frequency === "Yearly"
            ? values.loan_period
            : values.loan_period / 12,
          12
        );
        delete values.is_manual;
        if (values.date_Loan && values.loan_period && values.frequency)
          values.maturity_date = addTimePeriod(
            values.date_Loan,
            values.loan_period,
            values.frequency
          );
        const inProgressData = progressUploadDetails.length
          ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
          : 0;
        const used_space = storageData.usedSpace + inProgressData;

        const formdata = new FormData();
        formdata.append("user_details", JSON.stringify(values));
        formdata.append("user_id", values.user_id);
        formdata.append("used_space", used_space);
        formdata.append("institution", values.creditor_name);
        for (let i = 0; i < storeAcceptedFiles.length; i++) {
          formdata.append("attachments", storeAcceptedFiles[i]);
        }
        let data = {
          category: "installmentsaccount",
          main_category: "liabilities",
          used_space: used_space,
        };

        if (!!record) {
          data = { ...data, newData: values };
          dispatch(categoryUpdateRecord(data))
            .unwrap()
            .then((response) => {
              if (response.code === 200) {
                onFormSubmit("installmentsaccount");
                toast.success(response.message);
                props.dashboardAPi();
              }
              setLoading(false);
            })
            .catch((err) => {
              // setModalShow(false);
              toast.error(err.message);
              setLoading(false);
            });
        } else {
          data = { ...data, formdata: formdata };
          dispatch(categoryAdd(data))
            .unwrap()
            .then(async (response) => {
              if (response.code === 200) {
                const response = await dispatch(
                  get_user_storage({ id })
                ).unwrap();
                onFormSubmit("installmentsaccount");
                // toast.success(response.message);
                props.dashboardAPi();
              } else if (response.code === 500) {
                setStorageError(response.message);
              }
              setLoading(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
            });
        }
      },
    });

  const handleChange = (e) => {
    setLoading(false);
    if (e.target.name === "annual_rate" && e.target.value > 100) {
      setFieldValue(e.target.name, "");
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
    setDuplicateFileError("");
  };

  // Upload File Handler
  const removeAcceptedFile = (params) => {
    let store = [...storeAcceptedFiles];
    store.splice(params, 1);
    setStoreAcceptedFiles(store);
    setDuplicateFileError("");
    setStorageError("");
  };
  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setDuplicateFileError("");
      setStorageError("");
      if (
        validateDuplicateFileName(
          acceptedFiles,
          storeAcceptedFiles,
          setDuplicateFileError
        )
      ) {
        setStoreAcceptedFiles([...acceptedFiles, ...storeAcceptedFiles]);
      }
    },
    [storeAcceptedFiles]
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    disabled: loading,
    onDropAccepted,
  });
  const files = storeAcceptedFiles.map((file, i) =>
    adjustLength(file.path, () => removeAcceptedFile(i))
  );

  return (
    <Modal
      show={showModal}
      backdrop="static"
      id="connect-your-institutions"
      aria-labelledby="contained-modal-title-vcenter"
      className="cap-dash-main-modal"
      centered
      keyboard={false}
      dialogClassName="cs-lg-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          {commonNames.installmentAccountOther}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Name of Creditor</Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  name="creditor_name"
                  onKeyDown={(e) => {
                    !/^[a-zA-Z_ ]*$/.test(e.key) && e.preventDefault();
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.creditor_name}
                  className={values.creditor_name && "cs-input-field-active"}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  disabled={!values.is_manual}
                />
                {errors.creditor_name && touched.creditor_name ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.creditor_name}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="email"
                  placeholder="Optional"
                  name="creditor_email"
                  onKeyDown={(e) => e.code === "Space" && e.preventDefault()}
                  defaultValue={values.creditor_email}
                  className={values.creditor_email && "cs-input-field-active"}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                />
                {errors.creditor_email && touched.creditor_email ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.creditor_email}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={6} sm={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Phone Number (Optional)</Form.Label>
                <PhoneInput
                  limitMaxLength={15}
                  international
                  countryCallingCodeEditable={false}
                  addInternationalOption={false}
                  placeholder="Optional"
                  autoComplete="off"
                  defaultCountry="US"
                  name="creditor_phonenumber"
                  withCountryCallingCode={false}
                  value={values.creditor_phonenumber}
                  // className={values.phoneNo && "cs-input-field-active"}
                  className="form-control"
                  countries={["US"]}
                  // onChange={(e) => checkPhoneValid(e, setPhoneNo, setMsg, setLoading)}
                  onChange={(e) => {
                    phoneHandleChange(e, setPhoneNo, setMsg, setLoading);
                  }}
                />
                {msg !== "" ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {msg}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Loan Amount</Form.Label>
                <NumberFormat
                  type="text"
                  thousandSeparator={true}
                  autoComplete="off"
                  placeholder=""
                  name="loan_amount"
                  maxLength={12}
                  value={values.loan_amount}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  className={
                    values.loan_amount
                      ? "dollar-symbol text-md-end form-control cs-input-field-active"
                      : "text-md-end form-control"
                  }
                  allowNegative={false}
                  disabled={!values.is_manual}
                  decimalScale={2}
                  onKeyDown={(e) => {
                    if (e.key === "." && e.target.value === "") {
                      e.preventDefault(); // Prevent typing another dot if there's already one
                    }
                  }}
                />
                {errors.loan_amount && touched.loan_amount ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.loan_amount}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Loan Date</Form.Label>
                <DatePicker
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  name="date_Loan"
                  value={values.date_Loan}
                  onChange={(e) => {
                    setLoading(false);
                    setFieldValue("date_Loan", e);
                  }}
                  // onBlur={handleBlur}
                  clearIcon={null}
                  className={
                    values.date_Loan
                      ? "cs-input-field-active select-date-range cs-date-picker-calender"
                      : "cs-date-picker-calender"
                  }
                  calendarIcon={
                    <span className="cs-neutral-90">
                      <CapsyncIcon title="calender-outlined" size="18" />
                    </span>
                  }
                  format="MM/dd/y"
                  maxDate={new Date()}
                  disabled={!values.is_manual}
                />
                {errors.date_Loan && touched.date_Loan ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.date_Loan}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Loan Term</Form.Label>
                <>
                  <div className="loan-term-box">
                    <div className="loan-term-input">
                      <NumberFormat
                        type="text"
                        thousandSeparator={true}
                        autoComplete="off"
                        placeholder=""
                        name="loan_period"
                        onKeyDown={(e) => {
                          if (e.key === " " && e.target.selectionStart === 0) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={3}
                        decimalScale={0}
                        value={values.loan_period}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        className={
                          values.loan_period
                            ? "form-control cs-input-field-active"
                            : "form-control"
                        }
                        onPaste={(e) => {
                          const clipboardData =
                            e.clipboardData || window.clipboardData;
                          const pastedText = clipboardData.getData("Text");

                          // Check if the pasted value is negative and prevent it if it is
                          if (parseFloat(pastedText) < 0) {
                            e.preventDefault();
                          }
                        }}
                        allowNegative={false}
                      />
                    </div>
                    <div className="loan-term-dropdown cs-common-add-dropdown">
                      <Dropdown>
                        <Dropdown.Toggle>
                          {/* {values.frequency || "Choose Term"} */}
                          {values.frequency}
                          <span className="cs-neutral-90">
                            <CapsyncIcon
                              title="chevron-down-outlined"
                              size="18"
                            />
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {loanTermType.map((dateItr) => (
                            <Dropdown.Item
                              className={
                                dateItr === values.frequency
                                  ? "cs-selected-dropdown"
                                  : ""
                              }
                              key={dateItr}
                              onClick={() => {
                                setLoading(false);
                                setFieldValue("frequency", dateItr);
                              }}
                            >
                              {dateItr}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </>
                {errors.loan_period && touched.loan_period ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.loan_period}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Interest Rate (%)</Form.Label>
                <NumberFormat
                  type="text"
                  autoComplete="off"
                  placeholder=""
                  name="annual_rate"
                  maxLength={5}
                  value={values.annual_rate}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  className={
                    values.annual_rate
                      ? "form-control percentage-symbol cs-input-field-active"
                      : "form-control"
                  }
                  onKeyUp={(e) => check(e.target.value, "percentage")}
                  // onPaste={(e) => {
                  //   const clipboardData =
                  //     e.clipboardData || window.clipboardData;
                  //   const pastedText = clipboardData.getData("Text");

                  //   // Check if the pasted value is negative and prevent it if it is
                  //   if (parseFloat(pastedText) < 0) {
                  //     e.preventDefault();
                  //   }
                  // }}
                  onKeyDown={handleOwnershipKeyDown}
                  onPaste={handleOwnershipPaste}
                  allowNegative={false}
                  // onKeyDown={handleInterestValue}
                  decimalScale={2}
                />
                {errors.annual_rate && touched.annual_rate ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.annual_rate}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col Col xs={12} md={6}>
              <Form.Group className="cs-form-group cs-disabled">
                <Form.Label>Maturity Date</Form.Label>
                <DatePicker
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  name="maturity_date"
                  value={
                    values.date_Loan && values.loan_period && values.frequency
                      ? addTimePeriod(
                          values.date_Loan,
                          values.loan_period,
                          values.frequency
                        )
                      : ""
                  }
                  onChange={(e) => {
                    setLoading(false);
                    setFieldValue("maturity_date", e);
                  }}
                  // onBlur={handleBlur}
                  clearIcon={null}
                  className={
                    values.maturity_date
                      ? "cs-input-field-active select-date-range cs-date-picker-calender"
                      : "cs-date-picker-calender"
                  }
                  calendarIcon={
                    <span className="cs-neutral-90">
                      <CapsyncIcon title="calender-outlined" size="18" />
                    </span>
                  }
                  format="MM/dd/y"
                  disabled={true}
                />
                {errors.maturity_date && touched.maturity_date ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.maturity_date}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group cs-disabled">
                <Form.Label>Payment</Form.Label>
                <NumberFormat
                  className={
                    "dollar-symbol text-md-end form-control cs-input-field-active"
                  }
                  thousandSeparator={true}
                  autoComplete="off"
                  placeholder=""
                  name="payment"
                  maxLength={12}
                  value={
                    values.loan_amount &&
                    values.annual_rate &&
                    values.annual_rate > 0 &&
                    values.loan_period
                      ? calculatePaymentPeriod(
                          values.loan_amount,
                          values.annual_rate,
                          values.frequency === "Yearly"
                            ? values.loan_period
                            : values.loan_period / 12,
                          12
                        )
                      : values.loan_amount
                  }
                  onChange={handleChange}
                  onKeyUp={(e) => check(e.target.value, "dollar")}
                  // onBlur={handleBlur}
                  // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  disabled={true}
                />
                {errors.payment && touched.payment ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.payment}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Balance Due</Form.Label>
                <NumberFormat
                  className={
                    values.balance_due
                      ? "dollar-symbol text-md-end form-control cs-input-field-active"
                      : "text-md-end form-control"
                  }
                  thousandSeparator={true}
                  autoComplete="off"
                  placeholder=""
                  name="balance_due"
                  maxLength={12}
                  value={values.balance_due}
                  onChange={handleChange}
                  onKeyUp={(e) => check(e.target.value, "dollar")}
                  // onBlur={handleBlur}
                  // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  onPaste={(e) => {
                    const clipboardData =
                      e.clipboardData || window.clipboardData;
                    const pastedText = clipboardData.getData("Text");

                    // Check if the pasted value is negative and prevent it if it is
                    if (parseFloat(pastedText) < 0) {
                      e.preventDefault();
                    }
                  }}
                  allowNegative={false}
                  disabled={!values.is_manual}
                  decimalScale={2}
                  onKeyDown={(e) => {
                    if (e.key === "." && e.target.value === "") {
                      e.preventDefault(); // Prevent typing another dot if there's already one
                    }
                  }}
                />
                {errors.balance_due && touched.balance_due ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.balance_due}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Date Updated</Form.Label>
                <DatePicker
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  name="date_updated"
                  value={values.date_updated}
                  onChange={(e) => {
                    setLoading(false);
                    setFieldValue("date_updated", e);
                  }}
                  // onBlur={handleBlur}
                  clearIcon={null}
                  className={
                    values.date_updated
                      ? "cs-input-field-active select-date-range cs-date-picker-calender"
                      : "cs-date-picker-calender"
                  }
                  calendarIcon={
                    <span className="cs-neutral-90">
                      <CapsyncIcon title="calender-outlined" size="18" />
                    </span>
                  }
                  format="MM/dd/y"
                  maxDate={new Date()}
                />
                {errors.date_updated && touched.date_updated ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.date_updated}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  placeholder="Optional"
                  name="loan_type"
                  onKeyDown={(e) => {
                    !/^[a-zA-Z_ ]*$/.test(e.key) && e.preventDefault();
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.loan_type}
                  className={values.loan_type && "cs-input-field-active"}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                />
                {errors.loan_type && touched.loan_type ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.loan_type}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Collateral</Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  placeholder="Optional"
                  name="collateral"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.collateral}
                  className={values.collateral && "cs-input-field-active"}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  onKeyPress={(e) =>
                    !/^[a-zA-Z_ ]*$/.test(e.key) && e.preventDefault()
                  }
                />
                {errors.collateral && touched.collateral ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.collateral}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Form className="cs-form-group amortization-schedule-box">
                <Form.Check
                  id="amortizationadd"
                  type="checkbox"
                  aria-label="amortization"
                  checked={amortization}
                  onChange={(e) => {
                    setLoading(false);
                    setAmortization(e.target.checked);
                  }}
                  className="cs-form-check-box"
                  label={
                    <span
                      htmlFor="amortizationadd"
                      className="cursor-pointer default-regular-h5 cs-primary"
                    >
                      Create Amortization Schedule
                    </span>
                  }
                />
              </Form>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Notes</Form.Label>
                <textarea
                  autoComplete="off"
                  type="text"
                  placeholder={NotesPlaceholder}
                  name="notes"
                  value={values.notes}
                  className={
                    values.notes
                      ? "cs-input-field-active cs-textarea"
                      : "cs-textarea"
                  }
                  onChange={handleChange}
                  // onBlur={handleBlur}
                ></textarea>
              </Form.Group>
            </Col>
          </Row>
          {!record && (
            <>
              <Row>
                <Col>
                  <Form.Label className="default-regular-sub-heading-xs cs-neutral-100">
                    Upload Files
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="cs-upload-box">
                    <div
                      {...getRootProps({})}
                      className={`cs-upload-files cursor-pointer ${
                        loading && "cs-neutral-60 disabled"
                      }`}
                    >
                      <input
                        {...getInputProps()}
                        disabled={loading}
                        name="attachments"
                      />
                      <span className="cs-primary">
                        <CapsyncIcon title="upload-files-filled" size="28" />
                      </span>
                      <span className="default-regular-sub-heading-s cs-neutral-90">
                        <span className="cs-primary">Browse</span>&nbsp;or drag
                        file here
                      </span>
                      <p className="default-regular-sub-heading-xxs">
                        Supports PDF, PNG, JPG and JPEG format
                      </p>
                    </div>
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {storageError || duplicateFileError}
                    </span>
                    <aside className="category-attachments-files">
                      <ul className="attachment-files cs-files">{files}</ul>
                    </aside>
                  </div>
                </Col>
              </Row>
            </>
          )}

          <div className="cs-modal-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={onFormClose}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="cs-btn-primary md-btn default-regular-h5"
              disabled={loading}
            >
              Save
              {loading && (
                <span className="cs-common-spinner cs-primary-main">
                  <CapsyncIcon title="loading-outlined" size="16" />
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default InstallmentAmountOther;
