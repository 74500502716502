import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NavDropdown, Nav, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { backAppURl } from "../applicationMode";
import CapsyncIcon from "./CapsyncIcon";
import UseFetchPermission from "../customHooks/UseFetchPermission";
import Avatar from "./Avatar";
import { avatarTitles } from "../config/avatar";

const NavSettings = ({ props, profileImg }) => {
  const fetchUserPermission = UseFetchPermission();
  const indivisualSetting = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const pfsAccessUser = window.localStorage.getItem("pfs_access_id");
  let id;
  const avatarState = useSelector((state) => state.avatar.avatarState)
  const [isLoading, setIsLoading] = useState(true);

  if (pfsAccessUser !== null) {
    id = pfsAccessUser;
  } else {
    id = user && user.user.id;
  }
  const [initials, setInitials] = useState("");
  const logoutFunc = () => {
    props.CapsyncLogoutModal(true);
  };
  const getInitials = (name) => {
    const nameArray = name.split(' ');
    const firstName = nameArray[0];
    const lastName = nameArray[nameArray.length - 1];
    const initial = firstName.charAt(0) + lastName.charAt(0);

    setInitials(initial.toUpperCase())

  };

  useEffect(() => {
    getInitials(user.user.displayName)
  }, [user.user])


  useEffect(() => {
    if (avatarState !== "" && avatarState !== "avtar-6" && avatarState !== user.user.profileImageUrl) {
      setInitials("")
    }
    else {
      getInitials(user.user.displayName)
    }
  }, [])

  useEffect(() => {
    if (profileImg !== undefined) {
      setIsLoading(false);
    }
  }, [profileImg]);

  return (
    <NavDropdown
      title={

        <>
          {isLoading ? (

            <div className="setting-image-loader">
              <span className="spinner-border spinner-border-sm"></span>
            </div>
          ) : (
            !!user.user.profileImageUrl && user.user.profileImageUrl.includes('avtar') ?
              <div className={` ${user.user.profileImageUrl === 'avtar-6' ? 'avatar-initials' : ''} `}>
                <Avatar title={user.user.profileImageUrl} size={40} />
                {(user.user.profileImageUrl === 'avtar-6') && (
                  <div className="initials-overlay default-regular-h5 cs-neutral-80">{initials} </div>
                )}
              </div> : user.user.profileImageUrl === null ?
                <>
                  <Avatar title="avtar-6" size={40} />
                  <div className="initials-overlay default-regular-h5 cs-neutral-80">{initials}</div>
                </>
                : <Image
                  className="profile-img"
                  src={props.customImgSrc !== "" ? props.customImgSrc
                    : user.user.profileImageUrl ? `${backAppURl}/api/${user.user_role.role === 1 ? 'user' : 'wealthmanager'}/profile/${user.user.id}?img=${user.user.profileImageUrl}`
                      : require("../../assets/images/other/face.png")
                  }
                  alt="Profile"
                  width={40}
                  height={40}
                />
          )}
        </>
      }
      className="cs-settings-dropdown"
      id="nav-avatar"
    >
      {
        fetchUserPermission.to_access_permission === 1 &&
          pfsAccessUser !== null ? (
          <NavDropdown.Item
            className={`profile-item ${[
              "/individual/account-details",
              "/individual/file-encryption",
              "/individual/mfa-setup",
              "/individual/billing-subscription",
              "/individual/audit-logs",
            ].includes(window.location.pathname)
              ? "selected-profile-item"
              : ""
              }`}
            onClick={() => {
              indivisualSetting("/individual/billing-subscription");
              props.dashactive.current = false;
              props.assetactive.current = false;
              props.liabiliactive.current = false;
              props.requestactive.current = false;
              props.collaborationactive.current = false;
              props.verifyinvestoractive.current = false;



            }}
          >
            <Nav.Link className="profile-item-link" id="nav-avatar-setting">
              {[
                "/individual/account-details",
                "/individual/file-encryption",
                "/individual/mfa-setup",
                "/individual/billing-subscription",
                "/individual/audit-logs",
              ].includes(window.location.pathname) ? (
                <>
                  <span className="selected-nav-icon">
                    <CapsyncIcon title="settings-filled" size="22" />
                  </span>
                  <span className="default-regular-h5 selected-nav-item">
                    Settings
                  </span>
                </>
              ) : (
                <>
                  <CapsyncIcon title="settings-outlined" size="22" />

                  <span className="default-regular-h5">Settings</span>
                </>
              )}
            </Nav.Link>
          </NavDropdown.Item>
        ) : fetchUserPermission.to_access_permission === 2 &&
          pfsAccessUser !== null ? (
          <>
            <NavDropdown.Item
              className={`profile-item ${[
                "/individual/account-details",
                "/individual/file-encryption",
                "/individual/mfa-setup",
                "/individual/billing-subscription",
                "/individual/audit-logs",
              ].includes(window.location.pathname)
                ? "selected-profile-item"
                : ""
                }`}
              onClick={() => {
                indivisualSetting("/individual/file-encryption");
                props.dashactive.current = false;
                props.assetactive.current = false;
                props.liabiliactive.current = false;
                props.requestactive.current = false;
                props.collaborationactive.current = false;
                props.verifyinvestoractive.current = false;

              }}
            >
              <Nav.Link className="profile-item-link" id="nav-avatar-setting">
                {[
                  "/individual/account-details",
                  "/individual/file-encryption",
                  "/individual/mfa-setup",
                  "/individual/billing-subscription",
                  "/individual/audit-logs",
                ].includes(window.location.pathname) ? (
                  <>
                    <span className="selected-nav-icon">
                      <CapsyncIcon title="settings-filled" size="22" />
                    </span>
                    <span className="default-regular-h5 selected-nav-item">
                      Settings
                    </span>
                  </>
                ) : (
                  <>
                    <CapsyncIcon title="settings-outlined" size="22" />

                    <span className="default-regular-h5">Settings</span>
                  </>
                )}
              </Nav.Link>
            </NavDropdown.Item>
          </>
        ) : (
          <NavDropdown.Item
            className={`profile-item ${[
              "/individual/account-details",
              "/individual/file-encryption",
              "/individual/mfa-setup",
              "/individual/billing-subscription",
              "/individual/audit-logs",
            ].includes(window.location.pathname)
              ? "selected-profile-item"
              : ""
              }`}
            onClick={() => {
              indivisualSetting("/individual/account-details");
              props.dashactive.current = false;
              props.assetactive.current = false;
              props.liabiliactive.current = false;
              props.requestactive.current = false;
              props.collaborationactive.current = false;
              props.verifyinvestoractive.current = false;
            }}
          >
            <Nav.Link className="profile-item-link" id="nav-avatar-setting">
              {[
                "/individual/account-details",
                "/individual/file-encryption",
                "/individual/mfa-setup",
                "/individual/billing-subscription",
                "/individual/audit-logs",
              ].includes(window.location.pathname) ? (
                <>
                  <span className="selected-nav-icon">
                    <CapsyncIcon title="settings-filled" size="22" />
                  </span>
                  <span className="default-regular-h5 selected-nav-item">
                    Settings
                  </span>
                </>
              ) : (
                <>
                  <CapsyncIcon title="settings-outlined" size="22" />

                  <span className="default-regular-h5">Settings</span>
                </>
              )}
            </Nav.Link>
          </NavDropdown.Item>
        )
      }

      {
        // !pfsAccessUser && (
        <NavDropdown.Item className="profile-item" onClick={logoutFunc}>
          <Nav.Link className="profile-item-link" id="nav-avatar-logout">
            <CapsyncIcon title="logout-outlined" size="22" />
            <span className="default-regular-h5">Log out</span>
          </Nav.Link>
        </NavDropdown.Item>
        // )
      }
    </NavDropdown >
  );
};

export default NavSettings;
