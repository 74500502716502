import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Image } from "react-bootstrap";
import SuccessValidation from "../../../../../assets/images/other/success-validation.svg";

const InitialSetup4 = (props) => {
  const { changeCounter, refreshAsset, refreshLiability, closeTheModal, getCategoryTotal } = props;

  const notNowClicked = localStorage.getItem("notNowClicked");

  const { user } = useSelector((state) => state.auth);
  let id;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const changeModal = () => {
    changeCounter(1);
    refreshAsset();
    // refreshLiability();
  };

  const profileModalButton = () => {
    closeTheModal(false, true);
    changeCounter(0);
    refreshAsset();
    // refreshLiability();
  };
  useEffect(() => {
    getCategoryTotal()
  }, [])

  return (

    <>
      {notNowClicked ? ("") : (
        <>
          <Modal
            show={true}
            onHide={() => profileModalButton()}
            size="cs-md-modal"
            backdrop="static"
            id="connect-your-institutions"
            aria-labelledby="contained-modal-title-vcenter"
            className="cap-dash-main-modal plaid-modal plaid-progress-modal"
            centered
            keyboard={false}
            dialogClassName="cs-md-modal"
          >
            <Modal.Header>
              <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
                Account(s) successfully linked
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="succes-smodal-body default-regular-sub-heading-m cs-neutral-90">
                <span className="account-success-img">
                  <Image
                    src={SuccessValidation}
                    alt="Success Validation"
                    width={84}
                    height={84}
                  />
                </span>
                <p className="text-center default-light-body-text-m cs-neutral-90">
                  You've successfully linked your account. Link another account to your portfolio?
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="cs-modal-btn cs-center-btn">
                <Button
                  className="cs-btn-secondary md-btn default-regular-h5 "
                  onClick={profileModalButton}
                >
                  Not now
                </Button>
                <Button
                  className="cs-btn-primary md-btn default-regular-h5"
                  onClick={changeModal}
                >
                  Link new account
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default InitialSetup4;