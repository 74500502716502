import { assetsCategory, liabilitiesCategory } from "../config/finance";

const getFinanceData = (categoryData, type, payInstallAuto, date) => {
  const financeData = [];
  const categoryList = type === "assets" ? assetsCategory : liabilitiesCategory;

  let newDataObject = {};
  categoryData && Array.isArray(categoryData) && categoryData.forEach(item => {
    const key = Object.keys(item)[0];
    const value = item[key];
    newDataObject[key] = value;
  });

  let newDateObject = {};
  date && Array.isArray(date) && date.forEach(item => {
    newDateObject[Object.keys(item)[0]] = item.date;
  });


  categoryList.map((category) => {


    const balance = newDataObject && newDataObject[category.url];

    const categoryObj = category;
    const { title, url } = categoryObj;
    const financeObj = {
      title,
      url: `${url}`,
      balance: balance === undefined ? 0 : balance,
      pay: payInstallAuto,
      // date: date
      //   ? date.automationDate && date.automationDate[category.catDate]
      //   : null,
      date: newDateObject
        ? newDateObject && newDateObject[category.url]
        : null,

    };

    if (type === "liabilities") {
      if (category.category === "installmentAuto") {
        financeObj.pay = categoryData && categoryData.installmentAutoMonthlyPayment;
      } else if (category.category === "installmentAcc") {
        financeObj.pay = categoryData && categoryData.installmentAccPayment;
      } else if (category.category === "mortgageOnRealestate") {
        financeObj.pay = categoryData && categoryData.liabilityMortagegeRealEstateMonthlyPayment;
      }
      else if (category.category === "accountPayable") {
        financeObj.pay = categoryData && categoryData.liabilityAccountsPayablesMonthlyPayment;
      } else if (category.category === "notesPayable") {
        financeObj.pay = categoryData && categoryData.liabilityNotesPayablesMonthlyPayment;
      }
    }

    financeData.push(financeObj);
  });
  return financeData;
};

const totalBalanceColumn = (columnCount) => {
  let arr = [];
  for (let i = 0; i < columnCount; i++) {
    arr.push(i + 1)
  }
  return arr;
}

export { getFinanceData, totalBalanceColumn };
