import API from "../util/api";
import { headerAddToken } from "../util/common";

const access_pfs_add_user = async (values) => {
  let header = await headerAddToken();
  return API.post("/invitation/add_user", values, header);
};

const pfs_re_invitation = async (values) => {
  let header = await headerAddToken();
  return API.post("/invitation/pfs_re_invitation", values, header);
};

const access_pfs_AcceptDeny = async (values) => {
  let header = await headerAddToken();
  return API.post("/invitation/accept_decline", values, header);
};

const access_invitation_Pfs = async (values) => {
  let header = await headerAddToken();
  return API.post("/invitation/fetchpfs", values, header);
};

const access_pfs_getPermission = async (values) => {
  let header = await headerAddToken();
  return API.post("/invitation/get_permission", values, header);
};

const access_pfs_fetchAll = async (id) => {
  let header = await headerAddToken();
  return API.get(`/invitation/fetchall/${id}`, header);
};
const access_pfs_deleteUser = async (values) => {
  let header = await headerAddToken();
  return API.post(`/invitation/delete`, values, header);
};
const wm_delete_individual_account_from_company = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/invitation/wm_delete_individual_account_from_company",
    values,
    header
  );
};

const wm_delete_individual_account_from_ind = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/invitation/wm_delete_individual_account_from_ind",
    values,
    header
  );
};

const access_indiPFS_fromWealth = async (values) => {
  let header = await headerAddToken();
  return API.post("/invitation/fetch_user", values, header);
};

const access_indiPFS_fromWealth_without_pfsid = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/invitation/access_indiPFS_fromWealth_without_pfsid",
    values,
    header
  );
};

const change_permission = async (values) => {
  let header = await headerAddToken();
  return API.post("/invitation/change_permission", values, header);
};

const recently_viewed = async (values) => {
  let header = await headerAddToken();
  return API.post("/invitation/recently", values, header);
};

const add_recently = async (values) => {
  let header = await headerAddToken();
  return API.post("/invitation/add_recently", values, header);
};

const company_recently = async (values) => {
  let header = await headerAddToken();
  return API.post("/invitation/company_recently", values, header);
};

const company_add_recently = async (values) => {
  let header = await headerAddToken();
  return API.post("/invitation/company_add_recently", values, header);
};

const pfs_access_service = {
  access_pfs_add_user,
  pfs_re_invitation,
  access_pfs_AcceptDeny,
  access_invitation_Pfs,
  access_pfs_fetchAll,
  access_pfs_deleteUser,
  access_indiPFS_fromWealth,
  access_indiPFS_fromWealth_without_pfsid,
  change_permission,
  access_pfs_getPermission,
  wm_delete_individual_account_from_company,
  wm_delete_individual_account_from_ind,
  recently_viewed,
  add_recently,
  company_recently,
  company_add_recently,
};

export default pfs_access_service;
