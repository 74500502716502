/* eslint-disable no-lone-blocks */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

// COMPONENT IMPORTS
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import {
  getFileIcon,
  handleS3Upload,
  headerAddToken,
  replaceLastOccurrence,
  supportedExtensions,
} from "../../../util/common";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import {
  file_add,
  get_user_storage,
  setProgressUploadDetails,
  showUploadProgress,
  useProgressUploadDetails,
} from "../../../slices/fileManagement.slice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { show_categories } from "../../../slices/category.slice";

const GridTableView = ({
  data,
  type,
  category,
  isSelected,
  selectedRows,
  openDropdownId,
  storage,
  setFileStorageModal,
  setOpenDropdownId,
  CapsyncDeleteMessage,
  viewOnlyPermission,
  handleFileDownload,
  handleRestore,
  handleProtectedFileDownload,
  handleArchive,
  setRenameFolderDetails,
  setRenameFolder,
  setCreateFolderModal,
  setRenameFile,
  handleClick,
  handleFileDelete,
  handleRowDoubleClick,
  handleMove,
  parentId,
  storageData,
  categoryDetails,
  setSelectedFiles,
  setCategoryDetails,
  setExistingFiles,
  setRemainingFiles,
  setFetchLoading,
  isFileUploadAllow,
  setExistingModal,
  fetchFilesList,
}) => {
  // const navDrop = useRef();
  // useOutsideClick(navDrop, () => setOpenDropdownId(null));

  const { user } = useSelector((state) => state.auth);
  const progressUploadDetails = useProgressUploadDetails();
  const [clickTimeout, setClickTimeout] = useState(null);

  const dropZoneRef = useRef(null);
  const [dragging, setDragging] = useState(false);

  const dispatch = useDispatch();

  const categoryData = useSelector((state) => state.category);
  const { categoryListing } = categoryData;

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  useEffect(() => {
    if (
      [...categoryListing.assets, ...categoryListing.liabilities].length ===
        0 &&
      dragging
    ) {
      dispatch(show_categories());
    }
  }, [categoryListing]);

  useEffect(() => {
    if (
      category &&
      category.length &&
      category[category.length - 1].row_id !== null &&
      category[category.length - 1].category_id !== null &&
      categoryListing
    ) {
      let categoryData = [
        ...categoryListing.assets.map((val) => ({
          ...val,
          category_name: "assets",
        })),
        ...categoryListing.liabilities.map((val) => ({
          ...val,
          category_name: "liabilities",
        })),
      ].filter((val) => val.id === category[category.length - 1].category_id);
      setCategoryDetails(categoryData[0]);
    }
  }, [category, categoryListing]);

  const toggleDropdown = (id, e) => {
    e.stopPropagation();
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };
  const handleSingleClick = (document, event, index) => {
    if (!event.detail || event.detail === 1) {
      handleClick(document, event, index);
    }
  };

  const handleClickEvents = (document, event, index) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout);
      handleSingleClick(document, event, index);
      setClickTimeout(null);
    } else {
      setClickTimeout(
        setTimeout(() => {
          handleRowDoubleClick && handleRowDoubleClick(document);
          setClickTimeout(null);
        }, 300)
      );
    }
  };

  const validateDuplicateFileName = (newFiles) => {
    let totalNewFileSize = 0;
    let totalExistingFileSize = 0;
    let errorDetected = false;

    const isCategoryUpload =
      category[category.length - 1].row_id !== null &&
      category[category.length - 1].category_id !== null;

    const supportedExt = isCategoryUpload
      ? [".pdf", ".png", ".jpg", ".jpeg"]
      : [...supportedExtensions];

    // Calculate total size of new files
    for (const newFile of newFiles) {
      if (newFile.size === 0) {
        toast.error("This file contains no data");
        errorDetected = true;
        break;
      }
      if (
        category[category.length - 1].row_id !== null &&
        category[category.length - 1].category_id !== null
      ) {
        const fileExtension = newFile.name.slice(
          ((newFile.name.lastIndexOf(".") - 1) >>> 0) + 2
        );
        const notAllowedExtensionHandling =
          fileExtension === "" ||
          fileExtension === null ||
          fileExtension === undefined;
        if (
          notAllowedExtensionHandling ||
          !supportedExt.includes(`.${fileExtension.toLowerCase()}`)
        ) {
          toast.error("Format not supported");
          return false;
        }
      }

      // if (!isValidFileName(newFile.name)) {
      //   toast.error(
      //     'Special characters ([, <. , {, ^, }, %, `, ], ", >, ~, #, |, Non-printable ASCII characters) are not allowed in file name.'
      //   );
      //   errorDetected = true;
      //   break;
      // }

      totalNewFileSize += newFile.size;

      if (errorDetected) {
        break;
      }
    }

    // Check total file size against the limit
    const totalFileSize = totalNewFileSize + totalExistingFileSize;
    const maxSizeAllowed =
      process.env.REACT_APP_FILE_UPLOAD_SIZE_ALLOWED_FILE_MANAGEMENT ||
      209715200; // Default to 200MB if env variable is not set
    const maxSizeBytes = parseInt(maxSizeAllowed);

    if (!errorDetected && totalFileSize > maxSizeBytes) {
      const maxSizeMB = maxSizeBytes / (1024 * 1024);
      toast.error(`Total file size greater than ${maxSizeMB} MB not allowed`);
      errorDetected = true;
    }

    return !errorDetected;
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (validateDuplicateFileName(droppedFiles) && isFileUploadAllow) {
      const files = droppedFiles.filter((file) => file.type);
      files.length && handleFileSave(files);
    }
    setDragging(false);
  };

  const collectAllFileNames = (fileArr) => {
    const allFileNames = new Set([
      ...data.map((file) => file.name.trim()),
      ...fileArr.map((file) => file.name.trim()),
    ]);

    // Include file names from progressUploadDetails
    progressUploadDetails.forEach((detail) => {
      if (detail.parentId === parentId) {
        detail.files.forEach((file) => {
          allFileNames.add(file.trim());
        });
      }
    });

    return allFileNames;
  };

  const handleFileSave = async (selectedFiles) => {
    let header = await headerAddToken();
    let uId = Date.now();
    try {
      const emptyFileArr = [];
      let fileNames = [];
      selectedFiles.forEach((file) => {
        emptyFileArr.push(file);
      });

      let totalFileSize =
        selectedFiles &&
        selectedFiles.reduce((acc, item) => acc + item.size, 0);

      if (category[category.length - 1].check_double_space) {
        totalFileSize = totalFileSize * 2;
      }

      const uploadedData = storageData && storageData.usedSpace;
      const maxLimitData = storageData && storageData.maxSpace;
      const inProgressData = progressUploadDetails.length
        ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
        : 0;

      if (uploadedData + totalFileSize + inProgressData > maxLimitData) {
        setFileStorageModal(true);
        setSelectedFiles([]);
        // setFileModal(false);
        // setDuplicateFileError(
        //   "Please increase your storage or remove some files."
        // );
        return;
      }

      const existingFileNames = new Set(data.map((value) => value.name));

      const existingFile = selectedFiles.filter((items) => {
        return existingFileNames.has(items.name);
      });

      const remainingFile = selectedFiles.filter((items) => {
        return !existingFileNames.has(items.name);
      });

      let response;
      if (
        category[category.length - 1].row_id !== null &&
        category[category.length - 1].category_id !== null
      ) {
        if (existingFile.length > 0) {
          toast.error(
            "Please note that there are files already existing. Kindly remove these files."
          );
          return;
        }
        const formData = new FormData();
        formData.append("institution", category[category.length - 1].title);
        formData.append("user_id", user.user.id);
        formData.append("used_space", storageData.usedSpace);
        formData.append("row_id", category[category.length - 1].row_id);
        for (let i = 0; i < emptyFileArr.length; i++) {
          const file = emptyFileArr[i];
          formData.append("attachments", file);
          fileNames.push(file.name);
        }
        const config = {
          ...header,
          onUploadProgress: (progressEvent) => {
            let total =
              progressEvent.total === progressEvent.loaded
                ? 100
                : (progressEvent.loaded * 100) / progressEvent.total;
            dispatch(
              showUploadProgress({
                files: [...fileNames],
                progress: Math.floor(total) > 90 ? 90 : Math.floor(total),
                id: uId,
                isDelete: false,
              })
            );
          },
        };
        // setFileModal(false);
        setSelectedFiles([]);
        setCategoryDetails(null);
        dispatch(
          setProgressUploadDetails({
            id: uId,
            files: [...fileNames],
            parentId: parentId,
            size: totalFileSize,
            isDelete: false,
          })
        );
        let res = await axios.post(
          `${process.env.REACT_APP_BACKEND}/api/${categoryDetails.category_name}/${categoryDetails.url}/file/update`,
          formData,
          config
        );
        response = res.data;
        dispatch(setProgressUploadDetails({ id: uId, isDelete: true }));
        if (response && response.code === 200) {
          await dispatch(get_user_storage({ id })).unwrap();
          setTimeout(() => {
            dispatch(
              showUploadProgress({
                files: [...fileNames],
                progress: Math.floor(100),
                id: uId,
                isDelete: false,
              })
            );
          }, 1000);
          setTimeout(() => {
            toast.success("Your file has been uploaded successfully.");
            dispatch(showUploadProgress({ id: uId, isDelete: true }));
            fetchFilesList();
          }, 1500);
          setSelectedFiles([]);
          setCategoryDetails(null);
        } else {
          setTimeout(() => {
            dispatch(showUploadProgress({ id: uId, isDelete: true }));
          }, 1000);
          setSelectedFiles([]);
          setCategoryDetails(null);
          toast.error(response.message);
        }
      } else {
        if (existingFile.length > 0) {
          // setFileModal(false);
          setExistingFiles(existingFile);
          setRemainingFiles(remainingFile);
          setExistingModal(true);
          setSelectedFiles([]);
          setCategoryDetails(null);
          return;
        }
        let fileArray = [];
        if (progressUploadDetails.length > 0) {
          const allFileNames = collectAllFileNames(emptyFileArr);
          let existing = [];
          let remaining = [];

          const checkNameIncludes = () => {
            emptyFileArr.forEach((file) => {
              const exists = progressUploadDetails.some(
                (obj) =>
                  obj.parentId === parentId && obj.files.includes(file.name)
              );
              if (exists) {
                existing.push(file);
              } else {
                remaining.push(file);
              }
            });
          };

          checkNameIncludes();

          if (existing.length > 0) {
            const newFiles = existing.map((items) => {
              let fileName = items.name.trim();
              const extension = fileName.split(/\.(?=[^\.]+$)/)[1];
              fileName = fileName.split(/\.(?=[^\.]+$)/)[0];

              const match = fileName.match(/ \((\d+)\)(?!.* \(\d+\))/);
              let newStr;

              if (match) {
                const number = parseInt(match[1]);
                let newCount = number + 1;
                do {
                  newStr = replaceLastOccurrence(
                    fileName,
                    `(${number})`,
                    `(${newCount})`
                  );
                  newCount++;
                } while (allFileNames.has(`${newStr}.${extension}`));
              } else {
                newStr = `${fileName} (1)`;
                if (allFileNames.has(`${newStr}.${extension}`)) {
                  const match = newStr.match(/ \((\d+)\)(?!.* \(\d+\))/);
                  let number = parseInt(match[1]);
                  let newCount = number + 1;
                  do {
                    newStr = replaceLastOccurrence(
                      newStr,
                      `(${number})`,
                      `(${newCount})`
                    );
                    number++;
                    newCount++;
                  } while (allFileNames.has(`${newStr}.${extension}`));
                }
              }

              newStr = `${newStr}.${extension}`;

              allFileNames.add(newStr);

              return new File([items], newStr, {
                type: items.type,
                lastModified: items.lastModified,
              });
            });

            fileArray = [...newFiles, ...remaining];

            for (let i = 0; i < [...newFiles, ...remaining].length; i++) {
              const file = [...newFiles, ...remaining][i];
              fileNames.push(file.name);
            }
          } else {
            for (let i = 0; i < emptyFileArr.length; i++) {
              const file = emptyFileArr[i];
              fileNames.push(file.name);
            }

            fileArray = [...emptyFileArr];
          }
        } else {
          for (let i = 0; i < emptyFileArr.length; i++) {
            const file = emptyFileArr[i];
            fileNames.push(file.name);
          }

          fileArray = [...emptyFileArr];
        }

        dispatch(
          setProgressUploadDetails({
            id: uId,
            files: [...fileNames],
            parentId: parentId,
            size: totalFileSize,
            isDelete: false,
          })
        );

        // setFileModal(false);
        setSelectedFiles([]);
        setCategoryDetails(null);

        const { successfulUploads, failedUploads } = await handleS3Upload(
          fileArray,
          user.user.id,
          dispatch
        );
        setFetchLoading(true);
        let data = successfulUploads.map((val) => {
          return {
            name: val.file.name,
            size: val.file.size,
            attachment: val.result.Key,
          };
        });
        let payload = {
          parentId,
          data,
          userId: user.user.id,
        };
        let res = await dispatch(file_add(payload)).unwrap();

        dispatch(
          setProgressUploadDetails({
            id: uId,
            isDelete: true,
          })
        );

        if (res && res.code === 200) {
          await dispatch(get_user_storage({ id })).unwrap();
          toast.success("Your file has been uploaded successfully.");
          setSelectedFiles([]);
          setCategoryDetails(null);
        } else {
          setFetchLoading(false);
          toast.error(res.message);
          setSelectedFiles([]);
          setCategoryDetails(null);
        }
      }
    } catch (error) {
      console.log("error", error);

      setFetchLoading(false);
      setSelectedFiles([]);

      dispatch(
        setProgressUploadDetails({
          id: uId,
          isDelete: true,
        })
      );

      setTimeout(() => {
        dispatch(showUploadProgress({ id: uId, isDelete: true }));
      }, 1000);
    }
  };

  // Handle the dragenter event (fires when the dragged item enters the drop zone)
  const handleDragEnter = (event) => {
    event.preventDefault();
    setDragging(true);
    // if (event.dataTransfer.items) {
    // Check if the dragged item is a file and whether it's an image
    // const draggedItem = event.dataTransfer.items[0];
    // if (draggedItem.kind === "file") {
    //   dropZoneRef.current.style.cursor = "move";
    // setDragging(true);
    // } else {
    //   // Prevent default for anything other than valid images
    //   dropZoneRef.current.style.cursor = "not-allowed";
    // }
    // }
  };

  // Handle the dragleave event (fires when the dragged item leaves the drop zone)
  const handleDragLeave = (event) => {
    if (
      dropZoneRef.current &&
      !dropZoneRef.current.contains(event.relatedTarget)
    ) {
      setDragging(false);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      {data.length > 0 ? (
        <div
          ref={dropZoneRef} // Attach ref to the drop zone
          onDrop={handleDrop}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          className={`cs-dropzone cs-grid-dropzone ${
            dragging
              ? isFileUploadAllow
                ? "cs-dropzone-hover"
                : "cs-dropzone-hover-not-allowed"
              : ""
          }`}
        >
          <div className="cs-grid-view ">
            {data.map((document, index) => {
              const extension =
                document.name &&
                document.name.split(/\.(?=[^\.]+$)/)[1] &&
                document.name.split(/\.(?=[^\.]+$)/)[1].toLowerCase();
              return (
                <React.Fragment key={index}>
                  {(type == "document" ? !document.is_archive : document) && (
                    <div
                      key={index}
                      className={
                        isSelected(document.id) ? "cs-box selected" : "cs-box"
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                        isMobile
                          ? handleClickEvents(document, event, index)
                          : (!event.detail || event.detail === 1) &&
                            handleClick(document, event, index);
                      }}
                      onDoubleClick={() =>
                        !isMobile &&
                        handleRowDoubleClick &&
                        handleRowDoubleClick(document)
                      }
                      id={"grid-" + index}
                    >
                      <div className="file-preview">
                        {document.thumbnail_attachment ? (
                          <LazyLoadImage
                            src={document.thumbnail_attachment}
                            width={600}
                            height={400}
                            alt="Image Alt"
                          />
                        ) : document.attachment ? (
                          <span>{getFileIcon(extension, "72")}</span>
                        ) : (
                          <span className="cs-neutral-60">
                            <CapsyncIcon title="folder-icon-filled" size="72" />
                          </span>
                        )}
                      </div>
                      <div className="file-info file-management-dropdown">
                        <div className="file-title">
                          {document.attachment ? (
                            <span>{getFileIcon(extension, "22")}</span>
                          ) : (
                            <span className="cs-neutral-60">
                              <CapsyncIcon
                                title="folder-icon-filled"
                                size="22"
                              />
                            </span>
                          )}
                          <h4
                            className="default-regular-body-text-l cs-neutral-100"
                            title={document.name}
                          >
                            {document.name}
                          </h4>
                        </div>
                        {selectedRows &&
                          type == "document" &&
                          !selectedRows.length > 0 &&
                          (document.is_downloadable ||
                            document.is_movable ||
                            document.is_archive_allowed ||
                            document.is_delete_allowed) && (
                            <DropdownButton
                              key={index}
                              id={`dropdown-grid-${index}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleDropdown(document.id, e);
                              }}
                              show={openDropdownId === document.id}
                              title={
                                <span className="cs-neutral-80 cursor-pointer">
                                  <CapsyncIcon
                                    title="option-horizontal-filled"
                                    size="22"
                                  />
                                </span>
                              }
                              className="cs-settings-dropdown"
                            >
                              {document.is_downloadable && (
                                <Dropdown.Item
                                  eventKey="1"
                                  className=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (storage.usedSpace > storage.maxSpace) {
                                      setFileStorageModal(true);
                                    } else {
                                      handleFileDownload([document]);
                                    }
                                  }}
                                >
                                  <span>
                                    <CapsyncIcon
                                      title="download-outlined"
                                      size="22"
                                    />
                                  </span>
                                  <span>Download</span>
                                </Dropdown.Item>
                              )}
                              {/* {document.is_downloadable && (
                              <Dropdown.Item
                                eventKey="2"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleProtectedFileDownload([document]);
                                }}
                              >
                                <span>
                                  <CapsyncIcon
                                    title="protect-download-outlined"
                                    size="22"
                                  />
                                </span>
                                <span>Encrypt Download</span>
                              </Dropdown.Item>
                            )} */}
                              {!viewOnlyPermission && document.is_movable && (
                                <Dropdown.Item
                                  eventKey="3"
                                  className=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCreateFolderModal(true);
                                    if (document.attachment) {
                                      setRenameFile(true);
                                    } else {
                                      setRenameFolder(true);
                                    }
                                    setRenameFolderDetails(document);
                                  }}
                                >
                                  <span>
                                    <CapsyncIcon
                                      title="edit-image-outlined"
                                      size="22"
                                    />
                                  </span>
                                  <span>Rename</span>
                                </Dropdown.Item>
                              )}
                              {!viewOnlyPermission && document.is_movable && (
                                <Dropdown.Item
                                  eventKey="4"
                                  className=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleMove(document);
                                  }}
                                >
                                  <span>
                                    <CapsyncIcon
                                      title="move-outlined"
                                      size="22"
                                    />
                                  </span>
                                  <span>Move</span>
                                </Dropdown.Item>
                              )}
                              {!viewOnlyPermission &&
                                document.is_archive_allowed && (
                                  <Dropdown.Item
                                    eventKey="5"
                                    className=""
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleArchive(document);
                                    }}
                                  >
                                    <span>
                                      <CapsyncIcon
                                        title="archive-outlined"
                                        size="22"
                                      />
                                    </span>
                                    <span>Archive</span>
                                  </Dropdown.Item>
                                )}
                              {!viewOnlyPermission &&
                                document.is_delete_allowed && (
                                  <Dropdown.Item
                                    eventKey="6"
                                    className=""
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      CapsyncDeleteMessage(
                                        true,
                                        "",
                                        `Are you sure you want to delete this ${
                                          document.attachment
                                            ? "File"
                                            : "Folder"
                                        }? You can restore it later from the trash. Please confirm if you wish to proceed.`,
                                        () => handleFileDelete(document)
                                      );
                                    }}
                                  >
                                    <span>
                                      <CapsyncIcon
                                        title="delete-outlined"
                                        size="22"
                                      />
                                    </span>
                                    <span>Delete</span>
                                  </Dropdown.Item>
                                )}
                            </DropdownButton>
                          )}
                        {!viewOnlyPermission &&
                          selectedRows &&
                          !selectedRows.length &&
                          (type == "archive" || type == "trash") > 0 && (
                            <DropdownButton
                              key={index}
                              id={`dropdown-grid-${index}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleDropdown(document.id, e);
                              }}
                              show={openDropdownId === document.id}
                              title={
                                <span className="cs-neutral-80 cursor-pointer">
                                  <CapsyncIcon
                                    title="option-horizontal-filled"
                                    size="22"
                                  />
                                </span>
                              }
                              className="cs-settings-dropdown"
                            >
                              {" "}
                              <Dropdown.Item
                                eventKey="1"
                                className=""
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (storage.usedSpace > storage.maxSpace) {
                                    setFileStorageModal(true);
                                  } else {
                                    handleFileDownload([document]);
                                  }
                                }}
                              >
                                <span>
                                  <CapsyncIcon
                                    title="download-outlined"
                                    size="22"
                                  />
                                </span>
                                <span>Download</span>
                              </Dropdown.Item>
                              {/* <Dropdown.Item
                              eventKey="2"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleProtectedFileDownload([document]);
                              }}
                            >
                              <span>
                                <CapsyncIcon
                                  title="protect-download-outlined"
                                  size="22"
                                />
                              </span>
                              <span>Encrypt Download</span>
                            </Dropdown.Item> */}
                              <Dropdown.Item
                                eventKey="1"
                                className=""
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRestore(document);
                                }}
                              >
                                <span>
                                  <CapsyncIcon
                                    title="move-outlined"
                                    size="22"
                                  />
                                </span>
                                <span>Move</span>
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="2"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const message =
                                    type === "trash"
                                      ? `Are you sure you want to permanently delete this ${
                                          document.attachment
                                            ? "File"
                                            : "Folder"
                                        }? This action cannot be undone and the item cannot be restored. Please confirm if you wish to proceed.`
                                      : `Are you sure you want to delete this items? You can restore it later from the trash. Please confirm if you wish to proceed.`;
                                  CapsyncDeleteMessage(true, "", message, () =>
                                    handleFileDelete(document)
                                  );
                                }}
                              >
                                <span>
                                  <CapsyncIcon
                                    title="delete-outlined"
                                    size="22"
                                  />
                                </span>
                                <span>Delete</span>
                              </Dropdown.Item>
                            </DropdownButton>
                          )}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="manage-file-container no-records">
          <label className="cs-upload-files default-regular-h4">
            We don't have any active records to display
          </label>
        </div>
      )}
    </React.Fragment>
  );
};

export default GridTableView;
