import React, { useEffect, useState } from "react";
import moment from "moment";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import img from "../../../../assets/icons/svg/outlined/search-outlined.svg";
import { Link } from "react-router-dom";
import Actiondelete from "../../../../assets/icons/svg/outlined/delete-outlined.svg";
import edit from "../../../../assets/icons/svg/outlined/edit-outlined.svg";
import NumberFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";
import {
  wealth_submission_history_list,
  wealth_submission_history_delete,
} from "../../../slices/wealthManager.slice";
import DataTable from "react-data-table-component";
import AddModal from "./AddModal";
import UpdateModal from "./UpdateModal";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import UseFetchPermission from "../../../customHooks/UseFetchPermission";
import PermissionPrevent from "../../PermissionPrevent";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import CapsyncSectionHeader from "../../../commonComponent/CapsyncSectionHeader";
import "./ActivityHistory.css";
import CapsyncToolTip from "../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import { customStyles } from "../../../util/common";

const Submission = (props) => {
  const CapsyncDeleteMessage = props.CapsyncDeleteMessage;

  const [historyData, setHistoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState();
  const { fetchUserPermission } = UseFetchPermission();
  const [rowId, setRowId] = useState("");

  const [updateModalState, setUpdateModalState] = useState({
    show: false,
    hide: true,
  });

  const { user } = useSelector((state) => state.auth);
  let user_id;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    user_id = window.localStorage.getItem("pfs_access_id");
  } else {
    user_id = user && user.user.id;
  }
  let wm_id = user && user.user.id;

  const dispatch = useDispatch();

  const [modalState, setModalState] = useState({
    show: false,
    hide: true,
  });

  const getSubmissionHistory = () => {
    if (user_id) {
      dispatch(
        wealth_submission_history_list({ user_id: user_id, wm_id: wm_id })
      )
        .unwrap()
        .then((response) => {
          setHistoryData(response);
          setFilteredData(response);
        })
        .catch((err) => {
          props.toastMessage(err.message, "error");
        });
    }
  };

  const changeModalState = (s, h) => {
    setModalState({
      show: s,
      hide: h,
    });
  };

  useEffect(() => {
    getSubmissionHistory();
  }, [user_id]);

  //----------------------*********----------------//

  const deleteRecord = (params) => {
    CapsyncDeleteMessage(true, "Delete", "", () => deleteFunc(params));
  };

  function deleteFunc(params) {
    dispatch(wealth_submission_history_delete({ row_id: params }))
      .unwrap()
      .then(() => {
        props.toastMessage(
          "Your record has been deleted successfully. ",
          "success"
        );
        getSubmissionHistory();
      })
      .catch((err) => {
        props.toastMessage(err.message, "error");
      });
  }

  const updateRecord = (s, h, params) => {
    setRowId(params);
    setUpdateModalState({
      show: s,
      hide: h,
    });
  };

  useEffect(() => {
    const result = historyData.filter((rowData) => {
      return (
        rowData.application_type.toLowerCase().match(search.toLowerCase()) ||
        rowData.financial_institution
          .toLowerCase()
          .match(search.toLowerCase()) ||
        rowData.amount_applied_for.toString().match(search.toString()) ||
        rowData.submission_status.toLowerCase().match(search.toLowerCase()) ||
        moment(rowData.date).format("MM/DD/YYYY").match(search) ||
        rowData.notes.toLowerCase().match(search.toLowerCase()) ||
        rowData.loan_reference.match(search)
      );
    });
    setFilteredData(result);
  }, [search]);

  const columns = [
    {
      name: "Date",
      selector: "date",
      format: (row) => moment(row.date).format("MM/DD/YYYY"),
      sortable: true,
    },
    {
      name: "Application Type",
      selector: "application_type",
      format: (row) => row.application_type,
      sortable: true,
    },
    {
      name: "Financial Institution",
      selector: "financial_institution",
      format: (row) => row.financial_institution,
      sortable: true,
    },
    {
      name: "Amount Applied For",
      selector: "amount_applied_for",
      format: (row) => (
        <>
          <div className="dollar-value-gap">
            <span> $&nbsp;</span>

            <NumberFormat
              value={Number(row.amount_applied_for).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: "Reference",
      selector: "submission_status",
      sortable: true,
      format: (row) => (
        <span
          className={
            row.submission_status === "Rejected"
              ? "cs-badge cs-danger default-regular-body-text-xs"
              : row.submission_status === "Completed"
              ? "cs-badge cs-success default-regular-body-text-xs"
              : "cs-badge cs-warning default-regular-body-text-xs"
          }
        >
          {row.submission_status}
        </span>
      ),
    },
    {
      name: "Loan Reference #",
      selector: "loan_reference",
      format: (row) => row.loan_reference,
      sortable: true,
    },
    {
      name: "Notes",
      selector: "notes",
      format: (row) =>
        `${row.notes.substring(0, 10)}${row.notes.length > 10 ? "..." : ""}`,
      sortable: true,
    },
    {
      name: "Actions",
      selector: "id",
      format: (row) => (
        <div className="activity-action">
          <span
            className="cs-neutral-80"
            onClick={() => updateRecord(true, false, row.id)}
          >
            <CapsyncToolTip
              Child={"edit-outlined"}
              placement={"top"}
              size="18"
              message={"Edit Details"}
              type="icon"
            />
          </span>

          <span className="cs-danger" onClick={() => deleteRecord(row.id)}>
            <CapsyncToolTip
              Child={"delete-outlined"}
              placement={"top"}
              size="18"
              message={"Delete"}
              type="icon"
            />
          </span>
        </div>
      ),
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <>
      <AddModal
        modalState={modalState}
        changeModalState={changeModalState}
        getSubmissionHistory={getSubmissionHistory}
        toastMessage={props.toastMessage}
      />
      <UpdateModal
        rowId={rowId}
        updateModalState={updateModalState}
        updateRecord={updateRecord}
        getSubmissionHistory={getSubmissionHistory}
        toastMessage={props.toastMessage}
      />

      <section className="activity-history-main-section">
        <Container fluid>
          <div className="cs-section-topbar financial-advisor-topbar">
            <div className="mobile-add-head-button">
              <h1 className="default-semi-bold-h1 cs-neutral-100">
                Activity History
              </h1>
              <span
                className="cs-neutral-10 mobile-add-acc-icon"
                onClick={() => changeModalState(true, false)}
              >
                <CapsyncIcon title="add-filled" size="18" />
              </span>
            </div>
            <div className="cs-search-bar">
              <Form.Group className="cs-form-group">
                <Form.Control
                  type="text"
                  placeholder="Search by file or folder name"
                  value={search}
                  className={search && "cs-input-field-active"}
                  onChange={(event) => setSearch(event.target.value)}
                />
                <span className="input-field-icon cs-neutral-70">
                  <CapsyncIcon title="search-outlined" size="18" />
                </span>
              </Form.Group>
            </div>

            <Button
              type="submit"
              className="default-regular-h5 cs-btn-icon-primary desktop-add-button"
              onClick={() => changeModalState(true, false)}
            >
              <span className="cs-neutral-10">
                <CapsyncIcon title="add-filled" size="18" />
              </span>
              Add history
            </Button>
          </div>

          <div className="cs-data-table financial-activity-table">
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              paginationComponentOptions={paginationOptions}
              sortIcon={
                <>
                  <span className="cs-neutral-100">
                    <CapsyncIcon title="sort-outlined" size="22" />
                  </span>
                </>
              }
              customStyles={customStyles}
            />
          </div>
        </Container>
      </section>
    </>
  );
};
export default Submission;
