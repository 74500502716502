import React from 'react'
import { Table } from 'react-bootstrap'
import CapsyncToolTip from '../../../commonComponent/capsyncTooltip/CapsyncToolTip'
const ModalTable = ({ tableLabel, dataArray }) => {
    return (dataArray && dataArray.length !== 0 &&
        <div className="partners-details-table">
            <p className="default-regular-body-text-m cs-neutral-80">{tableLabel}</p>
            {<Table className="cs-table" responsive>
                <thead className="cs-thead">
                    <tr>
                        <th className="default-medium-sub-heading-m cs-neutral-100">Name</th>
                        <th className="default-medium-sub-heading-m cs-neutral-100">Email</th>
                    </tr>
                </thead>
                <tbody className="cs-tbody">
                    {dataArray && dataArray.map((data, rowIndex) => (
                        <tr key={rowIndex}>
                            <td key={rowIndex + 1} className='default-regular-sub-heading-m cs-neutral-90'>{`${data.firstName} ${data.lastName}`}</td>
                            <td key={rowIndex + 2} className='default-regular-sub-heading-m cs-neutral-90'>
                                {/* {data.email} */}
                                {data.email ? (
                                    data.email.length > 25 ? (
                                        <CapsyncToolTip
                                            Child={data.email.slice(0, 25) + "..."}
                                            placement={"top"}
                                            message={data.email}
                                            type="text"
                                        />
                                    ) : (
                                        data.email
                                    )
                                ) : (
                                    "-"
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>}
        </div>
    )
}

export default ModalTable