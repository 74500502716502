import React, { useEffect, useState } from "react";
import CapsyncPFSFilter from "./CapsyncPFSFilter";
import CapsyncBreadcrumb from "./CapsyncBreadcrumb";
import moment_timezone from "moment-timezone";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const lastSyncContentDisplayURLs = [
  "/individual",
  "/individual/assets",
  "/individual/assets/cashonhand",
  "/individual/assets/savingaccount",
  "/individual/assets/retirementaccount",
  "/individual/assets/accountnotesreceviable",
  "/individual/assets/lifeinsurance",
  "individual/assets/nonretirementbrokerage",
  "/individual/assets/realestate",
  "/individual/assets/automobile",
  "/individual/assets/business",
  "/individual/assets/personalproperty",
  "/individual/assets/otherassets",
  "/individual/liabilities",
  "/individual/liabilities/accountpayable",
  "/individual/liabilities/notespayabletobanksandothers",
  "/individual/liabilities/installmentsauto",
  "/individual/liabilities/installmentsaccount",
  "/individual/liabilities/lifeinsurance",
  "/individual/liabilities/mortgagesonrealestate",
  "/individual/liabilities/unpaidtaxes",
  "/individual/liabilities/otherliabilities",
  "/individual/assets/nonretirementbrokerage",
];

const CapsyncSectionHeader = ({
  pagetitle,
  assetTotal, //CapsyncPFSFilter
  liabilityTotal, //CapsyncPFSFilter
  mainFilterStatus, //CapsyncPFSFilter
  getCategoryTotal, //CapsyncPFSFilter
  getCategoryTotalAssets, //CapsyncPFSFilter
  getTableList, //CapsyncPFSFilter
  individualUserData, //CapsyncPFSFilter
  mainTitle, //CapsyncBreadcrumb
  subTitle, //CapsyncBreadcrumb
  mainUrl, //CapsyncBreadcrumb
}) => {
  const location = useLocation();

  const { lastSyncDate } = useSelector((state) => state.auth);

  const [lastSyncContent, setLastSyncContent] = useState("");

  useEffect(() => {
    if (lastSyncDate) {
      const isLastContentVisible = lastSyncContentDisplayURLs.find(
        (url) => url === location.pathname
      );
      if (isLastContentVisible) {
        setLastSyncContent(lastSyncDate);
      }
    } else {
      setLastSyncContent("");
    }
  }, [location.pathname, lastSyncDate]);

  return (
    <>
      {mainTitle && (
        <div className="cs-breadcrumb">
          <CapsyncBreadcrumb
            mainTitle={mainTitle}
            subTitle={subTitle}
            mainUrl={mainUrl}
          />
        </div>
      )}
      <div className="cs-title">
        {pagetitle && (
          <h1 className="default-semi-bold-h1 cs-neutral-100">{pagetitle}</h1>
        )}
        {/* {individualUserData && (
          <div className="cs-view-pfs-filter">
            <CapsyncPFSFilter
              assetTotal={assetTotal}
              liabilityTotal={liabilityTotal}
              mainFilterStatus={mainFilterStatus}
              getCategoryTotal={getCategoryTotal}
              getCategoryTotalAssets={getCategoryTotalAssets}
              getTableList={getTableList}
              individualUserData={individualUserData}
            />
          </div>
        )} */}
        {lastSyncContent && (
          <span className="default-regular-body-text-m cs-neutral-80">
            Last sync: {lastSyncContent}
          </span>
        )}
      </div>
    </>
  );
};

export default CapsyncSectionHeader;
