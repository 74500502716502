import React, { useEffect } from "react";
import { Button, Image } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";

const LeftPart = (props) => {
  const arrPath = [
    "/signin-mf-verification",
    "/signin-mf-auth-setup",
    "/mf-auth-emailpin",
    "/signup-mf-auth-phone-setup",
    "/mf-auth-phonepin",
    "/signup-mf-auth-google-setup",
    "/mf-auth-googlepin",
  ];

  const loginStatus = JSON.parse(localStorage.getItem("loginStatus"));
  const localUser = localStorage.getItem("user");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let reactClass = "";
    if (window.location.pathname === "/") {
      reactClass = "signin";
    } else if (props.page === "email-success") {
      reactClass = "email-success";
    } else {
      reactClass = window.location.pathname.substring(1).replaceAll("/", "-");
    }
    document.body.classList.add(reactClass);
    return () => {
      document.body.classList.remove(reactClass);
    };
  }, []);

  useEffect(() => {
    if (arrPath.indexOf(location.pathname) > -1) {
      if (!localUser) {
        return navigate("/");
      }
    }
  }, []);

  useEffect(() => {
    if (
      loginStatus &&
      loginStatus.loginStatus === true &&
      loginStatus.user_role === 1
    ) {
      return navigate("/individual");
    }
    if (
      loginStatus &&
      loginStatus.loginStatus === true &&
      (loginStatus.user_role === 2)
    ) {
      return navigate("/financial-advisor");
    }
    if (
      loginStatus &&
      loginStatus.loginStatus === true &&
      (loginStatus.user_role === 3)
    ) {
      return navigate("/professional");
    }
  }, [loginStatus]);



  return (
    <div className="auth-left-area">
      <div className="content-area">
        {props.title && <h2 className="default-semi-bold-h2">{props.title}</h2>}

        {props.text && (
          <p className="default-regular-sub-heading-m">{props.text}</p>
        )}
        <Link to="https://www.capsync.com" target="_blank" className="cs-btn-icon-secondary default-regular-h5">
          Learn More
        </Link>
      </div>
      <div className="auth-image-box">
        <Image
          src={
            !props.img && props.img !== ""
              ? require("../../../assets/images/auth/auth-image.svg")
              : require(`../../../assets/images/auth/${props.img}`)
          }
          alt="Capsync Authentication"
        />
      </div>
    </div>
  );
};

export default LeftPart;
