import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import "./Transactions.css"
import SkeletonSettingsTable from './../settings/SkeletonSettingsTable';

const SkeletonTransactions = () => {
    return (
        <>
            <div className="cs-section-topbar">
                <div className='amortization-skeleton-breadcrumb'>
                    <div className='skeleton-breadcrumb-1'> <Skeleton width={50} /></div>
                    <div className='skeleton-breadcrumb-2'> <Skeleton width={20} /></div>
                    <div className='skeleton-breadcrumb-3'><Skeleton width={150} /></div>
                    <div className='skeleton-breadcrumb-4'> <Skeleton width={20} /></div>
                    <div className='skeleton-breadcrumb-5'><Skeleton width={40} /></div>
                </div>
                <div className="cs-title">
                    <h1 className="default-semi-bold-h1 cs-neutral-100"><Skeleton width={200} /></h1>
                </div>
            </div>
            <div className="transaction-search-bar for-des">
                <Row>
                    <Col lg={8}>
                        <Skeleton width={150} height={40} />
                    </Col>
                    <Col lg={4}>
                        <div className='skeleton-search-download'>
                            <Skeleton width={200} height={40} />
                            <Skeleton width={130} height={40} />
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="transaction-search-bar for-mob">
                <Row>
                    <Col lg={12}>
                        <div className="transaction-search">
                            <div className="cs-search-bar">
                                <Skeleton width="95%" height={30} />
                            </div>
                            <Skeleton width={30} height={30} />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Skeleton width="100%" height={30} />
                    </Col>
                </Row>
            </div>
            <SkeletonSettingsTable tableData={[1, 2, 3, 4, 5, 6]} />
        </>
    )
}

export default SkeletonTransactions
