/* eslint-disable */
import React, { useCallback, useState } from "react";
import { Modal, Form, Row, Col, Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import DatePicker from "react-date-picker";
import NumberFormat from "react-currency-format";
import { useFormik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";

import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import validationObj from "./validationSchema";
import { adjustLength } from "../../../../customHooks/adjustLength";
import {
  categoryAdd,
  categoryUpdateRecord,
} from "../../../../slices/category.slice";
import commonNames from "../../../../config/commonNames";
import { useEffect } from "react";
import {
  addTimePeriod,
  checkPhoneValid,
  phoneHandleChange,
} from "../../../../util/common";
import { isValidPhoneNumber } from "react-phone-number-input";
import { validateDuplicateFileName } from "../../../../config/validateDuplicateFileName";
import {
  handleInterestValue,
  handleOwnershipKeyDown,
  handleOwnershipPaste,
  handleOwnershipValue,
} from "../../../../config/handleOwnershipValue";
import {
  get_user_storage,
  useProgressUploadDetails,
  useUploadStorage,
} from "../../../../slices/fileManagement.slice";

const NotesReceivable = (props) => {
  const { onFormClose, onFormSubmit, record, showModal } = props;
  const storageData = useUploadStorage();
  const progressUploadDetails = useProgressUploadDetails();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }
  // const notesReceivableType = [
  //     "Individual",
  //     "Bussiness"
  // ];
  const frequencyList = ["Yearly", "Monthly"];

  const [loading, setLoading] = useState(false);
  const [storeAcceptedFiles, setStoreAcceptedFiles] = useState([]);
  // const [type, setType] = useState(notesReceivableType[0]);
  const [frequency, setFrequency] = useState(frequencyList[0]);
  // const [frequency, setFrequency] = useState("");
  const [amortization, setAmortization] = useState(false);
  const [initialValues, setInitialValues] = useState({
    user_id: id,
    property_name: "",
    debtor_email: "",
    debtor_phonenumber: "",
    loan_amount: "",
    date_Loan: moment().format(),
    loan_period: "",
    frequency: frequencyList[0],
    annual_rate: "",
    payment: "",
    maturity_date: moment().format(),
    balance_due: "",
    date_updated: moment().format(),
    loan_type: "",
    collateral: "",
    notes: "",
  });

  const [phoneNo, setPhoneNo] = useState("");
  const [msg, setMsg] = useState("");
  const [duplicateFileError, setDuplicateFileError] = useState("");
  const [storageError, setStorageError] = useState("");

  // const checkType = (event) => {
  //     setType(event);
  // };
  const checkFrequency = (event) => {
    setFrequency(event);
    setFieldValue("frequency", event);
  };

  useEffect(() => {
    if (!!record) {
      setInitialValues({
        user_id: record.user_id,
        property_name: record.property_name,
        loan_amount:
          record.loan_amount && record.loan_amount.toLocaleString("en-US"),
        debtor_email: record.debtor_email,
        debtor_phonenumber: record.debtor_phonenumber,
        date_Loan: record.date_Loan,
        maturity_date: record.maturity_date,
        loan_period:
          record.loan_period && record.loan_period.toLocaleString("en-US"),
        annual_rate:
          record.annual_rate && record.annual_rate.toLocaleString("en-US"),
        payment: record.payment && record.payment.toLocaleString("en-US"),
        balance_due:
          record.balance_due && record.balance_due.toLocaleString("en-US"),
        ownership: record.ownership && record.ownership.toLocaleString("en-US"),
        date_updated: new Date(
          moment(record.date_updated).format("MM/DD/YYYY")
        ),
        loan_type: record.loan_type,
        collateral: record.collateral,
        notes: record.notes,
        frequency: record.frequency || frequencyList[0],
      });
      setAmortization(record.is_amortization_link_enable);
      setFrequency(record.frequency);
      record.creditor_phonenumber && setPhoneNo(record.creditor_phonenumber);
    }
  }, [record]);

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema:
        validationObj.asset_accountNotesReceivable_validationSchema,
      onSubmit: async (values) => {
        if (phoneNo && (!isValidPhoneNumber(phoneNo) || msg)) {
          setMsg("Enter a valid phone number");
          return true;
        }
        values.debtor_phonenumber = phoneNo;
        setLoading(true);
        record && (values.id = record.id);
        values.user_id = id;
        values.pfs_access_id = id ? id : 0;
        // values.loan_type = type;
        values.frequency = frequency;
        values.is_amortization_link_enable = amortization;
        if (values.date_Loan && values.loan_period && values.frequency)
          values.maturity_date = addTimePeriod(
            values.date_Loan,
            values.loan_period,
            values.frequency
          );
        const inProgressData = progressUploadDetails.length
          ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
          : 0;
        const used_space = storageData.usedSpace + inProgressData;

        const formdata = new FormData();
        formdata.append("user_details", JSON.stringify(values));
        formdata.append("user_id", values.user_id);
        formdata.append("used_space", used_space);
        formdata.append("institution", values.property_name);
        for (let i = 0; i < storeAcceptedFiles.length; i++) {
          formdata.append("attachments", storeAcceptedFiles[i]);
        }

        let data = {
          category: "accountnotesreceviable",
          main_category: "assets",
          used_space: used_space,
        };

        if (!!record) {
          data = { ...data, newData: values };
          dispatch(categoryUpdateRecord(data))
            .unwrap()
            .then((response) => {
              if (response.code === 200) {
                onFormSubmit("accountnotesreceviable");
                toast.success(response.message);
                props.dashboardAPi();
              }
              setLoading(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
            });
        } else {
          data = { ...data, formdata: formdata };
          dispatch(categoryAdd(data))
            .unwrap()
            .then(async (response) => {
              if (response.code === 200) {
                const response = await dispatch(
                  get_user_storage({ id })
                ).unwrap();
                onFormSubmit("accountnotesreceviable");
                // toast.success(response.message);
                props.dashboardAPi();
              } else if (response.code === 500) {
                setStorageError(response.message);
              }
              setLoading(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
            });
        }
      },
    });

  const handleChange = (e) => {
    setLoading(false);
    if (e.target.name === "annual_rate" && e.target.value > 100) {
      setFieldValue(e.target.name, "");
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
    setDuplicateFileError("");
  };

  // Upload File Handler
  const removeAcceptedFile = (params) => {
    let store = [...storeAcceptedFiles];
    store.splice(params, 1);
    setStoreAcceptedFiles(store);
    setDuplicateFileError("");
    setStorageError("");
  };
  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setDuplicateFileError("");
      setStorageError("");
      if (
        validateDuplicateFileName(
          acceptedFiles,
          storeAcceptedFiles,
          setDuplicateFileError
        )
      ) {
        setStoreAcceptedFiles([...acceptedFiles, ...storeAcceptedFiles]);
      }
    },
    [storeAcceptedFiles]
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    disabled: loading,
    onDropAccepted,
  });
  const files = storeAcceptedFiles.map((file, i) =>
    adjustLength(file.path, () => removeAcceptedFile(i))
  );

  function calculateAmortization(P, r, t, n) {
    let principal;
    if (P && (P === "" || P === undefined)) {
      principal = 0;
    } else {
      principal = P;
    }
    // let principal = P == "" ? 0 : P;
    const interestRate = r / 100;
    const ratePerPeriod = interestRate / n;

    const numerator = 1 - Math.pow(1 + ratePerPeriod, -(n * t));

    let TotalBalance = principal;
    // typeof (P) == "number" ?
    //     principal
    //     : Number(principal.replace(/,/g, ""))

    const monthlyPaymentt = (TotalBalance * ratePerPeriod) / numerator;

    let simpleInterest = monthlyPaymentt * (t * n);

    if (P == 0 || r == 0 || t == 0 || n == 0) {
      return 0;
    } else {
      return simpleInterest.toFixed(2);
    }
  }

  const handleChecked = (e) => {
    setAmortization(e.target.checked);
    setLoading(false);
  };

  return (
    <Modal
      show={showModal}
      backdrop="static"
      id="connect-your-institutions"
      aria-labelledby="contained-modal-title-vcenter"
      className="cap-dash-main-modal"
      centered
      keyboard={false}
      dialogClassName="cs-lg-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          {commonNames.NotesReceivable}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Name of Debtor</Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  name="property_name"
                  onKeyDown={(e) => {
                    !/^[a-zA-Z_ ]*$/.test(e.key) && e.preventDefault();
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.property_name}
                  className={values.property_name && "cs-input-field-active"}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                />
                {errors.property_name && touched.property_name ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.property_name}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="email"
                  placeholder="Optional"
                  name="debtor_email"
                  onKeyDown={(e) => e.code === "Space" && e.preventDefault()}
                  value={values.debtor_email}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  className={values.debtor_email && "cs-input-field-active"}
                />
                {errors.debtor_email && touched.debtor_email ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.debtor_email}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={6} sm={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Phone Number (Optional)</Form.Label>
                <PhoneInput
                  limitMaxLength={15}
                  international
                  countryCallingCodeEditable={false}
                  addInternationalOption={false}
                  placeholder="Optional"
                  autoComplete="off"
                  defaultCountry="US"
                  name="debtor_phonenumber"
                  withCountryCallingCode={false}
                  value={values.debtor_phonenumber}
                  // className={values.phoneNo && "cs-input-field-active"}
                  className="form-control"
                  countries={["US"]}
                  // onChange={(e) => checkPhoneValid(e, setPhoneNo, setMsg, setLoading)}
                  onChange={(e) => {
                    phoneHandleChange(e, setPhoneNo, setMsg, setLoading);
                  }}
                />
                {msg !== "" ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {msg}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Loan Amount</Form.Label>
                <NumberFormat
                  className={
                    values.loan_amount
                      ? "dollar-symbol text-md-end form-control cs-input-field-active"
                      : "text-md-end form-control"
                  }
                  displayType={"input"}
                  thousandSeparator={true}
                  placeholder=""
                  name="loan_amount"
                  autoComplete="off"
                  value={values.loan_amount}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  maxLength={12}
                  allowNegative={false}
                  decimalScale={2}
                  onKeyDown={(e) => {
                    if (e.key === "." && e.target.value === "") {
                      e.preventDefault(); // Prevent typing another dot if there's already one
                    }
                  }}
                />
                {errors.loan_amount && touched.loan_amount ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.loan_amount}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Loan Date</Form.Label>
                <DatePicker
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  maxDate={new Date()}
                  name="date_Loan"
                  value={values.date_Loan}
                  onChange={(e) => {
                    setLoading(false);
                    setFieldValue("date_Loan", e);
                  }}
                  // onBlur={handleBlur}
                  clearIcon={null}
                  className={
                    values.date_updated
                      ? "cs-input-field-active select-date-range cs-date-picker-calender"
                      : "cs-date-picker-calender"
                  }
                  calendarIcon={
                    <span className="cs-neutral-90">
                      <CapsyncIcon title="calender-outlined" size="18" />
                    </span>
                  }
                  format="MM/dd/y"
                />
                {errors.date_Loan ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.date_Loan}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Loan Term</Form.Label>
                <>
                  <div className="loan-term-box">
                    <div className="loan-term-input">
                      <NumberFormat
                        type="text"
                        autoComplete="off"
                        placeholder=""
                        name="loan_period"
                        onKeyDown={(e) => {
                          if (e.key === " " && e.target.selectionStart === 0) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={3}
                        decimalScale={0}
                        value={
                          values.loan_period
                          // values.loan_period !== 0 ? values.loan_period : ""
                        }
                        className={
                          values.loan_period
                            ? "form-control cs-input-field-active"
                            : "form-control"
                        }
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        allowNegative={false}
                      />
                    </div>
                    <div className="loan-term-dropdown cs-common-add-dropdown">
                      {/* <Dropdown onSelect={handleInputChange}> */}
                      <Dropdown>
                        <Dropdown.Toggle>
                          {frequency}
                          <span className="cs-neutral-90">
                            <CapsyncIcon
                              title="chevron-down-outlined"
                              size="18"
                            />
                          </span>
                        </Dropdown.Toggle>
                        {/* <Dropdown.Toggle>
                                                    {frequency === "" ? "Choose Term" : frequency.length > 8 ? frequency.slice(0, 10) + "..." : frequency}
                                                    <span className="cs-neutral-90">
                                                        <CapsyncIcon title="chevron-down-outlined" size="18" />
                                                    </span>
                                                </Dropdown.Toggle> */}
                        <Dropdown.Menu>
                          {frequencyList.map((item) => (
                            <Dropdown.Item
                              className={
                                item === frequency ? "cs-selected-dropdown" : ""
                              }
                              key={item}
                              onClick={() => {
                                setLoading(false);
                                checkFrequency(item);
                              }}
                            >
                              {item}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </>
                {errors.loan_period && touched.loan_period && (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.loan_period}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Interest Rate (%)</Form.Label>
                <NumberFormat
                  type="text"
                  autoComplete="off"
                  placeholder=""
                  name="annual_rate"
                  value={
                    values.annual_rate
                    // values.annual_rate !== 0 ? values.annual_rate : ""
                  }
                  onChange={handleChange}
                  onKeyDown={handleOwnershipKeyDown}
                  onPaste={handleOwnershipPaste}
                  // maxLength={3}
                  // onBlur={handleBlur}
                  className={
                    values.annual_rate
                      ? "form-control percentage-symbol cs-input-field-active"
                      : "form-control"
                  }
                  allowNegative={false}
                  // onKeyDown={handleInterestValue}
                  decimalScale={2}
                />
                {errors.annual_rate && touched.annual_rate ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.annual_rate}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group cs-disabled">
                <Form.Label>Maturity Date</Form.Label>
                <DatePicker
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  name="maturity_date"
                  value={
                    values.date_Loan && values.loan_period && values.frequency
                      ? addTimePeriod(
                          values.date_Loan,
                          values.loan_period,
                          values.frequency
                        )
                      : ""
                  }
                  onChange={(e) => {
                    setLoading(false);
                    setFieldValue("maturity_date", e);
                  }}
                  disabled={true}
                  // onBlur={handleBlur}
                  clearIcon={null}
                  className={
                    values.date_updated
                      ? "cs-input-field-active select-date-range cs-date-picker-calender"
                      : "cs-date-picker-calender"
                  }
                  calendarIcon={
                    <span className="cs-neutral-90">
                      <CapsyncIcon title="calender-outlined" size="18" />
                    </span>
                  }
                  format="MM/dd/y"
                />
                {errors.maturity_date ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.maturity_date}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group cs-disabled">
                <Form.Label>Payment</Form.Label>
                <NumberFormat
                  className={
                    values.payment
                      ? "dollar-symbol text-md-end form-control cs-input-field-active"
                      : "text-md-end form-control"
                  }
                  placeholder=""
                  // value={values.payment}
                  value={
                    (values.payment =
                      values.loan_amount &&
                      values.annual_rate &&
                      values.annual_rate > 0 &&
                      frequency
                        ? calculateAmortization(
                            Number(values.loan_amount.replace(/,/g, "")),
                            values.annual_rate,
                            frequency === "Yearly"
                              ? values.loan_period
                              : values.loan_period / 12,
                            12
                          )
                        : values.loan_amount)
                  }
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  name="payment"
                  displayType={"input"}
                  thousandSeparator={true}
                  autoComplete="off"
                  maxLength={12}
                  disabled={true}
                />
                {errors.payment ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.payment}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            {/* )} */}
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Balance Due</Form.Label>
                <NumberFormat
                  className={
                    values.balance_due
                      ? "dollar-symbol text-md-end form-control cs-input-field-active"
                      : "text-md-end form-control"
                  }
                  displayType={"input"}
                  thousandSeparator={true}
                  placeholder=""
                  name="balance_due"
                  autoComplete="off"
                  value={values.balance_due !== 0 ? values.balance_due : ""}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  maxLength={12}
                  decimalScale={2}
                  onKeyDown={(e) => {
                    if (e.key === "." && e.target.value === "") {
                      e.preventDefault(); // Prevent typing another dot if there's already one
                    }
                  }}
                />
                {errors.balance_due && touched.balance_due ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.balance_due}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Date Updated</Form.Label>
                <DatePicker
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  name="date_updated"
                  value={values.date_updated}
                  onChange={(e) => {
                    setLoading(false);
                    setFieldValue("date_updated", e);
                  }}
                  // onBlur={handleBlur}
                  clearIcon={null}
                  className={
                    values.date_updated
                      ? "cs-input-field-active select-date-range cs-date-picker-calender"
                      : "cs-date-picker-calender"
                  }
                  calendarIcon={
                    <span className="cs-neutral-90">
                      <CapsyncIcon title="calender-outlined" size="18" />
                    </span>
                  }
                  format="MM/dd/y"
                  maxDate={new Date()}
                />
                {errors.date_updated && touched.date_updated ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.date_updated}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group ">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  placeholder="Optional"
                  name="loan_type"
                  onKeyDown={(e) => {
                    !/^[a-zA-Z_ ]*$/.test(e.key) && e.preventDefault();
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.loan_type}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  className={values.loan_type && "cs-input-field-active"}
                />
                {errors.loan_type && touched.loan_type ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.loan_type}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Collateral</Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  placeholder="Optional"
                  name="collateral"
                  onKeyDown={(e) => {
                    !/^[a-zA-Z_ ]*$/.test(e.key) && e.preventDefault();
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.collateral}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  className={values.collateral && "cs-input-field-active"}
                />
                {errors.collateral && touched.collateral ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.collateral}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              {/* <span className="checkboxREalestate"> */}

              {/* </span> */}
              <Form className="cs-form-group amortization-schedule-box">
                <Form.Check
                  id="amortizationadd"
                  type="checkbox"
                  aria-label="option 1"
                  checked={amortization}
                  onChange={(e) => handleChecked(e)}
                  className="cs-form-check-box"
                  label={
                    <span
                      htmlFor="amortizationadd"
                      className="cursor-pointer default-regular-h5 cs-primary"
                    >
                      Create Amortization Schedule
                    </span>
                  }
                />
              </Form>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Notes</Form.Label>
                <textarea
                  autoComplete="off"
                  type="text"
                  placeholder="Optional"
                  name="notes"
                  value={values.notes}
                  className={
                    values.notes
                      ? "cs-input-field-active cs-textarea"
                      : "cs-textarea"
                  }
                  onChange={handleChange}
                  // onBlur={handleBlur}
                ></textarea>
              </Form.Group>
            </Col>
          </Row>
          {!record && (
            <>
              <Row>
                <Col>
                  <Form.Label className="default-regular-sub-heading-xs cs-neutral-100">
                    Upload Files
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="cs-upload-box">
                    <div
                      {...getRootProps({})}
                      className={`cs-upload-files cursor-pointer ${
                        loading && "cs-neutral-60 disabled"
                      }`}
                    >
                      <input
                        {...getInputProps()}
                        disabled={loading}
                        name="attachments"
                      />
                      <span className="cs-primary">
                        <CapsyncIcon title="upload-files-filled" size="28" />
                      </span>
                      <span className="default-regular-sub-heading-s cs-neutral-90">
                        <span className="cs-primary">Browse</span>&nbsp;or drag
                        file here
                      </span>
                      <p className="default-regular-sub-heading-xxs">
                        Supports PDF, PNG, JPG and JPEG format
                      </p>
                    </div>
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {storageError || duplicateFileError}
                    </span>
                    <aside className="category-attachments-files">
                      <ul className="attachment-files cs-files">{files}</ul>
                    </aside>
                  </div>
                </Col>
              </Row>
            </>
          )}

          <div className="cs-modal-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={onFormClose}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="cs-btn-primary md-btn default-regular-h5"
              disabled={loading}
            >
              Save
              {loading && (
                <span className="cs-common-spinner cs-primary-main">
                  <CapsyncIcon title="loading-outlined" size="16" />
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default NotesReceivable;
