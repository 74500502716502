import React from 'react'
import Skeleton from 'react-loading-skeleton'

const LineChartSkeleton = () => {
    return (
        <div>
            <div className="chart-heading">
                <h2 className="default-semi-bold-h2 cs-neutral-100"> <Skeleton width={200} /></h2>
                <span className="default-regular-body-text-s cs-neutral-70">
                    <Skeleton width={150} />
                </span>
            </div>
            <div className="skeleton-graph-line-chart-main-container graph-line-chart-main-container">

                <div className="skeleton-line-chart-container">
                    <div className="skeleton-line-legends"> <Skeleton width={100} /></div>
                    <div className="skeleton-line-chart for-des">
                        <Skeleton height={350} />
                    </div>
                    <div className="skeleton-line-chart for-mob">
                        <Skeleton height={290} />
                    </div>

                </div>

            </div>
        </div>
    )
}


export default LineChartSkeleton