import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form, Image, Button } from "react-bootstrap";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import { fetch_all_external_attachments } from "../../../../slices/requests";
import { useDispatch, useSelector } from "react-redux";

export default function ExternalAttachments({
  ext_attachment,
  removeInputField,
  changeDocumentInputVal,
  handleUploadAttachment,
  setDocumentName,
  ext_Files,
  documentName,
  inputRef,
  setComments,
  comments,
  fileUploadError,
  setFileUploadError,
  checkExtensions,
  previousAttachFiles,
  removeDatabaseFile,
}) {
  const { user } = useSelector((state) => state.auth);
  const user_id = user && user.user.id;
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  // const [previousAttachFiles, setPreviousAttachFiles] = useState([])
  const isUploadDisabled = () =>
    !isFileUploaded || ext_attachment === "" || ext_attachment.length === 0;
  const dispatch = useDispatch();
  const handleDocumentNameChange = (e) => {
    const inputValue = e.target.value;
    setDocumentName(inputValue);
    setFileUploadError("");
  };
  const handleCommentsChange = (e) => {
    const inputValue = e.target.value;
    setComments(inputValue);
    setFileUploadError("");
  };

  const handleFileUpload = (e) => {
    changeDocumentInputVal(e.target.files);
    setIsFileUploaded(e.target.files && e.target.files.length > 0);
    setFileUploadError("");
    // setCheckExtensions('')
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <h2 className="pfs-h2-title default-regular-h2 cs-neutral-100">
              Other Attachments
            </h2>
            {/* <div>
            </div> */}
          </Col>
        </Row>
        <div className="other-attachment-container">
          <Row>
            <Col lg={6} md={6}>
              <div className="attachment-row">
                <Row>
                  <Col lg={5}>
                    <Form.Group className="cs-form-group">
                      <Form.Label>Document Name</Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        placeholder={documentName ? documentName : "Optional"}
                        name="Document Name"
                        maxLength={40}
                        // onChange={(e) =>
                        //   changeDocumentInputVal(e.target.value, "name", i)
                        // }
                        value={documentName}
                        className={documentName ? "cs-input-field-active" : ""}
                        onChange={handleDocumentNameChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={5}>
                    <Form.Group className="cs-form-group">
                      <Form.Label>Upload Files</Form.Label>
                      <div className="cs-choose-file">
                        <div
                          className="cs-choose-file-box"
                          title={
                            (ext_attachment.length && ext_attachment[0].name) ||
                            "Choose file"
                          }
                        >
                          <Form.Control
                            id="ext-attachment-upload"
                            type="file"
                            // accept="image/*"
                            // onChange={(e) =>
                            //   changeDocumentInputVal(e.target.files)

                            // }
                            ref={inputRef}
                            onChange={handleFileUpload}
                            // className={isFileUploaded ? 'cs-input-field-active' : ''}
                            className={
                              ext_attachment.length > 0
                                ? "cs-input-field-active"
                                : ""
                            }
                          />
                          <span className="cs-icon cs-neutral-10">
                            <CapsyncIcon title="upload-outlined" size="18" />
                          </span>
                        </div>
                      </div>
                      <span className="form-error-msg default-light-body-text-s cs-danger">
                        {fileUploadError}
                      </span>
                      <span className="form-error-msg default-light-body-text-s cs-danger">
                        {checkExtensions}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col lg={2}>
                    <Button
                      type="submit"
                      className="cs-btn-primary md-btn default-regular-h5"
                      onClick={handleUploadAttachment}
                      disabled={isUploadDisabled()}
                    >
                      Upload
                    </Button>
                  </Col>
                </Row>

                <>
                  <Row>
                    <Col lg={12}>
                      <div className="attachment-files cs-files">
                        {/* {previousAttachFiles.map((itr, i) => {
                          return (
                            <div className="cs-selected-files">
                              <div className="cs-fileicon cs-neutral-100">
                                <span className="cs-icon cs-neutral-10">
                                  <CapsyncIcon
                                    title="file-icon-outlined"
                                    size="18"
                                  />
                                </span>
                              </div>
                              <div className="cs-files-names">
                                <span className="download-filename default-regular-sub-heading-xs cs-neutral-100">
                                  {itr.attachments.substring(
                                    itr.attachments.indexOf("@") + 1
                                  )}
                                </span>
                              </div>
                              <div
                                className="cs-file-icon icons"
                                onClick={() =>
                                  removeDatabaseFile(
                                    i,
                                    itr.id,
                                    itr.signature_id,
                                    itr.attachments
                                  )
                                }
                              >
                                <span className="cs-icon-badge-danger cs-danger cursor-pointer">
                                  <CapsyncIcon
                                    title="delete-outlined"
                                    size="18"
                                  />
                                </span>
                              </div>
                            </div>
                          );
                        })} */}
                        {ext_Files.map((itr, i) => {
                          return (
                            <div className="cs-selected-files">
                              <div className="cs-fileicon cs-neutral-100">
                                <span className="cs-icon cs-neutral-10">
                                  <CapsyncIcon
                                    title="file-icon-outlined"
                                    size="18"
                                  />
                                </span>
                              </div>
                              <div className="cs-files-names">
                                <span className="download-filename default-regular-sub-heading-xs cs-neutral-100">
                                  {itr.name}
                                </span>
                              </div>
                              <div
                                className="cs-file-icon icons"
                                onClick={() => removeInputField(i)}
                              >
                                <span className="cs-icon-badge-danger cs-danger cursor-pointer">
                                  <CapsyncIcon
                                    title="delete-outlined"
                                    size="18"
                                  />
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="attachment-files cs-files"></div>
                    </Col>
                  </Row>
                </>
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="attachment-row attachment-comments-box">
                <Form.Group className="cs-form-group">
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Optional"
                    // className="cs-textarea"
                    // className={documentName ? 'cs-input-field-active' : ''}
                    className={`cs-textarea ${
                      comments ? "cs-input-field-active" : ""
                    } `}
                    onChange={handleCommentsChange}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}
