import React from "react";
import { Form } from "react-bootstrap";
import SubMenuHeader from "../SubMenuHeader";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import SubMethodsList from "../../SubMethodsList";
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";



// sequence No. six  (Asmita date-29th June => Do not remove this line)
const MethodIdThirteen = (props) => {


  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Accredited Investor",
      url: `/individual/verifyinvestor/accreditedInvestor/${props.verificationType}/${props.unique_code}`,
    },
  ];



  return (
    <>
      {props.is_loader.assets === true || props.is_loader.liabilities === true ? <SkeletonAllSubMethods /> :
        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="Special Situation"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>
              <SubMenuHeader />
            </div>
            <div className="method-section">
              <div className="default-regular-body-text-l cs-neutral-70"> <SubMethodsList methodId={15.1} /></div>


              <div className="cs-divider"></div>

              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert} />

                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Please explain why you believe an attorney can reasonably verify you and upload supporting evidence as appropriate. If you have any questions or need help, please email accreditation@capsync.com.
                </Form.Label>

                <UploadFileBlock
                  files={props.files}
                  setOpenModal={props.setOpenModal}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink}
                  showFile={props.showFile}
                  uploadError={props.uploadError}
                  fetchedMedia={props.fetchedMedia}
                />
                <NotesBlock setNotesValue={props.setNotesValue}
                />
              </Form>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default MethodIdThirteen;
