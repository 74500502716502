import React from "react";
import { Dropdown } from "react-bootstrap";
import { customLabelLength } from "../../../../customHooks/adjustLength";

const CategoryDropDown = ({ checkType, data, financeType }) => {
  return (
    <>
      {data.map((itr, key) => {
        if (financeType == 'liability' || financeType == 'assets') {
          return (
            <Dropdown.Item
              id={key}
              tabIndex={key}
              onClick={() => checkType(itr.id)}
              className="default-regular-sub-heading-s"
            >
              {customLabelLength(itr.sub_category, 39, 39)}
            </Dropdown.Item>
          );
        }
      })}
    </>
  );
};

export default CategoryDropDown;
