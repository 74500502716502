import React, { useState, useEffect } from "react";
import { Col, Row, Dropdown, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  vi_findUser_by_UniqueID,
  update_investor_type,
} from "../../../../../slices/verifyInvestor";
import CapsyncSectionHeader from "../../../../../commonComponent/CapsyncSectionHeader";
import CapsyncBreadcrumb from "../../../../../commonComponent/CapsyncBreadcrumb";
import CapsyncIcon from "../../../../../commonComponent/CapsyncIcon";

const CommonMethodsHeader = (props) => {
  let investorTypeArray = [
    "Angel",
    "EB-5",
    "Friends & Family",
    "Financial Institution",
    "Insurance",
    "Private Equity",
    "Private Investor",
    "Venture Capital",
    "Other",
    "None or N/A",
  ];

  const [selectInverstorType, setSelectInverstorType] = useState("Select Option");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const VI_location = useLocation();
  let store = VI_location.pathname.split("/");

  let htmlStore = "";
  for (let index = 0; index < store.length - 1; index++) {
    htmlStore += `${store[index]}/`;
  }
  const [currentPageLocation] = useState(htmlStore);
  const unique_string = store.slice(-1).toString();
  const vi_navigate = useNavigate();

  const vi_findUser_by_Unique = () => {
    dispatch(vi_findUser_by_UniqueID({ unique_rowID: unique_string }))
      .unwrap()
      .then((response) => {
        setSelectInverstorType(response.allData.pending[0].investor_type);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    vi_findUser_by_Unique();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const checkInvestorType = (param) => {
    setSelectInverstorType(param);
    dispatch(
      update_investor_type({
        investor_type: param,
        unique_rowID: unique_string,
      })
    )
      .unwrap()
      .then((response) => {
        vi_findUser_by_Unique();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeStatusTab = (tabName) => {
    if (tabName === "Individual") {
      navigate(
        `/individual/verifyinvestor/accreditedInvestor/individuals/${unique_string}`
      );
    } else if (tabName === "Entity") {
      navigate(
        `/individual/verifyinvestor/accreditedInvestor/entities/${unique_string}`
      );
    } else {
      navigate(
        `/individual/verifyinvestor/accreditedInvestor/trusts/${unique_string}`
      );
    }
  };
  // function handleDropdownSelect(eventKey) {
  //   if (eventKey === "Individual") {
  //     vi_navigate(
  //       `/individual/verifyinvestor/accreditedInvestor/individuals/${unique_string}`
  //     );
  //   } else if (eventKey === "Entity") {
  //     vi_navigate(
  //       `/individual/verifyinvestor/accreditedInvestor/entities/${unique_string}`
  //     );
  //   } else {
  //     vi_navigate(
  //       `/individual/verifyinvestor/accreditedInvestor/trusts/${unique_string}`
  //     );
  //   }
  // }

  // function fetchTitle(params) {
  //   let newContent = "";
  //   newContent += params;
  //   let newArr = newContent.split("/");
  //   let newStr = newArr[newArr.length - 2];
  //   if (newStr === "individuals") {
  //     return "As an Individual";
  //   } else if (newStr === "entities") {
  //     return "As an Entity";
  //   } else if (newStr === "trusts") {
  //     return "As a Trust";
  //   }
  // }
  const pageTitle =
    currentPageLocation ===
      "/individual/verifyinvestor/accreditedInvestor/individuals/" ||
      currentPageLocation ===
      "/individual/verifyinvestor/accreditedInvestor/entities/" ||
      currentPageLocation ===
      "/individual/verifyinvestor/accreditedInvestor/trusts/"
      ? "Accredited Investor"
      : currentPageLocation ===
        "/individual/verifyinvestor/accreditedInvestor/qualifiedclient/"
        ? "Qualified Client"
        : "Qualified Purchaser";

  return (
    <>
      <div className="cs-section-topbar">
        <CapsyncBreadcrumb
          mainTitle="Investor Accreditation"
          mainUrl="/individual/verifyinvestor/pending"
          subTitle={pageTitle}
        />
        <CapsyncSectionHeader pagetitle={pageTitle} />
      </div>

      <div className="accredited-common-box">
        <div className="accredited-title">
          <h3 className="default-semi-bold-h3 cs-neutral-90">
            {currentPageLocation ===
              "/individual/verifyinvestor/accreditedInvestor/individuals/" ||
              currentPageLocation ===
              "/individual/verifyinvestor/accreditedInvestor/entities/" ||
              currentPageLocation ===
              "/individual/verifyinvestor/accreditedInvestor/trusts/"
              ? "Accredited Investor Verification Methods"
              : currentPageLocation ===
                "/individual/verifyinvestor/accreditedInvestor/qualifiedclient/"
                ? "Qualified Client Verification Methods"
                : "Qualified Purchaser Verification Methods"}
          </h3>
          <h4 className="default-regular-h4 cs-neutral-80">
            What type of investor are you? This helps us understand who is using our system. You may choose None or N/A if you prefer.
          </h4>
        </div>

        <div className="investor-type-row">
          <Row>
            <Col lg={3}>
              <span className="default-regular-sub-heading-xs">
                Select Investor type
              </span>
              <div className="investor-type-dropdown">
                <Dropdown className="dropdown cs-form-dropdown">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic-accredited"
                    className="dropdown-toggle default-regular-sub-heading-s"
                  >
                    {selectInverstorType}
                    <span className="input-field-icon">
                      <CapsyncIcon title="chevron-down-outlined" size="16" />
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {investorTypeArray.map((item) => (
                      <Dropdown.Item
                        className={
                          item === selectInverstorType
                            ? "dropdown-item dropdown-item-active default-regular-sub-heading-s "
                            : "default-regular-sub-heading-s cs-content-title "
                        }
                        key={item}
                        onClick={() => checkInvestorType(item)}
                        onChange={(event) => setSelectInverstorType(event.target.value)}
                      >
                        {item}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>

        <div className="accredited-tabbing-row default-regular-body-text-l cs-neutral-80">
          <h3 className="default-semi-bold-h3 cs-neutral-90">How are you investing?</h3>
          {props.investorType === "individualMethod" && (
            <p>
              Click Individual/Joint if you are investing in your own personal
              name or if you are investing with your spouse, but in your own
              personal names (such as John and Jane Doe). If you're investing
              through an entity or trust, click Entity or Trust, as appropriate.
              If you are investing through an IRA, click the appropriate category
              depending on whether your IRA is set up with your direct names or
              through an entity or trust.
            </p>
          )}
          {props.investorType === "individualMethod" && (
            <div className="cs-tabs">
              <Button
                onClick={() => changeStatusTab("Individual")}
                className={
                  currentPageLocation ===
                    "/individual/verifyinvestor/accreditedInvestor/individuals/"
                    ? "cs-stroke-default-tab cs-stroke-active default-regular-h5 "
                    : "cs-stroke-default-tab default-regular-h5 "
                }
              >
                As an Individual/Joint
              </Button>
              <Button
                onClick={() => changeStatusTab("Entity")}
                className={
                  currentPageLocation ===
                    "/individual/verifyinvestor/accreditedInvestor/entities/"
                    ? "cs-stroke-default-tab cs-stroke-active default-regular-h5"
                    : "cs-stroke-default-tab default-regular-h5"
                }
              >
                As an Entity
              </Button>
              <Button
                onClick={() => changeStatusTab("Trust")}
                className={
                  currentPageLocation ===
                    "/individual/verifyinvestor/accreditedInvestor/trusts/"
                    ? "cs-stroke-default-tab cs-stroke-active default-regular-h5"
                    : "cs-stroke-default-tab default-regular-h5"
                }
              >
                As a Trust
              </Button>
            </div>
          )}


          <p>
            Choose the applicable method Of verification below. You certify that you qualify as {props.investorType === "QualifiedPurchase" ? "a qualified purchaser" : props.investorType === "QualifiedClient" ? "a qualified client" : "an accredited investor"} using the method you
            select.
          </p>

        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default CommonMethodsHeader;
