/* eslint-disable */
import React, { useState } from "react";
import {
  Button,
  CloseButton,
  Col,
  Image,
  Modal,
  Row,
  Form,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { wealth_companies_update } from "../../../slices/wealthManager.slice";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import ModalTable from './ModalTable'
import EditAddModal from "./EditAddModal";
import { socket } from "../../../config/Socket";
const EditModal = (props) => {
  const {
    editModalState,
    fetchAllCompanies,
    toastMessage,
    filteredCompanies,
    changeEditModalState,
    companyPartners,
    changeAddEditModalState,
    editAddmodalState,
    setPartners,
    partners
  } = props;
  const { user } = useSelector((state) => state.auth);
  const user_id = user && user.user.id;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [is_disabled, setIsDisabled] = useState(true);
  const [selectedIndividualFromCompany, setSelectedIndividualFromCompany] = useState([])
  const [selectedIndividualEmailArray, setSelectedIndividualEmailArray] = useState([])
  const [compName, setCompName] = useState(editModalState.compName);
  const [tempCompName, setTempCompName] = useState();

  const [companyValidation, setCompanyValidation] = useState({
    msg: "",
    color: "",
  });

  const [validation, setValidation] = useState({
    msg: "",
    color: "",
  });

  // modalState
  //   changeModalState
  //   fetchAllCompanies
  //   toastMessage
  //   filteredCompanies
  const companyName = (event) => {
    setCompanyValidation("")
    setTempCompName(event.target.value);
    setIsDisabled(false);
  };

  const doCompanyNameValidation = (flag) => {
    if (tempCompName === "") {
      flag++;
      setCompanyValidation({
        msg: "Enter company name",
        color: "text-danger",
      });
    } else {
      setCompanyValidation({
        msg: "",
        color: "",
      });
      // if (
      //   // /^\s/.test(tempCompName) ||
      //   // /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(tempCompName)
      //   /^\s|\s$/.test(tempCompName) ||
      //   /^[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(tempCompName) ||
      //   /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]\s/.test(tempCompName) ||  // Check for special characters followed by a space
      //   /\s[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(tempCompName) ||  // Check for space followed by special characters
      //   /(\.\s|\s\.)/.test(tempCompName) ||  // Check for dot followed by space or space followed by dot
      //   /^(\.|\s)|(\.|\s)$/.test(tempCompName)  // Check for dot or space at the start or end
      // ) {
      //   flag++;
      //   setCompanyValidation({
      //     msg: "*Special characters and space not allowed",
      //     color: "text-danger",
      //   });
      // } else {
      //   setCompanyValidation({
      //     msg: "",
      //     color: "",
      //   });
      // }
    }

    let checkCompName = filteredCompanies.filter(
      (record) => record.companyName === tempCompName && editModalState.companyId !== record.id
    );

    if (checkCompName.length !== 0) {
      flag++;
      setValidation({
        msg: "A company with this name already exists",
        color: "text-danger",
      });
    } else {
      setValidation({
        msg: "",
        color: "",
      });
    }
    return flag;
  }
  const closeModal = () => {
    setTempCompName(compName)
    setCompanyValidation("")
    setValidation("")
    changeEditModalState(false, true, editModalState.companyName, editModalState.companyId);
    setIsDisabled(true);
  };
  const handleAddMorePartnerClick = () => {
    // compName()
    if (doCompanyNameValidation(0) !== 0) {
      return;
    }
    setValidation("")
    changeEditModalState(false, true, editModalState.companyName, editModalState.companyId);
    setIsDisabled(true);
    changeAddEditModalState(true, false)
  }


  const updateCompName = ({ closeAddPartnerModal = false }) => {
    let flag = 0;
    flag = doCompanyNameValidation(flag);
    const isPartnerEmpty = () => {
      if (partners.length === 1 && (partners[0].email === "" && partners[0].firstName === "" && partners[0].lastName === "")) {
        return true;
      }
      return false;
    }

    let storeData = {
      partners: isPartnerEmpty() === true ? [] : partners,
      companyName: tempCompName,
      wm_id: user_id,
      comp_id: editModalState.companyId,
      request_send_by_displayName: user.user && user.user.displayName,
      request_send_by_email: user.user && user.user.email,
      selected_individual: selectedIndividualEmailArray
    };


    if (flag === 0) {
      setLoading(true);
      setValidation({
        msg: "",
        color: "",
      });
      dispatch(wealth_companies_update(storeData))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            closeModal()
            closeAddPartnerModal && closeAddPartnerModal()
            socket.emit("notification", "message")
            setLoading(false);
            fetchAllCompanies();
            toastMessage(response.message, "success");
            setValidation({
              msg: "",
              color: "",
            });
            setPartners([
              { email: "", firstName: "", lastName: "", id: 0 },
            ])
            setIsDisabled(true);
          } else if (response.code === 500 && response.invalid_emails && response.invalid_emails.length > 0) {
            let data = partners.map((val) => {
              return {
                ...val,
                emailError: response.invalid_emails.includes(val.email) ? response.message : ""
              }
            })
            setPartners(data)
            setLoading(false);
          } else {
            closeModal()
            closeAddPartnerModal && closeAddPartnerModal()
            setLoading(false);
            toastMessage(response.message, "error");
            setPartners([
              { email: "", firstName: "", lastName: "", id: 0 },
            ])
          }
        })
        .catch((err) => {
          closeModal()
          closeAddPartnerModal && closeAddPartnerModal()
          setLoading(false);
          setPartners([
            { email: "", firstName: "", lastName: "", id: 0 },
          ])
          toastMessage(err.message, "error");
          console.log(err)
        })
    }
  };

  return (
    <>
      <EditAddModal
        setTempCompName={setTempCompName}
        tempCompName={tempCompName}
        editAddmodalState={editAddmodalState}
        changeAddEditModalState={changeAddEditModalState}
        fetchAllCompanies={fetchAllCompanies}
        toastMessage={props.toastMessage}
        filteredCompanies={filteredCompanies}
        setPartners={setPartners}
        partners={partners}
        compName={compName}
        updateCompName={updateCompName}
        selectedIndividualEmailArray={selectedIndividualEmailArray}
        setSelectedIndividualEmailArray={setSelectedIndividualEmailArray}
        selectedIndividualFromCompany={selectedIndividualFromCompany}
        setSelectedIndividualFromCompany={setSelectedIndividualFromCompany}
        companyPartners={companyPartners}
        loading={loading}
      />
      <Modal
        show={editModalState.show}
        onHide={!editModalState}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">Company Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Row>
              <Col lg={12} md={12}>
                <Form.Group
                  className="cs-form-group"
                  controlId="validationFormik03"
                >
                  <Form.Label>Edit company name</Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={70}
                    onChange={companyName}
                    defaultValue={editModalState.companyName}
                    className={editModalState.companyName && "cs-input-field-active"}
                    autoComplete="off"
                    value={tempCompName}
                  />
                  {/* <span className={companyValidation.color}>
                    {companyValidation.msg}
                  </span> */}
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {companyValidation.msg}
                  </span>
                  {/* <span className={validation.color}>{validation.msg}</span> */}
                  <span className="form-error-msg default-light-body-text-s cs-danger">{validation.msg}</span>
                </Form.Group>
                <ModalTable dataArray={companyPartners} tableLabel={"Partner Details"} />
                <div className="associate-add-more add-more-btn">

                  <Button
                    type="submit"
                    // disabled={validation.msg.length != 0}
                    className="default-regular-h5 cs-btn-icon-tertiary cs-primary"
                    onClick={handleAddMorePartnerClick}
                  >
                    <span >
                      <CapsyncIcon title="add-filled" size="16" />
                    </span>
                    Add more partners

                  </Button>
                </div>
                {/* <div className="">
                  <p className="form-label">Partner Details</p>
                {<Table className="cs-table">
                    <thead className="cs-thead">
                      <tr>
                        <th className="default-medium-sub-heading-m cs-neutral-100">Name</th>
                        <th className="default-medium-sub-heading-m cs-neutral-100">Email</th>
                      </tr>
                    </thead>
                    <tbody className="cs-tbody">
                      {companyPartners && companyPartners.map((data, rowIndex) => (
                        <tr key={rowIndex}>
                            <td key={0}>{`${data.firstName} ${data.lastName}`}</td>
                            <td key={0}>{data.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>}
                </div> */}
              </Col>
            </Row>

          </>
        </Modal.Body>
        <Modal.Footer>
          <div className="cs-modal-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              className="cs-btn-primary md-btn default-regular-h5"
              onClick={updateCompName}

            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditModal;
