import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import LogoImage from "../logoImage";
import LeftPart from "./LeftPart";
import { clearMessage } from "../../slices/message";
import { mfaEmailAuth, mfaPhoneAuth } from "../../slices/auth";
import BackToSignin from "./BackToSignin";
import CapsyncIcon from "../../commonComponent/CapsyncIcon";

const SignInMFVerification = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);

  // const MFAoptionValue = user && user.user.firstSignInSteps === 1;

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const email = user && user.user.email;
  const id = user && user.user.id;
  const MFAEmail = user && user.user.MFAEmail;
  const MFAPhone = user && user.user.MFAPhone;
  const MFAGoogle = user && user.user.MFAGoogle;

  const mfOptionEmail = () => {
    setSelectedOption("email");
    dispatch(mfaEmailAuth({ email }))
      .then((data) => {
        if (data.payload.code === 200) {
          navigate("/mf-auth-emailpin");
        }
      })
      .catch((err) => { });
  };

  const mfOptionPhone = () => {
    setSelectedOption("phone");
    dispatch(mfaPhoneAuth({ id, email }))
      .then((res) => {
        if (res.payload.code === 200) {
          localStorage.setItem("phone", JSON.stringify(res.payload.res_data));
          navigate("/mf-auth-phonepin", {
            res_phoneNo: res.payload.res_data,
          });
        }
      })
      .catch((err) => { });
  };

  const mfOptionGoogle = () => {
    setSelectedOption("google");
    navigate("/mf-auth-googlepin");
  };

  return (
    <>
      <section className="auth-section">
        <Container fluid>
          <Row className="align-items-center">
            <Col lg={6}>
              <LeftPart
                page="multifactorauth"
                img="auth-image.svg"
                title="Your New Immersive Digital Finance Hub."
                text="Sync your real-time digital Personal Financial Statement with CapSync."
              />
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={12}>
                  <div className="auth-right-section">
                    <LogoImage />
                    <div className="cs-auth-form">
                      <h2 className="default-semi-bold-h1 cs-neutral-100 ">
                        Multi-factor authentication
                      </h2>
                      <hr className="head-underline" />

                      <p className="cs-neutral-80 default-regular-h5">
                        Choose your preferred authentication method
                      </p>
                      <div>
                        {MFAEmail && (
                          <div
                            // className="auth-mfa-item"
                            className={`auth-mfa-item ${selectedOption === "email" ? "active" : ""
                              }`}
                            onClick={() => mfOptionEmail()}
                          >
                            <div className="auth-mfa-inner">
                              <div className="auth-mfa-options cs-radio-btn">
                                <input
                                  type="radio"
                                  name="mfOption-email"
                                  id="email"
                                  value="1"
                                  className="radio-button"
                                  checked={selectedOption === "email"}
                                  onChange={() => { }}
                                />
                              </div>
                              <div className="auth-mfa-options">
                                <h4 className="cs-neutral-100 default-regular-h4">
                                  Email verification
                                </h4>
                                <span className="default-regular-h6 cs-neutral-60">
                                  Send a code to your email
                                </span>
                              </div>
                            </div>
                            <CapsyncIcon title="email-outlined" size="22" />
                          </div>
                        )}
                        {MFAPhone && (
                          <div
                            // className="auth-mfa-item"
                            className={`auth-mfa-item ${selectedOption === "phone" ? "active" : ""
                              }`}
                            onClick={() => mfOptionPhone()}
                          >
                            <div className="auth-mfa-inner">
                              <div className="auth-mfa-options cs-radio-btn">
                                <input
                                  type="radio"
                                  name="mfOption-phone"
                                  id="mobile"
                                  value="2"
                                  className="radio-button"
                                  checked={selectedOption === "phone"}
                                  onChange={() => { }}
                                />
                              </div>
                              <div className="auth-mfa-options">
                                <h4 className="cs-neutral-100 default-regular-h4 ">
                                  Mobile verification
                                </h4>
                                <p className="default-regular-h6 cs-neutral-60">
                                  Send a code to your phone via SMS
                                </p>
                              </div>
                            </div>
                            <CapsyncIcon title="mobile-outlined" size="22" />
                          </div>
                        )}
                        {MFAGoogle && (
                          <div
                            // className="auth-mfa-item"
                            className={`auth-mfa-item ${selectedOption === "google" ? "active" : ""
                              }`}
                            onClick={() => mfOptionGoogle()}
                          >
                            <div className="auth-mfa-inner">
                              <div className="auth-mfa-options cs-radio-btn">
                                <input
                                  type="radio"
                                  name="mfOption-google"
                                  id="auth"
                                  value="3"
                                  className="radio-button"
                                  checked={selectedOption === "google"}
                                  onChange={() => { }}
                                />
                              </div>
                              <div className="auth-mfa-options">
                                <h4 className="cs-neutral-100 default-regular-h4">
                                  Authenticator app
                                </h4>
                                <span className="default-regular-h6 cs-neutral-60 ">
                                  Connect your authenticator app
                                </span>
                              </div>
                            </div>
                            <CapsyncIcon title="qr-code-outlined" size="22" />
                          </div>
                        )}
                      </div>

                      <div>
                        <BackToSignin />
                      </div>
                    </div>
                    {message && (
                      <div className="cs-msg default-regular-body-text-s cs-danger">
                        <span className="icon">
                          <CapsyncIcon title="info-filled" />
                        </span>
                        <span className="formik-form-error"> {message.message}</span>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SignInMFVerification;
