import React from 'react';
import {
    Card,
    Button,
} from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';

const SkeletonMfa = () => {
    return (
        <Card className="settings-mfa-card">
            <div className="mfa-icon-text">
                <span className="cs-neutral-80">
                    <Skeleton width={30} height={35} />
                </span>
                <div className="mfa-content-text">
                    <h4 className="default-regular-h4 cs-neutral-100">
                        <Skeleton width={140} />
                    </h4>
                    <h6 className="default-regular-h6 cs-neutral-60">
                        <Skeleton width={220} />
                    </h6>
                </div>
            </div>
            <Skeleton width={195} height={40} />
        </Card>
    )
}

export default SkeletonMfa