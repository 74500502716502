import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  FormLabel,
  Dropdown,
  FormControl,
  Image,
} from "react-bootstrap";
import Select from "react-select";
import { GetState, GetCountries } from "react-country-state-city";
import {
  zipCodeLookup,
  autoCompleteAddress,
  placeSearch,
} from "../services/thirdPartyAPI";
import { getAddressObject, getValidAddress } from "../util/common";
import GoogleMapImg from "../../assets/images/other/google-map.png";
import { uniValidation } from "../pages/individual/liabilities/categoryForms/validationSchema";

const AddressSearch = (props) => {
  const {
    errors,
    touched,
    values,
    setErrorMessage,
    handleChange,
    setUpdateDetails,
    setFieldValue,
    setAddressObj,
    record,
    setStatus,
    status,
    addressObj,
    setIsAddressChange,
    label = "Address",
    setFieldTouched,
    licenceAddress,
    duplicateAddressError = null,
  } = props;
  const [stateOptionsList, setStateOptionsList] = useState([]);
  const [stateSelected, setStateSelected] = useState({
    value: "",
    label: "Choose State",
  });
  const [autocompletePredictions, setAutocompletePredictions] = useState([]);
  const [isCityNotFound, setIsCityNotFound] = useState(false);
  const [nearestLocalities, setNearestLocalities] = useState([]);

  const colourStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#eef1ff"
          : isFocused
          ? "#eef1ff"
          : undefined,
        color: isSelected ? "#0024D4" : "#424242",
      };
    },
  };

  // Set US State List
  useEffect(() => {
    setIsAddressChange(false);
    GetCountries().then((result) => {
      const usCountryId = result.find((item) => item.iso2 === "US");
      GetState(usCountryId.id).then((result) => {
        const stateList = result.map((item) => ({
          value: item.name,
          label: item.name,
        }));
        setStateOptionsList(stateList);
      });
    });
  }, []);

  useEffect(() => {
    if (!!licenceAddress && licenceAddress.length > 0) {
      setAutocompletePredictions([
        ...autocompletePredictions,
        ...licenceAddress,
      ]);
    }
  }, [licenceAddress]);

  // Set Record Data
  useEffect(() => {
    try {
      if (!!record) {
        let address = record;
        setFieldValue("street_name", address.line1);
        setFieldValue("city", address.city);
        setFieldValue("country", address.country);
        setFieldValue("zip_code", address.postal_code);
        setStateSelected({ label: address.state, value: address.state });
        setAddressObj(record);
      }
    } catch (error) {
      console.log(error);
    }
  }, [record]);

  // Handle Change State
  const handleChangeState = (option) => {
    setErrorMessage && setErrorMessage("");
    setStateSelected(option);
    setIsAddressChange(true);
    if (
      !!values.zip_code &&
      values.zip_code.length === 5 &&
      !!addressObj.state &&
      option.value.toLowerCase() !== addressObj.state.toLowerCase()
    ) {
      setStatus({ ...status, state: uniValidation.state.notMatch });
    } else {
      setStatus({ ...status, state: "" });
    }
  };

  // Handle Change City
  const handleChangeCity = (e) => {
    setErrorMessage && setErrorMessage("");
    handleChange(e);
    setIsAddressChange(true);
    if (!values.zip_code) {
      setStatus({ ...status, city: "" });
    }
    if (
      !!values.zip_code &&
      values.zip_code.length === 5 &&
      !!addressObj.city &&
      e.target.value.toLowerCase().trim() !==
        addressObj.city.toLowerCase().trim() &&
      !isCityNotFound
    ) {
      setStatus({ ...status, city: uniValidation.city.notMatch });
    } else {
      setStatus({ ...status, city: "" });
    }

    if (!!isCityNotFound) {
      setFieldValue("city", e.target.value);
      e.target.value &&
        setAddressObj({ ...addressObj, city: e.target.value.trim() });
    }
  };
  const handleBlurCity = () => {
    if (!!isCityNotFound) {
      const checkValidCity =
        nearestLocalities &&
        nearestLocalities.length > 0 &&
        nearestLocalities.some(
          (item) => item.toLowerCase() === values.city.toLowerCase().trim()
        );
      if (checkValidCity) {
        setStatus({ ...status, city: "" });
      } else {
        setStatus({ ...status, city: uniValidation.city.notMatch });
      }
    }
  };

  // ZipCode API and Handle Change
  const handleChangeZipCode = (e) => {
    setErrorMessage && setErrorMessage("");
    const zipCode = e.target.value;
    setIsAddressChange(true);
    if (
      e.target.name === "zip_code" &&
      e.target.value !== "" &&
      e.target.value.length === 5
    ) {
      onHandleZipCode(zipCode);
    }
    handleChange(e);
    setStatus({});
  };
  const onHandleZipCode = async (inputValue) => {
    const zipLookup = await zipCodeLookup({ zip_code: inputValue });
    const address =
      zipLookup.data.results.length > 0
        ? zipLookup.data.results[0].address_components
        : zipLookup.data.results;
    if (address.length === 0) {
      setStatus({ ...status, zip_code: "Invalid zip code!" });
      return;
    }

    let addressObject = getAddressObject(address);
    const { city, country, state, state_code } = addressObject;

    if (country !== "US") {
      setStatus({ ...status, zip_code: "Out of USA zip code not allowed!" });
      return;
    }

    let isNearestCity;
    if (!city) {
      setIsCityNotFound(true);
      setNearestLocalities(
        zipLookup.data.results.length > 0
          ? zipLookup.data.results[0].postcode_localities
          : []
      );
      isNearestCity =
        zipLookup.data.results.length > 0 &&
        values.city &&
        zipLookup.data.results[0].postcode_localities;
    } else {
      setIsCityNotFound(false);
      setNearestLocalities([]);
    }
    //Update values for user details
    !city ? setFieldValue("city", values.city) : setFieldValue("city", city);
    setFieldValue("country", country);
    inputValue && setFieldValue("zip_code", inputValue.trim());

    // Handled city validation message
    setFieldTouched("city", false, false);
    if (!city && !!values.city) {
      isNearestCity && isNearestCity.includes(values.city.trim())
        ? setStatus({ ...status, city: "" })
        : setStatus({ ...status, city: uniValidation.city.notMatch });
    }

    setStateSelected({ label: state, value: state });
    setAddressObj({
      ...addressObj,
      postal_code: inputValue,
      city: isNearestCity
        ? values.city && values.city.trim()
        : city && city.trim(),
      country,
      state,
      state_code,
    });
  };

  // Google API and Handle Change
  const handleChangeAddress = async (event) => {
    setErrorMessage && setErrorMessage("");
    setUpdateDetails && setUpdateDetails(false);
    const inputValue = event.target.value;
    setIsAddressChange(true);
    setFieldValue("street_name", inputValue);
    setAddressObj({ ...addressObj, line1: inputValue, line2: "" });
    setStatus({ ...status, street_name: "" });

    const autocompleteService = await autoCompleteAddress({
      search_data: inputValue,
    });
    if (autocompleteService && autocompleteService.status === 200) {
      !!autocompleteService.data &&
        autocompleteService.data.status === "OK" &&
        !!autocompleteService.data.predictions &&
        setAutocompletePredictions([...autocompleteService.data.predictions]);
    }
    if (autocompleteService && autocompleteService.status === 200) {
      !!autocompleteService.data &&
        autocompleteService.data.status === "OK" &&
        licenceAddress &&
        !!autocompleteService.data.predictions &&
        setAutocompletePredictions([
          ...autocompleteService.data.predictions,
          ...licenceAddress,
        ]);
    }
  };
  const handleOptionSelect = async (value) => {
    const selectedAddress = autocompletePredictions.filter(
      (prediction) => prediction.description === value
    )[0];
    const placesService =
      selectedAddress &&
      (await placeSearch({ add_place_id: selectedAddress.place_id }));

    if (
      !!placesService &&
      placesService.status === 200 &&
      !!placesService.data &&
      placesService.data.status === "OK"
    ) {
      let addressObj =
        !!placesService.data.result &&
        !!placesService.data.result.address_components &&
        getValidAddress(placesService.data.result.address_components);
      if (addressObj.error) {
        addressObj.error.includes("city")
          ? setIsCityNotFound(true)
          : setIsCityNotFound(false);
        const isStreetNumberError = addressObj.error.includes("street number")
          ? true
          : false;
        addressObj = addressObj.address;

        setAddressObj({
          ...addressObj,
          line1: isStreetNumberError ? addressObj.line1 : values.street_name,
        });
        setFieldValue(
          "street_name",
          isStreetNumberError ? addressObj.line1 : values.street_name
        );
        //Set predictions as empty due to error
        setAutocompletePredictions([]);
      } else {
        setFieldValue("street_name", addressObj.line1);
        setAddressObj(addressObj);
      }

      setFieldValue("city", addressObj.city);
      setFieldValue("zip_code", addressObj.postal_code);
      setStateSelected({ value: addressObj.state, label: addressObj.state });

      setFieldTouched("street_name", false, false);
      setFieldTouched("city", false, false);
      setFieldTouched("zip_code", false, false);

      setStatus({});
      setIsAddressChange(true);
    }
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <Form.Group className="cs-form-group">
            <FormLabel>{label}</FormLabel>
            <Dropdown className="cs-form-dropdown">
              <Dropdown.Toggle>
                <FormControl
                  type="text"
                  autoComplete="off"
                  name="street_name"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.street_name}
                  className={values.street_name && "cs-input-field-active"}
                  onChange={handleChangeAddress}
                  id="address-input"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="cs-scroller cs-setup-dropdown">
                {autocompletePredictions.map((prediction) => (
                  <Dropdown.Item
                    key={prediction.place_id}
                    onClick={() => handleOptionSelect(prediction.description)}
                    className="google-map"
                  >
                    <span>{prediction.description}</span>
                    <Image
                      src={GoogleMapImg}
                      alt="Google Map Image"
                      width={30}
                      height={30}
                    />
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
              {errors.street_name && touched.street_name ? (
                <p className="form-error-msg default-light-body-text-s cs-danger">
                  {errors.street_name}
                </p>
              ) : null}

              {status && status.street_name && !errors.street_name && (
                <span className="form-error-msg default-light-body-text-s cs-danger">
                  {status.street_name}
                </span>
              )}
              {duplicateAddressError && duplicateAddressError !== "" && (
                <span className="form-error-msg default-light-body-text-s cs-danger">
                  {duplicateAddressError}
                </span>
              )}
            </Dropdown>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Form.Group className="cs-form-group last-form-field">
            <FormLabel>City</FormLabel>
            <Form.Control
              type="text"
              autoComplete="off"
              name="city"
              onKeyDown={(e) => {
                if (e.key === " " && e.target.selectionStart === 0) {
                  e.preventDefault();
                }
              }}
              value={values.city}
              className={values.city && "cs-input-field-active"}
              onChange={handleChangeCity}
              onBlur={handleBlurCity}
            />
            {errors.city && touched.city ? (
              <p className="form-error-msg default-light-body-text-s cs-danger">
                {errors.city}
              </p>
            ) : null}

            {status && status.city && !errors.city && (
              <span className="form-error-msg default-light-body-text-s cs-danger">
                {status.city}
              </span>
            )}
          </Form.Group>
        </Col>
        <Col lg={4}>
          <div className="cs-address-dropdown select-car-dropdown">
            <Form.Group className="cs-form-group default-regular-body-text-m last-form-field">
              <FormLabel>State</FormLabel>
              <Select
                options={stateOptionsList}
                value={stateSelected}
                onChange={(e) => handleChangeState(e)}
                name="state"
                onKeyDown={(e) => {
                  if (e.key === " " && e.target.selectionStart === 0) {
                    e.preventDefault();
                  }
                }}
                styles={colourStyles}
              />
              {errors.state && touched.state ? (
                <p className="form-error-msg default-light-body-text-s cs-danger">
                  {errors.state}
                </p>
              ) : null}

              {status && status.state && !errors.state && (
                <span className="form-error-msg default-light-body-text-s cs-danger">
                  {status.state}
                </span>
              )}
            </Form.Group>
          </div>
        </Col>
        <Col lg={4}>
          <Form.Group className="cs-form-group last-form-field">
            <FormLabel>Zip code</FormLabel>
            <Form.Control
              type="text"
              autoComplete="off"
              name="zip_code"
              className={values.zip_code && "cs-input-field-active"}
              onChange={handleChangeZipCode}
              value={values.zip_code}
              maxLength={5}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
            />
            {errors.zip_code && touched.zip_code ? (
              <p className="form-error-msg default-light-body-text-s cs-danger">
                {errors.zip_code}
              </p>
            ) : null}

            {status && status.zip_code && !errors.zip_code && (
              <span className="form-error-msg default-light-body-text-s cs-danger">
                {status.zip_code}
              </span>
            )}
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default AddressSearch;
