const requestTableHeader = {
  requestFromPending: [
    {
      title: "Request",
      class: "default-medium-sub-heading-m",
      width: "280",
    },
    {
      title: "From",
      class: "default-medium-sub-heading-m",
      width: "240",
    },
    {
      title: "Role",
      class: "default-medium-sub-heading-m",
      width: "240",
    },
    {
      title: "Company Name",
      class: "default-medium-sub-heading-m",
      width: "240",
    },
    {
      title: "Request Date",
      class: "default-medium-sub-heading-m",
      width: "240",
    },
    {
      title: "Status",
      class: "default-medium-sub-heading-m",
      width: "320",
    },
    {
      title: "",
      class: "default-medium-sub-heading-m",
      width: "264",
    },
  ],
  requestFromCompleted: [
    {
      title: "Request",
      class: "default-medium-sub-heading-m",
      width: "280",
    },
    {
      title: "From",
      class: "default-medium-sub-heading-m",
      width: "240",
    },
    {
      title: "Role",
      class: "default-medium-sub-heading-m",
      width: "240",
    },
    {
      title: "Company Name",
      class: "default-medium-sub-heading-m",
      width: "240",
    },
    {
      title: "Request Date",
      class: "default-medium-sub-heading-m",
      width: "240",
    },
    {
      title: "Status",
      class: "default-medium-sub-heading-m",
      width: "320",
    },
    {
      title: "Actions",
      class: "default-medium-sub-heading-m",
      width: "264",
    },
  ],

  requestToPending: [
    {
      title: "Request",
      class: "default-medium-sub-heading-m",
      width: "280",
    },
    {
      title: "To",
      class: "default-medium-sub-heading-m",
      width: "240",
    },
    {
      title: "Role",
      class: "default-medium-sub-heading-m",
      width: "240",
    },
    {
      title: "Request Date",
      class: "default-medium-sub-heading-m",
      width: "240",
    },

    {
      title: "Status",
      class: "default-medium-sub-heading-m",
      width: "320",
    },

    {
      title: "",
      class: "default-medium-sub-heading-m",
      width: "264",
    },
  ],
  requestToCompleted: [
    {
      title: "Request",
      class: "default-medium-sub-heading-m",
      width: "280",
      FaWidth: "248",
    },
    {
      title: "To",
      class: "default-medium-sub-heading-m",
      width: "240",
      FaWidth: "270",
    },
    {
      title: "Role",
      class: "default-medium-sub-heading-m",
      width: "240",
      FaWidth: "220",
    },
    {
      title: "Request Date",
      class: "default-medium-sub-heading-m",
      width: "240",
      FaWidth: "200",
    },
    {
      title: "Status",
      class: "default-medium-sub-heading-m",
      width: "320",
      FaWidth: "320",
    },
    {
      title: "Actions",
      class: "default-medium-sub-heading-m",
      width: "264",
      FaWidth: "260",
    },
  ],
  accountAccess: [
    {
      title: "Request",
      class: "default-medium-sub-heading-m",
      width: "280",
    },
    {
      title: "From",
      class: "default-medium-sub-heading-m",
      width: "240",
    },
    {
      title: "Role",
      class: "default-medium-sub-heading-m",
      width: "240",
    },
    {
      title: "Company Name",
      class: "default-medium-sub-heading-m",
      width: "240",
    },
    {
      title: "Request Date",
      class: "default-medium-sub-heading-m",
      width: "240",
    },
    {
      title: "Permission",
      class: "default-medium-sub-heading-m",
      width: "320",
    },
    {
      title: "",
      class: "default-medium-sub-heading-m",
      width: "264",
    },
  ],
};
export { requestTableHeader };
