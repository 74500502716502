/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CommonMethodsHeader from "./CommonMethodsHeader";
import commonNames from "../../../../../config/commonNames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { add_update_verification_methodId } from "../../../../../slices/verifyInvestor";
import UseFetchPermission from "../../../../../customHooks/UseFetchPermission";
import PermissionPrevent from "../../../../PermissionPrevent";
import SubMethodsList from "../SubMethodsList";
import WarningModal_VI from "../Modals/WarningModal_VI";
import SkeletonMethodsDashboard from './SkeletonMethodsDashboard';
import ConfirmationModel from "../Modals/ConfirmationModel";

const TrustsMethods = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [changeMethod, setChangeMethod] = useState("yes");
  const { fetchUserPermission } = UseFetchPermission();
  // const [enableLoader, setEnableLoader] = useState(false);
  const [showTrustMethods, setShowTrustMethods] = useState(false);
  const [showIndividualMethods, setShowIndividualMethods] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showWarning2, setShowWarning2] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false)

  // const [selectInverstorType, setSelectInverstorType] = useState("Select Option");

  const Vi_Location = useLocation();
  let unique_string = Vi_Location.pathname.split("/").slice(-1).toString();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleConfirmationModal = () => {
    setShowConfirmation(true);
  }

  const GoToSubMethods = (type, methodID, indicator) => {
    // if (selectInverstorType !== "Select Option" ){
    // setEnableLoader(true);
    setShowWarning(false);
    if (indicator === "yes") {
      dispatch(
        add_update_verification_methodId({
          unique_rowID: unique_string,
          verification_method_id: methodID,
        })
      )
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            setTimeout(() => {
              navigate(
                `/individual/verifyinvestor/accreditedInvestor/${type}/${unique_string}/${methodID}`
              );
            }, 1000);
            // setEnableLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (indicator === "no") {
      // setEnableLoader(false);
      setChangeMethod("no");
    } else if (indicator === "openThreeMethods") {
      // setEnableLoader(false);
      setChangeMethod("openThreeMethods");
    }
    // }else {
    //   toast.error("Select an investor type.")
    // }

  };

  const handleRevocableTrust = () => {
    GoToSubMethods("trusts", "", "openThreeMethods")
    setShowIndividualMethods(true)
  }



  return (
    <>

      <section className="accreditation-main-section accreditation-inner-section">
        {/* {enableLoader ? (
            <CapsyncLoader />
          ) : ( */}
        <Container fluid>
          {props.is_loader.assets === true || props.is_loader.liabilities === true ? < SkeletonMethodsDashboard investorType="individualMethod" /> :
            <>
              <CommonMethodsHeader investorType="individualMethod"
              // selectInverstorType={selectInverstorType} setSelectInverstorType={setSelectInverstorType}  //code for selectInverstorType
              />
              {!showTrustMethods &&
                <div className="verification-method-box">
                  <div className="accredited-method">
                    <Row>
                      <Col lg={12}>
                        <span className="cs-neutral-100 default-medium-sub-heading-m">
                          Verification Method
                        </span>
                      </Col>
                    </Row>
                  </div>
                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90">
                          {" "}
                          <p>
                            I already have or can get a new letter, which is dated within the last three months, from a registered broker-dealer, an SEC-registered investment adviser, a licensed attorney, or a certified public accountant certifying the investor as an accredited investor.
                          </p>
                        </div>
                      </Col>
                      <Col xl={1} md={3}>
                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5 trust-btn"
                          onClick={() => GoToSubMethods("trusts", "22", "yes")}
                        >
                          Yes
                        </Button>

                        <Button
                          className="sm-btn default-regular-h5 cs-btn-danger"
                          onClick={() => setShowTrustMethods(true)}
                        >
                          No
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              }
              {showTrustMethods &&
                <div className="verification-method-box">
                  <div className="accredited-method">
                    <Row>
                      <Col lg={12}>
                        <span className="cs-neutral-100 default-medium-sub-heading-m">
                          Verification Method
                        </span>
                      </Col>
                    </Row>
                  </div>
                  {changeMethod === "yes" ? (
                    <div className="vi-innerArea">
                      <Row>
                        <Col xl={11} md={9}>
                          <div className="default-regular-sub-heading-m cs-neutral-90">
                            {" "}
                            <p>
                              Is Investor a trust, with total assets in excess of
                              $5,000,000 USD, not formed for the specific purpose
                              of acquiring the securities offered, whose purchase
                              is directed by a person who either alone or with his
                              purchaser representative(s) has such knowledge and
                              experience in financial and business matters that he
                              is capable of evaluating the merits and risks of the
                              prospective investment.
                            </p>
                          </div>
                        </Col>
                        <Col xl={1} md={3}>
                          <Button
                            className="cs-btn-primary sm-btn default-regular-h5 trust-btn"
                            onClick={() => GoToSubMethods("trusts", "23", "yes")}
                          >
                            Yes
                          </Button>

                          <Button
                            className="sm-btn default-regular-h5 cs-btn-danger"
                            onClick={() => GoToSubMethods("trusts", "23", "no")}
                          >
                            No
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  ) : changeMethod === "no" ? (
                    <>
                      <Row className="vi-innerArea">
                        <Col xl={11} md={9}>
                          <p className="default-regular-sub-heading-m cs-neutral-90">
                            A revocable trust where the grantor(s) is a single
                            individual or are a married couple or spousal
                            equivalent.
                          </p>
                        </Col>
                        <Col xl={1} md={3} className="vi-btnArea">
                          <Button
                            className="cs-btn-danger sm-btn default-regular-h5"
                            onClick={handleRevocableTrust}
                          >
                            {commonNames.Select}
                          </Button>
                        </Col>
                      </Row>
                      <div className="cs-divider">
                        <span className="default-regular-body-text-s cs-neutral-50">
                          OR
                        </span>
                      </div>
                      <Row className="vi-innerArea">
                        <Col xl={11} md={9}>
                          <p className="default-regular-sub-heading-m cs-neutral-90">
                            Any other revocable trust, or any irrevocable trust.
                          </p>
                        </Col>
                        <Col xl={1} md={3} className="vi-btnArea">
                          <Button
                            className="cs-btn-danger sm-btn default-regular-h5"
                            onClick={() =>
                              GoToSubMethods("trusts", "", "openThreeMethods")
                            }
                          >
                            {commonNames.Select}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <div>
                      <p className="vi-innerArea default-regular-sub-heading-m cs-neutral-90">
                        IMPORTANT: Please proceed with verification as if the equity owners of the trust were the investors (except that you should keep the trust name as the name of the investor). In a revocable trust, typically, the grantors are the equity owners. In an irrevocable trust, determination of the equity owners can be complicated. Please contact <Link target="_blank" to="mailto:accreditation@capsync.com">accreditation@capsync.com</Link> for assistance.
                      </p>
                      <div className="cs-divider">
                      </div>
                      {!showIndividualMethods &&
                        <div>
                          <Row className="vi-innerArea">
                            <Col xl={11} md={9} className="cap-title-color-net-vi">
                              {/* <p className="default-regular-sub-heading-m cs-neutral-90">
                                  A registered broker-dealer, an SEC-registered investment adviser, a licensed attorney, or a certified public accountant has already verified me as an accredited investor within the last three months.
                                </p> */}
                              <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={13} /></div>

                            </Col>
                            <Col xl={1} md={3} className="vi-btnArea">
                              <Button
                                className="cs-btn-danger sm-btn default-regular-h5"
                                onClick={() => GoToSubMethods("trusts", "22", "yes")}
                              >
                                {commonNames.Select}
                              </Button>
                            </Col>
                          </Row>
                          <div className="cs-divider">
                            <span className="default-regular-body-text-s cs-neutral-50">
                              OR
                            </span>
                          </div>
                          <Row className="vi-innerArea">
                            <Col xl={11} md={9} className="cap-title-color-net-vi">
                              <p className="default-regular-sub-heading-m cs-neutral-90">
                                An entity in which all the equity owners are
                                accredited investors.
                              </p>
                            </Col>
                            <Col xl={1} md={3} className="vi-btnArea">
                              <Button
                                className="cs-btn-danger sm-btn default-regular-h5"
                                onClick={() => GoToSubMethods("trusts", "24", "yes")}
                              >
                                {commonNames.Select}
                              </Button>
                            </Col>
                          </Row>
                          <div className="cs-divider">
                            <span className="default-regular-body-text-s cs-neutral-50">
                              OR
                            </span>
                          </div>
                          <Row className="vi-innerArea">
                            <Col xl={11} md={9}>
                              <div className="default-regular-sub-heading-m cs-neutral-90">
                                I have a special situation and believe that I can be reasonably verified as an accredited investor using principles based methods. Please contact  <Link target="_blank" to="mailto:accreditation@capsync.com">accreditation@capsync.com</Link> before you select this method of verification.
                              </div>
                            </Col>
                            <Col xl={1} md={3} className="vi-btnArea">
                              <Button
                                className="cs-btn-danger sm-btn default-regular-h5"
                                onClick={() => setShowWarning2(true)}
                              >
                                {commonNames.Select}
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      }
                      {showIndividualMethods &&
                        <div>

                          {/* method-11 is added in new design  */}
                          <div className="vi-innerArea">
                            <Row>
                              <Col xl={11} md={9}>
                                <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={13} /></div>

                              </Col>
                              <Col xl={1} md={3} >
                                <Button
                                  // className="cs-btn-primary sm-btn default-regular-h5"
                                  className="cs-btn-danger sm-btn default-regular-h5"
                                  onClick={() => GoToSubMethods("trusts", "20", "yes")}
                                >
                                  {commonNames.Select}
                                </Button>
                              </Col>
                            </Row>
                          </div>

                          <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>
                          <div className="vi-innerArea">
                            <Row>
                              <Col xl={11} md={9}>
                                <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={1} /></div>

                              </Col>
                              <Col xl={1} md={3} >
                                <Button
                                  // className="cs-btn-primary sm-btn default-regular-h5"
                                  className="cs-btn-danger sm-btn default-regular-h5"
                                  onClick={() => GoToSubMethods("trusts", "17", "yes")}
                                >
                                  {commonNames.Select}
                                </Button>
                              </Col>
                            </Row>
                          </div>

                          <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                          <div className="vi-innerArea">
                            <Row>
                              <Col xl={11} md={9}>
                                <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={2} /></div>
                              </Col>
                              <Col xl={1} md={3} >

                                <Button
                                  className="cs-btn-danger sm-btn default-regular-h5"
                                  onClick={() => GoToSubMethods("trusts", "18", "yes")}
                                >
                                  {commonNames.Select}
                                </Button>
                              </Col>
                            </Row>
                          </div>

                          <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                          <div className="vi-innerArea">
                            <Row>
                              <Col xl={11} md={9}>
                                <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={3} /></div>
                              </Col>
                              <Col xl={1} md={3} >

                                <Button
                                  className="cs-btn-danger sm-btn default-regular-h5"
                                  onClick={() => GoToSubMethods("trusts", "19", "yes")}
                                >
                                  {commonNames.Select}
                                </Button>
                              </Col>
                            </Row>
                          </div>

                          <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                          <div className="vi-innerArea">
                            <Row>
                              <Col xl={11} md={9}>
                                <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={37} /></div>
                              </Col>
                              <Col xl={1} md={3} >

                                <Button
                                  className="cs-btn-primary sm-btn default-regular-h5"
                                  onClick={() => GoToSubMethods("trusts", "42", "yes")}
                                >
                                  {commonNames.Select}
                                </Button>
                              </Col>
                            </Row>
                          </div>

                          <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                          <div className="vi-innerArea">
                            <Row>
                              <Col xl={11} md={9}>
                                <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={38} /></div>
                              </Col>
                              <Col xl={1} md={3} >

                                <Button
                                  className="cs-btn-primary sm-btn default-regular-h5"
                                  onClick={() => GoToSubMethods("trusts", "41", "yes")}
                                >
                                  {commonNames.Select}
                                </Button>
                              </Col>
                            </Row>
                          </div>

                          <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                          <div className="vi-innerArea">
                            <Row>
                              <Col xl={11} md={9}>
                                <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={25} /></div>

                              </Col>
                              <Col xl={1} md={3} >
                                <Button
                                  className="cs-btn-primary sm-btn default-regular-h5"
                                  onClick={() => setShowWarning(true)}
                                >
                                  {commonNames.Select}
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      }

                    </div>
                  )}
                </div>
              }

              <p className="default-regular-sub-heading-m">
                If you are not an accredited investor or wish to cancel this
                verification request, &nbsp;
                <Link
                  onClick={handleConfirmationModal}
                  className="vi-link-color"
                >
                  click here
                </Link>{" "}
              </p>
            </>
          }
        </Container>
        {/* )} */}
      </section>

      <WarningModal_VI showWarning={showWarning} onClose={() => setShowWarning(false)} onButtonClick={() => GoToSubMethods("entities", "21", "yes")} />
      <WarningModal_VI showWarning={showWarning2} onClose={() => setShowWarning2(false)} onButtonClick={() => GoToSubMethods("entities", "25", "yes")} />
      <ConfirmationModel
        setShowConfirmation={setShowConfirmation}
        showConfirmation={showConfirmation}

      />
    </>
  );
};

export default TrustsMethods;
