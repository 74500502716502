import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Modal,
  Row,
  Col,
  Dropdown
} from "react-bootstrap";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import IndividualJoint from "../../../commonComponent/pfsComponent/IndividualJoint";
import { roleName } from "../../../config/commonRoleName";

const SelectPFSTypeModal = props => {
  const { showSharePFSType, onSendSharePFS } = props;
  const [selectType, setSelectType] = useState(null);
  const [inputField, setInputField] = useState({});
  const [emailError, setEmailError] = useState('');

  const pfsType = [
    { name: "Joint", key: 'joint' },
    { name: "Individual", key: roleName.individual },
  ];

  useEffect(() => {
    if (selectType === roleName.individual) {
      setInputField({ selectType: selectType })
    } else {
      setInputField({ ...inputField, selectType: selectType })
    }

  }, [selectType]);

  const handleChange = (event) => {
    setInputField({
      ...inputField,
      [event.target.name]: event.target.value
    })
  }

  const handleSelectType = (event) => {
    setSelectType(event)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectType === "joint" && ((Object.keys(inputField).length === 0 && inputField.constructor === Object) || inputField.email === "")) {
      setEmailError('Enter a valid email address');
      return false;
    } else {
      setEmailError('');
      // onSubmitSharePFSForm(inputField);
    }

    onSendSharePFS(inputField);
  }

  return (
    <Modal
      show={showSharePFSType}
      onHide={!showSharePFSType}
      backdrop="static"
      keyboard={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">Select PFS type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="select-pfs-form">
          <Form.Group className="cs-form-group">
            <Form.Label>You want to share PFS as </Form.Label>
            {/* {userRole == 1 && IsChecked != undefined ? (
              <Form.Select
                aria-label="Default select example"
                className="cap-select-role select-user-role select-select"
                onChange={(e) => props.setSelectValue(e.target.value)}
              >
                <option value={1}>Individual</option>
              </Form.Select>
            ) : ( */}
            <Dropdown onSelect={handleSelectType}>
              <Dropdown.Toggle
                variant="primary"
                id="dropdown-basic"
                className="indi-sharepfs-dropdown main-filter default-regular-sub-heading-s"
              >
                {selectType === null
                  ? "Choose joint or individual"
                  : selectType === "individual"
                    ? "Individual"
                    : "Joint"}
                <span className="cs-neutral-90 cs-dropdown-icon">
                  <CapsyncIcon title="chevron-down-outlined" size="18" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="cs-scroller">
                {pfsType.map((shareType, idx) => {
                  return (
                    <Dropdown.Item
                      key={idx}
                      className={`${props.selectValue == shareType.key ? 'selected-nav-item' : ''} default-regular-sub-heading-s`}
                      eventKey={shareType.key}
                    >{shareType.name}</Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            {/* )} */}
          </Form.Group>
        </div>
        {selectType === "joint" && <>
          <Row>
            <Col lg={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>
                  Enter name of cosigner
                </Form.Label>
                <Form.Control
                  type="text"
                  className={inputField.spouseName && "cs-input-field-active"}
                  autoComplete="off"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  name="spouseName"
                  value={inputField.spouseName}
                  onChange={handleChange}
                  maxLength={50}
                // onChange={(e) =>
                //   props.HandleSpouseNameChange(e.target.value)
                // }
                />
                <span className="form-error-msg default-light-body-text-s cs-danger">
                  {/* {nameValidation} */}
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Form.Group className="cs-form-group">
                <Form.Label >
                  Enter email of cosigner
                </Form.Label>
                <Form.Control
                  type="email"
                  className={inputField.jointEmail && "cs-input-field-active"}
                  autoComplete="off"
                  name="jointEmail"
                  value={inputField.jointEmail}
                  onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                  onChange={handleChange}
                />
                {emailError !== "" && (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {emailError}
                  </span>)}
              </Form.Group>
            </Col>
          </Row>
        </>}

        <div className="cs-modal-btn">
          <Button
            className="cs-btn-secondary md-btn default-regular-h5"
          // onClick={() => setOpenModal(false)}
          >Cancel </Button>
          <Button
            className="cs-btn-primary md-btn default-regular-h5"
            onClick={handleSubmit}
          // onClick={() =>
          //   handleModal(props.secondEmail, props.secondUserName)
          // }
          >Send</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
};

export default SelectPFSTypeModal;