/* eslint-disable */
import React, { useState, useEffect, createContext } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Form,
  Container,
  Card,
  ModalFooter,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  userFindRecord,
  fileEncryptionUpdateRecord,
} from "../../../../slices/user";
import { clearMessage } from "../../../../slices/message";
import {
  mfaEmailAuth,
  mfaPhoneAuth,
  mfaGoogleAuth,
} from "../../../../slices/auth";
import CommonMfEmailPin from "../commonMfEmailPin";
import CommonMfPhonePin from "../commonMfPhonePin";
import CommonMfGooglePin from "../commonMfGooglePin";
import CommonSettingHeading from "../CommonHeader/CommonSettingHeading";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import CapsyncSectionHeader from "../../../../commonComponent/CapsyncSectionHeader";
import "../../settings/File_Encryption/file_encryption.css";
import CommonSettingDropdown from "../CommonHeader/CommonSettingDropdown";
import { useLocation, useNavigate } from "react-router-dom";
import SkeletonFileEncryption from "./SkeletonFileEncryption";
import Skeleton from "react-loading-skeleton";
import { uniValidation } from "../../liabilities/categoryForms/validationSchema";
import PasswordRequirement from "../../../../commonComponent/PasswordRequirement";
import { DecryptFilePassword } from "../../../../customHooks/EncryptDecryptReact";

export const Themeprovider = createContext(null);

const PASSWORD_TYPE = {
  FILE_MANAGEMENT: "FileManagement",
  BOTH: "Both",
  PFS: "PFS",
  BOTH_CHANGE: "BothChange",
};

const fileEncryption = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [showpass, setshowpass] = useState(false);
  const [showpass1, setshowpass1] = useState(false);
  const [showpass2, setshowpass2] = useState(false);
  const [showpass3, setshowpass3] = useState(false);
  const [record, setRecord] = useState({});
  const [errMsg, setErrMsg] = useState("");
  const [emptyValidation, setEmptyValidation] = useState("");
  const [modalType, setModalType] = useState(0);
  const [mfaType, setMfaType] = useState(0);
  const [showPwdRequirements, setShowPwdRequirements] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [showPwdRequirementsCurr, setShowPwdRequirementsCurr] = useState(false);
  const [showPwdCurr, setShowPwdCurr] = useState(false);
  const [isBothPasswordSame, setIsBothPasswordSame] = useState(false);
  const [changePasswordType, setChangePasswordType] = useState(false);
  const [lastClickedShowPassword, setLastClickedShowPassword] = useState("");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const MFAoptionValue = record && record.firstSignInSteps === 1;
  const { pathname } = useLocation();
  const isMySettings = pathname?.split("/")?.[2] === "my-settings";

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const MFAEmail = record.MFAEmail;
  const MFAPhone = record.MFAPhone;
  const MFAGoogle = record.MFAGoogle;

  const findUserRecord = () => {
    if (id) {
      dispatch(userFindRecord({ id }))
        .unwrap()
        .then((response) => {
          setRecord(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const email = record.email;

  const handleMFAOptionChange = (type) => {
    setMfaType(type);
    // setMfaModal(false);
    // setMfaOptionModal(true);
  };
  const submitMfaOption = () => {
    if (mfaType === 1) {
      dispatch(clearMessage());
      dispatch(mfaEmailAuth({ email }))
        .then((data) => {
          if (data.payload.code === 200) {
            setModalType(1);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (mfaType === 2) {
      dispatch(clearMessage());
      dispatch(mfaPhoneAuth({ id, email }))
        .then((res) => {
          if (res.payload.code === 200) {
            localStorage.setItem("phone", JSON.stringify(res.payload.res_data));
            setModalType(2);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (mfaType === 3) {
      setModalType(3);
    }
    // setMfaModal(false);
    // setMfaOptionModal(true);
  };

  // const mfOptionEmail = () => {
  //   dispatch(clearMessage());
  //   dispatch(mfaEmailAuth({ email }))
  //     .then((data) => {
  //       if (data.payload.code === 200) {
  //         setModalType(1);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const mfOptionPhone = () => {
  //   dispatch(clearMessage());
  //   dispatch(mfaPhoneAuth({ id, email }))
  //     .then((res) => {
  //       if (res.payload.code === 200) {
  //         localStorage.setItem("phone", JSON.stringify(res.payload.res_data));
  //         setModalType(2);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const mfOptionGoogle = () => {
  //   setModalType(3);
  // };

  const mfaPassChange = (type = PASSWORD_TYPE.PFS) => {
    setModalShow1(true);
    setChangePasswordType(type);
  };

  useEffect(() => {
    findUserRecord();
  }, []);

  useEffect(() => {
    setIsBothPasswordSame(
      user &&
        user.user &&
        user.user.fileEncryption === user.user.fileManagementEncryption
    );
    if (record && record.fileEncryption) {
      setIsBothPasswordSame(
        record && record.fileEncryption === record.fileManagementEncryption
      );
    }
  }, [record]);

  const commonMfaSetup = (status) => {
    setModalShow(status);
    // setModalType(0);
    if (status === false) {
      setshowpass(true);
    }
  };

  const validationSchema = yup.object().shape({
    oldFileEncryption: yup
      .string()
      .required(uniValidation.password.require)
      .test("is-not-only-spaces", uniValidation.password.require, (value) => {
        return value.trim() !== "";
      }),
    NewFileEncryption: yup
      .string()
      .required(uniValidation.password.require)
      .test("is-not-only-spaces", uniValidation.password.require, (value) => {
        return value.trim() !== "";
      })
      .matches(/^\S*$/, uniValidation.password.comman)
      .matches(/[0-9]/, uniValidation.password.comman)
      .matches(/[a-z]/, uniValidation.password.comman)
      .matches(/[A-Z]/, uniValidation.password.comman)
      .matches(/[^A-Za-z0-9]/, uniValidation.password.comman)
      .min(8, uniValidation.password.min)
      .max(20, uniValidation.password.max),
    confirmFileEncryption: yup
      .string()
      .matches(/^\S*$/, uniValidation.confirm_password.sapces)
      .required(uniValidation.confirm_password.require)
      .test(
        "is-not-only-spaces",
        uniValidation.confirm_password.require,
        (value) => {
          return value.trim() !== "";
        }
      )
      // .oneOf([yup.ref("password"), null], uniValidation.confirm_password.match)
      .max(20, uniValidation.confirm_password.max),
  });
  const closeModalFunc = () => {
    setModalShow1(false);
    resetForm();
    setErrMsg("");
    setShowPwd(false);
    setShowPwdCurr(false);
    setshowpass1(false);
    setshowpass2(false);
    setshowpass3(false);
  };

  const {
    values,
    resetForm,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setErrors,
  } = useFormik({
    initialValues: {
      NewFileEncryption: "",
      oldFileEncryption: "",
      confirmFileEncryption: "",
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      values.id = id;
      if (values.NewFileEncryption === "") {
        errors.NewFileEncryption = uniValidation.password.require;
        return;
      } else if (values.confirmFileEncryption === "") {
        errors.confirmFileEncryption = uniValidation.confirm_password.require;
        return;
      } else if (values.NewFileEncryption === values.oldFileEncryption) {
        errors.NewFileEncryption =
          "Password must be different to previously used passwords";
        return;
      } else if (values.NewFileEncryption !== values.confirmFileEncryption) {
        errors.confirmFileEncryption = uniValidation.confirm_password.match;
        return;
      }

      const valuesWithoutConfirmPassword = {
        ...values,
        type: isBothPasswordSame
          ? PASSWORD_TYPE.BOTH_CHANGE
          : changePasswordType,
      };
      delete valuesWithoutConfirmPassword.confirmFileEncryption;
      dispatch(fileEncryptionUpdateRecord(valuesWithoutConfirmPassword))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            setshowpass(false);
            props.toastMessage(
              "Your password has been updated successfully.",
              "success"
            );
            findUserRecord();
            closeModalFunc();
          } else {
            setErrMsg(response.message);
          }
        })
        .catch((err) => {
          setErrMsg("");
          props.toastMessage("fileEncryptionUpdateRecord :", "error");
          resetForm();
        });
    },
  });

  const checkValidation = () => {
    if (
      values.oldFileEncryption === undefined ||
      values.NewFileEncryption === undefined ||
      values.confirmFileEncryption === undefined
    ) {
      setEmptyValidation(
        "Please fill out all fields before submitting the form"
      );
    } else {
      setEmptyValidation("");
    }
  };
  const viewPass = (lastClickedShowPassword) => {
    setLastClickedShowPassword(lastClickedShowPassword);
    setMfaType(0);
    setModalType(0);
    if (!showpass) {
      setModalShow(true);
    } else {
      setshowpass(false);
    }
  };

  const toggleIsBothPasswordSameCheckbox = () => {
    if (isBothPasswordSame) {
      setIsBothPasswordSame(false);
      return;
    }
    dispatch(fileEncryptionUpdateRecord({ id: id, type: PASSWORD_TYPE.BOTH }))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setshowpass(false);
          props.toastMessage(
            "Your password has been updated successfully.",
            "success"
          );
          findUserRecord();
        }
      });
  };

  useEffect(() => setErrMsg(""), [values]);

  return (
    <>
      <section className="settings-main-section">
        <Container fluid>
          {props.is_loader.assets === true ||
          props.is_loader.liabilities === true ? (
            <div className="cs-section-topbar">
              <div className="skeleton-breadcrumb">
                <div className="skeleton-breadcrumb-1">
                  {" "}
                  <Skeleton width={50} />
                </div>
                <div className="skeleton-breadcrumb-2">
                  {" "}
                  <Skeleton width={20} />
                </div>
                <div className="skeleton-breadcrumb-3">
                  <Skeleton width={50} />
                </div>
              </div>
              <div className="cs-title">
                <h1 className="default-semi-bold-h1 cs-neutral-100">
                  <Skeleton width={200} />
                </h1>
              </div>
            </div>
          ) : (
            <div className="cs-section-topbar">
              <CapsyncSectionHeader
                mainTitle="Dashboard"
                mainUrl="/individual"
                pagetitle={isMySettings ? "My Settings" : "Settings"}
                subTitle={isMySettings ? "My Settings" : "Settings"}
              />
            </div>
          )}

          {props.is_loader.assets === true ||
          props.is_loader.liabilities === true ? (
            <div className="setting-mobile-dropdown for-mob">
              <Skeleton width="100" height={30} />
            </div>
          ) : (
            <CommonSettingDropdown editAccessUser={props.editAccessUser} />
          )}

          <Card>
            <div className="settings-body">
              <>
                {props.is_loader.assets === true ||
                props.is_loader.liabilities === true ? (
                  <div className="for-des">
                    {" "}
                    <div className="skeleton-account-tabs">
                      <p className="skeleton-account-tab">
                        {" "}
                        <Skeleton width={100} />
                      </p>
                      <p className="skeleton-account-tab">
                        {" "}
                        <Skeleton width={100} />
                      </p>
                      <p className="skeleton-account-tab">
                        {" "}
                        <Skeleton width={80} />
                      </p>
                      <p className="skeleton-account-tab">
                        {" "}
                        <Skeleton width={150} />
                      </p>
                      <p className="skeleton-account-tab">
                        {" "}
                        <Skeleton width={100} />
                      </p>
                    </div>
                  </div>
                ) : (
                  <CommonSettingHeading editAccessUser={props.editAccessUser} />
                )}

                {props.is_loader.assets === true ||
                props.is_loader.liabilities === true ? (
                  <SkeletonFileEncryption />
                ) : (
                  <div className="file-encryption-page">
                    <div className="text">
                      <h3 className="default-regular-h3 cs-neutral-100">
                        Secure your files with password encryption
                      </h3>
                      <p className="default-regular-sub-heading-m cs-neutral-70">
                        Choose a password below to secure your files. You must
                        share this password with the recipient to allow them to
                        access your files.
                      </p>
                    </div>
                    <div className="file-encryption-row">
                      <Row>
                        <Col md={6}>
                          <div>
                            <Form className="form-input-button">
                              <Row>
                                <Col>
                                  <Form.Group
                                    className="cs-form-group"
                                    controlId="validationFormik01"
                                  >
                                    <Form.Label>PFS Files Password</Form.Label>
                                    <Form.Control
                                      type={
                                        showpass &&
                                        lastClickedShowPassword ===
                                          PASSWORD_TYPE.PFS
                                          ? "text"
                                          : "password"
                                      }
                                      onKeyDown={(e) => {
                                        if (
                                          e.key === " " &&
                                          e.target.selectionStart === 0
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      placeholder="To view your password, click on the eye icon."
                                      autoComplete="off"
                                      name="fileEncryption"
                                      maxLength="20"
                                      value={
                                        showpass &&
                                        lastClickedShowPassword ===
                                          PASSWORD_TYPE.PFS
                                          ? DecryptFilePassword(
                                              record.fileEncryption
                                            )
                                          : ""
                                      }
                                      className={
                                        showpass &&
                                        lastClickedShowPassword ===
                                          PASSWORD_TYPE.PFS &&
                                        "cs-input-field-active"
                                      }
                                    />

                                    <span
                                      onClick={() =>
                                        viewPass(PASSWORD_TYPE.PFS)
                                      }
                                      id="check-pass-toggle"
                                      className="input-field-icon cs-neutral-80 cursor-pointer"
                                    >
                                      <CapsyncIcon
                                        title={`${
                                          showpass &&
                                          lastClickedShowPassword ===
                                            PASSWORD_TYPE.PFS
                                            ? "show-password-outlined"
                                            : "hide-password-outlined"
                                        }`}
                                        size="18"
                                      />
                                    </span>
                                  </Form.Group>
                                  {errors.fileEncryption &&
                                  touched.fileEncryption ? (
                                    <p className="formik-form-error">
                                      {errors.fileEncryption}
                                    </p>
                                  ) : null}
                                </Col>
                              </Row>
                            </Form>

                            <Form.Check
                              type="checkbox"
                              className="cs-form-check-box"
                              onChange={toggleIsBothPasswordSameCheckbox}
                              checked={isBothPasswordSame}
                              label={
                                <span
                                  className="default-regular-body-text-m cs-neutral-90"
                                  onClick={toggleIsBothPasswordSameCheckbox}
                                >
                                  Keep both PFS and other password same
                                </span>
                              }
                            />

                            <div className="change-password-button">
                              <Button
                                onClick={() => mfaPassChange(PASSWORD_TYPE.PFS)}
                                className="cs-btn-primary lg-btn default-regular-h5"
                                variant="primary"
                              >
                                Change password
                              </Button>
                            </div>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div>
                            <Form className="form-input-button">
                              <Row>
                                <Col>
                                  <Form.Group
                                    className={`cs-form-group ${
                                      isBothPasswordSame && "cs-disabled"
                                    }`}
                                    controlId="validationFormik01"
                                  >
                                    <Form.Label>
                                      Other Files Password
                                    </Form.Label>
                                    <Form.Control
                                      type={
                                        showpass &&
                                        lastClickedShowPassword ===
                                          PASSWORD_TYPE.FILE_MANAGEMENT
                                          ? "text"
                                          : "password"
                                      }
                                      onKeyDown={(e) => {
                                        if (
                                          e.key === " " &&
                                          e.target.selectionStart === 0
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      placeholder="To view your password, click on the eye icon."
                                      autoComplete="off"
                                      name="fileEncryption"
                                      maxLength="20"
                                      value={
                                        showpass &&
                                        lastClickedShowPassword ===
                                          PASSWORD_TYPE.FILE_MANAGEMENT
                                          ? DecryptFilePassword(
                                              record.fileManagementEncryption
                                            )
                                          : ""
                                      }
                                      className={
                                        showpass &&
                                        lastClickedShowPassword ===
                                          PASSWORD_TYPE.FILE_MANAGEMENT &&
                                        "cs-input-field-active"
                                      }
                                      disabled={isBothPasswordSame}
                                    />

                                    <span
                                      onClick={() =>
                                        !isBothPasswordSame &&
                                        viewPass(PASSWORD_TYPE.FILE_MANAGEMENT)
                                      }
                                      id="check-pass-toggle"
                                      className="input-field-icon cs-neutral-80 cursor-pointer"
                                    >
                                      <CapsyncIcon
                                        title={`${
                                          showpass &&
                                          lastClickedShowPassword ===
                                            PASSWORD_TYPE.FILE_MANAGEMENT
                                            ? "show-password-outlined"
                                            : "hide-password-outlined"
                                        }`}
                                        size="18"
                                      />
                                    </span>
                                  </Form.Group>
                                  {errors.fileEncryption &&
                                  touched.fileEncryption ? (
                                    <p className="formik-form-error">
                                      {errors.fileEncryption}
                                    </p>
                                  ) : null}
                                </Col>
                              </Row>
                            </Form>

                            <div className="change-password-button">
                              <Button
                                onClick={() =>
                                  mfaPassChange(PASSWORD_TYPE.FILE_MANAGEMENT)
                                }
                                className="cs-btn-primary lg-btn default-regular-h5"
                                variant="primary"
                                disabled={isBothPasswordSame}
                              >
                                Change password
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </>
            </div>
          </Card>

          {props.is_loader.assets === true ||
          props.is_loader.liabilities === true ? (
            <div className="mobile-file-encryption for-mob">
              <span>
                <Skeleton width="100" />
              </span>
            </div>
          ) : (
            <div className="mobile-file-encryption">
              <Button
                onClick={() => mfaPassChange()}
                className="cs-btn-primary lg-btn default-regular-h5"
                variant="primary"
              >
                Change Password
              </Button>
            </div>
          )}
        </Container>
      </section>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="md"
        centered
        backdrop="static"
        dialogClassName="cs-md-modal"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            {modalType === 1
              ? "Email Verification"
              : modalType === 2
              ? "Mobile Verification"
              : modalType === 3
              ? "Authentication Verification"
              : "PFS Password"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalType === 0 && record && (
            <div className="choose-password-section">
              <h5 className="default-regular-h5 cs-neutral-80">
                Choose your preferred authentication method
              </h5>
              {MFAoptionValue && MFAEmail && (
                <div
                  className={
                    "email-verification cursor-pointer " +
                    (mfaType === 1 ? "active-method" : "")
                  }
                  onClick={() => handleMFAOptionChange(1)}
                >
                  <div className="radio-text">
                    <div className="cs-radio-btn">
                      <input
                        type="radio"
                        name="mfOption"
                        id="email"
                        value="1"
                        className="radio-button"
                        checked={mfaType === 1}
                      />
                    </div>
                    <div className="choose-pass-text">
                      <h4 className="default-regular-h4 cs-neutral-90">
                        Email verification
                      </h4>
                      <h6 className="default-regular-h6 cs-neutral-70">
                        Send a code to your email
                      </h6>
                    </div>
                  </div>
                  <span className="cs-neutral-80">
                    <CapsyncIcon title="email-outlined" size="22" />
                  </span>
                </div>
              )}
              {MFAoptionValue && MFAPhone && (
                <div
                  className={
                    "mobile-verification cursor-pointer " +
                    (mfaType === 2 ? "active-method" : "")
                  }
                  onClick={() => handleMFAOptionChange(2)}
                >
                  <div className="radio-text">
                    <div className="cs-radio-btn">
                      <input
                        type="radio"
                        name="mfOption"
                        id="mobile"
                        value="2"
                        className="radio-button"
                        checked={mfaType === 2}
                      />
                    </div>
                    <div className="choose-mob-text">
                      <h4 className="default-regular-h4 cs-neutral-90">
                        Mobile verification
                      </h4>
                      <h6 className="default-regular-h6 cs-neutral-70">
                        Send a code to your phone via SMS
                      </h6>
                    </div>
                  </div>
                  <span className="cs-neutral-80">
                    <CapsyncIcon title="mobile-outlined" size="22" />
                  </span>
                </div>
              )}
              {MFAoptionValue &&
                MFAGoogle &&
                record.MFAGoogleSecret !== false && (
                  <div
                    className={
                      "authentication-app cursor-pointer " +
                      (mfaType === 3 ? "active-method" : "")
                    }
                    onClick={() => handleMFAOptionChange(3)}
                  >
                    <div className="radio-text">
                      <div className="cs-radio-btn">
                        <input
                          type="radio"
                          name="mfOption"
                          id="auth"
                          value="3"
                          className="radio-button"
                          checked={mfaType === 3}
                        />
                      </div>
                      <div className="choose-auth-text">
                        <h4 className="default-regular-h4 cs-neutral-90">
                          Authenticator app
                        </h4>
                        <h6 className="default-regular-h6 cs-neutral-70">
                          Connect your authenticator app
                        </h6>
                      </div>
                    </div>
                    <span className="cs-neutral-80">
                      <CapsyncIcon title="qr-code-outlined" size="22" />
                    </span>
                  </div>
                )}
            </div>
          )}
          <Themeprovider.Provider
            value={{ closeModal: () => commonMfaSetup(false) }}
          >
            {modalType === 1 && (
              <CommonMfEmailPin
                setModalShow={setModalShow}
                setshowpass={setshowpass}
                notitle={true}
                forFileEncryption={true}
              />
            )}

            {modalType === 2 && (
              <CommonMfPhonePin
                setModalShow={setModalShow}
                setshowpass={setshowpass}
                notitle={true}
                forFileEncryption={true}
              />
            )}

            {modalType === 3 && (
              <CommonMfGooglePin
                setModalShow={setModalShow}
                setshowpass={setshowpass}
                notitle={true}
                forFileEncryption={true}
              />
            )}
          </Themeprovider.Provider>
        </Modal.Body>
        {!modalType && (
          <ModalFooter>
            <div className="pfs-password-buttons">
              <Button
                onClick={() => setModalShow(false)}
                className="cs-btn-secondary md-btn default-regular-h5"
                variant="secondary"
              >
                Cancel
              </Button>
              <Button
                className="cs-btn-primary md-btn default-regular-h5"
                variant="primary"
                onClick={submitMfaOption}
                disabled={mfaType === 0 ? true : false}
              >
                Next
              </Button>
            </div>
          </ModalFooter>
        )}
      </Modal>

      <Modal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            Change Password
          </Modal.Title>
          {/* <CloseButton onClick={closeModalFunc} /> */}
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg={12}>
                <Form.Group
                  className={`cs-form-group ${
                    showPwdRequirementsCurr && "last-password-feild"
                  }`}
                >
                  <Form.Label>Current password</Form.Label>
                  <div>
                    <Form.Control
                      className={
                        values.oldFileEncryption && "cs-input-field-active"
                      }
                      type={showPwdCurr ? "text" : "password"}
                      autoComplete="off"
                      name="oldFileEncryption"
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.selectionStart === 0) {
                          e.preventDefault();
                        }
                      }}
                      maxLength="20"
                      value={values.oldFileEncryption}
                      onChange={handleChange}
                      // onBlur={() => setShowPwdRequirementsCurr(false)}
                      // onFocus={() => setShowPwdRequirementsCurr(true)}
                    />
                    <span
                      onClick={() => setShowPwdCurr(!showPwdCurr)}
                      onMouseDown={(e) => e.preventDefault()}
                      className="input-field-icon cursor-pointer cs-neutral-80"
                    >
                      {showPwdCurr ? (
                        <CapsyncIcon title="show-password-outlined" size="18" />
                      ) : (
                        <CapsyncIcon title="hide-password-outlined" size="18" />
                      )}
                    </span>
                  </div>
                  {!showPwdRequirementsCurr &&
                  errors.oldFileEncryption &&
                  touched.oldFileEncryption ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.oldFileEncryption}
                    </span>
                  ) : errMsg ? (
                    <div className="cs-msg default-regular-body-text-s cs-danger cs-flex">
                      <span className="icon">
                        <CapsyncIcon title="info-filled" />
                      </span>
                      {errMsg}
                    </div>
                  ) : null}
                </Form.Group>
                {showPwdRequirementsCurr && (
                  <PasswordRequirement password={values.oldFileEncryption} />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Form.Group
                  className={`cs-form-group ${
                    showPwdRequirements && "last-password-feild"
                  }`}
                >
                  <Form.Label>New password</Form.Label>
                  <div>
                    <Form.Control
                      className={
                        values.NewFileEncryption && "cs-input-field-active"
                      }
                      type={showPwd ? "text" : "password"}
                      autoComplete="off"
                      name="NewFileEncryption"
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.selectionStart === 0) {
                          e.preventDefault();
                        }
                      }}
                      maxLength="20"
                      value={values.NewFileEncryption}
                      onChange={handleChange}
                      onBlur={() => setShowPwdRequirements(false)}
                      onFocus={() => setShowPwdRequirements(true)}
                    />
                    <span
                      onClick={() => setShowPwd(!showPwd)}
                      onMouseDown={(e) => e.preventDefault()}
                      className="input-field-icon cursor-pointer cs-neutral-80"
                    >
                      {showPwd ? (
                        <CapsyncIcon title="show-password-outlined" size="18" />
                      ) : (
                        <CapsyncIcon title="hide-password-outlined" size="18" />
                      )}
                    </span>
                  </div>
                  {!showPwdRequirements &&
                  errors.NewFileEncryption &&
                  touched.NewFileEncryption ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.NewFileEncryption}
                    </span>
                  ) : null}
                </Form.Group>
                {showPwdRequirements && (
                  <PasswordRequirement password={values.NewFileEncryption} />
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Form.Group className="cs-form-group last-form-field">
                  <Form.Label>Confirm new password</Form.Label>
                  <div>
                    <Form.Control
                      type={showpass3 ? "text" : "password"}
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.selectionStart === 0) {
                          e.preventDefault();
                        }
                      }}
                      autoComplete="off"
                      maxlength="20"
                      name="confirmFileEncryption"
                      value={values.confirmFileEncryption}
                      onChange={(e) => {
                        handleChange(e);
                        setErrMsg("");
                      }}
                      // onBlur={handleBlur}
                      className={
                        values.confirmFileEncryption && "cs-input-field-active"
                      }
                    />

                    <span
                      className="input-field-icon cs-neutral-80 cursor-pointer"
                      onClick={() => setshowpass3(!showpass3)}
                    >
                      <CapsyncIcon
                        title={`${
                          showpass3
                            ? "show-password-outlined"
                            : "hide-password-outlined"
                        }`}
                        size="18"
                      />
                    </span>
                  </div>
                  {errors.confirmFileEncryption &&
                  touched.confirmFileEncryption ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.confirmFileEncryption}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            {emptyValidation && (
              <p className="default-light-body-text-s cs-danger">
                {emptyValidation}
              </p>
            )}
          </Form>
        </Modal.Body>
        <ModalFooter>
          <div className="change-password-buttons">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              variant="secondary"
              onClick={closeModalFunc}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit}
              className="cs-btn-primary md-btn default-regular-h5"
              variant="primary"
              disabled={
                !values.oldFileEncryption ||
                !values.NewFileEncryption ||
                !values.confirmFileEncryption
              }
            >
              Change Password
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default fileEncryption;
