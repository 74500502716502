import { createSlice } from "@reduxjs/toolkit";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const initialState = {
  isIdpLoadedScript: false,
  isCmLoadedScript: [],
  arrayId: "",
};

const arraySlice = createSlice({
  name: "array",
  initialState,
  reducers: {
    setIdpLoadedScript: (state, action) => {
      state.isIdpLoadedScript = action.payload;
    },
    setArrayId: (state, action) => {
      state.arrayId = action.payload;
    },
    setCmLoadedScript: (state, action) => {
      const scriptName = action.payload;
      if (!scriptName) {
        state.isCmLoadedScript = [];
      } else {
        const index = state.isCmLoadedScript.findIndex(
          (item) => item === scriptName
        );
        if (index === -1) {
          state.isCmLoadedScript.push(scriptName);
        } else {
          state.isCmLoadedScript[index] = scriptName;
        }
      }
    },
  },
});

export const { setIdpLoadedScript, setCmLoadedScript, setArrayId } =
  arraySlice.actions;

export default arraySlice.reducer;

const selectIdpLoadedScript = (state) => state.array.isIdpLoadedScript;
export const useIdpLoadedScript = () => {
  const isIdpLoadedScript = useSelector(selectIdpLoadedScript);
  return useMemo(() => isIdpLoadedScript, [isIdpLoadedScript]);
};

const selectCmLoadedScript = (state) => state.array.isCmLoadedScript;
export const useCmLoadedScript = () => {
  const isCmLoadedScript = useSelector(selectCmLoadedScript);
  return useMemo(() => isCmLoadedScript, [isCmLoadedScript]);
};

const selectArrayId = (state) => state.array.arrayId;
export const useArrayId = () => {
  const arrayId = useSelector(selectArrayId);
  return useMemo(() => arrayId, [arrayId]);
};
