import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormControl,
} from "react-bootstrap";
import LeftPart from "./LeftPart";
import { clearMessage } from "../../slices/message";
// import { isValidPhoneNumber } from "react-phone-number-input";
import { mfaPhoneAuthSetup } from "../../slices/auth";
import LogoImage from "../logoImage";
import { uniValidation } from "../individual/liabilities/categoryForms/validationSchema";

const SignInMFAuthPhoneSetup = () => {
  const { user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [phoneNo, setPhoneNo] = useState(user && user.user.phoneNo); //.replace(/\D/g, "").slice(-10)
  const phoneNo = user && user.user.phoneNo;
  const id = user && user.user.id;
  const email = user && user.user.email;

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  // const handleChange = (val) => {
  //   if (val) {
  //     if (isValidPhoneNumber(val) === true) {
  //       setPhoneNo(val);
  //       setMsg("");
  //     } else {
  //       setPhoneNo(val);
  //       setMsg(uniValidation.phone_number.valid);
  //     }
  //   } else {
  //     setPhoneNo();
  //     setMsg(uniValidation.phone_number.valid);
  //   }
  // };
  const handleSubmit = () => {
    setLoading(true);
    if (phoneNo && msg === "") {
      dispatch(mfaPhoneAuthSetup({ id, email, phoneNo }))
        .unwrap()
        .then((res) => {
          setLoading(false);
          if (res.code === 200) {
            localStorage.setItem("phone", JSON.stringify(res.res_data));
            navigate("/mf-auth-phonepin", {
              res_phoneNo: res.res_data,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setMsg(uniValidation.phone_number.valid);
    }
  };

  return (
    <section className="auth-section">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={6}>
            <LeftPart
              page="mfa-phone-pin"
              img="auth-image.svg"
              title="Your New Immersive Digital Finance Hub."
              text="Sync your real-time digital Personal Financial Statement with CapSync."
            />
          </Col>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <div className="auth-right-section auth-mobile-setup">
                  <LogoImage />
                  <h1 className="default-semi-bold-h1 cs-neutral-100">
                    Mobile verification
                  </h1>
                  <hr className="head-underline" />
                  <div className="auth-cs-logo">
                    <span className="default-regular-sub-heading-s cs-neutral-80">
                      Insert your phone number and we will send you a verification code over SMS.
                    </span>
                  </div>
                  <div>
                    <Form.Group className="cs-form-group">
                      <FormControl
                        className=""
                        placeholder="Phone Number"
                        name="phoneNo"
                        value={phoneNo}
                        disabled={true}
                      />
                      {/*<PhoneInput
                  limitMaxLength={15}
                        international
                        countryCallingCodeEditable={false}
                        placeholder="Phone Number"
                        // maxlength="16"
                        pattern="[1-9]{1}[0-9]{9}"
                        autoComplete="off"
                        name="phoneNo"
                        defaultCountry="US"
                        withCountryCallingCode={false}
                        value={phoneNo}
                        className="form-control"
                        onChange={handleChange}
                        countries={["IN", "GB", "US"]}
                        disabled={true}
                      /> */}
                      {msg ? <p className="formik-form-error">{msg}</p> : null}
                    </Form.Group>
                    <Button
                      type="submit"
                      className="cs-btn-primary lg-btn default-regular-h5"
                      // disabled={loading}
                      onClick={handleSubmit}
                    >
                      Send code
                    </Button>
                  </div>

                  {message && (
                    <div className="">
                      {/* <BsFillInfoCircleFill className="iconAlert" /> */}
                      {message}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SignInMFAuthPhoneSetup;
