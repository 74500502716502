import axios from "axios";
import API from "../util/api";
import { headerAddToken } from "../util/common";

const zipCodeLookup = async (values) => {
  let header = await headerAddToken();
  return API.post(`/google_manual/zipcode_search`, values, header);
}

const autoCompleteAddress = async (values) => {
  let header = await headerAddToken();
  return API.post(`/google/auto_search_address`, values, header);
}

const placeSearch = async (values) => {
  let header = await headerAddToken();
  return API.post(`/google/search_place`, values, header);
}

export { zipCodeLookup, autoCompleteAddress, placeSearch }