/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Button, Form, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import moment from "moment";
import { add_advisors } from "../../../slices/requests";
import {
  wm_delete_individual_account_from_ind,
  access_invitation_Pfs,
  add_recently,
} from "../../../slices/pfs_access";
import AddModal from "./AddModal";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import "./Individual.css";
import { socket } from "../../../config/Socket";
import SkeletonIndCompany from "./SkeletonIndCompany";
import { setIndividuals } from "../../../slices/pfs_access";
import CapsyncToolTip from "../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import { customStyles, formatPhoneNumber } from "../../../util/common";
import { roleName } from "../../../config/commonRoleName";
import Pagination from "../../../commonComponent/Pagination";

const Individual = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const role = user && user.user_role.role;
  const wm_id = user && user.user.id;
  const individuals = useSelector((state) => state.manageUser.individuals);

  const [search, setSearch] = useState([]);
  const [companyIndividual, setCompanyIndividual] = useState([...individuals]);
  const [modalState, setModalState] = useState(false);
  const [rowStartIdx, setRowStartIdx] = useState(0);
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownItems[0]);

  const changeModalState = (s) => {
    setModalState(s);
  };

  const getIndividuals = () => {
    dispatch(
      access_invitation_Pfs({
        email: user && user.user.email,
      })
    )
      .unwrap()
      .then((response) => {
        const res = response.length > 0 ? [...response] : [];
        setCompanyIndividual(res);
        dispatch(setIndividuals(res));
      })
      .catch((err) => {
        props.toastMessage("access_invitation_Pfs :" + err.message, "error");
      });
  };

  useEffect(() => {
    if (user.user.email) {
      getIndividuals();
    }
  }, [user]);

  useEffect(() => {
    const handleGetUpdatedList = (msg) => {
      getIndividuals();
    };

    socket.on("get_updated_list", handleGetUpdatedList);

    return () => {
      socket.off("get_updated_list", handleGetUpdatedList);
    };
  }, []);

  useEffect(() => {
    setCompanyIndividual(individuals);
  }, [individuals]);

  useEffect(() => {
    getIndividuals();
  }, []);

  const deleteRecord = (id, displayName, email) => {
    props.CapsyncDeleteMessage(
      true,
      "delete message",
      "Are you sure you want to remove the individual access from your account?",
      () => deleteFunc(id, displayName, email)
    );
  };

  function deleteFunc(id, displayName, email) {
    dispatch(
      wm_delete_individual_account_from_ind({
        pfs_id: id,
        WealthId: user && user.user.id,
        deletedDisplayName: displayName,
        deletedEmail: email,
      })
    )
      .unwrap()
      .then(() => {
        props.toastMessage(
          "This client has been deleted successfully.",
          "success"
        );
        getIndividuals();
      })
      .catch((err) => {
        props.toastMessage(
          "wm_delete_individual_account_from_ind :" + err.message,
          "error"
        );
      });
  }
  const handleAccessPFS = (row) => {
    if (row.to_status === 1 && row.is_subscribe === true) {
      accessIndividualPFS(row);
    }
  };

  const accessIndividualPFS = async (store) => {
    //------------------------------------------------- Aniket:- Do not remove this code -------------------------------------------------//
    try {
      await dispatch(
        add_recently({
          recent_ids: Number(store.ind_id),
          wm_id: Number(window.localStorage.getItem("id")),
          module: roleName.individual,
        })
      ).unwrap();

      const payload = {
        advisor_user_id: Number(window.localStorage.getItem("id")),
        user_id: Number(store.ind_id),
        advisor_user_role: role,
      };

      if (store.to_access_permission === 1) {
        await dispatch(add_advisors(payload)).unwrap();
        props.accessPFS(store.ind_id, wm_id);
      } else {
        const response = await dispatch(add_advisors(payload)).unwrap();
        if (response) {
          socket.emit("broadcast_message", { user_id: Number(store.ind_id) });
          props.accessPFS(store.ind_id, wm_id);
        }
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
    //--------------------------------------------------------------------------------------------------------------------------------//
  };

  const columns = [
    {
      name: "First name",
      selector: (row) => {
        const firstName = row.firstName || "-";
        if (firstName.length > 20) {
          const shortenedFirstName = firstName.substring(0, 20) + "...";
          return (
            <CapsyncToolTip
              Child={shortenedFirstName}
              placement={"top"}
              size="18"
              message={firstName}
              type="text"
            />
          );
        } else {
          return firstName;
        }
      },
    },
    {
      name: "Last name",
      selector: (row) => {
        const lastName = row.lastName || "-";
        if (lastName.length > 20) {
          const shortenedLastName = lastName.substring(0, 20) + "...";
          return (
            <CapsyncToolTip
              Child={shortenedLastName}
              placement={"top"}
              size="18"
              message={lastName}
              type="text"
            />
          );
        } else {
          return lastName;
        }
      },
    },
    {
      name: "Email",
      selector: (row) => row.email,
      selector: (row) => {
        const email = row.email || "-";
        if (email.length > 25) {
          const shortenedEmail = email.substring(0, 25) + "...";
          return (
            <CapsyncToolTip
              Child={shortenedEmail}
              placement={"top"}
              size="18"
              message={email}
              type="text"
            />
          );
        } else {
          return email;
        }
      },
    },
    {
      name: "Phone No.",
      selector: (row) => (!!row.phoneNo ? formatPhoneNumber(row.phoneNo) : "-"),
    },
    {
      name: "Date Added",
      selector: (row) =>
        row.to_status == 1 ? (
          <div>{moment(row.updatedAt).format("MM/DD/YYYY")}</div>
        ) : row.to_status === 2 ? (
          <span className="cs-badge cs-danger default-regular-body-text-xs">
            Declined
          </span>
        ) : (
          <span className="cs-badge cs-warning default-regular-body-text-xs">
            Pending
          </span>
        ),
    },
    {
      name: "Actions",
      selector: (row) => (
        <div className="wm-individual-action">
          <span
            className="cs-danger"
            onClick={(e) => {
              deleteRecord(row.pfs_access_id, row.displayName, row.email);
              e.stopPropagation();
            }}
          >
            <CapsyncToolTip
              Child={"delete-outlined"}
              placement={"top"}
              size="18"
              message={"Delete"}
              type="icon"
            />
          </span>
          <span
            className={
              row.to_status === 1 && row.is_subscribe !== false
                ? "cs-neutral-80 "
                : "cs-neutral-80 cs-disabled-icon"
            }
            onClick={(e) => {
              handleAccessPFS(row);
              e.stopPropagation();
            }}
          >
            {row.to_status === 1 && row.is_subscribe === false ? (
              <CapsyncToolTip
                Child={"chevron-right-outlined"}
                placement={"top"}
                size="18"
                message={"There’s an issue with this users account"}
                type="icon"
              />
            ) : (
              <CapsyncIcon title="chevron-right-outlined" size="18" />
            )}
          </span>
        </div>
      ),
    },
  ];

  const searchIndividual = (params) => {
    if (params === "") {
      setSearch(params);
      setCompanyIndividual(individuals);
    } else {
      setSearch(params);
      let findIndividual = individuals.filter((row) => {
        const fullName = `${row.firstName} ${row.lastName}`;
        return (
          row.firstName.toLowerCase().includes(params.toLowerCase()) ||
          fullName.toLowerCase().includes(params.toLowerCase()) ||
          row.lastName.toLowerCase().includes(params.toLowerCase()) ||
          row.email.toLowerCase().includes(params.toLowerCase()) ||
          row.phoneNo.toString().includes(params) ||
          formatPhoneNumber(row.phoneNo).includes(params) ||
          (row.to_status === 1 &&
            moment(row.updatedAt).format("MM/DD/YYYY").includes(params)) ||
          (row.to_status !== 1 &&
            "Pending".toLowerCase().includes(params.toLowerCase()))
        );
      });
      setCompanyIndividual(findIndividual);
    }
  };
  const conditionalRowStyles = [
    {
      when: (row) => row.to_status === 1 && row.is_subscribe !== false,
      style: {
        cursor: "pointer", // Red background for rows with age less than 30
      },
    },
  ];

  return (
    <>
      <AddModal
        changeModalState={changeModalState}
        modalState={modalState}
        toastMessage={props.toastMessage}
        getIndividuals={getIndividuals}
      />

      {props.isLoader === true || user.user.is_subscribe === false ? (
        <section className="dashboard-main-section">
          <Container fluid>
            <SkeletonIndCompany />
          </Container>
        </section>
      ) : (
        <>
          <section className="dashboard-main-section">
            <Container fluid>
              <div className="cs-section-topbar financial-advisor-topbar">
                <div className="mobile-add-head-button">
                  <h1 className="default-semi-bold-h1 cs-neutral-100">
                    Individuals
                  </h1>
                  <span
                    className="cs-neutral-10 mobile-add-acc-icon"
                    onClick={() => changeModalState(true)}
                  >
                    <CapsyncIcon title="add-filled" size="18" />
                  </span>
                </div>
                <div className="cs-search-bar">
                  <Form.Group className="cs-form-group">
                    <Form.Control
                      type="text"
                      placeholder="Search by individual name"
                      value={search}
                      className={search && "cs-input-field-active"}
                      onChange={(event) => searchIndividual(event.target.value)}
                    />
                    <span className="input-field-icon cs-neutral-70">
                      <CapsyncIcon title="search-outlined" size="18" />
                    </span>
                  </Form.Group>
                </div>

                <Button
                  type="button"
                  className="default-regular-h5 cs-btn-icon-primary desktop-add-button"
                  onClick={() => changeModalState(true)}
                >
                  <span className="cs-neutral-10">
                    <CapsyncIcon title="add-filled" size="18" />
                  </span>
                  Add Individual
                </Button>
              </div>

              <div className="cs-data-table advisor-individual-table">
                <DataTable
                  columns={columns}
                  // data={companyIndividual}
                  conditionalRowStyles={conditionalRowStyles}
                  onRowClicked={(row) => handleAccessPFS(row)}
                  data={companyIndividual
                    .slice(rowStartIdx, rowStartIdx + rowsPerPage)
                    .filter((individual) => individual.to_status !== 2)}
                  sortIcon={
                    <>
                      <span className="cs-neutral-100">
                        <CapsyncIcon title="sort-outlined" size="22" />
                      </span>
                    </>
                  }
                  customStyles={customStyles}
                />
                {companyIndividual &&
                  companyIndividual.filter(
                    (individual) => individual.to_status !== 2
                  ).length !== 0 && (
                    <Pagination
                      rowStartIdx={rowStartIdx}
                      setRowStartIdx={setRowStartIdx}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                      paginationDropdownItems={paginationDropdownItems}
                      paginationList={
                        companyIndividual
                          ? companyIndividual.filter(
                              (individual) => individual.to_status !== 2
                            )
                          : []
                      }
                    />
                  )}
              </div>
            </Container>
          </section>
        </>
      )}
    </>
  );
};

export default Individual;
