import React from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import LogoImage from "./logoImage";
import BackToSignin from "./auth/BackToSignin";

const PageNotFound = () => {
  return (
    <Container>
      <div className="page404">
        <Row>
          <Col lg={12}>
            <div className="error-container">
              <LogoImage />
              <div className="error-image">
                <Image src={require("../../assets/images/other/404-image.png")} className="pagenotfound" />
              </div>
              <div className="error-content">
                <h1 className="default-semi-bold-h1 cs-neutral-100">Page Not Found</h1>
                <p className="default-semi-bold-h3 cs-neutral-80">We're sorry, the page you requested could not be found please go back to the homepage</p>   
                <BackToSignin />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default PageNotFound;
