import React from "react";
import {
  Button,
  Image,
  Row,
  OverlayTrigger,
  Tooltip,
  Col,
  Modal,
  Form,
  ModalFooter,
} from "react-bootstrap";
import emailIcon from "../../../../assets/icons/svg/outlined/email-outlined.svg";
import copypfs from "../../../../assets/icons/svg/outlined/copy-outlined.svg";

const SharePFSModal = ({
  showSharePFSModal,
  setShowSharePFSModal,
  shareEmail,
  changeToPreviewModal,
}) => {
  const copyUrl = (params) => {
    navigator.clipboard.writeText(params);
    alert("Copied to clipboard");
  };

  const changeModal = () => {
    setShowSharePFSModal(false);
    changeToPreviewModal(true);
  };

  return (
    <>
      <Modal
        show={showSharePFSModal}
        onHide={!showSharePFSModal}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">Share PFS </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  autoComplete="off"
                  placeholder="johndeo@gmail.com"
                  onKeyDown={(event) => {
                    if (event.code === "Space")
                      event.preventDefault();
                  }}
                  value={shareEmail.email}
                  disabled={true}
                />
                <span>
                  <Image src={emailIcon} />
                </span>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group className="cs-form-group ">
                <p>or Share via link</p>
                <span>
                  <OverlayTrigger
                    key="right"
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-right">Click to copy Link</Tooltip>
                    }
                  >
                    <Image
                      src={copypfs}
                      className="img-pfs"
                      onClick={() => copyUrl(shareEmail.sharelink)}
                    />
                  </OverlayTrigger>
                </span>
                <input
                  className="share-pfs-copy-input"
                  type="text"
                  autoComplete="off"
                  name="sharelink"
                  placeholder={
                    window.innerWidth >= 320 && window.innerWidth <= 575
                      ? shareEmail.sharelink.slice(22, 50) + "..."
                      : shareEmail.sharelink.slice(22, 70) + "..."
                  }
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <ModalFooter>
          <div className="cs-modal-btn">
            <Button
              onClick={() => setShowSharePFSModal(false)}
              className="cs-btn-secondary md-btn default-regular-h5"
            >
              Cancel
            </Button>
            <Button
              className="cs-btn-primary md-btn default-regular-h5"
              onClick={changeModal}
            >
              Review and Send
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SharePFSModal;
