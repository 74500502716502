/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";

// COMPONENT IMPORTS
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import FileManagementBreadcrumb from "../../../commonComponent/FileManagementBreadcrumb";
import CapsyncLoader from "../../../commonComponent/CapsyncLoader";

// API
import {
  fetch_main_list,
  move_file_folder,
} from "../../../slices/fileManagement.slice";
import commonNames from "../../../config/commonNames";
import { getFileIcon } from "../../../util/common";
import { useSelector } from "react-redux";

const FileFolderMoveModal = ({
  moveModalOpen,
  moveContent,
  moveSelectedContent = [],
  isMulti = false,
  closeModal,
  fileParentId = null,
  setOpenDuplicateModalOpen,
  setExistingMoveFiles,
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [moveLoading, setMoveLoading] = useState(false);
  const [data, setData] = useState([]);
  const [breadcrumbDetails, setBreadcrumbDetails] = useState([
    {
      title: "My Documents",
      id: 0,
      is_movable: false,
      category_id: null,
      row_id: null,
    },
  ]);
  const [parentId, setParentId] = useState(breadcrumbDetails[0].id);

  const fetchMainList = async () => {
    const user_id = Number(
      JSON.parse(window.localStorage.getItem("pfs_access_id"))
    )
      ? Number(JSON.parse(window.localStorage.getItem("pfs_access_id")))
      : Number(JSON.parse(window.localStorage.getItem("id")));
    const payload = { user_id: user_id, id: parentId, isMove: true };
    try {
      setLoading(true);
      const response = await dispatch(fetch_main_list(payload)).unwrap();
      if (response && response.code === 200) {
        setData(response.data.files || []);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("err", err);
    }
  };

  useEffect(() => {
    fetchMainList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentId]);

  const breadcrumbChange = (items) => {
    if (loading) {
      return;
    }

    setParentId(items.id);
    let data = [...breadcrumbDetails];
    let idx = data.findIndex((val) => val.id === items.id);
    let final_data = data.slice(0, idx + 1);
    setBreadcrumbDetails(final_data);
  };

  const getDataArray = () => {
    let data = [];
    let filesArray = isMulti ? moveSelectedContent : [moveContent];
    filesArray.map((val) =>
      data.push({
        id: val.id,
        fromCategoryId: val.category_id || null,
        fromRowId: val.row_id || null,
      })
    );
    return data;
  };

  const checkExistFile = () => {
    let filesArray = isMulti ? moveSelectedContent : [moveContent];
    let fileNames = [];
    filesArray.map((val) => fileNames.push(val.name));
    let existingFiles = [];
    // eslint-disable-next-line array-callback-return
    data.map((obj) => {
      if (fileNames.includes(obj.name)) {
        existingFiles.push(obj);
      }
    });
    if (existingFiles.length > 0) {
      closeClick(false);
      setMoveLoading(false);
      setExistingMoveFiles(existingFiles);
      setOpenDuplicateModalOpen(true);
      return true;
    }
    return false;
  };

  const onMoveHere = async () => {
    setMoveLoading(true);
    if (checkExistFile()) {
      setMoveLoading(false);
      return false;
    }
    try {
      let payload = {
        user_id: user.user.id,
        data: getDataArray(),
        moveLocationId: parentId,
        moveRowId:
          breadcrumbDetails[breadcrumbDetails.length - 1].row_id || undefined,
        moveCategoryId:
          breadcrumbDetails[breadcrumbDetails.length - 1].category_id ||
          undefined,
        institutionName: breadcrumbDetails[breadcrumbDetails.length - 1]
          .category_id
          ? breadcrumbDetails[breadcrumbDetails.length - 1].title
          : undefined,
      };
      let response = await dispatch(move_file_folder(payload)).unwrap();
      if (response && response.code === 200) {
        closeClick();
      }
      setMoveLoading(false);
    } catch (error) {
      setMoveLoading(false);
      console.log(error.message);
    }
  };

  const closeClick = (callListApi = true) => {
    closeModal(callListApi);
    setMoveLoading(false);
    setParentId(null);
    setData([]);
    setBreadcrumbDetails([
      {
        title: "My Documents",
        id: 0,
        is_movable: false,
        category_id: null,
        row_id: null,
      },
    ]);
  };

  const getFileExtension = (filename) => {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const checkContent = () => {
    const allowExtensions = ["pdf", "png", "jpeg", "jpg"];

    let filesArray = isMulti ? moveSelectedContent : [moveContent];

    for (let file of filesArray) {
      // Validation 1: Check file extension
      let fileExtension = getFileExtension(file.name);
      if (!allowExtensions.includes(fileExtension.toLowerCase())) {
        return false;
      }

      // Validation 2: Check file attachment
      if (!file.attachment) {
        return false;
      }
    }

    return true; // All validations passed
  };

  const checkIsDisable = (val) => {
    if (val.attachment) {
      return true;
    }
    if (
      [commonNames.Assets, commonNames.Liabilities].includes(val.name) &&
      parentId === 0
    ) {
      return !checkContent();
    }
    if (isMulti) {
      return !!moveSelectedContent.find((data) => data.id === val.id);
    } else {
      return moveContent.id === val.id;
    }
  };

  return (
    <Modal
      show={moveModalOpen}
      onHide={(e) => (moveLoading ? e.preventDefault() : closeClick(false))}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal manage-files file-management-modal"
      className="cs-common-modal-overlay">
      <Modal.Header>
        <Modal.Title>
          <div className="default-semi-bold-h3">
            {isMulti
              ? moveSelectedContent.length > 1
                ? "All"
                : moveSelectedContent[0].name
              : moveContent.name}
          </div>
          {breadcrumbDetails.length > 1 && (
            <div className="cs-breadcrumb">
              <FileManagementBreadcrumb
                mainLevelArr={breadcrumbDetails}
                onClick={(val) => {
                  breadcrumbChange(val);
                }}
              />
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="cs-move-container">
          {loading ? (
            <CapsyncLoader />
          ) : data.length === 0 ? (
            <span className="default-regular-h4">This folder is empty</span>
          ) : (
            data.map((val, index) => {
              const extension =
                val.name &&
                val.name.split(/\.(?=[^\.]+$)/)[1] &&
                val.name.split(/\.(?=[^\.]+$)/)[1].toLowerCase();
              const icon = getFileIcon(extension);
              return (
                <div
                  className={`cs-move-row ${
                    checkIsDisable(val) ? "cs-icon-disabled" : ""
                  }`}
                  key={index}
                  onClick={() => {
                    if (!loading && checkIsDisable(val)) {
                      return false;
                    }
                    if (!loading && !val.attachment) {
                      setLoading(true);
                      setParentId(val.id);
                      let oldData = [
                        ...breadcrumbDetails,
                        {
                          title: val.name,
                          id: val.id,
                          is_movable: val.is_movable,
                          category_id: val.category_id,
                          row_id: val.row_id,
                        },
                      ];
                      setBreadcrumbDetails(oldData);
                    }
                  }}>
                  <div className="cs-folder-title">
                    {val.attachment ? (
                      <span className="file-icon">{icon}</span>
                    ) : (
                      <span className="cs-neutral-80">
                        <CapsyncIcon title="folder-icon-filled" size="22" />
                      </span>
                    )}
                    <span className="default-regular-sub-heading-m cs-neutral-100">
                      {val.name}
                    </span>
                  </div>
                  {!val.attachment && (
                    <span className="cs-arrow cs-neutral-80">
                      <CapsyncIcon title="chevron-right-outlined" size="22" />
                    </span>
                  )}
                </div>
              );
            })
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="cs-modal-btn">
        <Button
          variant="secondary"
          className="cs-btn-secondary md-btn default-regular-h5"
          onClick={() => closeClick(false)}>
          Cancel
        </Button>
        <Button
          disabled={
            loading ||
            parentId === 0 ||
            fileParentId === parentId ||
            (breadcrumbDetails.some((val) =>
              [commonNames.Assets, commonNames.Liabilities].includes(val.title)
            ) &&
              breadcrumbDetails.length < 4) ||
            moveLoading
          }
          onClick={() => onMoveHere()}
          className="cs-btn-primary md-btn default-regular-h5">
          Move here
          {moveLoading && (
            <span className="cs-common-spinner cs-primary-main">
              <CapsyncIcon title="loading-outlined" size="16" />
            </span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileFolderMoveModal;
