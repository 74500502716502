import React from 'react'

const Avatar = (props) => {
    return (
        <svg width={props.size ? props.size : "60"}
            height={props.size ? props.size : "60"}
            viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg" className={`${(props.isSelected) ? 'avatar-selected' : ''}`} >
            <g clipPath="url(#clip0_5041_68254)">


                {props.title === "avtar-1" && (
                    <>
                        <path d="M30.005 56.6396L41.89 53.6396C48.675 48.0046 52.995 39.5097 52.995 29.9996C52.995 15.3346 42.715 3.06965 28.97 0.0146484C12.88 0.559648 0 13.7746 0 29.9996C0 38.3346 3.4 45.8746 8.885 51.3097L30.005 56.6396Z" fill="#CBE2FF" />
                        <path d="M52.3736 30C52.3736 39.835 47.6436 48.56 40.3286 54.035L51.1136 51.31C56.5986 45.875 59.9986 38.335 59.9986 30C59.9986 13.43 46.5686 0 29.9986 0C28.7086 0 27.4336 0.08 26.1836 0.24C40.9536 2.115 52.3686 14.725 52.3686 30H52.3736Z" fill="#BED8FB" />
                        <path d="M37.4563 11.36C37.4563 9.82996 36.2163 8.58496 34.6813 8.58496H25.1213C21.5963 8.58496 18.7363 11.445 18.7363 14.97V22.06H41.2613V15.07C41.2613 13.54 40.0213 12.295 38.4863 12.295H38.3913C37.8763 12.295 37.4563 11.875 37.4563 11.36Z" fill="#365E7D" />
                        <path d="M38.4859 12.3H38.3909C37.8759 12.3 37.4559 11.88 37.4559 11.365C37.4559 9.83497 36.2159 8.58997 34.6809 8.58997H33.6309V22.065H41.2559V15.075C41.2559 13.545 40.0159 12.3 38.4809 12.3H38.4859Z" fill="#2B4D66" />
                        <path d="M49.8109 43.635C49.0759 42.91 48.1709 42.35 47.1409 42.025L38.9509 39.43L30.0059 39.115L21.0559 39.43L12.8659 42.025C10.0959 42.9 8.21094 45.475 8.21094 48.38V50.62C13.4609 56.165 20.8009 59.71 28.9759 59.985C38.1309 57.95 45.7509 51.83 49.8159 43.64L49.8109 43.635Z" fill="#4A80AA" />
                        <path d="M51.7953 50.6149V48.3749C51.7953 46.3199 50.8553 44.4349 49.3203 43.1949C44.9703 52.0549 36.3803 58.4599 26.1953 59.7549C27.4253 59.9099 28.6753 59.9949 29.9503 59.9949C31.1703 59.9949 32.3753 59.9199 33.5603 59.7849C40.7103 58.9399 47.0953 55.5799 51.7953 50.6099V50.6149Z" fill="#407093" />
                        <path d="M30.6812 59.5499C32.4962 55.9449 37.2212 45.7149 35.8762 39.3199L30.0062 37.0349L24.1312 39.3199C22.7412 45.9099 27.8062 56.5799 29.4862 59.8599C29.8912 59.7649 30.2912 59.6599 30.6862 59.5449L30.6812 59.5499Z" fill="#E4F6FF" />
                        <path d="M33.7763 56.695L31.3963 47.33H28.6113L26.2363 56.67L29.1713 59.94C29.9163 59.77 30.6513 59.57 31.3763 59.345L33.7763 56.695Z" fill="#E28086" />
                        <path d="M29.2167 59.9899C29.4617 59.9949 29.7017 59.9999 29.9467 59.9999C30.2317 59.9999 30.5117 59.9949 30.7917 59.9849L32.3067 58.3149C31.1167 58.7349 29.8917 59.0749 28.6367 59.3449L29.2167 59.9899Z" fill="#DD636E" />
                        <path d="M35.0144 38.4601V33.1801H24.9844V38.4601C24.9844 41.2301 29.9994 44.2551 29.9994 44.2551C29.9994 44.2551 35.0144 41.2301 35.0144 38.4601Z" fill="#FFDDCE" />
                        <path d="M41.26 21.13H39.345C38.28 21.13 37.415 20.265 37.415 19.2V18.145C37.415 16.755 36.055 15.775 34.74 16.21C31.665 17.225 28.345 17.225 25.27 16.21C23.95 15.775 22.595 16.755 22.595 18.145V19.2C22.595 20.265 21.73 21.13 20.665 21.13H18.75V24.285C18.75 25.09 19.405 25.745 20.21 25.745C20.465 25.745 20.67 25.945 20.69 26.2C21 31.085 25.055 34.95 30.015 34.95C34.975 34.95 39.03 31.085 39.34 26.2C39.355 25.945 39.565 25.745 39.82 25.745C40.625 25.745 41.28 25.09 41.28 24.285V21.13H41.26Z" fill="#FFCBBE" />
                        <path d="M39.3444 21.13C38.2794 21.13 37.4144 20.265 37.4144 19.2V18.145C37.4144 16.755 36.0544 15.775 34.7394 16.21C34.3744 16.33 34.0094 16.435 33.6394 16.525V26.95C33.5894 30.34 31.7294 33.295 28.9844 34.89C29.3194 34.925 29.6594 34.945 30.0044 34.945C34.9644 34.945 39.0194 31.08 39.3294 26.195C39.3444 25.94 39.5544 25.74 39.8094 25.74C40.6144 25.74 41.2694 25.085 41.2694 24.28V21.125H39.3544L39.3444 21.13Z" fill="#FFCBBE" />
                        <path d="M24.9858 37.0399L21.0508 40.2349L25.1758 45.3349C25.6758 45.8249 26.4658 45.8549 27.0058 45.4049L30.1058 42.8149L24.9858 37.0399Z" fill="#F4FBFF" />
                        <path d="M35.0155 37.0399L30.1055 42.8149L33.0205 45.3049C33.5555 45.7599 34.3455 45.7399 34.8555 45.2499L38.9505 40.2349L35.0155 37.0399Z" fill="#F4FBFF" />
                        <path d="M24.9859 37.0399L19.2159 39.8749C18.7759 40.0899 18.6009 40.6249 18.8259 41.0599L19.9559 43.2549C20.2509 43.8249 20.1409 44.5149 19.6909 44.9649C19.4559 45.1949 19.2059 45.4499 18.9859 45.6649C18.5959 46.0549 18.4559 46.6299 18.6259 47.1599C19.9909 51.3199 26.5609 57.6849 29.0509 59.9899C29.2159 59.9899 29.3859 59.9949 29.5559 59.9999C27.7509 56.4899 21.3609 43.1649 24.9909 37.0399H24.9859Z" fill="#365E7D" />
                        <path d="M40.3159 44.9649C39.8659 44.5149 39.7559 43.8199 40.0509 43.2549L41.1809 41.0599C41.4059 40.6249 41.2309 40.0899 40.7909 39.8749L35.0209 37.0399C38.6509 43.1649 32.2659 56.4849 30.4609 59.9949C30.6309 59.9949 30.8009 59.9899 30.9709 59.9799C33.4609 57.6699 40.0209 51.3149 41.3859 47.1549C41.5559 46.6299 41.4159 46.0549 41.0259 45.6599C40.8059 45.4399 40.5559 45.1899 40.3209 44.9549L40.3159 44.9649Z" fill="#365E7D" />
                        <path d="M30.1058 42.8099L27.0508 45.3599L28.6058 47.3249H31.3908L32.9458 45.2399L30.1008 42.8049L30.1058 42.8099Z" fill="#DD636E" />
                        <path d="M29.8515 31.3449C28.5065 31.3449 27.6565 30.5949 27.6415 30.5849C27.5215 30.4799 27.5115 30.2949 27.6215 30.1749C27.7265 30.0549 27.9115 30.0449 28.0315 30.1549C28.0965 30.2149 29.6315 31.5349 31.8615 30.1249C31.9965 30.0399 32.1765 30.0799 32.2615 30.2149C32.3465 30.3499 32.3065 30.5299 32.1715 30.6149C31.3065 31.1599 30.5215 31.3449 29.8565 31.3449H29.8515Z" fill="#E9837B" />
                        <path d="M25.6009 26.8199C26.896 26.8199 27.9459 26.3431 27.9459 25.7549C27.9459 25.1668 26.896 24.6899 25.6009 24.6899C24.3058 24.6899 23.2559 25.1668 23.2559 25.7549C23.2559 26.3431 24.3058 26.8199 25.6009 26.8199Z" fill="#F6AFA8" />
                        <path d="M34.2454 26.8199C35.5405 26.8199 36.5904 26.3431 36.5904 25.7549C36.5904 25.1668 35.5405 24.6899 34.2454 24.6899C32.9503 24.6899 31.9004 25.1668 31.9004 25.7549C31.9004 26.3431 32.9503 26.8199 34.2454 26.8199Z" fill="#F6AFA8" />
                        <path d="M27.3749 20.3049C27.3449 20.5799 27.1299 20.7649 26.8899 20.7399C26.0099 20.6549 25.1349 20.7999 24.3299 21.1649C24.1099 21.2649 23.8449 21.1599 23.7299 20.9099C23.6149 20.6599 23.7199 20.3399 23.9799 20.2249C24.9049 19.8049 25.9049 19.6399 26.9149 19.7399C27.1949 19.7649 27.3999 20.0349 27.3749 20.3099V20.3049Z" fill="#2B4D66" />
                        <path d="M32.4708 20.3049C32.5008 20.5799 32.7158 20.7649 32.9558 20.7399C33.8358 20.6549 34.7108 20.7999 35.5158 21.1649C35.7358 21.2649 36.0008 21.1599 36.1158 20.9099C36.2308 20.6599 36.1258 20.3399 35.8658 20.2249C34.9408 19.8049 33.9408 19.6399 32.9308 19.7399C32.6508 19.7649 32.4458 20.0349 32.4708 20.3099V20.3049Z" fill="#2B4D66" />
                        <path d="M29.8252 29.1349C29.7902 29.1349 29.7502 29.1299 29.7152 29.1149C29.0952 28.8599 28.7552 28.5699 28.6502 28.2099C28.5102 27.7349 28.8152 27.3049 29.1352 26.8499C29.3352 26.5649 29.5452 26.2699 29.6452 25.9649C29.6952 25.8149 29.8602 25.7299 30.0102 25.7799C30.1602 25.8299 30.2452 25.9949 30.1952 26.1449C30.0652 26.5349 29.8252 26.8799 29.6052 27.1849C29.3652 27.5249 29.1402 27.8399 29.2002 28.0499C29.2352 28.1699 29.3902 28.3599 29.9302 28.5799C30.0802 28.6399 30.1502 28.8099 30.0852 28.9549C30.0402 29.0649 29.9302 29.1349 29.8202 29.1349H29.8252Z" fill="#E9837B" />
                        <path d="M26.0766 24.6849C26.5019 24.6849 26.8466 24.0357 26.8466 23.2349C26.8466 22.4341 26.5019 21.7849 26.0766 21.7849C25.6514 21.7849 25.3066 22.4341 25.3066 23.2349C25.3066 24.0357 25.6514 24.6849 26.0766 24.6849Z" fill="#2A2D6B" />
                        <path d="M33.7759 24.6849C34.2011 24.6849 34.5459 24.0357 34.5459 23.2349C34.5459 22.4341 34.2011 21.7849 33.7759 21.7849C33.3506 21.7849 33.0059 22.4341 33.0059 23.2349C33.0059 24.0357 33.3506 24.6849 33.7759 24.6849Z" fill="#2A2D6B" />
                    </>
                )}
                {props.title === "avtar-2" && (
                    <>
                        <path d="M30.6596 58.16L41.9496 54.9C49.1696 49.23 53.8096 40.43 53.8096 30.53C53.8096 15.34 42.8596 2.69996 28.4096 0.0799561C12.8596 1.19996 0.599609 14.17 0.599609 30C0.599609 38.35 3.94961 45.84 9.44961 51.28L10.6896 52.44L30.6596 58.17V58.16Z" fill="#CBE2FF" />
                        <path d="M30.5991 0C29.3091 0 28.0391 0.08 26.7891 0.24C41.5591 2.11 52.9791 14.72 52.9791 30C52.9791 41.2 46.8391 50.97 37.7391 56.13L50.5191 52.44C56.7091 46.94 60.6091 38.93 60.6091 30C60.5991 13.43 47.1691 0 30.5991 0Z" fill="#BED8FB" />
                        <path d="M39.8387 35.98C41.8387 34.81 43.0587 32.67 43.0587 30.35C43.0587 23.69 43.0587 20.04 43.0587 20.04C43.0587 13.16 37.4787 7.57996 30.5987 7.57996C23.7187 7.57996 18.1387 13.16 18.1387 20.04C18.1387 20.04 18.1387 23.69 18.1387 30.35C18.1387 32.66 19.3687 34.81 21.3587 35.98L22.7687 36.8C25.1487 38.19 27.8487 38.93 30.6087 38.93C33.3687 38.93 36.0687 38.2 38.4487 36.8L39.8587 35.98H39.8387Z" fill="#894255" />
                        <path d="M43.0595 20.0399C43.0595 14.8699 39.9195 10.4399 35.4395 8.54993V38.1499C36.4795 37.8099 37.4895 37.3599 38.4395 36.7999L39.8495 35.9799C41.8495 34.8099 43.0695 32.6699 43.0695 30.3499C43.0695 23.6899 43.0695 20.0399 43.0695 20.0399H43.0595Z" fill="#77374B" />
                        <path d="M30.5992 49L38.5092 43.44L35.3192 39.57V30.76H25.8892V39.57L22.6992 43.44L30.6092 49H30.5992Z" fill="#FFDDCE" />
                        <path d="M46.0192 41.63L37.2892 39.95C35.9092 42.2 33.4292 43.6899 30.5992 43.6899C27.7692 43.6899 25.2892 42.19 23.9092 39.95L15.1792 41.63C11.8492 42.27 9.44922 45.1799 9.44922 48.57V51.29C14.1292 55.94 20.3292 59.0499 27.2392 59.82C27.7592 59.8799 28.2792 59.85 28.7892 59.7299L31.0092 59.24C31.5392 59.12 32.0792 59.13 32.6092 59.27C33.3292 59.46 34.0992 59.4 34.7892 59.1199C41.5892 56.2699 47.1592 51.0699 50.4792 44.5299C49.4592 43.0599 47.8792 41.99 46.0192 41.63Z" fill="#4A80AA" />
                        <path d="M51.7491 48.56C51.7491 46.65 50.9791 44.89 49.7191 43.6C45.3091 52.25 36.8291 58.48 26.7891 59.75C28.0091 59.9 29.2391 59.99 30.4991 59.99C30.4991 59.99 30.6391 59.99 30.6891 59.99C38.8991 59.96 46.3391 56.64 51.7391 51.27V48.55L51.7491 48.56Z" fill="#407093" />
                        <path d="M31.21 59.1899C32.33 55.9499 34.65 48.64 35.2 42.2C33.91 43.14 32.32 43.6899 30.6 43.6899C28.88 43.6899 27.3 43.14 26 42.2C26.57 48.81 29 56.3499 30.08 59.4399L31.21 59.1899Z" fill="#F4FBFF" />
                        <path d="M29.9883 59.1799C30.0983 59.4899 30.1883 59.7699 30.2783 59.9999C30.8983 59.9999 30.6783 59.9999 30.9283 59.9999C31.0383 59.6799 31.1783 59.2899 31.3383 58.8199C30.8883 58.9499 30.4483 59.0699 29.9883 59.1799Z" fill="#DD636E" />
                        <path d="M38.959 19.2C35.629 15.61 35.009 12.35 35.009 12.35C33.359 16.69 29.149 19.77 24.229 19.77C24.079 19.77 23.929 19.77 23.779 19.77C22.649 19.73 21.709 20.63 21.709 21.77V25.41C21.779 30.26 25.729 34.17 30.599 34.17C35.469 34.17 39.419 30.26 39.489 25.41V20.56C39.489 20.06 39.299 19.57 38.959 19.2Z" fill="#FFDDCE" />
                        <path d="M38.9587 19.2C36.9587 17.05 35.9387 15.01 35.4387 13.73V25.01C35.3687 29.46 32.0287 33.12 27.7188 33.69C28.6187 34 29.5887 34.17 30.5987 34.17C35.4687 34.17 39.4187 30.26 39.4887 25.41V20.56C39.4887 20.06 39.2987 19.57 38.9587 19.2Z" fill="#FFDDCE" />
                        <path d="M20.7394 40.25C20.3594 40.48 20.2794 41 20.5794 41.33L22.4294 43.38C22.6894 43.67 22.6694 44.13 22.3694 44.39L21.1494 45.47C20.8894 45.7 20.7694 46.04 20.8294 46.38C21.5094 50.19 27.5994 57.45 29.7994 59.98C29.9594 59.98 30.1094 59.98 30.2694 59.98C29.1994 57 25.8794 47.08 25.8794 39.55V37.08L20.7394 40.23V40.25Z" fill="#365E7D" />
                        <path d="M40.4597 40.25L35.3197 37.1V39.57C35.3197 47.1 31.9897 57.02 30.9297 60C31.0897 60 31.2397 60 31.3997 60C33.5997 57.47 39.6897 50.2 40.3697 46.4C40.4297 46.06 40.2997 45.72 40.0497 45.49L38.8297 44.41C38.5297 44.15 38.5097 43.69 38.7697 43.4L40.6197 41.35C40.9197 41.02 40.8397 40.5 40.4597 40.27V40.25Z" fill="#365E7D" />
                    </>
                )}
                {props.title === "avtar-3" && (
                    <>
                        <path d="M30.1992 60C46.7678 60 60.1992 46.5685 60.1992 30C60.1992 13.4315 46.7678 0 30.1992 0C13.6307 0 0.199219 13.4315 0.199219 30C0.199219 46.5685 13.6307 60 30.1992 60Z" fill="#90DFAA" />
                        <path d="M30.339 42.9C30.339 42.9 30.249 42.95 30.149 42.95C30.099 42.95 30.059 42.9 29.959 42.9H30.339Z" fill="#E6E9EE" />
                        <path d="M54.339 47.7201C48.909 55.1401 40.069 60 30.149 60C20.229 60 11.439 55.1801 5.95898 47.7201C10.209 43.1801 15.979 40.78 20.789 39.55C20.359 43 22.159 47.6301 22.209 47.4901C24.009 43.3301 30.099 42.9501 30.099 42.9501C30.099 42.9501 36.189 43.2801 38.039 47.4901C38.089 47.5801 39.879 43 39.459 39.55C44.329 40.78 50.089 43.1901 54.339 47.7201Z" fill="#E6E9EE" />
                        <path d="M37.6197 36.38C37.6197 36.99 37.2397 40.25 30.3397 42.9H29.9597C23.0597 40.25 22.6797 36.99 22.6797 36.38C22.7297 35.15 23.0097 33.92 23.5297 32.79C25.4197 34.92 27.6897 36.33 30.1397 36.33C32.5897 36.33 34.8597 34.96 36.7497 32.79C37.2697 33.92 37.5997 35.15 37.5997 36.38H37.6197Z" fill="#997A72" />
                        <path d="M34.2591 43.9799L32.7491 46.9999H27.5491L26.0391 43.9799C28.1191 43.0799 30.1491 42.9399 30.1491 42.9399C30.1491 42.9399 32.2291 43.0799 34.2591 43.9799Z" fill="#F1543F" />
                        <path d="M25.9492 59.7201C27.3192 59.9101 28.7392 60.0001 30.1992 60.0001C31.6592 60.0001 33.0292 59.9101 34.4492 59.7201L32.7992 47.0601H27.5992L25.9492 59.7201Z" fill="#FF7058" />
                        <path d="M37.6202 36.28C37.6202 36.28 37.9502 40.06 30.1602 42.94C30.1602 42.94 36.2502 43.27 38.1002 47.48C38.1502 47.67 41.6902 38.5 37.6302 36.28H37.6202Z" fill="white" />
                        <path d="M22.6896 36.28C18.6696 38.5 22.1696 47.62 22.2596 47.48C24.0596 43.32 30.1996 42.94 30.1996 42.94C22.3596 40.06 22.6896 36.28 22.6896 36.28Z" fill="white" />
                        <path d="M37.1402 33.73C35.1602 35.86 32.7902 37.23 30.1502 37.23C27.5102 37.23 25.1402 35.86 23.1602 33.73C23.2502 33.4 23.4002 33.07 23.5402 32.79C25.4302 34.92 27.7002 36.33 30.1502 36.33C32.6002 36.33 34.8702 34.96 36.7602 32.79C36.9002 33.07 37.0402 33.4 37.1402 33.73Z" fill="#543F3B" />
                        <path d="M43.0498 26.5C42.0598 28.15 40.5498 29.15 39.4098 28.96C37.1398 33.16 33.8398 36.28 30.1498 36.28C26.4598 36.28 23.1598 33.11 20.8898 28.96C19.7598 29.1 18.2398 28.16 17.2498 26.5C16.0698 24.56 16.0198 22.44 17.1598 21.73C17.4398 21.59 17.7298 21.49 18.0598 21.54C18.8198 23.48 19.8598 24.66 19.8598 24.66C18.7698 18.66 23.2098 18 22.4598 12.23C22.4598 12.23 25.2498 18.42 33.2298 14.07L32.6198 15.25C43.7698 12.75 40.0398 25.88 39.9398 26.21C41.2198 24.6 42.0198 23 42.4898 21.53C42.6798 21.53 42.9198 21.62 43.0598 21.72C44.2898 22.38 44.2398 24.55 43.0598 26.49L43.0498 26.5Z" fill="#997A72" />
                        <path d="M36.5801 5.99998C37.4801 5.90998 38.9401 6.70998 38.9401 6.70998C34.9201 2.12998 28.3601 5.05998 28.3601 5.05998C30.5801 3.16998 32.5601 3.35998 32.5601 3.35998C23.1601 1.93998 21.5501 7.55998 21.5501 7.55998C21.1201 6.89998 21.1201 5.94998 21.1701 5.47998C20.5101 6.60998 21.2201 8.87998 21.2201 8.87998C20.5601 7.22998 18.2401 7.50998 18.2401 7.50998C20.2201 7.83998 20.5501 9.06998 20.5501 9.06998C15.5901 10.91 16.4401 16.06 16.4401 16.06L17.6201 14.64C16.1601 20.26 19.8901 24.61 19.8901 24.61C18.8001 18.61 23.2401 17.95 22.4901 12.18C22.4901 12.18 25.2801 18.37 33.2601 14.02L32.6501 15.2C43.9401 12.65 39.9701 26.21 39.9701 26.21C43.9901 21.11 43.3701 15.86 43.3701 15.86L43.8401 17.75C45.1201 9.24998 36.5601 5.98998 36.5601 5.98998L36.5801 5.99998Z" fill="#324A5E" />
                        <path d="M25.9182 24.15C26.6141 24.15 27.1782 23.5859 27.1782 22.89C27.1782 22.1941 26.6141 21.63 25.9182 21.63C25.2223 21.63 24.6582 22.1941 24.6582 22.89C24.6582 23.5859 25.2223 24.15 25.9182 24.15Z" fill="#3D2F46" />
                        <path d="M33.9983 24.15C34.6942 24.15 35.2583 23.5859 35.2583 22.89C35.2583 22.1941 34.6942 21.63 33.9983 21.63C33.3024 21.63 32.7383 22.1941 32.7383 22.89C32.7383 23.5859 33.3024 24.15 33.9983 24.15Z" fill="#3D2F46" />
                    </>
                )}
                {props.title === "avtar-4" && (
                    <>
                        <path d="M30.8008 0C14.2308 0 0.800781 13.43 0.800781 30C0.800781 46.57 14.2308 60 30.8008 60C47.3708 60 60.8008 46.57 60.8008 30C60.8008 13.43 47.3708 0 30.8008 0Z" fill="#90DFAA" />
                        <path d="M8.66992 50.26C14.1499 56.25 22.0399 60 30.7999 60C39.5599 60 47.4399 56.24 52.9299 50.26V47.07C52.9299 45.32 51.5999 43.42 49.9699 42.81C44.3399 40.71 31.5699 36.25 30.7999 35.49C30.0599 36.23 17.2599 40.7 11.6399 42.81C10.0099 43.42 8.67992 45.33 8.67992 47.08V50.26H8.66992Z" fill="#E6E9EE" />
                        <path d="M49.9616 42.81C44.3416 40.71 31.5716 36.25 30.8016 35.49C30.0616 36.23 17.2616 40.7 11.6416 42.81C10.0116 43.42 8.68164 45.33 8.68164 47.08V50.25C8.68164 48.5 10.0016 46.6 11.6416 45.98C17.2716 43.87 30.0516 39.4 30.8016 38.66C31.5716 39.43 44.3416 43.87 49.9616 45.98C51.5916 46.59 52.9216 48.5 52.9216 50.24V47.07C52.9316 45.33 51.6016 43.42 49.9616 42.81Z" fill="#DCDEE2" />
                        <path d="M30.8013 44.17L25.4113 37.47C23.7913 38.13 23.9813 38.89 22.0312 39.64L29.7113 59.97C30.0712 59.99 30.4313 60 30.8113 60C31.1913 60 31.5413 59.99 31.9113 59.97L39.5913 39.65C37.6313 38.89 37.8213 38.14 36.2013 37.47L30.8213 44.17H30.8013Z" fill="white" />
                        <path d="M34.8704 42.14L33.3604 45.16H28.1604L26.6504 42.14C28.7304 41.24 30.7604 41.1 30.7604 41.1C30.7604 41.1 32.8404 41.24 34.8704 42.14Z" fill="#F1543F" />
                        <path d="M28.3509 59.9C29.7509 59.98 29.3509 60 30.8109 60C32.2709 60 31.5909 59.95 33.0609 59.91L33.4109 45.21H28.2109L28.3509 59.9Z" fill="#FF7058" />
                        <path d="M22.1902 38.65L19.0202 39.96L18.4902 43.91L21.1302 45.22L19.0202 47.33C19.0202 47.33 24.9002 55.26 28.3402 59.89C28.7802 59.92 29.2202 59.95 29.6702 59.97L22.1802 38.64L22.1902 38.65Z" fill="#DCDEE2" />
                        <path d="M42.3905 39.96L39.2205 38.65L31.7305 59.98C32.1705 59.97 32.6105 59.94 33.0505 59.91C36.4905 55.29 42.3805 47.34 42.3805 47.34L40.2705 45.23L42.9105 43.92L42.3805 39.97L42.3905 39.96Z" fill="#DCDEE2" />
                        <path d="M36.0713 41.02L30.8013 44.18L25.5312 41.02V32.59H36.0612V41.02H36.0713Z" fill="#997A72" />
                        <path d="M45.5508 47.34L35.0108 42.07L30.8008 44.17L36.0708 35.74L39.2408 37.85L45.5608 47.34H45.5508Z" fill="white" />
                        <path d="M16.051 47.34L26.591 42.07L30.801 44.17L25.531 35.74L22.361 37.85L16.041 47.34H16.051Z" fill="white" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M36.0617 35.16C36.0417 34.67 36.1117 34.11 36.0817 33.55C36.0817 33.55 32.9217 35.81 30.6417 35.84C28.3617 35.86 25.5517 33.73 25.5517 33.73C25.5217 34.3 25.5417 34.92 25.5417 35.42C25.6017 35.47 25.5417 35.4201 25.6017 35.4701C25.7817 35.6301 28.4417 37.9201 30.6517 37.9001C32.8517 37.9201 35.5217 35.63 35.7017 35.48C35.8317 35.37 35.9617 35.27 36.0817 35.17L36.0617 35.16Z" fill="#543F3B" />
                        <path d="M30.8005 36.8C26.5005 36.8 21.3105 30.45 21.3105 25.21V17.83C21.3105 12.6 25.5505 8.33997 30.8005 8.33997C36.0505 8.33997 40.2905 12.58 40.2905 17.83V25.21C40.2905 30.44 35.1105 36.8 30.8005 36.8Z" fill="#997A72" />
                    </>
                )}
                {props.title === "avtar-5" && (
                    <>
                        <path d="M30.4004 60C46.9689 60 60.4004 46.5685 60.4004 30C60.4004 13.4315 46.9689 0 30.4004 0C13.8318 0 0.400391 13.4315 0.400391 30C0.400391 46.5685 13.8318 60 30.4004 60Z" fill="#EEF1FF" />
                        <path d="M54.4705 47.8799C44.5305 34.5799 25.7005 31.8499 12.3905 41.7799C10.0805 43.4999 8.03055 45.5499 6.31055 47.8599C16.1605 61.1599 34.9305 63.9599 48.2305 54.1099C50.6105 52.3499 52.7105 50.2499 54.4705 47.8699V47.8799Z" fill="#0024D4" />
                        <path d="M30.401 31.93C36.813 31.93 42.011 26.732 42.011 20.32C42.011 13.9079 36.813 8.70996 30.401 8.70996C23.989 8.70996 18.791 13.9079 18.791 20.32C18.791 26.732 23.989 31.93 30.401 31.93Z" fill="#0024D4" />
                    </>
                )}
                {props.title === "avtar-6" && (
                    <>
                        <path d="M30.4004 60C46.9689 60 60.4004 46.5685 60.4004 30C60.4004 13.4315 46.9689 0 30.4004 0C13.8318 0 0.400391 13.4315 0.400391 30C0.400391 46.5685 13.8318 60 30.4004 60Z" fill="#C2C2C2" />

                    </>
                )}
                {/* <circle cx="30" cy="30" r="29" stroke="#0024D4" stroke-width="2" /> */}
            </g>
            <defs>
                <clipPath id="clip0_5041_68254">
                    <rect width="60" height="60" fill="white" />
                </clipPath>
            </defs>


        </svg >

    )
}

export default Avatar