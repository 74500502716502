import API from "../util/api";
import { headerAddToken, mongoDBAxiosAPI } from "../util/common";

const signup = (values) => {
  return API.post("/auth/signup", values);
};

const forgotpassword = (email) => {
  return API.post("/auth/forgotpassword", { email });
};

const resetpassword = (formValue) => {
  return API.post("/auth/resetpassword", formValue);
};

const signin = (email, password, device_info) => {
  return API.post("/auth/signin", {
    email,
    password,
    device_info
  }).then((response) => {
    if (response.data.res_data) {
      localStorage.setItem("user", JSON.stringify(response.data.res_data));
      return response.data.res_data;
    } else {
      return response.data;
    }
  });
};

const signout = (values) => {
  let header = headerAddToken();
  return API.post("/auth/signout", values, header);
};

const mfaEmailAuth = async (email) => {
  let header = await headerAddToken();
  return API.post("/auth/mfaEmailAuth", { email }, header);
};
const mfaEmailAuthPin = async (email, id, MFAEmailOtp) => {
  let header = await headerAddToken();
  return API.post("/auth/mfaEmailAuthPin", { email, id, MFAEmailOtp }, header);
};

const mfaGoogleAuth = async (email) => {
  let header = await headerAddToken();
  return API.post("/auth/mfaGoogleAuth", { email }, header);
};
const mfaGoogleAuthSetup = async (email) => {
  let header = await headerAddToken();
  return API.post("/auth/mfaGoogleAuthSetup", { email }, header);
};

const mfaGoogleAuthFirstPin = async (email, id, MFAGoogleOtp) => {
  let header = await headerAddToken();
  return API.post(
    "/auth/mfaGoogleAuthFirstPin",
    { email, id, MFAGoogleOtp },
    header
  );
};

const mfaGoogleAuthPin = async (email, id, MFAGoogleOtp) => {
  let header = await headerAddToken();
  return API.post(
    "/auth/mfaGoogleAuthPin",
    { email, id, MFAGoogleOtp },
    header
  );
};
//wealth manager
const wmmfaGoogleAuth = async (email) => {
  let header = await headerAddToken();
  return API.post("/auth/wmmfaGoogleAuth", { email }, header);
};
const wmmfaGoogleAuthSetup = async (email) => {
  let header = await headerAddToken();
  return API.post("/auth/wmmfaGoogleAuthSetup", { email }, header);
};

const wmmfaGoogleAuthPin = async (email, id, MFAGoogleOtp) => {
  let header = await headerAddToken();
  return API.post(
    "/auth/wmmfaGoogleAuthPin",
    { email, id, MFAGoogleOtp },
    header
  );
};

const mfaPhoneAuth = async (values) => {
  let header = await headerAddToken();
  return API.post("/auth/mfaPhoneAuth", values, header);
};

const verify_phone_otp_send = async (values) => {
  let header = await headerAddToken();
  return API.post("/auth/verify_phone_otp_send", values, header);
};

const update_password = async (values) => {
  let header = await headerAddToken();
  return API.post("/auth/update_password", values, header);
};

const mfaPhoneAuthSetup = async (id, email, phoneNo) => {
  let header = await headerAddToken();
  return API.post("/auth/mfaPhoneAuthSetup", { id, email, phoneNo }, header);
};
const mfaPhoneAuthPin = async (email, id, MFAPhoneOtp) => {
  let header = await headerAddToken();
  return API.post("/auth/mfaPhoneAuthPin", { email, id, MFAPhoneOtp }, header);
};

const mfaPhoneAuthFirstPin = async (email, id, MFAPhoneOtp) => {
  let header = await headerAddToken();
  return API.post(
    "/auth/mfaPhoneAuthFirstPin",
    { email, id, MFAPhoneOtp },
    header
  );
};

const sendVerificationEmail = (emailVerificationCode) => {
  return API.get(`/auth/sendemail/${emailVerificationCode}`);
};

const confirmEmailVerification = (emailVerificationCode) => {
  return API.get(`/auth/confirmemail/${emailVerificationCode}`);
};

const confirmemailOtp = async (values) => {
  return API.post(`/auth/confirmemail/otp`, values);
};

const verifyLicenseNumber = async (values) => {
  let mongoDbUrl = await mongoDBAxiosAPI();
  let data = {
    licenseNumber: values.licenseId
      ? values.licenseId
      : values.licenceId
        ? values.licenceId
        : 0,
  };
  return mongoDbUrl.post(`/getWmDataByLicenseNumber`, data);
};

const authService = {
  signup,
  forgotpassword,
  resetpassword,
  signin,
  signout,
  mfaEmailAuth,
  mfaEmailAuthPin,
  mfaPhoneAuth,
  mfaPhoneAuthSetup,
  mfaPhoneAuthPin,
  mfaPhoneAuthFirstPin,
  mfaGoogleAuth,
  mfaGoogleAuthSetup,
  mfaGoogleAuthPin,
  mfaGoogleAuthFirstPin,
  sendVerificationEmail,
  confirmEmailVerification,
  wmmfaGoogleAuth,
  wmmfaGoogleAuthSetup,
  wmmfaGoogleAuthPin,
  confirmemailOtp,
  verifyLicenseNumber,
  verify_phone_otp_send,
  update_password,
};

export default authService;
