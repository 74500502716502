

import React from "react";
import { Modal, Button, Image } from 'react-bootstrap';
import ErrorValidation from "../../assets/images/other/error-validation.svg";

const ConfirmEditModal = props => {

  const { showModal, onUpdateMarketValue } = props;

  return (
    <Modal
      show={showModal}
      hide={!showModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal"
      className="cs-common-modal-overlay"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">Confirm Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body >
        <div className="delete-modal-content">
          <Image
            src={ErrorValidation}
            alt="Error Validation"
            width={100}
            height={100}
          />
          <p className="default-light-body-text-m cs-neutral-90">
            Are you sure want to edit the market value? We recommend using the
            best selling price based on present conditions.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="cs-modal-btn cs-center-btn">
          <Button
            className="cs-btn-secondary md-btn default-regular-h5"
            // onClick={updateAfterPopup}
            onClick={() => onUpdateMarketValue(true)}
          >
            Update
          </Button>
          <Button
            className="cs-btn-primary md-btn default-regular-h5"
            // onClick={updateAfterPopupUsingApi}
            onClick={() => onUpdateMarketValue(false)}
          >
            Keep Standard value
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );

};

export default ConfirmEditModal;