import React from "react";
import { Form, Row, Col, Dropdown } from "react-bootstrap";
import SubMenuHeader from "../SubMenuHeader";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import SubMethodsList from "../../SubMethodsList";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import CapsyncIcon from "../../../../../../commonComponent/CapsyncIcon";
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";

// sequence No. three  (Asmita date-29th June => Do not remove this line)
const MethodIdTwentySeven = (props) => {


  let investorTypeArray = [
    "Individual",
    "Company"
  ];
  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Qualified Client",
      url: `/individual/verifyinvestor/accreditedInvestor/qualifiedclient/${props.unique_code}`,
    },
  ];



  return (
    <>
      {props.is_loader.assets === true || props.is_loader.liabilities === true ? <SkeletonAllSubMethods /> :
        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="QP"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>
              <SubMenuHeader />
            </div>
            <div className="method-section method-twenty-seven">
              <div className="default-regular-body-text-l cs-neutral-70">
                <SubMethodsList methodId={29} />
              </div>

              <div className="cs-divider"></div>
              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert}
                />
                <div className="row-spacing">
                  <p className="default-regular-body-text-l cs-neutral-70">
                    In order to verify your accreditation we need you to upload
                    evidence. Please upload the appropriate files below and black-out
                    any sensitive information.
                  </p>
                </div>

                <div className="investor-type-row">
                  <Row>
                    <Col lg={5}>
                      <span className="default-regular-sub-heading-s">
                        Are you investing as an individual or company?
                      </span>
                      <div className={props.selectInvestorError ? "investor-type-dropdown investor-error" : "investor-type-dropdown"}>
                        <Dropdown className="dropdown cs-form-dropdown">
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic-accredited"
                            className="dropdown-toggle default-regular-sub-heading-s"
                          >
                            {props.selectInverstorType}
                            <span className="input-field-icon cs-neutral-90">
                              <CapsyncIcon title="chevron-down-outlined" size="16" />
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {investorTypeArray.map((item) => (
                              <Dropdown.Item
                                className={
                                  item === props.selectInverstorType
                                    ? "dropdown-item dropdown-item-active default-regular-sub-heading-s "
                                    : "default-regular-sub-heading-s cs-content-title "
                                }
                                key={item}
                                onClick={() => props.handleInvestorType(item)}
                              >
                                {item}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                          {props.selectInvestorError && <span className="default-regular-sub-heading-xs cs-danger">Select an option whether you are investing as an individual or a company</span>}

                        </Dropdown>
                      </div>
                    </Col>
                  </Row>
                </div>
                {props.selectInverstorType === "Company" &&
                  <>
                    <Form.Check
                      type="checkbox"
                      id="credit-checkbox"
                      aria-label="option 2"
                      name="terms"
                      className={props.isCheckedError ? "cs-form-check-box investor-error" : "cs-form-check-box"}
                      checked={props.isChecked}
                      onChange={props.handleCheckbox}
                      label={
                        <span className="default-regular-sub-heading-s cs-neutral-90">
                          Investor is not an investment company and is not an entity that would be an investment company but for the exemption offered under Section 3(c)(1) of the Investment Company Act.
                        </span>
                      }
                    />
                    {props.isCheckedError && <span className="default-regular-sub-heading-xs cs-danger"> Check the box to make the representation that the investor meets the requirements indicated.</span>}

                  </>
                }
                <Form.Label>
                  <span className="default-regular-sub-heading-s cs-neutral-90">
                    Upload Qualified Purchaser Certificate: {" "}
                  </span>
                  <span className="default-regular-sub-heading-s cs-neutral-70">
                    If you don’t have this, you may ask your issuer to issue new qualified purchaser verification request(s) for you.
                  </span>
                </Form.Label>
                <UploadFileBlock
                  files={props.files}
                  setOpenModal={props.setOpenModal}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile}
                  showFile={props.showFile}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink}
                  uploadError={props.uploadError}
                  showError={props.showError}

                />
                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Upload other evidence (optional):
                </Form.Label>
                <UploadFileBlock
                  files={props.files2}
                  setOpenModal={props.setOpenModal2}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile2}
                  showFile={props.showFile2}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink2}
                />
                <NotesBlock setNotesValue={props.setNotesValue} />
              </Form>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default MethodIdTwentySeven;
