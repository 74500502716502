import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import NumberFormat from "react-currency-format";

import commonNames from "../../../config/commonNames";
import UseFetchPermission from "../../../customHooks/UseFetchPermission";
import {
  contingent_lia_addUpdate,
  contingent_liabilities_getbalance,
} from "../../../slices/contingent_Liabilities";

const ContingentLiabilities = (props) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const pfsAccessId = window.localStorage.getItem("pfs_access_id");
  const id = pfsAccessId !== null ? pfsAccessId : user && user.user.id;
  const { fetchUserPermission } = UseFetchPermission();
  const [getContigentValues, setGetContigentValues] = useState({});

  const [isNumber, setIsNumber] = useState({
    coMaker: false,
    judgments: false,
  });

  const [contigentValues, setContigentValues] = useState({
    coMakerValue: "",
    judgmentsValue: "",
  });

  const checkNumber = (type) => {
    if (type === "coMaker") {
      setIsNumber({ coMaker: true, judgments: false });
    } else {
      setIsNumber({ coMaker: false, judgments: true });
    }
  };

  const getAllContingentLia = () => {
    dispatch(contingent_liabilities_getbalance({ id }))
      .unwrap()
      .then((response) => {
        let newContent = { ...contigentValues };
        if (response[0] === undefined) {
          setGetContigentValues(response);
          setContigentValues(newContent);
        } else {
          newContent.judgmentsValue = response[0].judgments_balance;
          newContent.coMakerValue = response[0].co_maker_balance;
          setGetContigentValues(response[0]);
          setContigentValues(newContent);
        }
      });
  };

  const changeContingentValues = (event, type) => {
    let newContent = { ...contigentValues };
    if (type === "judgements") {
      newContent.judgmentsValue = event;
      setContigentValues(newContent);
    } else {
      newContent.coMakerValue = event;
      setContigentValues(newContent);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      updateContingentData();
    }
  };

  const handleBlur = (e) => {
    updateContingentData();
  };

  const updateContingentData = (event) => {
    const storeData = {
      user_id: id,
      co_maker_balance:
        contigentValues.coMakerValue === 0
          ? getContigentValues.co_maker_balance.toString()
          : contigentValues.coMakerValue.toString(),
      judgments_balance:
        contigentValues.judgmentsValue === 0
          ? getContigentValues.judgments_balance.toString()
          : contigentValues.judgmentsValue.toString(),
    };
    dispatch(contingent_lia_addUpdate({ ...storeData }))
      .unwrap()
      .then(() => {
        setIsNumber({ coMaker: false, judgments: false });
        getAllContingentLia();
      });
  };

  useEffect(() => {
    getAllContingentLia();
  }, []);
  return (
    <div className="contingent-finance-data">
      <h3 className="heading default-semi-bold-h3 cs-neutral-100">
        Contingent {commonNames.Liabilities}{" "}
        <span className="default-light-body-text-s">
          Values not inclusive in net worth
        </span>
      </h3>
      <Table>
        <thead className="contingent-finance-thead">
          <tr>
            <th className="default-medium-sub-heading-l">Category</th>
            <th className="default-medium-sub-heading-l">Balance</th>
          </tr>
        </thead>
        <tbody className="contingent-tbody default-regular-sub-heading-m">
          <tr>
            <td>{commonNames.AsEndorser}</td>

            <td>
              <div className="dollar-value-gap cursor-pointer">
                <span>$&nbsp;</span>
                {((fetchUserPermission.to_access_permission === 1 &&
                  pfsAccessId !== null) || props.isSharePFS) ? (
                  <NumberFormat
                    className="dash-bal-padding text-end"
                    value={Math.round(
                      getContigentValues && getContigentValues.co_maker_balance
                    ).toFixed(0)}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                ) : (
                  <div onClick={() => checkNumber("coMaker")}>
                    {isNumber.coMaker === true ? (
                      <NumberFormat
                        className="text-end dash-input-contingency"
                        placeholder=""
                        thousandSeparator={true}
                        onChange={(e) =>
                          changeContingentValues(e.target.value, "maker")
                        }
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        value={contigentValues.coMakerValue}
                        maxLength={15}
                      />
                    ) : (
                      <NumberFormat
                        value={Math.round(
                          getContigentValues &&
                          getContigentValues.co_maker_balance
                        ).toFixed(0)}
                        displayType={"text"}
                        className="text-end dash-input-contingency"
                        thousandSeparator={true}
                      />
                    )}
                  </div>
                )}
              </div>
            </td>
            <td className="go-next-page-div"></td>

          </tr>
          <tr className="legal-contingent-row">
            <td>{commonNames.LegalClaims}</td>
            <td>
              <div className="dollar-value-gap cursor-pointer">
                <span>$&nbsp;</span>
                {((fetchUserPermission.to_access_permission === 1 &&
                  pfsAccessId !== null) || props.isSharePFS) ? (
                  <NumberFormat
                    className="dash-bal-padding text-end"
                    value={Math.round(
                      getContigentValues && getContigentValues.judgments_balance
                    ).toFixed(0)}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                ) : (
                  <div onClick={() => checkNumber("judgments")}>
                    {isNumber.judgments === true ? (
                      <NumberFormat
                        placeholder=""
                        className="text-end dash-input-contingency "
                        thousandSeparator={true}
                        name="judgments_balance"
                        onChange={(e) =>
                          changeContingentValues(e.target.value, "judgements")
                        }
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        value={contigentValues.judgmentsValue}
                        maxLength={15}
                      />
                    ) : (
                      <NumberFormat
                        value={Math.round(
                          getContigentValues &&
                          getContigentValues.judgments_balance
                        ).toFixed(0)}
                        displayType={"text"}
                        className="text-end dash-input-contingency"
                        thousandSeparator={true}
                      />
                    )}
                  </div>
                )}
              </div>
            </td>
            <td className="go-next-page-div"></td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default ContingentLiabilities;
