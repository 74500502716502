import React, { useEffect } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import { emailSchema } from "../../util/common";
import { uniValidation } from "../../pages/individual/liabilities/categoryForms/validationSchema";

const JointSpouse = ({
  props,
  handleCheckboxChange,
  nameValidation,
  Validation,
  setValidation,
  isChecked,
  setNameValidation,
  submitted,
  setSubmitted
}) => {

  const handleEmailValidation = async (email) => {
    let emailError = "";
    try {
      await emailSchema.validate(email);
      emailError = "";
    } catch (error) {
      emailError = error.message || uniValidation.email.validEmail;
    }
    setValidation(emailError);
    props.handleSpouseEmailChange(email);
  }

  useEffect(() => {
    return () => {
      setNameValidation("")
      setValidation("")
      setSubmitted(false)
    }
  }, [])

  return (
    <>
      <Row>
        <Col lg={12}>
          <Form.Group className="cs-form-group">
            <Form.Label>Enter name of cosigner </Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              onKeyDown={(e) => {
                if (e.key === " " && e.target.selectionStart === 0) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => props.HandleSpouseNameChange(e.target.value)}
              className={`${props.checkJointName && 'cs-input-field-active'}`}
              maxLength={50}
            // className={
            //   target.value && "cs-input-field-active"
            // }
            />
            {/* <span>
            <Image src={props.userIcon} className="pfs-share-user-icon" />
          </span> */}
            {<span className="form-error-msg default-light-body-text-s cs-danger">
              {nameValidation}
            </span>}
          </Form.Group>
        </Col>
        <Col lg={12}>
          <Form.Group className="cs-form-group">
            <Form.Label>Enter email of cosigner</Form.Label>
            <Form.Control
              type="email"
              autoComplete="off"
              onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
              onChange={(e) => handleEmailValidation(e.target.value)}
              className={props.checkJointEmail && 'cs-input-field-active'}
            />
            {/* <span>
          <Image
            src={props.emailIcon}
            className="pfs-share-email-icon-3-modal"
          />
        </span> */}
            {<span className="form-error-msg default-light-body-text-s cs-danger">
              {Validation}
            </span>}
          </Form.Group>
        </Col>

        {/* <Form.Group className="cs-form-group">
            <Form.Check
              type="checkbox"
              className="cs-form-check-box"
              checked={isChecked}
              onChange={handleCheckboxChange}
              label={
                <span className="">
                  Return to Me
                </span>
              }
            />
          </Form.Group> */}
        {/* <span className="indi-sharepfs-checkbox">
            <label>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <p>Return to Me</p>
            </label>
          </span> */}
      </Row>
    </>
  );
};

export default JointSpouse;
