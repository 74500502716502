import axios from "axios";
import { backAppURl } from "../applicationMode";

const plaidObj = {
  assets: {
    new: [
      "new_asset_cash_on_hands",
      "new_asset_savings_account",
      "new_asset_retirement_accounts",
      "new_asset_stocks_bonds",
    ],
    duplicate: [
      "duplicate_asset_cash_on_hands",
      "duplicate_asset_savings_account",
      "duplicate_asset_retirement_accounts",
      "duplicate_asset_stocks_bonds",
    ],
  },
  liabilities: {
    new: [
      "new_installmentAuto",
      "new_accoutPayable",
      "new_otherLiability",
      "new_realEstateMortgage",
      "new_notesPayable",
    ],
    duplicate: [
      "duplicate_installmentAuto",
      "duplicate_accoutPayable",
      "duplicate_otherLiability",
      "duplicate_realEstateMortgage",
      "duplicate_notesPayable",
    ],
  },
};

async function checkUserAccounts(user_id, metadata) {
  try {
    let fetchData = await axios.post(
      `${backAppURl}/api/plaid/check_user_accounts`,
      {
        user_id: user_id,
        metadata: metadata,
      }
    );
    console.log("checkUserAccounts:- ", fetchData.data);
    return fetchData.data;
  } catch (err) {
    console.log(err);
  }
}

function CategoryAccountsLength(newAcc, duplicateAcc, type) {
  let assetSum = 0;
  plaidObj[type].new.forEach((itr) => {
    assetSum += newAcc[itr].length;
  });
  plaidObj[type].duplicate.forEach((itr) => {
    assetSum += duplicateAcc[itr].length;
  });
  return assetSum;
}

function fetchInstitution(params, user_id, setInstitution) {
  axios
    .get(`${backAppURl}/api/plaid/fetch_ins/${params}/${user_id}`)
    .then((response) => {
      console.log("institution data", response.data);
      setInstitution(response.data);
    })
    .catch((err) => {
      console.log(err);
    });
}

function syncPlaidAccounts(user_id) {
  axios
    .get(`${backAppURl}/api/plaid/syncPlaidDataByUserId/${user_id}`)
    .then((response) => {
      console.log("Plaid Account sync data called");
    })
    .catch((err) => {
      console.log(err);
    });
}

async function getLastSyncDate(user_id) {
  try {
    const res = await axios.get(`${backAppURl}/api/plaid/getLastSyncDate/${user_id}`);
    return res;
  } catch (error) {
    console.log("error",error);
  }
}

function investmentHoldingTransactions(user_id, ins_id) {
  axios
    .get(`${backAppURl}/api/plaid/holdings_transactions/${user_id}/${ins_id}`)
    .then((response) => {
      console.log("investmentHoldingTransactions:- ", response.data);
    })
    .catch((err) => {
      console.log("err:- ", err);
    });
}

function transactionGet(user_id, ins_id) {
  axios
    .get(`${backAppURl}/api/plaid/transactions/get/${user_id}/${ins_id}`)
    .then((response) => {
      console.log("transactionGet:- ", response.data);
    })
    .catch((err) => {
      console.log(err);
    });
}

async function itemRemoveException(user_id, ins_id) {
  try {
    let fetchData = await axios.post(
      `${backAppURl}/api/plaid/item/remove/exception`,
      {
        user_id: user_id,
        ins_id: ins_id,
      }
    );
    return fetchData;
  } catch (err) {
    console.log("err:- ", err);
  }
}

function accountsGet(
  metadata,
  id,
  storeDuplicateAccount,
  storeNewAccount,
  plaidErrorDisplay,
  setProgessPlaid,
  setTotalAccounts,
  handlePlaidError,
  investmentHolding,
  updateFlag,
  updateIsInvestment
) {
  axios
    .post(`${backAppURl}/api/plaid/accounts/get`, {
      id: id,
      metadata: metadata,
    })
    .then((response) => {
      if (Object.keys(response.data).includes("error_code") === true) {
        plaidErrorDisplay(true, response.data.error_message);
      } else {
        setProgessPlaid((prev) => prev + 1);
        console.log("accountsGet:- ", response.data);
        setTotalAccounts(response.data.accounts.length);

        storeDuplicateAccount(
          "duplicate_installmentAuto",
          response.data.liabilities_plaid_data.duplicate_installmentAuto
        );
        storeDuplicateAccount(
          "duplicate_accoutPayable",
          response.data.liabilities_plaid_data.duplicate_accoutPayable
        );
        storeDuplicateAccount(
          "duplicate_otherLiability",
          response.data.liabilities_plaid_data.duplicate_otherLiability
        );
        storeDuplicateAccount(
          "duplicate_realEstateMortgage",
          response.data.liabilities_plaid_data.duplicate_realEstateMortgage
        );
        storeDuplicateAccount(
          "duplicate_notesPayable",
          response.data.liabilities_plaid_data.duplicate_notesPayable
        );

        storeNewAccount(
          "new_installmentAuto",
          response.data.liabilities_plaid_data.installmentAuto
        );
        storeNewAccount(
          "new_accoutPayable",
          response.data.liabilities_plaid_data.accoutPayable
        );
        storeNewAccount(
          "new_otherLiability",
          response.data.liabilities_plaid_data.otherLiability
        );
        storeNewAccount(
          "new_realEstateMortgage",
          response.data.liabilities_plaid_data.realEstateMortgage
        );
        storeNewAccount(
          "new_notesPayable",
          response.data.liabilities_plaid_data.notesPayable
        );

        storeDuplicateAccount(
          "duplicate_asset_cash_on_hands",
          response.data.assets_plaid_data.duplicate_asset_cash_on_hands
        );
        storeDuplicateAccount(
          "duplicate_asset_savings_account",
          response.data.assets_plaid_data.duplicate_asset_savings_account
        );

        storeNewAccount(
          "new_asset_cash_on_hands",
          response.data.assets_plaid_data.asset_cash_on_hands
        );
        storeNewAccount(
          "new_asset_savings_account",
          response.data.assets_plaid_data.asset_savings_account
        );

        storeDuplicateAccount(
          "duplicate_asset_retirement_accounts",
          response.data.investments_plaid_data
            .duplicate_asset_retirement_accounts
        );
        storeDuplicateAccount(
          "duplicate_asset_stocks_bonds",
          response.data.investments_plaid_data.duplicate_asset_stocks_bonds
        );

        storeNewAccount(
          "new_asset_retirement_accounts",
          response.data.investments_plaid_data.asset_retirement_accounts
        );
        storeNewAccount(
          "new_asset_stocks_bonds",
          response.data.investments_plaid_data.asset_stocks_bonds
        );

        if (
          response.data.institution_products.includes("investments") === true &&
          handlePlaidError.status === false &&
          response.data.investment_counter > 0
        ) {
          investmentHolding();
        }

        if (updateFlag) {
          transactionGet(id, metadata.institution.institution_id);
          if (updateIsInvestment) {
            investmentHoldingTransactions(
              id,
              metadata.institution.institution_id
            );
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

const create_link_token = (
  id,
  plaidErrorDisplay,
  setFetchlinktokenIndicator,
  changeLinkToken,
  setPublicTokenLink,
  secureLocalStorage
) => {
  axios
    .post(`${backAppURl}/api/plaid/create_link_token`, {
      user_id: id,
    })
    .then((data) => {
      if (Object.keys(data.data).includes("error_code") === true) {
        plaidErrorDisplay(true, data.data.error_message);
      } else {
        setFetchlinktokenIndicator(true);
        changeLinkToken(true);
        setPublicTokenLink(data.data.link_token);
        secureLocalStorage.removeItem("transactions");
        secureLocalStorage.removeItem("assets");
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export {
  checkUserAccounts,
  investmentHoldingTransactions,
  transactionGet,
  fetchInstitution,
  CategoryAccountsLength,
  plaidObj,
  itemRemoveException,
  accountsGet,
  create_link_token,
  syncPlaidAccounts,
  getLastSyncDate
};
