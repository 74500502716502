/* eslint-disable */
import React, { useState } from "react";
import { NavDropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CapsyncIcon from "./CapsyncIcon";
import { requestsNotifications_read, read_single_notification } from "../slices/requests";
import { Link } from "react-router-dom";
import { roleName } from "../config/commonRoleName";

const checkDate = (date) => {
  return (
    <>
      <span className="date">{date.split(" ")[0]}</span>
      <span className="time">{date.split(" ")[1]} PM</span>
    </>
  );
};
const Notifications = ({ props, notification, forIndividual }) => {

  const { user } = useSelector((state) => state.auth);
  const userAddress = user.user && user.user.address;
  const profileImg = user.user && user.user.profileImageUrl;
  const [isNotificationHovered, setIsNotificationHovered] = useState(-1);
  const dispatch = useDispatch();
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }
  const user_role = user && user.user_role.role
  const checkNotifications = () => {
    dispatch(
      requestsNotifications_read({
        user_id: id,
        module_name: user_role == 1 ? roleName.individual : user_role == 2 ? roleName.financial_advisor : roleName.tax_professional,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          props.getNotificationRequests();
          setTimeout(() => {
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const markSingleNotification = (id) => {
    dispatch(
      read_single_notification({
        notification_id: id
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          props.getNotificationRequests();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  if (["/individual/account-details"].includes(location.pathname)) {
    props.dashactive.current = false;
    props.assetactive.current = false;
    props.liabiliactive.current = false;
    props.verifyinvestoractive.current = false;
    props.collaborationactive.current = false;
    props.requestactive.current = false;
  }

  const sortedNotifications = [...props.storeAllNotifications].sort((a, b) =>
    new Date(b.createdAt) - new Date(a.createdAt)
  );

  return (
    <>
      <NavDropdown
        title={
          <>
            <span className="nav-notification" id="notification-icon">
              <CapsyncIcon title="notification-outlined" size="22" />

              {notification > 0 ? (
                <span className="nav-notification-btn blue-notification-badge" />
              ) : (
                (!userAddress || !profileImg) && (
                  <span className="nav-notification-btn red-notification-badge" />
                )
              )}
            </span>
          </>
        }
        className="cs-nav-dropdown nav-icons">
        <div className="cs-notification">
          {props.storeAllNotifications.length > 0 ? (
            <>
              <div className="title">
                <div className="notification">
                  <div className="notification-img">
                    <CapsyncIcon title="notification-outlined" size="22" />
                  </div>
                  <span className="default-regular-sub-heading-m cs-neutral-100">
                    Notifications
                  </span>
                </div>
                <div className="mark-as cursor-pointer">
                  <span
                    className="default-light-body-text-m"
                    onClick={checkNotifications}
                    id="notify-markasread">
                    Mark all as read
                  </span>
                </div>
              </div>
              <div className="cs-scroller">
                {sortedNotifications.map((notificationsItr, i) => {
                  return (
                    <Link
                      to={
                        forIndividual
                          ? "/individual/request"
                          : user_role === 2
                          ? "/financial-advisor/requests"
                          : "/professional/requests"
                      }
                      key={i}>
                      <div
                        className="notification-item"
                        key={i}
                        onMouseEnter={() => setIsNotificationHovered(i)}
                        onMouseLeave={() => setIsNotificationHovered(-1)}>
                        <div className="notification-message">
                          {/* <span className="position-absolute blue-notification-badge" /> */}
                          <span
                            className={
                              notificationsItr.message.length >= 1 &&
                              "position-absolute blue-notification-badge"
                            }
                          />
                          <span className="default-regular-body-text-m cs-neutral-90">
                            {notificationsItr && notificationsItr.message}
                          </span>
                        </div>
                        <div
                          className="notification-date-time default-light-body-text-s"
                          onClick={(e) => {
                            e.preventDefault();
                            markSingleNotification(notificationsItr.id);
                            e.stopPropagation();
                          }}>
                          {isNotificationHovered === i ? (
                            <CapsyncIcon title="close-outlined" size="18" />
                          ) : (
                            <>
                              {checkDate(
                                moment(notificationsItr.createdAt).format(
                                  "MM/DD/YYYY h:mm"
                                )
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div className="title">
                <div className="notification">
                  <span className="cs-neutral-100">
                    <CapsyncIcon title="notification-outlined" size="22" />
                  </span>
                  <span className="default-regular-sub-heading-m cs-neutral-100">
                    Notifications
                  </span>
                </div>
                <div className="mark-as cursor-pointer">
                  <span className="default-regular-h6"></span>
                </div>
              </div>
              <div className="notification-item">
                <p className="notification-message default-regular-sub-heading-s">
                  <span className="notification-message default-regular-body-text-m cs-neutral-60 p-0">
                    No pending notifications
                  </span>
                </p>
              </div>
            </>
          )}
          {/* ---------------To Do--------------------- */}
          <div className="title">
            <div className="notification">
              <div className="notification-img">
                <CapsyncIcon title="todo-outlined" size="22" />
              </div>
              <span className="default-regular-sub-heading-m cs-neutral-100">
                To-do's
              </span>
            </div>
          </div>

          {!userAddress && (
            <Link
              to={
                forIndividual
                  ? "/individual/account-details"
                  : user_role === 2
                  ? "/financial-advisor/account-details"
                  : "/professional/account-details"
              }>
              <div className="bullet-item-container">
                <div className="red-notification-badge"></div>
                <div className="bullet-item">
                  <p className="notification-message default-regular-sub-heading-m cs-neutral-90">
                    Address required
                  </p>
                </div>
              </div>
            </Link>
          )}

          {!profileImg && (
            <Link
              to={
                forIndividual
                  ? "/individual/account-details"
                  : user_role === 2
                  ? "/financial-advisor/account-details"
                  : "/professional/account-details"
              }>
              <div className="bullet-item-container">
                <div className="red-notification-badge"></div>
                <div className="bullet-item">
                  <p className="notification-message default-regular-sub-heading-m cs-neutral-90">
                    Profile picture required
                  </p>
                </div>
              </div>
            </Link>
          )}
          {userAddress && profileImg && (
            <div className="bullet-item-container">
              <div className="bullet-item">
                <p className="notification-message default-regular-body-text-m cs-neutral-60">
                  No pending to-do's
                </p>
              </div>
            </div>
          )}
        </div>
      </NavDropdown>

      {/* <div className="navbar-vertical-line nav-icons"></div> */}
    </>
  );
};

export default Notifications;
