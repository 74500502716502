import React, { useState } from "react";
import DataTable from "react-data-table-component";
import CapsyncIcon from "../CapsyncIcon";
import {
  Button,
  Container,
  Dropdown,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import moment from "moment";
import CapsyncToolTip from "../capsyncTooltip/CapsyncToolTip";
import { capitalizeName } from "../../config/finance";
import commonNames from "../../config/commonNames";
import { totalBalance } from "../../config/financeCalculation";

function currencyFormat(num) {
  return Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function balanceListing(params, type) {
  return (
    <>
      <span className="dollar-value-gap">
        <span className="dollar-margin-right"> $ &nbsp; </span>
        <span>
          {type ? currencyFormat(Math.abs(params)) : currencyFormat(params)}
        </span>
      </span>
    </>
  );
}

function dateUpdated(params) {
  return (
    <OverlayTrigger
      key={"top"}
      placement={"top"}
      overlay={
        <Tooltip
          style={{ maxWidth: "300px" }}
          className={"accredited-investor-tooltip"}
        >
          {params}
        </Tooltip>
      }
    >
      <span>-</span>
    </OverlayTrigger>
  );
}

const SecuritiesHoldings = ({
  securitiesHoldings,
  headingState,
  storedColumns,
}) => {
  const [rowStartIdx, setRowStartIdx] = useState(0);
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownItems[0]);
  const [dropdownDisplay, setDropdownDisplay] = useState(rowsPerPage);
  const handlePaginationDropdownClick = (dropdownValue) => {
    setDropdownDisplay(dropdownValue);
    setRowStartIdx(0);
    if (dropdownValue === "All") {
      setRowsPerPage(securitiesHoldings.length);
      return;
    }
    setRowsPerPage(dropdownValue);
  };

  const currentNumberOfRows = (rowStartIdx, rowsPerPage, lengthOfCompanies) => {
    if (rowStartIdx + rowsPerPage <= lengthOfCompanies) {
      return rowsPerPage;
    } else {
      return lengthOfCompanies - rowStartIdx;
    }
  };

  const handleFirstPageClick = () => {
    setRowStartIdx(0);
    // Assuming your rowsPerPage is already set appropriately
  };
  const handleLastPageClick = () => {
    const totalRows = securitiesHoldings.length;
    const lastPageStartIdx =
      Math.floor((totalRows - 1) / rowsPerPage) * rowsPerPage;
    setRowStartIdx(lastPageStartIdx);
  };

  let columnData = {
    Description: {
      selector: (tr_itr, key) => (
        <td key={key}>
          <CapsyncToolTip
            Child={
              tr_itr.name.length > 25
                ? tr_itr.name.slice(0, 25) + "..."
                : tr_itr.name
            }
            placement={"top"}
            message={tr_itr.name}
            type="text"
          />
        </td>
      ),
      footer: (key) => <td key={key}>{commonNames.Total}</td>,
    },
    Type: {
      selector: (tr_itr, key) => (
        <td key={key}>{capitalizeName(tr_itr.type)}</td>
      ),
    },
    Symbol: {
      selector: (tr_itr, key) => <td key={key}>{tr_itr.ticker_symbol}</td>,
    },
    Currency: {
      selector: (tr_itr, key) => (
        <td key={key} className="text-end">
          {tr_itr.currency}
        </td>
      ),
    },
    Price: {
      selector: (tr_itr, key) => (
        <td key={key} className="text-end">
          {tr_itr.cost_basis
            ? "$" + currencyFormat(parseFloat(tr_itr.cost_basis).toFixed(2))
            : "-"}
        </td>
      ),
    },
    Value: {
      selector: (tr_itr, key) => (
        <td key={key} className="text-end">
          {tr_itr.price
            ? "$" + currencyFormat(parseFloat(tr_itr.price).toFixed(2))
            : "-"}
        </td>
      ),
    },
    Quantity: {
      selector: (tr_itr, key) => (
        <td key={key} className="text-end">
          {tr_itr.quantity ? currencyFormat(parseFloat(tr_itr.quantity)) : "-"}
        </td>
      ),
    },
    "Market Value": {
      selector: (tr_itr, key) => (
        <td key={key} className="text-end">
          {tr_itr.market_value
            ? "$" + currencyFormat(parseFloat(tr_itr.market_value).toFixed(2))
            : "-"}
        </td>
      ),
      footer: (key, allDatas) => (
        <td key={key} className="text-end">
          ${currencyFormat(totalBalance(allDatas, "market_value").toFixed(2))}
        </td>
      ),
    },
    "Date Updated": {
      selector: (tr_itr, key) => (
        <td key={key} className="text-end">
          {tr_itr.date_updated === "-"
            ? dateUpdated("data not available")
            : moment(tr_itr.date_updated).format("MM/DD/YYYY")}
        </td>
      ),
    },
  };

  return (
    <>
      {securitiesHoldings.length === 0 || headingState.length === 0 ? (
        <span className="cs-common-no-record">
          There are no records to display
        </span>
      ) : (
        <div className="cs-data-table portfolio-data-table">
          {/* Custom table */}

          <div className="category-cs-table-container">
            <Table className="cs-table">
              <thead className="cs-thead">
                <tr>
                  {Array.isArray(headingState) &&
                    headingState.map((items, index) => {
                      return (
                        <React.Fragment key={index}>
                          <th
                            className={`default-medium-sub-heading-m ${
                              items == "Price" ||
                              items == "Value" ||
                              items == "Quantity" ||
                              items == "Market Value" ||
                              items == "Date Updated" ||
                              items == "Currency"
                                ? "text-end"
                                : null
                            }`}
                            width={`${
                              items == "Description"
                                ? "300"
                                : items == "Type"
                                ? "180"
                                : items == "Symbol"
                                ? "120"
                                : items == "Price"
                                ? "140"
                                : items == "Value"
                                ? "140"
                                : items == "Market Value"
                                ? "140"
                                : items === "Date Updated"
                                ? "160"
                                : items === "Quantity"
                                ? "140"
                                : items === "Currency"
                                ? "100"
                                : ""
                            }`}
                          >
                            {items}
                          </th>
                        </React.Fragment>
                      );
                    })}
                </tr>
              </thead>
              <tbody className="cs-tbody default-regular-sub-heading-m">
                {Array.isArray(securitiesHoldings) &&
                  securitiesHoldings
                    .slice(rowStartIdx, rowStartIdx + rowsPerPage)
                    .map((items, index) => {
                      return (
                        <tr key={index}>
                          {Array.isArray(headingState) &&
                            headingState.map((head, i) => {
                              return columnData[head]
                                ? columnData[head].selector(items, i)
                                : null;
                            })}
                        </tr>
                      );
                    })}
                <tr className="cs-tfoot-assets default-medium-sub-heading-m">
                  {Array.isArray(headingState) &&
                    headingState.map((head, i) => {
                      return columnData[head] && columnData[head].footer ? (
                        columnData[head].footer(i, securitiesHoldings)
                      ) : (
                        <td></td>
                      );
                    })}
                </tr>
              </tbody>
            </Table>
          </div>
          {securitiesHoldings.length > 0 && (
            <div className="cs-custom-pagination">
              <div className="cs-common-add-dropdown">
                <span className="default-light-body-text-m cs-neutral-80">
                  Rows per page:
                </span>
                <Dropdown>
                  <Dropdown.Toggle className="default-light-body-text-m cs-neutral-80">
                    {dropdownDisplay}
                    <CapsyncIcon title="chevron-down-outlined" size="12" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu onSelect={(e) => console.log(e)}>
                    {paginationDropdownItems.map((noOfRowsPerPage) => {
                      return (
                        <Dropdown.Item
                          className={
                            noOfRowsPerPage === dropdownDisplay &&
                            "cs-selected-dropdown"
                          }
                          onClick={() =>
                            handlePaginationDropdownClick(noOfRowsPerPage)
                          }
                          value={noOfRowsPerPage}
                        >
                          {noOfRowsPerPage}
                        </Dropdown.Item>
                      );
                    })}
                    <Dropdown.Item
                      onClick={() => handlePaginationDropdownClick("All")}
                      className={
                        "All" === dropdownDisplay && "cs-selected-dropdown"
                      }
                    >
                      All
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="default-light-body-text-m cs-neutral-80">
                <p>
                  {rowStartIdx + 1} -{" "}
                  {rowStartIdx +
                    currentNumberOfRows(
                      rowStartIdx,
                      rowsPerPage,
                      securitiesHoldings.length
                    )}{" "}
                  of {securitiesHoldings.length}
                </p>
              </div>
              <div className="cs-pagination-arrow">
                <Button
                  onClick={handleFirstPageClick}
                  disabled={rowStartIdx === 0}
                  className={
                    rowStartIdx === 0 ? "cs-neutral-50" : "cs-neutral-100"
                  }
                >
                  <CapsyncIcon title="chevron-left-skip-outlined" size="18" />
                </Button>
                <Button
                  onClick={() => setRowStartIdx(rowStartIdx - rowsPerPage)}
                  disabled={rowStartIdx === 0}
                  className={
                    rowStartIdx === 0 ? "cs-neutral-50" : "cs-neutral-100"
                  }
                >
                  <CapsyncIcon title="chevron-left-outlined" size="18" />
                </Button>
                <Button
                  onClick={() => setRowStartIdx(rowStartIdx + rowsPerPage)}
                  className={
                    !(rowStartIdx + rowsPerPage < securitiesHoldings.length)
                      ? "cs-neutral-50"
                      : "cs-neutral-100"
                  }
                  disabled={
                    !(rowStartIdx + rowsPerPage < securitiesHoldings.length)
                  }
                >
                  <CapsyncIcon title="chevron-right-outlined" size="18" />
                </Button>
                <Button
                  onClick={handleLastPageClick}
                  className={
                    !(rowStartIdx + rowsPerPage < securitiesHoldings.length)
                      ? "cs-neutral-50"
                      : "cs-neutral-100"
                  }
                  disabled={
                    !(rowStartIdx + rowsPerPage < securitiesHoldings.length)
                  }
                >
                  <CapsyncIcon title="chevron-right-skip-outlined" size="18" />
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SecuritiesHoldings;
