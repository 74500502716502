/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import FirstModal_VI from "./Modals/FirstModal_VI";
// import PaymentInitialModal_VI from "./Modals/PaymentInitialModal_VI";
import { fetch_all_payment_plan } from "../../../../slices/common_payment_plans";
import CapsyncSectionHeader from "../../../../commonComponent/CapsyncSectionHeader";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import SkeletonAccreditation from "./SkeletonAccreditation";

const CommonViHeader = (props) => {
  const VI_location = useLocation();
  const [referenceName, setReferenceName] = useState("");
  // const [paymentModal, setPaymentModal] = useState(false);
  const [firstModal, setFirstModal] = useState(false);
  const [getActivePlan, setGetActivePlan] = useState();
  const [currentPageLocation] = useState(VI_location.pathname);
  const [getPlans, setGetPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({
    title: "",
    amount: 0,
  });

  const vi_navigate = useNavigate();
  const dispatch = useDispatch();

  const changeStatusTab = (tabName) => {
    if (tabName === "Pending") {
      vi_navigate("/individual/verifyinvestor/pending");
    } else if (tabName === "Completed") {
      vi_navigate("/individual/verifyinvestor/completed");
    } else {
      vi_navigate("/individual/verifyinvestor/expired");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // -----------Do not remove below code => Asmita Dt.4th Sept 2023------------//
  // const setActivePlan = (planName) => {
  //   setGetActivePlan(planName);
  // };
  // -----------Do not remove above code => Asmita Dt.4th Sept 2023------------//

  const openFirstModal = () => {
    dispatch(fetch_all_payment_plan({ module_name: "Verify Investor" }))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setGetPlans(response && response.data);
          // setSelectedPlan(response && response.data && response.data[0].title);
          setFirstModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // function handleDropdownSelect(eventKey) {
  //   if (eventKey === "Pending") {
  //     vi_navigate("/individual/verifyinvestor/pending");
  //   } else if (eventKey === "Completed") {
  //     vi_navigate("/individual/verifyinvestor/completed");
  //   } else {
  //     vi_navigate("/individual/verifyinvestor/expired");
  //   }
  // }

  // function fetchTitle(params) {
  //   let newContent = "";
  //   newContent += params;
  //   let newArr = newContent.split("/");
  //   let newStr = newArr[newArr.length - 1];
  //   return (
  //     newStr.substring(0, 1).toUpperCase() + newStr.substring(1, newStr.length)
  //   );
  // }

  return (
    <>
      <div className="cs-section-topbar">
        <CapsyncSectionHeader pagetitle="Investor Accreditation" />
        <div className="cs-tabs-flex">
          <div className="cs-tabs">
            <Button
              onClick={() => changeStatusTab("Pending")}
              className={
                currentPageLocation === "/individual/verifyinvestor/pending"
                  ? "cs-stroke-default-tab cs-stroke-active default-regular-h5 "
                  : "cs-stroke-default-tab default-regular-h5 "
              }
            >
              Pending
            </Button>
            <Button
              onClick={() => changeStatusTab("Completed")}
              className={
                currentPageLocation === "/individual/verifyinvestor/completed"
                  ? "cs-stroke-default-tab cs-stroke-active default-regular-h5"
                  : "cs-stroke-default-tab default-regular-h5"
              }
            >
              Completed
            </Button>
            <Button
              onClick={() => changeStatusTab("Expired")}
              className={
                currentPageLocation === "/individual/verifyinvestor/expired"
                  ? "cs-stroke-default-tab cs-stroke-active default-regular-h5"
                  : "cs-stroke-default-tab default-regular-h5"
              }
            >
              Expired
            </Button>
          </div>
          {props.editAccessUser && <Button
            className="default-regular-h5 cs-btn-icon-primary "
            type="submit"
            onClick={openFirstModal}
          >
            <span className="cs-neutral-10 req-svg">
              <CapsyncIcon title="add-filled" size="18" />
            </span>
            Request New Verification
          </Button>}
        </div>
      </div>


      {/* -----------Do not remove below code => Asmita Dt.4th Sept 2023------------ */}
      {/* 
      <PaymentInitialModal_VI
        setActivePlan={setActivePlan}
        setGetActivePlan={setGetActivePlan}
        getActivePlan={getActivePlan}
        paymentModal={paymentModal}
        setPaymentModal={setPaymentModal}
        setFirstModal={setFirstModal}
      /> */}
      {/* -----------Do not remove above code => Asmita Dt.4th Sept 2023------------ */}

      <FirstModal_VI
        getActivePlan={getActivePlan}
        setGetActivePlan={setGetActivePlan}
        firstModal={firstModal}
        setFirstModal={setFirstModal}
        setReferenceName={setReferenceName}
        referenceName={referenceName}
        getPlans={getPlans}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />
    </>
  );
};
export default CommonViHeader;
