import React from "react";
import { Image, Button, Modal, ModalFooter } from "react-bootstrap";
import ErrorValidation from "../../../../../assets/images/other/error-validation.svg"
import { useSelector } from "react-redux";

const ConfirmEditProfie = (props) => {
  const {
    onContinueEmailUpdate,
    showConfirmEmailUpdate,
    setShowConfirmEmailUpdate,
    values,
    saveAccountDetails
  } = props;
  const { user } = useSelector((state) => state.auth);
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }
  const phone = user && user.user.phoneNo;
  const email = user && user.user.email;




  return (
    <Modal
      id="confirm-edit-profile"
      show={showConfirmEmailUpdate}
      onHide={() => setShowConfirmEmailUpdate(false)}
      backdrop="static"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal cs-delete-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
          Are you Sure?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delete-modal-content">
          <Image src={ErrorValidation}
            alt="Error Validation"
            width={100}
            height={100} />

          <p className="default-light-body-text-m cs-neutral-90">
            {/* Are you sure you want to update email address? */}
            {/* {` Are you sure you want to update ${phone !== values.phoneNo ? "phone number?" : "email address?"} `} */}
            {` Are you sure you want to update ${phone !== values.phoneNo && email !== values.email ? "phone number and email address?" : email !== values.email ? "email address?" : "phone number?"} `}
          </p>
        </div>
      </Modal.Body>
      <ModalFooter className="cs-modal-btn cs-center-btn">
        <Button
          className="cs-btn-secondary md-btn default-regular-h5 btn btn-primary"
          type="button"
          onClick={() => {
            setShowConfirmEmailUpdate(false)
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="cs-btn-primary md-btn default-regular-h5 btn btn-primary"
          onClick={onContinueEmailUpdate}
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmEditProfie;
