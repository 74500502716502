import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./app/store";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./app/App";
import { SkeletonTheme } from "react-loading-skeleton";
import { ErrorBoundary } from "react-error-boundary";
import FallBack from "./app/FallBack";
import { BrowserRouter } from "react-router-dom";

const container = document.getElementById("root");
const root = createRoot(container);

const errorHandler = (error, errorInfo) => {
  console.log("error:- ", error);
  console.log("errorInfo:- ", errorInfo);
};

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ErrorBoundary FallbackComponent={FallBack} onError={errorHandler}>
        <SkeletonTheme baseColor="#E0E0E0" highlightColor="#c2c2c2">
          <App />
        </SkeletonTheme>
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>
);
