import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import subscriptionPlan from "../services/subscription_plan.service";
import { localDataRemove } from "../config/finance";

export const subscription_plan_fetch = createAsyncThunk(
  "/subscriptionplan/get-subscriptionplan-by-role",
  async (values, thunkAPI) => {
    try {
      const response = await subscriptionPlan.subscription_plan_fetch(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const subscription_plan_fetch_all_by_role = createAsyncThunk(
  "/subscriptionplan/get-subscriptionplan",
  async (values, thunkAPI) => {
    try {
      const response =
        await subscriptionPlan.subscription_plan_fetch_all_by_role(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const get_subscription_product_list = createAsyncThunk(
  "/subscriptionplan/get-subscription-product-list",
  async (values, thunkAPI) => {
    try {
      const response = await subscriptionPlan.get_subscription_product_list(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const get_storage_subscription_list = createAsyncThunk(
  "/subscriptionplan/get-storage-subscription-list",
  async (values, thunkAPI) => {
    try {
      const response = await subscriptionPlan.get_storage_subscription_list(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

const initialState = {
  subscriptionPlan: [],
  fileStoragePlan: []
};

const subscriptionPlanSlice = createSlice({
  name: "subscriptionPlan",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(
        get_subscription_product_list.fulfilled,
        (state, action) => {
          let subscriptionPlanObj = {
            month: [],
            year: [],
          };
          action.payload.data.map((plan, index) => {
            const monthlyPlan = plan.price.filter(
              (price) => price.recurring.interval === "month"
            )[0];

            const yearlyPlan = plan.price.filter(
              (price) => price.recurring.interval === "year"
            )[0];

            subscriptionPlanObj.month.push({
              id: plan.id,
              name: plan.name,
              description: plan.description,
              features: plan.features,
              metadata: plan.metadata,
              price: monthlyPlan,
            });

            subscriptionPlanObj.year.push({
              id: plan.id,
              name: plan.name,
              description: plan.description,
              features: plan.features,
              metadata: plan.metadata,
              price: yearlyPlan,
            });
            return subscriptionPlanObj;
          });
          state.subscriptionPlan = subscriptionPlanObj;
        })
      .addCase(get_storage_subscription_list.fulfilled, (state, action) => {
        let storagePlanObj = [];
        action.payload && action.payload.data.map((plan, index) => {
          storagePlanObj.push({
            id: plan.id,
            name: plan.name,
            description: plan.description,
            features: plan.features,
            metadata: plan.metadata,
            price: plan.price[0]
          })
          return storagePlanObj;
        });
        state.fileStoragePlan = storagePlanObj;
      });
  },
});

const { reducer } = subscriptionPlanSlice;
export default reducer;
