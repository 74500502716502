import React from 'react'
import Skeleton from 'react-loading-skeleton'
import SkeletonSettingsTable from "../../../individual/settings/SkeletonSettingsTable"
const SkeletonCategoryDashboard = () => {
    const category = window.location.pathname.substring(1).split("/")[2];
    const categories = ["retirementaccount", "lifeinsurance",
        "business", "accountnotesreceviable", "accountpayable",
        "notespayabletobanksandothers", "installmentsauto",
        "installmentsaccount", "mortgagesonrealestate"];
    return (
        <div className="cs-section-topbar">

            <div className='skeleton-breadcrumb'>
                <div className='skeleton-breadcrumb-1'> <Skeleton width={50} /></div>
                <div className='skeleton-breadcrumb-2'> <Skeleton width={20} /></div>
                <div className='skeleton-breadcrumb-3'><Skeleton width={50} /></div>
            </div>
            <div className="cs-title">
                <h1><Skeleton width={200} height={30} /></h1>
                {/* <div className="cs-view-pfs-filter">
                    <div className="default-regular-body-text-m"><Skeleton width={100} /></div>
                    <div> <Skeleton width={150} /></div>
                </div> */}
            </div>
            <div className="line-chart-container skeleton-line-graph skeleton-category-graph-box">
                <div>
                    <div className="chart-heading category-skeleton-chart-head">
                        <h2 className="default-semi-bold-h2 cs-neutral-100"> <Skeleton width={200} /></h2>
                        <span className="default-regular-body-text-s cs-neutral-70">
                            <Skeleton width={250} />
                        </span>
                    </div>
                    <div className="for-des">
                        <div className="skeleton-category-linechart">
                            <Skeleton height={340} />
                        </div>
                    </div>
                    <div className="for-mob">
                        <div className="skeleton-category-linechart">
                            <Skeleton height={290} />
                        </div>
                    </div>
                </div>
            </div>
            {categories.includes(category) && (
                <>
                    <div className='for-des'>
                        <div className="category-manage-row">
                            <div className="cs-common-add-dropdown">
                                <Skeleton width={200} height={40} />
                            </div>
                        </div>
                    </div>
                    <div className='for-mob'>
                        <div className="category-manage-row">
                            <div className="cs-common-add-dropdown skeleton-mob-drag">
                                <Skeleton width={40} height={40} />
                            </div>
                        </div>
                    </div>
                </>
            )
            }
            <SkeletonSettingsTable tableData={[1, 2, 3]} />
        </div>
    )
}

export default SkeletonCategoryDashboard