import API from "../util/api";
import { headerAddToken } from "../util/common";

const subscription_plan_fetch = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/subscriptionplan/get-subscriptionplan-by-role",
    values,
    header
  );
};

const subscription_plan_fetch_all_by_role = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/subscriptionplan/get-subscriptionplan",
    values,
    header
  );
};

const get_subscription_product_list = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/subscriptionplan/get-subscription-product-list",
    values,
    header
  );
};

const get_storage_subscription_list = async (id) => {
  let header = await headerAddToken();
  return API.get(
    "/subscriptionplan/get-storage-subscription-list/" + id,
    header
  );
};

const subscriptionPlan = {
  subscription_plan_fetch,
  subscription_plan_fetch_all_by_role,
  get_subscription_product_list,
  get_storage_subscription_list
};

export default subscriptionPlan;
