import React, { useState } from "react";
import { Form, Row, Col, Image } from "react-bootstrap";
import SubMenuHeader from "../SubMenuHeader";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import SubMethodsList from "../../SubMethodsList";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import { Link } from "react-router-dom";
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";

// sequence No. four  (Asmita date-29th June => Do not remove this line)
const MethodIdThirtyFive = (props) => {


  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Accredited Investor",
      url: `/individual/verifyinvestor/accreditedInvestor/${props.verificationType}/${props.unique_code}`,
    },
  ];

  const [crdValidation, setCrdValidation] = useState("");
  const handleCRDNumberChange = (e) => {
    let crd = e.target.value;
    setCrdValidation(crd.length === 7 ? "" : "Enter a valid CRD number")
    props.getCrdNumber(e.target.value);


  }

  return (
    <>
      {props.is_loader.assets === true || props.is_loader.liabilities === true ? <SkeletonAllSubMethods /> :
        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="License Holders"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>
              <SubMenuHeader />
            </div>
            <div className="method-section">
              <div className="default-regular-body-text-l cs-neutral-70">
                <SubMethodsList methodId={37} />
              </div>

              <div className="cs-divider"></div>
              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert}
                />

                {props.setShowTrustUploadBlock && <div>
                  <Form.Label className="default-regular-sub-heading-s cs-neutral-90">Please upload evidence of who all the equity owners of the trust are (by uploading your trust documentation or this <Link className="cs-neutral-100" to="https://s3.amazonaws.com/static-capsync.com/Verify-Investor-files/All-Owners-Accredited-Officer-Certificate.pdf" target="_blank"><b>Officer’s Certificate</b></Link> along with a document to show ownership percentages). In a revocable trust, typically, the grantors are the equity owners.</Form.Label>
                  <UploadFileBlock
                    files={props.files2}
                    setOpenModal={props.setOpenModal2}
                    mediaAlert={props.mediaAlert}
                    deleteFile={props.deleteFile2}
                    showFile={props.showFile2}
                    pfsLink={props.pfsLink}
                    pfsName={props.pfsName}
                    pfsDelete={props.pfsDelete}
                    showDeleteSpinner={props.showDeleteSpinner}
                    showPfsLink={props.showPfsLink2} />
                </div>}
                <p className="row-spacing default-regular-body-text-l cs-neutral-70">
                  Enter your name as it appears on your license, your individual
                  CRD#, and the name of the firm you are licensed through, if
                  applicable.
                </p>
                <Row className="cs-form-group row-spacing">
                  <Col sm={12} md={5} lg={5}>
                    <Form.Group
                      controlId="validationFormik01"
                    >
                      <Form.Label className="default-regular-sub-heading-s cs-neutral-100">
                        Name
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="Name"
                        autoComplete="off"
                        name="Name"
                        // onKeyPress={(e) =>
                        //   !/[a-zA-z]/.test(e.key) && e.preventDefault()
                        // }
                        onChange={(e) => props.getNumberValue(e.target.value)}
                        value={props.formValue}
                        className={props.formValue && "cs-input-field-active"}
                        maxLength={50}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="cs-form-group row-spacing">
                  <Col sm={12} md={5} lg={5}>
                    <Form.Group
                      controlId="validationFormik01"
                    >
                      <Form.Label className="default-regular-sub-heading-s cs-neutral-100">
                        Individual CRD Number
                        <span className="cs-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="CRD #"
                        autoComplete="off"
                        name="Name"
                        onChange={handleCRDNumberChange}
                        // onChange={(e) =>   props.getCrdNumber(e.target.value)}
                        value={props.crdNumber}
                        className={props.crdNumber ? "cs-input-field-active" : props.checkInvestorError ? "investor-error" : ""}
                      />
                      {props.checkInvestorError ? (<span className="default-regular-sub-heading-xs cs-danger">Enter CRD number</span>)
                        : crdValidation && (<p className="form-error-msg default-light-body-text-s cs-danger">{crdValidation}</p>)}
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={5} lg={5}>
                    <Form.Group
                      className="mb-3 cap-form-group"
                      controlId="validationFormik01"
                    >
                      <Form.Label className="default-regular-sub-heading-s cs-neutral-100">
                        Firm Name (required for series 7 or 82)
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Firm Name"
                        autoComplete="off"
                        name="Name"
                        // onKeyPress={(e) =>
                        //   !/[a-zA-z]/.test(e.key) && e.preventDefault()
                        // }
                        onChange={(e) => props.getFirmName(e.target.value)}
                        value={props.firmName}
                        className={props.firmName && "cs-input-field-active"}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {props.setShowTrustUploadBlock &&
                  <p className="default-regular-sub-heading-m cs-neutral-90 row-spacing">If verification is for an ENTITY or TRUST owned by multiple individuals who are not all licensed, please select another verification method. If both individuals are licensed, please enter the information for the second license in the box at the bottom of the page.</p>}
                <Form.Group controlId="formFile" >
                  <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                    Upload other evidence (Optional):
                  </Form.Label>
                  <UploadFileBlock
                    files={props.files}
                    setOpenModal={props.setOpenModal}
                    mediaAlert={props.mediaAlert}
                    deleteFile={props.deleteFile}
                    showFile={props.showFile}
                    pfsLink={props.pfsLink}
                    pfsName={props.pfsName}
                    pfsDelete={props.pfsDelete}
                    showDeleteSpinner={props.showDeleteSpinner}
                    showPfsLink={props.showPfsLink}
                    uploadError={props.uploadError}

                  />
                </Form.Group>
                <NotesBlock setNotesValue={props.setNotesValue} />
              </Form>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default MethodIdThirtyFive;
