import React, { useEffect } from "react";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import "../assets/css/variables.css";
import "../assets/css/main.css";
import "../assets/css/responsive.css";
import "react-toastify/dist/ReactToastify.css";

import Routing from "./routes/Routing";
import DeviceDetector from "device-detector-js";
import PageNotFound from "./pages/404";
import UnderMaintenance from "./pages/underMaintenance";
import { useLocation } from "react-router-dom";
import { useUser } from "./slices/auth";
import { socket } from "./config/Socket";
import { useSelector } from "react-redux";

var pjson = require("../../package.json");
const version = process.env.REACT_APP_VERSION + "-" + pjson.commit_id;
console.log("App version & commit id:", version);
const App = () => {
  const location = useLocation();

  const user = useUser();
  const token = user && user.token;

  const { authUser } = useSelector((state) => state.auth);
  const loginStatus = JSON.parse(window.localStorage.getItem("loginStatus"));
  const role = loginStatus && loginStatus.user_role;

  useEffect(() => {
    const handleBeforeUnload = () => {
      socket.emit("access_remove", {
        advisor_user_id: authUser ? authUser?.user?.id : user?.user?.id,
        user_id: authUser ? authUser?.user?.id : user?.user?.id,
        advisor_user_role: role,
        flag: true,
      });
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  // useEffect(() => {
  //   (function (w, d, s, l, i) {
  //     let f = d.getElementsByTagName(s)[0],
  //       j = d.createElement(s),
  //       dl = l != "dataLayer" ? "&l=" + l : "";
  //     let scripts = document.getElementsByTagName("script");

  //     if (
  //       process.env.REACT_APP_MODE === "PROD" &&
  //       ["/", "/signup"].find(
  //         (pathName) => pathName === window.location.pathname
  //       ) &&
  //       !token
  //     ) {
  //       w[l] = w[l] || [];
  //       w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  //       j.async = true;
  //       j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;

  //       let isScriptContain = false;
  //       for (const script of scripts) {
  //         if (
  //           script.src ==
  //           "https://www.googletagmanager.com/gtm.js?id=" + i + dl
  //         ) {
  //           isScriptContain = true;
  //         }
  //       }
  //       if (!isScriptContain) {
  //         f.parentNode.insertBefore(j, f);
  //       }
  //     } else {
  //       let removeScript = [];
  //       const scripts = document.querySelectorAll(
  //         'script[src*="https://www.googletagmanager.com"]'
  //       );

  //       // for (let s of scripts) {
  //       //   document.head.parentNode.removeChild(s);
  //       // }
  //       // for (const script of scripts) {
  //       //   if (script.src.includes("www.googletagmanager.com")) {
  //       //     removeScript.push(script);
  //       //   }
  //       // }
  //       if (removeScript) {
  //         for (let script of scripts) {
  //           f && f.parentNode && f.parentNode.removeChild(script);
  //         }
  //       }
  //     }
  //   })(window, document, "script", "dataLayer", "GTM-TQ59WWG");
  // }, [location]);

  useEffect(() => {
    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    const device_info = deviceDetector.parse(userAgent);

    document.body.classList.add(
      `os-${device_info.os.name.replace(/ /g, "-").toLowerCase()}`
    );
    document.body.classList.add(
      `browser-${device_info.client.name.replace(/ /g, "-").toLowerCase()}`
    );
  }, []);

  if (process.env.REACT_APP_IS_UNDER_MAINTENANCE === "true") {
    return <UnderMaintenance />;
  }
  let output = window.localStorage.getItem("tempPass");
  if (output === null && process.env.REACT_APP_WEB_PASSWORD !== "app_login") {
    let webPass = process.env.REACT_APP_WEB_PASSWORD;
    let disPass = window.prompt("Enter password:");
    if (disPass === webPass) {
      window.localStorage.setItem("tempPass", webPass);
      window.location.href = "/";
    } else {
      return <PageNotFound />;
    }
  } else {
    return (
      <>
        <Routing />
        <div className="versionFooter" version={version}></div>
      </>
    );
  }
};

export default App;
