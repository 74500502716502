/* eslint-disable */
import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import commonNames from "../../../../config/commonNames";

import { fetchCategoryDataPFSTable } from '../../../../slices/category.slice';
import AssetCategoryAllData from "../../category_all_data/AssetCategoryAllData";
import LiabilityCategoryAllData from "../../category_all_data/LiabilityCategoryAllData";
import { tableHeader } from "../../../../config/categoryTable";
import { Link } from "react-router-dom";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";

const SharePfsCategoryTable = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const { categoryDataPFS: { assetsListArr = [], liabilitiesListArr = [] } } = useSelector((state) => state.category);
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const fetchCategoryData = (category) => {
    if (id) {
      dispatch(fetchCategoryDataPFSTable({ user_id: id, category }))
        .unwrap()
        .catch((error) => {

        });
    }
  };

  useEffect(() => {
    fetchCategoryData('assets');
    fetchCategoryData('liabilities');
  }, []);

  return (
    <>
      <Container fluid>
        <div className="pfs-assets-table">
          <Row>
            <Col lg={12}>
              <h2 className="pfs-h2-title default-semi-bold-h1 cs-neutral-100">
                {commonNames.Assets}
                <Link
                  to={`/individual/assets`}
                  className="custom-table-element"
                  onClick={props.closeModal}
                >
                  <span className="cs-asset icon">
                    <CapsyncIcon title="redirect-outlined" size="28" />
                  </span>
                </Link>
              </h2>

              {assetsListArr.length > 0 && assetsListArr.map((assetsCategoryData, index) => {
                const { categoryTitle, categoryType, data } = assetsCategoryData;

                const assetsColumnsListArr = tableHeader.assets[categoryType] ? tableHeader.assets[categoryType] : [];
                return (
                  <AssetCategoryAllData
                    closeModal={props.closeModal}
                    toastMessage={props.toastMessage}
                    assetsColumnsListArr={assetsColumnsListArr}
                    categoryTitle={categoryTitle}
                    categoryType={categoryType}
                    displayArr={data}
                  />
                )
              })}
            </Col>
          </Row>
        </div>

        <div className="pfs-liabilities-table">
          <Row>
            <Col lg={12}>
              <h2 className="pfs-h2-title default-semi-bold-h1 cs-neutral-100">
                {commonNames.Liabilities}
                <Link
                  to={`/individual/liabilities`}
                  className="custom-table-element"
                  onClick={props.closeModal}
                >
                  <span className="cs-liability icon">
                    <CapsyncIcon title="redirect-outlined" size="28" />
                  </span>
                </Link>
              </h2>

              {liabilitiesListArr.length > 0 && liabilitiesListArr.map((liabilitiesCategoryData, index) => {
                const { categoryTitle, categoryType, data } = liabilitiesCategoryData;
                const liabilitiesColumnsListArr = tableHeader.liabilities[categoryType];
                return (
                  <LiabilityCategoryAllData
                    closeModal={props.closeModal}
                    toastMessage={props.toastMessage}
                    liabilitiesColumnsListArr={liabilitiesColumnsListArr}
                    categoryTitle={categoryTitle}
                    categoryType={categoryType}
                    displayArr={data}
                  />
                )
              })}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
export default SharePfsCategoryTable;
