import {
  assetsCategory,
  liabilitiesCategory,
  lineQuarter,
  dashboardChartData,
  portFolioType,
  lineChartMonth,
  plaidListObj,
} from "./finance";
import moment from "moment";
import moment_timezone from "moment-timezone";

// const moment_timezone = require("moment-timezone");

function portfolioPieChartCalculate(data) {
  let storeArr = [];
  let allSecuritiesTypes = data.map((x) => x.securities[0].type);
  let allHoldings = data.map((x) => x.holdings).flat();

  let uniqueTypes = [...new Set(allSecuritiesTypes)];

  let typeSecurity = [];

  uniqueTypes.forEach((itr) => {
    let sec_id = [];
    data.forEach((itr_data) => {
      if (itr_data.securities[0].type === itr) {
        sec_id.push(itr_data.securities[0].security_id);
      }
    });
    typeSecurity.push({ type: itr, security_id: sec_id });
  });

  typeSecurity.forEach((itr) => {
    let netValue = 0;
    itr.security_id.forEach((security_id_itr) => {
      allHoldings.forEach((itr) => {
        if (security_id_itr === itr.security_id) {
          netValue += itr.institution_price * itr.quantity;
        }
      });
    });

    storeArr.push({
      name: itr.type,
      value: netValue,
      fill:
        portFolioType[itr.type] !== undefined
          ? portFolioType[itr.type]
          : "#CA4949",
    });
  });

  return storeArr;
}

function pieChartCalculate(data, type) {
  const categoryList =
    type === "dashboard"
      ? dashboardChartData
      : type === "assets"
        ? assetsCategory
        : liabilitiesCategory;

  let pieData = [];

  categoryList.forEach((itr) => {
    const value = data[itr.summaryAllocation];
    if (value !== 0) {
      pieData.push({
        name: itr.title,
        value: value,
        fill: itr.fill,
      });
    }
  });

  return pieData;
}

function fetchDate(params) {
  let return_date = new Date(params);
  return return_date.getMonth();
}

function fetchDateYear(params) {
  let return_date = new Date(params);
  return return_date.getFullYear().toString().substring(2, 4);
}

function monthWiseQuarter() {
  var threeMonthsAgo = moment().subtract(0, "months").format("YYYY-MM-DD");
  var threeMonthsAgo2 = moment().subtract(4, "months").format("YYYY-MM-DD");
  var threeMonthsAgo3 = moment().subtract(8, "months").format("YYYY-MM-DD");
  var threeMonthsAgo4 = moment().subtract(12, "months").format("YYYY-MM-DD");

  const legendFooter = [
    lineChartMonth[fetchDate(threeMonthsAgo4)] +
    " '" +
    fetchDateYear(threeMonthsAgo4),
    lineChartMonth[fetchDate(threeMonthsAgo3)] +
    " '" +
    fetchDateYear(threeMonthsAgo3),
    lineChartMonth[fetchDate(threeMonthsAgo2)] +
    " '" +
    fetchDateYear(threeMonthsAgo2),
    lineChartMonth[fetchDate(threeMonthsAgo)] +
    " '" +
    fetchDateYear(threeMonthsAgo),
  ];
  return legendFooter;
}

function lineChartCalculation(data, type) {
  let lineData = [];
  const legendFooter = monthWiseQuarter();



  lineQuarter.forEach((itr, i) => {
    lineData.push({
      name: legendFooter[i],
      // [type]: data[itr],
      [type]: data && data.graphData && data.graphData[itr],
    });
  });

  return lineData;
}

function dashboardLineChartCalculation(asset, liability, type) {

  let allLiability = lineChartCalculation(liability, "Liabilities");
  let allAsset = lineChartCalculation(asset, "Assets");
  const legendFooter = monthWiseQuarter();
  let lineData = [];

  allAsset.forEach((itr, i) => {
    lineData.push({
      name: legendFooter[i],
      Assets: itr.Assets,
      Liabilities: allLiability[i].Liabilities,
      NetWorth: itr.Assets - allLiability[i].Liabilities,
    });
  });

  return lineData;
}

function categoryLineChartCalculation(data, type) {
  let lineData = [];
  const legendFooter = monthWiseQuarter();

  let i = 1;
  for (const key in data) {
    lineData.push({
      name: legendFooter[i - 1],
      [type]: data[key],
    });
    i++;
  }
  return lineData;
}

function extractDate(params) {
  let new_date = new Date(params);
  // return `${new_date.getFullYear()}-${
  //   new_date.getMonth() + 1
  // }-${new_date.getDate()}`;
  return `${new_date.getFullYear()}-${(new_date.getMonth() + 1).toString().length === 1
    ? "0" + (new_date.getMonth() + 1)
    : new_date.getMonth() + 1
    }-${new_date.getDate().toString().length === 1
      ? "0" + new_date.getDate()
      : new_date.getDate()
    }`;
}

function quarterDate(params) {
  const today = new Date(params);
  const month = today.getMonth();
  today.setMonth(month - 4);
  return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
}

function checkWeekend(params) {
  const d = moment_timezone(params).tz("America/New_York");
  let weekDate = "";
  if (d.day() === 0) {
    weekDate = moment_timezone(params)
      .tz("America/New_York")
      .subtract(2, "days")
      .format("YYYY-MM-DD");
  } else if (d.day() === 6) {
    weekDate = moment_timezone(params)
      .tz("America/New_York")
      .subtract(1, "days")
      .format("YYYY-MM-DD");
  } else {
    weekDate = params;
  }
  return weekDate;
}

const findPastDates = (date, intervalInMonth, frequency, dates = []) => {
  const newDates = [...dates];
  let pastDate = date;
  if (date) {
    pastDate = moment_timezone(date)
      .tz("America/New_York")
      .subtract(intervalInMonth, "months")
      .format("YYYY-MM-DD");
  } else {
    const timeStamp = moment_timezone().tz("America/New_York").format("h:mma");
    const currentTime = moment(timeStamp, "h:mma");
    const marketOpenTime = moment("9:30am", "h:mma");
    // check market is opened or not - if opened then considered todays date otherwise yesterday date
    if (currentTime.isAfter(marketOpenTime)) {
      pastDate = moment_timezone(new Date())
        .tz("America/New_York")
        .format("YYYY-MM-DD");
    } else {
      pastDate = moment_timezone(new Date())
        .tz("America/New_York")
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    }
  }
  const newFreq = frequency - 1;
  if (frequency > 0) {
    return findPastDates(pastDate, intervalInMonth, newFreq, [
      ...newDates,
      { name: checkWeekend(pastDate), value: 0 },
    ]);
  } else {
    return newDates;
  }
};

function categoryStocksLineChartCalculation(historicalData, securitiesData) {
  let holdingsData =
    securitiesData && securitiesData.length ? [...securitiesData] : [];
  const pieChartData = {};
  let lineChartData = findPastDates("", 4, 4) || [];
  const finalPiechartData = [];

  if (!holdingsData.length) {
    return {
      holdingsData,
      lineChartData: [],
      finalPiechartData,
    };
  }

  for (let i = 0; i < holdingsData.length; i++) {
    const selectedSecuritiesData = historicalData.find(
      (data) => data.ticker_symbol === holdingsData[i].ticker_symbol
    );

    if (
      selectedSecuritiesData &&
      selectedSecuritiesData.data &&
      selectedSecuritiesData.data.length
    ) {
      lineChartData = lineChartData.map((date, dateIndex) => {
        const matchedData = selectedSecuritiesData.data.find((data) => {
          if (
            moment(date.name).format("YYYY-MM-DD") ===
            moment(data.date).format("YYYY-MM-DD")
          ) {
            return data;
          }
        });
        if (matchedData && matchedData.close) {
          if (dateIndex === 0) {
            holdingsData[i].price = matchedData.close;
            holdingsData[i].market_value =
              matchedData.close * holdingsData[i].quantity;
            if (pieChartData[holdingsData[i].type]) {
              pieChartData[holdingsData[i].type] +=
                matchedData.close * holdingsData[i].quantity;
            } else {
              pieChartData[holdingsData[i].type] =
                matchedData.close * holdingsData[i].quantity;
            }
          }
          return {
            ...date,
            value: date.value + matchedData.close * holdingsData[i].quantity,
          };
        } else {
          if (dateIndex === 0) {
            if (pieChartData[holdingsData[i].type]) {
              pieChartData[holdingsData[i].type] +=
                holdingsData[i].price * holdingsData[i].quantity;
            } else {
              pieChartData[holdingsData[i].type] =
                holdingsData[i].price * holdingsData[i].quantity;
            }
          }
          return {
            ...date,
            value:
              date.value + holdingsData[i].price * holdingsData[i].quantity,
          };
        }
      });
    } else {
      if (pieChartData[holdingsData[i].type]) {
        pieChartData[holdingsData[i].type] +=
          holdingsData[i].price * holdingsData[i].quantity;
      } else {
        pieChartData[holdingsData[i].type] =
          holdingsData[i].price * holdingsData[i].quantity;
      }
      lineChartData = lineChartData.map((date) => {
        return {
          ...date,
          value: date.value + holdingsData[i].price * holdingsData[i].quantity,
        };
      });
    }
  }

  for (const key in pieChartData) {
    finalPiechartData.push({
      name: key,
      value: parseFloat(pieChartData[key].toFixed(2)),
      fill: portFolioType[key] !== undefined ? portFolioType[key] : "#CA4949",
    });
  }

  lineChartData = lineChartData.reverse().map((val) => {
    return {
      name: moment(val.name).format("MMM 'YY"),
      Assets: parseFloat(val.value.toFixed(2)),
    };
  });

  return { holdingsData, lineChartData, finalPiechartData };
}

function totalBalance(external_api, type) {
  let totalExternalApi =
    external_api && external_api.reduce((prev, curr) => prev + curr[type], 0);
  return totalExternalApi;
}

function plaidAccountListing(newAcc, duplicateAcc, type, categoryNames) {
  let storeArr = [];
  for (let i = 0; i < plaidListObj[type].newAcc.length; i++) {
    storeArr.push({
      newAcc: newAcc[plaidListObj[type].newAcc[i]],
      duplicateAcc: duplicateAcc[plaidListObj[type].duplicateAcc[i]],
      tableHead: categoryNames[i],
    });
  }

  return storeArr;
}

export {
  pieChartCalculate,
  lineChartCalculation,
  dashboardLineChartCalculation,
  categoryLineChartCalculation,
  categoryStocksLineChartCalculation,
  portfolioPieChartCalculate,
  totalBalance,
  plaidAccountListing,
};
