import React from 'react'
import { Button, Image } from 'react-bootstrap';
import DefaultImg from './DefaultImg';


const PlaidUpdateMode = ({ handleUpdate, navigateToDashboard, imgpath_3, headerMsg }) => {
    return (
        <>
            <div className={`text-center plaid-callApi-prog-bar`}>
                <div className="plaid-head">
                    <div className="modal-header">
                        <h3 className="default-semi-bold-h3 modal-title h4">Are you sure?</h3>
                    </div>

                    <div className="log-out-popup">
                        <div className="cs-bank-images">
                            <div className="cs-common-logo">
                                <Image
                                    src={require("../../../../../assets/images/cap-icon-logo.svg").default}
                                    alt="loadergif"
                                    className="plaid-img-bank plaid-logo-circle"
                                />
                            </div>
                            <Image
                                src={require("../../../../../assets/images/other/update_sync.svg").default}
                                alt="loadergif"
                                className="plaid-arrow-width"
                            />
                            <div className="cs-common-logo">
                                <DefaultImg params={imgpath_3} />
                            </div>
                        </div>
                        <p className="default-light-body-text-m cs-neutral-90">
                            You are attempting to link a new, or remove an existing account from your {headerMsg} account.
                        </p>
                    </div>
                    <div className="cs-modal-btn cs-center-btn">
                        <Button className="cs-btn-secondary md-btn default-regular-h5" onClick={navigateToDashboard}>
                            Cancel
                        </Button>
                        <Button onClick={handleUpdate} className="cs-btn-primary md-btn default-regular-h5">
                            Continue
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PlaidUpdateMode