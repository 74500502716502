/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Table, Dropdown, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment_timezone from "moment-timezone";

import moment from "moment";
import {
  transactionsGetAll,
  investmentTransactions,
  transaction_breadcrumb,
} from "../../../slices/transactions";
import { DecryptReact } from "../../../customHooks/EncryptDecryptReact";
import BreadCrumHeader from "./BreadCrumHeader";
import SearchFilter from "./SearchFilter";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import SkeletonTransactions from "./SkeletonTransactions";
import defaultImg from "../../../../assets/images/institution_logo/ins_129570.svg";
import { capitalizeName } from "../../../config/finance";
import CapsyncLoader from "../../../commonComponent/CapsyncLoader";
import CapsyncToolTip from "../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import useDebounce from "../../../customHooks/useDebounce";

function currencyFormat(num) {
  if (num > 0) {
    return (
      "$" +
      Number(num)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  } else {
    return (
      "-$" +
      Number(Math.abs(num))
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
    );
  }
}

function balanceListing(params, type) {
  return (
    <>
      <span className="dollar-value-gap transaction_custom_column">
        <span className="dollar-margin-right"> $ &nbsp; </span>
        <span>
          {type ? currencyFormat(Math.abs(params)) : currencyFormat(params)}
        </span>
      </span>
    </>
  );
}

function descriptionListing(src, description, type) {
  return (
    <>
      <div className="transaction-flex">
        {type === "description" ? (
          src.status ? (
            <img src={src.source} alt="" srcSet="" width={50} height={50} />
          ) : (
            transactionImg(src.source)
          )
        ) : (
          src && transactionImg(src)
        )}
        <div>
          {type === "description" ? (
            <CapsyncToolTip
              Child={
                description.length > 35
                  ? description.slice(0, 35) + "..."
                  : description
              }
              placement={"top"}
              message={description}
              type="text"
            />
          ) : (
            description
          )}
        </div>
      </div>
    </>
  );
}

function transactionImg(img) {
  try {
    return (
      <>
        <img
          src={require(`../../../../assets/images/transaction_img/${img}`)}
          alt="loadergif"
          width={50}
          height={50}
        />
      </>
    );
  } catch (error) {
    return (
      <>
        <img
          src={defaultImg}
          alt="loadergif"
          className="plaid-img-anotherbank plaid-logo-circle"
        />
      </>
    );
  }
}

const Transactions = (props) => {
  const apiDataLimit = 1000;
  const { mask_id, category } = useParams();

  const [rowStartIdx, setRowStartIdx] = useState(0);
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownItems[0]);
  const [dropdownDisplay, setDropdownDisplay] = useState(rowsPerPage);
  const [totalRow, setTotalRow] = useState(0);
  const [sort, setSort] = useState({
    name: "",
    type: "",
  });

  const [downloadButton, setDownloadButton] = useState(false);

  const handlePaginationDropdownClick = (dropdownValue) => {
    setDropdownDisplay(dropdownValue);
    setRowStartIdx(0);
    if (dropdownValue === "All") {
      setRowsPerPage(filteredTransactions.length);
      return;
    }
    setTransactionsList(allTransactionsList.slice(0, 0 + dropdownValue));
    setRowsPerPage(dropdownValue);
  };

  const currentNumberOfRows = (rowStartIdx, rowsPerPage, lengthOfCompanies) => {
    if (rowStartIdx + rowsPerPage <= lengthOfCompanies) {
      return rowsPerPage;
    } else {
      return lengthOfCompanies - rowStartIdx;
    }
  };

  const handleFirstPageClick = () => {
    setTransactionsList(allTransactionsList.slice(0, 0 + rowsPerPage));
    setRowStartIdx(0);
    // Assuming your rowsPerPage is already set appropriately
  };

  const handleLastPageClick = () => {
    const totalRows = totalRow;
    const lastPageStartIdx =
      Math.floor((totalRows - 1) / rowsPerPage) * rowsPerPage;
    if (allTransactionsList.length === totalRow) {
      setTransactionsList(
        allTransactionsList.slice(
          lastPageStartIdx,
          lastPageStartIdx + rowsPerPage
        )
      );
    } else {
      getAllTransactions(
        "last",
        lastPageStartIdx,
        search,
        sort.name,
        sort.value,
        startDate,
        endDate,
        transactionTypeFilterActives
      );
    }
    setRowStartIdx(lastPageStartIdx);
  };

  const { user, lastSyncDate } = useSelector((state) => state.auth);

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const dispatch = useDispatch();

  const [allTransactionsList, setAllTransactionsList] = useState([]);
  const [transactionsList, setTransactionsList] = useState([]);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredTransactions, setFiltered] = useState([]);
  const [transDate, setTransDate] = useState({
    start: "",
    end: "",
  });
  const [breadcrumb, setBreadcrumb] = useState({
    category: "",
    sub_category: "",
    main_category: "",
    institute: "",
    category_url: "",
  });

  const [isLoader, setIsLoader] = useState(true);
  const [selectCustomFilter, setSelectCustomFilter] = useState("View All");
  const [transactionTypeFilterModelShow, setTransactionTypeFilterModelShow] =
    useState(false);
  const [transactionTypeFilterActives, settransactionTypeFilterActives] =
    useState([]);
  const [transactionTypeFilterList, setTransactionTypeFilterList] = useState(
    []
  );
  const transactionTypeFilterDropdown = useRef(null);

  function fetchInsId() {
    const decryptedData = DecryptReact(
      window.localStorage.getItem("trans_ins_id")
    );
    return decryptedData;
  }

  const onHandleActiveTransactionTypeFilter = (key) => {
    if (transactionTypeFilterActives.includes(key)) {
      const updatedRowData = transactionTypeFilterActives.filter(
        (data) => data !== key
      );
      settransactionTypeFilterActives(updatedRowData);
      getAllTransactions(
        0,
        0,
        search,
        sort.name,
        sort.value,
        startDate,
        endDate,
        updatedRowData
      );
      setRowStartIdx(0);
    } else {
      settransactionTypeFilterActives([...transactionTypeFilterActives, key]);
      getAllTransactions(
        0,
        0,
        search,
        sort.name,
        sort.value,
        startDate,
        endDate,
        [...transactionTypeFilterActives, key]
      );
      setRowStartIdx(0);
    }
  };
  const closeOpenMenus = (e) => {
    if (
      transactionTypeFilterModelShow &&
      !!transactionTypeFilterDropdown.current &&
      !transactionTypeFilterDropdown.current.contains(e.target)
    ) {
      setTransactionTypeFilterModelShow(false);
    }
  };

  document.addEventListener("mousedown", closeOpenMenus);

  const statusFilter = (
    <div className="wm-clientbill-filter transaction-filter">
      <Dropdown
        className="cs-form-dropdown"
        show={transactionTypeFilterModelShow}
        ref={transactionTypeFilterDropdown}
      >
        <Dropdown.Toggle
          onClick={() =>
            setTransactionTypeFilterModelShow(!transactionTypeFilterModelShow)
          }
        >
          <span className="cs-neutral-100">
            <CapsyncIcon title="filter-filled" size="18" />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu autoClose="inside">
          {transactionTypeFilterList.map((key) => (
            <Dropdown.Item
              onClick={() => onHandleActiveTransactionTypeFilter(key)}
              className={`${
                transactionTypeFilterActives.includes(key)
                  ? " client-status-active "
                  : ""
              } client-status-default`}
            >
              {transactionTypeFilterActives.includes(key) && (
                <span
                  className={
                    transactionTypeFilterActives.includes(key)
                      ? ""
                      : "cs-primary"
                  }
                >
                  <CapsyncIcon title="check-outlined" size="14" />
                </span>
              )}
              <Form.Check
                type="checkbox"
                className="cs-form-check-box"
                onChange={(e) => {
                  transactionTypeFilterActives.includes(key);
                }}
                checked={transactionTypeFilterActives.includes(key)}
                label={
                  <span className="default-regular-body-text-m cs-neutral-90">
                    {key}
                  </span>
                }
              />
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  function columnListing() {
    if (["retirementaccount", "nonretirementbrokerage"].includes(category)) {
      return [
        {
          name: "Date",
          selector: (tr_itr) => moment(tr_itr.date).utc().format("MM/DD/YYYY"),
          cell: (tr_itr) => moment(tr_itr.date).utc().format("MM/DD/YYYY"),
          sortable: true,
          width: 160,
          column_name: "date",
        },
        {
          name: "Subtype",
          selector: (tr_itr) => capitalizeName(tr_itr.subtype),
          sortable: true,
          width: 200,
          column_name: "subtype",
        },
        {
          name: "Description",
          selector: (tr_itr) => (
            <CapsyncToolTip
              Child={
                tr_itr.name.length > 44
                  ? tr_itr.name.slice(0, 44) + "..."
                  : tr_itr.name
              }
              placement={"top"}
              message={tr_itr.name}
              type="text"
            />
          ),
          sortable: true,
          width: 700,
          column_name: "description",
        },
        {
          name: "Currency",
          selector: (tr_itr) => (
            <span className="text-end">{tr_itr.currency}</span>
          ),
          sortable: true,
          width: 160,
          column_name: "currency",
          header_class: "text-end",
        },
        {
          name: "Quantity",
          selector: (tr_itr) => (
            <span className="text-end">{tr_itr.quantity}</span>
          ),
          sortable: true,
          width: 160,
          column_name: "quantity",
          header_class: "text-end",
        },
        {
          name: "Price",
          selector: (tr_itr) => (
            <span className="text-end">
              {currencyFormat(tr_itr.price.toFixed(2))}
            </span>
          ),
          sortable: true,
          width: 200,
          column_name: "price",
          header_class: "text-end",
        },
        {
          name: "Fees",
          selector: (tr_itr) => (
            <span className="text-end">
              {currencyFormat(tr_itr.fees.toFixed(2))}
            </span>
          ),
          sortable: true,
          width: 200,
          column_name: "fees",
          header_class: "text-end",
        },
        {
          name: "Amount",
          selector: (tr_itr) => (
            <span className="text-end">
              {currencyFormat(tr_itr.amount.toFixed(2))}
            </span>
          ),
          cell: (tr_itr) => balanceListing(tr_itr.amount, true),
          sortable: true,
          width: 200,
          column_name: "amount",
          header_class: "text-end",
        },
      ];
    } else {
      return [
        {
          name: "Date",
          selector: (tr_itr) => moment(tr_itr.date).utc().format("MM/DD/YYYY"),
          cell: (tr_itr) => moment(tr_itr.date).utc().format("MM/DD/YYYY"),
          sortable: true,
          width: 200,
          column_name: "date",
        },
        {
          name: "Description",
          selector: (tr_itr) =>
            descriptionListing(tr_itr.img, tr_itr.name, "description"),
          sortable: true,
          width: 600,
          column_name: "name",
        },
        {
          name: "Transaction Type",
          selector: (tr_itr) =>
            descriptionListing(
              tr_itr.transaction_type_img,
              tr_itr.transaction_type,
              "transaction_type"
            ),
          sortable: false,
          width: 300,
          column_name: "trans_type",
          isFilter: true,
        },
        {
          name: "Amount",
          className: "trans",
          selector: (tr_itr) => (
            <span className={`text-end ${tr_itr.amount > 0 && "cs-primary"}`}>
              {currencyFormat(tr_itr.amount.toFixed(2))}
            </span>
          ),
          sortable: false,
          width: 300,
          column_name: "amount",
          header_class: "text-end",
        },
        {
          name: `Balance`,
          className: "trans",
          selector: (tr_itr) => (
            <span className="text-end">
              {tr_itr.final_balance !== 0 &&
                currencyFormat(tr_itr.final_balance.toFixed(2))}
            </span>
          ),
          sortable: true,
          width: 300,
          column_name: "final_balance",
          header_class: "text-end",
        },
      ];
    }
  }

  const getAllTransactions = (
    offset,
    startId = 0,
    search_keyword = "",
    sort_name = "",
    sort_value = "",
    startDate,
    endDate,
    transactionTypeFilters
  ) => {
    if (["retirementaccount", "nonretirementbrokerage"].includes(category)) {
      const params = {
        user_id: id,
        ins_id: fetchInsId(),
        mask_id,
        offset: offset === "last" ? allTransactionsList.length : offset,
        limit: offset === "last" ? totalRow : apiDataLimit,
        search_keyword,
        column_name: sort_name,
        sort: sort_value,
      };
      if (startDate && endDate) {
        params["start_date"] = startDate;
        params["end_date"] = endDate;
      }
      dispatch(investmentTransactions(params))
        .unwrap()
        .then((response) => {
          setIsLoader(false);
          if (offset === 0) {
            setAllTransactionsList([...response.trasactions]);
            setTransactionsList(
              [...response.trasactions].slice(startId, startId + rowsPerPage)
            );
          } else {
            setAllTransactionsList([
              ...allTransactionsList,
              ...response.trasactions,
            ]);
            setTransactionsList(
              [...allTransactionsList, ...response.trasactions].slice(
                startId,
                startId + rowsPerPage
              )
            );
          }
          setTotalRow(response.total);
          setFiltered(response.trasactions);
        })
        .catch((err) => {
          setIsLoader(false);
          console.log(err);
          return false;
        });
    } else {
      const params = {
        user_id: id,
        ins_id: fetchInsId(),
        mask_id,
        offset: offset === "last" ? allTransactionsList.length : offset,
        limit: offset === "last" ? totalRow : apiDataLimit,
        search_keyword,
        column_name: sort_name,
        sort: sort_value,
        transactionTypeFilters: transactionTypeFilters,
      };
      if (startDate && endDate) {
        params["start_date"] = startDate;
        params["end_date"] = endDate;
      }
      dispatch(transactionsGetAll(params))
        .unwrap()
        .then((response) => {
          setIsLoader(false);
          let transactionData =
            offset === 0
              ? [...response.trasactions]
              : [...allTransactionsList, ...response.trasactions];
          if (
            (params.column_name === "" &&
              params.sort === "" &&
              transactionTypeFilters.length === 0 &&
              (!search_keyword || search_keyword === "") &&
              !params.start_date &&
              !params.end_date) ||
            (params.column_name === "date" &&
              params.sort === "DESC" &&
              transactionTypeFilters.length === 0 &&
              (!search_keyword || search_keyword === "") &&
              !params.start_date &&
              !params.end_date)
          ) {
            transactionData.map((item, index, self) => {
              if (self.findIndex((t) => t.date === item.date) !== index) {
                item.final_balance = 0;
              }
              return item;
            });
          }
          if (offset === 0) {
            setAllTransactionsList([...transactionData]);
            setTransactionsList(
              [...transactionData].slice(startId, startId + rowsPerPage)
            );
            setTransactionTypeFilterList(response.transactionTypeFilters);
          } else {
            setAllTransactionsList([...transactionData]);
            setTransactionsList(
              [...transactionData].slice(startId, startId + rowsPerPage)
            );
            setTransactionTypeFilterList(response.transactionTypeFilters);
          }

          // rowStartIdx
          setTotalRow(response.total);
          setFiltered(response.trasactions);
        })
        .catch((err) => {
          setIsLoader(false);
          console.log(err);
          return false;
        });
    }
  };

  const fetchTransactionBreadCrumb = () => {
    dispatch(
      transaction_breadcrumb({
        mask_id: mask_id,
        user_id: id,
        institution_id: fetchInsId(),
      })
    )
      .unwrap()
      .then((response) => {
        setBreadcrumb({
          category: response.category,
          sub_category: response.sub_category,
          institute: response.institute,
          main_category: response.main_category,
          category_url: response.category_url,
        });
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };

  useEffect(() => {
    getAllTransactions(
      0,
      0,
      search,
      sort.name,
      sort.value,
      startDate,
      endDate,
      transactionTypeFilterActives
    );
    fetchTransactionBreadCrumb();
  }, []);

  useEffect(() => {
    if (!transactionsList || !transactionsList.length) {
      setDownloadButton(true);
    } else {
      setDownloadButton(false);
    }
  }, [transactionsList]);

  const handleSearch = (params) => {
    setSearch(params);
    onHandleSearchData(params);
  };

  const onHandleSearchData = useDebounce((params) => {
    settransactionTypeFilterActives([]);
    getAllTransactions(
      0,
      0,
      params,
      sort.name,
      sort.value,
      startDate,
      endDate,
      []
    );
    setRowStartIdx(0);
  }, 700);

  useEffect(() => {
    checkTransactionDate();
  }, [transDate]);

  function checkTransactionDate() {
    if (transDate.end !== "" && transDate.start !== "") {
      const startDate = moment_timezone(transDate.start)
        .tz("America/New_York")
        .toDate();
      const endDate = moment_timezone(transDate.end)
        .tz("America/New_York")
        .toDate();
      setStartDate(startDate);
      setEndDate(endDate);
      getAllTransactions(
        0,
        0,
        search,
        sort.name,
        sort.value,
        startDate,
        endDate,
        transactionTypeFilterActives
      );
      setRowStartIdx(0);
    }
  }

  const transactionDate = (params, type) => {
    type === "start"
      ? setTransDate({ ...transDate, start: params })
      : setTransDate({ ...transDate, end: params });
  };

  const checkCustomFilter = (event) => {
    setSelectCustomFilter(event);

    if (event === "Last Month") {
      const startDate = moment()
        .subtract(1, "months")
        .startOf("month")
        .toDate();
      const endDate = moment()
        .subtract(1, "months")
        .endOf("month")
        .startOf("day")
        .toDate();

      setStartDate(startDate);
      setEndDate(endDate);
      getAllTransactions(
        0,
        0,
        search,
        sort.name,
        sort.value,
        startDate,
        endDate,
        transactionTypeFilterActives
      );
      setRowStartIdx(0);
    } else if (event === "All data" || event === "View All") {
      setStartDate(null);
      setEndDate(null);
      getAllTransactions(
        0,
        0,
        search,
        sort.name,
        sort.value,
        null,
        null,
        transactionTypeFilterActives
      );
      setRowStartIdx(0);
    } else if (event === "Year to date") {
      const startDate = moment_timezone()
        .tz("America/New_York")
        .subtract(1, "years")
        .toDate();
      const endDate = moment_timezone().tz("America/New_York").toDate();
      setStartDate(startDate);
      setEndDate(endDate);
      getAllTransactions(
        0,
        0,
        search,
        sort.name,
        sort.value,
        startDate,
        endDate,
        transactionTypeFilterActives
      );
      setRowStartIdx(0);
    } else if (event === "Last Quarter") {
      const startDate = moment_timezone()
        .tz("America/New_York")
        .subtract(3, "months")
        .startOf("quarter")
        .toDate();
      const endDate = moment_timezone()
        .tz("America/New_York")
        .subtract(3, "months")
        .endOf("quarter")
        .toDate();
      setStartDate(startDate);
      setEndDate(endDate);
      getAllTransactions(
        0,
        0,
        search,
        sort.name,
        sort.value,
        startDate,
        endDate,
        transactionTypeFilterActives
      );
      setRowStartIdx(0);
    }
  };

  const onPageChange = (idx, state) => {
    if (allTransactionsList.length >= idx + rowsPerPage) {
      if (state === "next") {
        setTransactionsList(
          allTransactionsList.slice(
            idx + rowsPerPage,
            idx + rowsPerPage + rowsPerPage
          )
        );
      } else {
        setTransactionsList(allTransactionsList.slice(idx, idx + rowsPerPage));
      }
    } else {
      getAllTransactions(
        allTransactionsList.length,
        idx,
        search,
        sort.name,
        sort.value,
        startDate,
        endDate,
        transactionTypeFilterActives
      );
    }
    if (state === "next") {
      setRowStartIdx(idx + rowsPerPage);
    } else {
      setRowStartIdx(idx);
    }
  };

  const onSort = (column) => {
    if (!column.sortable) {
      return false;
    }
    if (sort && sort.name && sort.name === column.column_name) {
      if (sort.value === "ASC") {
        setSort({
          ...sort,
          value: "DESC",
        });
        getAllTransactions(
          0,
          0,
          search,
          sort.name,
          "DESC",
          startDate,
          endDate,
          transactionTypeFilterActives
        );
        setRowStartIdx(0);
      } else {
        setSort({
          name: "",
          value: "",
        });
        getAllTransactions(
          0,
          0,
          search,
          "",
          "",
          startDate,
          endDate,
          transactionTypeFilterActives
        );
        setRowStartIdx(0);
      }
    } else {
      setSort({
        name: column.column_name,
        value: "ASC",
      });
      getAllTransactions(
        0,
        0,
        search,
        column.column_name,
        "ASC",
        startDate,
        endDate,
        transactionTypeFilterActives
      );
      setRowStartIdx(0);
    }
  };

  const allColumnsData = columnListing();

  return (
    <React.Fragment>
      {props.is_loader.assets === true ||
      props.is_loader.liabilities === true ? (
        <SkeletonTransactions />
      ) : (
        <section className="transaction-main-section">
          <Container fluid>
            <div className="cs-section-topbar">
              <BreadCrumHeader breadcrumb={breadcrumb} />
              <div className="cs-title">
                <h1 className="default-semi-bold-h1 cs-neutral-100">
                  Transactions
                </h1>
                {lastSyncDate && (
                  <span className="default-regular-body-text-m cs-neutral-80">
                    Last sync: {lastSyncDate}
                  </span>
                )}
              </div>
            </div>
            {isLoader ? (
              <CapsyncLoader />
            ) : (
              <React.Fragment>
                <SearchFilter
                  checkCustomFilter={checkCustomFilter}
                  transactionDate={transactionDate}
                  selectCustomFilter={selectCustomFilter}
                  mask_id={mask_id}
                  transDate={transDate}
                  handleSearch={handleSearch}
                  search={search}
                  breadcrumb={breadcrumb}
                  editAccessUser={props.editAccessUser}
                  startDate={startDate}
                  endDate={endDate}
                  isInvestTransactions={[
                    "retirementaccount",
                    "nonretirementbrokerage",
                  ].includes(category)}
                  downloadButton={downloadButton}
                  setDownloadButton={setDownloadButton}
                />

                {(transactionsList && transactionsList.length > 0) ||
                transactionTypeFilterActives.length ? (
                  <React.Fragment>
                    <div className="plaid-transactions-table">
                      <Table className="cs-table">
                        <thead className="cs-thead">
                          <tr>
                            {allColumnsData &&
                              allColumnsData.length > 0 &&
                              allColumnsData.map((column, i) => (
                                <th
                                  className={`default-medium-sub-heading-m ${
                                    column.header_class
                                      ? column.header_class
                                      : ""
                                  }`}
                                  width={column.width}
                                  key={i}
                                  onClick={() => onSort(column)}
                                >
                                  <div
                                    className={`sorting-icon ${
                                      column.sortable && "cursor-pointer"
                                    }`}
                                  >
                                    {column.name}
                                    {!search &&
                                      column.isFilter &&
                                      column.isFilter === true &&
                                      statusFilter}
                                    {sort &&
                                      sort.name === column.column_name && (
                                        <span className="cs-neutral-100">
                                          <CapsyncIcon
                                            title="sort-outlined"
                                            size="20"
                                          />
                                        </span>
                                      )}
                                  </div>
                                </th>
                              ))}
                          </tr>
                        </thead>
                        <tbody className="cs-tbody default-regular-sub-heading-m">
                          {transactionsList &&
                            transactionsList.length > 0 &&
                            transactionsList.map((row, i) => (
                              <tr key={i}>
                                {allColumnsData &&
                                  allColumnsData.length > 0 &&
                                  allColumnsData.map((column, j) => (
                                    <td width={column.width} key={j}>
                                      {column.selector(row)}
                                    </td>
                                  ))}
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                    <div className="cs-custom-pagination">
                      <div className="cs-common-add-dropdown">
                        <span className="default-light-body-text-m cs-neutral-80">
                          Rows per page:
                        </span>
                        <Dropdown>
                          <Dropdown.Toggle className="default-light-body-text-m cs-neutral-80">
                            {dropdownDisplay}
                            <CapsyncIcon
                              title="chevron-down-outlined"
                              size="12"
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu onSelect={(e) => console.log(e)}>
                            {paginationDropdownItems.map((noOfRowsPerPage) => {
                              return (
                                <Dropdown.Item
                                  className={
                                    noOfRowsPerPage === dropdownDisplay &&
                                    "cs-selected-dropdown"
                                  }
                                  onClick={() =>
                                    handlePaginationDropdownClick(
                                      noOfRowsPerPage
                                    )
                                  }
                                  value={noOfRowsPerPage}
                                >
                                  {noOfRowsPerPage}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="default-light-body-text-m cs-neutral-80">
                        <p>
                          {rowStartIdx + 1} -{" "}
                          {rowStartIdx +
                            currentNumberOfRows(
                              rowStartIdx,
                              rowsPerPage,
                              totalRow
                            )}{" "}
                          of {totalRow}
                        </p>
                      </div>
                      <div className="cs-pagination-arrow">
                        <Button
                          onClick={handleFirstPageClick}
                          disabled={rowStartIdx === 0}
                          className={
                            rowStartIdx === 0
                              ? "cs-neutral-50"
                              : "cs-neutral-100"
                          }
                        >
                          <CapsyncIcon
                            title="chevron-left-skip-outlined"
                            size="18"
                          />
                        </Button>
                        <Button
                          onClick={() =>
                            onPageChange(rowStartIdx - rowsPerPage)
                          }
                          disabled={rowStartIdx === 0}
                          className={
                            rowStartIdx === 0
                              ? "cs-neutral-50"
                              : "cs-neutral-100"
                          }
                        >
                          <CapsyncIcon
                            title="chevron-left-outlined"
                            size="18"
                          />
                        </Button>
                        <Button
                          onClick={() => onPageChange(rowStartIdx, "next")}
                          className={
                            !(rowStartIdx + rowsPerPage < totalRow)
                              ? "cs-neutral-50"
                              : "cs-neutral-100"
                          }
                          disabled={!(rowStartIdx + rowsPerPage < totalRow)}
                        >
                          <CapsyncIcon
                            title="chevron-right-outlined"
                            size="18"
                          />
                        </Button>
                        <Button
                          onClick={handleLastPageClick}
                          className={
                            !(rowStartIdx + rowsPerPage < totalRow)
                              ? "cs-neutral-50"
                              : "cs-neutral-100"
                          }
                          disabled={!(rowStartIdx + rowsPerPage < totalRow)}
                        >
                          <CapsyncIcon
                            title="chevron-right-skip-outlined"
                            size="18"
                          />
                        </Button>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <span className="cs-common-no-record">
                    {" "}
                    There are no records to display{" "}
                  </span>
                )}
              </React.Fragment>
            )}
          </Container>
        </section>
      )}
    </React.Fragment>
  );
};

export default Transactions;
