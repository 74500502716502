import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useFormik } from "formik";
import { clearMessage } from "../../slices/message";
import { forgotpassword } from "../../slices/auth";
import BackToSignin from "./BackToSignin";
import LeftPart from "./LeftPart";
import CapsyncIcon from "../../commonComponent/CapsyncIcon";
import AuthHead from "./AuthHead";
import { checkDomainAccept } from "../../util/common";
import { uniValidation } from "../individual/liabilities/categoryForms/validationSchema";


const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [actionDisabled, setActionDisabled] = useState(false);

  const { message } = useSelector((state) => state.message);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object().shape({
    email: yup.string()
      // .matches(/^\S*$/, "Whitespace is not allowed")
      .required(uniValidation.email.required).test("is-not-only-spaces", uniValidation.email.required, (value) => {
        return value.trim() !== "";
      })
      .email(uniValidation.email.validEmail)
      .test('no-more-than-30-before-at', uniValidation.email.validEmail, value =>
        value ? value.split('@')[0].length <= 30 : true
      )
      .test('at-least-1-character-before-at', 'Enter at least 1 character before @', value =>
        value ? value.split('@')[0].length > 0 : true
      )
      .test('custom-email-format', uniValidation.email.validEmail, value => {
        return value ? uniValidation.email.allowedFormat.test(value) : true
      })
      .test('domain-acceptance', uniValidation.email.validEmail, value => {
        return checkDomainAccept(value);
      }),
  })


  const onSubmit = async (formValue) => {
    dispatch(clearMessage());
    const { email } = formValue;
    setLoading(true);
    setActionDisabled(true)
    try {
      const response = await dispatch(forgotpassword({ email })).unwrap()
      if (response.code === 200) {
        setActionDisabled(false)
        toast.success("We have emailed you a password reset link.");
        setLoading(true);
        navigate("/");
      } else {
        setActionDisabled(false)
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      console.log('error', error)
      setActionDisabled(false)
    }
  }

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: onSubmit
    });

  return (
    <>
      <section className="auth-section">
        <Container fluid>
          <Row className="align-items-center">
            <Col lg={6}>
              <LeftPart
                page="forgotpassword"
                img="auth-image.svg"
                title="Your New Immersive Digital Finance Hub."
                text="Sync your real-time digital Personal Financial Statement with CapSync."
              />
            </Col>
            <Col lg={6}>
              <Row>
                <Col>
                  <div className="auth-right-section">
                    <div>
                      <div>
                        <AuthHead title="Forgot password" />
                        <p className="cs-neutral-80 default-regular-h5">
                          Don't worry! Enter your email address below and we will email you a link to reset your password.
                        </p>
                      </div>
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col lg={12}>
                            <Form.Group className="cs-form-group">
                              <Form.Control
                                autoComplete="off"
                                type="text"
                                placeholder="Email address"
                                name="email"
                                onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                                value={values.email}
                                className={values.email && "cs-input-field-active"}
                                onChange={(e) => { setFieldValue("email", e.target.value); dispatch(clearMessage()) }}
                              // isInvalid={!!errors.email}
                              // isValid={touched.email && !errors.email}
                              // onBlur={handleBlur}
                              />
                              {/* <Form.Control.Feedback type="invalid"> */}

                              {errors.email && touched.email ? (
                                <span className="form-error-msg default-light-body-text-s cs-danger">
                                  {errors.email}
                                </span>
                              ) : null}
                              {/* </Form.Control.Feedback> */}
                            </Form.Group>
                          </Col>
                        </Row>
                        {message && (
                          <div className="cs-msg default-light-body-text-m cs-danger">
                            <span className="icon">
                              <CapsyncIcon title="declined-outlined" />
                            </span>
                            <span className="txt">{message.message || message}</span>
                          </div>
                        )}
                        <Button
                          type="submit"
                          className="cs-btn-primary lg-btn default-regular-h5"
                          disabled={actionDisabled}
                        >
                          Reset password
                        </Button>
                        <BackToSignin />
                      </Form>
                    </div>
                    <div>
                      <span className="auth-main-link default-regular-body-text-m">
                        New to CapSync?{" "}
                        <Link
                          className="cs-primary"
                          to="/signup"
                        >
                          Sign up
                        </Link>
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ForgotPassword;
