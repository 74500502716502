/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Row, Col, Button, Container, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CommonMethodsHeader from "./CommonMethodsHeader";
import commonNames from "../../../../../config/commonNames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { add_update_verification_methodId } from "../../../../../slices/verifyInvestor";
import UseFetchPermission from "../../../../../customHooks/UseFetchPermission";
import PermissionPrevent from "../../../../PermissionPrevent";
import CapsyncLoader from "../../../../../commonComponent/CapsyncLoader";
import SubMethodsList from "../SubMethodsList";
import WarningModal_VI from "../Modals/WarningModal_VI";
import { toast } from "react-toastify";
import SkeletonMethodsDashboard from "./SkeletonMethodsDashboard";
import ConfirmationModel from "../Modals/ConfirmationModel";

const IndividualMethods = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fetchUserPermission } = UseFetchPermission();
  const [showConfirmation, setShowConfirmation] = useState(false)


  const Vi_Location = useLocation();
  let unique_string = Vi_Location.pathname.split("/").slice(-1).toString();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleConfirmationModal = () => {
    setShowConfirmation(true);
  }

  // const [enableLoader, setEnableLoader] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  // const [selectInverstorType, setSelectInverstorType] = useState("Select Option");

  const GoToSubMethods = (type, methodID) => {
    // if (selectInverstorType !== "Select Option" ){
    // setEnableLoader(true);
    dispatch(
      add_update_verification_methodId({
        unique_rowID: unique_string,
        verification_method_id: methodID,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setTimeout(() => {
            navigate(
              `/individual/verifyinvestor/accreditedInvestor/${type}/${unique_string}/${methodID}`
            );
            // setEnableLoader(false);
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
        // setEnableLoader(false);
      });
    // } else {
    //   toast.error("Select an investor type.")
    // }

  };



  return (
    <>

      <section className="accreditation-main-section accreditation-inner-section">
        {/* {enableLoader ? (
            <CapsyncLoader />
          ) : ( */}
        <Container fluid>
          {props.is_loader.assets === true || props.is_loader.liabilities === true ? < SkeletonMethodsDashboard investorType="individualMethod" /> :
            <>
              <CommonMethodsHeader investorType="individualMethod"
              // selectInverstorType={selectInverstorType} setSelectInverstorType={setSelectInverstorType} //code for selectInverstorType
              />
              <div className="verification-method-box">
                <div className="accredited-method">
                  <Row>
                    <Col lg={12}>
                      <span className="cs-neutral-100 default-medium-sub-heading-m">Verification Method</span>
                    </Col>
                  </Row>
                </div>
                {/* method-11 is added in new design  */}
                <div className="vi-innerArea">
                  <Row>
                    <Col xl={11} md={9}>
                      <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={13} /></div>

                    </Col>
                    <Col xl={1} md={3} >
                      <Button
                        // className="cs-btn-primary sm-btn default-regular-h5"
                        className="cs-btn-primary sm-btn default-regular-h5"
                        onClick={() => GoToSubMethods("individuals", "13")}
                      >
                        {commonNames.Select}
                      </Button>
                    </Col>
                  </Row>
                </div>

                <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>
                <div className="vi-innerArea">
                  <Row>
                    <Col xl={11} md={9}>
                      <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={1} /></div>

                    </Col>
                    <Col xl={1} md={3} >
                      <Button
                        // className="cs-btn-primary sm-btn default-regular-h5"
                        className="cs-btn-primary sm-btn default-regular-h5"
                        onClick={() => GoToSubMethods("individuals", "1")}
                      >
                        {commonNames.Select}
                      </Button>
                    </Col>
                  </Row>
                </div>

                <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                <div className="vi-innerArea">
                  <Row>
                    <Col xl={11} md={9}>
                      <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={2} /></div>
                    </Col>
                    <Col xl={1} md={3} >

                      <Button
                        className="cs-btn-primary sm-btn default-regular-h5"
                        onClick={() => GoToSubMethods("individuals", "2")}
                      >
                        {commonNames.Select}
                      </Button>
                    </Col>
                  </Row>
                </div>

                <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                <div className="vi-innerArea">
                  <Row>
                    <Col xl={11} md={9}>
                      <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={3} /></div>
                    </Col>
                    <Col xl={1} md={3} >

                      <Button
                        className="cs-btn-danger sm-btn default-regular-h5"
                        onClick={() => GoToSubMethods("individuals", "3")}
                      >
                        {commonNames.Select}
                      </Button>
                    </Col>
                  </Row>
                </div>

                <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                <div className="vi-innerArea">
                  <Row>
                    <Col xl={11} md={9}>
                      <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={37} /></div>
                    </Col>
                    <Col xl={1} md={3} >

                      <Button
                        className="cs-btn-primary sm-btn default-regular-h5"
                        onClick={() => GoToSubMethods("individuals", "37")}
                      >
                        {commonNames.Select}
                      </Button>
                    </Col>
                  </Row>
                </div>

                <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                <div className="vi-innerArea">
                  <Row>
                    <Col xl={11} md={9}>
                      <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={38} /></div>
                    </Col>
                    <Col xl={1} md={3} >

                      <Button
                        className="cs-btn-primary sm-btn default-regular-h5"
                        onClick={() => GoToSubMethods("individuals", "38")}
                      >
                        {commonNames.Select}
                      </Button>
                    </Col>
                  </Row>
                </div>

                <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                <div className="vi-innerArea">
                  <Row>
                    <Col xl={11} md={9}>
                      <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={15} /></div>

                    </Col>
                    <Col xl={1} md={3} >
                      <Button
                        className="cs-btn-danger sm-btn default-regular-h5"
                        onClick={() => setShowWarning(true)}
                      >
                        {commonNames.Select}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
              <p className="default-regular-sub-heading-m cs-neutral-80">
                If you are not an accredited investor or wish to cancel this
                verification request, &nbsp;
                <Link
                  onClick={handleConfirmationModal}
                  className="vi-link-color "
                >
                  click here
                </Link>
              </p>
            </>
          }
        </Container>
        {/* )} */}
      </section>

      <WarningModal_VI showWarning={showWarning} onClose={() => setShowWarning(false)} onButtonClick={() => GoToSubMethods("individuals", "15")} />
      <ConfirmationModel
        setShowConfirmation={setShowConfirmation}
        showConfirmation={showConfirmation}

      />
    </>
  );
};

export default IndividualMethods;
