import React, { useState } from "react";
import {
  Button,
  Modal,
  Row,
  Col,
  ButtonGroup,
  ToggleButton,
  CloseButton,
} from "react-bootstrap";
import { requestSendLink } from "../../../slices/requests";
import { useDispatch } from "react-redux";

const PfsPreviewModal = ({
  previewModal,
  shareEmail,
  changeToPreviewModal,
  successchangeState,
  errorchangeState,
}) => {
  const dispatch = useDispatch();
  const [radioValue, setRadioValue] = useState("1");

  const checkFetchRadio = (params) => {
    setRadioValue(params);
  };

  const radios = [
    { name: "PFS Summary", value: "1" },
    { name: "PFS Worksheet", value: "2" },
  ];

  function pfsAccessMsg(params) {
    if (params !== "") {
      let newContent = "";
      newContent += params;
      let storeContent =
        newContent.substring(0, 1).toUpperCase() +
        newContent.substring(1, newContent.length) +
        "'s PFS";
      return storeContent;
    }
  }

  const sendShareLink = () => {
    dispatch(requestSendLink(shareEmail))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          successchangeState(true);
          changeToPreviewModal(false);
        }
      })
      .catch((err) => {
        errorchangeState(true);
      });
  };

  return (
    <>
      <Modal
        show={previewModal}
        onHide={!previewModal}
        aria-labelledby="example-custom-modal-styling-title"
        dialogClassName="modal-85w pfs-preview"
        centered
      >
        <Modal.Header className="pfs-preview-modal">
          <Modal.Title id="example-custom-modal-styling-title">
            <CloseButton onClick={() => changeToPreviewModal(false)} />
            <Row className="m-0">
              <Col className="p-0">
                <div className="heading-filter-d-flex-pfs">
                  <div className="pfs-modal-1">
                    <ButtonGroup className="manage-user-btn">
                      {radios.map((radio, index) => (
                        <ToggleButton
                          key={index}
                          id={`radio-${index}`}
                          type="radio"
                          variant={
                            index % 2
                              ? "manage-user-individual outline-danger"
                              : "manage-user-wealth-manager"
                          }
                          name="radio"
                          value={radio.value}
                          checked={radioValue === radio.value}
                          onChange={(e) =>
                            checkFetchRadio(e.currentTarget.value)
                          }
                        >
                          {radio.name}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </div>
                  <div className="pfs-modal-title">
                    <p className="welcome-message">
                      <span className="welcome-main-heading">
                        {pfsAccessMsg(shareEmail.displayName)}
                      </span>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pfs-preview-modal">
          <section className="dashbaord-main-section dashboard-assets-container gx-0">
            {/* {radioValue == 1 ? (
              <>
                <div className="pfs-preview-header">
                  <Row>
                    <Col xs={12} md={6} lg={12} xxl={6}>
                      <div className="view-pfs-modal">Name : {displayName}</div>
                    </Col>
                    <Col xs={12} md={6} lg={12} xxl={6}>
                      <div className="view-pfs-modal">Email : {userEmail}</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6} lg={12} xxl={6}>
                      <div className="view-pfs-modal">
                        Address : {address == null ? "-" : address}
                      </div>
                    </Col>
                    <Col xs={12} md={6} lg={12} xxl={6}>
                      <div className="view-pfs-modal">
                        Phone : {userPhoneNo}
                      </div>
                    </Col>
                  </Row>
                </div>
                <Row className="box-chart-table-division cs-assets-liabilities-mix m-0">
                  <Col
                    xs={12}
                    md={6}
                    lg={12}
                    xxl={6}
                    className="chart-table-top-ind s-box-chart-table-division"
                  >
                    <div className="assetsDiv">
                      <div className="pfs-assets">
                        <Link to={"/individual/assets"}>
                          <p
                            id="ind-assest-heading"
                            className="commonTable-heading text-dark"
                          >
                            Assets{"\u00A0"}
                            <Image
                              className="sc-mt-12 cursor_pointer"
                              onClick={() => setShowSecondModal(false)}
                              src={require("../../../assets/icons/svg/outlined/redirect-outlined.svg")}
                            />
                          </p>
                        </Link>
                        <Table responsive className="table dash-table-width">
                          <thead>
                            <tr>
                              <th className="fz-20-px td-width">Category</th>
                              <th className="fz-20-px">
                                <div className="text-md-end pr-30-px">
                                  Balance
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Link
                                  to="/individual/assets/cashonhand"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Cash On Hands & In Banks
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataAsset &&
                                          dataAsset.assets_category_total &&
                                          Number(
                                            dataAsset_filter_wise.cashonhandTotal
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/assets/cashonhand"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="fz-20-px">
                                <Link
                                  to="/individual/assets/savingaccount"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Savings Accounts
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataAsset &&
                                          dataAsset.assets_category_total &&
                                          Number(
                                            dataAsset_filter_wise.savingsAccount
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/assets/savingaccount"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="fz-20-px">
                                <Link
                                  to="/individual/assets/retirementaccount"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  IRA, 401k, SEP & Other Retirement Accounts
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataAsset &&
                                          dataAsset.assets_category_total &&
                                          Number(
                                            dataAsset_filter_wise.otherRetirement
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/assets/retirementaccount"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="fz-20-px">
                                <Link
                                  to="/individual/assets/accountnotesreceviable"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Accounts & Notes Receivable
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataAsset &&
                                          dataAsset.assets_category_total &&
                                          Number(
                                            dataAsset_filter_wise.notesReceivable
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/assets/accountnotesreceviable"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="fz-20-px">
                                <Link
                                  to="/individual/assets/lifeinsurance"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Life Insurance - Cash Surrender Value Only
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataAsset &&
                                          dataAsset.assets_category_total &&
                                          Number(
                                            dataAsset_filter_wise.lifeInsurance
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />

                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/assets/lifeinsurance"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Link
                                  to="/individual/assets/nonretirementbrokerage"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Stocks & Bonds (Non-Retirement Brokerage
                                  Accounts)
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataAsset &&
                                          dataAsset.assets_category_total &&
                                          Number(
                                            dataAsset_filter_wise.stocksBonds
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />

                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/assets/nonretirementbrokerage"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Link
                                  to="/individual/assets/business"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Businesses
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataAsset &&
                                          dataAsset.assets_category_total &&
                                          Number(
                                            dataAsset_filter_wise.businesses
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/assets/business"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="fz-20-px">
                                <Link
                                  to="/individual/assets/automobile"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Automobiles
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataAsset &&
                                          dataAsset.assets_category_total &&
                                          Number(
                                            dataAsset_filter_wise.automobileTotal
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/assets/automobile"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="fz-20-px">
                                <Link
                                  to="/individual/assets/personalproperty"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Personal Property
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataAsset &&
                                          dataAsset.assets_category_total &&
                                          Number(
                                            dataAsset_filter_wise.personalProperty
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/assets/personalproperty"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="fz-20-px">
                                <Link
                                  to="/individual/assets/realestate"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Real Estate
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataAsset &&
                                          dataAsset.assets_category_total &&
                                          Number(
                                            dataAsset_filter_wise.realEstate
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />

                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/assets/realestate"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="fz-20-px">
                                <Link
                                  to="/individual/assets/otherassets"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Others Assets
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataAsset &&
                                          dataAsset.assets_category_total &&
                                          Number(
                                            dataAsset_filter_wise.otherAssets
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/assets/otherassets"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr className="total-assets-balance total-assets-bg-gray">
                              <th>Total Assets</th>
                              <th className="ind-value-position">
                                <div>$&nbsp;</div>
                                <div className="mr-30-px">
                                  <NumberFormat
                                    value={Number(assetGrandTotal).toFixed(2)}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                </div>
                              </th>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                      <div className="total-assets-networth d-flex">
                        <div>
                          <p className="">  {commonNames.NetWorth}</p>
                        </div>
                        <div>
                          <p className="">
                            $&nbsp;
                            <NumberFormat
                              value={Number(
                                assetGrandTotal - liabilitiyGrandTotal
                              ).toFixed(2)}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    md={6}
                    lg={12}
                    xxl={6}
                    className="chart-table-ind sl-box-chart-table-division"
                  >
                    <div className="cap-liabilitie-division">
                      <div className="pfs-liabilities">
                        <Link
                          to={"/individual/liabilities"}
                          onClick={() => setShowSecondModal(false)}
                        >
                          <p
                            id="cap-liabilities-heading"
                            className="commonTable-heading text-dark"
                          >
                            Liabilities{" "}
                            <Image
                              className="sc-mt-12 cursor_pointer"
                              src={require("../../../assets/icons/svg/outlined/redirect-outlined.svg")}
                            />
                          </p>
                        </Link>
                        <Table responsive className="table dash-table-width">
                          <thead>
                            <tr>
                              <th className="fz-20-px td-width">Category</th>
                              <th className="fz-20-px">
                                <div className="text-md-end pr-30-px">
                                  Balance
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Link
                                  to="/individual/assets/lifeinsurance"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Loan on Life Insurance
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataAsset &&
                                          Number(
                                            dataAsset_filter_wise.lifeInsuranceLoan
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/assets/lifeinsurance"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Link
                                  to="/individual/assets/automobile"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Installment Account (Auto)
                                  <span className="liability-subtext">
                                    $&nbsp;
                                    <NumberFormat
                                      value={Number(paymentInstallAuto).toFixed(
                                        2
                                      )}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                    /Mo
                                  </span>
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={Number(balanceDueAuto).toFixed(
                                          2
                                        )}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />

                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/assets/automobile"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Link
                                  to="/individual/liabilities/accountpayable"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Accounts Payable
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataLiability &&
                                          dataLiability.liability_category_total &&
                                          Number(
                                            dataLiability_filter_wise.accountPayable
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/liabilities/accountpayable"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Link
                                  to="/individual/liabilities/notespayabletobanksandothers"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Notes Payable to Banks and Others
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataLiability &&
                                          dataLiability.liability_category_total &&
                                          Number(
                                            dataLiability_filter_wise.notesPayable
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />

                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/liabilities/notespayabletobanksandothers"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Link
                                  to="/individual/liabilities/installmentsaccount"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Installment Account (Other)
                                  <span className="liability-subtext">
                                    $&nbsp;
                                    <NumberFormat
                                      value={Number(
                                        dataLiability &&
                                          dataLiability.installmentAccPayment
                                      ).toFixed(2)}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                    /Mo
                                  </span>
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataLiability &&
                                          dataLiability.liability_category_total &&
                                          Number(
                                            dataLiability_filter_wise.installmentAcc
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />

                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/liabilities/installmentsaccount"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Link
                                  to="/individual/liabilities/unpaidtaxes"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Unpaid taxes
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataLiability &&
                                          dataLiability.liability_category_total &&
                                          Number(
                                            dataLiability_filter_wise.unapidTextes
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />

                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/liabilities/unpaidtaxes"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Link
                                  to="/individual/liabilities/mortgagesonrealestate"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Mortgages on Real Estate
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataLiability &&
                                          dataLiability.liability_category_total &&
                                          Number(
                                            dataLiability_filter_wise.mortgageOnRealestate
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />

                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/liabilities/mortgagesonrealestate"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Link
                                  to="/individual/liabilities/otherliabilities"
                                  className="custom-table-element"
                                  onClick={() => setShowSecondModal(false)}
                                >
                                  Other Liabilities
                                </Link>
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={
                                          dataLiability &&
                                          dataLiability.liability_category_total &&
                                          Number(
                                            dataLiability_filter_wise.otherLiabilities
                                          ).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />

                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual/liabilities/otherliabilities"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr className="total-assets-balance total-assets-bg-gray ">
                              <th className="total-assets-balance total-assets-bg-gray">
                                Total Liabilities
                              </th>
                              <th className="ind-value-position">
                                <div>$</div>
                                <div className="mr-30-px">
                                  <NumberFormat
                                    value={Number(liabilitiyGrandTotal).toFixed(
                                      2
                                    )}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                  />
                                </div>
                              </th>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                      <div className="contigent-liabilities-pfs">
                        <p
                          id="cap-liabilities-heading"
                          className="commonTable-heading liabiliti-heaing-terms"
                        >
                          Contingent Liabilities{" "}
                          <span className="pr-20-px">
                            <small>Values not inclusive in net worth*</small>
                          </span>
                        </p>
                        <Table responsive className="table dash-table-width">
                          <thead>
                            <tr>
                              <th className="fz-20-px td-width">Category</th>
                              <th className="fz-20-px">
                                <div className="text-md-end pr-30-px">
                                  Balance
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="fz-20-px">
                                As Endorser or Co-Maker
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={Number(
                                          getContigentValues &&
                                            getContigentValues.co_maker_balance
                                        ).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td className="fz-20-px">
                                {commonNames.LegalClaims}
                              </td>
                              <td className="fz-20-px">
                                <div className="ind-value-position">
                                  <div>$</div>
                                  <div>
                                    <span>
                                      <NumberFormat
                                        value={Number(
                                          getContigentValues &&
                                            getContigentValues.judgments_balance
                                        ).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                      />
                                      <span className="ml-10-px">
                                        <Link
                                          to="/individual"
                                          className="custom-table-element"
                                          onClick={() =>
                                            setShowSecondModal(false)
                                          }
                                        >
                                          <BsChevronRight className="bs-chevron-right" />
                                        </Link>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Col>
                </Row>
                <br />
                <div className="pfs-preview-footer">
                  <Row className="pfs-preview-footer-desc">
                    <Col
                      lg={12}
                      md={12}
                      sm={6}
                      xl={12}
                      className="pfs-preview-footer-p"
                    >
                      <p className="cap-primary-color">
                        I authorize Lender and/or assigns to make inquiries as
                        necessary to verify the accuracy of the statements made
                        and to determine my creditworthiness. I certify the
                        above and the statements contained in the attachments
                        are true and accurate as of the stated date(s). These
                        statements are made for the purpose of either obtaining
                        loan or guaranteeing a loan. I understand FALSE
                        statements may result in forfeiture of benefits and
                        possible prosecution by the U.S. Attorney General
                        (Reference 18 U.S.C. 1001).
                      </p>
                    </Col>
                    <Row className="pfs-preview-footer-row-name">
                      <Col>
                        Name
                        <span className="pfs-preview-footer-displayname">
                          {displayName}
                        </span>
                      </Col>
                      <Col>
                        Name
                        <span className="pfs-preview-footer-displayname">
                          {displayName}
                        </span>
                      </Col>
                    </Row>
                    <Row className="pfs-preview-footer-row-signature">
                      <Col>
                        <Row>
                          <Col>
                            Signature
                            <span className="pfs-preview-footer-name">
                              {displayName}
                            </span>
                          </Col>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>Date</Form.Label>
                              <DatePicker
                                className="pfs-datepicker"
                                calendarIcon={<BsCalendarWeek />}
                                onChange={setFirstDate}
                                value={firstDate}
                                clearIcon={null}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            Signature
                            <span className="pfs-preview-footer-name">
                              {displayName}
                            </span>
                          </Col>
                          <Col>
                            <Form.Group className="mb-3">
                              <Form.Label>Date</Form.Label>
                              <DatePicker
                                className="pfs-datepicker"
                                calendarIcon={<BsCalendarWeek />}
                                onChange={setSeondDate}
                                value={seondDate}
                                clearIcon={null}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Row>
                </div>
              </>
            ) : (
              <section className="assets-main-section">
                <SharePfsCategoryTable
                // closeModal={() => setShowSecondModal(false)}
                />
              </section>
            )} */}
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cs-pri-sm-btn me-3"
            onClick={() => sendShareLink()}
          >
            Send
          </Button>
          <Button
            className="cs-sec-sm-btn ms-3"
          // onClick={() => setShowSecondModal(false)}
          >
            Decline
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PfsPreviewModal;
