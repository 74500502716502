import React from "react";
import { Modal, Image, CloseButton } from "react-bootstrap";

const MessageModal = ({ show, onClose }) => {
  return (
    <>
      <Modal
        dialogClassName="wm-modal-doc wm-company-delete-pop "
        className="status-msg-popup"
        show={show}
        onHide={onClose}
        centered
      >
        <Modal.Header>
          <Modal.Title className="m-auto">Signature Request</Modal.Title>
          <CloseButton onClick={onClose}></CloseButton>
        </Modal.Header>
        <Modal.Body className="wm-delete-modal-body">
          <div className="wm-company-delete-content">
            <span className="wm-company-delete-icon">
              <Image
                src={require("../../../../assets/images/other/success-validation.svg")}
                alt="popup_logout"
                className="action-icon"
              />
            </span>
            <p className="wm-company-deleteicon-msg">
              Please check your registered email inbox to add the signature.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MessageModal;
