import React, { useState } from "react";
import moment from "moment";
import {
  Button, Col,
  Container, Dropdown,
  Modal, Row
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import userDetails from "../../../customHooks/UseFetchDetails";
import { customLabelLength } from "../../../customHooks/adjustLength";

import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import CapsyncPFSFilter from "../../../commonComponent/CapsyncPFSFilter";
import FinanceTable from "../dashboard/FinanceTable";
import NetWorthDashboard from "../dashboard/NetWorthDashboard";
import FinanceCategoryTables from "./FinanceCategoryTables";
import ExternalAttachments from "./ExternalAttachments";
import { externalAttachmentSharePfs } from "../../../slices/sharePfs";

const MyPFSModal = props => {
  const { dataLiability, paymentInstallAuto, dataAsset,
    getCategoryTotalLib, getCategoryTotal, setShowMyPFS, showMyPFS,
    toastMessage, pfsSection, onSharePFSTypeToggle
  } = props;

  const categoryType = [
    { name: "PFS Summary", value: "1" },
    { name: "PFS Worksheet", value: "2" },
    { name: "Attachments", value: "3" },
  ];
  const [myPFSType, setMyPFSType] = useState({ num: "1", name: "PFS Summary" });
  const [inputFields, setInpuFields] = useState([{ file: "", index_id: 0 }]);
  const [externalAttachment, setExternalAttachment] = useState([]);

  const userData = userDetails();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const current_user_token = user && user.token;
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }
  const changePFSType = (params, name) => {
    setMyPFSType({ name: name, num: params });
  };

  const changePFSTypeMobile = (params) => {
    setMyPFSType({
      num: categoryType.filter((x) => x.value === params)[0].value,
      name: categoryType.filter((x) => x.value === params)[0].name,
    })
  }

  //sending PFS from nav bar 
  const handleCloseMyPFS = (event) => {
    setShowMyPFS(false);
  }

  //After sending PFS ask for joint/individual
  const handleSendPFS = (event) => {
    event.preventDefault();
    setShowMyPFS(false);
    onSharePFSTypeToggle(true);
  }
  //External attachments
  const addFields = () => {
    let fetchArr = [...inputFields];
    fetchArr.push({ statement: "", file: "", index_id: fetchArr.length });
    let extFetchArr = [...externalAttachment];
    extFetchArr.push({ files: "", index_id: extFetchArr.length });
    setExternalAttachment(extFetchArr);
    setInpuFields(fetchArr);
  };

  const removeInputField = (params) => {
    let externalAttach = [...externalAttachment];
    externalAttach.splice(params, 1);
    setExternalAttachment(externalAttach);
  };

  const attachmentUpload = () => {
    const formData = new FormData();

    externalAttachment.forEach((itr) => {
      formData.append("attachments", itr);
    });

    formData.append("user_id", id);

    dispatch(externalAttachmentSharePfs(formData))
      .unwrap()
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => { });
  };

  const changeDocumentInputVal = (params) => {
    let attachmentVal = [];
    for (let index = 0; index < params.length; index++) {
      attachmentVal.push(params[index]);
    }
    let externalAttachmentVal = [...externalAttachment, ...attachmentVal];
    setExternalAttachment(externalAttachmentVal);
  };

  const { fetchUserData: {
    displayName,
    email,
    phoneNo,
    address
  } = {} } = userData;

  // const assetGrandTotal = Number(
  //   dataAsset &&
  //   dataAsset.filter_wise_total &&
  //   dataAsset.filter_wise_total.filter_wise_total
  // );

  // const liabilitiyGrandTotal = Number(
  //   dataLiability &&
  //   dataLiability.filter_wise_total &&
  //   dataLiability.filter_wise_total.filter_wise_total
  // );
  const { newCategoryData } = useSelector((state) => state.category);


  const liabilitiyGrandTotal = Number(newCategoryData && newCategoryData.assets && newCategoryData.liabilities.grandTotal);
  const assetGrandTotal = Number(newCategoryData && newCategoryData.liabilities && newCategoryData.assets.grandTotal);

  return (
    <>
      <Modal
        show={showMyPFS}
        onHide={!showMyPFS}
        centered
        aria-labelledby="contained-modal-title-vcenter"
        className="share-pfs-modal"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <div className="pfs-preview-modal">
            <div className="buttons">
              <div className="for-des">
                <div className="cs-tabs cs-primary">
                  {categoryType.map((categoryType, index) => (
                    <Button
                      className={`cs-stroke-default-tab default-regular-h5 cs-tabs-height ${myPFSType.num === categoryType.value ? 'cs-stroke-active' : ''}`}
                      key={index}
                      id={`radio-${index}`}
                      type="radio"
                      variant={index % 2 ? "manage-user-individual" : "manage-user-wealth-manager"}
                      name="categoryType"
                      value={categoryType.value}
                      checked={myPFSType.num === categoryType.value}
                      onClick={(e) => changePFSType(e.currentTarget.value)}
                    >{categoryType.name}</Button>
                  ))}
                </div>
              </div>
              <div className="for-mob">
                <div className="pfs-preview-dropdown cs-common-add-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle className="default-semi-bold-h5">
                      {myPFSType.name}
                      <CapsyncIcon title="chevron-down-outlined" size="16" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="cs-pfs-menu cs-scroller">
                      {categoryType.map((optionValue, idx) => {
                        return (
                          <Dropdown.Item
                            key={idx}
                            className={`default-regular-h5 ${myPFSType.num === optionValue.value ? 'selected' : ''}`}
                            onClick={(e) => changePFSTypeMobile(optionValue.value)}
                          >
                            {optionValue.name}
                          </Dropdown.Item>
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <Modal.Title className="default-semi-bold-h1 cs-neutral-100 for-mob">
              My PFS
            </Modal.Title>
            <div className="cs-pfs-right">
              <Modal.Title className="default-semi-bold-h1 cs-neutral-100 for-des">
                My PFS
              </Modal.Title>
              {/* <div className="cs-view-pfs-filter">
                <CapsyncPFSFilter
                  assetTotal={getCategoryTotal}
                  liabilityTotal={getCategoryTotalLib}
                  individualUserData={userData}//previously it was individualUserData
                />
              </div> */}
            </div>
          </div>
        </Modal.Header>

        <div className="pfs-modal-scroll dashboard-main-section cs-scroller">
          <Modal.Body className="pfs-preview-modal">
            <section className="pfs-container">
              {myPFSType.num == 1 &&
                <Container fluid>
                  <div className="pfs-header">
                    <Row>
                      <Col lg={6}>
                        <div className="view-pfs-modal default-regular-sub-heading-m">
                          <p>Name:&nbsp;</p> {displayName}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="view-pfs-modal default-regular-sub-heading-m">
                          <p>Email:</p> &nbsp; {email}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="view-pfs-modal default-regular-sub-heading-m">
                          <p>Address:&nbsp;</p>
                          {/* {address === null ? "-" : customLabelLength(address, 45, 48)} */}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="view-pfs-modal default-regular-sub-heading-m">
                          <p>Phone:&nbsp;</p>{phoneNo}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="pfs-body">
                    <Row>
                      <Col lg={6}>
                        <div className="pfs-common-box finance-box">
                          <FinanceTable
                            type="assets"
                            // data={dataAsset.assets_category_total}
                            data={newCategoryData.assets.catgeorylist}
                            grandTotal={assetGrandTotal}
                            paymentInstallAuto={paymentInstallAuto}
                          />
                          <div className="for-des">
                            <NetWorthDashboard
                              netWorth={assetGrandTotal - liabilitiyGrandTotal}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="pfs-common-box">
                          <FinanceTable
                            type="liabilities"
                            // data={
                            //   dataLiability &&
                            //   dataLiability.liability_category_total
                            // }
                            data={newCategoryData.liabilities.catgeorylist}
                            grandTotal={liabilitiyGrandTotal}
                          />
                        </div>
                        <div className="for-mob">
                          <NetWorthDashboard
                            netWorth={assetGrandTotal - liabilitiyGrandTotal}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="pfs-footer default-regular-sub-heading-m cs-neutral-70">
                    <p>
                      I authorize Lender and/or assigns to make inquiries
                      as necessary to verify the accuracy of the
                      statements made and to determine my
                      creditworthiness. I certify the above and the
                      statements contained in the attachments are true and
                      accurate as of the stated date(s). These statements
                      are made for the purpose of either obtaining loan or
                      guaranteeing a loan. I understand FALSE statements
                      may result in forfeiture of benefits and possible
                      prosecution by the U.S. Attorney General (Reference
                      18 U.S.C. 1001).
                    </p>
                    <div className="pfs-signature-container">
                      <Row>
                        <Col lg={6}>
                          <div className="pfs-signature-box pfs-name-block">
                            <span className="default-regular-sub-heading-l cs-neutral-100">
                              Name
                            </span>
                            <span className="default-regular-sub-heading-s cs-neutral-100">
                              {displayName}
                            </span>
                          </div>
                          <div className="pfs-signature-bottom">
                            <div className="pfs-signature-box pfs-signature-block">
                              <span className="default-regular-sub-heading-l cs-neutral-100">
                                Signature
                              </span>
                              <span className="cs-signature-text cs-neutral-100"></span>
                            </div>

                            <div className="pfs-signature-box pfs-date-block">
                              <span className="default-regular-sub-heading-l cs-neutral-100">
                                Date
                              </span>
                              <span className="default-regular-sub-heading-s cs-neutral-100">
                                {moment(new Date()).format("MM/DD/YYYY")}
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Container>
              }

              {myPFSType.num == 2 && (
                <div className="assets-main-section">
                  <FinanceCategoryTables
                    closeModal={setShowMyPFS}
                    toastMessage={toastMessage}
                  />
                </div>)}

              {myPFSType.num == 3 && (
                <ExternalAttachments
                  addFields={addFields}
                  inputFields={inputFields}
                  ext_attachment={externalAttachment}
                  attachmentUpload={attachmentUpload}
                  removeInputField={removeInputField}
                  changeDocumentInputVal={changeDocumentInputVal}
                />
              )}
            </section>
          </Modal.Body>
        </div>

        <Modal.Footer>
          <div className="cs-modal-btn wm-req-shareicon-viewpdf">
            <Button
              className="cs-btn-secondary default-regular-h5 lg-btn"
              type="button"
              onClick={handleCloseMyPFS}
            > Cancel</Button>

            {pfsSection === "navBarSection" &&
              <Button
                className="cs-btn-primary default-regular-h5 lg-btn"
                type="button"
                onClick={handleSendPFS} //second modal will be closed & third modal will be open
              >Sign</Button>}

            {/* {userRole === 1 ? (
              <>
                {props.AllIndividuals != undefined ||
                  email == user_email ? (
                  <Button
                    className="cs-btn-primary default-regular-h5 lg-btn"

                    type="button"
                    onClick={handleModal} //second modal will be closed & third modal will be open
                  >
                    Sign
                  </Button>
                ) : (
                  <Button
                    className="cs-btn-primary default-regular-h5 lg-btn"

                    type="button"
                    onClick={handleOpen} //second modal will be closed & third modal will be open
                  >
                    {email != user_email ? "Send for Signature" : "Sign"}
                  </Button>
                )}
              </>
            ) : (
              <Button
                className="cs-btn-primary default-regular-h5 lg-btn"

                type="button"
                onClick={handleOpen} //second modal will be closed & third modal will be open
              >
                Send for Signature
              </Button>
            )} */}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
};

export default MyPFSModal;