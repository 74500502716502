import React from "react";
import { Image } from "react-bootstrap";
import DefaultImg from "./DefaultImg";

const ConnectedAccBlock = ({ imgpath_3, headerMsg }) => {
  return (
    <>
      <div className="cs-bank-images">
        <div className="cs-common-logo">
          <Image
            src={require("../../../../../assets/images/cap-icon-logo.svg").default}
            alt="loadergif"
            className="plaid-img-bank plaid-logo-circle"
          />
        </div>
        <Image
          src={require("../../../../../assets/images/other/plaid-sync.svg").default}
          alt="loadergif"
          className="plaid-arrow-width"
        />
        <div className="cs-common-logo">
          <DefaultImg params={imgpath_3} />
        </div>
      </div>
      <h3 className="cs-bank-text default-semi-bold-h4 cs-neutral-100">{headerMsg}</h3>
    </>
  );
};

export default ConnectedAccBlock;
