import React from 'react'
import Skeleton from 'react-loading-skeleton';
import {
    Row,
    Col,
    Form,
} from "react-bootstrap";
import { useSelector } from 'react-redux';
const SkeletonAccount = () => {

    const { user } = useSelector((state) => state.auth);
    let role = user && user.user_role.role;

    return (
        <div className="account-detail-box skeleton-account-box">
            <div className="profile-personal">
                <div className="profile">
                    <div className='skeleton-profile'><Skeleton width={96} height={96} circle /></div>
                    <div className="display-names">
                        <h2 className="default-semi-bold-h2 cs-neutral-100">
                            <Skeleton width={50} />
                        </h2>
                        <p className="default-regular-sub-heading-m cs-neutral-80">
                            <Skeleton width={180} />
                        </p>
                    </div>
                </div>
                <div className="skeleton-personal-information">
                    <h3 className="default-regular-h3 cs-neutral-100">
                        <Skeleton width={180} />
                    </h3>
                    <div className='for-des'>
                        <Skeleton width={100} height={40} />
                    </div>
                </div>
            </div>
            <>
                <Form className="personal-inform-form">
                    <Row>
                        <Col className="col" lg={6} md={6} sm={6}>
                            <div className='skeleton-forms'>
                                <span className='skeleton-label'> <Skeleton /></span>
                                <span className='skeleton-group'><Skeleton height={40} /></span>
                            </div>
                        </Col>
                        <Col className="col" lg={6} md={6} sm={6}>
                            <div className='skeleton-forms'>
                                <span className='skeleton-label'> <Skeleton /></span>
                                <span className='skeleton-group'><Skeleton height={40} /></span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={6} sm={6}>
                            <div className='skeleton-forms'>
                                <span className='skeleton-label'> <Skeleton /></span>
                                <span className='skeleton-group'><Skeleton height={40} /></span>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6}>
                            <div className='skeleton-forms'>
                                <span className='skeleton-label'> <Skeleton /></span>
                                <span className='skeleton-group'><Skeleton height={40} /></span>
                            </div>
                        </Col>
                    </Row>
                    {role === 1 &&
                        <Row>
                            <div className='skeleton-forms'>
                                <span className='skeleton-label'> <Skeleton /></span>
                                <span className='skeleton-group'><Skeleton height={40} /></span>
                            </div>
                        </Row>
                    }
                    {(role === 2 || role === 3) &&
                        <>
                            <Row>
                                <Col lg={6} md={6} sm={6}>
                                    <div className='skeleton-forms'>
                                        <span className='skeleton-label'> <Skeleton /></span>
                                        <span className='skeleton-group'><Skeleton height={40} /></span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={6}>
                                    <div className='skeleton-forms'>
                                        <span className='skeleton-label'> <Skeleton /></span>
                                        <span className='skeleton-group'><Skeleton height={40} /></span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6} sm={6}>
                                    <div className='skeleton-forms'>
                                        <span className='skeleton-label'> <Skeleton /></span>
                                        <span className='skeleton-group'><Skeleton height={40} /></span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={6}>
                                    <div className='skeleton-forms'>
                                        <span className='skeleton-label'> <Skeleton /></span>
                                        <span className='skeleton-group'><Skeleton height={40} /></span>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    }
                    <div className="account-change-password for-des">
                        <span className="default-regular-h5 cs-primary cursor-pointer" >
                            <Skeleton width={100} />
                        </span>
                    </div>
                    <div className="account-change-password for-mob">
                        <span className="default-regular-h5 cs-primary cursor-pointer" >
                            <Skeleton width="100" height={30} />
                        </span>
                        <span className="default-regular-h5 cs-primary cursor-pointer" >
                            <Skeleton width="100" height={30} />
                        </span>
                    </div>
                </Form>
            </>
        </div>
    )
}

export default SkeletonAccount