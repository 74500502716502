import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form, Modal } from "react-bootstrap";
import CapsyncIcon from "../../../../../commonComponent/CapsyncIcon";
import { useDispatch, useSelector } from "react-redux";
import { fetch_attachments } from "../../../../../slices/verifyInvestor";
import { adjustLengthVi } from "../../../../../customHooks/adjustLength";




const AttachedDocument = (props) => {

    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    let id;

    if (window.localStorage.getItem("pfs_access_id") !== null) {
        id = window.localStorage.getItem("pfs_access_id");
    } else {
        id = user && user.user.id;
    }

    const [allAssets, setAllAssets] = useState([]);
    const [allLiblities, setAllLiblities] = useState([]);
    const allCategories = Object.values(allAssets).concat(Object.values(allLiblities));
    const user_attachement_data = () => {
        if (id) {
            dispatch(fetch_attachments({ user_id: id }))
                .unwrap()
                .then((response) => {
                    if (response.code === 200) {
                        if (response.assets) {
                            setAllAssets(response.assets)
                        }

                        if (response.liabilities) {
                            setAllLiblities(response.liabilities)
                        }
                    }

                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    useEffect(() => {
        if (props.showAttachedDocument) {
            user_attachement_data()
        }
    }, [props.showAttachedDocument])
    const handleCheckboxChange = (attachment, attachmentName, id) => {
        const isSelected = props.selectedAttachments.some(item => item.id === id);
        if (isSelected) {
            props.setSelectedAttachments((prev) => prev.filter((item) => item.id !== id));

        } else {
            props.setSelectedAttachments((prev) => [...prev, { attachment, attachmentName, id }]);
        }
    };

    const handleCancel = () => {
        props.setShowAttachedDocument(false);
        props.setSelectedAttachments(props.attachmentsDocuments)

        if (props.selectedAttachments.length === 0) {
            props.setAttachmentsDocuments([]);
            props.setSelectedAttachments([]);
        }
        props.setShowDuplicateError(false);
    }

    const assetsOrder = ["Cash on Hand & In Banks",
        "Savings Accounts",
        "IRA or Other Retirement Account",
        "Accounts & Notes Receivable",
        "Life Insurance",
        "Non-Retirement Brokerage Accounts (Stocks & Bonds)",
        "Real Estate",
        "Automobiles",
        "Businesses",
        "Other Personal Property",
        "Other Assets"]

    const liblityOrder = [
        "Accounts Payable",
        "Notes Payable to Banks and Others",
        "Installment Account (Auto)",
        "Installment Account (Other)",
        "Loan(s) Against Life Insurance",
        "Mortgages on Real Estate",
        "Unpaid Taxes",
        "Other Liabilities"
    ]

    const handleRowClick = (attachments, attachmentName, id) => {
        handleCheckboxChange(attachments, attachmentName, id);
        props.setShowDuplicateError(false)
        props.setShowMaxfileSizeError(false)
        props.setCheckPfsStatus(false)
        props.setCheckPfsStatus(false)
    };

    const flattenedArray = allCategories && [].concat(...allCategories);

    const attachments = [];

    flattenedArray.forEach(combinedItem => {
        props.fetchedMedia && props.fetchedMedia.forEach(mediaItem => {
            if (combinedItem.attachments === mediaItem.file_name) {

                attachments.push(combinedItem);
            }
        });
    });

    return (
        <>
            <Modal
                show={props.showAttachedDocument}
                onHide={() => !props.showAttachedDocument}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="cs-md-modal"
                className="cs-common-modal-overlay attached-doc-popup"
            >
                <Modal.Header>
                    <span className="cs-neutral-70" onClick={handleCancel}>
                        <CapsyncIcon
                            title="chevron-left-outlined"
                            size="24px"
                            alt="LeftIcon"
                        />
                    </span>
                    <Modal.Title className="default-semi-bold-h3">
                        Select Documents
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="selected-documents-box">
                        <Row>
                            <Col lg={12}>
                                <div className="text-center default-regular-sub-heading-s cs-neutral-90">
                                    <h4 className="default-semi-bold-h4 cs-neutral-100">Please select the supporting documents you wish to submit for review.</h4>
                                </div>
                            </Col>
                        </Row>
                        <Form>
                            <Row>
                                <Col lg={12}>
                                    <div className="selected-category-list cs-scroller">
                                        <div className="cs-accoutns-list  assets-accoutns-list">
                                            <div className="finance-data-container">
                                                <span className="finance-heading heading-asset default-medium-sub-heading-m cs-neutral-100">Assets</span>
                                            </div>
                                            {
                                                Object.keys(allAssets).length === 0 && <div className="cs-common-no-record"> <span className="default-regular-sub-heading-s cs-neutral-100"> There is no documents attached in assets.</span></div>
                                            }
                                            {allAssets &&
                                                assetsOrder.map((key) => {
                                                    return (
                                                        <div key={key} className="category-list">
                                                            {allAssets[key] && <h5 className="default-medium-sub-heading-s cs-neutral-100">{key}</h5>}
                                                            {
                                                                allAssets[key] && allAssets[key].map((asset, index) => {
                                                                    return (
                                                                        <div key={index}
                                                                            className={!(attachments.some(item => item.attachments === asset.attachments)) ? "selected-doc-list" : "selected-doc-list cs-disabled"}
                                                                            onClick={() => !(attachments.some(item => item.attachments === asset.attachments)) && handleRowClick(asset.attachments, asset.original_file_name, asset.id)}>
                                                                            <div className="category-title">
                                                                                <span className="cs-neutral-70">
                                                                                    <CapsyncIcon title="files-outlined" size="18" />
                                                                                </span>
                                                                                <span className="default-regular-sub-heading-xs cs-neutral-100">{asset.original_file_name && adjustLengthVi(asset.original_file_name)
                                                                                }</span>
                                                                            </div>
                                                                            <Form.Check
                                                                                className="cs-form-check-box default-regular-sub-heading-xs cs-neutral-100"
                                                                                type="checkbox"
                                                                                disabled={attachments.some(item => item.attachments === asset.attachments)}
                                                                                checked={props.selectedAttachments.some(item => item.id === asset.id) || attachments.some(item => item.attachments === asset.attachments)} />
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="cs-accoutns-list liability-accoutns-list">
                                            <div className="finance-data-container">
                                                <span className="finance-heading heading-liability default-medium-sub-heading-m cs-neutral-100">Liabilities</span>
                                            </div>
                                            {
                                                Object.keys(allLiblities).length === 0 && <div className="cs-common-no-record"> <span className="default-regular-sub-heading-s cs-neutral-100"> There is no documents attached in Liabilities.</span></div>
                                            }
                                            {/* category list start */}
                                            {allLiblities &&
                                                liblityOrder.map((key) => {
                                                    return (
                                                        <div key={key} className="category-list">
                                                            {
                                                                allLiblities[key] && <h5 className="default-medium-sub-heading-s cs-neutral-100">{key}</h5>

                                                            }

                                                            {
                                                                allLiblities[key] && allLiblities[key].map((asset, index) => {
                                                                    return (
                                                                        <div key={index}
                                                                            className={!(attachments.some(item => item.attachments === asset.attachments)) ? "selected-doc-list" : "selected-doc-list cs-disabled"}
                                                                            onClick={() => !(attachments.some(item => item.attachments === asset.attachments)) && handleRowClick(asset.attachments, asset.original_file_name, asset.id)}>
                                                                            <div className="category-title">
                                                                                <span className="cs-neutral-70">
                                                                                    <CapsyncIcon title="files-outlined" size="18" />
                                                                                </span>
                                                                                <span className="default-regular-sub-heading-xs cs-neutral-100">{asset.original_file_name && adjustLengthVi(asset.original_file_name)}</span>
                                                                            </div>
                                                                            <Form.Check
                                                                                className="cs-form-check-box default-regular-sub-heading-xs cs-neutral-100"
                                                                                type="checkbox"
                                                                                // onChange={() => handleCheckboxChange(asset.attachments)}
                                                                                disabled={attachments.some(item => item.attachments === asset.attachments)}
                                                                                checked={props.selectedAttachments.some(item => item.id === asset.id) || attachments.some(item => item.attachments === asset.attachments)}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </Form>
                    </div>
                </Modal.Body>
                <Modal.Footer className="cs-modal-btn">
                    <Button className="cs-btn-secondary md-btn default-regular-h6" onClick={handleCancel}>
                        Close
                    </Button>
                    <Button
                        className="cs-btn-primary md-btn default-regular-h6"
                        type="submit"
                        disabled={props.selectedAttachments.length === 0}
                        onClick={props.handleAttachmentSubmit}
                    >
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal >

            {/* {showLoader && <HelloSignProgress />
} */}
        </>
    )
}

export default AttachedDocument