/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ModalTable from "./ModalTable";
import {
    wealth_companies_add,
    wealth_individuals_exist,
} from "../../../slices/wealthManager.slice";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import "./Company.css";
import ChooseIndiDropdown from "./ChooseIndiDropdown";
import { access_invitation_Pfs } from '../../../slices/pfs_access'
import useDebounce from "../../../customHooks/useDebounce";
import { emailSchema } from "../../../util/common";

const EditAddModal = ({
    setTempCompName,
    tempCompName,
    editAddmodalState,
    changeAddEditModalState,
    compName,
    setPartners,
    partners,
    updateCompName,
    selectedIndividualEmailArray,
    setSelectedIndividualEmailArray,
    selectedIndividualFromCompany,
    setSelectedIndividualFromCompany,
    companyPartners,
    toastMessage,
    loading
}) => {
    const { user } = useSelector((state) => state.auth);
    const user_email = user && user.user.email;
    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(false);
    const [individuals, setIndividuals] = useState([])
    const [submitted, setSubmitted] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [companyValidation, setCompanyValidation] = useState({
        msg: "",
        color: "",
    });
    const [validation, setValidation] = useState({
        msg: "",
        color: "",
    });
    const [dropdownValidation, setDropdownValidation] = useState("")


    useEffect(() => {
        if (editAddmodalState.show) {
            getIndividuals();
        }
        if (!isChecked) {
            setSelectedIndividualFromCompany([]);
            setSelectedIndividualEmailArray([]);
            setDropdownValidation("")
        }
    }, [editAddmodalState.show, isChecked]);


    const acceptedIndividuals = individuals.filter((individual) => individual.to_status === 1);
    const handleCheckboxClick = () => {
        if (acceptedIndividuals && acceptedIndividuals.length > 0) {
            setIsChecked(!isChecked)
        }
    }

    const addFields = () => {
        setShowLoader(true);
        setSubmitted(false)
        setPartners([
            ...partners,
            { email: "", firstName: "", lastName: "", id: -1 },
        ]);
        // const isAllFieldsFilled = partners.every(
        //     (partner) =>
        //     (partner.email.trim() !== "" &&
        //         partner.firstName.trim() !== "" &&
        //         partner.lastName.trim() !== "")
        // );

        // setTimeout(() => {
        //     if (isAllFieldsFilled) {
        //         setShowLoader(false);
        //         setPartners([
        //             ...partners,
        //             { email: "", firstName: "", lastName: "", id: -1 },
        //         ]);
        //     } else {
        //         setValidation({
        //             msg: "*Please fill all partner details",
        //             color: "text-danger",
        //         });
        //     }
        // }, 1000);
    };

    const fieldChange = async (val, params, type) => {
        setSubmitted(false);
        let inputData = [...partners];
        setValidation({
            msg: "",
            color: "",
        });
        if (type === "email") {
            inputData[params].email = val;

            let isValidEmail = false
            try {
                await emailSchema.validate(val);
                isValidEmail = true
                inputData[params].emailError = ""
                setPartners(inputData);
            } catch (error) {
                isValidEmail = false
                inputData[params].emailError = error.message
                setPartners(inputData);
            }
            const isEmailAlreadyInPartners = (currEmail) => {
                for (let i = 0; i < companyPartners.length; i++) {
                    const partner = companyPartners[i]
                    const email = partner.email ? partner.email : ""
                    if (currEmail == email) {
                        return true
                    }
                }
                return false
            }
            const isEmailAlreadyInIndividualList = (currEmail) => {
                for (let j = 0; j < individuals.length; j++) {
                    const individual = individuals[j]
                    const email = individual.email ? individual.email : ""
                    if (currEmail == email) {
                        return true
                    }
                }
                return false
            }

            if (isValidEmail && val === user_email) {
                inputData[params].emailError = "Can not send invitation to yourself";
            } else if (isValidEmail && isEmailAlreadyInPartners(val)) {
                inputData[params].emailError =
                    "User Already Exists in Members";
            } else if (isValidEmail && isEmailAlreadyInIndividualList(val)) {
                inputData[params].emailError =
                    "User Already Exists in Individuals list";
            }

            if (isValidEmail) {
                checkUserExist(val, params)
            }
            // setPartners(inputData);
        } else if (type === "firstName") {

            inputData[params].firstName = val;
            // const hasLeadingTrailingWhitespace = /^\s|\s$/.test(val);
            // const hasNonWhitespaceCharacter = /\S/.test(val);
            // const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(val);
            if (val === "") {
                inputData[params].fnameError = "";
            }
            // else if (hasLeadingTrailingWhitespace || !hasNonWhitespaceCharacter) {
            //     inputData[params].fnameError =
            //         "Enter first name";
            // }
            // else if (hasSpecialCharacter) {
            //     inputData[params].fnameError =
            //         "Special characters are not allowed";
            // } 
            else {
                inputData[params].fnameError = "";
            }
            setPartners(inputData);
        } else if (type === "lastName") {
            inputData[params].lastName = val;
            // const hasLeadingTrailingWhitespace = /^\s|\s$/.test(val);
            // const hasNonWhitespaceCharacter = /\S/.test(val);
            // const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(val);
            if (val === "") {
                inputData[params].lnameError = "";
            }
            // else if (hasLeadingTrailingWhitespace || !hasNonWhitespaceCharacter) {
            //     inputData[params].lnameError =
            //         "Enter last name";
            // }
            // else if (hasSpecialCharacter) {
            //     inputData[params].lnameError =
            //         "Special characters are not allowed";
            // }
            else {
                inputData[params].lnameError = "";
            }
            setPartners(inputData);
        } else {
            inputData[params].lastName = val;
            setPartners(inputData);
        }
        const isAllFieldsFilled = partners.every(
            (partner) =>
            (partner.email.trim() !== "" &&
                partner.firstName.trim() !== "" &&
                partner.lastName.trim() !== "")
        );
        const isAnyFieldFilled = partners.some(
            (partner) =>
                partner.email.trim() !== "" ||
                partner.firstName.trim() !== "" ||
                partner.lastName.trim() !== ""
        );
        if (!isAllFieldsFilled && isChecked && !isAnyFieldFilled) {
            const removeError = partners.map((val) => {
                return {
                    ...val,
                    fnameError: "",
                    lnameError: "",
                    emailError: "",
                }
            })
            setPartners(removeError);
        }
    };

    useEffect(() => {
        const isAllFieldsFilled = partners.every(
            (partner) =>
            (partner.email.trim() !== "" &&
                partner.firstName.trim() !== "" &&
                partner.lastName.trim() !== "")
        );
        const isAnyFieldFilled = partners.some(
            (partner) =>
                partner.email.trim() !== "" ||
                partner.firstName.trim() !== "" ||
                partner.lastName.trim() !== ""
        );
        if (!isAllFieldsFilled && isChecked && !isAnyFieldFilled) {
            const removeError = partners.map((val) => {
                return {
                    ...val,
                    fnameError: "",
                    lnameError: "",
                    emailError: "",
                }
            })
            setPartners(removeError);
        }
    }, [isChecked])

    const checkUserExist = useCallback((val, params) => {
        let inputData = [...partners];
        if (val !== "") {
            dispatch(wealth_individuals_exist({ val: val }))
                .unwrap()
                .then((response) => {
                    inputData[params].id = response.user_id;
                    setPartners(inputData);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    },
        [partners]
    );

    const removeField = (params) => {
        if (partners.length > 1) {
            let fetchData = [...partners];
            fetchData.splice(params, 1);
            setPartners(fetchData);
        }
    };
    const handleEmailChange = (event, index) => {
        const value = event.target.value;
        fieldChange(value, index, "email");
    }

    const closeAddPartnerModal = () => {
        changeAddEditModalState(false, true);
        setTempCompName(compName);
        setSelectedIndividualFromCompany([]);
        setSelectedIndividualEmailArray([]);
        setIsChecked(false);
        setPartners([{ email: "", firstName: "", lastName: "" }]);
        setValidation({
            msg: "",
            color: "",
        });
        setCompanyValidation({
            msg: "",
            msg: "",
            color: "",
        });
    };

    const sendInvitation = () => {
        setSubmitted(true)
        const isAllFieldsFilled = partners.every(
            (partner) =>
            (partner.email.trim() !== "" &&
                partner.firstName.trim() !== "" &&
                partner.lastName.trim() !== "")
        );

        if (isChecked && selectedIndividualFromCompany.length === 0) {
            setDropdownValidation("Please select atleast one individual");
            return;
        }
        const isAnyFieldFilled = partners.some(
            (partner) =>
                partner.email.trim() !== "" ||
                partner.firstName.trim() !== "" ||
                partner.lastName.trim() !== ""
        );

        if ((!isAllFieldsFilled && !isChecked) || (isAnyFieldFilled && isChecked && !isAllFieldsFilled)) {
            const error = partners.map((val) => {
                return {
                    ...val,
                    fnameError: val.firstName.trim() === "" ? "Enter first name" : val.fnameError || "",
                    lnameError: val.lastName.trim() === "" ? "Enter last name" : val.lnameError || "",
                    emailError: val.email.trim() === "" ? "Enter email" : val.emailError || "",
                }
            })
            setPartners(error);
            return
        }
        let flag = 0;
        let fetchRawPartners = [];

        partners.forEach((new_itr) => {
            fetchRawPartners.push(new_itr.email);
        });

        let dummyfetchRawPartners = new Set(fetchRawPartners);
        if (Array.from(dummyfetchRawPartners).length !== fetchRawPartners.length) {
            setValidation({
                msg: "*You cannot add same email more than once.",
                color: "text-danger",
            });
            return false;
        }

        partners.forEach((itr, index) => {
            if ((itr.email === "" || itr.firstName === "" || itr.lastName === "") && index > 0) {
                flag++;
            }
        });

        if (compName === "") {
            flag++;
            setCompanyValidation({
                msg: "Enter company name",
                color: "text-danger",
            });
        } else {
            setCompanyValidation({
                msg: "",
                color: "",
            });
            // if (
            //     /^\s|\s$/.test(compName) ||
            //     /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(compName)
            // ) {
            //     flag++;
            //     setCompanyValidation({
            //         msg: "*Special characters and space not allowed",
            //         color: "text-danger",
            //     });
            // } else {
            //     setCompanyValidation({
            //         msg: "",
            //         color: "",
            //     });
            // }
        }

        // Check for invalid emails
        const invalidEmails = partners.some(
            (partner) => partner.emailError && partner.emailError !== ""
        );

        if (invalidEmails) {
            flag++;
        }
        // Check for invalid First Name
        const invalidFName = partners.some(
            (partner) => partner.fnameError && partner.fnameError !== ""
        );

        if (invalidFName) {
            flag++;
        }
        // Check for invalid Last Name
        const invalidLName = partners.some(
            (partner) => partner.lnameError && partner.lnameError !== ""
        );

        if (invalidLName) {
            flag++;
        }

        // let storeData = {
        //     partners: partners,
        //     company_name: compName,
        //     user_id: user_id,
        //     request_send_by_displayName: user_displayName,
        //     request_send_by_email: user_email,
        //     selected_individual: selectedIndividualEmailArray
        // };

        // let checkCompName = filteredCompanies.filter(
        //     (record) => record.companyName === compName
        // );

        // if (checkCompName.length !== 0) {
        //     flag++;
        //     setValidation({
        //         msg: "A company with this name already exists",
        //         color: "text-danger",
        //     });
        // } else 

        if (flag === 0) {
            setValidation({
                msg: "",
                color: "",
            });
            // setLoading(true);
            updateCompName({ closeAddPartnerModal: closeAddPartnerModal });
            // closeModal();
            // dispatch(wealth_companies_add(storeData))
            //     .unwrap()
            //     .then((response) => {
            //         if (response.code === 200) {
            //             fetchAllCompanies();
            //             changeAddEditModalState(false, true);
            //             toastMessage("Company created successfully!", "success");
            //             setPartners([{ email: "", firstName: "", lastName: "" }]);
            //             setCompName("");
            //             setValidation({
            //                 msg: "",
            //                 color: "",
            //             });
            //             setLoading(false);
            //             setSelectedIndividualEmailArray([]);
            //             setSelectedIndividualFromCompany([]);
            //             setIsChecked(false)
            //         } else {
            //             setLoading(false);
            //             toastMessage(response.message, "error");
            //         }
            //     })
            //     .catch((err) => {
            //         setLoading(false);
            //         toastMessage(err.message, "error");
            //     });
        }
    };

    const getIndividuals = () => {
        dispatch(
            access_invitation_Pfs({
                email: user && user.user.email,
            })
        )
            .unwrap()
            .then((response) => {
                setIndividuals(response ? response : []);
            })
            .catch((err) => {
                toastMessage("access_invitation_Pfs :" + err.message, "error");
            });
    };

    const handleChooseIndiDropdown = () => {
        getIndividuals()
    }

    const everyFieldShouldBeFilled = (partner) => partner.email === "" || partner.firstName === "" || partner.lastName === ""
    const everyFieldShouldBeEmpty = (partner) => {
        const result = (partner.email === "" && partner.firstName === "" && partner.lastName === "") && isChecked && selectedIndividualFromCompany.length > 0
        return result == true ? false : true
    }
    return (
        <>
            <Modal
                show={editAddmodalState.show}
                onHide={!editAddmodalState.show}
                backdrop="static"
                keyboard={false}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="cs-md-modal company-add-popup"
            >
                <Modal.Header>
                    <Modal.Title className="default-semi-bold-h3">
                        Add Partners to company
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="add-company-form">
                            {partners.map((iterator, index) => {
                                return (
                                    <>
                                        <Row>
                                            <span className="company-modal-line">
                                                {partners.length > 1 && index !== 0 && (
                                                    <hr className="cs-neutral-60" />
                                                )}
                                            </span>
                                            {partners.length > 1 && index !== 0 && (
                                                <div className="company-popup-remove">
                                                    <span
                                                        className="cs-danger"
                                                        onClick={() => removeField(index)}
                                                    >
                                                        <CapsyncIcon title="delete-filled" size="16" />
                                                    </span>
                                                    <span
                                                        className="default-regular-h5 cs-danger"
                                                        onClick={() => removeField(index)}
                                                    >
                                                        Remove
                                                    </span>
                                                </div>
                                            )}
                                            <Row>
                                                <span className="default-regular-body-text-m cs-neutral-80 partner-detail">Enter partners details</span>
                                            </Row>
                                            <Col md={6}>
                                                <Form.Group
                                                    className={`cs-form-group${iterator.fnameError ? "" : ""
                                                        }`}
                                                    controlId={`validationFormik03-${index}`}
                                                >
                                                    <Form.Label>First name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        autoComplete="off"
                                                        value={iterator.firstName}
                                                        className={
                                                            iterator.firstName && "cs-input-field-active"
                                                        }
                                                        onChange={(event) =>
                                                            fieldChange(event.target.value, index, "firstName")
                                                        }
                                                        onKeyDown={(e) => {
                                                            if (e.key === " " && e.target.selectionStart === 0) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onKeyPress={(e) =>
                                                            /[0-9]/.test(e.key) && e.preventDefault()
                                                        }
                                                        maxLength={40}
                                                    />
                                                    {submitted && iterator.fnameError && (
                                                        <span className="form-error-msg default-light-body-text-s cs-danger">
                                                            {iterator.fnameError}
                                                        </span>
                                                    )}
                                                    {submitted && index > 0 && iterator.firstName == "" && ("Enter first name") && !iterator.fnameError && (
                                                        <span className="form-error-msg default-light-body-text-s cs-danger">
                                                            Enter first name
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group
                                                    className={`cs-form-group ${iterator.lnameError ? "" : ""
                                                        }`}
                                                    controlId={`validationFormik03-${index}`}
                                                >
                                                    <Form.Label>Last name</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={iterator.lastName}
                                                        className={
                                                            iterator.lastName && "cs-input-field-active"
                                                        }
                                                        autoComplete="off"
                                                        onKeyDown={(e) => {
                                                            if (e.key === " " && e.target.selectionStart === 0) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(event) =>
                                                            fieldChange(event.target.value, index, "lastName")
                                                        }
                                                        onKeyPress={(e) =>
                                                            /[0-9]/.test(e.key) && e.preventDefault()
                                                        }
                                                        maxLength={40}
                                                    />
                                                    {submitted && iterator.lnameError && (
                                                        <span className="form-error-msg default-light-body-text-s cs-danger">
                                                            {iterator.lnameError}
                                                        </span>
                                                    )}
                                                    {submitted && index > 0 && iterator.lastName === "" && !iterator.lnameError && (
                                                        <span className="form-error-msg default-light-body-text-s cs-danger">
                                                            Enter last name
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={12}>
                                                <Form.Group
                                                    className={`cs-form-group last-email-field${iterator.emailError ? "" : ""
                                                        }`}
                                                    controlId={`validationFormik03-${index}`}
                                                >
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        autoComplete="off"
                                                        onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                                                        maxLength={100}
                                                        value={iterator.email}
                                                        className={iterator.email && "cs-input-field-active"}
                                                        onChange={(event) =>
                                                            handleEmailChange(event, index)
                                                        }
                                                    // onBlur={(event) =>
                                                    //     checkUserExist(event.target.value, index)
                                                    // }
                                                    />
                                                    {submitted && iterator.emailError && (
                                                        <span className="form-error-msg default-light-body-text-s cs-danger">
                                                            {iterator.emailError}
                                                        </span>
                                                    )}
                                                    {submitted && index > 0 && iterator.email === "" && !iterator.emailError && (
                                                        <span className="form-error-msg default-light-body-text-s cs-danger">
                                                            Enter email
                                                        </span>
                                                    )}
                                                </Form.Group>
                                            </Col>
                                        </Row>


                                    </>
                                );
                            })}
                        </div>

                        <>
                            {partners.every(
                                (partner) =>
                                    partner.email.trim() !== "" &&
                                    partner.firstName.trim() !== "" &&
                                    partner.lastName.trim() !== "" &&
                                    partner.emailError === "" &&
                                    partner.fnameError === "" &&
                                    partner.lnameError === ""

                            )}
                        </>

                        <div className="associate-add-more">
                            <Button
                                type="submit"
                                disabled={validation.msg.length != 0}
                                className="default-regular-h5 cs-btn-icon-tertiary"
                                onClick={addFields}
                            >
                                <span className="cs-primary">
                                    <CapsyncIcon title="add-filled" size="16" />
                                    Add more partners
                                </span>
                            </Button>
                            <span
                                className={
                                    validation.color &&
                                    "form-error-msg default-light-body-text-s cs-danger"
                                }
                            >
                                {validation.msg}
                            </span>
                            <Form.Group onClick={handleCheckboxClick} >
                                <Form.Check
                                    type="checkbox"
                                    checked={isChecked}
                                    disabled={acceptedIndividuals && acceptedIndividuals.length === 0}
                                    label="Associate already added individual with company"
                                    className="cs-form-check-box "
                                />
                            </Form.Group>
                            {isChecked && <ChooseIndiDropdown data={individuals}
                                selectedIndividualFromCompany={selectedIndividualFromCompany}
                                setSelectedIndividualFromCompany={setSelectedIndividualFromCompany}
                                setSelectedIndividualEmailArray={setSelectedIndividualEmailArray}
                                selectedIndividualEmailArray={selectedIndividualEmailArray}
                                companyPartners={companyPartners}
                                dropdownValidation={dropdownValidation}
                                setDropdownValidation={setDropdownValidation}
                            />}
                            {isChecked && <ModalTable tableLabel="Partners Details" dataArray={selectedIndividualFromCompany} />}
                        </div>


                    </>

                </Modal.Body>
                <Modal.Footer>
                    <div className="cs-modal-btn">
                        <Button
                            className="cs-btn-secondary md-btn default-regular-h5"
                            onClick={closeAddPartnerModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="cs-btn-primary md-btn default-regular-h5"
                            onClick={sendInvitation}
                            disabled={loading}
                        // || partners.every(
                        // (partner) => {
                        //     return everyFieldShouldBeEmpty(partner) && everyFieldShouldBeFilled(partner) //=== true ? (isChecked && selectedIndividualFromCompany > 0) : true
                        // })
                        >
                            Send Invitation
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditAddModal;
