import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { socket } from "../../../config/Socket";
import UpgradeStorageModal from "./UpgradeStorageModal";

const UpgradeStorage = ({ storage, onSuccess, showLimitModal, setShowLimitModal }) => {

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  useEffect(() => {
    setShowUpgradeModal(showLimitModal)
  }, [showLimitModal])

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  return (
    <>
      <div className="cs-storage">
        <div className="storage-info">
          <div className="storage-value">
            <span className="cs-neutral-100 default-regular-sub-heading-xxs">
              {(storage && storage.usedSpace ? (storage.usedSpace / 1073741824).toFixed(2) : 0)} Used /
            </span>
            <span className="cs-neutral-70 default-regular-sub-heading-xxs">
              &nbsp; {storage && storage.maxSpace ? (storage.maxSpace / 1073741824).toFixed(2) : 0} GB
            </span>
          </div>
          <h4
            className="cs-primary default-light-body-text-m cursor-pointer"
            onClick={() => setShowUpgradeModal(true)}
          >
            {storage.is_upgraded_plan ? "Manage storage" : "Upgrade storage"}
          </h4>
        </div>
        <div className="cs-progress-bar">
          <ProgressBar now={storage.maxSpace && storage.usedSpace ? 100 * (storage.usedSpace / 1073741824) / (storage.maxSpace / 1073741824) : 0} />
        </div>
      </div>
      <Elements stripe={stripePromise} >
        <UpgradeStorageModal
          setShowUpgradeModal={setShowUpgradeModal}
          showUpgradeModal={showUpgradeModal}
          onSuccess={onSuccess}
          storage={storage}
          showLimitModal={showLimitModal}
          setShowLimitModal={setShowLimitModal}
        />
      </Elements>
    </>
  );
};

export default UpgradeStorage;
