export const INDIVIDUAL_PATHS = {
    DASHBOARD: "dashboard",
    ASSETS: "assets",
    LIABILITIES: "liabilities",
    VERIFY_INVESTOR: "verifyinvestor",
    COLLABORATION: "collaboration",
    REQUEST: "request",
    ACTIVITY_HISTORY: "activity-history",
    CREDIT_MANAGER: "credit-manager",
    IDENTITY_PROTECT: "identity-protect",
    FILE_MANAGEMENT: "file-management",
    ARCHIVE: "archive",
    TRASH: "trash",

}

export const FA_PATHS = {
    DASHBOARD: "dashboard",
    INDIVIDUALS: "individuals",
    COMPANIES: "companies",
    REQUESTS: "requests",
} 