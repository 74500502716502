import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import SubMenuHeader from "../SubMenuHeader";
import { combine } from "../../../../../../util/common";
import NumberFormat from "react-currency-format";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import SubMethodsList from "../../SubMethodsList";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import { Link } from "react-router-dom";
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";

// sequence No. one  (Asmita date-29th June => Do not remove this line)
const MethodIdTwelve = (props) => {


  const [newDollar, setDollar] = useState(false);
  let common;

  const check = (para, a) => {
    common = combine(para, a);
    if (common.value === "dollar") {
      setDollar(common.status);
    }
  };

  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Accredited Investor",
      url: `/individual/verifyinvestor/accreditedInvestor/${props.verificationType}/${props.unique_code}`,
    },
  ];

  return (
    <>
      {props.is_loader.assets === true || props.is_loader.liabilities === true ? <SkeletonAllSubMethods /> :
        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="3rd Party Letter"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>
              <SubMenuHeader />
            </div>
            <div className="method-section">
              <div className="default-regular-body-text-l cs-neutral-70">
                <SubMethodsList methodId={14} />
              </div>

              <div className="cs-divider"></div>

              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert}
                />

                <div className="row-spacing">
                  <p className="default-regular-body-text-l cs-neutral-70">

                    In order to verify your accreditation we need you to upload
                    evidence. Please upload the appropriate files below and
                    black-out any sensitive information.
                  </p>
                  <p className="default-regular-body-text-l cs-danger">

                    Upon a successful verification, the expiration date of the
                    verification letter will be based on the evidence provided to
                    reviewers, e.g. if a 3rd party letter or a monthly statement is
                    2 months old at the time of submission for review the
                    verification letter will expire in 1 month.
                  </p>
                </div>

                <div className="row-spacing">
                  <p className="default-regular-body-text-l cs-neutral-90">
                    Upload a signed letter from a <span className="cs-danger">registered broker-dealer</span>, an&nbsp;
                    <span className="cs-danger">SEC-registered investment adviser</span>, a <span className="cs-danger">licensed attorney</span>, or a&nbsp;
                    <span className="cs-danger">certified public accountant</span> showing that they have already
                    verified you as an accredited investor within the last three
                    months:
                  </p>

                  <p className="default-regular-body-text-l cs-danger ">
                    IF YOUR LETTER IS NOT COMPLETED BY ANY OF THE NOTED PROFESSIONALS, PLEASE USE THE NET WORTH VERIFICATION METHOD
                  </p>
                </div>
                <Form.Label className="default-regular-sub-heading-s ">
                  <span className="cs-neutral-90">
                    Sample Letter
                  </span><Link to={"https://s3.amazonaws.com/static-capsync.com/Verify-Investor-files/Sample-3rd-Party-Verification-Letter.pdf"} target="_blank"> <u className="cs-neutral-100">Here</u></Link></Form.Label>
                <UploadFileBlock
                  files={props.files}
                  setOpenModal={props.setOpenModal}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile}
                  showFile={props.showFile}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink}
                  uploadError={props.uploadError}

                />
                <NotesBlock setNotesValue={props.setNotesValue} />
              </Form>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default MethodIdTwelve;
