import React, { useState } from "react";
import { Row, Col, Button, Dropdown, Form, } from "react-bootstrap";
import DatePicker from "react-date-picker";
import UseTransactionPdf from "../../../customHooks/UseTransactionPdf";
import { backAppURl } from "../../../applicationMode";
import { useSelector } from "react-redux";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import { DecryptReact } from "../../../customHooks/EncryptDecryptReact";

const SearchFilter = ({
  breadcrumb, checkCustomFilter,
  transactionDate, selectCustomFilter,
  mask_id, transDate, handleSearch, search,
  editAccessUser, startDate, endDate,isInvestTransactions, downloadButton,setDownloadButton
}) => {
  const [customFilters] = useState([
    'View All',
    "Custom date",
    "Last Month",
    "Last Quarter",
    "Year to date",
  ]);
  const [selectedStatData, seSlectedStartDate] = useState("")
  const [selectedEndData, seSlectedEndtDate] = useState("")

  const { user } = useSelector((state) => state.auth);
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  function fetchInsId() {
    const decryptedData = DecryptReact(
      window.localStorage.getItem("trans_ins_id")
    );
    return decryptedData;
  }

  const downloadTransactions = (params) => {

    setDownloadButton(true);
    const param = {
      user_id: id,
      ins_id: fetchInsId(),
      mask_id: params,
      is_investment_transactions: isInvestTransactions
    }
    if (startDate && endDate) {
      param['start_date'] = startDate;
      param['end_date'] = endDate;
    }
    UseTransactionPdf(
      `${backAppURl}/api/transactions/pdf`,
      param,
      `${breadcrumb.institute}_${params}_transactions.pdf`,
      () => setDownloadButton(false)
    );
  };


  return (
    <>

      <div className="transaction-search-bar">
        <Row>
          <Col lg={8}>
            <div className="transaction-period-select ">
              <Form.Group className="dropdown-item-transaction">
                <div className="cs-common-add-dropdown">
                  <Dropdown >
                    <Dropdown.Toggle
                    >
                      <span>
                        {selectCustomFilter === ""
                          ? "View All"
                          : selectCustomFilter}
                      </span>
                      <span className="cs-neutral-100">
                        <CapsyncIcon title="chevron-down-outlined" size="16" />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu >
                      {customFilters.map((itr, ind) => (
                        <Dropdown.Item
                          value={itr}
                          key={ind}
                          selected={selectCustomFilter === itr}
                          onClick={() => checkCustomFilter(itr)}
                          className={itr === selectCustomFilter && "cs-selected-dropdown"}
                        >
                          {itr}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Form.Group>

              {selectCustomFilter === "Custom date" && (
                <>
                  <div className="showdate">
                    <DatePicker
                      type="date"
                      dayPlaceholder="DD"
                      monthPlaceholder="MM"
                      yearPlaceholder="YYYY"
                      maxDate={selectedEndData ? selectedEndData : new Date()}
                      // minDate={selectedEndData}
                      value={transDate.start}
                      onChange={(e) => { transactionDate(e, "start"); seSlectedStartDate(e) }}
                      clearIcon={null}
                      className={transDate.start ? "cs-input-field-active select-date-range cs-date-picker-calender" : "cs-date-picker-calender"}
                      calendarIcon={
                        <span className="cs-neutral-70">
                          <CapsyncIcon title="calender-outlined" size="18" />
                        </span>
                      }
                      format="MM/dd/yy"
                    />
                    <span className="default-regular-h6 cs-neutral-70">
                      To
                    </span>

                    <DatePicker
                      type="date"
                      dayPlaceholder="DD"
                      monthPlaceholder="MM"
                      maxDate={new Date()}
                      minDate={selectedStatData}
                      yearPlaceholder="YYYY"
                      value={transDate.end}
                      onChange={(e) => { transactionDate(e, "end"); seSlectedEndtDate(e) }}
                      clearIcon={null}
                      className={transDate.end ? "cs-input-field-active select-date-range cs-date-picker-calender" : "cs-date-picker-calender"}
                      calendarIcon={
                        <span className="cs-neutral-70">
                          <CapsyncIcon title="calender-outlined" size="18" />
                        </span>
                      }
                      format="MM/dd/yy"
                    />
                  </div>
                </>
              )}
            </div>
          </Col>
          <Col lg={4}>
            <div className="transaction-search">
              <div className="cs-search-bar">
                <Form.Group className="cs-form-group">
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    value={search}
                    onChange={(event) => handleSearch(event.target.value)}
                    className={
                      search && "cs-input-field-active"
                    }
                  />
                  <span className="input-field-icon">
                    <CapsyncIcon title="search-outlined" size="18" />
                  </span>
                </Form.Group>
              </div>
              {editAccessUser && <Button
                className="md-btn cs-btn-primary default-regular-h6"
                onClick={() => downloadTransactions(mask_id)}
                disabled={downloadButton}
              >
                <span className="for-des">Download</span>
                <span className="for-mob cs-neutral-10">
                  <CapsyncIcon title="download-filled" size="18" />
                </span>
              </Button>}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SearchFilter;
