/* eslint-disable */
import React, { Fragment } from "react";
import FetchDefaultInstitutionLogo from "../FetchDefaultInstitutionLogo";
import "../DefaultPayment.css";
import CapsyncIcon from "../../CapsyncIcon";

const CardPaymentDetails = (props) => {
  const {
    item,
    index,
    removePaymentMethod,
    currentActive,
    setCurrentActive,
    showOnPage,
    setDisabled,
    verifyTitle,
  } = props;
  const itemToken =
    item.stripe_payment_method && JSON.parse(item.stripe_payment_method);

  function paymentTitle() {
    if (item.subscription_type === 1) {
      if (itemToken.card.funding === "credit") {
        return "Credit Card";
      } else {
        return "Debit Card";
      }
    } else {
      return "Bank Account";
    }
  }

  return (
    <Fragment key={index}>
      <p className="default-regular-body-text-m cs-neutral-100">
        {!showOnPage &&
          (item.is_active
            ? "Default payment method"
            : verifyTitle
            ? "Waiting for verification"
            : !item.is_active && index === 1
            ? "Other payment method"
            : "")}
      </p>
      <div
        onClick={() => {
          if (item.is_verify) {
            !showOnPage && setCurrentActive(item.user_payment_detail_id);
            !showOnPage && setDisabled(false);
          }
        }}
        onLoad={() => {
          !showOnPage &&
            currentActive == null &&
            item.is_active &&
            setCurrentActive(item.user_payment_detail_id);
        }}
        className={
          (currentActive == null && item.is_active) ||
          currentActive == item.user_payment_detail_id
            ? "credit-change-method change-method-active"
            : item.is_verify
            ? "credit-change-method"
            : "credit-change-method cs-disabled"
        }
      >
        <div className="change-credit-text">
          <p className="default-regular-body-text-l cs-neutral-80">
            {paymentTitle()}
          </p>
          <span className="change-card-image default-semi-bold-h3 cs-neutral-100">
            <div className="cs-card-logo">
              <FetchDefaultInstitutionLogo
                img={
                  JSON.parse(item.stripe_payment_method).card
                    ? JSON.parse(item.stripe_payment_method).card.brand
                    : JSON.parse(item.stripe_payment_method).us_bank_account
                    ? "Bank Account"
                    : ""
                }
              />
            </div>
            **** **** ****
            {JSON.parse(" " + item.stripe_payment_method).card
              ? JSON.parse(" " + item.stripe_payment_method).card.last4
              : JSON.parse(" " + item.stripe_payment_method).us_bank_account
              ? JSON.parse(" " + item.stripe_payment_method).us_bank_account
                  .last4
              : ""}
          </span>
        </div>
        {window.localStorage.getItem("pfs_access_id") !== null ||
        !item.is_verify
          ? ""
          : !item.is_active && (
              <span
                className="cs-danger change-card-delete"
                onClick={(e) => {
                  e.stopPropagation();
                  removePaymentMethod(item.user_payment_detail_id);
                }}
              >
                <CapsyncIcon title="delete-outlined" size="22" />
              </span>
            )}
      </div>
    </Fragment>
  );
};

export default CardPaymentDetails;
