const fetchSignatureFrom = (dataArray, signStatus, forIndividual) => {

    if (signStatus !== "Pending" && signStatus !== "Completed") {
        throw new Error();
    }


    let response = [];
    let Status = signStatus === "Completed" ? true : false;
    if (forIndividual === false) {
        for (let index in dataArray) {
            let dataObject = dataArray[index];
            if (dataObject.is_completed === Status) {
                response.push(dataObject);
            }
        }

    } else {

        for (let index in dataArray) {
            let dataObject = dataArray[index];
            let requiredStatus = Status === true ? 2 : 3;
            if (dataObject.indvidual_users.length === 1) {
                dataObject.indvidual_users.map((user) => {
                    if (user.request_status === requiredStatus) {
                        response.push(dataObject);
                    }
                    else if (dataObject.is_completed === Status && (user.request_status === 1 || user.request_status === 4)) {
                        response.push(dataObject);
                    }
                })
            }
            else if (dataObject.is_completed === Status) {

                response.push(dataObject);
            }


        }
    }

    return response;
};
const getSignatureTo = (dataArray, signStatus) => {
    if (signStatus !== "Pending" && signStatus !== "Completed") {
        throw new Error();
    }

    let result = [];
    let Status = signStatus === "Completed" ? true : false;
    // for (let index in dataArray) {

    //     console.log("dataArray[index]", dataArray[index])
    //     if (signStatus == "Completed" && dataArray[index].request_status == 1) {
    //         result.push(dataArray[index]);
    //     } else if (
    //         signStatus == "Pending" &&
    //         (dataArray[index].request_status == 2 ||
    //             dataArray[index].request_status == 3)
    //     ) {
    //         result.push(dataArray[index]);
    //     }
    // }
    for (let index in dataArray) {
        let dataObject = dataArray[index];
        let requiredStatus = Status === true ? 2 : 3;
        if (dataObject.indvidual_users && dataObject.indvidual_users.length === 1) {
            dataObject.indvidual_users.map((user) => {
                if (user.request_status === requiredStatus) {
                    result.push(dataObject);
                }
                else if (dataObject.is_completed === Status && (user.request_status === 1 || user.request_status === 4)) {
                    result.push(dataObject);
                }
            })
        }
        else if (dataObject.is_completed === Status) {

            result.push(dataObject);
        }


    }

    return result;
};
const requestStatusFilter = (dataArray, requestStatus) => {

    if (requestStatus !== "Completed" && requestStatus !== "Pending") {
        throw new Error();
    }
    let requiredStatus = requestStatus === "Completed" ? 1 : 3;
    let result = [];


    for (let index in dataArray) {
        let userRequestObject = dataArray[index];

        let userRequestStatus = userRequestObject.check_status;
        if (requiredStatus === userRequestStatus || (requestStatus === "Completed" && userRequestStatus === 2)) {
            result.push(userRequestObject);
        }
    }

    return result;
};
const getStatusInText = (num) => {
    const map = new Map([
        [1, "Completed"],
        [2, "Completed"],
        [3, "Pending"],
        [4, "Rejected"],
    ]);
    return map.get(num);
};

const getSignatureStatus = (signatureRequestObject, userType = 'Individual') => {
    const result = [getStatusInText(1)];//always completed
    const signatureRequestUsers = userType === "Individual" ? signatureRequestObject.indvidual_users : signatureRequestObject.wm_users;
    let userStatusArray = [];
    let requestStatus = null;

    for (let index in signatureRequestUsers) {
        let userStatus = signatureRequestUsers[index].request_status;
        userStatusArray.push(userStatus);
    }


    userStatusArray.sort();

    for (let index in userStatusArray) {
        requestStatus = getStatusInText(userStatusArray[index]);
        result.push(requestStatus);
    }

    if (requestStatus === "Rejected") {
        result.push(getStatusInText(4));
    } else if (requestStatus === "Pending") {
        result.push(getStatusInText(3));
    } else if (requestStatus === "Completed") {
        result.push(getStatusInText(1));
    }
    if (signatureRequestUsers === undefined && (signatureRequestObject.request_status === 1 || signatureRequestObject.request_status === 2)) {
        result.push(getStatusInText(1));
        result.push(getStatusInText(1));
    }

    return result;

};


export {
    fetchSignatureFrom,
    getSignatureStatus,
    getSignatureTo,
    getStatusInText,
    requestStatusFilter,
};
