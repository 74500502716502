import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import moment_timezone from "moment-timezone";

import {
  add_subscription,
  add_user_payment,
  add_payment_billing,
  get_billing_and_subscription_by_user,
} from "../../../slices/user_subscription";
import CapsyncIcon from "../../CapsyncIcon";
import { authUserUpdated, userInitialModalSetup } from "../../../slices/auth";

const StripeCheckOutForm = (props) => {
  const {
    selectedPlan,
    setShowStripeForm,
    customerId,
    subscriptionDetail,
    intentType,
    paymnetintentType,
    unique_row_id,
    setBillingDetailModal,
    setPaymentLoader,
    clientSecret,
  } = props;

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const [stripeErrorMessage, setStripeErrorMessage] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [defaultPaymentCheck, setDefaultPaymentCheck] = useState(false);

  let user_id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    user_id = window.localStorage.getItem("pfs_access_id");
  } else if (user && user.user.id) {
    user_id = user.user.id;
  }
  let user_role = user && user.user_role.role;
  let user_email = user && user.user.email;

  const CARD_ELEMENT_OPTIONS = {
    fields: {
      billingDetails: {
        address: {
          country: "never",
        },
      },
    },
    style: {
      base: {
        color: "#999",
        fontWeight: 500,
        fontSize: "16px",
        fontSmoothing: "antialiased",
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
    applePay:
      intentType === 1
        ? {
            recurringPaymentRequest: {
              paymentDescription: "My subscription",
              managementURL: "https://example.com/billing",
              regularBilling: {
                amount:
                  selectedPlan &&
                  selectedPlan.price &&
                  selectedPlan.price.unit_amount
                    ? selectedPlan.price.unit_amount
                    : 0,
                label: "Subscription fee",
                recurringPaymentIntervalUnit:
                  selectedPlan &&
                  selectedPlan.price &&
                  selectedPlan.price.recurring.interval
                    ? selectedPlan.price.recurring.interval
                    : null,
                recurringPaymentIntervalCount:
                  selectedPlan &&
                  selectedPlan.price &&
                  selectedPlan.price.recurring.interval_count
                    ? selectedPlan.price.recurring.interval_count
                    : 1,
                // recurringPaymentStartDate: user.user.is_used_free_trial
                //   ? moment_timezone().tz("America/New_York").format()
                //   : process.env.REACT_APP_MODE === "dev"
                //   ? moment_timezone()
                //       .add(1, "day")
                //       .tz("America/New_York")
                //       .format()
                //   : moment_timezone()
                //       .add(1, "month")
                //       .tz("America/New_York")
                //       .format(),
              },
            },
          }
        : {},
  };

  const handleCardNumberChange = (event) => {
    setPaymentType(event.value.type);
    setStripeErrorMessage(event.error ? event.error.message : "");
  };

  const closeModal = () => {
    setStripeErrorMessage("");
    setShowStripeForm(false);
  };

  const handleStripePayment = (result) => {
    if (intentType === 1) {
      if (user.user.is_used_free_trial) {
        setPaymentLoader(true);
        setBillingDetailModal(false);
        setShowStripeForm(false);
      }
      dispatch(
        add_subscription({
          stripe_customer_id: customerId,
          price_id: selectedPlan.price.id,
          subscription_type: paymentType === "card" ? 1 : 2, //card/bank
          is_auto_subscription: true,
          currency: "usd",
          id: user_id,
          role: user_role,
          response: result,
          intent_type:
            paymnetintentType === "setupIntents"
              ? "setupIntent"
              : "paymentIntent",
          subscription_detail: subscriptionDetail,
        })
      )
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            // toast.success("Your payment is Successful! Please enjoy CapSync!");
            if (!user.user.is_used_free_trial) {
              paymentSuccessContinue();
            }
            setShowStripeForm(false);
          } else {
            toast.error(response.message);
          }
        })
        .catch((err) => {
          console.log("err stripe checkout", err);
          setStripeErrorMessage("Something wrong");
        });
    } else if (intentType === 3) {
      dispatch(
        add_user_payment({
          subscription_type: paymentType === "card" ? 1 : 2,
          email: user_email,
          is_active: defaultPaymentCheck,
          response: result,
          intent_type: "setupIntent",
          id: user_id,
          role: user_role,
        })
      )
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            toast.success("Your payment method has been added successfully.");
            let values = {
              user_id:
                user_role === 1
                  ? window.localStorage.getItem("pfs_access_id") !== null
                    ? window.localStorage.getItem("pfs_access_id")
                    : user_id
                  : 0,
              wm_user_id:
                user_role === 2 || user_role === 3
                  ? window.localStorage.getItem("pfs_access_id") !== null
                    ? window.localStorage.getItem("pfs_access_id")
                    : user_id
                  : 0,
              filter_by: "",
            };
            dispatch(get_billing_and_subscription_by_user(values))
              .unwrap()
              .then((response) => {})
              .catch((err) => {
                console.log(err);
              });
            paymentSuccessContinue();
            setShowStripeForm(false);
          } else {
            toast.error(response.message);
          }
        })
        .catch((err) => {
          console.log("err stripe checkout", err);
          setStripeErrorMessage("Something wrong");
        });
    } else if (intentType === 2) {
      setPaymentLoader(true);
      setBillingDetailModal(false);
      setShowStripeForm(false);
      dispatch(
        add_payment_billing({
          response: result,
          intent_type: "paymentIntent",
          subscription_type: paymentType === "card" ? 1 : 2,
          unique_row_id: unique_row_id,
          email: user_email,
          id: user_id,
          role: user_role,
          stripe_customer_id: customerId,
          is_save: defaultPaymentCheck,
        })
      )
        .unwrap()
        .then((response) => {
          if (response.code != 200) {
            toast.error(response.message);
          }
        })
        .catch((err) => {
          console.log("err stripe checkout", err);
          setStripeErrorMessage("Something wrong");
        });
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      await elements.submit();
      if (paymnetintentType === "paymentIntent") {
        stripe
          .confirmPayment({
            elements,
            clientSecret: clientSecret,
            redirect: "if_required",
            confirmParams: {
              payment_method_data: {
                billing_details: {
                  address: {
                    country: "US",
                  },
                },
              },
              // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
              return_url: "https://example.com",
            },
          })
          .then((result) => {
            if (result.error) {
              setLoading(false);
              console.log("confirmPayment result error:-", result.error);
              if (result.error.type != "validation_error") {
                if (intentType === 2) {
                  setPaymentLoader(true);
                  setBillingDetailModal(false);
                  setShowStripeForm(false);
                }
                setStripeErrorMessage(result.error.message);
              }
            } else {
              setLoading(true);
              handleStripePayment(result);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log("confirmPayment error:-", error);
          });
      } else if (paymnetintentType === "setupIntents") {
        stripe
          .confirmSetup({
            elements,
            clientSecret: clientSecret,
            redirect: "if_required",
            confirmParams: {
              payment_method_data: {
                billing_details: {
                  address: {
                    country: "US",
                  },
                },
              },
              // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
              return_url: "https://example.com",
            },
          })
          .then((result) => {
            if (result.error) {
              setLoading(false);
              console.log("confirmSetup result error:-", result.error);
              result.error.type != "validation_error" &&
                setStripeErrorMessage(result.error.message);
            } else {
              setLoading(true);
              handleStripePayment(result);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log("confirmSetup error:-", error);
          });
      }
    } catch (err) {
      console.log("err 11", err);
      setStripeErrorMessage("Something wrong");
    }
  };

  const paymentSuccessContinue = () => {
    if (intentType === 1) {
      dispatch(
        userInitialModalSetup({ user_id: user.user.id, user_role: user_role })
      );
      dispatch(
        authUserUpdated({
          user: user,
          isSubscribe: true,
          initialSetup: true,
          stripePriceId: !!selectedPlan ? selectedPlan.price.id : "",
        })
      );
    } else if (intentType === 3) {
      dispatch(
        authUserUpdated({
          user: user,
          stripePriceId: !!selectedPlan ? selectedPlan.price.id : "",
        })
      );
    }
  };

  // HANDLED STRIPE JS NOT LOADING ERROR
  useEffect(() => {
    if (!!elements) {
      var paymentElement = elements && elements.getElement("payment");
      if (!!paymentElement) {
        paymentElement.on("loaderror", function (element) {
          if (element.error.message) {
            setTimeout(() => {
              setStripeErrorMessage(element.error.message);
              setIsStripeLoading(false);
            }, 2000);
          }
        });
      }
    }
  }, [elements]);

  return (
    <>
      <Modal
        className="cs-common-modal-overlay stripe-form"
        show={true}
        style={{ zIndex: "99999999" }}
        centered
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <span className="cs-neutral-70 cs-previous-btn" onClick={closeModal}>
            <CapsyncIcon
              title="chevron-left-outlined"
              size="24px"
              alt="LeftIcon"
            />
          </span>
          <Modal.Title className="default-semi-bold-h3 card-modal-head">
            Enter card details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="cs-form-group">
            <div className="cs-stripe-spinner">
              {isStripeLoading && (
                <span className="cs-common-spinner cs-primary">
                  <CapsyncIcon title="loading-outlined" size="30" />
                </span>
              )}
            </div>
            <PaymentElement
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleCardNumberChange}
              onReady={() => setIsStripeLoading(false)}
            />
            {/* {!isStripeLoading && intentType != 1 && window.localStorage.getItem("pfs_access_id") == null && ( */}
            {!isStripeLoading &&
              intentType == 3 &&
              window.localStorage.getItem("pfs_access_id") == null && (
                <Form.Check
                  type="checkbox"
                  className="cs-form-check-box"
                  onChange={(e) => {
                    setDefaultPaymentCheck(e.target.checked);
                  }}
                  checked={defaultPaymentCheck}
                  label={
                    <span
                      className="default-regular-body-text-m cs-neutral-90"
                      onClick={() => {
                        setDefaultPaymentCheck(!defaultPaymentCheck);
                      }}
                    >
                      {intentType === 3
                        ? "Would you like to make this as default payment method?"
                        : "Would you like to save this payment method?"}
                    </span>
                  }
                />
              )}
            {stripeErrorMessage && (
              <>
                <span className="form-error-msg default-light-body-text-s cs-danger">
                  {stripeErrorMessage}
                </span>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="cs-modal-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit()}
              className="cs-btn-primary md-btn default-regular-h5"
            >
              Continue
              {loading && (
                <span className="cs-common-spinner cs-neutral-40">
                  <CapsyncIcon title="loading-outlined" size="18" />
                </span>
              )}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StripeCheckOutForm;
