/* eslint-disable */
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import {
  assetsCategory,
  liabilitiesCategory,
} from "../../../config/finance";
import commonNames from "../../../config/commonNames";
import AssetCategoryAllData from "../category_all_data/AssetCategoryAllData";
import LiabilityCategoryAllData from "../category_all_data/LiabilityCategoryAllData";

const FinanceCategoryTables = (props) => {
  return (
    <>
      <Container fluid>
        <div className="pfs-assets-table">
          <Row>
            <Col lg={12}>
              <h2 className="pfs-h2-title default-semi-bold-h1 cs-neutral-100">
                {commonNames.Assets}
              </h2>
              {assetsCategory.map((itr) => {
                return (
                  <>
                    <AssetCategoryAllData
                      closeModal={props.closeModal}
                      toastMessage={props.toastMessage}
                      url={`/individual/assets/${itr.url}`}
                      title={itr.title}
                      category={itr.url}
                    />
                  </>
                );
              })}
            </Col>
          </Row>
        </div>

        <div className="pfs-liabilities-table">
          <Row>
            <Col lg={12}>
              <h2 className="pfs-h2-title default-semi-bold-h1 cs-neutral-100">
                {commonNames.Liabilities}
              </h2>
              {liabilitiesCategory.map((itr) => {
                return (
                  <>
                    <LiabilityCategoryAllData
                      closeModal={props.closeModal}
                      toastMessage={props.toastMessage}
                      url={`/individual/liabilities/${itr.url}`}
                      title={itr.title}
                      category={itr.url}
                    />
                  </>
                );
              })}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
export default FinanceCategoryTables;
