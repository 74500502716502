/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ProgressBar, Modal, Image } from "react-bootstrap";
import CapsyncLoader from "../../../../assets/images/capsync-loader.gif";

const PaymentProgressBar = () => {
  const [prog, setpProg] = useState(0);

  useEffect(() => {
    let timer = setInterval(() => {
      setpProg((prev) => prev + 1);
    }, 800);

    return () => clearInterval(timer);
  }, []);
  return (
    <>
      <Modal
        show={true}
        onHide={false}
        centered
        className="cs-common-modal-overlay"
      >
        <Modal.Body className="cs-sign-modal">
          <div className="text-center api-prog-bar sign-loader-area">
            <div className="text-center">
              <Image src={CapsyncLoader} alt="" className="w-25" />
            </div>
            <div>
              <strong className="sign-prog-bar-heading default-regular-sub-heading-s cs-neutral-100">
                Please Wait
              </strong>
            </div>
            <div className="cs-progress-bar">
              <ProgressBar
                now={(1 - 1 / prog) * 100}
                className="sign-load-bar"
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PaymentProgressBar;
