/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  notesreceivableGetAll,
  categoryGetAll,
  notesrevievable_loan_details,
  categoryTotal,
  notespayable_loan_details,
  categoryFindRecord
} from "../../slices/category.slice";
import moment from "moment";
import edit from "../../../assets/icons/svg/outlined/edit-outlined.svg";
import { calculateAmortization } from "../../util/common";
import Amoritzation from "../../commonComponent/component/amoritzation/Amoritzation";

const Amortization = (props) => {
  const [notesReceivableGetData, setNotesReceivableGetData] = useState({});
  const [totalassets, settotalassets] = useState();
  const [modalTitle, setModalTitle] = useState("");
  const [entryId, setEntryId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const dispatch = useDispatch();
  const [dataAsset, setDataAsset] = useState([]);
  const { row_id } = useParams();
  const [allData, setAllData] = useState({});
  const [data, setData] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();

  const category1 = window.location.pathname.substring(1).split("/")[3];
  const categoryType = window.location.pathname.substring(1).split("/")[1];

  let store = location.pathname.split("/");
  const category = store[store.length - 2];

  let id;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const fetchDetails = () => {
    if (category && category === "notespayabletobanksandothers") {
      dispatch(notespayable_loan_details({ id: row_id }))
        .unwrap()
        .then((response) => {
          setAllData(response[0]);
          setData(response);
        });
    } else if (category && category === "accountnotesreceviable") {
      dispatch(notesrevievable_loan_details({ id: row_id }))
        .unwrap()
        .then((response) => {
          setAllData(response[0]);
          setData(response);
        });
    }
    else {
      dispatch(
        categoryFindRecord({
          id: row_id,
          category: category1,
          main_category: categoryType,
        })
      )
        .unwrap()
        .then((response) => {

          setAllData(response);
          setData(response);
        })
        .catch((err) => {
          console.log(err)
        });
    }

  };

  useEffect(() => {
    fetchDetails()
  }, [modalShow]);

  // console.log("AllllDataaaaaaaaaaaaaaaaa", allData);
  // const formattedDate = moment(allData.loanStarting_date).format("MM/DD/YY");
  const formattedDate = allData && allData.date_Loan ? moment(allData.date_Loan).format("MM/DD/YY") : moment(allData.loanStarting_date).format("MM/DD/YY");

  const [show, setShow] = useState(false);
  const handleOpen = () => {
    setShow(!show); // Toggle accordion
  };
  //Amortization table calculations started here
  // let startdate = allData.loanStarting_date;
  let startdate = allData.date_Loan ? allData.date_Loan : allData.loanStarting_date;
  let enddate = allData.maturity_date;
  let payementFrequency = allData.frequency;
  let TotalBalance = allData.loan_amount;
  let payFrequency = allData.frequency ? allData.frequency : allData.frequencyTime;
  // Example usage:
  let principall = allData.loan_amount; // Principal amount
  const interestRate = allData.annual_rate; // 5% interest rate
  let time =
    payFrequency == "Monthly" ||
      payFrequency == "monthly"
      ? allData.loan_period
      : allData.loan_period * 12; // Time in years
  let paymentsPerYear = 12
  // if (payementFrequency === "Monthly") {
  //   paymentsPerYear = 12; // Monthly payments in a year
  // } else if (payementFrequency === "Quarterly") {
  //   paymentsPerYear = 4;
  // } else {
  //   paymentsPerYear = 1;
  // }
  const monthlyPaymentt = calculateAmortization(
    principall,
    interestRate,
    time,
    paymentsPerYear,
    payFrequency
  );

  let simpleInterest = monthlyPaymentt * time - TotalBalance;
  // if (payFrequency === "Yearly" || payFrequency === "yearly") {
  //   simpleInterest = monthlyPaymentt * (time * paymentsPerYear) - TotalBalance;
  // } else {
  //   simpleInterest = monthlyPaymentt * time - TotalBalance;
  // }
  // let TotalPaidAmount = simpleInterest + principall;
  let TotalPaidAmount = allData.payment
  let TotalInterest = TotalPaidAmount - TotalBalance;
  let monthpayement = Math.min(
    (allData.loan_amount + simpleInterest) / (time * 12)
  );
  // if (payementFrequency === "Monthly") {
  //   monthpayement = Math.min(
  //     (allData.loan_amount + simpleInterest) / (time * 12)
  //   );
  // } else if (payementFrequency === "Quarterly") {
  //   monthpayement = Math.min(
  //     (allData.loan_amount + simpleInterest) / (time * 4)
  //   );
  // } else {
  //   monthpayement = Math.min((allData.loan_amount + simpleInterest) / time);
  // }
  useEffect(() => {
    fetchDetails();
  }, []);

  const amortizationTable = [];
  let interRate = allData.annual_rate / 100;
  let remainingAmount = allData.loan_amount + simpleInterest;
  let monthlypayement = Math.min(
    (allData.loan_amount + simpleInterest) / (time * 12)
  );
  // if (payementFrequency === "Monthly") {
  //   monthlypayement = Math.min(
  //     (allData.loan_amount + simpleInterest) / (time * 12)
  //   );
  // } else if (payementFrequency === "Quarterly") {
  //   monthlypayement = Math.min(
  //     (allData.loan_amount + simpleInterest) / (time * 4)
  //   );
  // } else {
  //   monthlypayement = Math.min((allData.loan_amount + simpleInterest) / time);
  // }
  let loan_time_period = time * 12;
  // if (payementFrequency === "Monthly") {
  //   loan_time_period = time * 12;
  // } else if (payementFrequency === "Quarterly") {
  //   loan_time_period = time * 4;
  // } else {
  //   loan_time_period = time;
  // }
  let total = allData.loan_amount + simpleInterest + monthlypayement;
  const start = new Date(startdate);
  // start.setDate(start.getDate() - 1);
  // start.setMonth(start.getMonth() - 1); // Move to the next month 
  start.setDate(1);
  const end = new Date(enddate);

  let onemonth = allData.loan_amount / (time * 12);
  // if (payementFrequency === "Monthly") {
  //   onemonth = allData.loan_amount / (time * 12);
  // } else if (payementFrequency === "Quarterly") {
  //   onemonth = allData.loan_amount / (time * 4);
  // } else {
  //   onemonth = allData.loan_amount / time;
  // }
  let principal = allData.loan_amount;
  let prinicipalpayementt = monthlyPaymentt - principal * (interRate / 12);
  // if (payFrequency === "Yearly" || payFrequency === "yearly") {
  //   prinicipalpayementt = monthpayement - principal * (interRate / 12);
  // } else {
  //   prinicipalpayementt = monthlyPaymentt - principal * (interRate / 12);
  // }


  let interestt = monthlyPaymentt - prinicipalpayementt;
  // if (payFrequency === "Yearly" || payFrequency === "yearly") {
  //   interestt = monthpayement - prinicipalpayementt;

  // } else {
  //   interestt = monthlyPaymentt - prinicipalpayementt;
  // }
  let loop = new Date(start);
  let interesttPaid = interestt - interestt;


  let month = 0;
  while (remainingAmount > 0 && month < time * 12 && loop <= end) {

    let payementFrequency = allData.frequency;
    let newDate = loop.setMonth(loop.getMonth() + 1);
    // if (payementFrequency === "Quarterly") {
    //   newDate = loop.setDate(loop.getDate() + 92);
    // } else if (payementFrequency === "Yearly" || payementFrequency === "yearly") {
    //   newDate = loop.setDate(loop.getDate() + 365);
    // } else {
    //   newDate = loop.setMonth(loop.getMonth() + 1);
    // }
    loop = new Date(newDate);
    // mydate.toDateString().split(" ").slice(1).join(" ");
    let monthlyInterestRate = time / 12;
    // if (payementFrequency === "Monthly") {
    //   monthlyInterestRate = time / 12;
    // } else if (payementFrequency === "Quarterly") {
    //   monthlyInterestRate = time / 4;
    // } else {
    //   monthlyInterestRate = time;
    // }
    const monthlyInterest = remainingAmount * monthlyInterestRate;
    let onemonth = allData.loan_amount / (time * 12);
    // if (payementFrequency === "Monthly") {
    //   onemonth = allData.loan_amount / (time * 12);
    // } else if (payementFrequency === "Quarterly") {
    //   onemonth = allData.loan_amount / (time * 4);
    // } else {
    //   onemonth = allData.loan_amount / time;
    // }

    let monthlyPayement = Math.min((allData.loan_amount + simpleInterest) / time);
    // if (payFrequency === "Yearly" || payFrequency === "yearly") {
    //   monthlyPayement = Math.min(
    //     (allData.loan_amount + simpleInterest) / (time * 12)
    //   );
    // } else {
    //   monthlyPayement = Math.min((allData.loan_amount + simpleInterest) / time);
    // }
    let loan_time_period = time * 12;
    // if (payementFrequency === "Monthly") {
    //   loan_time_period = time * 12;
    // } else if (payementFrequency === "Quarterly") {
    //   loan_time_period = time * 4;
    // } else {
    //   loan_time_period = time;
    // }
    total -= monthlyPayement;
    remainingAmount -= monthlyPayement;

    //
    let interest;

    let prinicipalpayement = monthlyPayement - principal * (interRate / 12);
    principal -= prinicipalpayement;

    interest = monthlyPayement - prinicipalpayement;
    interesttPaid += interest;
    let amortizationRow;
    // if (payFrequency === "Yearly" || payFrequency === "yearly") {
    //   amortizationRow = {
    //     Total: interesttPaid,
    //     month: month + 1,
    //     dayloop: loop.toDateString().split(" ").slice(1).join(" "),
    //     total: prinicipalpayement,
    //     Interest: interest,
    //     principalAmount: principal,
    //     interestPaid: monthlyInterest,
    //     monthlyPayement: monthlyPayement,
    //     remainingAmount: remainingAmount,
    //   };
    // } else {
    amortizationRow = {
      Total: interesttPaid,
      month: month + 1,
      dayloop: loop.toDateString().split(" ").slice(1).join(" "),
      total: prinicipalpayement,
      Interest: interest,
      principalAmount: principal,
      interestPaid: monthlyInterest,
      monthlyPayement: monthlyPayement,
      remainingAmount: remainingAmount,
    };
    // }
    amortizationTable.push(amortizationRow);
    month++;
  }



  //ammortization table calculation ends here

  const modelData = (title, id) => {
    setModalTitle(title);
    setEntryId(id);
    setModalShow(true);
  };
  const getCategoryTotal = () => {
    dispatch(categoryTotal({ id: id, main_category: "assets" }))
      .unwrap()
      .then((response) => {
        setNotesReceivableGetData(response.notesReceivable_Quarter);
      })
      .catch(() => { });
  };
  useEffect(() => {
    getCategoryTotal();
  }, []);
  //----
  const getTableList = () => {
    // dispatch(notesreceivableGetAll({ id: id }))
    dispatch(
      categoryGetAll({
        id: id,
        category: "accountnotesreceviable",
        main_category: "assets",
      })
    )
      .unwrap()
      .then((response) => {
        const totalShareVal = response.manual_entries.reduce(
          (prev, curr) => prev + curr.loan_amount,
          0
        );
        settotalassets(totalShareVal);
        setDataAsset(response);
      })
      .catch((err) => {
        //
      });
  };

  useEffect(() => {
    getTableList();
  }, []);

  let startAmount = Number(allData.loan_amount).toLocaleString();
  const Data = [
    {
      name: "Interest",
      value: TotalInterest,
      fill: "#EA780E",
    },
    {
      name: "Principal",
      value: allData.loan_amount,
      fill: "#0024D4",
    },
  ];

  const defaultLabelStyle = {
    fontSize: "5px",
    fontFamily: "Arial",
    paddingleft: "0px",
  };

  // Group the data by year
  const groupedData = amortizationTable.reduce((acc, row) => {
    const year = new Date(row.dayloop).getFullYear();

    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(row);
    return acc;
  }, {});


  return (
    <>

      <section className="assets-main-section amortizationSection">
        <Amoritzation
          allData={allData}
          modelData={props.modelData}
          row_id={row_id}
          edit={edit}
          startAmount={startAmount}
          payFrequency={payFrequency}
          formattedDate={formattedDate}
          Data={Data}
          defaultLabelStyle={defaultLabelStyle}
          simpleInterest={simpleInterest}
          TotalInterest={TotalInterest}
          TotalPaidAmount={TotalPaidAmount}
          groupedData={groupedData}
          handleOpen={handleOpen}
          is_loader={props.is_loader}
          setModalShow={setModalShow}
          modalShow={modalShow}
          editAccessUser={props.editAccessUser}
        />
      </section>

    </>
  );
};

export default Amortization;
