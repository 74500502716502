import React from "react";
import { Form, Row, Col, Image, FormGroup } from "react-bootstrap";
import SubMenuHeader from "../SubMenuHeader";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import SubMethodsList from "../../SubMethodsList";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";

// sequence No. one  (Asmita date-29th June => Do not remove this line)
const MethodIdTwentyThree = (props) => {


  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Accredited Investor",
      url: `/individual/verifyinvestor/accreditedInvestor/${props.verificationType}/${props.unique_code}`,
    },
  ];



  return (
    <>
      {props.is_loader.assets === true || props.is_loader.liabilities === true ? <SkeletonAllSubMethods /> :
        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="Trust Assets"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>
              <SubMenuHeader />
            </div>
            <div className="method-section">
              <div className="default-regular-body-text-l cs-neutral-70"> <SubMethodsList methodId={23} /></div>

              <div className="cs-divider"></div>
              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert} />
                <div className="row-spacing">
                  <p className="default-regular-sub-heading-m cs-neutral-70">
                    In order to verify your accreditation we need you to upload
                    evidence. Please upload the appropriate files below and black-out
                    any sensitive information.
                  </p>
                  <p className="default-regular-sub-heading-m cs-danger">
                    Upon a successful verification, the expiration date of the
                    verification letter will be based on the evidence provided to
                    reviewers, e.g. if a 3rd party letter or a monthly statement is 2
                    months old at the time of submission for review the verification
                    letter will expire in 1 month.
                  </p>
                </div>

                <Form.Label className="default-regular-sub-heading-m cs-neutral-90">
                  Upload a statement that is no later than 90 days old showing your assets in excess of $5,000,000 USD:
                </Form.Label>
                <UploadFileBlock
                  files={props.files}
                  setOpenModal={props.setOpenModal}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile}
                  showFile={props.showFile}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink}
                  uploadError={props.uploadError}

                />

                <hr className="cs-divider"></hr>

                <FormGroup className="cs-form-group">
                  <Form.Label className="default-regular-sub-heading-m cs-neutral-90">
                    Upload other evidence:
                  </Form.Label>
                  <UploadFileBlock
                    files={props.files2}
                    setOpenModal={props.setOpenModal2}
                    mediaAlert={props.mediaAlert}
                    deleteFile={props.deleteFile2}
                    showFile={props.showFile2}
                    pfsLink={props.pfsLink}
                    pfsName={props.pfsName}
                    pfsDelete={props.pfsDelete}
                    showDeleteSpinner={props.showDeleteSpinner}
                    showPfsLink={props.showPfsLink2}
                  />
                </FormGroup>

                <NotesBlock setNotesValue={props.setNotesValue} />
              </Form>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default MethodIdTwentyThree;
