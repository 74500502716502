import React, { useEffect, useState } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CommonMethodsHeader from "./CommonMethodsHeader";
import commonNames from "../../../../../config/commonNames";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { add_update_verification_methodId } from "../../../../../slices/verifyInvestor";
import SubMethodsList from "../SubMethodsList";
import SkeletonMethodsDashboard from "./SkeletonMethodsDashboard";
import ConfirmationModel from "../Modals/ConfirmationModel";

const QualifiedClientMethods = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false)

  const Vi_Location = useLocation();
  // const [enableLoader, setEnableLoader] = useState(false);
  // const [selectInverstorType, setSelectInverstorType] = useState("Select Option");


  let unique_string = Vi_Location.pathname.split("/").slice(-1).toString();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleConfirmationModal = () => {
    setShowConfirmation(true);
  }

  const GoToSubMethods = (type, methodID) => {
    // if (selectInverstorType !== "Select Option" ){
    // setEnableLoader(true);
    dispatch(
      add_update_verification_methodId({
        unique_rowID: unique_string,
        verification_method_id: methodID,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setTimeout(() => {
            navigate(
              `/individual/verifyinvestor/accreditedInvestor/${type}/${unique_string}/${methodID}`
            );
          }, 1000);
          // setEnableLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }else {
    //   toast.error("Select an investor type.")
    // }

  };

  return (
    <>

      <section className="accreditation-main-section accreditation-inner-section">
        {/* {enableLoader ? (
            <CapsyncLoader />
          ) : ( */}
        <Container fluid>
          {
            props.is_loader.assets === true || props.is_loader.liabilities === true ? < SkeletonMethodsDashboard /> :
              <>
                <CommonMethodsHeader investorType="QualifiedClient"
                // selectInverstorType={selectInverstorType} setSelectInverstorType={setSelectInverstorType}  //code for selectInverstorType
                />
                <div className="verification-method-box">
                  <div className="accredited-method">
                    <Row>
                      <Col lg={12}>
                        <span className="cs-neutral-100 default-medium-sub-heading-m">Verification Method</span>
                      </Col>
                    </Row>
                  </div>

                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={27} /></div>
                      </Col>
                      <Col xl={1} md={3}>
                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5"
                          onClick={() => GoToSubMethods("qualifiedclient", "27")}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={28} /></div>
                      </Col>
                      <Col xl={1} md={3}>

                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5"
                          onClick={() => GoToSubMethods("qualifiedclient", "28")}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={29} /></div>
                      </Col>
                      <Col xl={1} md={3}>

                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5"
                          onClick={() => GoToSubMethods("qualifiedclient", "29")}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={30} /></div>
                      </Col>
                      <Col xl={1} md={3}>
                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5"
                          onClick={() => GoToSubMethods("qualifiedclient", "30")}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={31} /></div>
                      </Col>
                      <Col xl={1} md={3}>

                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5"
                          onClick={() => GoToSubMethods("qualifiedclient", "31")}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  {/* <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div> */}
                </div>
                <p className="default-regular-sub-heading-m cs-neutral-80">
                  If you are not a qualified client or wish to cancel this
                  verification request, &nbsp;
                  <Link
                    onClick={handleConfirmationModal}
                    className="vi-link-color "
                  >
                    click here
                  </Link>
                </p>
              </>
          }
        </Container>
        {/* )} */}
      </section>

      <ConfirmationModel
        setShowConfirmation={setShowConfirmation}
        showConfirmation={showConfirmation}

      />
    </>
  );
};

export default QualifiedClientMethods;
