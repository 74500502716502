import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import StripeCheckOutForm from "./StripeCheckOutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { create_payment_intent } from "../../../slices/user_subscription";

const StripeCheckOut = (props) => {
  const {
    intentType,
    setShowStripeForm,
    selectedPlan,
    unique_row_id,
    setBillingDetailModal,
    amount,
    sendData,
    setPaymentLoader,
  } = props;

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [clientSecret, setClientSecret] = useState("");
  const [subscriptionDetail, setSubscriptionDetail] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [paymnetintentType, setPaymnetintentType] = useState("");

  let email = user && user.user.email;

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#0024d4",
      colorBackground: "#f5f5f5",
      borderColor: "#c2c2c2",
      colorText: "#0a0a0a",
      colorDanger: "#e00000",
      gridRowSpacing: "24px",
      gridColumnSpacing: "20px",
      tabSpacing: "8px",
      colorTextPlaceholder: "#757575",
      tabIconColor: "#757575",
      tabIconHoverColor: "#0024d4",
      iconCardCvcColor: "#0024d4",
      borderRadius: "4px",
    },
    rules: {
      ".Tab": {
        border: "1px solid #c2c2c2",
        boxShadow: "none",
      },

      ".Tab:hover": {
        color: "var(--colorIconTabSelected)",
        borderColor: "var(--colorIconTabSelected)",
        backgroundColor: "#eef1ff",
      },

      ".Tab:focus": {
        boxShadow: "none",
      },

      ".Tab--selected": {
        borderColor: "#0024d4",
        backgroundColor: "#eef1ff",
        color: "var(--colorIconTabSelected)",
        tabIconSelectedColor: "#0a0a0a",
        boxShadow: "none",
      },
      ".Tab--selected:focus": {
        boxShadow: "none",
      },
    },
  };
  const options = {
    // clientSecret: clientSecret,
    mode:
      intentType === 1
        ? !user.user.is_used_free_trial
          ? "setup"
          : "subscription"
        : intentType === 2
        ? "payment"
        : intentType === 3
        ? "setup"
        : intentType === 4
        ? "subscription"
        : "payment",
    amount:
      intentType === 2
        ? amount
          ? amount * 100
          : null
        : intentType === 1 && user.user.is_used_free_trial && !!selectedPlan
        ? selectedPlan.price.unit_amount
        : null,
    currency: "usd",
    payment_method_types: ["card", "us_bank_account"],
    payment_method_options:
      (intentType === 1 && user.user.is_used_free_trial) || intentType === 2
        ? {
            us_bank_account: { verification_method: "instant" },
          }
        : null,
    appearance,
  };

  // CALL CREATE PAYMENT INTENT
  useEffect(() => {
    dispatch(
      create_payment_intent({
        email,
        intent_type: intentType,
        price_id: !!selectedPlan ? selectedPlan.price.id : null,
        amount: amount ? amount * 100 : "",
        unique_row_id: !!unique_row_id ? unique_row_id : "",
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setPaymnetintentType(response.data.payment_intent_type);
          setCustomerId(response.data.payment_intent.customer);
          setClientSecret(response.data.payment_intent.client_secret);
          setSubscriptionDetail(response.data.subscription);
        }
      })
      .catch((err) => {
        console.log("err stripe checkout", err);
      });
  }, []);

  return (
    <>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <StripeCheckOutForm
            clientSecret={clientSecret}
            customerId={customerId}
            selectedPlan={selectedPlan}
            subscriptionDetail={subscriptionDetail}
            setShowStripeForm={setShowStripeForm}
            intentType={intentType}
            paymnetintentType={paymnetintentType}
            setBillingDetailModal={setBillingDetailModal}
            unique_row_id={unique_row_id}
            sendData={sendData}
            setPaymentLoader={setPaymentLoader}
          />
        </Elements>
      )}
    </>
  );
};

export default StripeCheckOut;
