/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Form, Button, Container, Card } from "react-bootstrap";
import moment from "moment";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";

import { getAllAuditData } from "../../../../slices/auditLogs";
import CommonSettingHeading from "../CommonHeader/CommonSettingHeading";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import CapsyncSectionHeader from "../../../../commonComponent/CapsyncSectionHeader";
import "../../settings/Audit_logs/audit_logs.css";
import CommonSettingDropdown from "../CommonHeader/CommonSettingDropdown";
import SkeletonAuditlog from "./SkeletonAuditlog";
import Skeleton from "react-loading-skeleton";
import { roleName } from "../../../../config/commonRoleName";
import Pagination from "../../../../commonComponent/Pagination";
import { customStyles } from "../../../../util/common";
import { useLocation } from "react-router-dom";

const columns = [
  {
    name: "Date",
    selector: (row) => moment(row.createdAt).format("MM/DD/YYYY"),
    sortable: true,
    sortField: "createdAt",
  },
  {
    name: "Time",
    selector: (row) => moment(row.createdAt).format("HH:mm"),
    sortable: false,
  },
  {
    name: "User Name",
    selector: (row) => row.firstName + " " + row.lastName,
    sortable: false,
  },
  {
    name: "Categories",
    selector: (row) => row.module_name,
    sortable: false,
  },
  {
    name: "Sub Category",
    selector: (row) => row.sub_module_name,
    sortable: false,
  },
  {
    name: "Event",
    selector: (row) => row.title,
    sortable: false,
  },
];

const AuditLogs = (props) => {
  const [allData, setAllData] = useState([]);
  const [search, setSearch] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [userTab, setUserTab] = useState(roleName.individual);

  const [rowStartIdx, setRowStartIdx] = useState(0);
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownItems[0]);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isMySettings = pathname?.split("/")?.[2] === "my-settings";
  const { user, authUser } = useSelector((state) => state.auth);
  let id;

  if (isMySettings) {
    id = authUser?.user?.id;
  } else if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  useEffect(() => {
    // fetchAuditLogs();
    onChangeTabs(roleName.individual);
  }, []);

  useEffect(() => {
    if (typeof search === "string") {
      const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
      const searchRegex = new RegExp(escapedSearch, "i"); // "i" for case-insensitive search

      const result = allData.filter((rowData) => {
        return (
          rowData.sub_module_name.toLowerCase().match(searchRegex) ||
          rowData.firstName.toLowerCase().match(searchRegex) ||
          rowData.module_name.toLowerCase().match(searchRegex) ||
          rowData.lastName.toLowerCase().match(searchRegex) ||
          moment(rowData.createdAt).format("HH:mm").match(searchRegex) ||
          moment(rowData.createdAt).format("MM/DD/YYYY").match(searchRegex) ||
          rowData.title.toLowerCase().match(searchRegex) ||
          (
            rowData.firstName.toLowerCase() +
            " " +
            rowData.lastName.toLowerCase()
          ).match(searchRegex)
        );
      });

      setFilteredData(result);
    } else {
      // Handle the case when search is not a string (e.g., setFilteredData(allData))
    }
  }, [search, allData]);

  const fetchAuditLogs = () => {
    dispatch(getAllAuditData({ id: id, module: roleName.individual }))
      .unwrap()
      .then((response) => {
        // setIndividualActive(true);
        setFiltered(response.data.individual);
        setAllData(response.data.individual);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeTabs = (value) => {
    setUserTab(value);
    dispatch(getAllAuditData({ id: id, module: roleName.individual }))
      .unwrap()
      .then((response) => {
        let data = response.data;
        data = value === roleName.individual ? data.individual : data.wealth;
        setFilteredData(data);
        setAllData(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const handleSort = async (column, sortDirection) => {
    const sortedRecord = [...filteredData].sort((a, b) => {
      const aDate = new Date(a.createdAt);
      const bDate = new Date(b.createdAt);

      if (sortDirection === "asc") {
        return aDate - bDate;
      } else {
        return bDate - aDate;
      }
    });
    setFilteredData(sortedRecord);
  };
  return (
    <section className="settings-main-section">
      <Container fluid>
        {props.is_loader.assets === true ||
        props.is_loader.liabilities === true ? (
          <div className="cs-section-topbar">
            <div className="skeleton-breadcrumb">
              <div className="skeleton-breadcrumb-1">
                {" "}
                <Skeleton width={50} />
              </div>
              <div className="skeleton-breadcrumb-2">
                {" "}
                <Skeleton width={20} />
              </div>
              <div className="skeleton-breadcrumb-3">
                <Skeleton width={50} />
              </div>
            </div>
            <div className="cs-title">
              <h1 className="default-semi-bold-h1 cs-neutral-100">
                <Skeleton width={200} />
              </h1>
            </div>
          </div>
        ) : (
          <div className="cs-section-topbar">
            <CapsyncSectionHeader
              mainTitle="Dashboard"
              mainUrl="/individual"
              pagetitle={isMySettings ? "My Settings" : "Settings"}
              subTitle={isMySettings ? "My Settings" : "Settings"}
            />
          </div>
        )}

        {props.is_loader.assets === true ||
        props.is_loader.liabilities === true ? (
          <div className="setting-mobile-dropdown for-mob">
            <Skeleton width="100" height={30} />
          </div>
        ) : (
          <CommonSettingDropdown editAccessUser={props.editAccessUser} />
        )}

        <Card className="audit-log-card">
          <div className="settings-body">
            {props.is_loader.assets === true ||
            props.is_loader.liabilities === true ? (
              <div className="for-des">
                {" "}
                <div className="skeleton-account-tabs">
                  <p className="skeleton-account-tab">
                    {" "}
                    <Skeleton width={100} />
                  </p>
                  <p className="skeleton-account-tab">
                    {" "}
                    <Skeleton width={100} />
                  </p>
                  <p className="skeleton-account-tab">
                    {" "}
                    <Skeleton width={80} />
                  </p>
                  <p className="skeleton-account-tab">
                    {" "}
                    <Skeleton width={150} />
                  </p>
                  <p className="skeleton-account-tab">
                    {" "}
                    <Skeleton width={100} />
                  </p>
                </div>
              </div>
            ) : (
              <CommonSettingHeading editAccessUser={props.editAccessUser} />
            )}
            <div className="audit-logs-page">
              {props.is_loader.assets === true ||
              props.is_loader.liabilities === true ? (
                <SkeletonAuditlog />
              ) : (
                <>
                  <div className="cs-tabs-flex">
                    <div className="cs-tabs">
                      <Button
                        onClick={(e) => onChangeTabs(roleName.individual)}
                        className={`cs-stroke-default-tab default-regular-h5 ${
                          userTab === roleName.individual
                            ? "cs-stroke-active"
                            : ""
                        }`}
                      >
                        Individual
                      </Button>
                      <Button
                        onClick={(e) => onChangeTabs("wealthManager")}
                        className={`cs-stroke-default-tab default-regular-h5 ${
                          userTab === "wealthManager" ? "cs-stroke-active" : ""
                        }`}
                      >
                        Professional
                      </Button>
                    </div>
                    <div className="cs-search-bar">
                      <Form.Group className="cs-form-group">
                        <Form.Control
                          type="text"
                          placeholder="Search..."
                          value={search}
                          onChange={(event) => setSearch(event.target.value)}
                          // className={search && "cs-input-field-active"}
                        />
                        <span className="input-field-icon cs-neutral-70">
                          <CapsyncIcon title="search-outlined" size="18" />
                        </span>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="cs-data-table">
                    <DataTable
                      columns={columns}
                      data={
                        filteredData
                          ? filteredData.slice(
                              rowStartIdx,
                              rowStartIdx + rowsPerPage
                            )
                          : []
                      }
                      sortIcon={
                        <span className="cs-neutral-100">
                          <CapsyncIcon title="sort-outlined" size="22" />
                        </span>
                      }
                      onSort={handleSort}
                      sortServer
                      customStyles={customStyles}
                    />
                    <Pagination
                      rowStartIdx={rowStartIdx}
                      setRowStartIdx={setRowStartIdx}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                      paginationDropdownItems={paginationDropdownItems}
                      paginationList={filteredData ? filteredData : []}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </Card>
      </Container>
    </section>
  );
};

export default AuditLogs;
