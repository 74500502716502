/* eslint-disable */
import React, { useCallback, useState, useEffect } from "react";
import { Modal, Form, Row, Col, Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import DatePicker from "react-date-picker";
import NumberFormat from "react-currency-format";
import { useFormik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import validationObj from "./validationSchema";
import { adjustLength } from "../../../../customHooks/adjustLength";
import {
  categoryAdd,
  categoryUpdateRecord,
} from "../../../../slices/category.slice";
import commonNames from "../../../../config/commonNames";
import { combine } from "../../../../util/common";
import { validateDuplicateFileName } from "../../../../config/validateDuplicateFileName";
import {
  get_user_storage,
  useProgressUploadDetails,
  useUploadStorage,
} from "../../../../slices/fileManagement.slice";

const OtherLiabilities = (props) => {
  const { onFormClose, onFormSubmit, record, showModal } = props;
  const storageData = useUploadStorage();
  const progressUploadDetails = useProgressUploadDetails();

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const [loading, setLoading] = useState(false);
  const [storeAcceptedFiles, setStoreAcceptedFiles] = useState([]);
  const [initialValues, setInitialValues] = useState({
    user_id: id,
    description: "",
    balance_due: "",
    date_updated: moment().format(),
    notes: "",
  });

  const [phoneNo, setPhoneNo] = useState("");
  const [msg, setMsg] = useState("");
  const [newDollar, setDollar] = useState(false);
  const [newDollar1, setDollar1] = useState(false);
  const [newDollar2, setDollar2] = useState(false);
  const [newDollar3, setDollar3] = useState(false);
  const [duplicateFileError, setDuplicateFileError] = useState("");
  const [storageError, setStorageError] = useState("");

  const checkPhoneValid = (event) => {
    if (event) {
      if (isValidPhoneNumber(event) === true) {
        setPhoneNo(event);
        setMsg("");
      } else {
        setPhoneNo(event);
        setMsg("Enter a valid phone number");
      }
    } else {
      setPhoneNo("");
      setMsg("Enter a valid phone number");
    }
  };

  const check = (para, a) => {
    const common = combine(para, a);
    if (common.value === "dollar") {
      setDollar(common.status);
    } else if (common.value === "dollar_one") {
      setDollar1(common.status);
    } else if (common.value === "dollar_two") {
      setDollar2(common.status);
    } else if (common.value === "dollar_three") {
      // setDollar3(common.status);
    }
  };

  useEffect(() => {
    if (!!record) {
      setInitialValues({
        user_id: record.user_id,
        description: record.description,
        balance_due:
          record.balance_due && record.balance_due.toLocaleString("en-US"),
        date_updated: new Date(
          moment(record.date_updated).format("MM/DD/YYYY")
        ),
        notes: record.notes == null ? "" : record.notes,
      });

      setPhoneNo(record.creditor_phonenumber);
    }
  }, [record]);

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema:
        validationObj.liability_otherLiabilities_validationSchema,
      onSubmit: async (values, { setErrors }) => {
        if (values.balance_due === "." || values.balance_due === "0.") {
          setErrors({ balance_due: "Enter a numeric value" });
          return;
        }
        setLoading(true);
        record && (values.id = record.id);
        values.creditor_phonenumber = phoneNo;
        values.user_id = id;
        values.pfs_access_id = id ? id : 0;
        const formdata = new FormData();
        const inProgressData = progressUploadDetails.length
          ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
          : 0;
        const used_space = storageData.usedSpace + inProgressData;

        formdata.append("user_details", JSON.stringify(values));
        formdata.append("user_id", values.user_id);
        formdata.append("used_space", used_space);
        formdata.append("institution", values.description);
        for (let i = 0; i < storeAcceptedFiles.length; i++) {
          formdata.append("attachments", storeAcceptedFiles[i]);
        }

        let data = {
          category: "otherliabilities",
          main_category: "liabilities",
        };

        if (!!record) {
          data = { ...data, newData: values };
          dispatch(categoryUpdateRecord(data))
            .unwrap()
            .then((response) => {
              if (response.code === 200) {
                onFormSubmit("otherliabilities");
                toast.success(response.message);
                props.dashboardAPi();
              }
              setLoading(false);
            })
            .catch((err) => {
              // setModalShow(false);
              toast.error(err.message);
              setLoading(false);
            });
        } else {
          data = { ...data, formdata: formdata };
          dispatch(categoryAdd(data))
            .unwrap()
            .then(async (response) => {
              if (response.code === 200) {
                const response = await dispatch(
                  get_user_storage({ id })
                ).unwrap();
                onFormSubmit("otherliabilities");
                // toast.success(response.message);
                props.dashboardAPi();
              } else if (response.code === 500) {
                setStorageError(response.message);
              }
              setLoading(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
            });
        }
      },
    });

  const handleChange = (e) => {
    setLoading(false);
    setFieldValue(e.target.name, e.target.value);
    setDuplicateFileError("");
  };

  // Upload File Handler
  const removeAcceptedFile = (params) => {
    let store = [...storeAcceptedFiles];
    store.splice(params, 1);
    setStoreAcceptedFiles(store);
    setDuplicateFileError("");
    setStorageError("");
  };
  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setDuplicateFileError("");
      setStorageError("");
      if (
        validateDuplicateFileName(
          acceptedFiles,
          storeAcceptedFiles,
          setDuplicateFileError
        )
      ) {
        setStoreAcceptedFiles([...acceptedFiles, ...storeAcceptedFiles]);
      }
    },
    [storeAcceptedFiles]
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    disabled: loading,
    onDropAccepted,
  });
  const files = storeAcceptedFiles.map((file, i) =>
    adjustLength(file.path, () => removeAcceptedFile(i))
  );

  return (
    <Modal
      show={showModal}
      backdrop="static"
      id="connect-your-institutions"
      aria-labelledby="contained-modal-title-vcenter"
      className="cap-dash-main-modal"
      centered
      keyboard={false}
      dialogClassName="cs-lg-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          {commonNames.OtherLiabilities}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <Row>
            <Col xs={12} md={12}>
              <Form.Group
                className={
                  record && record.api_provider === "plaid"
                    ? "cs-form-group cs-disabled"
                    : "cs-form-group"
                }
              >
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder=""
                  name="description"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.description}
                  className={values.description && "cs-input-field-active"}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  disabled={record && record.api_provider === "plaid"}
                />
                {errors.description && touched.description ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.description}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Balance Due</Form.Label>
                <NumberFormat
                  thousandSeparator={true}
                  placeholder=""
                  name="balance_due"
                  autoComplete="off"
                  value={values.balance_due}
                  maxLength={12}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  className={
                    values.balance_due
                      ? "dollar-symbol text-md-end form-control cs-input-field-active"
                      : "text-md-end form-control"
                  }
                  onKeyUp={(e) => check(e.target.value, "dollar")}
                  // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  onPaste={(e) => {
                    const clipboardData =
                      e.clipboardData || window.clipboardData;
                    const pastedText = clipboardData.getData("Text");

                    // Check if the pasted value is negative and prevent it if it is
                    if (parseFloat(pastedText) < 0) {
                      e.preventDefault();
                    }
                  }}
                  decimalScale={2}
                  onKeyDown={(e) => {
                    if (e.key === "." && e.target.value === "") {
                      e.preventDefault(); // Prevent typing another dot if there's already one
                    }
                  }}
                />
                {errors.balance_due && touched.balance_due ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.balance_due}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Date Updated</Form.Label>
                <DatePicker
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  name="date_updated"
                  value={values.date_updated}
                  onChange={(e) => {
                    setLoading(false);
                    setFieldValue("date_updated", e);
                  }}
                  // onBlur={handleBlur}
                  clearIcon={null}
                  className={
                    values.date_updated
                      ? "cs-input-field-active select-date-range cs-date-picker-calender"
                      : "cs-date-picker-calender"
                  }
                  calendarIcon={
                    <span className="cs-neutral-90">
                      <CapsyncIcon title="calender-outlined" size="18" />
                    </span>
                  }
                  format="MM/dd/y"
                  maxDate={new Date()}
                />
                {errors.date_updated && touched.date_updated ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.date_updated}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <>
            <Row>
              <Col xs={12} md={12}>
                <Form.Group className="cs-form-group">
                  <Form.Label>Notes</Form.Label>
                  <textarea
                    autoComplete="off"
                    type="text"
                    placeholder="Optional"
                    name="notes"
                    value={values.notes}
                    className={
                      values.notes
                        ? "cs-input-field-active cs-textarea"
                        : "cs-textarea"
                    }
                    onChange={handleChange}
                    // onBlur={handleBlur}
                  ></textarea>
                </Form.Group>
              </Col>
            </Row>
            {!record && (
              <>
                <Row>
                  <Col>
                    <Form.Label className="default-regular-sub-heading-xs cs-neutral-100">
                      Upload Files
                    </Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="cs-upload-box">
                      <div
                        {...getRootProps({})}
                        className={`cs-upload-files cursor-pointer ${
                          loading && "cs-neutral-60 disabled"
                        }`}
                      >
                        <input
                          {...getInputProps()}
                          disabled={loading}
                          name="attachments"
                        />
                        <span className="cs-primary">
                          <CapsyncIcon title="upload-files-filled" size="28" />
                        </span>
                        <span className="default-regular-sub-heading-s cs-neutral-90">
                          <span className="cs-primary">Browse</span>&nbsp;or
                          drag file here
                        </span>
                        <p className="default-regular-sub-heading-xxs">
                          Supports PDF, PNG, JPG and JPEG format
                        </p>
                      </div>
                      <span className="form-error-msg default-light-body-text-s cs-danger">
                        {storageError || duplicateFileError}
                      </span>
                      <aside className="category-attachments-files">
                        <ul className="attachment-files cs-files">{files}</ul>
                      </aside>
                    </div>
                  </Col>
                </Row>
              </>
            )}

            <div className="cs-modal-btn">
              <Button
                className="cs-btn-secondary md-btn default-regular-h5"
                onClick={onFormClose}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="cs-btn-primary md-btn default-regular-h5"
                disabled={loading}
              >
                Save
                {loading && (
                  <span className="cs-common-spinner cs-primary-main">
                    <CapsyncIcon title="loading-outlined" size="16" />
                  </span>
                )}
              </Button>
            </div>
          </>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default OtherLiabilities;
