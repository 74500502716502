import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Dropdown, Table } from "react-bootstrap";
import CommonViHeader from "./CommonViHeader";
import commonNames from "../../../../config/commonNames";
// import CapsyncLoader from "../../../../commonComponent/CapsyncLoader";
import {
  verify_investor_find,
  verify_request_and_send,
  remove_pending_req,
} from "../../../../slices/verifyInvestor";
import { DateFormat } from "../../../../util/common";
import { useNavigate } from "react-router-dom";
import UseFetchPermission from "../../../../customHooks/UseFetchPermission";
import PermissionPrevent from "../../../PermissionPrevent";
// import CapsyncLoader from "../../../capsyncLoader";
import CapsyncToolTip from "../../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import FirstModal_VI from "./Modals/FirstModal_VI";
import CapsyncSectionHeader from "../../../../commonComponent/CapsyncSectionHeader";
import { fetch_all_payment_plan } from "../../../../slices/common_payment_plans";
import UploadDocumentVI from "./UploadDocumentVI";
import SkeletonAccreditation from "./SkeletonAccreditation";
import DeleteModal from "../../../../commonComponent/modalPopup/deleteModal/DeleteModal";
import CapsyncLoader from "../../../../commonComponent/CapsyncLoader";
import { adjustLengthVi } from "../../../../customHooks/adjustLength";
import { socket } from "../../../../config/Socket";

const VI_Pending = ({ is_loader, toastMessage, ...props }) => {
  const { fetchUserPermission } = UseFetchPermission();
  const [storePending, setStorePending] = useState([]);
  const [storeComplete, setStoreComplete] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [firstModal, setFirstModal] = useState(false);
  const [getActivePlan, setGetActivePlan] = useState();
  const [selectedPlan, setSelectedPlan] = useState({
    title: "",
    amount: 0,
  });
  const [referenceName, setReferenceName] = useState("");
  const [getPlans, setGetPlans] = useState([]);
  const [uniqueRowId, setUniqueRowId] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const [showUploadDocument, setShowUploadDocument] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState({
    show: false,
    message: "",
    title: "",
    custom: "",
  });
  const handleUpdateDocument = (unique_rowId) => {
    setUniqueRowId(unique_rowId);
    // e.preventDefault();
    setShowUploadDocument(true);
  };
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  let checkSocket = false;
  const verify_investor_pendingData = () => {
    if (id) {
      if (checkSocket) {
        setIsLoader(false);
      } else {
        setIsLoader(true);
      }
      dispatch(verify_investor_find({ user_id: id }))
        .unwrap()
        .then((response) => {
          setStorePending(
            response &&
              response.data.filter(
                (obj) => obj.status === 1 || obj.status === 4
              )
          );
          setIsLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoader(false);
        });
    }
  };

  function CapsyncDeleteMessage(showDeleteModal, msg, title, func) {
    if (!showDeleteModal) {
      setDeleteStatus({
        ...deleteStatus,
        show: false,
      });
    } else {
      setDeleteStatus({
        show: showDeleteModal,
        message: msg,
        title: title,
        custom: func,
      });
    }
  }

  const deletePendingRow = (id, row_id) => {
    CapsyncDeleteMessage(true, "Delete", "", () => handleDelete(id, row_id));
  };

  const handleDelete = async (rec_id, uniqueRowId) => {
    dispatch(remove_pending_req({ id: rec_id, unique_rowId: uniqueRowId }))
      .unwrap()
      .then(() => {
        verify_investor_pendingData();
        setIsLoader(false);
        toastMessage("Your record has been deleted successfully.", "success");
        if (
          currentNumberOfRows(rowStartIdx, rowsPerPage, storePending.length) <=
            1 &&
          rowStartIdx > 0
        ) {
          setRowStartIdx(rowStartIdx - rowsPerPage);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoader(false);
      });
  };

  const verify_investor_completedData = () => {
    if (id) {
      dispatch(verify_investor_find({ user_id: id }))
        .unwrap()
        .then((response) => {
          setStoreComplete(
            response &&
              response.data.filter(
                (obj) => obj.status === 2 || obj.status === 3
              )
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    verify_investor_completedData();
  }, []);

  useEffect(() => {
    verify_investor_pendingData();
  }, []);

  const continueVerification = (
    verificationType,
    unique_code,
    verification_method_id,
    is_request_sent,
    is_final_call_done
  ) => {
    setIsLoader(true);
    if (verification_method_id === null) {
      if (verificationType === "Accredited Investor") {
        setTimeout(() => {
          navigate(
            `/individual/verifyinvestor/accreditedInvestor/individuals/${unique_code}`
          );
          setIsLoader(false);
        }, 1000);
      } else if (verificationType === "Qualified Client") {
        setTimeout(() => {
          navigate(
            `/individual/verifyinvestor/accreditedInvestor/qualifiedclient/${unique_code}`
          );
          setIsLoader(false);
        }, 1000);
      } else if (verificationType === "Qualified Purchaser") {
        setTimeout(() => {
          navigate(
            `/individual/verifyinvestor/accreditedInvestor/qualifiedpurchaser/${unique_code}`
          );
          setIsLoader(false);
        }, 1000);
      }
    } else if (!is_request_sent && !is_final_call_done) {
      if (verificationType === "Accredited Investor") {
        setTimeout(() => {
          navigate(
            `/individual/verifyinvestor/accreditedInvestor/individuals/${unique_code}/${verification_method_id}`
          );
          setIsLoader(false);
        }, 1000);
      } else if (verificationType === "Qualified Client") {
        setTimeout(() => {
          navigate(
            `/individual/verifyinvestor/accreditedInvestor/qualifiedclient/${unique_code}/${verification_method_id}`
          );
          setIsLoader(false);
        }, 1000);
      } else if (verificationType === "Qualified Purchaser") {
        setTimeout(() => {
          navigate(
            `/individual/verifyinvestor/accreditedInvestor/qualifiedpurchaser/${unique_code}/${verification_method_id}`
          );
          setIsLoader(false);
        }, 1000);
      }
    } else if (!is_final_call_done) {
      dispatch(verify_request_and_send({ unique_rowId: unique_code }))
        .unwrap()
        .then((response) => {
          verify_investor_pendingData();
          setIsLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoader(false);
        });
    }
  };

  const openFirstModal = () => {
    dispatch(fetch_all_payment_plan({ module_name: "Verify Investor" }))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setGetPlans(response && response.data);
          // setSelectedPlan(response && response.data && response.data[0].title);
          setFirstModal(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [rowStartIdx, setRowStartIdx] = useState(0);
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownItems[0]);
  const [dropdownDisplay, setDropdownDisplay] = useState(rowsPerPage);

  const handlePaginationDropdownClick = (dropdownValue) => {
    setDropdownDisplay(dropdownValue);
    setRowStartIdx(0);
    if (dropdownValue === "All") {
      setRowsPerPage(storePending.length);
      return;
    }
    setRowsPerPage(dropdownValue);
  };
  const handleFirstPageClick = () => {
    setRowStartIdx(0);
    // Assuming your rowsPerPage is already set appropriately
  };

  const handleLastPageClick = () => {
    const totalRows = storePending.length;
    const lastPageStartIdx =
      Math.floor((totalRows - 1) / rowsPerPage) * rowsPerPage;
    setRowStartIdx(lastPageStartIdx);
  };

  const currentNumberOfRows = (rowStartIdx, rowsPerPage, lengthOfCompanies) => {
    if (rowStartIdx + rowsPerPage <= lengthOfCompanies) {
      return rowsPerPage;
    } else {
      return lengthOfCompanies - rowStartIdx;
    }
  };

  useEffect(() => {
    const handleAccreditationSignatureCompleted = (msg) => {
      checkSocket = true;
      verify_investor_pendingData();
    };
    socket.on(
      "accreditation_signature_completed",
      handleAccreditationSignatureCompleted
    );
    return () => {
      socket.off(
        "accreditation_signature_completed",
        handleAccreditationSignatureCompleted
      );
    };
  }, []);

  return (
    <>
      <>
        {is_loader.assets === true || is_loader.liabilities === true ? (
          <SkeletonAccreditation />
        ) : (
          <section className="accreditation-main-section accreditation-pending-section">
            <Container fluid>
              <div>
                {isLoader ||
                storePending === undefined ||
                (storePending.length === 0 && storeComplete.length === 0) ? (
                  <div>
                    <div className="cs-section-topbar">
                      <div className="for-mob">
                        <CapsyncSectionHeader pagetitle="Investor Accreditation" />
                      </div>

                      {props.editAccessUser && (
                        <div className="cs-tabs-flex">
                          <div className="for-des">
                            <CapsyncSectionHeader pagetitle="Investor Accreditation" />
                          </div>
                          <Button
                            className="default-regular-h5 cs-btn-icon-primary "
                            type="submit"
                            onClick={openFirstModal}
                          >
                            <span className="cs-neutral-10 req-svg">
                              <CapsyncIcon title="add-filled" size="18" />
                            </span>
                            Request New Verification
                          </Button>
                        </div>
                      )}
                    </div>
                    {isLoader ? (
                      <CapsyncLoader />
                    ) : (
                      <span className="cs-common-no-record">
                        There are no records to display
                      </span>
                    )}
                  </div>
                ) : (
                  <div>
                    <CommonViHeader editAccessUser={props.editAccessUser} />
                    {storePending === undefined || storePending.length === 0 ? (
                      <span className="cs-common-no-record">
                        There are no records to display
                      </span>
                    ) : (
                      <div className="listing-request commonTable">
                        <Table className="cs-table">
                          <thead id="table-head-details" className="cs-thead">
                            <tr>
                              <th
                                className="cs-thead default-medium-sub-heading-m"
                                width="263"
                              >
                                Legal name
                              </th>
                              <th
                                className="cs-thead default-medium-sub-heading-m"
                                width="220"
                              >
                                Requested
                              </th>
                              <th
                                className="cs-thead default-medium-sub-heading-m"
                                width="300"
                              >
                                Type
                              </th>
                              <th
                                className="cs-thead default-medium-sub-heading-m"
                                width="270"
                              >
                                Status
                              </th>
                              <th
                                className="cs-thead default-medium-sub-heading-m"
                                width="240"
                              >
                                Reference
                              </th>
                              <th
                                className="cs-thead default-medium-sub-heading-m"
                                width="220"
                              >
                                {commonNames.Actions}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="cs-tbody ">
                            {storePending &&
                              storePending
                                .slice(rowStartIdx, rowStartIdx + rowsPerPage)
                                .map((pending_itr, key) => {
                                  let referenceStore = "";

                                  if (pending_itr.reference_optional === "") {
                                    referenceStore += "-";
                                  } else {
                                    referenceStore +=
                                      pending_itr.reference_optional;
                                  }

                                  return (
                                    <tr key={key}>
                                      <td className="default-regular-sub-heading-m">
                                        {pending_itr.legal_name}
                                      </td>
                                      <td className="default-regular-sub-heading-m">
                                        {DateFormat(pending_itr.createdAt)}
                                      </td>
                                      <td className="default-regular-sub-heading-m">
                                        {pending_itr.verification_type}
                                      </td>
                                      <td className="default-regular-sub-heading-m">
                                        <div className="cs-progress-indicator">
                                          <div
                                            className={
                                              pending_itr.signature_hello_id !==
                                                "" &&
                                              pending_itr.is_request_sent ===
                                                false
                                                ? "cs-warning cs-indicator"
                                                : pending_itr.is_request_sent ===
                                                  true
                                                ? "cs-success cs-indicator"
                                                : "cs-indicator cs-neutral-50 "
                                            }
                                          >
                                            <span>
                                              <CapsyncToolTip
                                                Child={"information-outlined"}
                                                placement={"top"}
                                                message={
                                                  pending_itr.signature_hello_id !==
                                                    "" &&
                                                  pending_itr.sign_pfs_link ===
                                                    null &&
                                                  pending_itr.is_request_sent ===
                                                    false
                                                    ? "Signature request sent"
                                                    : pending_itr.sign_pfs_link !==
                                                        null &&
                                                      pending_itr.is_request_sent ===
                                                        false
                                                    ? "Signature request completed"
                                                    : pending_itr.is_request_sent ===
                                                      true
                                                    ? "Request sent successfully"
                                                    : "Waiting for information"
                                                }
                                                type="icon"
                                                size="22"
                                              />
                                            </span>
                                            <span className="cs-indicator-line"></span>
                                          </div>
                                          <div
                                            className={
                                              pending_itr.is_request_sent ===
                                              true
                                                ? "cs-success cs-indicator"
                                                : "cs-indicator cs-neutral-50 "
                                            }
                                          >
                                            <span>
                                              <CapsyncToolTip
                                                Child={"review-outlined"}
                                                placement={"top"}
                                                message={"Waiting for review"}
                                                type="icon"
                                                size="22"
                                              />
                                            </span>
                                            <span className="cs-indicator-line"></span>
                                          </div>
                                          {pending_itr.is_final_call_done ===
                                            true &&
                                          pending_itr.is_request_sent ===
                                            true &&
                                          pending_itr.status === 4 ? (
                                            <div
                                              className="cs-indicator cs-warning"
                                              // className={
                                              //   pending_itr.is_request_sent === true && pending_itr.status === 4
                                              //     ? "cs-warning cs-indicator"
                                              //     : "cs-indicator cs-info "
                                              // }
                                            >
                                              <span>
                                                <CapsyncToolTip
                                                  Child={"error-outlined"}
                                                  // width="225px"
                                                  placement={"top"}
                                                  size="26"
                                                  message={
                                                    "Additional document Required"
                                                  }
                                                  type="icon"
                                                />
                                              </span>
                                              <span className="cs-indicator-line"></span>
                                            </div>
                                          ) : (
                                            " "
                                          )}

                                          <div
                                            className={
                                              pending_itr.is_request_sent ===
                                              true
                                                ? "cs-success cs-indicator"
                                                : "cs-indicator cs-neutral-50 "
                                            }
                                          >
                                            <span className="cs-neutral-50">
                                              <CapsyncToolTip
                                                Child={"verification-outlined"}
                                                placement={"top"}
                                                message={
                                                  "Waiting for verification"
                                                }
                                                type="icon"
                                                size="22"
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="default-regular-sub-heading-m">
                                        {referenceStore.length > 25 ? (
                                          <CapsyncToolTip
                                            Child={
                                              referenceStore.length > 25
                                                ? referenceStore.slice(0, 25) +
                                                  "..."
                                                : referenceStore
                                            }
                                            placement={"top"}
                                            message={referenceStore}
                                            type="text"
                                          />
                                        ) : (
                                          referenceStore
                                        )}
                                      </td>
                                      {/* <td>
                                        <span> 05 Days</span>
                                      </td> */}
                                      <td className="default-regular-sub-heading-m action-td">
                                        <div className="cs-common-add-dropdown accreditation-action">
                                          <Dropdown>
                                            <Dropdown.Toggle variant="link">
                                              <span className="cs-table-menu-icon icon cs-neutral-80">
                                                <CapsyncIcon
                                                  title="option-horizontal-filled"
                                                  size="18"
                                                />
                                              </span>
                                              {/* <BsThreeDotsVertical className="bs-chevron-right" /> */}
                                            </Dropdown.Toggle>

                                            <div className="accreditation-menu">
                                              <Dropdown.Menu>
                                                {pending_itr.status === 1 &&
                                                pending_itr.is_request_sent ===
                                                  true ? (
                                                  <>
                                                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                                                      <CapsyncIcon
                                                        title="chevron-right-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Continue Verification
                                                      </span>
                                                    </Dropdown.Item>

                                                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                                                      <CapsyncIcon
                                                        title="delete-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Delete verification
                                                      </span>
                                                    </Dropdown.Item>
                                                    {/* {pending_itr.is_final_call_done === true && pending_itr.is_request_sent === true && pending_itr.status === 4 &&
                                                          <Dropdown.Item
                                                            className="cs-disabled cs-neutral-90"
                                                          // className="cs-neutral-60"
                                                          >
                                                            <CapsyncIcon
                                                              title="files-outlined"
                                                              size="22"
                                                            />
                                                            <span>Upload Document</span>
                                                          </Dropdown.Item>
                                                        } */}
                                                  </>
                                                ) : pending_itr.is_final_call_done ===
                                                    true &&
                                                  pending_itr.is_request_sent ===
                                                    true &&
                                                  pending_itr.status === 4 &&
                                                  ((window.localStorage.getItem(
                                                    "pfs_access_id"
                                                  ) === null &&
                                                    pending_itr.visitors_id !==
                                                      0) ||
                                                    (window.localStorage.getItem(
                                                      "pfs_access_id"
                                                    ) !== null &&
                                                      Number(
                                                        window.localStorage.getItem(
                                                          "id"
                                                        )
                                                      ) !==
                                                        pending_itr.visitors_id)) ? (
                                                  <>
                                                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                                                      <CapsyncIcon
                                                        title="chevron-right-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Continue Verification
                                                      </span>
                                                    </Dropdown.Item>

                                                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                                                      <CapsyncIcon
                                                        title="delete-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Delete verification
                                                      </span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                      className="cs-disabled cs-neutral-90"
                                                      // onClick={() =>
                                                      //   handleUpdateDocument(
                                                      //     pending_itr.unique_rowId,
                                                      //   )
                                                      // }
                                                    >
                                                      <CapsyncIcon
                                                        title="files-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Upload Document
                                                      </span>
                                                    </Dropdown.Item>
                                                  </>
                                                ) : pending_itr.is_final_call_done ===
                                                    true &&
                                                  pending_itr.is_request_sent ===
                                                    true &&
                                                  pending_itr.status === 4 &&
                                                  window.localStorage.getItem(
                                                    "pfs_access_id"
                                                  ) !== null &&
                                                  Number(
                                                    window.localStorage.getItem(
                                                      "id"
                                                    )
                                                  ) ===
                                                    pending_itr.visitors_id ? (
                                                  <>
                                                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                                                      <CapsyncIcon
                                                        title="chevron-right-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Continue Verification
                                                      </span>
                                                    </Dropdown.Item>

                                                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                                                      <CapsyncIcon
                                                        title="delete-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Delete verification
                                                      </span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                      className="cs-neutral-90"
                                                      onClick={() =>
                                                        handleUpdateDocument(
                                                          pending_itr.unique_rowId
                                                        )
                                                      }
                                                    >
                                                      <CapsyncIcon
                                                        title="files-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Upload Document
                                                      </span>
                                                    </Dropdown.Item>
                                                  </>
                                                ) : pending_itr.is_final_call_done ===
                                                    true &&
                                                  pending_itr.is_request_sent ===
                                                    true &&
                                                  pending_itr.status === 4 ? (
                                                  <>
                                                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                                                      <CapsyncIcon
                                                        title="chevron-right-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Continue Verification
                                                      </span>
                                                    </Dropdown.Item>

                                                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                                                      <CapsyncIcon
                                                        title="delete-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Delete verification
                                                      </span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                      className="cs-neutral-90"
                                                      onClick={() =>
                                                        handleUpdateDocument(
                                                          pending_itr.unique_rowId
                                                        )
                                                      }
                                                    >
                                                      <CapsyncIcon
                                                        title="files-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Upload Document
                                                      </span>
                                                    </Dropdown.Item>
                                                  </>
                                                ) : window.localStorage.getItem(
                                                    "pfs_access_id"
                                                  ) === null &&
                                                  pending_itr.visitors_id !==
                                                    0 ? (
                                                  <>
                                                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                                                      <CapsyncIcon
                                                        title="chevron-right-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Continue Verification
                                                      </span>
                                                    </Dropdown.Item>

                                                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                                                      <CapsyncIcon
                                                        title="delete-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Delete verification
                                                      </span>
                                                    </Dropdown.Item>
                                                  </>
                                                ) : window.localStorage.getItem(
                                                    "pfs_access_id"
                                                  ) != null &&
                                                  Number(
                                                    window.localStorage.getItem(
                                                      "id"
                                                    )
                                                  ) !==
                                                    pending_itr.visitors_id ? (
                                                  <>
                                                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                                                      <CapsyncIcon
                                                        title="chevron-right-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Continue Verification
                                                      </span>
                                                    </Dropdown.Item>

                                                    <Dropdown.Item className="cs-disabled cs-neutral-90">
                                                      <CapsyncIcon
                                                        title="delete-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Delete verification
                                                      </span>
                                                    </Dropdown.Item>
                                                  </>
                                                ) : window.localStorage.getItem(
                                                    "pfs_access_id"
                                                  ) !== null &&
                                                  Number(
                                                    window.localStorage.getItem(
                                                      "id"
                                                    )
                                                  ) ===
                                                    pending_itr.visitors_id ? (
                                                  <>
                                                    <Dropdown.Item
                                                      className="cs-neutral-90"
                                                      onClick={() =>
                                                        continueVerification(
                                                          pending_itr.verification_type,
                                                          pending_itr.unique_rowId,
                                                          pending_itr.verification_method_id,
                                                          pending_itr.is_request_sent,
                                                          pending_itr.is_final_call_done
                                                        )
                                                      }
                                                    >
                                                      <CapsyncIcon
                                                        title="chevron-right-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Continue Verification
                                                      </span>
                                                    </Dropdown.Item>

                                                    <Dropdown.Item
                                                      className="cs-neutral-90"
                                                      onClick={() => {
                                                        deletePendingRow(
                                                          pending_itr.id,
                                                          pending_itr.unique_rowId
                                                        );
                                                      }}
                                                    >
                                                      <CapsyncIcon
                                                        title="delete-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Delete verification
                                                      </span>
                                                    </Dropdown.Item>
                                                  </>
                                                ) : (
                                                  <>
                                                    <Dropdown.Item
                                                      className="cs-neutral-90"
                                                      onClick={() =>
                                                        continueVerification(
                                                          pending_itr.verification_type,
                                                          pending_itr.unique_rowId,
                                                          pending_itr.verification_method_id,
                                                          pending_itr.is_request_sent,
                                                          pending_itr.is_final_call_done
                                                        )
                                                      }
                                                    >
                                                      <CapsyncIcon
                                                        title="chevron-right-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Continue Verification
                                                      </span>
                                                    </Dropdown.Item>

                                                    <Dropdown.Item
                                                      className="cs-neutral-90"
                                                      onClick={() => {
                                                        deletePendingRow(
                                                          pending_itr.id,
                                                          pending_itr.unique_rowId
                                                        );
                                                      }}
                                                    >
                                                      <CapsyncIcon
                                                        title="delete-outlined"
                                                        size="22"
                                                      />
                                                      <span className="cs-neutral-90">
                                                        Delete verification
                                                      </span>
                                                    </Dropdown.Item>
                                                  </>
                                                )}
                                              </Dropdown.Menu>
                                            </div>
                                          </Dropdown>
                                        </div>

                                        <div>
                                          {pending_itr.status === 1 &&
                                          pending_itr.is_request_sent ===
                                            true ? (
                                            <div className="cursor-pointer cs-neutral-50">
                                              {/* <span className="cs-space">
                                              Continue Verification
                                            </span>
                                            <span>
                                              <CapsyncIcon
                                                title="chevron-right-outlined"
                                                size="16"
                                              />
                                            </span> */}
                                            </div>
                                          ) : pending_itr.is_final_call_done ===
                                              true &&
                                            pending_itr.is_request_sent ===
                                              true &&
                                            pending_itr.status === 4 ? (
                                            <div
                                              className="cursor-pointer"
                                              onClick={() =>
                                                handleUpdateDocument(
                                                  pending_itr.unique_rowId
                                                )
                                              }
                                            >
                                              {/* <span className="cs-space">
                                              Upload Documents
                                            </span>
                                            <span>
                                              <CapsyncIcon
                                                title="chevron-right-outlined"
                                                size="16"
                                              />
                                            </span> */}
                                            </div>
                                          ) : (
                                            <>
                                              <div
                                                className="cursor-pointer"
                                                onClick={() =>
                                                  continueVerification(
                                                    pending_itr.verification_type,
                                                    pending_itr.unique_rowId,
                                                    pending_itr.verification_method_id,
                                                    pending_itr.is_request_sent,
                                                    pending_itr.is_final_call_done
                                                  )
                                                }
                                              >
                                                {/* <span className="cs-space">
                                                Continue Verification
                                              </span>
                                              <span>
                                                <CapsyncIcon
                                                  title="chevron-right-outlined"
                                                  size="16"
                                                />
                                              </span> */}
                                              </div>

                                              {/* <span>
                                          </span> */}
                                            </>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </Table>
                      </div>
                    )}

                    {storePending.length > 0 && (
                      <div className="cs-custom-pagination">
                        <div className="cs-common-add-dropdown">
                          <span className="default-light-body-text-m cs-neutral-80">
                            Rows per page:
                          </span>
                          <Dropdown>
                            <Dropdown.Toggle className="default-light-body-text-m cs-neutral-80">
                              {dropdownDisplay}
                              <CapsyncIcon
                                title="chevron-down-outlined"
                                size="12"
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu onSelect={(e) => console.log(e)}>
                              {paginationDropdownItems.map(
                                (noOfRowsPerPage) => {
                                  return (
                                    <Dropdown.Item
                                      key={noOfRowsPerPage}
                                      className={
                                        noOfRowsPerPage === dropdownDisplay &&
                                        "cs-selected-dropdown"
                                      }
                                      onClick={() =>
                                        handlePaginationDropdownClick(
                                          noOfRowsPerPage
                                        )
                                      }
                                      value={noOfRowsPerPage}
                                    >
                                      {noOfRowsPerPage}
                                    </Dropdown.Item>
                                  );
                                }
                              )}
                              <Dropdown.Item
                                onClick={() =>
                                  handlePaginationDropdownClick("All")
                                }
                                className={
                                  "All" === dropdownDisplay &&
                                  "cs-selected-dropdown"
                                }
                              >
                                All
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className="default-light-body-text-m cs-neutral-80">
                          <p>
                            {rowStartIdx + 1} -{" "}
                            {rowStartIdx +
                              currentNumberOfRows(
                                rowStartIdx,
                                rowsPerPage,
                                storePending.length
                              )}{" "}
                            of {storePending.length}
                          </p>
                        </div>
                        <div className="cs-pagination-arrow">
                          <Button
                            onClick={handleFirstPageClick}
                            disabled={rowStartIdx === 0}
                            className={
                              rowStartIdx === 0
                                ? "cs-neutral-50"
                                : "cs-neutral-100"
                            }
                          >
                            <CapsyncIcon
                              title="chevron-left-skip-outlined"
                              size="18"
                            />
                          </Button>
                          <Button
                            onClick={() =>
                              setRowStartIdx(rowStartIdx - rowsPerPage)
                            }
                            disabled={rowStartIdx === 0}
                            className={
                              rowStartIdx === 0
                                ? "cs-neutral-50"
                                : "cs-neutral-100"
                            }
                          >
                            <CapsyncIcon
                              title="chevron-left-outlined"
                              size="18"
                            />
                          </Button>
                          <Button
                            onClick={() =>
                              setRowStartIdx(rowStartIdx + rowsPerPage)
                            }
                            className={
                              !(rowStartIdx + rowsPerPage < storePending.length)
                                ? "cs-neutral-50"
                                : "cs-neutral-100"
                            }
                            disabled={
                              !(rowStartIdx + rowsPerPage < storePending.length)
                            }
                          >
                            <CapsyncIcon
                              title="chevron-right-outlined"
                              size="18"
                            />
                          </Button>
                          <Button
                            onClick={handleLastPageClick}
                            className={
                              !(rowStartIdx + rowsPerPage < storePending.length)
                                ? "cs-neutral-50"
                                : "cs-neutral-100"
                            }
                            disabled={
                              !(rowStartIdx + rowsPerPage < storePending.length)
                            }
                          >
                            <CapsyncIcon
                              title="chevron-right-skip-outlined"
                              size="18"
                            />
                          </Button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Container>
          </section>
        )}
      </>

      <FirstModal_VI
        getActivePlan={getActivePlan}
        setGetActivePlan={setGetActivePlan}
        firstModal={firstModal}
        setFirstModal={setFirstModal}
        setReferenceName={setReferenceName}
        referenceName={referenceName}
        getPlans={getPlans}
        selectedPlan={selectedPlan}
        setSelectedPlan={setSelectedPlan}
      />
      <UploadDocumentVI
        showUploadDocument={showUploadDocument}
        setShowUploadDocument={setShowUploadDocument}
        uniqueRowId={uniqueRowId}
        user_id={id}
        toastMessage={toastMessage}
        verify_investor_pendingData={verify_investor_pendingData}
      />
      <DeleteModal
        CapsyncDeleteMessage={CapsyncDeleteMessage}
        deleteStatus={deleteStatus}
      />
    </>
  );
};

export default VI_Pending;
