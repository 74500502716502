import axios from "axios";
import { headerAddToken } from "../util/common";
import FileDownload from "js-file-download";

const UseZipDownload = (customUrl, params, user_id, callBackFn, toastFn) => {
  let header = headerAddToken();
  axios({
    url: customUrl,
    method: "POST",
    data: {
      row_id: params.toString(),
      user_id: user_id,
    },
    responseType: "blob",
    headers: {
      Authorization: header.headers.Authorization,
    },
  })
    .then((response) => {
      FileDownload(response.data, `CS-USER-${user_id}.zip`);
      callBackFn();
      toastFn();
    })
    .catch((err) => {
      console.log(err);
    });
};

export default UseZipDownload;
