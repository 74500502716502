import React from 'react';
import CapsyncIcon from '../../../commonComponent/CapsyncIcon';


const SortingIcon = ({ headerName, sortingHeader, itemTitle, sortingLabel }) => {
  const isActive = itemTitle.toLocaleLowerCase() === sortingLabel.toLocaleLowerCase();
  const iconType = sortingHeader[headerName] ? "arrow-up-outlined" : "arrow-down-outlined";
  // const iconClass = `cs-neutral-80 ${isActive ? "cs-primary" : ""}`;
  
  return isActive;
};

export default SortingIcon;