import moment from 'moment';
import { useEffect } from 'react'

const useSortHeading = (sortingHeaderName, sortingHeader, setDocuments, documents, checkSorting) => {
    useEffect(() => {
        if (sortingHeaderName === "name") {
            if (sortingHeader.name) {
                const sortedByName = [...documents].sort((a, b) => {
                    return (a && a.name && b && b.name && a.name.localeCompare(b.name))
                });
                setDocuments(sortedByName);
            } else {
                const sortedByName = [...documents].sort((a, b) => {
                    return (a && a.name && b && b.name && b.name.localeCompare(a.name));
                });
                setDocuments(sortedByName);
            }
        } else if (sortingHeaderName === "modifiedBy") {
            if (sortingHeader.modifiedBy) {
                const sortedByModifiedBy = [...documents].sort((a, b) => {
                    return (a && a.updated_by && b && b.updated_by && a.updated_by.localeCompare(b.updated_by));
                });
                setDocuments(sortedByModifiedBy);
            } else {
                const sortedByModifiedBy = [...documents].sort((a, b) => {
                    return (a && a.updated_by && b && b.updated_by && b.updated_by.localeCompare(a.updated_by));
                });
                setDocuments(sortedByModifiedBy);
            }
        } else if (sortingHeaderName === "dateArchived" || sortingHeaderName === "dateUpdated" || sortingHeaderName === "dateDeleted") {
            if (sortingHeader.dateArchived || sortingHeader.dateUpdated || sortingHeader.dateDeleted) {
                const sortedByDateUpdate = [...documents].sort((a, b) => {
                    const aDateInTimeStamp = moment(sortingHeaderName === "dateArchived" ? a.archived_date : sortingHeaderName === "dateUpdated" ? a.updatedAt : a.deleted_at).format("X");
                    const bDateInTimeStamp = moment(sortingHeaderName === "dateArchived" ? b.archived_date : sortingHeaderName === "dateUpdated" ? b.updatedAt : b.deleted_at).format("X")
                    return aDateInTimeStamp - bDateInTimeStamp;
                });
                setDocuments(sortedByDateUpdate);
            } else {
                const sortedByDateUpdate = [...documents].sort((a, b) => {
                    const aDateInTimeStamp = moment(sortingHeaderName === "dateArchived" ? a.archived_date : sortingHeaderName === "dateUpdated" ? a.updatedAt : a.deleted_at).format("X");
                    const bDateInTimeStamp = moment(sortingHeaderName === "dateArchived" ? b.archived_date : sortingHeaderName === "dateUpdated" ? b.updatedAt : b.deleted_at).format("X")
                    return bDateInTimeStamp - aDateInTimeStamp;
                });
                setDocuments(sortedByDateUpdate);
            }
        } else if (sortingHeaderName === "fileSize") {
            if (sortingHeader.fileSize) {
                const sortedByFileSize = [...documents].sort((a, b) => {
                    return a.file_size - b.file_size;
                });
                setDocuments(sortedByFileSize);
            } else {
                const sortedByFileSize = [...documents].sort((a, b) => {
                    return b.file_size - a.file_size;
                });
                setDocuments(sortedByFileSize);
            }
        } else {
            const sortedByName = [...documents].sort((a, b) => {
                return b.name.localeCompare(a.name);
            });
            setDocuments(sortedByName);
        }
    }, [sortingHeader, sortingHeaderName]);

}

export default useSortHeading