import React from "react";
import { Form, Row, Col, Image } from "react-bootstrap";
import SubMenuHeader from "../SubMenuHeader";
import { Link } from "react-router-dom";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import SubMethodsList from "../../SubMethodsList";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";

// sequence No. one of No selected (Asmita date-29th June => Do not remove this line)
const MethodIdTwenty = (props) => {


  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Accredited Investor",
      url: `/individual/verifyinvestor/accreditedInvestor/${props.verificationType}/${props.unique_code}`,
    },
  ];



  return (
    <>
      {props.is_loader.assets === true || props.is_loader.liabilities === true ? <SkeletonAllSubMethods /> :
        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="All Owners Accredited"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>
              <SubMenuHeader />
            </div>
            <div className="method-section">
              <div className="default-regular-body-text-l cs-neutral-70"> <SubMethodsList methodId={24} /></div>

              <div className="cs-divider"></div>
              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert} />
                <Form.Label>
                  <p className="default-regular-sub-heading-s cs-neutral-90">
                    Please upload evidence of who all the equity owners of the trust
                    are (by uploading your trust documentation or this &nbsp;
                    <Link
                      to="https://s3.amazonaws.com/static-capsync.com/Verify-Investor-files/All-Owners-Accredited-Officer-Certificate.pdf"
                      target="_blank"
                      className="vi-link-color cursor-pointer"
                    >
                      <b className="cs-neutral-100">Officer’s Certificate</b>
                    </Link>
                    &nbsp; along with a document to show ownership percentages). In a
                    revocable trust, typically, the grantors are the equity owners. In
                    an irrevocable trust, determination of the equity owners can be
                    complicated. Please contact <Link href="mailto=accreditation@capsync.com">accreditation@capsync.com</Link> for
                    assistance.
                  </p>
                </Form.Label>
                <div className={props.uploadFileOneError ? "investor-error" : ""} >
                  <UploadFileBlock
                    files={props.files}
                    setOpenModal={props.setOpenModal}
                    mediaAlert={props.mediaAlert}
                    deleteFile={props.deleteFile}
                    showFile={props.showFile}
                    pfsLink={props.pfsLink}
                    pfsName={props.pfsName}
                    pfsDelete={props.pfsDelete}
                    showDeleteSpinner={props.showDeleteSpinner}
                    showPfsLink={props.showPfsLink}
                    uploadError={props.uploadError}
                    uploadFileOneError={props.uploadFileOneError}
                    showError={props.showError}
                  />
                </div>
                <div className="cs-divider"></div>
                <div className="row-spacing">
                  <p className="default-regular-sub-heading-m cs-neutral-90">
                    Please upload a verification letter for each equity owner which
                    shows that such equity owner is an accredited investor.{" "}
                    <span className="default-regular-sub-heading-m cs-danger">
                      To obtain verification letters for each equity owner, please
                      order additional verification requests for each equity owner, or
                      if an issuer is paying for the verifications, please ask them to
                      place additional verification requests for each equity owner.
                      The issuer may need the email address for each equity investor
                      to place the verification requests.{" "}
                    </span>
                    Note that multiple verification requests can be sent to the same
                    email address, but different equity owners often prefer to receive
                    their verification request at their own email address for privacy
                    purposes.
                  </p>
                </div>
                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Upload verification letters for each equity owner:
                </Form.Label>
                <div className={props.uploadFileTwoError ? "investor-error" : ""} >
                  <UploadFileBlock
                    files={props.files2}
                    setOpenModal={props.setOpenModal2}
                    mediaAlert={props.mediaAlert}
                    deleteFile={props.deleteFile2}
                    showFile={props.showFile2}
                    pfsLink={props.pfsLink}
                    pfsName={props.pfsName}
                    pfsDelete={props.pfsDelete}
                    showDeleteSpinner={props.showDeleteSpinner}
                    showPfsLink={props.showPfsLink2}
                    showError={props.showError}
                    uploadError={props.uploadError}
                    uploadFileTwoError={props.uploadFileTwoError}
                  />
                </div>
                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Upload other evidence:
                </Form.Label>
                <UploadFileBlock
                  files={props.files3}
                  setOpenModal={props.setOpenModal3}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile3}
                  showFile={props.showFile3}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink3}
                />
                <NotesBlock setNotesValue={props.setNotesValue} />
              </Form>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default MethodIdTwenty;
