/* eslint-disable */
import React, { useEffect, useState } from "react";
import { access_pfs_getPermission } from "../slices/pfs_access";
import { useDispatch, useSelector } from "react-redux";

const UseFetchPermission = () => {
  const { user } = useSelector((state) => state.auth);

  let id;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = Number(window.localStorage.getItem("id"));
  }

  const dispatch = useDispatch();
  const [fetchUserPermission, setFetchUserData] = useState({});

  useEffect(() => {
    dispatch(
      access_pfs_getPermission({
        from_user_id: id,
        id: Number(window.localStorage.getItem("id")),
      })
    )
      .unwrap()
      .then((response) => {
        setFetchUserData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);
  return { fetchUserPermission };
};

export default UseFetchPermission;
