import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useStripe } from "@stripe/react-stripe-js";
import { Link } from "react-router-dom";

import StoragePlanCards from "../../../commonComponent/paymentSubscription/subscription/StoragePlanCards";
import PaymentProgressBar from "../../../commonComponent/paymentSubscription/payment/PaymentProgressBar";
import { get_storage_subscription_list } from "../../../slices/subscription_plan";
import {
  create_payment_intent,
  get_user_active_payment_method,
  purchase_user_storage_subscription,
} from "../../../slices/user_subscription";
import { socket } from "../../../config/Socket";
import StorageLimitWarningModal from "./StorageLimitWarningModal";
import StoragePaymentByModal from "./StoragePaymentByModal";
import PaymentFailed from "../../../commonComponent/paymentSubscription/payment/PaymentFailed";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";

const UpgradeStorageModal = (props) => {
  const {
    setShowUpgradeModal,
    setFileModal,
    showUpgradeModal,
    storage,
    showLimitModal,
    setShowLimitModal,
    noSocket,
  } = props;

  const { fileStoragePlan } = useSelector((state) => state.subscriptionPlan);
  const { user, authUser } = useSelector((state) => state.auth);
  const { userBillingDetail } = useSelector((state) => state.userSubscription);
  const dispatch = useDispatch();
  const stripe = useStripe();

  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [purchasedPlan, setPurchasedPlan] = useState({});
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [showPaymentFailed, setShowPaymentFailed] = useState(false);
  const [failureReason, setFailureReason] = useState("");
  const [isStorageUpgrade, setIsStorageUpgrade] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [paymentBy, setPaymentBy] = useState("");
  const [paymentByUserName, setPaymentByUserName] = useState("");
  const [showPaymentBy, setShowPaymentBy] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");

  let user_email = user && user.user.email;
  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else if (user && user.user.id) {
    id = user.user.id;
  }
  let role = user && user.user_role.role;

  const logUser = authUser && authUser.user ? authUser.user : authUser;

  useEffect(() => {
    dispatch(get_storage_subscription_list(id));

    let values = {
      user_id: id,
      wm_user_id: logUser && logUser.id ? logUser.id : 0,
      user_role:
        window.localStorage.getItem("pfs_access_id") !== null
          ? logUser.professional_role === 2
            ? "financial_advisor"
            : "professional"
          : "individual",
    };
    dispatch(get_user_active_payment_method(values))
      .unwrap()
      .then((response) => {
        if (response.code == 200 && response.data) {
          setPaymentMethod(response.data.active_payment_method);
          setPaymentBy(response.data.payment_by);
          setPaymentByUserName(response.data.payment_by_name);
        }
      })
      .catch((err) => { });
  }, []);

  useEffect(() => {
    setPlans(
      fileStoragePlan.filter((item, index) =>
        showLimitModal ? index != 0 : item
      )
    );
    !!fileStoragePlan &&
      setPurchasedPlan(
        fileStoragePlan.find((item) => item.price.is_active_plan)
      );
  }, [fileStoragePlan, showLimitModal]);

  useEffect(() => {
    setSelectedPlan(purchasedPlan);
  }, [purchasedPlan]);

  useEffect(() => {
    purchasedPlan &&
      selectedPlan &&
      setIsStorageUpgrade(
        purchasedPlan.price &&
          selectedPlan.price &&
          purchasedPlan.price.unit_amount <= selectedPlan.price.unit_amount
          ? true
          : false
      );
  }, [purchasedPlan, selectedPlan]);

  let latestPaymentStatus = null;
  useEffect(() => {
    if (!noSocket) {
      socket.on("storage_subscription", (subscription) => {
        props.onSuccess && props.onSuccess();
        dispatch(get_storage_subscription_list(id));
        setTimeout(() => {
          if (subscription.status === 1) {
            latestPaymentStatus = subscription.message;
            toast.success(latestPaymentStatus);
            setPaymentLoader(false);
          } else if (subscription.status === 2) {
            setPaymentLoader(false);
            setShowPaymentFailed(true);
            latestPaymentStatus = subscription.message;
            setFailureReason(latestPaymentStatus);
          } else if (subscription.status === 0) {
            setPaymentLoader(false);
            setShowWarning(true);
            latestPaymentStatus = subscription.message;
            setFailureReason(latestPaymentStatus);
          }
        }, 1000);
      });
      return () => {
        socket.off("storage_subscription");
      };
    }
  }, []);

  let selectedPaymentMethod;
  useEffect(() => {
    selectedPaymentMethod = userBillingDetail.find(
      (item) => item.is_active === true
    );
    selectedPaymentMethod && setPaymentMethod(selectedPaymentMethod);
  }, [userBillingDetail]);

  const callPurchaseAPI = (subscriptionId) => {
    dispatch(
      purchase_user_storage_subscription({
        user_id: id,
        is_upgrade:
          !!selectedPlan && selectedPlan.price.is_purchased
            ? isStorageUpgrade
            : "",
        price_id: !!selectedPlan ? selectedPlan.price.id : null,
        subscription_id: subscriptionId ? subscriptionId : null,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code !== 200) {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        console.log("purchase_user_storage_subscription error:-", err);
      });
  };

  const handleUpgrade = () => {
    setShowUpgradeModal && setShowUpgradeModal(false);
    setShowLimitModal && setShowLimitModal(false);
    if (
      (paymentBy !== "individual" &&
        window.localStorage.getItem("pfs_access_id") === null) ||
      (paymentBy === "individual" &&
        window.localStorage.getItem("pfs_access_id") !== null)
    ) {
      !!fileStoragePlan &&
        setTimeout(
          () =>
            setSelectedPlan(
              fileStoragePlan.find((item) => item.price.is_active_plan)
            ),
          200
        );
      setShowPaymentBy(true);
      return;
    }
    if (
      !!selectedPlan &&
      selectedPlan.price &&
      selectedPlan.price.metadata &&
      selectedPlan.price.metadata.storage_limit < storage.usedSpace
    ) {
      setShowWarning(true);
      !!fileStoragePlan &&
        setTimeout(
          () =>
            setSelectedPlan(
              fileStoragePlan.find((item) => item.price.is_active_plan)
            ),
          200
        );
      setFailureReason(
        "You’re using more storage than your selected plan, please delete some files to go desired storage plan."
      );
      return;
    }
    setPaymentLoader(true);
    let customerId, subscriptionId, clientSecret;
    if (
      !!selectedPlan &&
      selectedPlan.price &&
      selectedPlan.price.subscription_id === ""
    ) {
      if (paymentMethod) {
        dispatch(
          create_payment_intent({
            user_id: id,
            email: user_email,
            intent_type: 4,
            payment_method_id: JSON.parse(paymentMethod.stripe_payment_method)
              .id,
            price_id: !!selectedPlan ? selectedPlan.price.id : null,
          })
        )
          .unwrap()
          .then((response) => {
            if (response.code === 200) {
              if (response.data) {
                customerId =
                  response.data.payment_intent &&
                  response.data.payment_intent.customer;
                subscriptionId =
                  response.data.subscription && response.data.subscription.id;
                clientSecret =
                  response.data.payment_intent &&
                  response.data.payment_intent.client_secret;
              }

              try {
                stripe
                  .confirmPayment({
                    clientSecret: clientSecret,
                    redirect: "if_required",
                    confirmParams: {
                      payment_method: JSON.parse(
                        paymentMethod.stripe_payment_method
                      ).id,
                      // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
                      return_url: "https://example.com",
                    },
                  })
                  .then((result) =>
                    !result.error
                      ? callPurchaseAPI(subscriptionId)
                      : console.log("confirmPayment result error:-")
                  )
                  .catch((error) =>
                    console.log("confirmPayment error:-", error)
                  );
              } catch (err) {
                console.log("Error:-", err);
              }
            }
          })
          .catch((err) => {
            console.log("err stripe checkout", err);
          });
      } else {
        toast.error("Not have any default payment detail");
        setPaymentLoader(false);
      }
    } else {
      callPurchaseAPI(
        !!selectedPlan ? selectedPlan.price.subscription_id : null
      );
    }
  };

  return (
    <>
      {paymentLoader && <PaymentProgressBar />}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName={
          (showLimitModal ? "cs-md-modal" : "cs-lg-modal") +
          " storage-plan-box cloud-storage-modal"
        }
        show={showUpgradeModal}
        onHide={!showUpgradeModal}
        centered
        backdrop="static"
        keyboard={false}>
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            {" "}
            {showLimitModal ? "Storage Limit" : "Cloud storage"}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="cs-subscription-section file-management">
            {showLimitModal && (
              <p className="cs-neutral-80 default-regular-h5">
                You have exceeded the storage limit for your free plan. Upgrade
                now or delete files to continue using your CapSync cloud.
              </p>
            )}
            <StoragePlanCards
              plans={plans}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
            />
            {!paymentMethod && (
              <div className="error-msg default-light-body-text-m cs-danger">
                <span className="icon">
                  {" "}
                  <CapsyncIcon title="info-outlined" size="18" />{" "}
                </span>
                <span className="formik-form-error ">
                  You're unable to upgrade as there is no default payment
                  method.
                  <span className="cs-neutral-100 default-regular-h5">
                    {" "}
                    Go to
                    {window.localStorage.getItem("pfs_access_id") === null ? (
                      <Link
                        to="/individual/billing-subscription"
                        class="default-regular-h5">
                        {" "}
                        Billing & Subscriptions{" "}
                      </Link>
                    ) : (
                      " Billing & Subscriptions in your account "
                    )}
                    to choose.
                  </span>
                </span>
              </div>
            )}
          </section>
        </Modal.Body>
        <Modal.Footer className="cs-modal-btn">
          <Button
            className="cs-btn-secondary md-btn default-regular-h5"
            onClick={() => {
              setShowUpgradeModal && setShowUpgradeModal(false);
              !!fileStoragePlan &&
                setTimeout(() => {
                  setShowLimitModal && setShowLimitModal(false);
                  setSelectedPlan(
                    fileStoragePlan.find((item) => item.price.is_active_plan)
                  );
                }, 200);
              setFileModal && setFileModal(true)
            }}>
            Cancel
          </Button>
          <Button
            className="cs-btn-primary md-btn default-regular-h5"
            disabled={
              (selectedPlan &&
                purchasedPlan &&
                purchasedPlan.id === selectedPlan.id) ||
                !paymentMethod
                ? true
                : false
            }
            onClick={handleUpgrade}>
            {" "}
            {isStorageUpgrade ? "Upgrade" : "Downgrade"}{" "}
          </Button>
        </Modal.Footer>
      </Modal>
      <StorageLimitWarningModal
        showWarning={showWarning}
        warningMessage={failureReason}
        setShowWarning={setShowWarning}
      />
      <StoragePaymentByModal
        showPaymentBy={showPaymentBy}
        setShowPaymentBy={setShowPaymentBy}
        paymentByUserName={paymentByUserName}
      />
      <PaymentFailed
        showPaymentFailed={showPaymentFailed}
        failureReason={failureReason}
        setShowPaymentFailed={setShowPaymentFailed}
      />
    </>
  );
};

export default UpgradeStorageModal;
