import React, { useEffect, useState } from 'react'
import { Modal, Form, Row, Image, Button, CloseButton } from 'react-bootstrap';
import NumberFormat from "react-currency-format";
import CapsyncIcon from '../CapsyncIcon';
import "./Payment.css"
import { useDispatch } from 'react-redux';
import { categoryGetAll, categoryLinkRecord, getCategoryUnlinkAll } from '../../slices/category.slice';
import { setAddressString } from '../../util/common';
import CapsyncToolTip from '../capsyncTooltip/CapsyncToolTip';
import { toast } from 'react-toastify';

const LinkModal = ({
    modalShow,
    usrId,
    category,
    mainCategory,
    updateCategory,
    updateMainCategory,
    endPointAPI,
    displayCategory,
    toastMessage,
    descType,
    descDisplayType,
    numType,
    numDisplayType,
    id,
    idType,
    linkIdType,
    closeModal,
    addLinkSuccess,
    onHandleAddCategory,
    isUpdateData
}) => {
    const dispatch = useDispatch();

    const [listData, setListData] = useState([])
    const [selectedId, setSelectedId] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [isLinkDisable, setIsLinkDisable] = useState(false);

    useEffect(() => {
        fetchAllListData()
    }, [isUpdateData])

    const searchValueHandler = (e) => {
        setSearchValue(e.target.value);
    }

    const escapeRegExp = (string) => {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    };
    
    function searchFilter(data) {
        const escapedSearchValue = escapeRegExp(searchValue);
        const searchRegex = new RegExp(escapedSearchValue, 'i');
        if (typeof data[descType] === 'string') {
            return (
                data[descType]
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                data[numType].toString().match(searchRegex)
            );
        } else {
            return (
                setAddressString(data[descType])
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                data[numType].toString().match(searchRegex)
            );
        }
    }

    const selectEntry = (params) => {
        setSelectedId(params);
    }

    const fetchAllListData = () => {
        dispatch(
            getCategoryUnlinkAll({
                id: usrId,
                category: updateCategory,
                main_category: updateMainCategory,
                end_point: endPointAPI
            })
        )
            .unwrap()
            .then((response) => {
                response && response.data && response.data.all_entries && response.data.all_entries.length && setListData(response.data.all_entries)
            })
            .catch((err) => {
                toastMessage(err.message, "error");
            });
    }

    const LinkCategory = (linkId) => {
        setIsLinkDisable(true)
        dispatch(
            categoryLinkRecord({
                main_category: mainCategory,
                category,
                id,
                linkId,
                idType,
                linkIdType
            })
        )
            .unwrap()
            .then((response) => {
                if (response.code === 500 && response.message) {
                    toast.error(response.message, "error");
                };
                if (response.code === 200) {
                    addLinkSuccess();
                }
                setIsLinkDisable(false)
            })
            .catch((err) => {
                toast.error(err.message, "error");
                setIsLinkDisable(false)
            })
    }

    return (
        <Modal
            show={modalShow}
            backdrop="static" keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="cs-md-modal"
        >
            <Modal.Header>
                <Modal.Title className="default-semi-bold-h3">Choose {updateMainCategory}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <p className="default-light-body-text-l cs-neutral-80">
                    Choose the {updateMainCategory} that you want to link
                </p>

                <div className="cs-search-bar auto-link-search">
                    <Form.Group className="cs-form-group">
                        <Form.Control
                            type="text"
                            placeholder="Search..."
                            value={searchValue}
                            onChange={searchValueHandler}
                            className={searchValue && "cs-input-field-active"}
                        />
                        <span className="input-field-icon cs-neutral-70">
                            <CapsyncIcon title="search-outlined" size="18" />
                        </span>
                    </Form.Group>
                </div>



                <Row >
                        <div className="link-table-heading">
                            <span className="default-regular-sub-heading-m cs-neutral-100">{descDisplayType || 'Description'}</span>
                            <span className="default-regular-sub-heading-m cs-neutral-100">
                                {numDisplayType || 'Share Market Value'}
                            </span>
                        </div>
                    <div className="auto-link-popup">
                        {listData.filter((data) => searchFilter(data)).map((data, index) => (
                            <div
                                className={`auto-selection-box ${selectedId === data.id ? "selected-box" : ""}`}
                                key={index}
                                onClick={() => selectEntry(data.id)}
                            >
                                {selectedId === data.id && ""}
                                {/* <p className="default-regular-sub-heading-m cs-neutral-100">{JSON.parse(data[descType]).trim()}</p> */}
                                <p className="default-regular-sub-heading-m cs-neutral-100">{typeof data[descType] === 'string' ? data[descType] : setAddressString(data[descType])}</p>
                                <div className="verified-value-icon">
                                    <span className={`default-regular-sub-heading-m dollar-value-gap cs-neutral-100 ${data.is_manual === true ? "value-align" : ""}`}>
                                        $&nbsp;
                                        <NumberFormat
                                            value={Number(data[numType]).toFixed(2)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                        />
                                    </span>
                                    {data.is_manual === false &&
                                        <span className="cs-success">
                                            <CapsyncToolTip
                                                Child={"verification-outlined"}
                                                placement={"top"}
                                                message={updateCategory === 'automobile' ? "Verified by Vehicles Database" : "Verified by Attom Data"}
                                                type="icon"
                                                size="16"
                                            />
                                        </span>
                                    }
                                </div>
                            </div>
                        ))}
                        {listData.filter((data) => searchFilter(data)).length === 0 && (
                            <span className="cs-common-no-record">No data found.</span>
                        )}
                    </div>
                </Row>

                <Button
                    type="submit"
                    className="default-regular-h5 cs-btn-icon-tertiary add-another-asset"
                    onClick={() => onHandleAddCategory(updateCategory)}
                >
                    <span className="cs-primary">
                        <CapsyncIcon title="add-filled" size="18" />
                    </span>
                    Add another {updateMainCategory}
                </Button>
            </Modal.Body>

            <Modal.Footer>
                <div className='cs-modal-btn'>
                    <Button className="cs-btn-secondary md-btn default-regular-h5"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>

                    <Button
                        className="cs-btn-primary md-btn default-regular-h5"
                        onClick={() => LinkCategory(selectedId)}
                        disabled={selectedId === "" || isLinkDisable}
                    >
                        Link
                    </Button>
                </div>

            </Modal.Footer>
        </Modal>
    )
}

export default LinkModal