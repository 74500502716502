import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import categorySlice from "./slices/category.slice";
import userSlice from "./slices/user";
import columnFilterSlice from "./slices/columnFilter";
import avatarSlice from "./slices/avatar";
import notificationSlice from "./slices/requests";
import manageUserSlice from "./slices/pfs_access";
import auditLogsSlice from "./slices/auditLogs";
import wealthManagerSlice from "./slices/wealthManager.slice";
import subscriptionPlanSlice from "./slices/subscription_plan";
import userSubscriptionSlice from "./slices/user_subscription";
import activeTabSlice from "./slices/dashboard";
import fileManagementSlice from "./slices/fileManagement.slice";
import arraySlice from "./slices/array.slice";
const reducer = {
  auth: authReducer,
  message: messageReducer,
  userSlice: userSlice,
  columnFilterSlice: columnFilterSlice,
  category: categorySlice,
  avatar: avatarSlice,
  notification: notificationSlice,
  manageUser: manageUserSlice,
  auditLogs: auditLogsSlice,
  wealthManager: wealthManagerSlice,
  subscriptionPlan: subscriptionPlanSlice,
  userSubscription: userSubscriptionSlice,
  activeTab: activeTabSlice,
  fileManagement: fileManagementSlice,
  array: arraySlice,
};

const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
