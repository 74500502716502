import React from 'react'
import Skeleton from 'react-loading-skeleton'

const SkeletonSidebarSection = () => {
    return (
        <div className='cs-main-sidebar skeleton-main-sidebar'>
            <div className="sidebar-header">
                <div className='cs-logo'>  <Skeleton width={132} /></div>
            </div>
            <div className="sidebar-nav">
                {Array(8).fill(0).map((item, idx) => (
                    <div className='skeletion-main-nav-item' key={idx}>
                        <div className="skeleton-menu-icon">
                            <Skeleton width={22} height={22} />
                        </div>
                        <div className="default-regular-sub-heading-m">
                            <Skeleton width={150} height={22} />
                        </div>
                    </div>
                ))}
            </div>
            <div className="sidebar-add-account">
                <Skeleton />
            </div>
        </div>
    )
}

export default SkeletonSidebarSection