export const handleOwnershipValue = (event) => {
    const typedKey = event.key;
    let currentValue = event.target.value;
    // const pastedValue = event.clipboardData.getData('text/plain');
    let pastedValue;
    if (event.clipboardData) {
        pastedValue = event.clipboardData.getData('text/plain');
    } else if (window.clipboardData) {
        pastedValue = window.clipboardData.getData('Text');
    }
    if (pastedValue && pastedValue.startsWith('-')) {
        event.preventDefault();
        return;
    }
    let newValue = currentValue + pastedValue;

    if (!isNaN(newValue) && (parseFloat(newValue) > 100 || parseFloat(newValue) === 0)) {
        event.preventDefault();
    }

    if ((!isNaN(typedKey) && parseFloat(currentValue + typedKey) > 100)
        || (typedKey === '0' && (currentValue === '' || currentValue === '0'))
        || (typedKey === '.' && (currentValue === '' || currentValue === '0' || currentValue.endsWith('.')))
    ) {
        event.preventDefault();
    }
}
export const handleOwnershipKeyDown = (event) => {
    const typedKey = event.key;
    const { selectionStart, selectionEnd, value } = event.target;

    if (
        (!isNaN(typedKey) && parseFloat(replaceSelectedText(value, typedKey, selectionStart, selectionEnd)) > 100) ||
        (typedKey === '0' && (value === '' || value === '0')) ||
        (typedKey === '.' && (value === '' || value === '0' || value.endsWith('.')))
    ) {
        event.preventDefault();
    }
};

export const handleOwnershipPaste = (event) => {
    const pastedValue = (event.clipboardData || window.clipboardData).getData('text');
    const { selectionStart, selectionEnd, value } = event.target;

    if (pastedValue.startsWith('-') || (!isNaN(pastedValue) && (parseFloat(pastedValue) > 100 || parseFloat(pastedValue) === 0))) {
        event.preventDefault();
        return;
    }

    const newValue = replaceSelectedText(value, pastedValue, selectionStart, selectionEnd);

    if (parseFloat(newValue) > 100 || parseFloat(newValue) === 0) {
        event.preventDefault();
    }
};

const replaceSelectedText = (value, newText, selectionStart, selectionEnd) => {
    return value.slice(0, selectionStart) + newText + value.slice(selectionEnd);
};
export const handleInterestValue = (event) => {
    const typedKey = event.key;
    let currentValue = event.target.value;
    // const pastedValue = event.clipboardData.getData('text/plain');
    let pastedValue;
    if (event.clipboardData) {
        pastedValue = event.clipboardData.getData('text/plain');
    } else if (window.clipboardData) {
        pastedValue = window.clipboardData.getData('Text');
    }
    if (pastedValue && pastedValue.startsWith('-')) {
        event.preventDefault();
        return;
    }
    let newValue = currentValue + pastedValue;

    if (!isNaN(newValue) && (parseFloat(newValue) > 100)) {
        event.preventDefault();
    }

    if ((!isNaN(typedKey) && parseFloat(currentValue + typedKey) > 100)
        || (typedKey === '.' && (currentValue === '' || currentValue.endsWith('.')))
    ) {
        event.preventDefault();
    }
}