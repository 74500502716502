import React from "react";
import PlaidDataBlock from "./PlaidDataBlock";
import { useSelector } from "react-redux";
import { plaidAccountListing } from "../../../../config/financeCalculation";
import { customLabelLength } from "../../../../customHooks/adjustLength";

const PlaidAccountsList = ({ newAcc, duplicateAcc, type }) => {
    const reducerData = useSelector((state) => state);
    let assetNames = [
        reducerData.category.categoryListing.assets[0].title,
        reducerData.category.categoryListing.assets[1].title,
        reducerData.category.categoryListing.assets[2].title,
        reducerData.category.categoryListing.assets[5].title
    ];
    let liabilityNames = [
        reducerData.category.categoryListing.liabilities[2].sub_category, // changed from 1 to 2
        reducerData.category.categoryListing.liabilities[0].sub_category,
        // customLabelLength(
        //     reducerData.category.categoryListing.liabilities[2].sub_category, 30, 30
        // ), // Changed from  2 to 0
        reducerData.category.categoryListing.liabilities[7].sub_category,
        reducerData.category.categoryListing.liabilities[5].sub_category, // Changed from  6 to 5
        // reducerData.category.categoryListing.liabilities[2].sub_category, // changed from 3 to 1
        customLabelLength(
            reducerData.category.categoryListing.liabilities[1].sub_category, 30, 30
        ),
    ]
    const fetchAccounts = plaidAccountListing(newAcc, duplicateAcc, type, type === 'assets' ? assetNames : liabilityNames);
    return (
        <>
            {
                fetchAccounts.map((itr) => {
                    return (
                        <>
                            <PlaidDataBlock
                                newAcc={itr.newAcc}
                                duplicateAcc={itr.duplicateAcc}
                                tableHead={itr.tableHead}
                            />
                        </>
                    );
                })}
        </>
    )
}

export default PlaidAccountsList