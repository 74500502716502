import API from "../util/api";
import { headerAddToken } from "../util/common";

const fetch_main_list = async (id, user_id, isMove) => {
  let header = await headerAddToken();
  return API.get(
    `/file-manage/list?parentId=${id}&userId=${user_id}&isMove=${isMove}`,
    header
  );
};

const fetch_trash_list = async (user_id, last_days) => {
  let header = await headerAddToken();
  return API.get(
    `/file-manage/trash-list?userId=${user_id}&lastDays=${last_days}`,
    header
  );
};

const file_upload = async (values) => {
  let header = await headerAddToken();
  return API.post(`/file-manage/upload_files`, values, header);
};

const file_upload_dropbox = async (values) => {
  let header = await headerAddToken();
  return API.post(`/file-manage/upload-files-dropbox`, values, header);
};

const file_upload_google_drive = async (values) => {
  let header = await headerAddToken();
  return API.post(`/file-manage/upload-files-google-drive`, values, header);
};

const folder_upload = async (values) => {
  let header = await headerAddToken();
  return API.post(`/file-manage/folder_upload`, values, header);
};

const folder_create = async (values) => {
  let header = await headerAddToken();
  return API.post(`/file-manage/create`, values, header);
};

const file_add = async (values) => {
  let header = await headerAddToken();
  return API.post(`/file-manage/add-files`, values, header);
};

const folder_rename = async (values) => {
  let header = await headerAddToken();
  return API.put(`/file-manage/update-name`, values, header);
};

const trash_restore = async (payload) => {
  let header = await headerAddToken();
  return API.post(`/file-manage/restore-file`, payload, header);
};

const trash_delete = async (values) => {
  let header = await headerAddToken();
  return API.post(`/file-manage/trash-delete`, values, header);
};

const empty_trash = async (user_id) => {
  let header = await headerAddToken();
  return API.delete(`/file-manage/empty-trash?userId=${user_id}`, header);
};

const files_search = async (userId, keyword) => {
  let header = await headerAddToken();
  return API.get(
    `/file-manage/search?userId=${userId}&keyword=${keyword}`,
    header
  );
};
const archive_files_search = async (userId, keyword) => {
  let header = await headerAddToken();
  return API.get(
    `/file-manage/archive-search?userId=${userId}&keyword=${keyword}`,
    header
  );
};
const trash_files_search = async (userId, keyword) => {
  let header = await headerAddToken();
  return API.get(
    `/file-manage/trash-search?userId=${userId}&keyword=${keyword}`,
    header
  );
};

const delete_file = async (values) => {
  let header = await headerAddToken();
  return API.patch(`/file-manage/delete`, values, header);
};
const delete_multi_file_services = async (values) => {
  let header = await headerAddToken();
  return API.post(`/file-manage/delete-mulitple-files`, values, header);
};

const archive_file_services = async (values) => {
  let header = await headerAddToken();
  return API.post(`/file-manage/archive`, values, header);
};

const get_archive_file_services = async (values) => {
  const { userID, last_days } = values;
  let header = await headerAddToken();
  return API.get(
    `file-manage/archived-list?userId=${userID}&lastDays=${last_days}`,
    header
  );
};
const get_user_storage_services = async (values) => {
  const { id } = values;
  let header = await headerAddToken();
  return API.get(`file-manage/get-user-storage?userId=${id}`, header);
};

const restore_archive_file_services = async (values) => {
  let header = await headerAddToken();
  return API.post(`file-manage/archive-file-restore`, values, header);
};

const move_file_folder = async (values) => {
  let header = await headerAddToken();
  return API.post(`/file-manage/move`, values, header);
};

const scan_attachment = async (values) => {
  let header = await headerAddToken();
  return API.post(`/file-manage/scan-files`, values, header);
};

const check_life_insurance_data = async (values) => {
  let header = await headerAddToken();
  return API.post(`/file-manage/life-insurance-data`, values, header);
};

const file_management_service = {
  fetch_main_list,
  file_upload,
  file_upload_dropbox,
  file_upload_google_drive,
  folder_create,
  folder_rename,
  archive_file_services,
  get_archive_file_services,
  get_user_storage_services,
  restore_archive_file_services,
  trash_restore,
  trash_delete,
  fetch_trash_list,
  folder_upload,
  files_search,
  archive_files_search,
  trash_files_search,
  delete_file,
  delete_multi_file_services,
  empty_trash,
  move_file_folder,
  file_add,
  scan_attachment,
  check_life_insurance_data,
};

export default file_management_service;
