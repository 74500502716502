import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Modal,
  FormLabel,
  ModalFooter,
} from "react-bootstrap";
import { useSelector } from "react-redux";

import PhoneInput from "react-phone-number-input";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import AddressSearch from "../../../../commonComponent/AddressSearch";

const EditProfie = (props) => {
  const {
    showEditProfile,
    setShowEditProfile,
    loading,
    errors,
    touched,
    handleSubmit,
    handleChange,
    values,
    phoneHandleChange,
    confirmPhoneHandleChange,
    msg,
    handleReset,
    setMsg,
    saveDisable,
    record,
    updateDetails,
    setUpdateDetails,
    setFieldValue
  } = props;

  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [isPhoneChanged, setIsPhoneChanged] = useState(false);

  const refSubmit = useRef(null);
  const handleClick = () => {
    refSubmit.current.click();
  };


  useEffect(() => {
    setIsEmailChanged(false);
    setIsPhoneChanged(false);
    if (showEditProfile === false) {
      setUpdateDetails("")
    }
  }, [showEditProfile]);

  const handleCancel = () => {
    setShowEditProfile(false);
    handleReset();
    setMsg({ address: "", phoneNo: "", confirmPhoneNo: "" });
  }

  // uncomment 3 changes for confirm phonenumber 1.handlePhoneChange 2.Confirm Phone number field 3.Update button comment

  // const handlePhoneChange = (e) => {
  //   phoneHandleChange(e, "phoneNo");
  //   if (e === record.phoneNo) {
  //     setIsPhoneChanged(false);
  //     setFieldValue("confirmPhoneNo", "")
  //   } else {
  //     setIsPhoneChanged(true)
  //   }
  // }

  const handlePhoneChange = (e) => {
    phoneHandleChange(e, "phoneNo");
    if (e === record.phoneNo) {
      setIsPhoneChanged(false);
    }
  }

  return (
    <Modal
      show={showEditProfile}
      onHide={() => setShowEditProfile(false)}
      backdrop="static"
      // dialogClassName={"modal-dialog-border"}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-lg-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
          Edit Profile
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="personal-inform-form" onSubmit={handleSubmit}>
          <Row>
            <Col lg={6}>
              <Form.Group className="cs-form-group">
                <FormLabel>First name</FormLabel>
                <Form.Control
                  type="text"
                  name="firstName"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}

                  placeholder="First Name"
                  defaultValue={record.firstName}
                  onChange={handleChange}
                  className={values.firstName && "cs-input-field-active"}
                  // onBlur={handleBlur}
                  maxLength={40}
                />
                {errors.firstName && touched.firstName ? (
                  <p className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.firstName}
                  </p>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="cs-form-group">
                <FormLabel>Last name</FormLabel>
                <Form.Control
                  type="text"
                  name="lastName"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}

                  placeholder="Last Name"
                  defaultValue={record.lastName}
                  onChange={handleChange}
                  className={values.lastName && "cs-input-field-active"}
                  // onBlur={handleBlur}
                  maxLength={40}
                />
                {errors.lastName && touched.lastName ? (
                  <p className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.lastName}
                  </p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Form.Group className="cs-form-group cs-disabled">
                <FormLabel>Email</FormLabel>
                <Form.Control
                  type="email"
                  autoComplete="off"
                  name="email"
                  placeholder="Email Address"
                  defaultValue={record.email}
                  onKeyDown={(event) => {
                    if (event.code === "Space")
                      event.preventDefault();
                  }}
                  onPaste={(e) => {
                    e.preventDefault()
                    return false;
                  }} onCopy={(e) => {
                    e.preventDefault()
                    return false;
                  }}
                  onChange={(e) => {
                    handleChange(e)
                    setIsEmailChanged(true)
                  }}
                  className={values.email && "cs-input-field-active"}
                  disabled={true}
                // onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <p className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.email}
                  </p>
                ) : null}
              </Form.Group>
            </Col>

            <Col lg={6}>
              <Form.Group
                className="cs-form-group"
              // onClick={() => resetMfaType(1)}
              >
                <FormLabel>Phone number</FormLabel>
                {/* <Form.Control
                  type="text"
                  autoComplete="off"
                  name="phoneNo"
                  placeholder="Phone Number"
                  defaultValue={values.phoneNo}
                  onChange={handleChange}
                  className={values.phoneNo && "cs-input-field-active"}
                  // onBlur={handleBlur}
                  onKeyPress={(e) => {
                    // Allow only numeric characters and limit length to 12
                    if (!/[0-9]/.test(e.key) || values.phoneNo.length >= 13) {
                      e.preventDefault();
                    }
                  }}
                /> */}
                <PhoneInput
                  limitMaxLength={15}
                  international
                  countryCallingCodeEditable={false}
                  placeholder="Phone Number"
                  autoComplete="off"
                  name="phoneNo"
                  addInternationalOption={false}
                  withCountryCallingCode={false}
                  value={values.phoneNo}
                  className="form-control"
                  onChange={handlePhoneChange}
                  defaultCountry="US"
                  countries={["US"]}
                  onPaste={(e) => {
                    e.preventDefault()
                    return false;
                  }} onCopy={(e) => {
                    e.preventDefault()
                    return false;
                  }}
                />
                {errors.phoneNo || (msg && msg.field) ? (
                  <p className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.phoneNo || msg.field}
                  </p>
                ) : null}

              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>{isEmailChanged &&
              <Form.Group className="cs-form-group">
                <FormLabel>Confirm Email</FormLabel>
                <Form.Control
                  type="email"
                  autoComplete="off"
                  name="confirmEmail"
                  placeholder="Confirm Email Address"
                  value={record.confirmEmail}
                  required={isEmailChanged}
                  onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                  onPaste={(e) => {
                    e.preventDefault()
                    return false;
                  }} onCopy={(e) => {
                    e.preventDefault()
                    return false;
                  }}
                  onChange={handleChange}
                  className={values.confirmEmail && "cs-input-field-active"}
                // onBlur={handleBlur}
                />
                {errors.confirmEmail && touched.confirmEmail ? (
                  <p className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.confirmEmail}
                  </p>
                ) : null}
              </Form.Group>}
            </Col>
            {/* uncomment 3 changes for confirm phonenumber 1.handlePhoneChange 2.Confirm Phone number field 3.Update button comment */}
            {/* <Col lg={6}>{isPhoneChanged &&
              <Form.Group
                className="cs-form-group"
              // onClick={() => resetMfaType(1)}
              >
                <FormLabel>Confirm Phone number</FormLabel>
                <PhoneInput
                  limitMaxLength={15}
                  international
                  countryCallingCodeEditable={false}
                  placeholder="Confirm Phone Number"
                  autoComplete="off"
                  name="confirmPhoneNo"
                  withCountryCallingCode={false}
                  value={values.confirmPhoneNo}
                  required={isPhoneChanged}
                  className="form-control"
                  onChange={(e) => confirmPhoneHandleChange(e, "confirmPhoneNo")}
                  defaultCountry="US"
                  countries={["IN", "GB", "US"]}
                  onPaste={(e) => {
                    e.preventDefault()
                    return false;
                  }} onCopy={(e) => {
                    e.preventDefault()
                    return false;
                  }}
                />
                {errors.confirmPhoneNo && touched.confirmPhoneNo ? (
                  <p className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.confirmPhoneNo}
                  </p>
                ) : null}
                {msg && msg.confirmPhoneNo ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {msg.confirmPhoneNo}
                  </span>
                ) : null}
              </Form.Group>}
            </Col> */}
          </Row>

          {<AddressSearch {...props} record={record.address} />}

          {updateDetails && (
            <Row>
              <Col>
                <Form.Group className="mt-2">
                  <div className="cs-msg default-regular-body-text-s cs-danger">
                    <span className="icon">
                      <CapsyncIcon title="info-filled" size="16" />
                    </span>
                    <span className="txt"> {updateDetails}</span>
                  </div>
                </Form.Group>
              </Col>
            </Row>
          )}

          <div style={{ display: "none" }}>
            <Button type="submit" ref={refSubmit} />
          </div>
        </Form>
      </Modal.Body>
      <ModalFooter>

        <div className="cs-modal-btn">
          <Button
            className="cs-btn-secondary md-btn default-regular-h6"
            type="button"
            onClick={() => handleCancel()}
            id='cancel-edit-profile'
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="cs-btn-primary md-btn default-regular-h6"
            onClick={handleClick}
            disabled={saveDisable || (isPhoneChanged)}
            // disabled={saveDisable || (isPhoneChanged && !values.confirmPhoneNo)}
            // uncomment 3 changes for confirm phonenumber 1.handlePhoneChange 2.Confirm Phone number field 3.Update button comment
            id='update-edit-profile'
          >
            Update
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default EditProfie;
