/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import InitialCommonModel from "../dashboard/InitialSetup/InitialCommonModel";
import { SignaturerequestFetchAll } from "../../../slices/requests";
import { socket } from "../../../config/Socket";
import DesktopMenu from "./SidebarMenu/DesktopMenu";
import MobileMenu from "./SidebarMenu/MobileMenu";

const SidebarSection = (props) => {
  const [fetchSignPendingNotifications, setFetchSignPendingNotifications] = useState(0);
  const { setShowModalTrue, showModalTrue, openAddAccountModal } = props;

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  // const current_user_email = props.individualUserData.email;
  const current_user_email = user && user.user.email;

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  useEffect(() => {
    props.getProfilePicture();
  }, [props.getProfilePicture]);

  const changeModalState = (param) => {
    setTimeout(() => {
      document.getElementsByTagName("body")[0].setAttribute("style", "");
    }, 500);
    setShowModalTrue(param);
  };

  const fetchSignDetails = () => {
    if (props.individualUserData) {
      dispatch(
        SignaturerequestFetchAll({
          id: id,
          user_email: current_user_email,
        })
      )
        .unwrap()
        .then((response) => {

          const store = response;
          let response1 = response.map((itr) => itr.email);
          if (response1.includes(current_user_email)) {
            const count = response.filter(
              (x) => x.check_status === 3 && x.is_deleted === false
            );

            setFetchSignPendingNotifications(count.length);
          } else {
            const count = response.filter(
              (x) => x.check_status === 2 && x.is_deleted === false
            );

            setFetchSignPendingNotifications(count.length);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    fetchSignDetails();
  }, []);


  useEffect(() => {
    const handleNewNotification = (msg) => {
      props.fetchAllNotifications();
    };
    socket.on("newNotification", handleNewNotification);
    return () => {
      socket.off("newNotification", handleNewNotification);
    };
  }, [props.fetchAllNotifications]);
  return (
    <>
      {showModalTrue && (
        <InitialCommonModel
          getCategoryTotal={props.getCategoryTotal}
          getCategoryTotalLib={props.getCategoryTotalLib}
          showModal={showModalTrue}
          changeModal={changeModalState}
          dataAsset={props.dataAsset}
          dataLiability={props.dataLiability}
          changeCategoryData={props.changeCategoryData}
          toastMessage={props.toastMessage}
          changeFilterStatus={props.changeFilterStatus}
          changeProfileState={props.changeProfileState}
          profileState={props.profileState}
          mainFilterStatus={props.mainFilterStatus}
          individualUserData={props.individualUserData}
          errorAccounts={props.errorAccounts}
          setCategoryAccountAdded={props.setCategoryAccountAdded}
        />
      )}
      {props.openSide ? (
        <DesktopMenu props={props} openAddAccountModal={openAddAccountModal} individualUserData={props.individualUserData } />
      ) : (
        <MobileMenu props={props} openAddAccountModal={openAddAccountModal} />
      )}
    </>
  );
};

export default SidebarSection;
