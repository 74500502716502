import React from "react";
import LogoImage from "../logoImage";

const AuthHead = (props) => {
  return (
    <>
      <LogoImage />
      <div className="head">
        {props.title && (
          <>
            <h1 className="default-semi-bold-h1">{props.title}</h1>
            <hr className="head-underline" />
          </>
        )}
      </div>
    </>
  );
};

export default AuthHead;
