import React, { useState, createContext, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import InitialSetup1 from "./InitialSetup1";
import InitialSetup2 from "./InitialSetup2";
import InitialSetup3 from "./InitialSetup3";
import InitialSetup4 from "./InitialSetup4";
import "../../../../../app/pages/individual/dashboard/InitialSetup/initial-setup.css";
export const Tree = createContext(null);

const InitialCommonModel = (props) => {
  const [counter, setCounter] = useState(1);
  const [fetchTypeCategory, setFetchTypeCategory] = useState({
    type: 0,
    category: ''
  });

  const [loader, setLoader] = useState(false);
  const [display, setDisplay] = useState("");
  const [plaid_link_token, setPlaid_link_token] = useState(false);
  const [plaidCategories, setPlaidCategories] = useState(false);
  const [handlePlaidError, setHandlePlaidError] = useState({
    status: false,
    message: "",
  });

  function storeTypeCategory(key, params) {
    setFetchTypeCategory((prev) => ({ ...prev, [key]: params }))
  }

  function plaidErrorDisplay(status, message) {
    setHandlePlaidError({
      status: status,
      message: message,
    });
  }

  function changePlaidCategories(params) {
    setPlaidCategories(params);
  }

  const { user } = useSelector((state) => state.auth);

  let id;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const handleNotNow = () => {
    // Set a flag in local storage to indicate that the user clicked "Not now"
    localStorage.setItem("notNowClicked", "true");
  };

  const showNext = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
      setCounter(counter + 1);
    }, 100);
  };

  const showPrevious = () => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
      setCounter(counter - 1);
    }, 100);
  };

  const hideNotNow = () => {
    handleNotNow();
  };

  const changeCounter = (params) => {
    setCounter(params);
  };
  const changeCounter4 = () => {
    setCounter(4);
  };

  function changeDisplay(params) {
    setDisplay(params);
  }

  const inputTarget = useRef(null);
  const checkCategory = (event) => {
    storeTypeCategory('category', event);
  };

  const checkType = (event) => {
    storeTypeCategory('type', event);
    setTimeout(() => {
      inputTarget.current.click();
    }, 400);
  };

  function changeLinkToken(params) {
    setPlaid_link_token(params);
  }

  useEffect(() => {
    if (props.dataAsset.grandTotal == '0.00' && props.dataLiability.grandTotal == '0.00') {
      changeCounter(1);
    }
  }, [props.dataAsset.grandTotal, props.dataLiability.grandTotal])

  return (
    <>

      {counter === 1 && !loader && (
        <InitialSetup1
          changeDisplay={changeDisplay}
          plaid_link_token={plaid_link_token}
          changeLinkToken={changeLinkToken}
          plaidCategories={plaidCategories}
          changePlaidCategories={changePlaidCategories}
          plaidErrorDisplay={plaidErrorDisplay}
          handlePlaidError={handlePlaidError}
          changeCategoryData={props.changeCategoryData}
          individualUserData={props.individualUserData}
          changeProfileState={props.changeProfileState}
          profileState={props.profileState}
          previous={showPrevious}
          refreshAsset={props.getCategoryTotal}
          refreshLiability={props.getCategoryTotalLib}
          changeFilterStatus={props.changeFilterStatus}
          mainFilterStatus={props.mainFilterStatus}
          hideNotNow={hideNotNow}
          closeTheModal={props.changeModal}
          changeCounter={changeCounter}
          dataAsset={props.dataAsset.grandTotal}
          dataLiability={props.dataLiability.grandTotal}
          next={showNext}
          changeCounter4={changeCounter4}
          showModal={props.showModal}
          display={display}
          errorAccounts={props.errorAccounts}
          CapsyncLogoutModal={props.CapsyncLogoutModal}
          setCategoryAccountAdded={(category) => props && props.setCategoryAccountAdded && props.setCategoryAccountAdded(category)}
        />
      )}
      {counter === 2 && !loader && (
        <InitialSetup2
          storeTypeCategory={storeTypeCategory}
          fetchTypeCategory={fetchTypeCategory}
          dataAsset={props.dataAsset.grandTotal}
          dataLiability={props.dataLiability.grandTotal}
          previous={showPrevious}
          next={showNext}
          showModal={props.showModal}
          checkCategory={checkCategory}
          checkType={checkType}
          inputTarget={inputTarget}
          closeTheModal={props.changeModal}
        />
      )}
      {counter === 3 && !loader && (
        <InitialSetup3
          changeCategoryData={props.changeCategoryData} // need to remove after new forms implementation
          toastMessage={props.toastMessage} // need to remove after new forms implementation
          fetchTypeCategory={fetchTypeCategory}
          changeCounter={changeCounter}
          previous={showPrevious}
          showModal={props.showModal}
          setCategoryAccountAdded={(category) => props && props.setCategoryAccountAdded && props.setCategoryAccountAdded(category)}
        />
      )}
      {counter === 4 && !loader && (
        <InitialSetup4
          changeCounter={changeCounter}
          refreshAsset={props.getCategoryTotal}
          refreshLiability={props.getCategoryTotalLib}
          closeTheModal={props.changeModal}
          getCategoryTotal={props.getCategoryTotal}
        />
      )}


    </>
  );
};
export default InitialCommonModel;
