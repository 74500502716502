/* eslint-disable */
import axios from "axios";
import { headerAddToken } from "../util/common";

const UseDownload = (customUrl, fileName, row_id, toastFn) => {
  let header = headerAddToken();
  axios({
    url: customUrl,
    method: "POST",
    data: { fileName: fileName, row_id: row_id.toString() },
    headers: {
      Authorization: header.headers.Authorization,
    },
  })
    .then((response) => {
      toastFn();
      window.location.href = response.data.url;
    })
    .catch((err) => {
      console.log(err);
    });
};

export default UseDownload;
