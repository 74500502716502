import React, { useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import successValidation from "../../../../../src/assets/images/other/payment-successful-icon.svg";
import { useNavigate } from "react-router-dom";
import CapsyncLoader from "../../CapsyncLoader";

const PaymentSuccessfull = (props) => {
  const { showPaymentSuccessfull } = props;

  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(false);

  const continueToDashboard = () => {
    setShowLoader(true);

    setTimeout(() => {
      navigate("/individual/verifyinvestor/pending");
      setShowLoader(false);
    }, 2000);
  };

  return (
    <>
      <Modal
        show={showPaymentSuccessfull}
        onHide={() => !showPaymentSuccessfull}
        centered
        size="md"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="cs-md-modal payment-success"
      >
        {showLoader ? (
          <div>
            <CapsyncLoader />
          </div>
        ) : (
          <>
            <Modal.Header>
              <Modal.Title className="default-semi-bold-h3">
                Payment Successful!
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="success-head">
                <span>
                  <Image
                    src={successValidation}
                    alt="success Validation"
                    width={100}
                    height={100}
                  />
                </span>
                <p className="default-light-body-text-m cs-neutral-90">
                  Congratulations! You are on your way to becoming a Verified
                  Investor. You'll receive notice of verification in 1-2
                  business days. In the case of a successful verification.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="cs-btn-primary xl-btn default-regular-h5"
                onClick={() => continueToDashboard()}
              >
                Continue to dashboard
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default PaymentSuccessfull;
