import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PlanCards from "../../../commonComponent/paymentSubscription/subscription/PlanCards";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";

const SubscribeModal = (props) => {
  const [showModal, setShowModal] = useState(true);

  const handleLogout = () => {
    props.CapsyncLogoutModal(true);
  }

  return (
    <>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        backdrop="static"
        keyboard={false}
        className="WM-choose-plan-modal"
      >
        <Modal.Body>
          <span className="cs-modal-close cs-neutral-60" onClick={handleLogout}>
            <CapsyncIcon title="close-outlined" size="22" /></span>
          <PlanCards intentType={1} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubscribeModal;
