/* eslint-disable */
import React, { useEffect, useState } from "react";
import { PieChart, Pie, Legend, ResponsiveContainer, Tooltip } from "recharts";
import "./CommonComponent.css";
import ReactApexChart from "react-apexcharts";
import { capitalizeName } from "../config/finance";

const tooltipColorBlack = [
  "#80BFA4",
  "#99CCB6",
  "#B3D9C8",
  "#CCE5DA",
  "#E6F2ED",
  "#F29A9A",
  "#F7C2C2",
  "#FAD7D7",
  "#FCEBEB",
];

const FinanceAllocation = ({
  pieData,
  title,
  heading,
  height = 420,
  width = 400,
  nameOffsetY = 0,
  valueOffsetY = 6,
  respNameOffsetY = 0,
  respValueOffsetY = 0,
  position = "bottom",
  isAmoritzation = false,
}) => {
  const [pieDataSeries, setPieDataSeries] = useState([]);
  const [pieDataOptions, setPieDataOptions] = useState([]);
  const pieChartOptions = {
    chart: {
      type: "donut",
      height: isAmoritzation ? height : 420,
      width: isAmoritzation ? width : 400,
      redrawOnParentResize: true,
      responsive: true,
    },
    labels: [],
    legend: {
      position: isAmoritzation ? "right" : position,
      verticalAlign: "center",
      onItemClick: {
        toggleDataSeries: !isAmoritzation,
      },
      onItemHover: {
        highlightDataSeries: !isAmoritzation,
      },
      markers: {
        size: 4,
        shape: "circle",
      },
      formatter: function (seriesName, opts) {
        const name =
          seriesName.length > 30 ? seriesName.slice(0, 18) + "..." : seriesName;
        if (isAmoritzation) {
          return `${name}<span class="amort-pie-value"> $ ${Number(
            Math.round(parseFloat(opts.w.globals.series[opts.seriesIndex]))
          ).toLocaleString(0)}</span>`;
        }
        const sum = opts.w.config.series.reduce((a, b) => a + b, 0);
        const percent = (opts.w.config.series[opts.seriesIndex] / sum) * 100;
        return `<span
          placement='top'
          class='chart-tooltip'
          data=''
          data-title='${seriesName} (${percent.toFixed(1)}%)'
        >${name}</span>`;
      },
      markers: {
        size: 4,
        shape: "circle",
      },
    },
    colors: [],
    responsive: [
      // Max-width: 1790px
      {
        breakpoint: 1900,
        options: {
          chart: {
            width: "100%",
            height: isAmoritzation ? 270 : 400,
          },

          legend: {
            position: position,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    offsetY: respValueOffsetY,
                  },
                  name: {
                    offsetY: respNameOffsetY,
                    formatter: (w) => {
                      const name = w.length > 20 ? w.slice(0, 18) + "..." : w;
                      return name;
                    },
                  },
                },
              },
            },
          },
        },
      },
      // Max-width: 1500px
      {
        breakpoint: 1500,
        options: {
          chart: {
            width: "100%",
            height: isAmoritzation ? 300 : 380,
          },

          legend: {
            position: isAmoritzation ? "bottom" : position,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    offsetY: respValueOffsetY,
                  },
                  name: {
                    offsetY: respNameOffsetY,
                    formatter: (w) => {
                      const name = w.length > 20 ? w.slice(0, 18) + "..." : w;
                      return name;
                    },
                  },
                },
              },
            },
          },
        },
      },
      // Max-width: 1499px
      {
        breakpoint: 1499,
        options: {
          chart: {
            width: "100%",
            height: isAmoritzation ? 300 : 420,
          },

          legend: {
            position: isAmoritzation ? "bottom" : position,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    offsetY: respValueOffsetY,
                  },
                  name: {
                    offsetY: respNameOffsetY,
                    formatter: (w) => {
                      const name = w.length > 20 ? w.slice(0, 18) + "..." : w;
                      return name;
                    },
                  },
                },
              },
            },
          },
        },
      },
      // Max-width: 1200px
      {
        breakpoint: 1200,
        options: {
          chart: {
            width: "100%",
            height: isAmoritzation ? 380 : 400,
          },
          legend: {
            position: isAmoritzation ? "bottom" : position,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    // offsetY: -5,
                  },
                },
              },
            },
          },
        },
      },

      // Max-width: 991px
      {
        breakpoint: 992,
        options: {
          chart: {
            width: "100%",
            height: isAmoritzation ? 380 : 400,
          },
          legend: {
            position: isAmoritzation ? "bottom" : position,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    // offsetY: -5,
                  },
                },
              },
            },
          },
        },
      },

      // Max-width: 576px
      {
        breakpoint: 576,
        options: {
          chart: {
            width: "100%",
            height: isAmoritzation ? 330 : 400,
          },
          legend: {
            position: isAmoritzation ? "bottom" : position,
            // formatter: (w) => {
            //   const name = w.length > 20 ? w.slice(0, 10) + "..." : w
            //   return name;
            // },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    // offsetY: -10,
                  },
                },
              },
            },
          },
        },
      },
    ],
    plotOptions: {
      pie: {
        donut: {
          size: "50%",
          labels: {
            show: true,
            name: {
              show: true,
              showAlways: true,
              fontSize: "18px",
              fontWeight: 400,
              color: "#0A0A0A",
              formatter: (w) => {
                const name = w.length > 20 ? w.slice(0, 18) + "..." : w;
                return name;
              },
              // offsetY: 240
              offsetY: nameOffsetY,
            },
            value: {
              show: true,
              showAlways: true,
              fontWeight: 600,
              fontSize: "20px",
              formatter: (w) => {
                return "$ " + Math.round(parseFloat(w)).toLocaleString(0);
              },
              // offsetY: 200,
              offsetY: valueOffsetY,
            },
            total: {
              show: true,
              showAlways: true,
              label: title,
              formatter: (w) => {
                let total;
                if (
                  w.globals.seriesTotals.length === 1 &&
                  w.globals.labels[0] === "Total Liabilities"
                ) {
                  total = -w.globals.seriesTotals[0];
                } else if (
                  w.globals.seriesTotals.length === 2 &&
                  w.globals.labels.includes("Total Assets") &&
                  w.globals.labels.includes("Total Liabilities")
                ) {
                  total = Math.round(
                    w.globals.seriesTotals[0] - w.globals.seriesTotals[1]
                  );
                } else {
                  total = w.globals.seriesTotals.reduce(
                    (sum, value) => sum + value,
                    0
                  );
                }

                return "$ " + Number(total.toFixed(0)).toLocaleString();
              },
            },
          },
        },
      },
    },
    tooltip: {
      enabled: true,
      // y: {
      //   formatter: val => "$ " + val.toLocaleString(0),
      // },
      custom: ({ seriesIndex, w, series, dataPointIndex }) => {
        let total = 0;
        for (let x of series) {
          total += x;
        }
        let selected = series[seriesIndex];

        return `<span style="
                  background-color:${w.config.colors[seriesIndex]}; 
                  padding:6px; 
                  color:${
                    tooltipColorBlack.includes(w.config.colors[seriesIndex])
                      ? "#0A0A0A"
                      : "#FFFFFF"
                  };
                  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.12);
                  font-family: Readex Pro;
                  font-size: 10px;
                  font-weight: 300;
                  line-height: 16px;
                  ">
                    ${w.config.labels[seriesIndex]}: $ ${series[
          seriesIndex
        ].toLocaleString(0)} (${((selected / total) * 100).toFixed(1)}%)
                </span>`;
      },
    },
    dataLabels: {
      enabled: true,
    },
  };

  useEffect(() => {
    if (pieData && pieData.length) {
      setPieDataSeries(pieData.map((data) => (data.value ? data.value : 0)));
      setPieDataOptions({
        ...pieChartOptions,
        labels: pieData.map((data) => capitalizeName(data.name)),
        colors: pieData.map((data) => data.fill),
        dataLabels: {
          ...pieChartOptions.dataLabels,
          style: {
            colors: pieData.map((data) =>
              tooltipColorBlack.includes(data.fill) ? "#0A0A0A" : "#FFFFFF"
            ),
          },
        },
      });
    }
  }, [pieData]);

  // {
  //   fill:"#3D9C73"
  //   name:"Total Assets"
  //   value:7841599.5
  // }

  return (
    <>
      <div>
        <div className="chart-heading">
          <h2 className="default-semi-bold-h2">{heading}</h2>
        </div>
        {/* <div className="graph-pie-chart-main-container asset-pie-chart">
           {pieDataSeries && pieDataSeries.length ? <ReactApexChart options={pieDataOptions} series={pieDataSeries} type="donut" height={height} /> : null}
           </div> */}
        {pieData && pieData.length > 0 && (
          <div className="common-pie-charts">
            {pieDataSeries && pieDataSeries.length ? (
              <ReactApexChart
                options={pieDataOptions}
                series={pieDataSeries}
                type="donut"
                height={height}
              />
            ) : null}
          </div>
        )}
      </div>
      {pieData && pieData.length === 0 && (
        <span className="cs-common-no-record">
          {" "}
          There are no records to display{" "}
        </span>
      )}
    </>
  );
};

export default FinanceAllocation;
