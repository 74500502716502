import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import file_management_service from "../services/fileManagement.service";
import { localDataRemove } from "../config/finance";
import FileDownload from "js-file-download";
import { backAppURl } from "../applicationMode";
import axios from "axios";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { headerAddToken } from "../util/common";
import { toast } from "react-toastify";

export const fetch_main_list = createAsyncThunk(
  "file_management/fetch_category",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.fetch_main_list(
        values.id,
        values.user_id,
        values.isMove
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const fetch_trash_list = createAsyncThunk(
  "file_management/fetch_trash_list",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.fetch_trash_list(
        values.userID,
        values.lastDays
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const searchByKeyword = createAsyncThunk(
  "file_management/search",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.files_search(
        values.id,
        values.keyword
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const searchByKeywordArchive = createAsyncThunk(
  "file_management/searchByKeywordArchive",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.archive_files_search(
        values.id,
        values.keyword
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const searchByKeywordTrash = createAsyncThunk(
  "file_management/searchByKeywordTrash",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.trash_files_search(
        values.id,
        values.keyword
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const file_upload = createAsyncThunk(
  "folder/upload_files",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.file_upload(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const check_life_insurance_data = createAsyncThunk(
  "folder/life_insurance_data_check",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.check_life_insurance_data(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const file_upload_dropbox = createAsyncThunk(
  "folder/upload_files_dropbox",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.file_upload_dropbox(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const file_upload_google_drive = createAsyncThunk(
  "folder/upload_files_google_drive",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.file_upload_google_drive(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const folder_upload = createAsyncThunk(
  "folder/upload_folder",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.folder_upload(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const folder_create = createAsyncThunk(
  "folder/create_folder",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.folder_create(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const files_download = createAsyncThunk(
  "folder/files_download",
  async (values, thunkAPI) => {
    let header = await headerAddToken();
    let uId = Date.now();
    try {
      thunkAPI.dispatch(
        showDownloadProgress({
          files: ["Documents.zip"],
          progress: 0,
          id: uId,
          isDelete: false,
        })
      );
      const response = await axios({
        url: `${backAppURl}/api/file-manage/file_download`,
        method: "POST",
        data: { ...values },
        responseType: "blob", // Specify the response type as blob
        headers: header.headers,
        onDownloadProgress: (progressEvent) => {
          let total = (progressEvent.loaded * 100) / progressEvent.total;
          thunkAPI.dispatch(
            showDownloadProgress({
              files: ["Documents.zip"],
              progress: Math.floor(total),
              id: uId,
              isDelete: false,
            })
          );
        },
      });

      if (response) {
        setTimeout(() => {
          thunkAPI.dispatch(
            showDownloadProgress({
              files: ["Documents.zip"],
              progress: 100,
              id: uId,
              isDelete: false,
            })
          );
        }, 500);
        setTimeout(() => {
          thunkAPI.dispatch(showDownloadProgress({ id: uId, isDelete: true }));
          toast.success("Your Document has been downloaded successfully");
          FileDownload(response.data, "Document.zip");
        }, 1000);
        return response.data;
      }
    } catch (error) {
      setTimeout(() => {
        thunkAPI.dispatch(showDownloadProgress({ id: uId, isDelete: true }));
      }, 1000);
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      return thunkAPI.rejectWithValue({ error: errorMessage });
    }
  }
);

export const encrypted_files_download = createAsyncThunk(
  "folder/encrypted_files_download",
  async (values, thunkAPI) => {
    let header = await headerAddToken();
    let uId = Date.now();
    try {
      thunkAPI.dispatch(
        showDownloadProgress({
          files: ["Documents.zip"],
          progress: 0,
          id: uId,
          isDelete: false,
        })
      );
      const response = await axios({
        url: `${backAppURl}/api/file-manage/file_download_zip_protected`,
        method: "POST",
        data: { ...values },
        headers: header.headers,
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          let total = (progressEvent.loaded * 100) / progressEvent.total;
          thunkAPI.dispatch(
            showDownloadProgress({
              files: ["Documents.zip"],
              progress: Math.floor(total),
              id: uId,
              isDelete: false,
            })
          );
        }, // Specify the response type as blob
      });

      if (response) {
        // Save the file using file-saver
        setTimeout(() => {
          thunkAPI.dispatch(
            showDownloadProgress({
              files: ["Documents.zip"],
              progress: 100,
              id: uId,
              isDelete: false,
            })
          );
        }, 500);
        setTimeout(() => {
          thunkAPI.dispatch(showDownloadProgress({ id: uId, isDelete: true }));
          toast.success("Your Document has been downloaded successfully");
          FileDownload(response.data, "Document.zip");
        }, 1000);
        return response.data;
      }
    } catch (error) {
      setTimeout(() => {
        thunkAPI.dispatch(showDownloadProgress({ id: uId, isDelete: true }));
      }, 1000);
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      return thunkAPI.rejectWithValue({ error: errorMessage });
    }
  }
);

export const delete_file = createAsyncThunk(
  "file_management/delete_file",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.delete_file(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const delete_multi_file = createAsyncThunk(
  "file_management/delete_multi_file",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.delete_multi_file_services(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const folder_rename = createAsyncThunk(
  "folder/create_rename",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.folder_rename(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const restore_from_trash = createAsyncThunk(
  "file_management/restore_from_trash",
  async (payload, thunkAPI) => {
    try {
      const response = await file_management_service.trash_restore(payload);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const delete_from_trash = createAsyncThunk(
  "file_management/delete_from_trash",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.trash_delete(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const empty_trash = createAsyncThunk(
  "file_management/empty_trash",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.empty_trash(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const archive_file = createAsyncThunk(
  "file_management/archive_file",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.archive_file_services(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const get_archive_file = createAsyncThunk(
  "file_management/get_archive_file",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.get_archive_file_services(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const get_user_storage = createAsyncThunk(
  "file_management/get_user_storage",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.get_user_storage_services(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.code === 401 || error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const restore_archive_file = createAsyncThunk(
  "file_management/restore_archive_file",
  async (values, thunkAPI) => {
    try {
      const response =
        await file_management_service.restore_archive_file_services(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const file_add = createAsyncThunk(
  "file_management/file_add",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.file_add(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const move_file_folder = createAsyncThunk(
  "file_management/move_file",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.move_file_folder(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const scan_attachment = createAsyncThunk(
  "file_management/scan_attachment",
  async (values, thunkAPI) => {
    try {
      const response = await file_management_service.scan_attachment(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

const initialState = {
  uploadProgress: { totalFilesCount: 0, data: [] },
  downloadProgress: { totalFilesCount: 0, data: [] },
  progressUploadDetails: [],
  storageData: {
    maxSpace: 0,
    usedSpace: 0,
  },
};

const fileManagementSlice = createSlice({
  name: "fileManagement",
  initialState,
  reducers: {
    showUploadProgress: (state, action) => {
      const { uploadProgress } = state;
      const { payload } = action;

      if (payload.isDelete) {
        const updatedData = uploadProgress.data.filter(
          (item) => item.id !== payload.id
        );
        const totalFilesCount = updatedData.reduce(
          (total, item) => total + item.files.length,

          0
        );

        state.uploadProgress = {
          ...uploadProgress,
          data: updatedData,
          totalFilesCount: totalFilesCount,
        };
      } else {
        const existingIndex = uploadProgress.data.findIndex(
          (item) => item.id === payload.id
        );

        const updatedUploadProgressData =
          existingIndex !== -1
            ? uploadProgress.data.map((item, index) =>
                index === existingIndex
                  ? { ...item, progress: payload.progress }
                  : item
              )
            : [...uploadProgress.data, payload];

        const totalFilesCount = updatedUploadProgressData.reduce(
          (total, item) => total + item.files.length,
          0
        );

        state.uploadProgress = {
          ...uploadProgress,
          data: updatedUploadProgressData,
          totalFilesCount: totalFilesCount,
        };
      }
    },
    showDownloadProgress: (state, action) => {
      const { downloadProgress } = state;
      const { payload } = action;

      if (payload.isDelete) {
        const updatedData = downloadProgress.data.filter(
          (item) => item.id !== payload.id
        );
        const totalFilesCount = updatedData.reduce(
          (total, item) => total + item.files.length,

          0
        );

        state.downloadProgress = {
          ...downloadProgress,
          data: updatedData,
          totalFilesCount: totalFilesCount,
        };
      } else {
        const existingIndex = downloadProgress.data.findIndex(
          (item) => item.id === payload.id
        );

        const updatedDownloadProgressData =
          existingIndex !== -1
            ? downloadProgress.data.map((item, index) =>
                index === existingIndex
                  ? { ...item, progress: payload.progress }
                  : item
              )
            : [...downloadProgress.data, payload];

        const totalFilesCount = updatedDownloadProgressData.reduce(
          (total, item) => total + item.files.length,
          0
        );

        state.downloadProgress = {
          ...downloadProgress,
          data: updatedDownloadProgressData,
          totalFilesCount: totalFilesCount,
        };
      }
    },
    setProgressUploadDetails: (state, action) => {
      const { progressUploadDetails } = state;
      const { payload } = action;

      if (payload.isDelete) {
        const updatedData = progressUploadDetails.filter(
          (item) => item.id !== payload.id
        );
        state.progressUploadDetails = updatedData;
      } else {
        const existingIndex = progressUploadDetails.findIndex(
          (item) => item.id === payload.id
        );
        if (existingIndex !== -1) {
          const updatedData = [...progressUploadDetails];
          updatedData[existingIndex] = payload;
          state.progressUploadDetails = updatedData;
        } else {
          state.progressUploadDetails = [...progressUploadDetails, payload];
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(get_user_storage.fulfilled, (state, action) => {
      state.storageData = action.payload && action.payload.data.storage;
    });
  },
});

export const {
  showUploadProgress,
  showDownloadProgress,
  setProgressUploadDetails,
} = fileManagementSlice.actions;
export default fileManagementSlice.reducer;

export const selectUploadProgress = (state) =>
  state.fileManagement.uploadProgress;
export const useUploadProgress = () => {
  const uploadProgress = useSelector(selectUploadProgress);
  return useMemo(() => uploadProgress, [uploadProgress]);
};
export const selectStorage = (state) => state.fileManagement.storageData;
export const useUploadStorage = () => {
  const storageData = useSelector(selectStorage);
  return useMemo(() => storageData, [storageData]);
};
export const selectDownloadProgress = (state) =>
  state.fileManagement.downloadProgress;
export const useDownloadProgress = () => {
  const downloadProgress = useSelector(selectDownloadProgress);
  return useMemo(() => downloadProgress, [downloadProgress]);
};

export const selectProgressUploadDetails = (state) =>
  state.fileManagement.progressUploadDetails;
export const useProgressUploadDetails = () => {
  const progressUploadDetails = useSelector(selectProgressUploadDetails);
  return useMemo(() => progressUploadDetails, [progressUploadDetails]);
};
