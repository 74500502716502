/* eslint-disable */
import React from "react";
import { Form, Row, Col, Image, Dropdown, FormGroup } from "react-bootstrap";
import SubMenuHeader from "../SubMenuHeader";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import CapsyncToolTip from "../../../../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import SubMethodsList from "../../SubMethodsList";
import { Link } from "react-router-dom";
import CapsyncIcon from "../../../../../../commonComponent/CapsyncIcon";
import "./QualifiedPurchaserSubMethod.css"
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";

// sequence No. four  (Asmita date-29th June => Do not remove this line)
const MethodIdThirtyThree = (props) => {


  let investorTypeArray = [
    "Individual",
    "Company"
  ];
  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Qualified Purchaser",
      url: `/individual/verifyinvestor/accreditedInvestor/qualifiedpurchaser/${props.unique_code}`,
    },
  ];



  return (
    <>
      {props.is_loader.assets === true || props.is_loader.liabilities === true ? <SkeletonAllSubMethods /> :
        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="$25M+ Investments Management"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>
              <SubMenuHeader />
            </div>
            <div className="method-section method-thirty-three">
              <div className="default-regular-body-text-l cs-neutral-70">
                <SubMethodsList methodId={35} />
              </div>

              <div className="cs-divider"></div>
              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert}
                />
                <div className="row-spacing">

                  <p className="default-regular-sub-heading-m cs-neutral-70">
                    In order to verify your accreditation we need you to upload
                    evidence. Please upload the appropriate files below and black-out
                    any sensitive information.
                  </p>
                  <Link
                    to="https://s3.amazonaws.com/static-capsync.com/Verify-Investor-files/Net-investments.pdf"
                    target="_blank"
                  >
                    <u className="default-regular-sub-heading-m cs-neutral-100">
                      Examples of Net Investments and how these are calculated.
                    </u>
                  </Link>
                </div>

                <Row>
                  <Col lg={5}>
                    <Form.Label className="default-regular-sub-heading-s cs-neutral-100">
                      Are you investing as an individual or company?
                    </Form.Label>
                    <div className={props.selectInvestorError ? "investor-type-dropdown investor-error" : "investor-type-dropdown"}>
                      <Dropdown className="dropdown cs-form-dropdown">
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic-accredited"
                          className="dropdown-toggle default-regular-sub-heading-s"
                        >
                          {props.selectInverstorType}
                          <span className="input-field-icon cs-neutral-90">
                            <CapsyncIcon title="chevron-down-outlined" size="16" />
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {investorTypeArray.map((item) => (
                            <Dropdown.Item
                              className={
                                item === props.selectInverstorType
                                  ? "dropdown-item dropdown-item-active default-regular-sub-heading-s "
                                  : "default-regular-sub-heading-s cs-content-title "
                              }
                              key={item}
                              onClick={() => props.handleInvestorType(item)}
                            >
                              {item}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                        {props.selectInvestorError && <span className="default-regular-sub-heading-xs cs-danger">Select an option whether you are investing as an individual or a company</span>}
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
                {props.selectInverstorType === "Company" &&
                  <>
                    <Form.Check
                      type="checkbox"
                      id="default-checkbox"
                      // className="cs-form-check-box default-regular-sub-heading-s cs-neutral-90"
                      className={props.isCheckedError ? "cs-form-check-box default-regular-sub-heading-s cs-neutral-90 investor-error" : "cs-form-check-box default-regular-sub-heading-s cs-neutral-90"}
                      aria-label="option 1"
                      name="terms"
                      feedbackType="invalid"
                      label="I certify, represent and warrant that, with respect to the company making the investment:"
                      checked={props.isChecked}
                      onChange={props.handleCheckbox}
                    />
                    {props.isCheckedError && <span className="default-regular-sub-heading-xs cs-danger"> Check the box to make the representation that the investor meets the requirements indicated.</span>}

                    <ul className="default-regular-sub-heading-s cs-neutral-90">
                      <li>
                        it was not formed for the purpose of investing in the investment;
                      </li>
                      <li >
                        it has not and will not invest more than 40% of its total assets in the investment; and </li>
                      <li >
                        no investor in or beneficiary of the company can independently decide whether or not to participate in any particular investment made by the company.
                      </li>
                    </ul>
                  </>
                }

                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Upload evidence of at least $25,000,000 USD in Net Investments:
                </Form.Label>
                <UploadFileBlock
                  files={props.files}
                  setOpenModal={props.setOpenModal}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile}
                  showFile={props.showFile}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink}
                  uploadError={props.uploadError}
                  showError={props.showError}

                />
                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Upload proof of Investment management (optional):
                </Form.Label>
                <UploadFileBlock
                  files={props.files2}
                  setOpenModal={props.setOpenModal2}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile2}
                  showFile={props.showFile2}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink2}

                />
                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Upload other evidence (optional):
                </Form.Label>
                <UploadFileBlock
                  files={props.files3}
                  setOpenModal={props.setOpenModal3}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile3}
                  showFile={props.showFile3}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink3}

                />
                <NotesBlock setNotesValue={props.setNotesValue} />
              </Form>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default MethodIdThirtyThree;
