import React from "react";
import { Link } from "react-router-dom";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";

function changeCase(params) {
  let newContent = "";
  newContent += params;
  return (
    newContent.substring(0, 1).toUpperCase() +
    newContent.substring(1, newContent.length)
  );
}

const BreadCrumHeader = ({ breadcrumb }) => {
  return (
    <>
      <div className="cs-breadcrumb">
        <Link to={`/individual/${breadcrumb.main_category}`} className="default-light-body-text-s cs-neutral-80">
          {changeCase(breadcrumb.main_category)}
        </Link>
        <span className="cs-neutral-70">
            <CapsyncIcon title="chevron-right-outlined" size="12" />
          </span>
        <Link to={`/individual/${breadcrumb.main_category}/${breadcrumb.category_url}`} className="default-light-body-text-s cs-neutral-80">
          {breadcrumb.sub_category}
        </Link>
        <span className="cs-neutral-70">
            <CapsyncIcon title="chevron-right-outlined" size="12" />
          </span>
        <span className="default-light-body-text-s cs-neutral-100">
          {breadcrumb.institute}
        </span>
      </div>
      
    </>
  );
};

export default BreadCrumHeader;
