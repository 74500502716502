import React from "react";
import { Image } from "react-bootstrap";
import CapsyncLoaderSpin from "../../assets/images/capsync-loader.gif";

const CapsyncLoader = ({ size }) => {
  return (
    <div className="cs-loader">
      <Image
        src={CapsyncLoaderSpin}
        alt="Capsync Loader"
        width={size ? size : 192}
        height={size ? size : 192}
      />
    </div>
  );
};

export default CapsyncLoader;
