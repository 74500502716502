/* eslint-disable */
import { useEffect } from "react";
import { show_categories } from "../slices/masterTable.slice";
import { useDispatch } from "react-redux";

const UseMasterTable = () => {
  const dispatch = useDispatch();

  function fetchMasterCategories() {
    dispatch(show_categories())
      .unwrap()
      .then((response) => {
        // window.localStorage.setItem('mainCategories', JSON.stringify(response))
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    fetchMasterCategories();
  }, []);

};

export default UseMasterTable;
