import React, { Fragment } from "react";
import FetchDefaultInstitutionLogo from "../../../commonComponent/paymentSubscription/FetchDefaultInstitutionLogo";

const DefaultPaymentMethod = ({ defaultCard }) => {
  const itemToken =
    defaultCard && JSON.parse(defaultCard.stripe_payment_method);
  const paymentTitle = () => {
    if (defaultCard && defaultCard.subscription_type === 1) {
      return itemToken.card && itemToken.card.funding === "credit"
        ? "Credit Card"
        : "Debit Card";
    } else {
      return "Bank Account";
    }
  };

  return (
    <div
      className={`credit-change-method change-method-active ${
        defaultCard ? "" : "no-default-card"
      }`}
    >
      {defaultCard ? (
        <Fragment>
          <div className="change-credit-text">
            <p className="default-regular-body-text-l cs-neutral-80">
              {paymentTitle()}
            </p>
            <span className="change-card-image default-semi-bold-h3 cs-neutral-100">
              <div className="cs-card-logo">
                <FetchDefaultInstitutionLogo
                  img={
                    itemToken
                      ? itemToken.card
                        ? itemToken.card.brand
                        : "Bank Account"
                      : ""
                  }
                />
              </div>
              **** **** ****{" "}
              {itemToken
                ? itemToken.card
                  ? ` ${itemToken.card.last4}`
                  : ` ${itemToken.us_bank_account.last4}`
                : ""}
            </span>
          </div>
        </Fragment>
      ) : (
        <div className="no-default-card-message">No Default Card Selected</div>
      )}
    </div>
  );
};

export default DefaultPaymentMethod;
