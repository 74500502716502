import React from "react";
import { Card } from "react-bootstrap";
import CapsyncIcon from "../../CapsyncIcon";

const StoragePlanCards = (props) => {
  const { plans, selectedPlan, setSelectedPlan } = props;

  return (
    <div className="subscript-Planarea subscription-plan-cards">
      {plans &&
        plans.map((items, index) => (
          <Card
            key={index}
            className={
              selectedPlan && selectedPlan.id === items.id
                ? "card-simple-plan selected-plan "
                : "card-simple-plan "
            }
            onClick={() => setSelectedPlan(items)}
          >
            <Card.Body className="card-plan">
              <div className="card-plan-summary">
                <span className="cs-primary">
                  <CapsyncIcon title="folder-icon-filled" size="24" />
                </span>
                <h2 className="default-semi-bold-h1 cs-neutral-100">
                  {items.name}
                </h2>
              </div>
              <div className="card-plan-amount">
                <h2 className="default-semi-bold-h1 cs-neutral-100">
                  $&nbsp;{(items.price.unit_amount / 100).toFixed(2)}
                </h2>
                <span className="default-regular-h4 cs-neutral-60">
                  {"/" + items.price.recurring.interval}
                </span>
              </div>
              <div className="card-specification">
                <span className="default-regular-sub-heading-s">
                  {items.metadata.storage_size &&
                    "Up to " + items.metadata.storage_size}
                </span>
                <ul className="default-light-body-text-xs">
                  {items.features.map((features, index) => {
                    return <li key={index}>{features.name}</li>;
                  })}
                </ul>
              </div>
            </Card.Body>
          </Card>
        ))}
    </div>
  );
};

export default StoragePlanCards;
