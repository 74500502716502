import { createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import common_payment_plan from "../services/common_payment_plans.service";
import { localDataRemove } from "../config/finance";

export const fetch_all_payment_plan = createAsyncThunk(
  "/paymentplan/fetch_all_payment_plan",
  async (values, thunkAPI) => {
    try {
      const response = await common_payment_plan.fetch_all_payment_plan(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);