import commonNames from "../../../../config/commonNames";
import { INDIVIDUAL_PATHS } from "../../../../config/paths";

export const EDIT_INDIVIDUAL_PERMISSIONS = [
  "verifyinvestor",
  "collaboration",
  "request",
  "activity-history",
  "identity-protect",
  "credit-manager",
  "identity-protect-v2",
  "credit-manager-v2",
];

export const INDIVIDUAL_MENU_ITEMS = [
  {
    link: "/individual",
    activeKey: INDIVIDUAL_PATHS.DASHBOARD,
    title: commonNames.Dashboard,
    id: "dashboard",
    icon: "dashboard",
    isShow: true,
    isNotifications: false,
  },
  {
    link: "/individual/assets",
    activeKey: INDIVIDUAL_PATHS.ASSETS,
    title: commonNames.Assets,
    id: "asset",
    icon: "asset",
    isShow: true,
    isNotifications: false,
  },
  {
    link: "/individual/liabilities",
    activeKey: INDIVIDUAL_PATHS.LIABILITIES,
    title: commonNames.Liabilities,
    id: "liabilities",
    icon: "liability",
    isShow: true,
    isNotifications: false,
  },
  {
    link: "/individual/file-management",
    activeKey: INDIVIDUAL_PATHS.FILE_MANAGEMENT,
    title: commonNames.FileManagement,
    id: "file-management",
    icon: "files-management",
    isShow: true,
    isNotifications: false,
    subItems: [
      {
        link: "/individual/file-management",
        activeKey: INDIVIDUAL_PATHS.FILE_MANAGEMENT,
        title: commonNames.MyDocuments,
        id: "file-management",
        icon: "folder-icon",
        isShow: true,
        isNotifications: false,
      },
      {
        link: "/individual/archive",
        activeKey: INDIVIDUAL_PATHS.ARCHIVE,
        title: commonNames.Archive,
        id: "archive",
        icon: "archive",
        isShow: true,
        isNotifications: false,
      },
      {
        link: "/individual/trash",
        activeKey: INDIVIDUAL_PATHS.TRASH,
        title: commonNames.trash,
        id: "trash",
        icon: "delete",
        isShow: true,
        isNotifications: false,
      },
    ],
  },
  {
    link: "/individual/credit-manager",
    activeKey: INDIVIDUAL_PATHS.CREDIT_MANAGER,
    title: commonNames.CreditManager,
    id: "credit-manager",
    icon: "credit",
    isShow: true,
    isNotifications: false,
  },
  {
    link: "/individual/identity-protect",
    activeKey: INDIVIDUAL_PATHS.IDENTITY_PROTECT,
    title: commonNames.IdentityProtect,
    id: "identity-protect",
    icon: "protect",
    isShow: true,
    isNotifications: false,
  },
  {
    link: "/individual/verifyinvestor/pending",
    activeKey: INDIVIDUAL_PATHS.VERIFY_INVESTOR,
    title: commonNames.Accreditation,
    id: "verifyinvestor",
    icon: "accreditation",
    isShow: true,
    isNotifications: false,
  },
  {
    link: "/individual/collaboration",
    activeKey: INDIVIDUAL_PATHS.COLLABORATION,
    title: commonNames.Collaboration,
    id: "collaboration",
    icon: "user-multiple",
    isShow: true,
    isNotifications: false,
  },
  {
    link: "/individual/request",
    activeKey: INDIVIDUAL_PATHS.REQUEST,
    title: commonNames.Requests,
    id: "request",
    icon: "message-request",
    isShow: true,
    isNotifications: true,
  },
  {
    link: "/individual/activity-history",
    activeKey: INDIVIDUAL_PATHS.ACTIVITY_HISTORY,
    title: commonNames.ActivityHistory,
    id: "activity-history",
    icon: "history",
    isShow: true,
    isNotifications: false,
  },
];
