import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Table } from 'react-bootstrap';

const generateTableHeader = (noOfCols) => (
    <tr>
        {[...Array(noOfCols)].map((_, index) => (
            <th key={index} className="default-medium-sub-heading-m" width="260">
                <Skeleton width={200} />
            </th>
        ))}
        <th width="104"></th>
    </tr>
);

const generateTableRow = (noOfCols) => (
    <tr>
        {[...Array(noOfCols)].map((_, index) => (
            <td key={index}>
                <Skeleton width={200} />
            </td>
        ))}
        <td className="go-next-page-div">
            <Skeleton />
        </td>
    </tr>
);

const AccordionSkeleton = ({ noOfRows, noOfCols }) => {
    const numRowsToShow = noOfRows > 10 ? 10 : noOfRows;
    return (<Table className="cs-table skeleton-table">
        <thead className="cs-thead">{generateTableHeader(noOfCols)}</thead>
        <tbody className="cs-tbody default-regular-sub-heading-m">
            {[...Array(numRowsToShow)].map((_, index) => (
                <React.Fragment key={index}>{generateTableRow(noOfCols)}</React.Fragment>
            ))}
        </tbody>
    </Table>)
};

export default AccordionSkeleton;
