/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form, FormLabel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { getValidAddress, setAddressString } from "../../../../util/common";
import ProfileImageComponent from "../../../profileImage/ProfileCard";
import GoogleMapImg from "../../../../../assets/images/other/google-map.png";
import {
  authUserUpdated,
  getWmDataByLicenseNumber,
  userInitialModalSetup,
  wealth_managers_findone,
} from "../../../../slices/auth";
import { wealth_managers_update } from "../../../../slices/wealthManager.slice";
import { useNavigate } from "react-router-dom";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import useDebounce from "../../../../customHooks/useDebounce";
import AddressSearch from "../../../../commonComponent/AddressSearch";
import { autoCompleteAddress } from "../../../../services/thirdPartyAPI";
import { uniValidation } from "../../../individual/liabilities/categoryForms/validationSchema";

const CompleteProfile = (props) => {
  const [saveDisable, setSaveDisable] = useState(true);
  const { user } = useSelector((state) => state.auth);
  const user_Id = user && user.user.id;
  const role = user.user_role.role;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [autocompletePredictions, setAutocompletePredictions] = useState([]);
  const [address, setAddress] = useState(
    user.user.address ? setAddressString(user.user.address) : ""
  );
  const [addressObj, setAddressObj] = useState(
    user.user.address || {
      line1: "",
      line2: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
    }
  );
  const [addresses, setAddresses] = useState([]); // comes with API
  const [isManualAddress, setIsManualAddress] = useState(false); //Is Manual address
  const [isLoading, setIsLoading] = useState(true);
  const [msg, setMsg] = useState({
    address: "",
  });
  const [isAddressChange, setIsAddressChange] = useState(false);

  let id;
  const licenseId = user.user.licenceId;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const initialAddress = {
    street_name: "",
    city: "",
    state: "",
    zip_code: "",
  };

  const handleChange = ({ target }) => {
    setFieldValue(target.name, target.value);
  };

  const manualAddressValidationSchema = yup.object({
    street_name: yup
      .string()
      .required(uniValidation.street_name.require)
      .test(
        "is-not-only-spaces",
        uniValidation.street_name.require,
        (value) => {
          return value.trim() !== "";
        }
      )
      .matches(/(?=.*?\d)/, uniValidation.street_name.valid)
      .matches(/[A-Za-z]{2}/, uniValidation.street_name.valid),
    city: yup
      .string()
      .required(uniValidation.city.require)
      .test("is-not-only-spaces", uniValidation.city.require, (value) => {
        return value.trim() !== "";
      })
      .min(2, uniValidation.city.valid),
    state: yup
      .string()
      .matches(/^\S(?:.*\S)?$/, "No spaces allowed")
      .min(2, "Required at least 2 characters"),
    zip_code: yup
      .string()
      .required(uniValidation.zip_code.require)
      .test("is-not-only-spaces", uniValidation.zip_code.require, (value) => {
        return value.trim() !== "";
      })
      .matches(/^[0-9]+$/, uniValidation.zip_code.valid)
      .min(5, uniValidation.zip_code.valid)
      .max(5, uniValidation.zip_code.valid),
  });

  const {
    values,
    errors,
    touched,
    status,
    handleSubmit,
    setFieldValue,
    setStatus,
    setFieldTouched,
  } = useFormik({
    initialValues: initialAddress,
    validationSchema: manualAddressValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (
        !!isAddressChange &&
        !!status &&
        (!!status.street_name ||
          !!status.city ||
          !!status.state ||
          !!status.zip_code)
      ) {
        return;
      }
      let user_details = {};
      const {
        firstName,
        lastName,
        displayName,
        email,
        companyName,
        companyLicenceId,
        licenceId,
      } = user.user;
      user_details.id = id;
      user_details.address = addressObj;
      user_details.firstName = firstName;
      user_details.lastName = lastName;
      user_details.displayName = displayName;
      user_details.email = email;
      user_details.companyName = companyName;
      user_details.companyLicenceId = companyLicenceId;
      user_details.licenceId = licenceId;
      user_details.initial_setup_done = true;
      dispatch(wealth_managers_update(user_details))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            let getValue = localStorage.getItem("user");
            let user = JSON.parse(getValue);
            user.user["initial_setup_done"] = true;
            window.localStorage.setItem("user", JSON.stringify(user));
            dispatch(authUserUpdated({ user: user, addressobj: addressObj }))
              .then(async () => {
                await dispatch(
                  wealth_managers_findone({ wm_id: user_Id })
                ).unwrap();
              })
              .catch((err) => {
                console.log("err :>> ", err);
              });
            setSaveDisable(false);
            // navigate('/')
          } else {
            setSaveDisable(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setSaveDisable(true);
        });
    },
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      dispatch(getWmDataByLicenseNumber({ licenseId: licenseId }, { signal }))
        .unwrap()
        .then(async (res) => {
          let allAddress = [];
          const addresses = res && res.address ? res.address : [];
          for (let index = 0; index < addresses.length; index++) {
            const element = res.address[index];
            const autocompleteService = await autoCompleteAddress({
              search_data: setAddressString(element),
            });
            if (
              autocompleteService &&
              autocompleteService.status === 200 &&
              !!autocompleteService.data &&
              autocompleteService.data.status === "OK" &&
              !!autocompleteService.data.predictions
            ) {
              allAddress = [
                ...allAddress,
                autocompleteService.data.predictions[0],
              ];
            }
          }

          setAddresses(allAddress);
          setAutocompletePredictions([
            ...autocompletePredictions,
            ...allAddress,
          ]);
        });
    } catch (err) {
      console.log(err);
    }
    return () => {
      controller.abort();
    };
  }, []);

  const handleLogout = () => {
    props.CapsyncLogoutModal(true);
  };
  useEffect(() => {
    if (isAddressChange === true) {
      setIsLoading(false);
    }
  }, [isAddressChange]);

  return (
    <>
      <Modal
        show={props.showModal}
        onHide={() => handleLogout()}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="fa-cp-popup plaid-modal"
        id="connect-your-institutions"
        keyboard={false}
        dialogClassName={"cs-lg-modal"}
      >
        <div className="cs-complete-profile">
          <Modal.Header>
            <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
              Complete profile
            </Modal.Title>
            <span
              className="cs-neutral-70 cs-close-btn"
              onClick={() => handleLogout()}
            >
              <CapsyncIcon title="close-outlined" size="20" />
            </span>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Row>
                <p className="default-medium-sub-heading-m text-center cs-neutral-100">
                  Add or Edit your personal info to complete profile
                </p>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="personal-profile">
                    <div className="profile cs-avatar-lg">
                      <ProfileImageComponent
                        toastMessage={props.toastMessage}
                        role={user && user.user_role.role}
                        id={id}
                        profileImageUrl={user && user.user.profileImageUrl}
                        changeProfileState={props.changeProfileState}
                        profileState={props.profileState}
                        changeSrcStatus={props.changeSrcStatus}
                        customLoginImgSrc={props.customLoginImgSrc}
                        setCustomLoginImgSrc={props.setCustomLoginImgSrc}
                        customImgSrc={props.customImgSrc}
                        user={user}
                      />
                    </div>
                    <input
                      type="file"
                      name="file"
                      style={{ display: "none" }}
                      id="flexCheckDefault"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={6} sm={6}>
                  <Form.Group className="cs-form-group cs-disabled">
                    <FormLabel>First name</FormLabel>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="John"
                      disabled={true}
                      name="firstName"
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.selectionStart === 0) {
                          e.preventDefault();
                        }
                      }}
                      defaultValue={user.user.firstName}
                      readOnly
                      maxLength={40}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} md={6} sm={6}>
                  <Form.Group className="cs-form-group cs-disabled">
                    <FormLabel>Last name </FormLabel>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="Doe"
                      name="lastName"
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.selectionStart === 0) {
                          e.preventDefault();
                        }
                      }}
                      disabled={true}
                      defaultValue={user.user.lastName}
                      readOnly
                      maxLength={40}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group className="cs-form-group cs-disabled">
                    <FormLabel>Email</FormLabel>
                    <Form.Control
                      type="text"
                      autoComplete="off"
                      placeholder="John"
                      onKeyDown={(event) => {
                        if (event.code === "Space") event.preventDefault();
                      }}
                      name="email"
                      disabled={true}
                      defaultValue={user.user.email}
                      readOnly
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group className="cs-form-group cs-disabled">
                    <FormLabel>Phone no.</FormLabel>
                    <Form.Control
                      type="text"
                      name="phoneNo"
                      disabled={true}
                      defaultValue={user.user.phoneNo}
                      readOnly
                    />
                  </Form.Group>
                </Col>
              </Row>
              {role === 3 && (
                <Row>
                  <Col lg={12}>
                    <Form.Group className="cs-form-group cs-disabled">
                      <FormLabel>Name of Company</FormLabel>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        placeholder="Company Name"
                        onKeyDown={(event) => {
                          if (event.code === "Space") event.preventDefault();
                        }}
                        name="businessName"
                        disabled={true}
                        defaultValue={user.user.businessName}
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              {role == 2 && (
                <>
                  <Row>
                    <Col lg={6} md={6} sm={6}>
                      <Form.Group className="cs-form-group cs-disabled account-form-field">
                        <FormLabel>License number</FormLabel>
                        <Form.Control
                          type="text"
                          placeholder="License number"
                          autoComplete="off"
                          name="licenceId"
                          disabled={true}
                          defaultValue={user.user.licenceId}
                          className={
                            user.user.licenceId && "cs-input-field-active"
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <Form.Group className="cs-form-group account-form-field cs-disabled">
                        <FormLabel>Company name</FormLabel>
                        <Form.Control
                          type="text"
                          placeholder="Company name"
                          autoComplete="off"
                          disabled={true}
                          name="companyName"
                          defaultValue={user && user.user.companyName}
                          className={
                            user.user.companyName && "cs-input-field-active"
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} sm={6}>
                      <Form.Group className="cs-form-group cs-disabled">
                        <FormLabel>Company license number</FormLabel>

                        <Form.Control
                          type="text"
                          placeholder="Company license number"
                          autoComplete="off"
                          name="companyLicenceId"
                          disabled={true}
                          defaultValue={user && user.user.companyLicenceId}
                          className={
                            user.user.companyLicenceId &&
                            "cs-input-field-active"
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              )}
              <AddressSearch
                errors={errors}
                touched={touched}
                values={values}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                setAddressObj={setAddressObj}
                setStatus={setStatus}
                status={status}
                addressObj={addressObj}
                setIsAddressChange={setIsAddressChange}
                setFieldTouched={setFieldTouched}
                licenceAddress={autocompletePredictions}
              />

              <Row>
                <Col lg={12}>
                  <div className="profile-footer cs-center-btn cs-modal-btn">
                    <Button
                      className="cs-btn-primary md-btn default-regular-h5"
                      type="submit"
                      disabled={isLoading}
                    >
                      Continue
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default CompleteProfile;
