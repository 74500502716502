import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { localDataRemove } from "../config/finance";
import { headerAddToken } from "../util/common";
import API from "../util/api";

export const selectColumn = createAsyncThunk(
  "/columnfilter/select",
  async (data, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post("/columnfilter/select", data, header);
      // thunkAPI.dispatch(setMessage(response));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const columnFindRecord = createAsyncThunk(
  "/columnfilter/find/",
  async (values, thunkAPI) => {
    const { id, categoryNames, category_type } = values;
    try {
      let header = await headerAddToken();
      const response = await API.get(
        `/columnfilter/find/${id}/${categoryNames}/${category_type}`,
        header
      );
      // thunkAPI.dispatch(setMessage(response));
      // console.log(response)
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const columnFindRecordPortfolio = createAsyncThunk(
  "/columnfilter/find/",
  async (values, thunkAPI) => {

    try {
      let header = await headerAddToken();
      const response = await API.post("/columnfilter/find/portfolio/columns", values, header)
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const columnList = createAsyncThunk(
  "/columnList",
  async ({ id, categoryNames }, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.get(
        `/columnfilter/find/${id}/${categoryNames}`,
        header
      );
      thunkAPI.dispatch(setMessage(response));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);


const initialState = {};

const columnFilterSlice = createSlice({
  name: "columnFilter",
  initialState,
});

export default columnFilterSlice;
