import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import requestsService from "../services/requests.service";
import { localDataRemove } from "../config/finance";
import { useSelector } from "react-redux";
import { roleName } from "../config/commonRoleName";

export const requestsAdd = createAsyncThunk(
  "/requests/add",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.requests_add(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

//new
export const Signature_Requests_Add = createAsyncThunk(
  "/requests/sign_add",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.signature_Requests_add(values);
      thunkAPI.dispatch(setMessage(response.data.data));

      return response.data;
    } catch (error) {
      return error.response;
    }
  }
);
//old
export const SignatureRequestsAdd = createAsyncThunk(
  "/requests/Signadd",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.signatureRequests_add(values);
      thunkAPI.dispatch(setMessage(response.data.data));

      return response.data;
    } catch (error) {
      return error.response;

      // if (error.response.data.code === 401) {
      //   window.location.href = "/";
      localDataRemove();
      // } else {
      //   const message = error.response && error.response.data.message;
      //   thunkAPI.dispatch(setMessage(message));
      //   return thunkAPI.rejectWithValue();
      // }
    }
  }
);

export const secondUserSign = createAsyncThunk(
  "requests/secondUserSign",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.updateSecondUserSign(values);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const acceptSignatureSingleRequest = createAsyncThunk(
  "requests/updateSingleSignSecond",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.updateSingleSignSecond(values);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const updateSingleSign = createAsyncThunk(
  "requests/updateSingleSign",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.signatureupdateSingleSign(values);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const userRequestList = createAsyncThunk(
  "requests/allSignatureRequests",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.getAllDataFromRequest(values);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const UpdateSignature = createAsyncThunk(
  "requests/SignUpdate",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.signatureRequests_update(values);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const UpdateSecondUserSignature = createAsyncThunk(
  "requests/SecondSignUpdate",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.second_signatureRequests_update(
        values
      );
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const acceptSignatureReq = createAsyncThunk(
  "requests/acceptSignatureSignature",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.acceptSignatureReq_update(values);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const UpdateSignatureSigned = createAsyncThunk(
  "requests/SignUpdateSIgned",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.signatureRequests_update_signed(
        values
      );
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const requestsAcceptDeny = createAsyncThunk(
  "/requests/accept_deny",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.requests_AcceptDeny(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      // if (error.response.data.code === 401) {
      //   window.location.href = "/";
      localDataRemove();
      // } else {
      const message = error.response && error.response.data.message;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
      // }
    }
  }
);
export const requestDecline = createAsyncThunk(
  "/requests/approveDecline_InModal",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.approveDecline_InModal(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const requestsFetchAll = createAsyncThunk(
  "/requests/list",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.requests_list(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const requestFetchAll = createAsyncThunk(
  "/requests/request_list",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.request_list(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const SignaturerequestFetchAll = createAsyncThunk(
  "/requests/signature_request_list",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.signature_request_list(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const notificationOpen = createAsyncThunk(
  "/requests/notification_open",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.notification_open(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const SecondSignaturerequestFetchAll = createAsyncThunk(
  "/requests/SecondSignaturerequestFetchAll",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.Second_signature_request_list(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const INDISignaturerequestFetchAll = createAsyncThunk(
  "/requests/signature_request_Indi_list",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.signature_request_Indi_list(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const requestsFetchUsers = createAsyncThunk(
  "/requests/fetch_user",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.requests_user_details(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

const user = JSON.parse(localStorage.getItem("user"));
export const requestsNotifications = createAsyncThunk(
  "/requests/notifications",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.requests_user_notifications(
        values
      );
      let filteredNotification = [];
      if (response && response.data && response.data.length > 0) {
        const roles =
          user.user_role.role == 2
            ? roleName.financial_advisor
            : user.user_role.role == 3
              ? roleName.tax_professional
              : roleName.individual;
        const loginUserId = user.user.id;
        filteredNotification = response.data.filter((notification) => {
          return (
            notification.notification_send_to_role == roles &&
            notification.notification_send_to == loginUserId
          );
        });
      }

      return filteredNotification;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const requestsNotifications_read = createAsyncThunk(
  "/requests/markread",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.requests_user_notifications_read(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const requestDelete = createAsyncThunk(
  "/requests/delete",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.requests_delete(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const requestFetchId = createAsyncThunk(
  "/requests/fetchId",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.requests_user_fetchId(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const requestFromNotificaitonsFetchAll = createAsyncThunk(
  "/requests/allrequests",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.requests_fetch_all(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const FindSignatureRequest = createAsyncThunk(
  "/requests/FindSignatureRequests",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.find_Signature_requests(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const CancelSignatureRequest = createAsyncThunk(
  "/requests/cancel_signature_request",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.cancel_signature_requests(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const SignaturerequestFromNotificaitonsFetchAllIndi = createAsyncThunk(
  "/requests/allSignatureRequestsIndi",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.Signature_requests_fetch_all_indi(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const requestFetchCount = createAsyncThunk(
  "/requests/countrequests",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.requests_count_all(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const requestEmailLink = createAsyncThunk(
  "/requests/fetchlinkemail",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.requests_fetch_emailLink(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const requestDecryptShareLink = createAsyncThunk(
  "/requests/decryptLink",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.requests_decryptShareLink(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const requestSendLink = createAsyncThunk(
  "/requests/sendlink",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.requests_sendlink(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wm_to_ind_resend_email = createAsyncThunk(
  "/requests/wm_to_ind_resend_email",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.wm_to_ind_resend_email(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const manage_user_wealth = createAsyncThunk(
  "/requests/manage_user_wealth",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.manage_user_wealth(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const read_single_notification = createAsyncThunk(
  "/requests/read_single_notification",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.read_single_notification(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const requestBulkAdd = createAsyncThunk(
  "requests/bulk_add",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.request_bulk_add(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const get_access_from_indi = createAsyncThunk(
  "/requests/manage_user_wealth",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.get_access_from_indi(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const get_permission_status = createAsyncThunk(
  "/requests/manage_user_wealth",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.get_permission_status(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const get_all_standard_details = createAsyncThunk(
  "/requests/manage_user_wealth",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.get_all_standard_details(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const edit_permitted_user = createAsyncThunk(
  "/requests/manage_user_wealth",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.edit_permitted_user(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const all_users_access_status = createAsyncThunk(
  "/requests/manage_user_wealth",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.all_users_access_status(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const cancelled_by_main_user = createAsyncThunk(
  "/requests/manage_user_wealth",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.cancelled_by_main_user(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const get_cancelled_users_list = createAsyncThunk(
  "/requests/manage_user_wealth",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.get_cancelled_users_list(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const decline_by_main_user = createAsyncThunk(
  "/requests/manage_user_wealth",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.decline_by_main_user(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const change_access_back_to_indi = createAsyncThunk(
  "/requests/manage_user_wealth",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.change_access_back_to_indi(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const user_access_permission = createAsyncThunk(
  "/requests/user_access_permission",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.user_access_permission(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const check_edit_permission = createAsyncThunk(
  "/requests/manage_user_wealth",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.check_edit_permission(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const add_advisors = createAsyncThunk(
  "/requests/manage_user_wealth",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.add_advisors(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const get_all_details = createAsyncThunk(
  "/requests/manage_user_wealth",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.get_all_details(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const change_edit_permission = createAsyncThunk(
  "/requests/manage_user_wealth",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.change_edit_permission(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const remove_link_from_verify_table = createAsyncThunk(
  "/requests/remove_link_from_verify_table",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.remove_link_from_verify_table(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const fetch_all_external_attachments = createAsyncThunk(
  "/requests/fetch_all_external_attachments",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.fetch_all_external_attachments(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const other_attachements_remove = createAsyncThunk(
  "/requests/other_attachements_remove",
  async (values, thunkAPI) => {
    try {
      const response = await requestsService.other_attachements_remove(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

const initialState = {
  notification: [],
  accountAccess: {},
  signatureRequest: {},
  accessPermissionViewOnly: null
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(requestsNotifications.fulfilled, (state, action) => {
      state.notification = action.payload;
    });
    builder.addCase(requestFetchAll.fulfilled, (state, action) => {
      state.accountAccess = action.payload;
    });
    builder.addCase(userRequestList.fulfilled, (state, action) => {
      state.signatureRequest = action.payload;
    });
    builder.addCase(user_access_permission.fulfilled, (state, action) => {
      if (action.payload.access_status == 1) {
        state.accessPermissionViewOnly = true;
      } else {
        state.accessPermissionViewOnly = false;
      }
    });
  },
});

const { reducer } = notificationSlice;

export default reducer;
