import React, { useState, useEffect } from "react";
import { Form, Container, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { wmAuditLogs } from "../../../../slices/auditLogs";
import moment from "moment";
import DataTable from "react-data-table-component";
import CommonSettingHeader from "../CommonHeader/CommonSettingHeader";
import "../../../individual/settings/Audit_logs/audit_logs.css";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import CapsyncSectionHeader from "../../../../commonComponent/CapsyncSectionHeader";
import CommonSettingDropdown from "../CommonHeader/CommonSettingDropdown";
import Skeleton from "react-loading-skeleton";
import SkeletonAuditlog from "../../../individual/settings/Audit_logs/SkeletonAuditlog";
import Pagination from "../../../../commonComponent/Pagination";
import { customStyles } from "../../../../util/common";

const WmAuditLogs = (props) => {
  const [search, setSearch] = useState([]);
  const [record, setRecord] = useState([]);
  const [tempRecord, setTempRecord] = useState([]);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
    const role = user && user.user_role && user.user_role.role;
  const id = user && user.user.id;
  const recordsFromReducer = useSelector((state) => state.auditLogs.auditLogs);

  const [rowStartIdx, setRowStartIdx] = useState(0);
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownItems[0]);

  useEffect(() => {
    dispatch(wmAuditLogs({ wm_user_id: id }));
  }, []);

  useEffect(() => {
    setRecord(recordsFromReducer);
    setTempRecord(recordsFromReducer);
  }, [recordsFromReducer]);

  const columns = [
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("MM/DD/YYYY"),
      sortable: true,
      sortField: 'createdAt',
    },
    {
      name: "Time",
      selector: (row) => moment(row.createdAt).format("HH:mm"),
      sortable: false,
    },
    {
      name: "User Name",
      selector: (row) => row.firstName + " " + row.lastName,
      sortable: false,
    },
    {
      name: "Categories",
      selector: (row) => row.module_name,
      sortable: false,
    },
    {
      name: "Sub Category",
      selector: (row) => row.sub_module_name,
      sortable: false,
    },
    {
      name: "Event",
      selector: (row) => row.title,
      sortable: false,
    },
  ];

  useEffect(() => {
    if (tempRecord && typeof search === "string") {
      const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // Escape special characters
      const searchRegex = new RegExp(escapedSearch, "i"); // "i" for case-insensitive search

      const result = tempRecord.filter((rowData) => {
        return (
          rowData.sub_module_name.toLowerCase().match(searchRegex) ||
          rowData.firstName.toLowerCase().match(searchRegex) ||
          rowData.module_name.toLowerCase().match(searchRegex) ||
          rowData.lastName.toLowerCase().match(searchRegex) ||
          moment(rowData.createdAt).format("HH:mm").match(searchRegex) ||
          moment(rowData.createdAt).format("MM/DD/YYYY").match(searchRegex) ||
          rowData.title.toLowerCase().match(searchRegex)
        );
      });

      setRecord(result);
    } else {
      setRecord(record);
    }
  }, [search, tempRecord]);

  const handleSort = async (column, sortDirection) => {
    const sortedRecord = [...record].sort((a, b) => {
      const aDate = new Date(a.createdAt);
      const bDate = new Date(b.createdAt);

      if (sortDirection === 'asc') {
        return aDate - bDate;
      } else {
        return bDate - aDate;
      }
    });
    setRecord(sortedRecord);
  };
  return (
    <>
      <section className="settings-main-section financial-audit-log">
        <Container fluid>
          {/* <div className="cs-section-topbar">
            <CapsyncSectionHeader
              mainTitle="Dashboard"
              mainUrl="/individual"
              pagetitle={"Settings"}
              subTitle="Settings"
            />
          </div> */}

          {props.isLoader === true ? (
            <div className="cs-section-topbar">
              <div className="skeleton-breadcrumb">
                <div className="skeleton-breadcrumb-1">
                  {" "}
                  <Skeleton width={50} />
                </div>
                <div className="skeleton-breadcrumb-2">
                  {" "}
                  <Skeleton width={20} />
                </div>
                <div className="skeleton-breadcrumb-3">
                  <Skeleton width={50} />
                </div>
              </div>
              <div className="cs-title">
                <h1 className="default-semi-bold-h1 cs-neutral-100">
                  <Skeleton width={200} />
                </h1>
              </div>
            </div>
          ) : (
            <div className="cs-section-topbar">
              <CapsyncSectionHeader
                mainTitle="Dashboard"
                mainUrl={` ${
                  role == 2 ? "/financial-advisor" : "/professional"
                }`}
                pagetitle={"Settings"}
                subTitle="Settings"
              />
            </div>
          )}

          {props.isLoader === true ? (
            <div className="setting-mobile-dropdown for-mob">
              <Skeleton width="100" height={30} />
            </div>
          ) : (
            <CommonSettingDropdown />
          )}

          <Card className="audit-log-card">
            <div className="settings-body">
              {props.isLoader === true ? (
                <div className="for-des">
                  {" "}
                  <div className="skeleton-account-tabs">
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={100} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={100} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={80} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={150} />
                    </p>
                  </div>
                </div>
              ) : (
                <CommonSettingHeader />
              )}
              <div className="audit-logs-page">
                {props.isLoader === true ? (
                  <SkeletonAuditlog />
                ) : (
                  <>
                    <div className="cs-tabs-flex">
                      <div className="cs-tabs"></div>
                      <div className="cs-search-bar">
                        <Form.Group className="cs-form-group">
                          <Form.Control
                            type="text"
                            placeholder="Search..."
                            value={search}
                            onChange={(event) => setSearch(event.target.value)}
                            // className={search && "cs-input-field-active"}
                          />
                          <span className="input-field-icon cs-neutral-70">
                            <CapsyncIcon title="search-outlined" size="18" />
                          </span>
                        </Form.Group>
                      </div>
                    </div>

                    <div className="cs-data-table">
                      <DataTable
                        columns={columns}
                        data={
                          record
                            ? record.slice(
                                rowStartIdx,
                                rowStartIdx + rowsPerPage
                              )
                            : []
                        }
                        sortIcon={
                          <>
                            <span className="cs-neutral-100">
                              <CapsyncIcon title="sort-outlined" size="22" />
                            </span>
                          </>
                        }
                        onSort={handleSort}
                        sortServer
                        customStyles={customStyles}
                      />
                      <Pagination
                        rowStartIdx={rowStartIdx}
                        setRowStartIdx={setRowStartIdx}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        paginationDropdownItems={paginationDropdownItems}
                        paginationList={record ? record : []}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </Card>
        </Container>
      </section>
    </>
  );
};

export default WmAuditLogs;
