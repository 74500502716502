import React from 'react'
import { Modal, Button, Image } from 'react-bootstrap'
import ErrorValidation from "../../../assets/images/other/error-validation.svg";

const Unlink = ({ modalShow, modalFunc, removeLink, tableHeader }) => {

    return (
        <Modal
            show={modalShow}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="cs-md-modal"
        >
            <Modal.Header>
                <Modal.Title className="default-semi-bold-h3">Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className="delete-modal-content">
                    <Image
                        src={ErrorValidation}
                        alt="Error Validation"
                        width={100}
                        height={100}
                    />
                    <p className="default-light-body-text-m cs-neutral-90">
                        {`You are unlinking this liability from the ${tableHeader}, do you really
                        want to unlink?`}
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="cs-modal-btn cs-center-btn">
                    <Button
                        className="cs-btn-secondary md-btn default-regular-h5"
                        onClick={() => modalFunc(null, false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="cs-btn-primary md-btn default-regular-h5"
                        onClick={removeLink}
                    >
                        Yes, Unlink
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default Unlink