/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CardComponent from "./CardComponent";
import CapsyncSectionHeader from "../../../commonComponent/CapsyncSectionHeader";

import IndividualTable from "../IndividualTable";
import CompanyTable from "../CompanyTable";
import {
  recently_viewed,
  add_recently,
  company_recently,
} from "../../../slices/pfs_access";
import { add_advisors, user_access_permission } from "../../../slices/requests";
import SkeletonDashboard from "./SkeletonDashboard";
import { access_invitation_Pfs } from "../../../slices/pfs_access";
import { socket } from "../../../config/Socket";
import { roleName } from "../../../config/commonRoleName";
import { formatFullName } from "../../../config/finance";

const Dashboard = ({ CapsyncMessage, toastMessage, accessPFS, isLoader }) => {
  const { user } = useSelector((state) => state.auth);
  const role = user.user_role.role;

  const dispatch = useDispatch();
  const radios = [
    { name: "Individuals", value: "1" },
    { name: "Companies", value: "2" },
  ];
  const user_id = user && user.user.id;
  const loginCount = user && user.user.userLoginCount;
  const user_displayName =
    user && formatFullName(user.user.firstName, user.user.lastName);
  const displayName =
    `Welcome${loginCount > 1 ? " Back," : ","}` + " " + user_displayName + "!";
  const [radioValue, setRadioValue] = useState("1");
  const [recentlyViewedCompanies, setRecentlyViewedCompanies] = useState([]);
  const [recentlyViewedIndividual, setRecentlyViewedIndividual] = useState([]);

  useEffect(() => {
    fetchRecentlyViewedIndividuals();
    fetchRecentlyViewedCompanies();
    getIndividuals();

    socket.on("get_updated_list", () => {
      fetchRecentlyViewedIndividuals();
      fetchRecentlyViewedCompanies();
    });
    return () => {
      socket.off("get_updated_list", () => {
        fetchRecentlyViewedIndividuals();
        fetchRecentlyViewedCompanies();
      });
    };
  }, []);

  const fetchRecentlyViewedIndividuals = () => {
    dispatch(
      recently_viewed({
        wm_id: user && user.user.id,
        module: roleName.individual,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.data) {
          setRecentlyViewedIndividual(response.data);
        } else {
          setRecentlyViewedIndividual("");
        }
      })
      .catch((err) => {
        toastMessage("recentlyViewed :" + err.message, "error");
      });
  };

  const getIndividuals = () => {
    dispatch(
      access_invitation_Pfs({
        email: user && user.user.email,
      })
    )
      .unwrap()
      .then((response) => {})
      .catch((err) => {
        toastMessage("access_invitation_Pfs :" + err.message, "error");
      });
  };

  const fetchRecentlyViewedCompanies = () => {
    dispatch(
      company_recently({ wm_id: user && user.user.id, module: "company" })
    )
      .unwrap()
      .then((response) => {
        setRecentlyViewedCompanies(response ? response : []);
      })
      .catch((err) => {
        toastMessage(err.message, "error");
      });
  };

  const accessIndividualPFS = async (params) => {
    try {
      await dispatch(
        add_recently({
          recent_ids: Number(params),
          wm_id: Number(window.localStorage.getItem("id")),
          module: roleName.individual,
        })
      ).unwrap();

      const response = await dispatch(
        user_access_permission({
          user_id: Number(params),
          advisor_id: Number(window.localStorage.getItem("id")),
          user_role:
            JSON.parse(window.localStorage.getItem("loginStatus")).user_role ===
            1
              ? roleName.individual
              : JSON.parse(window.localStorage.getItem("loginStatus"))
                  .user_role === 2
              ? roleName.financial_advisor
              : roleName.tax_professional,
        })
      ).unwrap();

      if (response && response.access_status === 2) {
        const res = await dispatch(
          add_advisors({
            advisor_user_id: Number(window.localStorage.getItem("id")),
            user_id: Number(params),
            advisor_user_role: role,
          })
        ).unwrap();

        if (res) {
          socket.emit("broadcast_message", { user_id: Number(params) });
          accessPFS(params, Number(window.localStorage.getItem("id")));
        }
      } else {
        accessPFS(params, Number(window.localStorage.getItem("id")));
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const handleUserChange = (event) => {
    setRadioValue(event.currentTarget.value);
  };

  return (
    <section className="dashboard-main-section">
      <Container fluid>
        {isLoader === true ||
        (user.user.is_subscribe === false &&
          user.user.is_beta_user === false) ? (
          <SkeletonDashboard />
        ) : (
          <>
            <div className="cs-section-topbar">
              <CapsyncSectionHeader pagetitle={displayName} />
            </div>

            <CardComponent toastMessage={toastMessage} />

            <div className="wm-tab-content">
              <div className="cs-tabs cs-primary">
                {radios.map((radio, idx) => (
                  <Button
                    className={`cs-stroke-default-tab cs-tabs-height default-regular-h5 ${
                      radioValue === radio.value ? "cs-stroke-active" : ""
                    }`}
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant={
                      idx % 2
                        ? "manage-user-individual"
                        : "manage-user-wealth-manager"
                    }
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.value}
                    onClick={(e) => handleUserChange(e)}>
                    {radio.name}
                  </Button>
                ))}
              </div>
              <h6 className="default-regular-h6 cs-neutral-70">
                * Recently viewed
              </h6>
            </div>
            {radioValue === "1" ? (
              <IndividualTable
                data={recentlyViewedIndividual}
                accessIndividualPFS={accessIndividualPFS}
                CapsyncMessage={CapsyncMessage}
                toastMessage={toastMessage}
              />
            ) : (
              <CompanyTable
                forDashboard={true}
                data={recentlyViewedCompanies}
                toastMessage={toastMessage}
                accessPFS={accessPFS}
                rowStartIdx={0}
                rowsPerPage={5}
              />
            )}
          </>
        )}
      </Container>
    </section>
  );
};

export default Dashboard;
