import React from "react";
import { Button, Image, Modal } from "react-bootstrap";

// COMPONENT IMPORTS
import ErrorValidation from "../../../../assets/images/other/error-validation.svg";

const MoveAlertModal = ({
  openDuplicateModalOpen,
  closeModal,
  existingMoveFiles,
}) => {
  return (
    <Modal
      show={openDuplicateModalOpen}
      onHide={closeModal}
      centered
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="cs-md-modal payment-success"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          {existingMoveFiles.length > 1 ? "Files " : "File "}name exist
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="success-head">
          <span>
            <Image
              src={ErrorValidation}
              alt="Error Validation"
              width={100}
              height={100}
            />
          </span>
          <p className="default-light-body-text-m cs-neutral-90">
            {existingMoveFiles.length > 1
              ? "Some of the items"
              : existingMoveFiles[0].name}{" "}
            already exist in this location.
          </p>
          <p className="default-light-body-text-m cs-neutral-90">
            please change the{" "}
            {existingMoveFiles.length > 1 ? "Files " : "File "}name before
            moving into this folder.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="cs-btn-primary lg-btn default-regular-h5"
          onClick={closeModal}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MoveAlertModal;
