import React, { useState, useRef, useEffect } from "react";
import { Button, Col, Row, Form, Modal } from "react-bootstrap";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import { useDispatch } from "react-redux";
import { upload_required_document } from "../../../../slices/verifyInvestor";
import HelloSignProgress from "../../../../commonComponent/component/HelloSignProgress";
import AttachedDocument from "./Modals/AttachedDocument";
import { toast } from "react-toastify";

const UploadDocumentVI = (props) => {
  const { showUploadDocument, setShowUploadDocument } = props;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadList, setUploadList] = useState([]);
  const [notes, setNotes] = useState(" ");
  const [showLoader, setShowLoader] = useState(false);
  const [showAttachedDocument, setShowAttachedDocument] = useState(false);
  const [selectedAttachments, setSelectedAttachments] = useState([]);
  const [attachmentsDocuments, setAttachmentsDocuments] = useState([]);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [showDuplicateError, setShowDuplicateError] = useState(false);
  const [showMaxfileSizeError, setShowMaxfileSizeError] = useState(false);
  const [checkPfsStatus, setCheckPfsStatus] = useState(false);
  const [checkExtensions, setCheckExtensions] = useState(false);
  const fileInputRef = useRef(null);
  const fileTitle = selectedFiles.length
    ? selectedFiles.length === 1
      ? selectedFiles[0].name
      : `${selectedFiles.length} files `
    : "Choose file";

  const dispatch = useDispatch();

  const handleCancel = (e) => {
    e.preventDefault();
    setShowUploadDocument(false);
    setUploadList([]);
    setNotes(" ");
    setSelectedFiles([]);
    setAttachmentsDocuments([]);
    setSelectedAttachments([]);
    setShowDuplicateError(false);
    setShowMaxfileSizeError(false);
    setCheckExtensions(false);
  };

  const validateDuplicateFileName = (newFiles, existingFiles) => {
    const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes
    const supportedExtensions = [".pdf", ".png", ".jpg", ".jpeg"];
    for (const newFile of newFiles) {
      for (const existingFile of existingFiles) {
        if (
          (newFile.name && newFile.name === existingFile.name) ||
          (newFile.name && newFile.name === existingFile.original_file_name) ||
          newFile === existingFile.original_file_name
        ) {
          setShowDuplicateError(true);
          setShowMaxfileSizeError(false);
          setCheckExtensions(false);
          return false;
        }
      }
      // Check for maximum file size
      if (newFile.size && newFile.size > maxFileSize) {
        setShowMaxfileSizeError(true);
        setShowDuplicateError(false);
        setCheckExtensions(false);
        return false;
      }
      //check file extension
      const fileExtension = newFile.name.slice(
        ((newFile.name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      const notAllowedExtensionHandling =
        fileExtension === "" ||
        fileExtension === null ||
        fileExtension === undefined;
      if (
        notAllowedExtensionHandling ||
        !supportedExtensions.includes(`.${fileExtension.toLowerCase()}`)
      ) {
        setCheckExtensions(true);
        setShowMaxfileSizeError(false);
        setShowDuplicateError(false);
        return false;
      }
    }

    return true;
  };
  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    if (validateDuplicateFileName(newFiles, uploadList)) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setIsFileSelected(true);
      setShowMaxfileSizeError(false);
      setShowDuplicateError(false);
      setCheckExtensions(false);
    }

    setIsFileSelected(true);
    setSelectedFiles(newFiles);
  };

  const handleDelete = (index) => {
    const newFiles = [...uploadList];
    newFiles.splice(index, 1);
    setSelectedFiles(newFiles);
    setUploadList(newFiles);
    setCheckExtensions(false);
    setShowMaxfileSizeError(false);
    setShowDuplicateError(false);
  };

  const handleUpload = () => {
    const uniqueSelectedFiles = selectedFiles.filter(
      (file, index, self) =>
        index === self.findIndex((f) => f.name === file.name)
    );

    setUploadList((prevList) => {
      const newUploadList = prevList.filter(
        (uploadFile) =>
          !uniqueSelectedFiles.some(
            (uniqueFile) => uniqueFile.name === uploadFile.name
          )
      );
      return [...newUploadList, ...uniqueSelectedFiles];
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      setSelectedFiles([]);
    }

    setIsFileSelected(false);
  };

  useEffect(() => {
    if (showUploadDocument === false) {
      setIsFileSelected(false);
      setAttachmentsDocuments([]);
    }
  }, [showUploadDocument]);

  const sendData = (selected_files, external_files, notes) => {
    const formdata = new FormData();
    formdata.append("user_id", props.user_id);
    let all_details = {
      unique_code: props.uniqueRowId,
      user_id: props.user_id,
      note: notes,
    };

    if (external_files.length > 0) {
      for (let i = 0; i < external_files.length; i++) {
        formdata.append("attachments", external_files[i]);
      }
    }
    if (selected_files.length >= 1) {
      formdata.append("attached_documents", JSON.stringify(selected_files));
    }
    formdata.append("all_details", JSON.stringify(all_details));
    dispatch(upload_required_document(formdata))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setSelectedFiles([]);
          setNotes("");
          setUploadList([]);
          setAttachmentsDocuments([]);
          setSelectedAttachments([]);
          toast.success(response.message);
          setShowLoader(false);
          props.verify_investor_pendingData();
        }
        if (response.code === 500) {
          toast.error(response.message);
          setShowLoader(false);
          setSelectedFiles([]);
          setNotes("");
          setUploadList([]);
          setAttachmentsDocuments([]);
          setSelectedAttachments([]);
        }
      })
      .catch((error) => {
        console.log("error:- ", error);
        setShowLoader(false);
      });
  };

  const handleSubmit = () => {
    if (uploadList.length > 0 || attachmentsDocuments.length > 0) {
      setShowLoader(true);
      // dispatch(submitToDatabase(selectedFiles, notes));
      sendData(attachmentsDocuments, uploadList, notes);
      setShowUploadDocument(false);
    } else {
      toast.error("Please select files to submit to the database.");
    }
  };

  const handleAttachmentSubmit = () => {
    setAttachmentsDocuments(selectedAttachments);
    setShowAttachedDocument(false);
  };

  const handleShowAttachedDocument = () => {
    setShowAttachedDocument(true);
  };

  return (
    <>
      <Modal
        show={showUploadDocument}
        onHide={() => !showUploadDocument}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
        className="accredited-upload-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            Upload Document
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="accredited-content-box">
            <Row>
              <Col lg={12} className="row-spacing">
                <p className="default-medium-sub-heading-xs cs-neutral-100 ">
                  You need to upload a Bank statement, Assets report, Tax
                  documents to continue verification.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div
                  onClick={handleShowAttachedDocument}
                  className="cs-attached-doc"
                >
                  <span className="cs-neutral-60">
                    <CapsyncIcon title="file-icon-outlined" size="22" />
                  </span>
                  <h5 className="default-regular-sub-heading-xs cs-neutral-100 cs-attached-title">
                    Select Attached documents
                    <span className="cs-neutral-60">
                      <CapsyncIcon title="chevron-right-outlined" size="22" />
                    </span>
                  </h5>
                </div>
                {attachmentsDocuments.length > 0 && (
                  <p className="default-regular-body-text-s cs-primary">
                    {" "}
                    {attachmentsDocuments.length} Attachments are selected
                  </p>
                )}
                <div className="cs-divider cs-select-divider">
                  <span className="default-regular-body-text-s cs-neutral-50">
                    OR
                  </span>
                </div>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col lg={12}>
                  <div className="cs-upload-box">
                    <div className="cs-upload-files-box">
                      <Form.Group className="cs-form-group">
                        <div className="cs-choose-file">
                          <div className="cs-choose-file-box" title={fileTitle}>
                            <Form.Control
                              type="file"
                              multiple
                              onChange={handleFileChange}
                              placeholder="Choose file"
                              ref={fileInputRef}
                              className={
                                isFileSelected && "cs-input-field-active"
                              }
                            />
                            <span className="cs-icon cs-neutral-10">
                              <CapsyncIcon title="upload-outlined" size="18" />
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                      <Button
                        className="cs-btn-primary sm-btn default-regular-h5"
                        onClick={handleUpload}
                        disabled={
                          !isFileSelected ||
                          showDuplicateError ||
                          showMaxfileSizeError ||
                          checkExtensions
                        }
                      >
                        Upload
                      </Button>
                    </div>
                    {showDuplicateError && (
                      <span className="default-regular-sub-heading-xxs cs-danger form-error-msg">
                        A file with this name already exists
                      </span>
                    )}
                    {showMaxfileSizeError && (
                      <span className="default-regular-sub-heading-xxs cs-danger form-error-msg">
                        This file exceeds maximum file size limit (10MB)
                      </span>
                    )}
                    {checkExtensions && (
                      <span className="default-regular-sub-heading-xxs cs-danger form-error-msg">
                        {" "}
                        Format not supported
                      </span>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="attachment-files cs-files">
                    {uploadList.map((file, index) => (
                      <div className="cs-selected-files" key={index}>
                        <div className="cs-fileicon cs-neutral-100">
                          <span className="cs-icon cs-neutral-10">
                            <CapsyncIcon title="file-icon-outlined" size="16" />
                          </span>
                        </div>
                        <div className="cs-files-names">
                          <span className="download-filename default-regular-sub-heading-xs cs-neutral-100">
                            {file.name}{" "}
                          </span>
                        </div>
                        <div
                          className="cs-file-icon icons"
                          onClick={() => handleDelete(index)}
                        >
                          <span className="cs-icon-badge-danger cs-danger cursor-pointer">
                            <CapsyncIcon title="delete-outlined" size="18" />
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row>
                <div>
                  <Form.Group className="cs-form-group">
                    <Form.Label>Notes</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Optional"
                      className={
                        notes.trim().length > 0
                          ? "cs-input-field-active cs-textarea"
                          : "cs-textarea"
                      }
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </Row>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="cs-modal-btn">
          <Button
            onClick={handleCancel}
            className="cs-btn-secondary md-btn default-regular-h6"
          >
            Close
          </Button>
          <Button
            className="cs-btn-primary md-btn default-regular-h6"
            type="submit"
            onClick={handleSubmit}
            disabled={
              uploadList.length === 0 && selectedAttachments.length === 0
            }
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      {showLoader && <HelloSignProgress />}

      <AttachedDocument
        showAttachedDocument={showAttachedDocument}
        setShowAttachedDocument={setShowAttachedDocument}
        setSelectedAttachments={setSelectedAttachments}
        selectedAttachments={selectedAttachments}
        setAttachmentsDocuments={setAttachmentsDocuments}
        attachmentsDocuments={attachmentsDocuments}
        handleAttachmentSubmit={handleAttachmentSubmit}
        setShowDuplicateError={setShowDuplicateError}
        setShowMaxfileSizeError={setShowMaxfileSizeError}
        setCheckExtensions={setCheckExtensions}
        setCheckPfsStatus={setCheckPfsStatus}
      />
    </>
  );
};

export default UploadDocumentVI;
