import React from "react";

// COMPONENT IMPORTS
import CapsyncIcon from "./CapsyncIcon";

const FileManagementBreadcrumb = ({ mainLevelArr, onClick }) => {
  return (
    <React.Fragment>
      {mainLevelArr.slice(0, 3).map((items, idx, array) => {
        if (array.length - 1 === idx) {
          return;
        }
        return (
          <React.Fragment key={idx}>
            <span
              onClick={() => onClick(items)}
              key={items}
              title={items.title}
              className="default-light-body-text-s cs-neutral-80 cursor-pointer"
            >
              {items.title}
            </span>
            {idx < 2 && (
              <span className="cs-neutral-70">
                <CapsyncIcon title="chevron-right-outlined" size="12" />
              </span>
            )}
          </React.Fragment>
        );
      })}
      {mainLevelArr.length > 4 && (
        <React.Fragment>
          <span className="default-light-body-text-s cs-neutral-80">...</span>
          <span className="cs-neutral-70">
            <CapsyncIcon title="chevron-right-outlined" size="12" />
          </span>
        </React.Fragment>
      )}
      {mainLevelArr.length > 3 && (
        <React.Fragment>
          <span
            onClick={() => onClick(mainLevelArr[mainLevelArr.length - 2])}
            className="default-light-body-text-s cs-neutral-80 cursor-pointer"
            title={mainLevelArr[mainLevelArr.length - 2].title}
          >
            {mainLevelArr[mainLevelArr.length - 2].title}
          </span>
          <span className="cs-neutral-70">
            <CapsyncIcon title="chevron-right-outlined" size="12" />
          </span>
        </React.Fragment>
      )}
      <span title={mainLevelArr[mainLevelArr.length - 1].title} className="default-light-body-text-s cs-neutral-100">
        {mainLevelArr[mainLevelArr.length - 1].title}
      </span>
    </React.Fragment>
  );
};

export default FileManagementBreadcrumb;
