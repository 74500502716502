import React from 'react'
import Skeleton from 'react-loading-skeleton'
import SkeletonCategoryTable from '../assets/asset_dashboard/SkeletonCategoryTable'

const SkeletonRequest = () => {
    return (
        <div className="cs-section-topbar">

            <div className="cs-title">

                <h1 className="default-semi-bold-h1 cs-neutral-100"><Skeleton width={200} /></h1>


            </div>
            <div className='skeleton-manage-container for-des'>
                <div className="skeleton-tabs-container">
                    <h1 className="skeleton-tab-1"><Skeleton width={200} /></h1>
                    <h1 className="skeleton-tab-2"><Skeleton width={200} /></h1>
                </div>
                {/* <div><Skeleton width={150} /></div> */}
            </div>
            <div className='skeleton-manage-container for-mob'>
                <div className="skeleton-tabs-container">
                    {Array(2).fill(0).map((item, index) => (
                        <h1 key={index} className="skeleton-tab-1"><Skeleton width={140} height={30} /></h1>
                    ))}
                </div>
            </div>

            <div className="signature-request-from-box">
                <div className="cs-requests-tables">
                    <h4 className="default-medium-sub-heading-m cs-font-color-sub-title">
                        <Skeleton width={200} />
                    </h4>
                    <SkeletonCategoryTable tableData={[1, 2]} />
                </div>
                <div className="cs-requests-tables">
                    <h4 className="default-medium-sub-heading-m cs-font-color-sub-title">
                        <Skeleton width={200} />
                    </h4>
                    <SkeletonCategoryTable tableData={[1, 2]} />
                </div>
                <div className="cs-requests-tables">
                    <h4 className="default-medium-sub-heading-m cs-font-color-sub-title">
                        <Skeleton width={200} />
                    </h4>
                    <SkeletonCategoryTable tableData={[1, 2]} />
                </div>
            </div>


        </div>
    )
}

export default SkeletonRequest