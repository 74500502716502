import React, { useState, useCallback } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  AreaChart
} from "recharts";
import CapsyncLoader from "../../commonComponent/CapsyncLoader";
import CapsyncIcon from "../../commonComponent/CapsyncIcon";
import CapsyncToolTip from "../../commonComponent/capsyncTooltip/CapsyncToolTip";

const CategoryLineChart = ({ lineData, categoryName, datas, isTransactionLoading }) => {
  const [activeLines, setActiveLines] = useState({
    Assets: true,
    Liabilities: true,
  });

  const handleToggleLine = (dataKey) => {
    setActiveLines({
      ...activeLines,
      [dataKey]: !activeLines[dataKey],
    });
  };

  const [opacity, setOpacity] = useState({
    Assets: 1,
    Liabilities: 1,
  });

  const handleMouseEnter = useCallback(
    (o) => {
      const { dataKey } = o;

      setOpacity({ ...opacity, [dataKey]: 0 });
    },
    [opacity, setOpacity]
  );

  const handleMouseLeave = useCallback(
    (o) => {
      const { dataKey } = o;
      setOpacity({ ...opacity, [dataKey]: 1 });
    },
    [opacity, setOpacity]
  );
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <>
          <div className="chart-custom-tooltip">
            <p>{`${payload[0].name} : $ ${Number(payload[0].value).toLocaleString("en-US")}`}</p>
          </div>
        </>
      );
    }
    return null;
  };

  const DataFormater = (number) => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + "B";
    } else if (number > 1000000) {
      return (number / 1000000).toString() + "M";
    } else if (number > 1000) {
      return (number / 1000).toString() + "K";
    } else {
      return number.toString();
    }
  };

  return (
    <React.Fragment>
      <div className="chart-heading">
        <h2 className="default-semi-bold-h2">Summary&nbsp;{
          <CapsyncToolTip
            Child={"info-outlined"}
            placement={window.innerWidth >= 991 ? "right" : "top"}
            message={"Only accounts with transaction data are included in the chart."}
            type="icon"
            size={20}
          />
        }</h2>
        <p className="default-regular-body-text-s cs-neutral-70 loader-text">
          {isTransactionLoading === true && <span className="cs-common-spinner cs-primary-main">
            <CapsyncIcon title="loading-outlined" size="60" />
          </span>}Last 12 months data as of last sync
        </p>
      </div>

      <div className="graph-line-chart-main-container 88">

        <div className="legend-row">
          <div className="dollar-legend">$</div>
        </div>
        <ResponsiveContainer>
          <AreaChart
            data={lineData}
            syncId="anyId"
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid horizontal={true} vertical={false} />
            <XAxis dataKey="name" tickMargin={10} height={60} />
            <YAxis tickCount={8} tickFormatter={DataFormater} type="number" />
            <defs>
              <linearGradient id="assetsGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8DC6AE" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8DC6AE" stopOpacity={0} />
              </linearGradient>
            </defs>


            <defs>
              <linearGradient id="liabilitiesGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#FAD7D7" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#FAD7D7" stopOpacity={0} />
              </linearGradient>
            </defs>

            <Tooltip
              content={<CustomTooltip />}
              wrapperStyle={{ outline: "none" }}
            />
            {/* <Legend
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              layout="horizontal"
              verticalAlign="top"
              align="right"
              iconType="circle"
              margin={{ top: 0, right: 0, left: 0, bottom: 25 }}
            /> */}
            <Area
              name={categoryName}
              type="monotone"
              dataKey={categoryName}
              stroke={categoryName === "Assets" ? "#008048" : "#E53535"}
              fill={categoryName === "Assets" ? "url(#assetsGradient)" : "url(#liabilitiesGradient)"}
              // strokeOpacity={opacity.Assets}
              fillOpacity={activeLines[categoryName] ? 0.7 : 0} // Adjust opacity for the area
            // onClick={() => handleToggleLine(categoryName)}
            />
          </AreaChart>
        </ResponsiveContainer>

      </div>
    </React.Fragment>
  );
};

export default CategoryLineChart;
