/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Row, Col, Image, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import emailIcon from "../../../../assets/icons/svg/outlined/email-outlined.svg";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useFormik } from "formik";
import * as yup from "yup";
import { userFindRecord, mfaEmailUpdateRecord } from "../../../slices/user";
import { sendVerificationEmail } from "../../../slices/auth";
import Cookies from "universal-cookie";
import { checkDomainAccept } from "../../../util/common";
import { uniValidation } from "../liabilities/categoryForms/validationSchema";

function EmailVerification(props) {
  const CapsyncMessage = props.CapsyncMessage;
  const navigate = useNavigate();

  const [record, setRecord] = useState({});
  const [showMsg, setShowMsg] = useState("");
  const [formData, setFormData] = useState(true);

  const { user } = useSelector((state) => state.auth);
  const id = user && user.user.id;

  const dispatch = useDispatch();
  const cookies = new Cookies();

  useEffect(() => {
    if (id) {
      dispatch(userFindRecord({ id }))
        .unwrap()
        .then((response) => {
          setRecord(response);
        })
        .catch(() => { });
    }
  }, [id]);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      // .matches(/^\S*$/, "Whitespace is not allowed")
      .required(uniValidation.email.required).test("is-not-only-spaces", uniValidation.email.required, (value) => {
        return value.trim() !== "";
      })
      .email(uniValidation.email.validEmail)
      .test('no-more-than-30-before-at', 'Cannot accept more than 30 characters before @', value =>
        value ? value.split('@')[0].length <= 30 : true
      )
      .test('at-least-1-character-before-at', 'Enter at least 1 character before @', value =>
        value ? value.split('@')[0].length > 0 : true
      )
      .test('custom-email-format', uniValidation.email.validEmail, value => {
        return value ? uniValidation.email.allowedFormat.test(value) : true
      })
      // .test('no-capital-letters', 'Email address should not contain capital letters', value => {
      //   return !/[A-Z]/.test(value);
      // })
      .test('domain-acceptance', uniValidation.email.validEmail, value => {
        return checkDomainAccept(value);
      }),
  });

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } = useFormik({
    initialValues: record,
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      dispatch(mfaEmailUpdateRecord(values))
        .unwrap()
        .then((res) => {
          let emailVerificationCode = res && res.emailVerificationCode;
          if (res.code === 200) {
            dispatch(sendVerificationEmail({ emailVerificationCode }))
              .unwrap()
              .then((response) => {
                if (response.code === 200) {
                  setShowMsg("");
                  setFormData(false);
                  CapsyncMessage(
                    "Success",
                    "Please check your new email and complete re-verification process.",
                    "success"
                  );
                  if (cookies.get(`deviceAuth24Hr-${id}`)) {
                    cookies.remove(`deviceAuth24Hr-${id}`);
                  }
                  setTimeout(() => {
                    localStorage.clear();
                    sessionStorage.clear();
                    navigate("/");
                  }, 2000);
                }
              })
              .catch((err) => {
                setShowMsg("");
                CapsyncMessage(
                  "Error",
                  "sendVerificationEmail :: " + err,
                  "error"
                );
              });
          } else {
            setShowMsg(res.message);
          }
        })
        .catch((err) => {
          setShowMsg("");
          CapsyncMessage(
            "Error",
            "mfaEmailUpdateRecord :: " + err.message,
            "error"
          );
        });
    },
  });

  const handleChange = ({ target }) => {
    setFieldValue(target.name, target.value)
  };

  return (
    <>
      {formData ? (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={12} sm={12}>
              <h2 className="mfa-popup-subtitle">Email verification</h2>
              <hr className="underline" />
              <small className="mfa-popup-subtitle-text">
                You can update your email address
              </small>
              <Form.Group
                className="mb-3 cap-form-group update-emailinbox-margin margin-top-15"
                controlId="validationFormik03"
              >
                <span>
                  <Image src={emailIcon} className="cap-icon" />
                </span>
                <Form.Control
                  type="email"
                  autoComplete="off"
                  name="email"
                  onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                  placeholder="Email Address"
                  defaultValue={values.email}
                  onChange={handleChange}
                // onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <p className="formik-form-error">{errors.email}</p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          {showMsg && (
            <div className="cap-error cap-messageArea">
              <BsFillInfoCircleFill className="iconAlert" />
              {showMsg}
            </div>
          )}

          <div className="ind-setting-email-mfa">
            <div>
              <Button className="cs-pri-sm-btn" type="submit">
                Save
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        <Row>
          <Col lg={12} sm={12}>
            Please check your updated email and complete verification process.
          </Col>
        </Row>
      )}
    </>
  );
}

export default EmailVerification;
