import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { localDataRemove } from "../config/finance";
import { headerAddToken } from "../util/common";
import API from "../util/api";

export const contingent_lia_addUpdate = createAsyncThunk(
  "/contingentliability/addupdate",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        `/contingentliability/addupdate`,
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const contingent_liabilities_getbalance = createAsyncThunk(
  "/contingentliability/addupdate",
  async (id, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        `/contingentliability/getbalance`,
        id,
        header
      );
      thunkAPI.dispatch(setMessage(response.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

const initialState = {};

const ContingentliabilityCategorySlice = createSlice({
  name: "ContingentiabilityCategory",
  initialState,
});

export default ContingentliabilityCategorySlice;
