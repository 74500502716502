import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LeftPart from "./LeftPart";

import Cookies from "universal-cookie";
import AuthHead from "./AuthHead";
import { sendVerificationEmail } from "../../slices/auth";
import { clearMessage } from "../../slices/message";



const OtherUser = ({ handleLogoutFunc }) => {
  const [showPwd, setShowPwd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authValidMsg, setAuthValidMsg] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [sendEmail, setSendEmail] = useState();
  const { message } = useSelector((state) => state.message);
  const { emailVerificationCode } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const cookies = new Cookies();
  const location = useLocation();

  useEffect(() => {
    dispatch(clearMessage());
    const emailVerificationCode = new URLSearchParams(location.search).get('code')
    if(emailVerificationCode) {
      resendEmail(emailVerificationCode);
      setSendEmail(false);
    }
  }, [dispatch]);

  const onHandleLearnMoreClicked = () => {
    window.location.replace('https://capsync.com');
  }

  const resendEmail = (emailVerificationCode) => {
    setLoading(true);
    dispatch(sendVerificationEmail({ emailVerificationCode }))
      .unwrap()
      .then(() => {
        navigate(`/user-verification`)
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    setSendEmail(true);
  };


    return (
      <section className="auth-section otheruser-section">
        <Container fluid>
          <Row className="align-items-center">
            <Col lg={6}>
              <LeftPart
                page="signin"
                img="auth-image.svg"
                title="Your New Immersive Digital Finance Hub."
                text="Sync your real-time digital Personal Financial Statement with CapSync."
              />
            </Col>
            <Col lg={6}>
              <Row>
                <Col lg={12}>
                  <div className="auth-right-section">
                    <AuthHead title="Thank you for joining us!" />
                    <div className="cs-auth-form">
                        <p className="default-regular-sub-heading-s cs-neutral-80">Welcome aboard! Your journey with Cpasync begins now. Get ready for a seamless financial experience. We'll be launching shortly – stay tuned for the revolution!</p>
                      <button type="submit" onClick={onHandleLearnMoreClicked} className="cs-btn-primary lg-btn default-regular-h5">Learn More</button>
                    </div>
                    
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    );
};

export default OtherUser;
