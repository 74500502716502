import moment from "moment";
import DatePicker from "react-date-picker";
import { useParams, useNavigate } from "react-router-dom";
import moment_timezone from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Dropdown, Row, Col } from "react-bootstrap";

import {
  quickBooksAccountingMethodArr,
  quickBooksAnotherPeriodFilterArr,
  quickBooksArr,
  quickBooksColumnFilterArr,
  quickBooksReportPeriodFilterArr,
} from "../../config/finance";
import CapsyncIcon from "../CapsyncIcon";
import {
  quickBooksFetchReportFilter,
  quickBooksReportFilter,
  refreshTokenPresent,
} from "../../slices/quickbooks.slice";
import { error } from "pdf-lib";
import { toast } from "react-toastify";

const QuickBooksFilters = (props) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { business_id } = useParams();
  const {
    currentCategory,
    setQuickBooksData,
    customDate,
    setCustomDate,
    setHeaderDate,
    anotherPeriod,
    setAnotherPeriod,
    setDataCounter,
    setConditionalSummarizeCoulmn,
    setQuickbooksDataCounter,
    setSummarizeColumnBy,
    summarizeColumnBy,
  } = props;
  const { user } = useSelector((state) => state.auth);
  let id;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const [reportFilter, setReportFilter] = useState({
    profit_loss: {},
    balance_sheet: {},
    cash_flow: {},
    trial_balance: {},
  });
  const [runReportDisable, setRunReportDisable] = useState(false);
  const [summarizeCoulmn, setSummarizeCoulmn] = useState({
    profit_loss: quickBooksColumnFilterArr[0],
    balance_sheet: quickBooksColumnFilterArr[0],
    cash_flow: quickBooksColumnFilterArr[0],
    trial_balance: quickBooksColumnFilterArr[0],
  });
  const [accountingMethod, setAccountingMethod] = useState({
    profit_loss: "Accrual",
    balance_sheet: "Accrual",
    cash_flow: "Accrual",
    trial_balance: "Accrual",
  });
  const [reportPeriod, setReportPeriod] = useState({
    profit_loss: quickBooksReportPeriodFilterArr[6],
    balance_sheet: quickBooksReportPeriodFilterArr[6],
    cash_flow: quickBooksReportPeriodFilterArr[6],
    trial_balance: quickBooksReportPeriodFilterArr[6],
  });
  const [isFilterChanged, setIsFilterChanged] = useState(true);
  const [isEndDateError, setIsEndDateError] = useState(false);
  const [isDateLimit, setIsDateLimit] = useState(false);

  useEffect(() => {
    checkToken();
    fetchReportFilters();
  }, []);

  useEffect(() => {
    if (
      summarizeColumnBy.toLowerCase() !== "total" &&
      summarizeColumnBy.toLowerCase() !== "year" &&
      moment(customDate[currentCategory]["end_date"]).diff(
        moment(customDate[currentCategory]["start_date"]),
        "years",
        true
      ) > 1
    ) {
      setIsDateLimit(true);
      setRunReportDisable(true);
    } else {
      setIsDateLimit(false);
      setRunReportDisable(false);
    }
  }, [
    customDate[currentCategory]["start_date"],
    customDate[currentCategory]["end_date"],
    summarizeColumnBy,
  ]);

  useEffect(() => {
    changeReportPeriod(reportPeriod[currentCategory]);
    if (
      moment(customDate[currentCategory]["start_date"]).diff(
        moment(customDate[currentCategory]["end_date"]),
        "days"
      ) > 0
    ) {
      setIsEndDateError(true);
    } else {
      setIsEndDateError(false);
    }
  }, [
    reportPeriod[currentCategory],
    reportFilter[currentCategory].report_period,
  ]);

  const checkToken = async () => {
    const tokenPresent = await dispatch(
      refreshTokenPresent({
        business_id: Number(business_id),
        user_id: user && user.user && user.user.id,
      })
    )
      .unwrap()
      .then((response) => {
        if (response === null) {
          if (
            reportFilter[currentCategory].report_period &&
            reportFilter[currentCategory].report_period !== "Year to Date"
          ) {
            changeReportPeriod(reportPeriod[currentCategory]);
          }
          return true;
        } else {
          navigation(`/individual/assets/business`);
          toast.warning("Your account has been disconnected from QuickBooks.");
          return false;
        }
      })
      .catch((error) => { });
    return tokenPresent;
  };

  function startAndEndDate(params) {
    switch (params) {
      case quickBooksReportPeriodFilterArr[1]:
        return {
          start_date: moment_timezone()
            .subtract(1, "month")
            .startOf("months")
            .format("YYYY-MM-DD"),
          end_date: moment_timezone()
            .subtract(1, "month")
            .endOf("months")
            .format("YYYY-MM-DD"),
        };
      case quickBooksReportPeriodFilterArr[2]:
        return {
          start_date: moment_timezone()
            .tz("America/New_York")
            .subtract(3, "months")
            .startOf("quarter")
            .format("YYYY-MM-DD"),
          end_date: moment_timezone()
            .tz("America/New_York")
            .subtract(3, "months")
            .endOf("quarter")
            .format("YYYY-MM-DD"),
        };
      case quickBooksReportPeriodFilterArr[3]:
        return {
          start_date: moment([
            moment_timezone().tz("America/New_York").subtract(1, "year").year(),
          ]).format("YYYY-MM-DD"),
          end_date: moment([
            moment_timezone().tz("America/New_York").subtract(1, "year").year(),
          ])
            .endOf("year")
            .format("YYYY-MM-DD"),
        };
      case quickBooksReportPeriodFilterArr[4]:
        return {
          start_date: moment_timezone()
            .tz("America/New_York")
            .startOf("month")
            .format("YYYY-MM-DD"),
          end_date: moment_timezone()
            .tz("America/New_York")
            .format("YYYY-MM-DD"),
        };

      case quickBooksReportPeriodFilterArr[5]:
        return {
          start_date: moment_timezone()
            .tz("America/New_York")
            .startOf("quarter")
            .format("YYYY-MM-DD"),
          end_date: moment_timezone()
            .tz("America/New_York")
            .format("YYYY-MM-DD"),
        };

      case quickBooksReportPeriodFilterArr[6]:
        return {
          start_date: moment_timezone()
            .tz("America/New_York")
            .startOf("year")
            .format("YYYY-MM-DD"),
          end_date: moment_timezone()
            .tz("America/New_York")
            .format("YYYY-MM-DD"),
        };

      case quickBooksReportPeriodFilterArr[7]:
        return {
          start_date: reportFilter[currentCategory].start_date
            ? moment(reportFilter[currentCategory].start_date).format(
              "YYYY-MM-DD"
            )
            : moment_timezone().tz("America/New_York").format("YYYY-MM-DD"),
          end_date: reportFilter[currentCategory].end_date
            ? moment(reportFilter[currentCategory].end_date).format(
              "YYYY-MM-DD"
            )
            : moment_timezone().tz("America/New_York").format("YYYY-MM-DD"),
        };

      default:
        return {
          start_date: customDate[currentCategory].start_date,
          end_date: customDate[currentCategory].end_date,
        };
    }
  }

  const changeAccountingMethod = (params) => {
    let fetchData = { ...reportFilter };
    fetchData[currentCategory].accounting_method = params;
    setAccountingMethod({ ...accountingMethod, [currentCategory]: params });
    setReportFilter({ ...reportFilter, ...fetchData });
    setIsFilterChanged(true);
  };

  const changeSummarizeColumnBy = (params) => {
    let fetchData = { ...reportFilter };
    fetchData[currentCategory].summarize_column_by = params;
    setSummarizeCoulmn({ ...summarizeCoulmn, [currentCategory]: params });
    setSummarizeColumnBy(params);
    setReportFilter({ ...reportFilter, ...fetchData });
    setIsFilterChanged(true);
  };

  const changeReportPeriod = (params) => {
    let fetchDate = startAndEndDate(params);
    let customDateFetch = { ...customDate };
    customDateFetch[currentCategory] = fetchDate;
    setCustomDate({ ...customDate, ...customDateFetch });
    setIsFilterChanged(true);

    setReportPeriod({ ...reportPeriod, [currentCategory]: params });

    let reportFilterData = { ...reportFilter };
    reportFilterData[currentCategory].report_period = params;
    reportFilterData[currentCategory].start_date = fetchDate.start_date;
    reportFilterData[currentCategory].end_date = fetchDate.end_date;
    if (params === quickBooksReportPeriodFilterArr[0]) {
      reportFilterData[currentCategory].another_period = [];
      setAnotherPeriod({ ...anotherPeriod, [currentCategory]: [] });
    }
    setReportFilter({ ...reportFilter, ...reportFilterData });
  };

  const changeStartEndDate = (params, type) => {
    let fetchDate = { ...customDate };
    fetchDate[currentCategory][type] = moment(params).format("YYYY-MM-DD");
    if (
      moment(fetchDate[currentCategory]["start_date"]).diff(
        moment(fetchDate[currentCategory]["end_date"]),
        "days"
      ) <= 0
    ) {
      setIsEndDateError(false);
    }
    setCustomDate({ ...fetchDate });
    setIsFilterChanged(true);

    let reportFilterData = { ...reportFilter };
    reportFilterData[currentCategory][type] =
      moment(params).format("YYYY-MM-DD");
    setReportFilter({ ...reportFilter, ...reportFilterData });
  };

  const changeAnotherPeriod = (params) => {
    let fetchArr = [...anotherPeriod[currentCategory]];

    if (fetchArr.includes(params)) {
      fetchArr.splice(fetchArr.indexOf(params), 1);
    } else {
      fetchArr.push(params);
    }
    setIsFilterChanged(true);
    setAnotherPeriod({ ...anotherPeriod, [currentCategory]: fetchArr });
    let reportFilterData = { ...reportFilter };
    reportFilterData[currentCategory].another_period = fetchArr;
    setReportFilter({ ...reportFilter, ...reportFilterData });
  };

  function fetchReportFilters() {
    dispatch(
      quickBooksFetchReportFilter({
        user_id: id,
        business_id: Number(business_id),
      })
    )
      .unwrap()
      .then((response) => {
        setReportFilter(response);
        setAccountingMethod({
          profit_loss:
            response.profit_loss.accounting_method ||
            quickBooksAccountingMethodArr[0],
          balance_sheet:
            response.balance_sheet.accounting_method ||
            quickBooksAccountingMethodArr[0],
          cash_flow: quickBooksAccountingMethodArr[1],
          trial_balance: response.trial_balance.accounting_method ||
            quickBooksAccountingMethodArr[0],
        });
        setSummarizeCoulmn({
          profit_loss:
            response.profit_loss.summarize_column_by ||
            quickBooksColumnFilterArr[0],
          balance_sheet:
            response.balance_sheet.summarize_column_by ||
            quickBooksColumnFilterArr[0],
          cash_flow:
            response.cash_flow.summarize_column_by ||
            quickBooksColumnFilterArr[0],
          trial_balance:
            response.trial_balance.summarize_column_by ||
            quickBooksColumnFilterArr[0],
        });
        setSummarizeColumnBy(
          response[currentCategory].summarize_column_by ||
          quickBooksColumnFilterArr[0]
        );
        setConditionalSummarizeCoulmn({
          profit_loss:
            response.profit_loss.summarize_column_by ||
            quickBooksColumnFilterArr[0],
          balance_sheet:
            response.balance_sheet.summarize_column_by ||
            quickBooksColumnFilterArr[0],
          cash_flow:
            response.cash_flow.summarize_column_by ||
            quickBooksColumnFilterArr[0],
          trial_balance:
            response.trial_balance.summarize_column_by ||
            quickBooksColumnFilterArr[0],
        });
        setReportPeriod({
          profit_loss:
            response.profit_loss.report_period ||
            quickBooksReportPeriodFilterArr[6],
          balance_sheet:
            response.balance_sheet.report_period ||
            quickBooksReportPeriodFilterArr[6],
          cash_flow:
            response.cash_flow.report_period ||
            quickBooksReportPeriodFilterArr[6],
          trial_balance:
            response.trial_balance.report_period ||
            quickBooksReportPeriodFilterArr[6],
        });
        setAnotherPeriod({
          profit_loss: response.profit_loss.another_period || [],
          balance_sheet: response.balance_sheet.another_period || [],
          cash_flow: response.cash_flow.another_period || [],
          trial_balance: response.trial_balance.anotherPeriod || [],
        });
        setDataCounter({
          profit_loss: response.profit_loss.another_period || [],
          balance_sheet: response.balance_sheet.another_period || [],
          cash_flow: response.cash_flow.another_period || [],
          trial_balance: response.trial_balance.another_period || [],
        });
        setHeaderDate({
          profit_loss: {
            start_date:
              response.profit_loss.start_date ||
              moment_timezone()
                .tz("America/New_York")
                .startOf("year")
                .format("YYYY-MM-DD"),
            end_date:
              response.profit_loss.end_date ||
              moment_timezone().tz("America/New_York").format("YYYY-MM-DD"),
            date_macro:
              response.profit_loss.report_period ===
                quickBooksReportPeriodFilterArr[0]
                ? true
                : false,
          },
          balance_sheet: {
            start_date:
              response.balance_sheet.start_date ||
              moment_timezone()
                .tz("America/New_York")
                .startOf("year")
                .format("YYYY-MM-DD"),
            end_date:
              response.balance_sheet.end_date ||
              moment_timezone().tz("America/New_York").format("YYYY-MM-DD"),
            date_macro:
              response.balance_sheet.report_period ===
                quickBooksReportPeriodFilterArr[0]
                ? true
                : false,
          },
          cash_flow: {
            start_date:
              response.cash_flow.start_date ||
              moment_timezone()
                .tz("America/New_York")
                .startOf("year")
                .format("YYYY-MM-DD"),
            end_date:
              response.cash_flow.end_date ||
              moment_timezone().tz("America/New_York").format("YYYY-MM-DD"),
            date_macro:
              response.cash_flow.report_period ===
                quickBooksReportPeriodFilterArr[0]
                ? true
                : false,
          },
          trial_balance: {
            start_date:
              response.trial_balance.start_date ||
              moment_timezone()
                .tz("America/New_York")
                .startOf("year")
                .format("YYYY-MM-DD"),
            end_date:
              response.trial_balance.end_date ||
              moment_timezone().tz("America/New_York").format("YYYY-MM-DD"),
            date_macro:
              response.trial_balance.report_period ===
                quickBooksReportPeriodFilterArr[0]
                ? true
                : false,
          },
        });
        setCustomDate({
          profit_loss: {
            start_date:
              response.profit_loss.start_date ||
              moment_timezone()
                .tz("America/New_York")
                .startOf("year")
                .format("YYYY-MM-DD"),
            end_date:
              response.profit_loss.end_date ||
              moment_timezone().tz("America/New_York").format("YYYY-MM-DD"),
          },
          balance_sheet: {
            start_date:
              response.balance_sheet.start_date ||
              moment_timezone()
                .tz("America/New_York")
                .startOf("year")
                .format("YYYY-MM-DD"),
            end_date:
              response.balance_sheet.end_date ||
              moment_timezone().tz("America/New_York").format("YYYY-MM-DD"),
          },
          cash_flow: {
            start_date:
              response.cash_flow.start_date ||
              moment_timezone()
                .tz("America/New_York")
                .startOf("year")
                .format("YYYY-MM-DD"),
            end_date:
              response.cash_flow.end_date ||
              moment_timezone().tz("America/New_York").format("YYYY-MM-DD"),
          },
          trial_balance: {
            start_date:
              response.trial_balance.start_date ||
              moment_timezone()
                .tz("America/New_York")
                .startOf("year")
                .format("YYYY-MM-DD"),
            end_date:
              response.trial_balance.end_date ||
              moment_timezone().tz("America/New_York").format("YYYY-MM-DD"),
          },
        });
      })
      .catch((err) => {
        console.log("fetchReportFilters:- ", err);
        return false;
      });
  }

  const runReport = () => {
    let fetchDate = { ...customDate };
    if (
      moment(fetchDate[currentCategory]["start_date"]).diff(
        moment(fetchDate[currentCategory]["end_date"]),
        "days") > 0 && reportPeriod[currentCategory] === "Custom") {
      setIsEndDateError(true);
      return;
    }
    props.setIsLoader(true);
    setRunReportDisable(true);
    checkToken()
      .then((result) => {
        if (result === true) {
          setIsFilterChanged(false);
          let reportFilters = {
            profit_loss: {
              accounting_method: accountingMethod["profit_loss"],
              summarize_column_by: summarizeCoulmn["profit_loss"],
              report_period: reportPeriod["profit_loss"],
              another_period: anotherPeriod["profit_loss"],
              start_date:
                reportFilter.profit_loss.start_date &&
                reportFilter.profit_loss.start_date,
              end_date:
                reportFilter.profit_loss.end_date &&
                reportFilter.profit_loss.end_date,
            },
            balance_sheet: {
              accounting_method: accountingMethod["balance_sheet"],
              summarize_column_by: summarizeCoulmn["balance_sheet"],
              report_period: reportPeriod["balance_sheet"],
              another_period: anotherPeriod["balance_sheet"],
              start_date:
                reportFilter.balance_sheet.start_date &&
                reportFilter.balance_sheet.start_date,
              end_date:
                reportFilter.balance_sheet.end_date &&
                reportFilter.balance_sheet.end_date,
            },
            cash_flow: {
              accounting_method: accountingMethod["cash_flow"],
              summarize_column_by: summarizeCoulmn["cash_flow"],
              report_period: reportPeriod["cash_flow"],
              another_period: anotherPeriod["cash_flow"],
              start_date:
                reportFilter.cash_flow.start_date &&
                reportFilter.cash_flow.start_date,
              end_date:
                reportFilter.cash_flow.end_date &&
                reportFilter.cash_flow.end_date,
            },
            trial_balance: {
              accounting_method: accountingMethod["trial_balance"],
              summarize_column_by: summarizeCoulmn["trial_balance"],
              report_period: reportPeriod["trial_balance"],
              another_period: anotherPeriod["trial_balance"],
              start_date:
                reportFilter.trial_balance.start_date &&
                reportFilter.trial_balance.start_date,
              end_date:
                reportFilter.trial_balance.end_date &&
                reportFilter.trial_balance.end_date,
            },
          };

          for (const [category, value] of Object.entries(reportFilter)) {
            if (value.length) {
              reportFilters = reportFilter;
            }
          }
          dispatch(
            quickBooksReportFilter({
              user_id: id,
              business_id: Number(business_id),
              reportFilter: reportFilters,
              category: currentCategory,
            })
          )
            .unwrap()
            .then((response) => {
              setQuickbooksDataCounter(response.dataCounter);
              setRunReportDisable(false);
              props.setIsLoader(false);
              setQuickBooksData(response);
              fetchReportFilters();
            })
            .catch((err) => {
              props.setIsLoader(false);
              console.log("runReport:- ", err);
              return false;
            });
        }
      })
      .catch((err) => {
        console.log(err);
        throw new Error(err);
      });
  };

  return (
    <div className="transaction-search-bar">
      <Row className="align-items-center">
        <Col lg={5} xl={"auto"}>
          <div className="transaction-period-select">
            <h4 className="default-regular-sub-heading-xs cs-neutral-100">
              Report period
            </h4>
            <div className="cs-report-period">
              <div className="cs-common-add-dropdown">
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic">
                    <span>{reportPeriod[currentCategory]}</span>
                    <span className="cs-neutral-100">
                      <CapsyncIcon title="chevron-down-outlined" size="12" />
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {quickBooksReportPeriodFilterArr.map((itr, index) => {
                      return (
                        <Dropdown.Item
                          key={itr}
                          className={
                            itr === reportPeriod[currentCategory]
                              ? "cs-selected-dropdown"
                              : ""
                          }
                          onClick={() => changeReportPeriod(itr)}
                        >
                          {itr}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="showdate">
                {reportPeriod[currentCategory] !==
                  quickBooksReportPeriodFilterArr[0] && (
                    <>
                      <DatePicker
                        type="date"
                        dayPlaceholder="DD"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        disabled={
                          reportPeriod[currentCategory] !==
                            quickBooksReportPeriodFilterArr[7]
                            ? true
                            : false
                        }
                        // maxDate={new Date(customDate[currentCategory]['end_date'])}
                        // maxDate={new Date(customDate[currentCategory]['end_date'])}
                        // minDate={selectedEndData}
                        value={moment(
                          customDate[currentCategory].start_date,
                          "YYYY-MM-DD"
                        )}
                        className={"cs-date-picker-calender"}
                        onChange={(e) => {
                          changeStartEndDate(e, "start_date");
                        }}
                        clearIcon={null}
                        calendarIcon={
                          <span className="cs-neutral-70">
                            <CapsyncIcon title="calender-outlined" size="14" />
                          </span>
                        }
                        format="MM/dd/yy"
                      />

                      <span className="default-regular-h6 cs-neutral-70">to</span>

                      <DatePicker
                        id="endDatePicker"
                        type="date"
                        dayPlaceholder="DD"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        disabled={
                          reportPeriod[currentCategory] !==
                            quickBooksReportPeriodFilterArr[7]
                            ? true
                            : false
                        }
                        // maxDate={reportPeriod[currentCategory] === quickBooksReportPeriodFilterArr[7] && new Date()}
                        // minDate={new Date(customDate[currentCategory].start_date)}
                        className={`${isEndDateError &&
                          reportPeriod[currentCategory].toLowerCase() === "custom"
                          ? "cs-date-picker-calender end-date-error"
                          : "cs-date-picker-calender"
                          }`}
                        value={moment(
                          customDate[currentCategory].end_date,
                          "YYYY-MM-DD"
                        )}
                        onChange={(e) => {
                          changeStartEndDate(e, "end_date");
                        }}
                        clearIcon={null}
                        calendarIcon={
                          <span className="cs-neutral-70">
                            <CapsyncIcon title="calender-outlined" size="14" />
                          </span>
                        }
                        format="MM/dd/yy"
                      />
                    </>
                  )}
              </div>
              {isEndDateError &&
                reportPeriod[currentCategory].toLowerCase() === "custom" && (
                  <span
                    className="form-error-msg default-light-body-text-s cs-danger"
                    htmlFor="endDatePicker"
                  >
                    This end date is before the start date. Choose an end date
                    after the start date.
                  </span>
                )}
            </div>
          </div>
        </Col>
        <Col lg={5}>
          <div className="qbo-select-row cs-common-add-dropdown">
            <div className="cs-qbo-display">
              <h4 className="default-regular-sub-heading-xs cs-neutral-100">
                Display columns By
              </h4>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <span>{summarizeCoulmn[currentCategory]}</span>
                  <span className="cs-neutral-100">
                    <CapsyncIcon title="chevron-down-outlined" size="12" />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {currentCategory === "trial_balance"
                    ? quickBooksColumnFilterArr
                      .filter(
                        (column) => column === "Total" || column === "Month"
                      )
                      .map((itr) => {
                        return (
                          <Dropdown.Item
                            key={itr}
                            className={
                              itr === summarizeCoulmn[currentCategory]
                                ? "cs-selected-dropdown"
                                : ""
                            }
                            onClick={() => changeSummarizeColumnBy(itr)}
                          >
                            {itr}
                          </Dropdown.Item>
                        );
                      })
                    : quickBooksColumnFilterArr.map((itr) => {
                      return (
                        <Dropdown.Item
                          key={itr}
                          className={
                            itr === summarizeCoulmn[currentCategory]
                              ? "cs-selected-dropdown"
                              : ""
                          }
                          onClick={() => changeSummarizeColumnBy(itr)}
                        >
                          {itr}
                        </Dropdown.Item>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {(currentCategory === "profit_loss" ||
              currentCategory === "balance_sheet" ||
              currentCategory === "trial_balance") && (
                <>
                  {currentCategory !== "trial_balance" && (
                    <div className="cs-qbo-compare">
                      <h4 className="default-regular-sub-heading-xs cs-neutral-100">
                        Compare another period
                      </h4>
                      <Dropdown>
                        <Dropdown.Toggle id="dropdown-basic">
                          <span>
                            {anotherPeriod[currentCategory].length === 0
                              ? "Select Period"
                              : anotherPeriod[currentCategory].length > 1
                                ? "Multiple"
                                : anotherPeriod[currentCategory][0].split("(")[0]}
                          </span>
                          <span className="cs-neutral-100">
                            <CapsyncIcon
                              title="chevron-down-outlined"
                              size="12"
                            />
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {quickBooksAnotherPeriodFilterArr
                            .filter((x, i) =>
                              currentCategory === quickBooksArr[1].type
                                ? i <= 1
                                : i >= 0
                            )
                            .map((itr, index) => {
                              return (
                                <Form.Check
                                  type="checkbox"
                                  id={`default-${index}`}
                                  label={itr}
                                  name="group1"
                                  disabled={
                                    reportPeriod[currentCategory] ===
                                    quickBooksReportPeriodFilterArr[0]
                                  }
                                  className="cs-form-check-box default-light-body-text-m"
                                  checked={anotherPeriod[
                                    currentCategory
                                  ].includes(itr)}
                                  onChange={() => changeAnotherPeriod(itr)}
                                />
                              );
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  )}
                  <div className="cs-qbo-accounting">
                    <h4 className="default-regular-sub-heading-xs cs-neutral-100">
                      Accounting method
                    </h4>
                    <div className="cs-radio-row">
                      {quickBooksAccountingMethodArr.map((type, index) => (
                        <div key={`default-${type}`} className="cs-radio-btn">
                          <input
                            type="radio"
                            id={`default-${index}`}
                            className="radio-button"
                            checked={type === accountingMethod[currentCategory]}
                            name="group1"
                            onChange={() => changeAccountingMethod(type)}
                          />
                          <label
                            className="default-regular-body-text-m cursor-pointer"
                            onClick={() => changeAccountingMethod(type)}
                          >
                            {type}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
          </div>
        </Col>
        <Col lg={2} xl>
          <div className="text-end">
            <button
              className="md-btn cs-btn-primary default-regular-h6"
              onClick={runReport}
              disabled={
                isFilterChanged === false ||
                runReportDisable ||
                (summarizeCoulmn[currentCategory] === "Days" &&
                  anotherPeriod[currentCategory].length > 0)
              }
            >
              Run Report
            </button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default QuickBooksFilters;
