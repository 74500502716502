import API from "../util/api";
import { headerAddToken } from "../util/common";

const fetch_all_payment_plan = async (values) => {
  let header = await headerAddToken();
  return API.post("/paymentplan/fetch_all_payment_plan", values, header);
};

const common_payment_plan = {
  fetch_all_payment_plan,
};

export default common_payment_plan;