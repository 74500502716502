import axios from "axios";
import FileDownload from "js-file-download";

const UseTransactionPdf = (customUrl, data, fileName, downloadButton) => {
  axios({
    url: customUrl,
    method: "POST",
    data: data,
    responseType: "blob",
  })
    .then((response) => {
      downloadButton();
      FileDownload(response.data, fileName);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default UseTransactionPdf
