
import React, { useEffect, useState, useCallback, Fragment } from "react";
import {
  Form,
  Button,
  Modal,
  CloseButton,
  Row,
  Col,
  Image,
  FormControl,
  Dropdown
} from "react-bootstrap";
import {
  wealth_companies_add,
  wealth_companies_list,
  wealth_individuals_exist,
} from "../../../../slices/wealthManager.slice";

import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import UserSuccessModel from "./UserSuccessModal";
import useDebounce from "../../../../customHooks/useDebounce";
import { access_invitation_Pfs } from "../../../../slices/pfs_access";
import { emailSchema } from "../../../../util/common";
import { uniValidation } from "../../../individual/liabilities/categoryForms/validationSchema";


const CompanyForm = ({ toastMessage, setShowModal, showChildModal, setShowChildModal, setUserDropdown, filtered, getIndividuals }) => {
  const { user } = useSelector((state) => state.auth);
  const individuals = useSelector((state) => state.manageUser.individuals)
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch()
  const [compName, setCompName] = useState("");
  const [companyValidation, setCompanyValidation] = useState({
    msg: "",
    color: "",
  });
  const [validation, setValidation] = useState({
    msg: "",
    color: "",
  });
  const [partners, setPartners] = useState([
    { email: "", firstName: "", lastName: "", id: 0 },
  ]);
  const user_email = user && user.user.email;
  const user_role = user && user.user.email;
  const user_id = user && user.user.id;
  const user_displayName = user && user.user.displayName;
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [dropdownValidation, setDropdownValidation] = useState("");
  const [selectedIndividualFromCompany, setSelectedIndividualFromCompany] = useState([])
  const [selectedIndividualEmailArray, setSelectedIndividualEmailArray] = useState([])
  const [fetchRecords, setFetchRecords] = useState([]);
  const [companies, setFiltered] = useState([]);


  const fetchAllCompanies = () => {

    dispatch(wealth_companies_list({ user_id: user_id }))
      .unwrap()
      .then((response) => {
        setFetchRecords(response.fetchwealth_manager_companies);
        setFiltered(response.fetchwealth_manager_companies);
      })
      .catch((err) => {
        toastMessage(err.message, "error");
      });
  };

  const checkUserExist = useCallback((val, params) => {
    let inputData = [...partners];
    if (val !== "") {
      dispatch(wealth_individuals_exist({ val: val }))
        .unwrap()
        .then((response) => {
          inputData[params].id = response.user_id;
          setPartners(inputData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
    [partners]
  );

  const addFields = () => {
    setShowLoader(true);
    setPartners([
      ...partners,
      { email: "", firstName: "", lastName: "", id: 0 },
    ]);
    // const isAllFieldsFilled = partners.every(
    //   (partner) =>
    //     partner.email.trim() !== "" &&
    //     partner.firstName.trim() !== "" &&
    //     partner.lastName.trim() !== ""
    // );

    // setTimeout(() => {
    //   if (isAllFieldsFilled) {
    //     setShowLoader(false);
    //     setPartners([
    //       ...partners,
    //       { email: "", firstName: "", lastName: "", id: 0 },
    //     ]);
    //   } else {
    //     setValidation({
    //       msg: "*Please fill all partner details",
    //       color: "text-danger",
    //     });
    //   }
    // }, 1000);
  };

  const isEmailAlreadyInIndividualList = (currEmail) => {
    for (let j = 0; j < individuals.length; j++) {
      const individual = individuals[j]
      const email = individual.email ? individual.email : ""
      if (currEmail == email) {
        return true
      }
    }
    return false
  }

  const fieldChange = async (val, params, type) => {
    let inputData = [...partners];
    setValidation({
      msg: "",
      color: "",
    });
    if (type === "email") {
      inputData[params].email = val;
      // Check email format validity
      let isValidEmail = false
      try {
        await emailSchema.validate(val);
        isValidEmail = true
        inputData[params].emailError = ""
        setPartners(inputData);
      } catch (error) {
        isValidEmail = false
        inputData[params].emailError = error.message
        setPartners(inputData);
      }
      if (isValidEmail && val === user_email) {
        inputData[params].emailError = "Can not send invitation to yourself";
      } else if (isValidEmail && isEmailAlreadyInIndividualList(val)) {
        inputData[params].emailError = "User Already Exists in Individuals list";
      }
      if (isValidEmail) {
        checkUserExist(val, params)
      }
    } else if (type === "firstName") {
      inputData[params].firstName = val;
      // const hasLeadingTrailingWhitespace = /^\s|\s$/.test(val);
      // const hasNonWhitespaceCharacter = /\S/.test(val);
      if (val == "") {
        inputData[params].fnameError = "Enter first name"
      }
      // else if (hasLeadingTrailingWhitespace || !hasNonWhitespaceCharacter) {
      //   inputData[params].fnameError =
      //     "Avoid leading or trailing whitespace";
      // }
      else {
        inputData[params].fnameError = "";
      }
      setPartners(inputData);
    } else if (type === "lastName") {
      inputData[params].lastName = val;
      // const hasLeadingTrailingWhitespace = /^\s|\s$/.test(val);
      // const hasNonWhitespaceCharacter = /\S/.test(val);
      if (val == "") {
        inputData[params].lnameError = "Enter last name"
      }
      // else if (hasLeadingTrailingWhitespace || !hasNonWhitespaceCharacter) {
      //   inputData[params].lnameError =
      //     "Avoid leading or trailing whitespace";
      // } 
      else {
        inputData[params].lnameError = "";
      }
      setPartners(inputData);
    } else {
      inputData[params].lastName = val;
      setPartners(inputData);
    }
  };


  const removeField = (params) => {
    if (partners.length > 1) {
      let fetchData = [...partners];
      fetchData.splice(params, 1);
      setPartners(fetchData);
    }
  };
  const handlecompanyName = (event) => {
    setCompanyValidation({
      msg: "",
      color: "",
    })
    setCompName(event.target.value);
  };

  useEffect(() => {
    fetchAllCompanies();

  }, [])
  // access_invitation_Pfs
  const sendInvitation = () => {

    partners.forEach((items, index) => {
      if (items.email === "") {
        items.emailError = uniValidation.email.required
      }
      if (items.firstName === "") {
        items.fnameError = "Enter first name"
      }
      if (items.lastName === "") {
        items.lnameError = "Enter last name"
      }
    })
    setSubmitted(true);
    let flag = 0;
    let fetchRawPartners = [];
    partners.forEach((new_itr) => {
      fetchRawPartners.push(new_itr.email);
    });

    let dummyfetchRawPartners = new Set(fetchRawPartners);
    if (Array.from(dummyfetchRawPartners).length !== fetchRawPartners.length) {
      setValidation({
        msg: "*You cannot add same email more than once.",
        color: "text-danger",
      });
      return false;
    }

    partners.forEach((itr, index) => {
      if ((itr.email === "" || itr.firstName === "" || itr.lastName === "") && index > 0) {
        flag++;
      }
    });

    if (compName === "") {
      flag++;
      setCompanyValidation({
        msg: "Enter company name",
        color: "text-danger",
      });
    } else {
      setCompanyValidation({
        msg: "",
        color: "",
      });
      // if (
      //   // /^\s|\s$/.test(compName) ||
      //   // /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(compName)
      //   /^\s|\s$/.test(compName) ||
      //   /^[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(compName) ||
      //   /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]\s/.test(compName) ||  // Check for special characters followed by a space
      //   /\s[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(compName) ||  // Check for space followed by special characters
      //   /(\.\s|\s\.)/.test(compName) ||  // Check for dot followed by space or space followed by dot
      //   /^(\.|\s)|(\.|\s)$/.test(compName)  // Check for dot or space at the start or end
      // ) {
      //   flag++;
      //   setCompanyValidation({
      //     msg: "*Special characters and space not allowed",
      //     color: "text-danger",
      //   });
      // } else {
      //   setCompanyValidation({
      //     msg: "",
      //     color: "",
      //   });
      // }
    }

    // Check for invalid emails
    const invalidEmails = partners.some(
      (partner) => partner.emailError && partner.emailError !== ""
    );

    if (invalidEmails) {
      flag++;
    }
    // Check for invalid First Name
    const invalidFName = partners.some(
      (partner) => partner.fnameError && partner.fnameError !== ""
    );

    if (invalidFName) {
      flag++;
    }
    // Check for invalid Last Name
    const invalidLName = partners.some(
      (partner) => partner.lnameError && partner.lnameError !== ""
    );

    if (invalidLName) {
      flag++;
    }

    const isPartnerEmpty = () => {
      if (partners.length === 1 && (partners[0].email === "" && partners[0].firstName === "" && partners[0].lastName === "")) {
        return true;
      }
      return false;
    }
    let storeData = {
      partners: isPartnerEmpty() === true ? [] : partners,
      company_name: compName,
      user_id: user_id,
      request_send_by_displayName: user_displayName,
      request_send_by_email: user_email,
      selected_individual: selectedIndividualEmailArray
    };

    let checkCompName = companies.filter(
      (record) => record.companyName === compName
    );

    if (checkCompName.length !== 0) {
      flag++;
      setCompanyValidation({
        msg: "A company with this name already exists",
        color: "text-danger",
      });
    } else {
      // setValidation({
      //   msg: "*Some of the fields are empty",
      //   color: "text-danger",
      // });
    }

    if (flag === 0 && !invalidEmails) {
      setValidation({
        msg: "",
        color: "",
      });
      setLoading(true);
      dispatch(wealth_companies_add(storeData))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            setShowModal(false);
            setShowChildModal(true)
            fetchAllCompanies();
            filtered.length > 0 && getIndividuals()
            toastMessage("User added to this company successfully.", "success");
            setPartners([{ email: "", firstName: "", lastName: "" }]);
            setCompName("");
            dispatch(access_invitation_Pfs({ email: user_email }))
            setValidation({
              msg: "",
              color: "",
            });
            setLoading(false);
            setSelectedIndividualEmailArray([]);
            setSelectedIndividualFromCompany([]);
            setIsChecked(false)
            setShowModal(false);
            setShowChildModal(true)
            setFiltered(companies)
          } else if (response.code === 500 && response.invalid_emails && response.invalid_emails.length > 0) {
            let data = partners.map((val) => {
              return {
                ...val,
                emailError: response.invalid_emails.includes(val.email) ? response.message : ""
              }
            })
            setPartners(data);
            setLoading(false);
          } else {
            setShowModal(false);
            setShowChildModal(true);
            setLoading(false);
            setPartners([{ email: "", firstName: "", lastName: "" }]);
            setCompName("");
            setValidation({
              msg: "",
              color: "",
            });
            setSelectedIndividualEmailArray([]);
            setSelectedIndividualFromCompany([]);
            setIsChecked(false)
            toastMessage(response.message, "error");
          }
        })
        .catch((err) => {
          setShowModal(false);
          setShowChildModal(true);
          setLoading(false);
          toastMessage(err.message, "error");
        });
    }
  };

  const everyFieldShouldBeFilled = (partner) => partner.email === "" || partner.firstName === "" || partner.lastName === ""
  const everyFieldShouldBeEmpty = (partner) => {
    const result = (partner.email === "" && partner.firstName === "" && partner.lastName === "") && isChecked && selectedIndividualFromCompany.length > 0
    return result == true ? false : true
  }

  const handleCompanyCancel = () => {
    setShowModal(false);
    setUserDropdown('Individual');
  }

  return (
    <Fragment>
      <UserSuccessModel showChildModal={showChildModal} setShowModal={setShowModal} setShowChildModal={setShowChildModal} />
      <Row>
        <Col md={12}>
          <Form.Label>Company name</Form.Label>
          <Form.Group className="cs-form-group">
            <Form.Control
              type="text"
              onChange={handlecompanyName}
              value={compName}
              className={compName && "cs-input-field-active"}
              autoComplete="off"
              onKeyDown={(e) => {
                if (e.key === " " && e.target.selectionStart === 0) {
                  e.preventDefault();
                }
              }}
            />
            <span
              className={
                companyValidation.color &&
                "form-error-msg default-light-body-text-s cs-danger"
              }
            >
              {companyValidation.msg}
            </span>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="default-regular-body-text-m cs-neutral-80 partner-details">Enter partners details</div>
        </Col>
      </Row>
      {partners.map((iterator, index) => {
        return (
          <Fragment>
            <Row>
              <span className="company-modal-line">
                {partners.length > 1 && index !== 0 && (
                  <hr className="cs-neutral-60" />
                )}
              </span>
              {partners.length > 1 && index !== 0 && (
                <div className="company-popup-remove">
                  <span
                    className="cs-danger"
                    onClick={() => removeField(index)}
                  >
                    <CapsyncIcon title="delete-filled" size="16" />
                  </span>
                  <span
                    className="default-regular-h5 cs-danger"
                    onClick={() => removeField(index)}
                  >
                    Remove
                  </span>
                </div>
              )}

              <Col className="col" md={6}>
                <Form.Group
                  className={`cs-form-group${iterator.fnameError ? "" : ""
                    }`}
                  controlId={`validationFormik03-${index}`}
                >
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    value={iterator.firstName}
                    className={
                      iterator.firstName && "cs-input-field-active"
                    }
                    onChange={(event) =>
                      fieldChange(event.target.value, index, "firstName")
                    }
                    onKeyPress={(e) =>
                      /[0-9]/.test(e.key) && e.preventDefault()
                    }
                    onKeyDown={(e) => {
                      if (e.key === " " && e.target.selectionStart === 0) {
                        e.preventDefault();
                      }
                    }}
                    maxLength={40}
                  />
                  {iterator.fnameError && (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {iterator.fnameError}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col className="col" md={6}>
                <Form.Group
                  className={`cs-form-group ${iterator.lnameError ? "" : ""
                    }`}
                  controlId={`validationFormik03-${index}`}
                >
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    type="text"
                    value={iterator.lastName}
                    className={
                      iterator.lastName && "cs-input-field-active"
                    }
                    autoComplete="off"
                    onChange={(event) =>
                      fieldChange(event.target.value, index, "lastName")
                    }
                    onKeyPress={(e) =>
                      /[0-9]/.test(e.key) && e.preventDefault()
                    }
                    onKeyDown={(e) => {
                      if (e.key === " " && e.target.selectionStart === 0) {
                        e.preventDefault();
                      }
                    }}
                    maxLength={40}
                  />
                  {iterator.lnameError && (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {iterator.lnameError}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Form.Group
                  className={`cs-form-group last-email-field${iterator.emailError ? "" : ""
                    }`}
                  controlId={`validationFormik03-${index}`}
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    value={iterator.email}
                    className={iterator.email && "cs-input-field-active"}
                    onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                    onChange={(event) =>
                      fieldChange(event.target.value, index, "email")
                    }
                  // onBlur={(event) =>
                  //   checkUserExist(event.target.value, index)
                  // }
                  />
                  {iterator.emailError && (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {iterator.emailError}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Fragment>
        );
      })}

      <Fragment>
        <div className="initial-add-more">
          <Button
            type="submit"
            // disabled={validation.msg.length != 0}
            className="default-regular-h5 cs-btn-icon-tertiary"
            onClick={addFields}
          >
            <span className="cs-primary">
              <CapsyncIcon title="add-filled" size="16" />
              Add more partners
            </span>
          </Button>
          {partners.every(
            (partner) =>
              partner.email.trim() !== "" &&
              partner.firstName.trim() !== "" &&
              partner.lastName.trim() !== "" &&
              partner.emailError === "" &&
              partner.fnameError === "" &&
              partner.lnameError === ""

          )}
          <span
            className={
              validation.color &&
              "form-error-msg default-light-body-text-s cs-danger"
            }
          >
            {validation.msg}
          </span>
        </div>
      </Fragment>

      <div>
        <div className="cs-modal-btn">
          {filtered.length !== 0 &&
            (<Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={handleCompanyCancel}
            >
              Cancel
            </Button>)}

          <Button
            className="cs-btn-primary md-btn default-regular-h5"
            onClick={sendInvitation}
            // disabled={compName.length == 0 || partners.every(
            //   (partner) => {
            //     return everyFieldShouldBeEmpty(partner) && everyFieldShouldBeFilled(partner) //=== true ? (isChecked && selectedIndividualFromCompany > 0) : true
            //   })
            // }
            disabled={compName.length == 0 || loading}
          >
            Send Invitation
          </Button>
        </div>
      </div>
    </Fragment>
  )
}


export default CompanyForm
