/* eslint-disable */
import React, { useCallback, useState } from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import DatePicker from "react-date-picker";
import NumberFormat from "react-currency-format";
import { useFormik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";

import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import validationObj from "./validationSchema";
import { adjustLength } from "../../../../customHooks/adjustLength";
import {
  categoryAdd,
  categoryUpdateRecord,
} from "../../../../slices/category.slice";
import commonNames from "../../../../config/commonNames";
import { useEffect } from "react";
import { validateDuplicateFileName } from "../../../../config/validateDuplicateFileName";
import {
  get_user_storage,
  useProgressUploadDetails,
  useUploadStorage,
} from "../../../../slices/fileManagement.slice";

const PersonalProperty = (props) => {
  const { onFormClose, onFormSubmit, record, showModal } = props;
  const storageData = useUploadStorage();
  const progressUploadDetails = useProgressUploadDetails();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const [loading, setLoading] = useState(false);
  const [storeAcceptedFiles, setStoreAcceptedFiles] = useState([]);
  const [initialValues, setInitialValues] = useState({
    user_id: id,
    description: "",
    property_name: "",
    market_value: "",
    date_updated: moment().format(),
  });
  const [duplicateFileError, setDuplicateFileError] = useState("");
  const [storageError, setStorageError] = useState("");

  useEffect(() => {
    if (!!record) {
      setInitialValues({
        user_id: record.user_id,
        description: record.description,
        property_name: record.property_name,
        market_value:
          record.market_value && record.market_value.toLocaleString("en-US"),
        date_updated: new Date(
          moment(record.date_updated).format("MM/DD/YYYY")
        ),
        notes: record.notes == null ? "" : record.notes,
      });
    }
  }, [record]);

  const { values, errors, touched, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      enableReinitialize: true,
      validationSchema: validationObj.asset_personalProperty_validationSchema,
      onSubmit: async (values) => {
        setLoading(true);
        record && (values.id = record.id);
        values.user_id = id;
        values.pfs_access_id = id ? id : 0;
        const inProgressData = progressUploadDetails.length
          ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
          : 0;
        const used_space = storageData.usedSpace + inProgressData;

        const formdata = new FormData();

        formdata.append("user_details", JSON.stringify(values));
        formdata.append("user_id", values.user_id);
        formdata.append("used_space", used_space);
        formdata.append("institution", values.description);

        for (let i = 0; i < storeAcceptedFiles.length; i++) {
          formdata.append("attachments", storeAcceptedFiles[i]);
        }
        let data = {
          category: "personalproperty",
          main_category: "assets",
          used_space: used_space,
        };

        if (!!record) {
          data = { ...data, newData: values };
          dispatch(categoryUpdateRecord(data))
            .unwrap()
            .then((response) => {
              if (response.code === 200) {
                onFormSubmit("personalproperty");
                toast.success(response.message);
                props.dashboardAPi();
              }
              setLoading(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
            });
        } else {
          data = { ...data, formdata: formdata };
          dispatch(categoryAdd(data))
            .unwrap()
            .then(async (response) => {
              if (response.code === 200) {
                const response = await dispatch(
                  get_user_storage({ id })
                ).unwrap();
                onFormSubmit("personalproperty");
                // toast.success(response.message);
                props.dashboardAPi();
              } else if (response.code === 500) {
                setStorageError(response.message);
              }
              setLoading(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
            });
        }
      },
    });

  const handleChange = (e) => {
    setLoading(false);
    setFieldValue(e.target.name, e.target.value);
    setDuplicateFileError("");
  };

  // Upload File Handler
  const removeAcceptedFile = (params) => {
    let store = [...storeAcceptedFiles];
    store.splice(params, 1);
    setStoreAcceptedFiles(store);
    setDuplicateFileError("");
    setStorageError("");
  };
  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setDuplicateFileError("");
      setStorageError("");
      if (
        validateDuplicateFileName(
          acceptedFiles,
          storeAcceptedFiles,
          setDuplicateFileError
        )
      ) {
        setStoreAcceptedFiles([...acceptedFiles, ...storeAcceptedFiles]);
      }
    },
    [storeAcceptedFiles]
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    disabled: loading,
    onDropAccepted,
  });
  const files = storeAcceptedFiles.map((file, i) =>
    adjustLength(file.path, () => removeAcceptedFile(i))
  );

  return (
    <Modal
      show={showModal}
      backdrop="static"
      id="connect-your-institutions"
      aria-labelledby="contained-modal-title-vcenter"
      className="cap-dash-main-modal"
      centered
      keyboard={false}
      dialogClassName="cs-lg-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          {commonNames.PersonalProperty}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit} encType="multipart/form-data">
          <Row>
            <Col lg={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder=""
                  name="description"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.description}
                  className={values.description && "cs-input-field-active"}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                />
                {errors.description && touched.description ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.description}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Property Type</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="off"
                  placeholder=""
                  name="property_name"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.property_name}
                  className={values.property_name && "cs-input-field-active"}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                />
                {errors.property_name && touched.property_name ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.property_name}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6} xs={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Market Value</Form.Label>
                <NumberFormat
                  thousandSeparator={true}
                  autoComplete="off"
                  placeholder=""
                  name="market_value"
                  onKeyDown={(e) => {
                    if (e.key === "." && e.target.value === "") {
                      e.preventDefault(); // Prevent typing another dot if there's already one
                    }
                  }}
                  decimalScale={2}
                  maxLength={12}
                  value={values.market_value}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  className={
                    values.market_value
                      ? "dollar-symbol text-md-end form-control cs-input-field-active"
                      : "text-md-end form-control"
                  }
                  allowNegative={false}
                />
                {errors.market_value && touched.market_value ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.market_value}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Date Updated</Form.Label>
                <DatePicker
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  name="date_updated"
                  value={values.date_updated}
                  onChange={(e) => {
                    setLoading(false);
                    setFieldValue("date_updated", e);
                  }}
                  // onBlur={handleBlur}
                  clearIcon={null}
                  className={
                    values.date_updated
                      ? "cs-input-field-active select-date-range cs-date-picker-calender"
                      : "cs-date-picker-calender"
                  }
                  calendarIcon={
                    <span className="cs-neutral-90">
                      <CapsyncIcon title="calender-outlined" size="18" />
                    </span>
                  }
                  format="MM/dd/y"
                  maxDate={new Date()}
                />
                {errors.date_updated && touched.date_updated ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.date_updated}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Notes</Form.Label>
                <textarea
                  autoComplete="off"
                  type="text"
                  placeholder="Optional"
                  name="notes"
                  value={values.notes}
                  className={
                    values.notes
                      ? "cs-input-field-active cs-textarea"
                      : "cs-textarea"
                  }
                  onChange={handleChange}
                  // onBlur={handleBlur}
                ></textarea>
              </Form.Group>
            </Col>
          </Row>
          {!record && (
            <>
              <Row>
                <Col>
                  <Form.Label className="default-regular-sub-heading-xs cs-neutral-100">
                    Upload Files
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="cs-upload-box">
                    <div
                      {...getRootProps({})}
                      className={`cs-upload-files cursor-pointer ${
                        loading && "cs-neutral-60 disabled"
                      }`}
                    >
                      <input
                        {...getInputProps()}
                        disabled={loading}
                        name="attachments"
                      />
                      <span className="cs-primary">
                        <CapsyncIcon title="upload-files-filled" size="28" />
                      </span>
                      <span className="default-regular-sub-heading-s cs-neutral-90">
                        <span className="cs-primary">Browse</span>&nbsp;or drag
                        file here
                      </span>
                      <p className="default-regular-sub-heading-xxs">
                        Supports PDF, PNG, JPG and JPEG format
                      </p>
                    </div>
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {storageError || duplicateFileError}
                    </span>
                    <aside className="category-attachments-files">
                      <ul className="attachment-files cs-files">{files}</ul>
                    </aside>
                  </div>
                </Col>
              </Row>
            </>
          )}

          <div className="cs-modal-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={onFormClose}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="cs-btn-primary md-btn default-regular-h5"
              disabled={loading}
            >
              Save
              {loading && (
                <span className="cs-common-spinner cs-primary-main">
                  <CapsyncIcon title="loading-outlined" size="16" />
                </span>
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PersonalProperty;
