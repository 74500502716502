import { createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import pfs_access_service from "../services/pfs_access.service";
import { localDataRemove } from "../config/finance";
import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export const access_pfs_add_user = createAsyncThunk(
  "/invitation/add_user",
  async (values, thunkAPI) => {
    try {
      const response = await pfs_access_service.access_pfs_add_user(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const pfs_re_invitation = createAsyncThunk(
  "/invitation/pfs_re_invitation",
  async (values, thunkAPI) => {
    try {
      const response = await pfs_access_service.pfs_re_invitation(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const access_pfs_AcceptDeny = createAsyncThunk(
  "/invitation/accept_decline",
  async (values, thunkAPI) => {
    try {
      const response = await pfs_access_service.access_pfs_AcceptDeny(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const access_pfs_fetchAll = createAsyncThunk(
  "/invitation/fetchall",
  async ({ id, user }, thunkAPI) => {
    try {
      const response = await pfs_access_service.access_pfs_fetchAll(id);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const access_invitation_Pfs = createAsyncThunk(
  "/invitation/fetchpfs",
  async (values, thunkAPI) => {
    try {
      const response = await pfs_access_service.access_invitation_Pfs(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data.sort((ind1, ind2) => ind2.id - ind1.id);
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const access_pfs_getPermission = createAsyncThunk(
  "/invitation/get_permission",
  async (values, thunkAPI) => {
    try {
      const response = await pfs_access_service.access_pfs_getPermission(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const access_indiPFS_fromWealth = createAsyncThunk(
  "/invitation/fetch_user",
  async (values, thunkAPI) => {
    try {
      const response = await pfs_access_service.access_indiPFS_fromWealth(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const access_indiPFS_fromWealth_without_pfsid = createAsyncThunk(
  "/invitation/access_indiPFS_fromWealth_without_pfsid",
  async (values, thunkAPI) => {
    try {
      const response =
        await pfs_access_service.access_indiPFS_fromWealth_without_pfsid(
          values
        );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const change_permission = createAsyncThunk(
  "/invitation/change_permission",
  async (values, thunkAPI) => {
    try {
      const response = await pfs_access_service.change_permission(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const recently_viewed = createAsyncThunk(
  "/invitation/recently",
  async (values, thunkAPI) => {
    try {
      const response = await pfs_access_service.recently_viewed(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const access_pfs_deleteUser = createAsyncThunk(
  "/invitation/delete",
  async (values, thunkAPI) => {
    try {
      const response = await pfs_access_service.access_pfs_deleteUser(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const wm_delete_individual_account_from_company = createAsyncThunk(
  "/invitation/wm_delete_individual_account_from_company",
  async (values, thunkAPI) => {
    try {
      const response =
        await pfs_access_service.wm_delete_individual_account_from_company(
          values
        );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wm_delete_individual_account_from_ind = createAsyncThunk(
  "/invitation/wm_delete_individual_account_from_ind",
  async (values, thunkAPI) => {
    try {
      const response =
        await pfs_access_service.wm_delete_individual_account_from_ind(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const add_recently = createAsyncThunk(
  "/invitation/add_recently",
  async (values, thunkAPI) => {
    try {
      const response = await pfs_access_service.add_recently(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const company_recently = createAsyncThunk(
  "/invitation/company_recently",
  async (values, thunkAPI) => {
    try {
      const response = await pfs_access_service.company_recently(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const company_add_recently = createAsyncThunk(
  "/invitation/company_add_recently",
  async (values, thunkAPI) => {
    try {
      const response = await pfs_access_service.company_add_recently(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

const initialState = {
  manageUser: {},
  individuals: [],
};

const manageUserSlice = createSlice({
  name: "manageUser",
  initialState,
  reducers: {
    setIndividuals: (state, action) => {
      state.individuals = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(access_pfs_fetchAll.fulfilled, (state, action) => {
      state.manageUser = action.payload;
    });
    builder.addCase(access_invitation_Pfs.fulfilled, (state, action) => {
      state.individuals = action.payload;
    });
  },
});

export const { setIndividuals } = manageUserSlice.actions;
const { reducer } = manageUserSlice;
export default reducer;

const selectAccessPfs = (state) => state.manageUser.individuals;
export const useAccessPfsAccount = () => {
  const individual = useSelector(selectAccessPfs);
  return useMemo(() => individual, [individual]);
};
