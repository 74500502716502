/* eslint-disable */
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import SubMenuHeader from "../SubMenuHeader";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import SubMethodsList from "../../SubMethodsList";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import { Link } from "react-router-dom";
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";

// sequence No. seven  (Asmita date-29th June => Do not remove this line)
const MethodIdTen = (props) => {
  const [Fiduciary, setFiduciary] = useState(false);
  const [statement, setStatement] = useState(true);
  const [certificate, setCertificate] = useState(false);


  const handleFiduciary = () => {
    setFiduciary(true);
    setCertificate(false)
    setStatement(false)
  }
  const handleStatement = () => {
    setStatement(true);
    setFiduciary(false);
    setCertificate(false)

  }
  const handleCertificate = () => {
    setCertificate(true);
    setFiduciary(false)
    setStatement(false)
  };
  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Accredited Investor",
      url: `/individual/verifyinvestor/accreditedInvestor/entities/${props.unique_code}`,
    },
  ];



  return (
    <>
      {props.is_loader.assets === true || props.is_loader.liabilities === true ? <SkeletonAllSubMethods /> :
        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="ERISA Plan"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>
              <SubMenuHeader />
            </div>
            <div className="method-section">
              <div className="default-regular-body-text-l cs-neutral-70"> <SubMethodsList methodId={10.1} /></div>

              <div className="cs-divider"></div>
              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert} />


                {Fiduciary && <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Upload evidence that shows investment decisions are made by a Plan Fiduciary as described in the definition of “accredited investor”: — <span className="cs-neutral-70">Sample Officer’s Certificate</span> <Link to={"https://s3.amazonaws.com/static-capsync.com/Verify-Investor-files/OfficerCertificate-E72.pdf"} target="_blank" >Here</Link>
                </Form.Label>}

                {statement && <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Upload a statement that is no later than 90 days old showing your assets in excess of $5,000,000 USD:
                </Form.Label>}

                {certificate &&
                  <Form.Label>
                    Upload Officer's Certificate that certifies that all investment decisions are made solely by persons that are accredited investors: — <span className="cs-neutral-70">Sample Officer’s Certificate</span> <Link to={"https://s3.amazonaws.com/static-capsync.com/Verify-Investor-files/OfficerCertificate-E73.pdf"} target="_blank" >Here</Link>
                  </Form.Label>}

                <UploadFileBlock
                  files={props.files}
                  setOpenModal={props.setOpenModal}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile}
                  showFile={props.showFile}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink}
                  uploadError={props.uploadError}

                />
                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Upload other evidence:
                </Form.Label>
                <UploadFileBlock
                  files={props.files2}
                  setOpenModal={props.setOpenModal2}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile2}
                  showFile={props.showFile2}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink2}
                />
                <div className="row-spacing">
                  <p className="default-regular-body-text-l cs-neutral-70">Don’t have these forms? Choose {
                    !Fiduciary ? (<u onClick={handleFiduciary} className="cs-neutral-100 cursor-pointer"> Plan Fiduciary</u>) : (<u onClick={handleStatement} className="cs-neutral-100 cursor-pointer"> Plan Statement</u>)

                  } {" "} or {" "}{
                      !certificate ? (<u onClick={handleCertificate} className="cs-neutral-100 cursor-pointer"> Officer’s Certificate.</u>) : (<u onClick={handleFiduciary} className="cs-neutral-100 cursor-pointer"> Plan Fiduciary.</u>)

                    }
                  </p>
                </div>
                <NotesBlock setNotesValue={props.setNotesValue} />
              </Form>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default MethodIdTen;
