import { localStorageClear } from "../util/common";

const financeType = {
  assets: "Assets",
  liabilities: "Liabilities",
};

const chase_and_charles_schwab = ["ins_56", "ins_11"];

const plaidRefreshArr = [
  "asset_cash_on_hands",
  "asset_saving_accounts",
  "asset_retirement_accounts",
  "asset_stock_bonds",
  "liability_accounts_payable",
  "liability_installment_account",
  "liability_notes_payable",
  "liability_realestate_mortgages",
  "liability_automobiles",
];

const portFolioType = {
  derivative: "#67C59C",
  cash: "#71718C",
  "mutual fund": "#EA780E",
  equity: "#0024D4",
  etf: "#05A660",
};

// const lineQuarter = [
//   "firstQuarter_chart",
//   "secondQuarter_chart",
//   "thirdQuarter_chart",
//   "fourthQuarter_chart",
// ];

const lineQuarter = [
  "firstQuarter",
  "secondQuarter",
  "thirdQuarter",
  "fourthQuarter",
];

const lineChartMonth = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const dashboardChartData = [
  { title: "Total Assets", summaryAllocation: "assetTotal", fill: "#3D9C73" },
  {
    title: "Total Liabilities",
    summaryAllocation: "liabilitiyTotal",
    fill: "#E85B5B",
  },
];

const assetsCategory = [
  {
    category: "cashonhand",
    url: "cashonhand",
    title: "Cash on Hand & In Banks",
    summaryAllocation: "cashonhand",
    catDate: "assetsCashOnHandDate",
    fill: "#007241",
    // id: 8,
  },
  {
    category: "savingsAccount",
    url: "savingaccount",
    title: "Savings Accounts",
    summaryAllocation: "savingaccount",
    fill: "#007F48",
    catDate: "assetsSavingAccDate",
    // id: 9,
  },
  {
    category: "otherRetirement",
    url: "retirementaccount",
    title: "IRA or Other Retirement Account",
    summaryAllocation: "retirementaccount",
    fill: "#1A8C5A",
    catDate: "assetsRetirementAccDate",
    // id: 10,
  },
  {
    category: "notesReceivable",
    url: "accountnotesreceviable",
    title: "Accounts & Notes Receivable",
    summaryAllocation: "accountnotesreceviable",
    fill: "#33996D",
    catDate: "assetsNotesReceivableDate",
    // id: 11,
  },
  {
    category: "lifeInsurance",
    url: "lifeinsurance",
    title: "Life Insurance",
    summaryAllocation: "lifeinsurance",
    fill: "#4DA57F",
    catDate: "assetsLifeInsurenceDate",
    // id: 12,
  },
  {
    category: "nonRetirementBrokerage",
    url: "nonretirementbrokerage",
    title: "Non-Retirement Brokerage Accounts (Stocks & Bonds)",
    summaryAllocation: "nonretirementbrokerage",
    fill: "#66B291",
    catDate: "assetsStockNBondDate",
    // id: 13,
  },
  {
    category: "businesses",
    url: "business",
    title: "Businesses",
    summaryAllocation: "business",
    fill: "#80BFA4",
    catDate: "assetsBussinessDate",
    // id: 14,
  },
  {
    category: "automobile",
    url: "automobile",
    title: "Automobiles",
    summaryAllocation: "automobile",
    fill: "#99CCB6",
    catDate: "assetsAutomobileDate",
    // id: 15,
  },
  {
    category: "personalProperty",
    url: "personalproperty",
    title: "Other Personal Property",
    summaryAllocation: "personalproperty",
    fill: "#B3D9C8",
    catDate: "assetsPersonalPropertyDate",
    // id: 16,
  },
  {
    category: "realEstate",
    url: "realestate",
    title: "Real Estate",
    summaryAllocation: "realestate",
    fill: "#CCE5DA",
    catDate: "assetsRealEstateDate",
    // id: 17,
  },
  {
    category: "otherAssets",
    url: "otherassets",
    title: "Other Assets",
    summaryAllocation: "otherassets",
    fill: "#E6F2ED",
    catDate: "assetsOtherAssetsDate",
    // id: 18,
  },
];

const liabilitiesCategory = [
  {
    category: "life_insurance",
    url: "lifeinsurance",
    title: "Loan(s) Against Life Insurance",
    summaryAllocation: "lifeinsurance",
    catDate: "liaInstallmentAutoDate",
    fill: "#CE3030",
    // id: 20,
  },
  {
    category: "installmentAuto",
    url: "installmentsauto",
    title: "Installment Account (Auto)",
    summaryAllocation: "installmentsauto",
    catDate: "liaInstallmentAutoDate",
    fill: "#E53535",
    // id: 19,
  },
  {
    category: "accountPayable",
    url: "accountpayable",
    title: "Accounts Payable",
    summaryAllocation: "accountpayable",
    catDate: "liaAccPayableDate",
    fill: "#EA5D5D",
    // id: 1,
  },
  {
    category: "notesPayable",
    url: "notespayabletobanksandothers",
    title: "Notes Payable to Banks and Others",
    summaryAllocation: "notespayabletobanksandothers",
    catDate: "liaNotesPayableDate",
    fill: "#ED7272",
    // id: 2,
  },
  {
    category: "installmentAcc",
    url: "installmentsaccount",
    title: "Installment Account (Other)",
    summaryAllocation: "installmentsaccount",
    catDate: "liaInstallmentAccDate",
    fill: "#F29A9A",
    // id: 3,
  },
  {
    category: "unpaidTaxes",
    url: "unpaidtaxes",
    title: "Unpaid Taxes",
    summaryAllocation: "unpaidtaxes",
    catDate: "liaUnpaidDate",
    fill: "#F7C2C2",
    // id: 4,
  },
  {
    category: "mortgageOnRealestate",
    url: "mortgagesonrealestate",
    title: "Mortgages on Real Estate",
    summaryAllocation: "mortgagesonrealestate",
    catDate: "liaRealMortgageDate",
    fill: "#FAD7D7",
    // id: 5,
  },
  {
    category: "otherLiabilities",
    url: "otherliabilities",
    title: "Other Liabilities",
    summaryAllocation: "otherliabilities",
    catDate: "liaOtherLiaDate",
    fill: "#FCEBEB",
    // id: 6,
  },
];
const categoryIds = Object.freeze({
  asset_cash_on_hands: "-8", //
  asset_savings_account: "9",
  asset_retirement_account: "10",
  asset_notes_receivable: "11",
  asset_life_insurance: "12",
  asset_non_retirement_brokerage: "13",
  asset_businesses: "14", //
  asset_automobiles: "15", //
  asset_personal_property: "16",
  asset_real_estate: "17",
  asset_other_assets: "18",
  liability_accounts_payable: "1",
  liability_notes_payable: "2",
  liability_other_installment_accounts: "3",
  liability_unpaid_taxes: "4",
  liability_real_estate_mortgage: "5",
  liability_other_liability: "6",
  LiabilityFromAssets: "7",
  liability_installment_auto_acc: "19",
  liability_life_insurance: "20",
  LoanOnAutomobiles: "21",
});
const amortizationHeader = [
  { title: "Date", class: "th-mob-width", width: "180" },
  {
    title: "Principal",
    class: "amortization-principal th-mob-width text-end amort-alignment",
    width: "255",
  },
  {
    title: "+",
    class: "amortization-principal-icon th-mob-width",
    width: "26",
  },
  {
    title: "Interest",
    class: "amortization-interest th-mob-width text-end amort-alignment",
    width: "255",
  },
  { title: "=", class: "amortization-interest-icon th-mob-width", width: "26" },
  { title: "Payment", class: "th-mob-width text-end", width: "255" },
  {
    title: "Total Interest Paid",
    class: "th-mob-width text-end",
    width: "240",
  },
  {
    title: "Total Remaining Balance",
    class: "th-mob-width text-end",
    width: "240",
  },
  { title: "", class: "", width: "107" },
];

const retireType = [
  "Individual Retirement Arrangements (IRAs)",
  "Roth IRAs",
  "401(k) Plans",
  "SIMPLE 401(k) Plans",
  "403(b) Plans",
  "SIMPLE IRA Plans (Savings Incentive Match Plans for Employees)",
  "SEP Plans (Simplified Employee Pension)",
  "SARSEP Plans (Salary Reduction Simplified Employee Pension)",
  "Payroll Deduction IRAs",
  "Profit-Sharing Plans",
  "Defined Benefit Plans",
  "Money Purchase Plans",
  "Employee Stock Ownership Plans (ESOPs)",
  "Governmental Plans",
  "457 Plans",
  "Multiple Employer Plans",
  "Help with Choosing a Retirement Plan",
];

function localDataRemove() {
  localStorageClear();
}

const categoryId = {
  asset: {
    cashonhand: 8,
    savingaccount: 9,
    retirementaccount: 10,
    accountnotesreceviable: 11,
    lifeinsurance: 12,
    nonretirementbrokerage: 13,
    business: 14,
    automobile: 15,
    personalproperty: 16,
    realestate: 17,
    otherassets: 18,
  },
  liability: {
    lifeinsurance: 20,
    installmentsauto: 19,
    accountpayable: 1,
    notespayabletobanksandothers: 2,
    installmentsaccount: 3,
    unpaidtaxes: 4,
    mortgagesonrealestate: 5,
    otherliabilities: 6,
  },
};

const headingList = [
  {
    name: "Description",
    value: "description",
    filter: false,
    draggable: false,
  },
  { name: "Type", value: "type", filter: false, draggable: true },
  { name: "Symbol", value: "symbol", filter: false, draggable: true },
  { name: "Currency", value: "currency", filter: true, draggable: true },
  { name: "Price", value: "price", filter: false, draggable: true },
  { name: "Value", value: "value", filter: false, draggable: true },
  { name: "Quantity", value: "quantity", filter: false, draggable: true },
  {
    name: "Market Value",
    value: "market_value",
    filter: false,
    draggable: true,
  },
  {
    name: "Date Updated",
    value: "date_updated",
    filter: false,
    draggable: false,
  },
];

function nameInitials(first, last) {
  return (first && first.substring(0, 1)) + (last && last.substring(0, 1));
}

function capitalizeName(first) {
  return first.substring(0, 1).toUpperCase() + first.substring(1, first.lengt);
}

function formatFullName(firstName, lastName) {
  if (!firstName) {
    firstName = "";
  } else {
    firstName =
      firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
  }
  if (!lastName) {
    lastName = "";
  } else {
    lastName =
      lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();
  }
  return firstName + " " + lastName;
}

const plaidListObj = {
  assets: {
    newAcc: [
      "new_asset_cash_on_hands",
      "new_asset_savings_account",
      "new_asset_retirement_accounts",
      "new_asset_stocks_bonds",
    ],
    duplicateAcc: [
      "duplicate_asset_cash_on_hands",
      "duplicate_asset_savings_account",
      "duplicate_asset_retirement_accounts",
      "duplicate_asset_stocks_bonds",
    ],
  },
  liabilities: {
    newAcc: [
      "new_installmentAuto",
      "new_accoutPayable",
      "new_otherLiability",
      "new_realEstateMortgage",
      "new_notesPayable",
    ],
    duplicateAcc: [
      "duplicate_installmentAuto",
      "duplicate_accoutPayable",
      "duplicate_otherLiability",
      "duplicate_realEstateMortgage",
      "duplicate_notesPayable",
    ],
  },
};

const institutionColumn = {
  asset: {
    cashonhand: "institution",
    savingaccount: "institution",
    retirementaccount: "institution",
    accountnotesreceviable: "property_name",
    lifeinsurance: "company",
    nonretirementbrokerage: "institution",
    realestate: "line_address",
    automobile: "description",
    business: "entity_name",
    personalproperty: "description",
    otherassets: "description",
  },
  liability: {
    accountpayable: "creditor_name",
    notespayabletobanksandothers: "creditor_name",
    installmentsauto: "creditor_name",
    installmentsaccount: "creditor_name",
    lifeinsurance: "company",
    mortgagesonrealestate: "creditor_name",
    unpaidtaxes: "description",
    otherliabilities: "description",
  },
};

const quickBooksArr = [
  {
    title: "Profit & Loss",
    type: "profit_loss",
    img_name: "profit-and-loss-icon",
  },
  {
    title: "Balance Sheet",
    type: "balance_sheet",
    img_name: "balance-sheet-icon",
  },
  {
    title: "Statement of Cash Flows",
    type: "cash_flow",
    img_name: "cash-flows-icon",
  },
  {
    title: "Trial Balance",
    type: "trial_balance",
    img_name: "trial-balance-icon",
  },
];

const quickBooksColumnFilterArr = [
  "Total",
  "Days",
  "Week",
  "Month",
  "Quarter",
  "Year",
];

const quickBooksAnotherPeriodFilterArr = [
  "Previous Period (PP)",
  "Previous Year (PY)",
  "Year to Date (YTD)",
  "Previous Year to Date (PY YTD)",
];

const quickBooksReportPeriodFilterArr = [
  "All Dates", //0
  "Last Month", //1
  "Last Quarter", //2
  "Last Year", //3
  "Month to Date", //4
  "Quarter to Date", //5
  "Year to Date", //6
  "Custom", //7
];

const quickBooksMainLevelArr = [
  {
    title: "Assets",
    url: "/individual/assets",
  },
  {
    title: "Businesses",
    url: `/individual/assets/business`,
  },
];

const quickBooksAccountingMethodArr = ["Cash", "Accrual"];

export {
  quickBooksMainLevelArr,
  quickBooksAccountingMethodArr,
  quickBooksReportPeriodFilterArr,
  quickBooksAnotherPeriodFilterArr,
  quickBooksColumnFilterArr,
  quickBooksArr,
  plaidListObj,
  chase_and_charles_schwab,
  retireType,
  financeType,
  assetsCategory,
  liabilitiesCategory,
  lineQuarter,
  dashboardChartData,
  portFolioType,
  amortizationHeader,
  plaidRefreshArr,
  categoryId,
  categoryIds,
  headingList,
  institutionColumn,
  localDataRemove,
  nameInitials,
  capitalizeName,
  lineChartMonth,
  formatFullName,
};
