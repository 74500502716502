import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import LeftPart from "./LeftPart";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../slices/message";
import { confirmEmailVerification } from "../../slices/auth";
import LogoImage from "../logoImage";
import { useNavigate } from "react-router-dom";

const Success = () => {
  const { emailVerificationCode } = useParams();
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearMessage());
    dispatch(confirmEmailVerification({ emailVerificationCode }))
      .unwrap()
      .then(() => {
        localStorage.clear();
        sessionStorage.clear();
      })
      .catch(() => {});
  }, [dispatch]);

  function redirectToSignin() {
    navigate("/");
    localStorage.removeItem("user");
  }

  return (
    <section className="auth-section email-verify-success-page">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={6}>
            <LeftPart
              page="email-success"
              img="auth-image.svg"
              title="Your New Immersive Digital Finance Hub."
              text="Sync your real-time digital Personal Financial Statement with CapSync."
            />
          </Col>
          <Col lg={6}>
            <Row>
              <Col>
                <div className="auth-right-section">
                  <LogoImage />
                  <div className="auth-content-area">
                    {message && message.status === "Error" ? (
                      <h1 className="default-semi-bold-h1 cs-neutral-100 ">
                        {message.message}
                      </h1>
                    ) : (
                      <h1 className="default-semi-bold-h1 cs-neutral-100 ">
                        Your email has been verified
                      </h1>
                    )}
                    <>
                      <Button
                        type="button"
                        onClick={redirectToSignin}
                        className="cs-btn-primary lg-btn default-regular-h5"
                      >
                        Continue
                      </Button>
                    </>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Success;
