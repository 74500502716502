/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  NavDropdown,
  Row,
  Form,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// API
import {
  delete_file,
  encrypted_files_download,
  files_download,
  get_archive_file,
  scan_attachment,
  // restore_archive_file,
  searchByKeywordArchive,
} from "../../../slices/fileManagement.slice";
import { useTotalCategories } from "../../../slices/category.slice";

// HOOKS IMPORTS
import useDebounce from "../../../customHooks/useDebounce";

// COMPONENT IMPORTS
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import CapsyncLoader from "../../../commonComponent/CapsyncLoader";
import SkeletonFileManagement from "./SkeletonFileManagement";
import MultipleDeleteLoader from "./MultipleDeleteLoader";
import SkeletonArchiveTrash from "./SkeletonArchiveTrash";
import UnsupportedFile from "./UnsupportedFileModal";
import { fileDownload, getFileType } from "../../../util/common";
import UpgradeStorage from "./UpgradeStorage";
import GridTableView from "./GridTableView";
import FileViewModal from "./FileViewModal";
import TableView from "./TableView";
import useSortHeading from "../../../customHooks/useSortHeading";
import FileFolderMoveModal from "./FileFolderMoveModal";
import MoveAlertModal from "./MoveAlertModal";
import SortingIcon from "./GetSortingIcon";
import DownloadConfirmationModal from "./DownloadConfirmationModal";

const Archive = ({ CapsyncDeleteMessage }) => {
  const dispatch = useDispatch();

  const viewOnlyPermission = useSelector(
    (state) => state.notification.accessPermissionViewOnly
  );
  const { user } = useSelector((state) => state.auth);

  const fmListRef = useRef(null);
  const layoutRowRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const gridChildRef = useRef(null);
  const documentsRef = useRef(null);

  const totalCategories = useTotalCategories();
  const dataAsset = totalCategories.assets;
  const dataLiability = totalCategories.liabilities;
  const [fileStorageModal, setFileStorageModal] = useState(false);
  const [isBigFile, setIsBigFile] = useState(false);
  const [downloadItem, setDownloadItem] = useState(null);
  const [downloadConfirmationModalOpen, setDownloadConfirmationModalOpen] =
    useState(false);
  const [sortingHeaderName, setSortingHeaderName] = useState("dateArchived");
  const sortingLabel =
    sortingHeaderName === "name"
      ? "Name"
      : sortingHeaderName === "dateArchived"
      ? "Date Updated"
      : "";
  const [sortingHeader, setSortingHeader] = useState({
    name: false,
    dateArchived: false,
    fileSize: false,
  });
  const [unsupportedModal, setUnsupportedModal] = useState(false);
  const [isOpen, setIsOpen] = useState(
    JSON.parse(localStorage.getItem("cs-file-management-view-type"))
  );
  const [documents, setDocuments] = useState([]);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [previewMedia, setPreviewMedia] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [lastClickedIndex, setLastClickedIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState("All");
  const [isMulti, setIsMulti] = useState(false);
  const [moveModalOpen, setMoveModalOpen] = useState(null);
  const [moveContent, setMoveContent] = useState(null);
  const [moveSelectedContent, setMoveSelectedContent] = useState([]);
  const [openDuplicateModalOpen, setOpenDuplicateModalOpen] = useState(false);
  const [existingMoveFiles, setExistingMoveFiles] = useState([]);
  const [storageData, setStorageData] = useState({
    maxSpace: 0,
    usedSpace: 0,
  });

  const handleClick = (document, event, index) => {
    handleRowSelection(document, event, index);
  };

  const getArchiveFunction = async (lastDays = 60) => {
    try {
      const user_id = Number(
        JSON.parse(window.localStorage.getItem("pfs_access_id"))
      )
        ? Number(JSON.parse(window.localStorage.getItem("pfs_access_id")))
        : Number(JSON.parse(window.localStorage.getItem("id")));
      let payload = {
        userID: user_id,
        last_days: lastDays,
      };
      setLoading(true);
      setDeleteLoading(false);
      setSelectedRows([]);
      const response = await dispatch(get_archive_file(payload)).unwrap();
      if (response && response.code === 200) {
        setDocuments(response.data.files || []);
        documentsRef.current = response.data.files || [];
        setStorageData(response.data.storage || {});
      }
      setLoading(false);
    } catch (error) {
      console.log("error :>> ", error);
    } finally {
    }
  };

  const handleRowDoubleClick = (document) => {
    if (document && document.attachment) {
      const fileType = getFileType(document.name);
      setFileType(fileType);
      setPreviewMedia(document.attachment);
      setFileName(document.name);
      if (
        document.file_size <= process.env.REACT_APP_FILE_PREVIEW_SIZE_ALLOWED &&
        fileType !== "unknown"
      ) {
        setPreviewMedia(document.attachment);
        setFileName(document.name);
      } else {
        setUnsupportedModal(true);
        setPreviewMedia(null);
      }
    }
  };

  const searchFiles = async (params) => {
    setSelectedTitle("All");
    getArchiveFunction(60);
    const response = await dispatch(
      searchByKeywordArchive({ id: user.user.id, keyword: params })
    ).unwrap();
    if (response && response.code === 200) {
      setSearchedData(response.data);
    } else {
      setSearchedData([]);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    onHandleSearchData(event.target.value);
  };

  const onHandleSearchData = useDebounce((params) => {
    searchFiles(params);
  }, 500);

  const handleFileDelete = async (document) => {
    const ids = selectedRows.map((items) => items.id);
    const payload = {
      id: ids.length > 0 ? ids : [document.id],
      userId: user.user.id,
    };
    setDeleteLoading(true);

    await dispatch(delete_file(payload))
      .unwrap()
      .then((response) => {
        if (response) {
          // toast.warn("Deleted !!");
          if (ids.length <= 1) {
            toast.success("Your item has been deleted successfully.");
          } else {
            toast.success("Your items have been deleted successfully.");
          }
          getArchiveFunction();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const isSelected = (id) => selectedRows.some((e) => e.id === id);

  const handleRowSelection = (document, event, index) => {
    setOpenDropdownId(null);
    setSelectedRows((prevSelectedRows) => {
      let newSelectedRows = [...prevSelectedRows];
      if (event && event.shiftKey && lastClickedIndex !== null) {
        // Handle shift-click selection
        const start = Math.min(lastClickedIndex, index);
        const end = Math.max(lastClickedIndex, index);

        const selectedIndices = new Set();
        for (let i = start; i <= end; i++) {
          selectedIndices.add(i);
        }

        const uniqueIds = new Set(selectedRows.map((row) => row.id));
        const uniqueDocuments = documents.filter(
          (_, idx) =>
            selectedIndices.has(idx) && !uniqueIds.has(documents[idx].id)
        );

        newSelectedRows = [...selectedRows, ...uniqueDocuments];
      } else {
        // Handle normal click selection
        const existingIndex = newSelectedRows.findIndex(
          (e) => e.id === document.id
        );
        if (existingIndex !== -1) {
          newSelectedRows = newSelectedRows.filter(
            (_, i) => i !== existingIndex
          );
        } else {
          newSelectedRows.push(document);
        }
      }

      return newSelectedRows;
    });

    // Update last clicked index
    setLastClickedIndex(index);
  };
  const scanFiles = async (document, Ids = []) => {
    try {
      let payload = {
        id: [...Ids],
        user_id: user.user.id,
        category_id: document[0].category_id
          ? document[0].category_id
          : undefined,
      };

      let response = await dispatch(scan_attachment(payload)).unwrap();
      return response.data;
    } catch (error) {
      console.log("error", error.message);
    }
  };
  const handleFileDownload = async (document) => {
    if (storageData.usedSpace > storageData.maxSpace) {
      setFileStorageModal(true);
      return;
    }

    let Ids = document.map((val) => val.id);

    const scanResponse = await scanFiles(document, Ids);

    if (document.length === 1 && document[0].attachment !== null) {
      if (scanResponse.pending_files.length > 0) {
        toast.info(
          "Scanning process in progress please try this after some time."
        );
        setSelectedRows([]);
        return;
      } else if (
        scanResponse.infected_files.length > 0 ||
        scanResponse.not_scan_files.length > 0
      ) {
        setIsBigFile(scanResponse.not_scan_files.length > 0);
        setDownloadItem(document);
        setDownloadConfirmationModalOpen(true);
        return;
      } else {
        fileDownload(document[0]);
        return;
      }
    }

    let newIds = [];
    if (scanResponse.pending_files.length > 0) {
      toast.info(
        "Scanning process in progress please try this after some time."
      );
      setSelectedRows([]);
      return;
    } else if (scanResponse.infected_files.length > 0) {
      if (
        scanResponse.infected_files.length === 1 &&
        document.length === 1 &&
        document[0].attachment
      ) {
        setDownloadItem(document);
        setDownloadConfirmationModalOpen(true);
      } else if (document.length === 1 && !document[0].attachment) {
        let fileNames = scanResponse.infected_files
          .map((obj) => obj.name)
          .join(", ");
        toast.error(
          `${fileNames} This ${
            scanResponse.infected_files.length > 1 ? "Files" : "File"
          } can not be downloaded`
        );
        newIds = [...Ids];
      } else {
        let fileNames = scanResponse.infected_files
          .map((obj) => obj.name)
          .join(", ");
        toast.error(
          `${fileNames} This ${
            scanResponse.infected_files.length > 1 ? "Files" : "File"
          } can not be downloaded`
        );
        newIds = Ids.filter(
          (id) => !scanResponse.infected_files.some((obj) => obj.id === id)
        );
      }
    } else {
      newIds = [...Ids];
    }

    if (newIds.length === 0) {
      setSelectedRows([]);
      return;
    }

    let payload = {
      id: newIds,
      user_id: user.user.id,
      category_id: document[0].category_id
        ? document[0].category_id
        : undefined,
      is_encrypted: false,
    };

    try {
      let res = await dispatch(files_download(payload)).unwrap();
      if (res) {
        setSelectedRows([]);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };
  const handleProtectedFileDownload = async (document) => {
    if (storageData.usedSpace > storageData.maxSpace) {
      setFileStorageModal(true);
      return;
    }

    let Ids = document.map((val) => val.id);

    const scanResponse = await scanFiles(document, Ids);

    let newIds = [];
    if (scanResponse.pending_files.length > 0) {
      toast.info(
        "Scanning process in progress please try this after some time."
      );
      setSelectedRows([]);
      return;
    } else if (scanResponse.infected_files.length > 0) {
      let fileNames = scanResponse.infected_files
        .map((obj) => obj.name)
        .join(", ");
      toast.error(
        `${fileNames} This ${
          scanResponse.infected_files.length > 1 ? "Files" : "File"
        } can not be downloaded`
      );
      newIds = Ids.filter(
        (id) => !scanResponse.infected_files.some((obj) => obj.id === id)
      );
    } else {
      newIds = [...Ids];
    }

    if (newIds.length === 0) {
      setSelectedRows([]);
      return;
    }

    let payload = {
      id: newIds,
      user_id: user.user.id,
      category_id: document[0].category_id
        ? document[0].category_id
        : undefined,
      is_encrypted: true,
    };
    try {
      let res = await dispatch(encrypted_files_download(payload)).unwrap();
      if (res) {
        setSelectedRows([]);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
    }
  };

  useEffect(() => {
    getArchiveFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSortHeading(sortingHeaderName, sortingHeader, setDocuments, documents);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isClickInsideElement = (target) => {
        const click =
          documentsRef &&
          documentsRef.current &&
          documentsRef.current.find((data, index) => {
            const element = document.getElementById(`grid-${index}`);
            return element && element.contains(target);
          });
        return !click;
      };
      if (
        isClickInsideElement &&
        isClickInsideElement.length &&
        isClickInsideElement(event.target) &&
        layoutRowRef.current &&
        !layoutRowRef.current.contains(event.target) &&
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target)
      ) {
        setSelectedRows([]);
        setOpenDropdownId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [fmListRef, layoutRowRef, mobileMenuRef, gridChildRef]);

  // Array of dropdown menu items
  const trashViewMenuArray = [
    {
      title: "All",
      click: () => handleMenuItemClick(60, "All"),
    },
    { title: "Today", click: () => handleMenuItemClick(1, "Today") },
    {
      title: "Last 7 Days",
      click: () => handleMenuItemClick(7, "Last 7 Days"),
    },
    {
      title: "Last 30 Days",
      click: () => handleMenuItemClick(30, "Last 30 Days"),
    },
  ];
  const sortingDropDown = [
    {
      title: "Name",
      click: () => {
        setSortingHeaderName("name");
      },
    },
    {
      title: "Date Updated",
      click: () => {
        setSortingHeaderName("dateArchived");
      },
    },
  ];

  const handleMenuItemClick = (days, title) => {
    setSearchTerm("");
    getArchiveFunction(days);
    setSelectedTitle(title); // Update the dropdown title
  };
  return (
    <React.Fragment>
      {dataAsset &&
      dataLiability &&
      (dataAsset.grandTotal !== 0 || dataLiability.grandTotal !== 0) &&
      (Object.keys(dataAsset).length !== 0 ||
        Object.keys(dataLiability).length !== 0) ? (
        <div className="file-management-section archive-page">
          {previewMedia && (
            <FileViewModal
              isOpen={!!previewMedia}
              onClose={() => setPreviewMedia(null)}
              file={previewMedia}
              fileName={fileName}
              fileType={fileType}
            />
          )}
          {unsupportedModal && (
            <UnsupportedFile
              unsupportedModal={unsupportedModal}
              setUnsupportedModal={setUnsupportedModal}
              type="archive"
            />
          )}
          <Container fluid>
            <Row>
              <Col lg={12}>
                <div className="cs-section-topbar">
                  <Row>
                    <Col>
                      <div className="cs-section-title">
                        <h1 className="default-semi-bold-h1 cs-neutral-100">
                          Archive
                        </h1>
                      </div>
                    </Col>
                    <Col>
                      <div className="cs-search-bar global-search-box">
                        <Dropdown className="cs-form-dropdown">
                          <Dropdown.Toggle>
                            <Form.Control
                              type="text"
                              className={searchTerm && "cs-input-field-active"}
                              value={searchTerm}
                              onChange={handleSearch}
                              placeholder="Search"
                            />
                            {searchTerm === "" ? (
                              <span className="input-field-icon cs-neutral-70">
                                <CapsyncIcon
                                  title="search-outlined"
                                  size="18"
                                />
                              </span>
                            ) : (
                              <span
                                className="input-field-icon cs-neutral-70"
                                onClick={() => {
                                  setSearchTerm("");
                                }}>
                                <CapsyncIcon title="close-outlined" size="14" />
                              </span>
                            )}
                          </Dropdown.Toggle>
                        </Dropdown>
                      </div>
                    </Col>
                    <Col>
                      <div className="for-des">
                        <div className="layout-row" ref={layoutRowRef}>
                          <div className="layout-box">
                            {!viewOnlyPermission &&
                            selectedRows.length &&
                            selectedRows.length > 0 ? (
                              <div className="for-des">
                                <div className="cs-action-btn">
                                  <span
                                    className="cs-primary"
                                    title="Close"
                                    onClick={() => setSelectedRows([])}>
                                    <CapsyncIcon
                                      title="close-outlined"
                                      size="14"
                                    />
                                  </span>
                                  <span
                                    className="cs-neutral-80"
                                    title="Download"
                                    onClick={() => {
                                      handleFileDownload(selectedRows);
                                    }}>
                                    <CapsyncIcon
                                      title="download-outlined"
                                      size="18"
                                    />
                                  </span>
                                  {/* <span
                                    className="cs-neutral-80"
                                    title="Protect Download"
                                    onClick={() => {
                                      handleProtectedFileDownload(selectedRows);
                                    }}>
                                    <CapsyncIcon
                                      title="protect-download-outlined"
                                      size="18"
                                    />
                                  </span> */}
                                  <span
                                    className="cs-neutral-80"
                                    title="Move"
                                    onClick={() => {
                                      setIsMulti(true);
                                      setMoveModalOpen(true);
                                      setMoveSelectedContent(selectedRows);
                                    }}>
                                    <CapsyncIcon
                                      title="move-outlined"
                                      size="18"
                                    />
                                  </span>
                                  <span
                                    className="cs-danger"
                                    title="Delete"
                                    // onClick={handleFileDelete}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      CapsyncDeleteMessage(
                                        true,
                                        "",
                                        `Are you sure you want to delete this items? You can restore it later from the trash. Please confirm if you wish to proceed.`,
                                        () => handleFileDelete()
                                      );
                                    }}>
                                    <CapsyncIcon
                                      title="delete-outlined"
                                      size="18"
                                    />
                                  </span>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          {!viewOnlyPermission &&
                          selectedRows.length &&
                          selectedRows.length > 0 ? (
                            <div className="for-des">
                              {/* For laptop screen start */}

                              <div className="cs-lp-screen file-management-dropdown">
                                <NavDropdown
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                  title={
                                    <span
                                      className="cs-neutral-80 cursor-pointer"
                                      title="More Action">
                                      <CapsyncIcon
                                        title="option-horizontal-filled"
                                        size="18"
                                      />
                                    </span>
                                  }
                                  className="cs-settings-dropdown">
                                  <NavDropdown.Item
                                    title="Download"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleFileDownload(selectedRows);
                                    }}>
                                    <span>
                                      <CapsyncIcon
                                        title="download-outlined"
                                        size="22"
                                      />
                                    </span>
                                    Download
                                  </NavDropdown.Item>
                                  {/* <NavDropdown.Item
                                    title="Protect Download"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleProtectedFileDownload(selectedRows);
                                    }}
                                  >
                                    <span>
                                      <CapsyncIcon
                                        title="protect-download-outlined"
                                        size="22"
                                      />
                                    </span>
                                    Protect Download
                                  </NavDropdown.Item> */}
                                  <NavDropdown.Item
                                    title="Move"
                                    onClick={() => {
                                      setIsMulti(true);
                                      setMoveModalOpen(true);
                                      setMoveSelectedContent(selectedRows);
                                    }}>
                                    <span>
                                      <CapsyncIcon
                                        title="move-outlined"
                                        size="22"
                                      />
                                    </span>
                                    Move
                                  </NavDropdown.Item>
                                  <NavDropdown.Item
                                    title="Delete"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      CapsyncDeleteMessage(
                                        true,
                                        "",
                                        `Are you sure you want to delete this items? You can restore it later from the trash. Please confirm if you wish to proceed.`,
                                        () => handleFileDelete()
                                      );
                                    }}>
                                    <span>
                                      <CapsyncIcon
                                        title="delete-outlined"
                                        size="22"
                                      />
                                    </span>
                                    Delete
                                  </NavDropdown.Item>
                                </NavDropdown>
                              </div>
                              {/* For laptop screen start */}
                            </div>
                          ) : null}
                          <div
                            className="layout-btn cursor-pointer"
                            onClick={() => {
                              setIsOpen(!isOpen);
                              localStorage.setItem(
                                "cs-file-management-view-type",
                                JSON.stringify(!isOpen)
                              );
                            }}
                            title={!isOpen ? "Grid View" : "List View"}>
                            <span className="grid-view cs-neutral-80">
                              {!isOpen && (
                                <CapsyncIcon
                                  title="icon-view-outlined"
                                  size="18"
                                />
                              )}
                              {isOpen && (
                                <CapsyncIcon
                                  title="list-view-outlined"
                                  size="18"
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={12}>
                <>
                  <div
                    className="cs-trash-dropdown cs-common-add-dropdown"
                    ref={mobileMenuRef}>
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-basic">
                        <span>{selectedTitle}</span>
                        <span className="cs-neutral-100">
                          <CapsyncIcon
                            title="chevron-down-outlined"
                            size="12"
                          />
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {trashViewMenuArray.map((item, index) => (
                          <Dropdown.Item key={index} onClick={item.click}>
                            {item.title}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    {isOpen && (
                      <div className="sorting-dropdown">
                        <span
                          onClick={() => {
                            sortingHeaderName == "name"
                              ? setSortingHeader({
                                  name: !sortingHeader.name,
                                  modifiedBy: false,
                                  dateUpdated: false,
                                  fileSize: false,
                                })
                              : setSortingHeader({
                                  name: false,
                                  modifiedBy: false,
                                  dateArchived: !sortingHeader.dateArchived,
                                  fileSize: false,
                                });
                          }}
                          className="sorting-icons">
                          {sortingHeaderName == "name" &&
                            (sortingHeader.name ? (
                              <span className="cs-neutral-80">
                                <CapsyncIcon
                                  title="arrow-up-outlined"
                                  size="18"
                                />
                              </span>
                            ) : (
                              <span className="cs-neutral-80">
                                <CapsyncIcon
                                  title="arrow-down-outlined"
                                  size="18"
                                />
                              </span>
                            ))}
                          {sortingHeaderName == "dateArchived" &&
                            (sortingHeader.dateArchived ? (
                              <span className="cs-neutral-80">
                                <CapsyncIcon
                                  title="arrow-up-outlined"
                                  size="18"
                                />
                              </span>
                            ) : (
                              <span className="cs-neutral-80">
                                <CapsyncIcon
                                  title="arrow-down-outlined"
                                  size="18"
                                />
                              </span>
                            ))}
                        </span>
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic">
                            <span>{sortingLabel}</span>
                            <span className="cs-neutral-100">
                              <CapsyncIcon
                                title="chevron-down-outlined"
                                size="12"
                              />
                            </span>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {sortingDropDown.map((item, index) => {
                              return (
                                <Dropdown.Item
                                  key={index}
                                  onClick={item.click}
                                  className={
                                    item.title.toLocaleLowerCase() !==
                                    sortingLabel.toLocaleLowerCase()
                                      ? "drop-item-icon"
                                      : "cs-selected-dropdown"
                                  }>
                                  <SortingIcon
                                    headerName={sortingHeaderName}
                                    sortingHeader={sortingHeader}
                                    itemTitle={item.title}
                                    sortingLabel={sortingLabel}
                                  />
                                  {item.title}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                    <div className="for-mob">
                      {selectedRows.length && selectedRows.length > 0 ? (
                        <div className="">
                          {/* For laptop screen start */}

                          <div className="cs-lp-screen file-management-dropdown">
                            <NavDropdown
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              title={
                                <span
                                  className="cs-neutral-80 cursor-pointer"
                                  title="More Action">
                                  <CapsyncIcon
                                    title="option-horizontal-filled"
                                    size="18"
                                  />
                                </span>
                              }
                              className="cs-settings-dropdown">
                              <NavDropdown.Item
                                title="Move"
                                onClick={() => {
                                  setIsMulti(true);
                                  setMoveModalOpen(true);
                                  setMoveSelectedContent(selectedRows);
                                }}>
                                <span>
                                  <CapsyncIcon
                                    title="move-outlined"
                                    size="22"
                                  />
                                </span>
                                Move
                              </NavDropdown.Item>
                              <NavDropdown.Item
                                title="Delete"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  CapsyncDeleteMessage(
                                    true,
                                    "",
                                    `Are you sure you want to permanently delete this item? This action cannot be undone and the item cannot be restored. Please confirm if you wish to proceed.`,
                                    () => handleFileDelete()
                                  );
                                }}>
                                <span>
                                  <CapsyncIcon
                                    title="delete-outlined"
                                    size="22"
                                  />
                                </span>
                                Delete
                              </NavDropdown.Item>
                              {/* {isMobile && (
                                <NavDropdown.Item
                                  className=""
                                  title="Close"
                                  onClick={() => setSelectedRows([])}>
                                  <span>
                                    <CapsyncIcon
                                      title="close-outlined"
                                      size="14"
                                    />
                                  </span>
                                  Close
                                </NavDropdown.Item>
                              )} */}
                            </NavDropdown>
                          </div>
                          {/* For laptop screen start */}
                        </div>
                      ) : null}
                      <div
                        className="layout-btn cursor-pointer"
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}
                        title={!isOpen ? "Grid View" : "List View"}>
                        <span className="grid-view cs-neutral-80">
                          {!isOpen && (
                            <CapsyncIcon title="icon-view-outlined" size="18" />
                          )}
                          {isOpen && (
                            <CapsyncIcon title="list-view-outlined" size="18" />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <span className="cs-label default-regular-h4">
                    {selectedTitle}
                  </span>
                  {documents && documents.length > 0 ? (
                    <React.Fragment>
                      {!isOpen && (
                        <>
                          {loading ? (
                            <CapsyncLoader size={50} />
                          ) : (
                            <TableView
                              type="archive"
                              data={
                                searchTerm === "" ? documents : searchedData
                              }
                              handleRowDoubleClick={handleRowDoubleClick}
                              viewOnlyPermission={viewOnlyPermission}
                              fmListRef={fmListRef}
                              handleProtectedFileDownload={
                                handleProtectedFileDownload
                              }
                              handleFileDownload={handleFileDownload}
                              storage={storageData}
                              sortingHeader={sortingHeader}
                              sortingHeaderName={sortingHeaderName}
                              setSortingHeader={setSortingHeader}
                              setSortingHeaderName={setSortingHeaderName}
                              handleRestore={(data) => {
                                setIsMulti(false);
                                setMoveModalOpen(true);
                                setMoveContent(data);
                              }}
                              isSelected={isSelected}
                              selectedRows={selectedRows}
                              CapsyncDeleteMessage={CapsyncDeleteMessage}
                              handleClick={handleClick}
                              handleFileDelete={handleFileDelete}
                            />
                          )}
                        </>
                      )}
                      {isOpen && (
                        <>
                          {loading ? (
                            <CapsyncLoader size={50} />
                          ) : (
                            <GridTableView
                              type="archive"
                              data={
                                searchTerm === "" ? documents : searchedData
                              }
                              handleRowDoubleClick={handleRowDoubleClick}
                              isSelected={isSelected}
                              handleRestore={(data) => {
                                setIsMulti(false);
                                setMoveModalOpen(true);
                                setMoveContent(data);
                              }}
                              handleProtectedFileDownload={
                                handleProtectedFileDownload
                              }
                              handleFileDownload={handleFileDownload}
                              storage={storageData}
                              viewOnlyPermission={viewOnlyPermission}
                              setOpenDropdownId={setOpenDropdownId}
                              openDropdownId={openDropdownId}
                              selectedRows={selectedRows}
                              CapsyncDeleteMessage={CapsyncDeleteMessage}
                              handleClick={handleClick}
                              handleFileDelete={handleFileDelete}
                            />
                          )}
                        </>
                      )}
                    </React.Fragment>
                  ) : loading ? (
                    <CapsyncLoader size={50} />
                  ) : (
                    <div className="manage-file-container no-records">
                      <label className="cs-upload-files default-regular-h4">
                        We don't have any active records to display
                      </label>
                    </div>
                  )}
                </>
              </Col>
              <Col lg={12}>
                {!loading && (
                  <UpgradeStorage
                    onSuccess={getArchiveFunction}
                    storage={storageData}
                    setShowLimitModal={setFileStorageModal}
                    showLimitModal={fileStorageModal}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <React.Fragment>
          {documents && documents.length === 0 ? (
            <SkeletonArchiveTrash />
          ) : (
            <SkeletonFileManagement isOpen={isOpen} />
          )}
        </React.Fragment>
      )}

      {deleteLoading && (
        <MultipleDeleteLoader deleteLoading={deleteLoading} isDelete={true} />
      )}
      {moveModalOpen && (
        <FileFolderMoveModal
          moveModalOpen={moveModalOpen}
          moveContent={moveContent}
          isMulti={isMulti}
          moveSelectedContent={moveSelectedContent}
          setOpenDuplicateModalOpen={setOpenDuplicateModalOpen}
          setExistingMoveFiles={setExistingMoveFiles}
          closeModal={(callListApi = true) => {
            callListApi && getArchiveFunction();
            setMoveModalOpen(false);
            setIsMulti(false);
            setMoveContent(null);
            setMoveSelectedContent([]);
          }}
        />
      )}
      {openDuplicateModalOpen && (
        <MoveAlertModal
          openDuplicateModalOpen={openDuplicateModalOpen}
          existingMoveFiles={existingMoveFiles}
          closeModal={() => setOpenDuplicateModalOpen(false)}
        />
      )}
      {downloadConfirmationModalOpen && (
        <DownloadConfirmationModal
          downloadConfirmationModalOpen={downloadConfirmationModalOpen}
          isBigFile={isBigFile}
          closeModal={() => {
            setDownloadConfirmationModalOpen(false);
            setSelectedRows([]);
            setDownloadItem(null);
            setIsBigFile(false);
          }}
          downloadItem={downloadItem}
        />
      )}
    </React.Fragment>
  );
};

export default Archive;
