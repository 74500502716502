import CryptoJS from "crypto-js";

function EncryptReact(params) {
    const fetchData = CryptoJS.AES.encrypt(
        params,
        process.env.REACT_APP_ENCRYPT_DECRYPT
    ).toString();

    return fetchData;
}

function DecryptReact(params) {
    const bytes = CryptoJS.AES.decrypt(
        params,
        process.env.REACT_APP_ENCRYPT_DECRYPT
    );
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

    return decryptedData;
}

function DecryptFilePassword(encryptedPassword) {
    try {
        let data = atob(encryptedPassword, 'base64').toString('ascii');
        data = atob(data, 'base64').toString('ascii');
        data = atob(data, 'base64').toString('ascii');
        return data;
      } catch (err) {
        console.log('err', err);
        return err;
      }
}

export { EncryptReact, DecryptReact, DecryptFilePassword }