import React from "react";
import { Button, Image } from "react-bootstrap";
import ErrorValidation from "../../../../../assets/images/other/error-validation.svg";

const ErrorPopup = ({ params, tryAgain, navigateToDashboard }) => {
  return (
    <>
      <div className="default-semi-bold-h3 modal-header">Plaid error</div>
      <span></span>
      <div className="plaid-error-content">
        <span>
          <Image
            src={ErrorValidation}
            alt="Error Validation"
            width={100}
            height={100}
          />
        </span>
        <p
          className="pay-text default-regular-body-text-m cs-neutral-90"
          key={"danger"}>
          Plaid error:- {params}!
        </p>
      </div>
      <div className="modal-footer cs-modal-btn cs-center-btn ">
        <Button
          className="cs-btn-secondary md-btn default-regular-h5"
          onClick={navigateToDashboard}>
          Cancel
        </Button>
        <Button
          onClick={tryAgain}
          className="cs-btn-primary md-btn default-regular-h5">
          Try again
        </Button>
      </div>
    </>
  );
};

export default ErrorPopup;
