import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    avatarState: "",
};

const avatarSlice = createSlice({
  name: "Avatar",
  initialState,
  reducers: {
    setAvatarState: (state, action) => {
      state.avatarState = action.payload;
      },
      
      
  },
});

// Export actions
export const { setAvatarState } = avatarSlice.actions;

// Export reducer
export default avatarSlice.reducer;