import React from "react";
import SubMenuHeader from "../SubMenuHeader";
import { Form } from "react-bootstrap";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import SubMethodsList from "../../SubMethodsList";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import { Link } from "react-router-dom";
import CapsyncToolTip from "../../../../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import NumberFormat from "react-currency-format";
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";


// sequence No. three  (Asmita date-29th June => Do not remove this line)
const MethodIdThree = (props) => {


  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Accredited Investor",
      url: `/individual/verifyinvestor/accreditedInvestor/${props.verificationType}/${props.unique_code}`,
    },
  ];



  return (
    <>
      {props.is_loader.assets === true || props.is_loader.liabilities === true ? <SkeletonAllSubMethods /> :
        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="Net Worth"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>
              <SubMenuHeader />
            </div>
            <div className="method-section">
              <div className="default-regular-body-text-l cs-neutral-70"> <SubMethodsList methodId={3.1} /></div>
              <div className="cs-divider"></div>
              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert} />

                <div className="row-spacing">
                  <p className="default-regular-body-text-l cs-neutral-70">
                    In order to verify your accreditation we need you to upload
                    evidence. Please upload the appropriate files below and black-out
                    any sensitive information.
                  </p>
                  <p className="default-regular-body-text-l cs-neutral-70">
                    All evidence (statements/certificates, etc.) must be
                    no older than 90 days with limited exceptions (for
                    example, older documents evidencing ownership, tax returns, etc.).
                  </p>
                </div>
                {props.setShowTrustUploadBlock && <span>
                  <Form.Label className="default-regular-sub-heading-s cs-neutral-90">Please upload evidence of who all the equity owners of the trust are (by uploading your trust documentation or this <Link className="cs-neutral-100" to="https://s3.amazonaws.com/static-capsync.com/Verify-Investor-files/All-Owners-Accredited-Officer-Certificate.pdf" target="_blank"><b>Officer’s Certificate</b></Link> along with a document to show ownership percentages). In a revocable trust, typically, the grantors are the equity owners.</Form.Label>
                  <UploadFileBlock
                    files={props.files2}
                    setOpenModal={props.setOpenModal2}
                    mediaAlert={props.mediaAlert}
                    deleteFile={props.deleteFile2}
                    showFile={props.showFile2}
                    pfsLink={props.pfsLink}
                    pfsName={props.pfsName}
                    pfsDelete={props.pfsDelete}
                    showDeleteSpinner={props.showDeleteSpinner}
                    showPfsLink={props.showPfsLink2} />
                </span>}
                <p className="default-regular-body-text-xl cs-neutral-90 row-spacing">Individual Net Worth Assessment</p>



                <div className="cs-divider"></div>
                <div className="default-regular-body-text-l accredited-content-box method-spacing">
                  <p className="cs-neutral-90">Upload Recent Credit Report</p>
                  <p><CapsyncToolTip
                    width="640px"
                    Child={<span className="cs-neutral-70">Why do I need this? </span>}
                    placement={"right"}
                    type="text"
                    message={<div>
                      <p>  Kindly note that investors must provide their credit report for their net worth verification
                        according to Rule 506C from Regulation D on the SEC website as written below (see link
                        here for a full description of the law). If this is not provided, then, unfortunately, under US
                        federal laws, our reviewing attorneys may not be able to verify you as an accredited investor
                        through this verification method.</p> <br />

                      <p>   "(B) In regard to whether the purchaser is an accredited investor on the basis of net worth,
                        reviewing one or more of the following types of documentation dated within the prior three
                        months and obtaining a written representation from the purchaser that all liabilities
                        necessary to make a determination of net worth have been</p>
                    </div>}
                  />
                    <span className="cs-danger">This does not affect your credit score or security freezes on your credit.</span>
                  </p>
                  <p className="cs-neutral-70">
                    You may obtain your credit report from one of the following options:
                  </p>
                  <ol className="cs-neutral-70">
                    <li>Order one free credit report once a year from any of the credit reporting bureaus from <Link to="https://www.annualcreditreport.com" target="_blank"><u className="cs-neutral-90">https://www.annualcreditreport.com</u></Link>. On that site, we recommend requesting your report from either Equifax or TransUnion where you can download a PDF copy.</li>
                    <li>Order credit report from <Link to="https://connect.experian.com/register/personal.html?c=ptnr-vrfyinv&sg=bs&m=lk&a=verify-investor" target="_blank" ><u className="cs-neutral-90">Experian Connect℠</u></Link>.</li>
                  </ol>
                </div>

                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Upload your credit report below from options 1 or 2
                </Form.Label>
                <UploadFileBlock
                  files={props.files}
                  setOpenModal={props.setOpenModal}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile}
                  showFile={props.showFile}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink}
                  uploadError={props.uploadError}

                />

                <div className="row-spacing">
                  <u className="default-regular-body-text-l cs-neutral-70">If a US credit report exists for you, you must provide it. Most people that live in the US or have assets in the US will have a US credit report. If you do not have a US credit report, upload a credit report equivalent from your own country. If verifying together with a spouse or spousal equivalent, both individuals must provide a credit report.</u>

                </div>
                <Form.Check
                  type="checkbox"
                  id="credit-checkbox"
                  aria-label="option 1"
                  name="certify"
                  className={props.isCheckedError ? "cs-form-check-box investor-error" : "cs-form-check-box"}
                  checked={props.isChecked}
                  onChange={props.handleCheckbox}
                  label={<span className="default-regular-sub-heading-s cs-neutral-90 cursor-pointer">I certify that no credit report is available.</span>}
                />
                {props.isCheckedError && <span className="default-regular-sub-heading-xs cs-danger">Upload a recent credit report, or certify that no credit report is available.</span>}
                <div className="cs-divider"></div>
                <div className="row-spacing net-worth-asset-libility">
                  <p className="default-regular-body-text-xl cs-neutral-90">Claimed Net Worth:
                    $<NumberFormat
                      value={(props.dataAssetTotal - props.dataLiabilityTotal).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    /> <span>USD</span>
                    <span className="net-worth-info-icon">
                      <CapsyncToolTip
                        Child={"info-outlined"}
                        width="398px"
                        placement={"bottom"}
                        type="icon"
                        size="18"
                        message={"Please Refer to your portfolio for more details on net worth."}
                      />
                    </span>
                    {props.uploadFileOneError && <p className="default-regular-sub-heading-xs cs-danger">{props.showError}</p>}
                  </p>
                  <p className="default-regular-body-text-xl cs-neutral-90">Claimed Net Worth (for Verification):
                    $<NumberFormat
                      value={(props.dataAssetTotal - props.dataLiabilityTotal).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                    /> <span>USD</span>
                    <span className="net-worth-info-icon">
                      <CapsyncToolTip
                        Child={"info-outlined"}
                        placement={"bottom"}
                        width="398px"
                        type="icon"
                        size="18"
                        message={"Please Refer to your portfolio for more details on net worth."}
                      />
                    </span>
                  </p>
                </div>
                <NotesBlock setNotesValue={props.setNotesValue} />
              </Form>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default MethodIdThree;
