/* eslint-disable */
import React, { useState } from "react";
import {
  Button,
  CloseButton,
  Col,
  Image,
  Modal,
  Row,
  Form,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Actiondelete from "../../../../assets/icons/svg/outlined/delete-outlined.svg";
import {
  wealth_individuals_exist,
  wealth_companies_add_partner,
} from "../../../slices/wealthManager.slice";
import CapsyncLoader from "../../../../assets/images/capsync-loader.gif";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";

// using this modal to add more individuals to a particular company
const AddIndividual = ({
  addIndividualModalState,
  changeAddIndividualModalState,
  fetchAllCompanies,
  toastMessage,
  companyPartners,
}) => {
  const { user } = useSelector((state) => state.auth);
  const user_id = user && user.user.id;
  const user_displayName = user && user.user.displayName;
  const user_email = user && user.user.email;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [partners, setPartners] = useState([
    { email: "", firstName: "", lastName: "", id: 0 },
  ]);

  const [showLoader, setShowLoader] = useState(false);

  const [validation, setValidation] = useState({
    msg: "",
    color: "",
  });

  const addFields = () => {
    setShowLoader(true);

    const isAllFieldsFilled = partners.every(
      (partner) =>
        partner.email.trim() !== "" &&
        partner.firstName.trim() !== "" &&
        partner.lastName.trim() !== ""
    );

    setTimeout(() => {
      if (isAllFieldsFilled) {
        setShowLoader(false);
        setPartners([
          ...partners,
          { email: "", firstName: "", lastName: "", id: 0 },
        ]);
      } else {
        setValidation({
          msg: "*Please fill all partner details",
          color: "text-danger",
        });
      }
    }, 500);
  };

  const fieldChange = (val, params, type) => {
    let inputData = [...partners];
    setValidation({
      msg: "",
      color: "",
    });
    if (type === "email") {
      inputData[params].email = val;
      // Check email format validity
      const isValidEmail =
        // /^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}(?![0-9])$/.test(val);
        /^[a-z0-9._%+-]{1,30}@[a-z0-9.-]+\.[a-z]{2,}$/.test(val);
      const checkSpace = /\s/.test(val);

      if (val === user_email) {
        inputData[params].emailError = "Can not send invitation to yourself";
      } else if (checkSpace) {
        inputData[params].emailError =
          "Avoid leading or trailing whitespace";
      } else {
        inputData[params].emailError = isValidEmail
          ? ""
          : "Invalid email format";
      }
      setPartners(inputData);
    } else if (type === "firstName") {
      inputData[params].firstName = val;
      const hasLeadingTrailingWhitespace = /^\s|\s$/.test(val);
      const hasNonWhitespaceCharacter = /\S/.test(val);

      if (hasLeadingTrailingWhitespace || !hasNonWhitespaceCharacter) {
        inputData[params].fnameError =
          "Avoid leading or trailing whitespace";
      } else {
        inputData[params].fnameError = "";
      }
      setPartners(inputData);
    } else if (type === "lastName") {
      inputData[params].lastName = val;
      const hasLeadingTrailingWhitespace = /^\s|\s$/.test(val);
      const hasNonWhitespaceCharacter = /\S/.test(val);

      if (hasLeadingTrailingWhitespace || !hasNonWhitespaceCharacter) {
        inputData[params].lnameError =
          "Avoid leading or trailing whitespace";
      } else {
        inputData[params].lnameError = "";
      }
      setPartners(inputData);
    } else {
      inputData[params].lastName = val;
      setPartners(inputData);
    }
  };

  const checkUserExist = (val, params) => {
    let inputData = [...partners];
    if (val !== "") {
      dispatch(wealth_individuals_exist({ val: val }))
        .unwrap()
        .then((response) => {
          inputData[params].id = response.user_id;
          setPartners(inputData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const removeField = (params) => {
    if (partners.length > 1) {
      let fetchData = [...partners];
      fetchData.splice(params, 1);
      setPartners(fetchData);
    }
  };

  const sendInvitation = () => {
    let flag = 0;
    let fetchRawPartners = [];

    partners.forEach((new_itr) => {
      fetchRawPartners.push(new_itr.email);
    });

    let emptyArr = [];
    companyPartners.map((user) => {
      emptyArr.push(user.email);
    });

    let findCommonPartner = emptyArr.filter((item) => {
      return fetchRawPartners.includes(item);
    });

    if (findCommonPartner.length !== 0) {
      setValidation({
        msg: `${findCommonPartner} already exists.`,
        color: "text-danger",
      });
      return false;
    } else {
      setValidation({
        msg: "*Some of the fields are empty",
        color: "text-danger",
      });
    }

    let dummyfetchRawPartners = new Set(fetchRawPartners);
    if (Array.from(dummyfetchRawPartners).length !== fetchRawPartners.length) {
      setValidation({
        msg: "*You cannot add same email more than once.",
        color: "text-danger",
      });
      return false;
    }

    partners.forEach((itr) => {
      if (itr.email === "" || itr.firstName === "" || itr.lastName === "") {
        flag++;
      }
    });

    // Check for invalid emails
    const invalidEmails = partners.some(
      (partner) => partner.emailError && partner.emailError !== ""
    );

    if (invalidEmails) {
      flag++;
    }

    // Check for invalid First Name
    const invalidFName = partners.some(
      (partner) => partner.fnameError && partner.fnameError !== ""
    );

    if (invalidFName) {
      flag++;
    }
    // Check for invalid Last Name
    const invalidLName = partners.some(
      (partner) => partner.lnameError && partner.lnameError !== ""
    );

    if (invalidLName) {
      flag++;
    }

    let storeData = {
      partners: partners,
      companyId: addIndividualModalState.companyId,
      user_id: user_id,
      request_send_by_displayName: user_displayName,
      request_send_by_email: user_email,
    };

    if (flag === 0) {
      setValidation({
        msg: "",
        color: "",
      });
      setLoading(true);
      dispatch(wealth_companies_add_partner(storeData))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            fetchAllCompanies();
            changeAddIndividualModalState(false, true);
            toastMessage(response.message, "success");
            setPartners([{ email: "", firstName: "", lastName: "" }]);
            setValidation({
              msg: "",
              color: "",
            });
            setLoading(false);
          } else {
            setLoading(false);
            toastMessage(err.message, "error");
          }
        })
        .catch((err) => {
          setLoading(false);
          toastMessage(err.message, "error");
        });
    }
  };

  const closeModal = () => {
    changeAddIndividualModalState(false, true);
    setPartners([{ email: "", firstName: "", lastName: "" }]);
    setValidation({
      msg: "",
      color: "",
    });
  };

  return (
    <>
      <Modal
        show={addIndividualModalState.show}
        onHide={!addIndividualModalState.show}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">Add Partners</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {showLoader === true ? (
            <>
              <div className="text-center initial-modal-loader">
                <Image src={CapsyncLoader} alt="loadergif" className="w-50" />
              </div>
            </>
          ) : (
            <>
              {partners.map((iterator, index) => {
                return (
                  <>
                    <Row>
                      {/* <div className="ps-5 pe-5">
                        {partners.length > 1 && index !== 0 && (
                          <hr className="wm-popup-hr" />
                        )}
                      </div> */}

                      <span className="company-modal-line">
                        {partners.length > 1 && index !== 0 && (
                          <hr className="cs-neutral-60" />
                        )}
                      </span>
                      {partners.length > 1 && index !== 0 && (
                        <div className="company-popup-remove">
                          <span
                            className="cs-danger"
                            onClick={() => removeField(index)}
                          >
                            <CapsyncIcon title="delete-filled" size="16" />
                          </span>
                          <span
                            className="default-regular-h5 cs-danger"
                            onClick={() => removeField(index)}
                          >
                            Remove
                          </span>
                        </div>
                      )}

                      <Col md={6}>
                        {/* <Form.Group className="cs-form-group "> */}
                        <Form.Group
                          className={`cs-form-group ${iterator.fnameError ? "" : ""
                            }`}
                          controlId={`validationFormik03-${index}`}
                        >
                          <Form.Label>First name</Form.Label>
                          <Form.Control
                            type="text"
                            autoComplete="off"
                            placeholder="John"
                            value={iterator.firstName}
                            className={iterator.firstName && "cs-input-field-active"}
                            onChange={(event) =>
                              fieldChange(
                                event.target.value,
                                index,
                                "firstName"
                              )
                            }
                            onKeyPress={(e) =>
                              /[0-9]/.test(e.key) && e.preventDefault()
                            }
                            maxLength={40}
                          />
                          {iterator.fnameError && (
                            <span className="form-error-msg default-light-body-text-s cs-danger">
                              {iterator.fnameError}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        {/* <Form.Group className="cs-form-group"> */}
                        <Form.Group
                          className={`cs-form-group ${iterator.lnameError ? "" : ""
                            }`}
                          controlId={`validationFormik03-${index}`}
                        >
                          <Form.Label>Last name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Doe"
                            value={iterator.lastName}
                            className={iterator.lastName && "cs-input-field-active"}
                            autoComplete="off"
                            onChange={(event) =>
                              fieldChange(event.target.value, index, "lastName")
                            }
                            onKeyPress={(e) =>
                              /[0-9]/.test(e.key) && e.preventDefault()
                            }
                            maxLength={40}
                          />
                          {iterator.lnameError && (
                            <span className="form-error-msg default-light-body-text-s cs-danger">
                              {iterator.lnameError}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <Form.Group
                          className={`cs-form-group last-email-field${iterator.emailError ? "" : ""
                            }`}
                          controlId={`validationFormik03-${index}`}
                        >
                          <Form.Label>Email</Form.Label>

                          <Form.Control
                            type="text"
                            autoComplete="off"
                            placeholder="johndoe@email.com"
                            value={iterator.email}
                            className={iterator.email && "cs-input-field-active"}
                            onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                            onChange={(event) =>
                              fieldChange(event.target.value, index, "email")
                            }
                            onBlur={(event) =>
                              checkUserExist(event.target.value, index)
                            }
                          />
                          {/* {partners.length > 1 && index !== 0 && (
                            <span className="wm-popup-company-delete">
                              <Image
                                src={Actiondelete}
                                alt="Delete"
                                onClick={() => removeField(index)}
                                className="cursor_pointer"
                              />
                            </span>
                          )} */}
                          {iterator.emailError && (
                            <span className="form-error-msg default-light-body-text-s cs-danger">
                              {iterator.emailError}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                );
              })}
              <>
                {partners.every(
                  (partner) =>
                    partner.email.trim() !== "" &&
                    partner.firstName.trim() !== "" &&
                    partner.lastName.trim() !== "" &&
                    partner.emailError === ""
                ) && (
                    <Button
                      type="submit"
                      className="default-regular-h5 cs-btn-icon-tertiary"
                      onClick={addFields}
                    >
                      <span className="cs-primary">
                        <CapsyncIcon title="add-filled" size="16" />
                      </span>
                      Add more partners
                    </Button>
                  )}
              </>
              <span className="form-error-msg default-light-body-text-s cs-danger">{validation.msg}</span>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className="cs-modal-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              className="cs-btn-primary md-btn default-regular-h5"
              onClick={sendInvitation}
              disabled={partners.every(
                (partner) =>
                  partner.email.trim() === "" ||
                  partner.firstName.trim() === "" ||
                  partner.lastName.trim() === "" ||
                  partner.emailError !== "" ||
                  partner.fnameError !== "" ||
                  partner.lnameError !== ""
              )}
            >
              Send Invitation
            </Button>

          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddIndividual;
