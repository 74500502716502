import React, { useState, useCallback } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Area,
  AreaChart
} from "recharts";
import CapsyncToolTip from "./capsyncTooltip/CapsyncToolTip";

const FinanceSummary = ({ lineData, title, }) => {
  const [activeLines, setActiveLines] = useState({
    [title]: true,
  });

  const handleToggleLine = (dataKey) => {
    setActiveLines({
      ...activeLines,
      [dataKey]: !activeLines[dataKey],
    });
  };

  const [opacity, setOpacity] = useState({
    [title]: 1,
  });

  const handleMouseEnter = useCallback(
    (o) => {
      const { dataKey } = o;

      setOpacity({ ...opacity, [dataKey]: 0 });
    },
    [opacity, setOpacity]
  );
  const DataFormater = (number) => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + "B";
    } else if (number > 1000000) {
      return (number / 1000000).toString() + "M";
    } else if (number > 1000) {
      return (number / 1000).toString() + "K";
    } else {
      return number.toString();
    }
  };

  const handleMouseLeave = useCallback(
    (o) => {
      const { dataKey } = o;
      setOpacity({ ...opacity, [dataKey]: 1 });
    },
    [opacity, setOpacity]
  );
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <>
          <div className="chart-custom-tooltip">
            <p>{`${payload[0].name} : $ ${Number(payload[0].value).toLocaleString("en-US")}`}</p>
          </div>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <div>
        <div className="chart-heading">
          <h2 className="default-semi-bold-h2">Summary&nbsp;{
            <CapsyncToolTip
              Child={"info-outlined"}
              placement={window.innerWidth >= 991 ? "right" : "top"}

              message={"Only accounts with transaction data are included in the chart."}
              type="icon"
              size={20}
            />
          }</h2>
          <p className="default-regular-body-text-s cs-neutral-70">
            Last 12 months data as of last sync
          </p>
        </div>

        <div className="graph-line-chart-main-container">
          <div className="legend-row">
            <div className="dollar-legend">$</div>
          </div>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={lineData}
              syncId="anyId"
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >

              <CartesianGrid horizontal={true} vertical={false} />
              <XAxis dataKey="name" tickMargin={10} height={65} />
              <YAxis
                type="number"
                tickCount={8}
                // width={100}
                tickFormatter={DataFormater}
              />
              <defs>
                <linearGradient id="assetsGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8DC6AE" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8DC6AE" stopOpacity={0} />
                </linearGradient>
              </defs>


              <defs>
                <linearGradient id="liabilitiesGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#FAD7D7" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#FAD7D7" stopOpacity={0} />
                </linearGradient>
              </defs>

              <Tooltip
                content={<CustomTooltip />}
                wrapperStyle={{ outline: "none" }}
              />
              {/* <Legend
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="line-chart-tooltip"
                layout="horizontal"
                verticalAlign="top"
                align="right"
                iconType="circle"
                margin={{ top: 0, right: 0, left: 0, bottom: 25 }}
              /> */}
              <Area
                name={title}
                type="monotone"
                dataKey={title}
                stroke={title === "Assets" ? "#008048" : "#E53535"}
                fill={title === "Assets" ? "url(#assetsGradient)" : "url(#liabilitiesGradient)"}
                fillOpacity={0.5}
                strokeOpacity={activeLines[title] ? 1 : 0}
              // onClick={() => handleToggleLine(title)}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div >
    </>
  );
};

export default FinanceSummary;
