import React from "react";
import Skeleton from "react-loading-skeleton";
import { Col, Row } from "react-bootstrap"; 

// COMPONENT IMPORTS
import SkeletonCategoryTable from "../assets/asset_dashboard/SkeletonCategoryTable";

// CSS IMPORTS
import "./FileManagement.css";

const SkeletonFileManagement = ({ isOpen, category }) => {
  return (
    <div className="fm-skeleton">
      <div className="cs-section-topbar">
        <Row>
          <Col>
            {category && category.length > 1 ? (
              <div className="skeleton-breadcrumb">
                <div className="skeleton-breadcrumb-1">
                  <Skeleton width={50} />
                </div>
                <div className="skeleton-breadcrumb-2">
                  <Skeleton width={20} />
                </div>
                <div className="skeleton-breadcrumb-3">
                  <Skeleton width={50} />
                </div>
              </div>
            ) : null}
            <div className="skeleton-tabs-container">
              <h2 className="skeleton-tab-1">
                <Skeleton width={200} />
              </h2>
            </div>
          </Col>
          <Col>
            <h2 className="skeleton-tab-1">
              <Skeleton width="100%" />
            </h2>
          </Col>
          <Col>
            <div className="fm-skelton-button">
              <span>
                <Skeleton width={30} height={30} />
              </span>
              <span>
                <Skeleton width={120} height={30} />
              </span>
            </div>
          </Col>
        </Row>

        <div className="skeleton-manage-container for-mob">
          <div>
            <Skeleton width={100} height={20} />
          </div>
          <div>
            <Skeleton width="100%" height={30} />
          </div>
          <div className="skeleton-new-btn">
            <span>
              <Skeleton width={30} height={30} />
            </span>
            <span>
              <Skeleton width={30} height={30} />
            </span>
          </div>
        </div>
        {isOpen ? (
          <div className="cs-grid-view">
            {Array(3)
              .fill(null)
              .map((_, index) => {
                return (
                  <div className="cs-box" key={index}>
                    <Skeleton
                      width="100%"
                      height={window.innerWidth <= 575 ? 100 : 176}
                    />
                    <div className="file-info file-management-dropdown">
                      <div className="file-title">
                        <span className="cs-neutral-60">
                          <Skeleton width={20} height={20} />
                        </span>
                        <h4 className="default-regular-body-text-l cs-neutral-100">
                          <Skeleton width={80} height={20} />
                        </h4>
                      </div>
                      <div class="cs-settings-dropdown dropdown">
                        <Skeleton width={15} height={20} />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <SkeletonCategoryTable tableData={[1, 2, 3]} />
        )}
      </div>

      <div className="skeleton-upgrade for-des">
        <Row>
          <Col lg={12}>
            <Skeleton width={200} height={20} />
          </Col>
        </Row>
      </div>
      <div className="skeleton-upgrade for-mob">
        <Row>
          <Col lg={12}>
            <Skeleton width="100%" height={20} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SkeletonFileManagement;
