/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Nav, Button, Image } from "react-bootstrap";

import plusIcon from "../../../../../assets/images/other/plus.svg";
import UseFetchPermission from "../../../../customHooks/UseFetchPermission";
import capsyncMobileMenuLogo from "../../../../../assets/images/cap-icon-logo.svg";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import {
  INDIVIDUAL_MENU_ITEMS,
  EDIT_INDIVIDUAL_PERMISSIONS,
} from "./MenuItems";
import { roleName } from "../../../../config/commonRoleName";
import { userRequestList } from "../../../../slices/requests";
import { socket } from "../../../../config/Socket";

const MobileMenu = ({ props, openAddAccountModal }) => {
  const { fetchUserPermission } = UseFetchPermission();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const user_role = user && user.user_role.role;

  const sidebarMenuDisplay = user && user.user && user.user.permission;
  const handleErrorIconClick = () => {
    props.setIsExpanded(!props.isExpanded);
    props.toggleSideBar(true);
  };
  const activeTabState = useSelector((state) => state.activeTab.activeTabState);

  const [menuItems, setMenuItems] = useState([]);
  const [countNotiFrom, setCountNotiFrom] = useState(0);
  const [countNotiTo, setCountNotiTo] = useState(0);

  const checkPermission = (module_id) => {
    if (module_id === "activity-history") {
      return (
        props.editAccessUser &&
        user &&
        (user.user_role.role === 2 || user.user_role.role === 3) &&
        fetchUserPermission.to_access_permission === 2 &&
        window.localStorage.getItem("pfs_access_id") !== null
      );
    } else {
      return props.editAccessUser;
    }
  };
  const isModuleAllowed = (module_id) => {
    if (sidebarMenuDisplay) {
      if (module_id === "credit-manager") {
        return sidebarMenuDisplay.is_allow_creditmanager === "1";
      } else if (module_id === "verifyinvestor") {
        return sidebarMenuDisplay.is_allow_accreditation === "1";
      } else if (module_id === "identity-protect") {
        return sidebarMenuDisplay.is_allow_identityprotect === "1";
      } else if (module_id === "file-management") {
        return sidebarMenuDisplay.is_allow_filemanagement === "1";
      }
    }
    return true;
  };
  useEffect(() => {
    const menus = [...INDIVIDUAL_MENU_ITEMS].map((val) => {
      const isAllowed = isModuleAllowed(val.id);
      return {
        ...val,
        isShow: EDIT_INDIVIDUAL_PERMISSIONS.includes(val.id)
          ? checkPermission(val.id) && isAllowed
          : val.isShow && isAllowed,
      };
    });
    setMenuItems(menus);
  }, [props.editAccessUser, sidebarMenuDisplay, user]);

  const fetchSignatureFromDetails = () => {
    dispatch(
      userRequestList({
        id: user.user.id,
        user_email: user.user.email,
        flag: roleName.individual,
      })
    )
      .unwrap()
      .then((response) => {
        const countFrom = response.from.filter(
          (data) => data.is_completed === false
        );
        setCountNotiFrom(countFrom.length);
        const countTo = response.to.filter(
          (data) => data.is_completed === false
        );
        setCountNotiTo(countTo.length);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  useEffect(() => {
    if (user && user.user && user.user.email && user.user.id)
      fetchSignatureFromDetails();
  }, [user]);
  useEffect(() => {
    const handleNotification = (msg) => {
      fetchSignatureFromDetails();
    };

    socket.on("newNotification", handleNotification);
    socket.on("signature_request_status_update", handleNotification);
    socket.on("signature_request_status_update_individual", handleNotification);
    socket.on("signature_request_status_update_spouse", handleNotification);

    return () => {
      socket.off("newNotification", handleNotification);
      socket.off("signature_request_status_update", handleNotification);
      socket.off(
        "signature_request_status_update_individual",
        handleNotification
      );
      socket.off("signature_request_status_update_spouse", handleNotification);
    };
  }, []);
  return (
    <>
      <div
        className={classNames("collapse-sidebar", "cs-main-sidebar", {
          "is-open": props.isOpen,
        })}
      >
        <div className="sidebar-header">
          {/* <LogoImage /> */}
          <div className="cs-logo">
            <Link
              id="capsync-logo"
              to={
                user_role === 1
                  ? "/individual"
                  : user_role === 2
                  ? "/financial-advisor"
                  : "/professional"
              }
            >
              <Image src={capsyncMobileMenuLogo} alt="capsyncMobileMenuLogo" />
            </Link>
          </div>
          {/* <Button variant="link" onClick={props.toggle}>
            <CapsyncIcon title="close-outlined" size="18" />
          </Button> */}
        </div>

        <div className="sidebar-nav">
          <Nav>
            {menuItems.map((val, idx) => {
              if (!val.isShow) {
                return false;
              }
              if (val.subItems && val.subItems.length) {
                return val.subItems.map((subItem, index) => {
                  return (
                    <Link
                      key={index}
                      to={subItem.link}
                      onClick={() => props.handleclick()}
                      className={`cs-main-nav-item ${
                        activeTabState === subItem.activeKey && "active"
                      }`}
                      id={subItem.id}
                    >
                      <div className="cs-menu-icon">
                        <CapsyncIcon
                          title={`${subItem.icon}-${
                            activeTabState === subItem.activeKey
                              ? "filled"
                              : "outlined"
                          }`}
                          size="22"
                        />
                        {subItem.isNotifications &&
                          (props.requestNotifications > 0 ||
                            countNotiFrom > 0 ||
                            countNotiTo > 0) && (
                            <span className="blue-notification-badge sidebar-notification-icon " />
                          )}
                      </div>
                    </Link>
                  );
                });
              }
              return (
                <Link
                  key={idx}
                  to={val.link}
                  onClick={() => props.handleclick()}
                  className={`cs-main-nav-item ${
                    activeTabState === val.activeKey && "active"
                  }`}
                  id={val.id}
                >
                  <div className="cs-menu-icon">
                    <CapsyncIcon
                      title={`${val.icon}-${
                        activeTabState === val.activeKey ? "filled" : "outlined"
                      }`}
                      size="22"
                    />
                    {val.isNotifications &&
                      (props.requestNotifications > 0 ||
                        countNotiFrom > 0 ||
                        countNotiTo > 0) && (
                        <span className="blue-notification-badge sidebar-notification-icon " />
                      )}
                  </div>
                </Link>
              );
            })}
          </Nav>
        </div>

        {props.editAccessUser && (
          <React.Fragment>
            {fetchUserPermission.to_access_permission === 1 &&
            window.localStorage.getItem("pfs_access_id") !== null ? (
              ""
            ) : (
              <>
                <div className="sidebar-add-account">
                  {(props.plaidErrorAccounts.error_free_accounts.length > 0 ||
                    props.plaidErrorAccounts.error_accounts.length.length >
                      0) && (
                    <div
                      className={`${
                        props.plaidErrorAccounts.error_accounts.length > 0
                          ? "banking-validations"
                          : "banking-validations banking-sync-data"
                      }`}
                      onClick={handleErrorIconClick}
                      id="banking"
                    >
                      {props.plaidErrorAccounts.error_accounts.length > 0 ? (
                        <span
                          className="cs-danger"
                          onClick={props.toggleSideBar}
                        >
                          <CapsyncIcon
                            title="error-validation-filled"
                            size="22"
                          />
                        </span>
                      ) : (
                        <span
                          className="cs-primary"
                          onClick={props.toggleSideBar}
                        >
                          <CapsyncIcon title="verification-filled" size="22" />
                        </span>
                      )}
                    </div>
                  )}
                  <Button
                    className="cs-btn-primary default-regular-h5"
                    onClick={() => openAddAccountModal(true)}
                    id="add-account"
                  >
                    <Image
                      src={plusIcon}
                      className="add-account-plus-icon-sm-btn"
                    />
                  </Button>
                </div>
              </>
            )}
          </React.Fragment>
        )}
      </div>
    </>
  );
};

export default MobileMenu;
