import { FA_PATHS } from "../../../../config/paths";
import commonNames from "../../../../config/commonNames";

export const FA_MENU_ITEMS = [
    {
        link: "/financial-advisor",
        activeKey: FA_PATHS.DASHBOARD,
        title: commonNames.Dashboard,
        id: "dashboard",
        icon: "dashboard",
        isShow: true,
        isNotifications: false,
    },
    {
        link: "/financial-advisor/individuals",
        activeKey: FA_PATHS.INDIVIDUALS,
        title: commonNames.Individuals,
        id: "individuals",
        icon: "user-single",
        isShow: true,
        isNotifications: false,
    },
    {
        link: "/financial-advisor/companies",
        activeKey: FA_PATHS.COMPANIES,
        title: commonNames.Companies,
        id: "companies",
        icon: "company",
        isShow: true,
        isNotifications: false,
    },
    {
        link: "/financial-advisor/requests",
        activeKey: FA_PATHS.REQUESTS,
        title: commonNames.Requests,
        id: "requests",
        icon: "message-request",
        isShow: true,
        isNotifications: true,
    },
]
export const PRO_MENU_ITEMS = [
    {
        link: "/professional",
        activeKey: FA_PATHS.DASHBOARD,
        title: commonNames.Dashboard,
        id: "dashboard",
        icon: "dashboard",
        isShow: true,
        isNotifications: false,
    },
    {
        link: "/professional/individuals",
        activeKey: FA_PATHS.INDIVIDUALS,
        title: commonNames.Individuals,
        id: "individuals",
        icon: "user-single",
        isShow: true,
        isNotifications: false,
    },
    {
        link: "/professional/companies",
        activeKey: FA_PATHS.COMPANIES,
        title: commonNames.Companies,
        id: "companies",
        icon: "company",
        isShow: true,
        isNotifications: false,
    },
    {
        link: "/professional/requests",
        activeKey: FA_PATHS.REQUESTS,
        title: commonNames.Requests,
        id: "requests",
        icon: "message-request",
        isShow: true,
        isNotifications: true,
    },
]