import React from "react";
import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";

const CommonSettingDropdown = (props) => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const { pathname } = useLocation();
  const isMySettings = pathname?.split("/")?.[2] === "my-settings";

  const tabArr = [
    {
      pathName: isMySettings
        ? "/individual/my-settings/account-details"
        : "/individual/account-details",
      name: "Account Details",
    },
    // {
    //   pathName: isMySettings
    //     ? "/individual/my-settings/file-encryption"
    //     : "/individual/file-encryption",
    //   name: "File Encryption",
    // },
    {
      pathName: isMySettings
        ? "/individual/my-settings/mfa-setup"
        : "/individual/mfa-setup",
      name: "MFA Setup",
    },
    {
      pathName: "/individual/billing-subscription",
      name: "Billing & Subscription",
    },
    {
      pathName: isMySettings
        ? "/individual/my-settings/audit-logs"
        : "/individual/audit-logs",
      name: "Audit Logs",
    },
  ];

  function handleDropdownSelect(eventKey) {
    navigate(eventKey);
  }

  const pfsAccessId = window.localStorage.getItem("pfs_access_id");

  return (
    <div className="setting-mobile-dropdown">
      <Dropdown onSelect={handleDropdownSelect}>
        <Dropdown.Toggle className="default-regular-body-text-l">
          {tabArr.filter((x) => x.pathName === window.location.pathname)[0] &&
            tabArr.filter((x) => x.pathName === window.location.pathname)[0]
              .name}
          <span className="cs-neutral-80">
            <CapsyncIcon title="chevron-down-outlined" size="16" />
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            eventKey={
              isMySettings
                ? "/individual/my-settings/account-details"
                : "/individual/account-details"
            }
            active={
              window.location.pathname ===
              (isMySettings
                ? "/individual/my-settings/account-details"
                : "/individual/account-details")
            }
            className="default-regular-body-text-l cs-neutral-90"
          >
            Account Details
          </Dropdown.Item>
          {/* {pfsAccessId === null && (
            <Dropdown.Item
              eventKey={
                isMySettings
                  ? "/individual/my-settings/file-encryption"
                  : "/individual/file-encryption"
              }
              active={
                window.location.pathname ===
                (isMySettings
                  ? "/individual/my-settings/file-encryption"
                  : "/individual/file-encryption")
              }
              className="default-regular-body-text-l cs-neutral-90"
            >
              File Encryption
            </Dropdown.Item>
          )} */}
          {(isMySettings || pfsAccessId === null) && (
            <Dropdown.Item
              eventKey={
                isMySettings
                  ? "/individual/my-settings/mfa-setup"
                  : "/individual/mfa-setup"
              }
              active={
                window.location.pathname ===
                (isMySettings
                  ? "/individual/my-settings/mfa-setup"
                  : "/individual/mfa-setup")
              }
              className="default-regular-body-text-l cs-neutral-90"
            >
              MFA Setup
            </Dropdown.Item>
          )}
          {user.user.is_beta_user === false &&
            props.editAccessUser &&
            !isMySettings && (
              <Dropdown.Item
                eventKey={"/individual/billing-subscription"}
                active={
                  window.location.pathname ===
                  "/individual/billing-subscription"
                }
                className="default-regular-body-text-l cs-neutral-90"
              >
                Billing & Subscription
              </Dropdown.Item>
            )}
          <Dropdown.Item
            eventKey={
              isMySettings
                ? "/individual/my-settings/audit-logs"
                : "/individual/audit-logs"
            }
            active={
              window.location.pathname ===
              (isMySettings
                ? "/individual/my-settings/audit-logs"
                : "/individual/audit-logs")
            }
            className="default-regular-body-text-l cs-neutral-90"
          >
            Audit Logs
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default CommonSettingDropdown;
