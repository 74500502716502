import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import userSubscriptions from "../services/user_subscription.service";
import { localDataRemove } from "../config/finance";
import { headerAddToken } from "../util/common";
import API from "../util/api";

export const add_subscription = createAsyncThunk(
  "/usersubscription/add-user-subscription",
  async (values, thunkAPI) => {
    try {
      const response = await userSubscriptions.add_subscription(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const create_payment_intent = createAsyncThunk(
  "/usersubscription/create-user-payment-intent",
  async (values, thunkAPI) => {
    try {
      const response = await userSubscriptions.create_payment_intent(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const get_billing_and_subscription_by_user = createAsyncThunk(
  "/usersubscription/get-billing-and-subscription",
  async (values, thunkAPI) => {
    try {
      const response =
        await userSubscriptions.get_billing_and_subscription_by_user(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const get_user_active_payment_method = createAsyncThunk(
  "/usersubscription/get_user_active_payment_method",
  async (values, thunkAPI) => {
    try {
      const response =
        await userSubscriptions.get_user_active_payment_method(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const remove_user_subscription_method = createAsyncThunk(
  "/usersubscription/delete-user-payment-method",
  async (values, thunkAPI) => {
    try {
      const response = await userSubscriptions.remove_user_subscription_method(
        values
      );
      thunkAPI.dispatch(setMessage(response));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const update_user_payment_method = createAsyncThunk(
  "/usersubscription/update-user-payment-method",
  async (values, thunkAPI) => {
    try {
      const response = await userSubscriptions.update_user_payment_method(
        values
      );
      thunkAPI.dispatch(setMessage(response));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const update_user_default_payment_method = createAsyncThunk(
  "/usersubscription/update-user-default-payment-method",
  async (values, thunkAPI) => {
    try {
      const response =
        await userSubscriptions.update_user_default_payment_method(values);
      thunkAPI.dispatch(setMessage(response));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const cancel_user_subscription = createAsyncThunk(
  "/usersubscription/cancel-subscription",
  async (values, thunkAPI) => {
    try {
      const response = await userSubscriptions.cancel_user_subscription(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const subscription_plan_cancel_reason = createAsyncThunk(
  "/usersubscription/subscription_plan_cancel_reason",
  async (values, thunkAPI) => {
    try {
      const response = await userSubscriptions.subscription_plan_cancel_reason(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const trasfer_billing = createAsyncThunk(
  "/usersubscription/transfer-billing",
  async (values, thunkAPI) => {
    try {
      const response = await userSubscriptions.trasfer_billing(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const add_user_payment = createAsyncThunk(
  "/usersubscription/add-user-payment-manually",
  async (values, thunkAPI) => {
    try {
      const response = await userSubscriptions.add_user_payment(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const update_user_subscription_plan = createAsyncThunk(
  "/usersubscription/update-user-subscription-plan",
  async (values, thunkAPI) => {
    try {
      const response = await userSubscriptions.update_user_subscription_plan(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const add_payment_billing = createAsyncThunk(
  "/usersubscription/payment-billing-add",
  async (values, thunkAPI) => {
    try {
      const response = await userSubscriptions.add_payment_billing(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const purchase_user_storage_subscription = createAsyncThunk(
  "/usersubscription/purchase-user-storage-subscription",
  async (values, thunkAPI) => {
    try {
      const response = await userSubscriptions.purchase_user_storage_subscription(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const add_default_payment_billing = createAsyncThunk(
  "/usersubscription/payment-default-billing-add",
  async (values, thunkAPI) => {
    try {
      const response = await userSubscriptions.add_default_payment_billing(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const change_active_payment_method = createAsyncThunk(
  "/usersubscription/change-active-payment-method",
  async (values, thunkAPI) => {
    try {
      const response = await userSubscriptions.change_active_payment_method(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

function sortBillingDetails(array) {
  let billingDetailArray = [...array];
  billingDetailArray = billingDetailArray.sort(
    (a, b) =>
      Number(b.user_payment_detail_id) - Number(a.user_payment_detail_id)
  );
  billingDetailArray = billingDetailArray.sort(
    (a, b) => Number(b.is_verify) - Number(a.is_verify)
  );
  billingDetailArray = billingDetailArray.sort(
    (a, b) => Number(b.is_active) - Number(a.is_active)
  );
  return billingDetailArray;
}

const initialState = {
  userBillingDetail: [],
  userTransactionData: [],
  userBillingHistory: [],
};

const userSubscriptionSlice = createSlice({
  name: "userSubscription",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      get_billing_and_subscription_by_user.fulfilled,
      (state, action) => {
        state.userBillingDetail = sortBillingDetails(
          action.payload.data.user_payment_deatil_data
        );
        state.userTransactionData = action.payload.data.transactions;
        state.userBillingHistory = action.payload.data.user_details;
      }
    );
    builder.addCase(
      remove_user_subscription_method.fulfilled,
      (state, action) => {
        state.userBillingDetail = state.userBillingDetail.filter(
          (item) => item.user_payment_detail_id !== action.payload.data
        );
      }
    );
    builder.addCase(add_user_payment.fulfilled, (state, action) => {
      state.userBillingDetail = sortBillingDetails([
        action.payload.data,
        ...state.userBillingDetail,
      ]);
    });
    builder.addCase(change_active_payment_method.fulfilled, (state, action) => {
      let newData = state.userBillingDetail.map((obj) =>
        action.payload.data === obj.user_payment_detail_id
          ? { ...obj, is_active: true }
          : { ...obj, is_active: false }
      );
      state.userBillingDetail = sortBillingDetails(newData);
    });
  },
});

const { reducer } = userSubscriptionSlice;
export default reducer;
