import * as yup from "yup";
import "yup-phone-lite";
import { checkDomainAccept } from "../../../../util/common";
import { uniValidation } from "../../liabilities/categoryForms/validationSchema";

var date = new Date();

const asset_cash_on_hand_validationSchema = yup.object().shape({

    institution: yup
        .string()
        .required(uniValidation.assets_and_liabilities.institution.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.institution.require, (value) => {
        return value.trim() !== "";
      })
        // .matches(/^\S.*$/, uniValidation.assets_and_liabilities.institution.require)
        // .min(2, uniValidation.assets_and_liabilities.institution.min)
        .max(30, uniValidation.assets_and_liabilities.institution.max),
    balance: yup.string().required(uniValidation.assets_and_liabilities.balance.require)
        .test(
            "non-zero",
            uniValidation.assets_and_liabilities.balance.nonzero,
            (value) => Number(value.replace(/,/g, "")) !== 0
        )
    // .test(
    //     'Is positive?',
    //     'Balance must be a positive number',
    //     (value) => !!value && !value.includes('-')
    // ),
    // .test(
    //     'Is positive?',
    //     'Balance must be a positive number',
    //     (value) => value && !(Number(value.replace(/,/g, "")) < 0)
    // )
    // .test(
    //     'checkDecimal',
    //     'Maximum two digits are allowed after the decimal point',
    //     (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)
    // )
    ,

    ownership: yup
        .number()
        // .positive("Ownership can't start with a minus")
        // .moreThan(0, "Ownership should not be zero")
        // .lessThan(101, "Ownership should not be more than 100")
        .required(uniValidation.assets_and_liabilities.ownership.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.ownership.require, (value) => {
            return value && value.toString().trim() !== "";
      }),
    date_updated: yup
        .date()
        // .max(new Date(), "Future date can not be accepted")
        .required("Please select updated date"),
});

const asset_business_validationSchema = yup.object().shape({
    entity_name: yup
        .string()
        // .matches(/^\S.*$/, "Whitespace is not allowed")
        // .min(2, uniValidation.assets_and_liabilities.entity_name.min)
        .max(100, uniValidation.assets_and_liabilities.entity_name.max).test("is-not-only-spaces", uniValidation.assets_and_liabilities.entity_name.require, (value) => {
            return value.trim() !== "";
        })
        .required(uniValidation.assets_and_liabilities.entity_name.require),
    industry: yup
        .string()
        // .matches(/^\S.*$/, "Whitespace is not allowed")
        // .min(2, uniValidation.assets_and_liabilities.industry.min)
        .max(40, uniValidation.assets_and_liabilities.industry.max)
    // .required(uniValidation.assets_and_liabilities.industry.requrie),
    //     .required("Please enter industry"),
    ,
    balance: yup.string()
        .required("Enter market value")
        .test(
            "non-zero",
            "Market value must be greater than zero",
            (value) => Number(value.replace(/,/g, "")) !== 0
        )
        // .test(
        //     'Is positive?',
        //     'Total value must be a positive number',
        //     (value) => !!value && !value.includes('-')
        // ),
        .test(
            'Is positive?',
            'Total value must be a positive number',
            // (value) => !!value && !value.includes('-')
            (value) => value && !(Number(value.replace(/,/g, "")) < 0)
        )
    // .test(
    //     'checkDecimal',
    //     'Maximum two digits are allowed after the decimal point',
    //     (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)

    // )
    ,
    ownership: yup
        .number()
        // .positive("Ownership can't start with a minus")
        // .moreThan(0, "Ownership should not be zero")
        // .lessThan(101, "Ownership should not be more than 100")
        .required(uniValidation.assets_and_liabilities.ownership.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.ownership.require, (value) => {
            return value && value.toString().trim() !== "";
      }),
    date_updated: yup
        .date()
        .required("Please select updated date")
    // .max(new Date(), "Future date can not be accepted"),
});

const asset_otherAssets_validationSchema = yup.object().shape({
    description: yup
        .string()
        // .matches(/^\S.*$/, "Whitespace is not allowed")
        // .min(2, uniValidation.assets_and_liabilities.description.min)
        .max(100, uniValidation.assets_and_liabilities.description.max)
        .required(uniValidation.assets_and_liabilities.description.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.description.require, (value) => {
        return value.trim() !== "";
      }),
    property_name: yup
        .string()
        // .matches(/^\S.*$/, "Whitespace is not allowed")
        // .min(2, "Property name must be at least 2 characters")
        .max(100, "Maximum 100 characters allowed")
        .required("Enter asset type").test("is-not-only-spaces", "Enter asset type", (value) => {
            return value.trim() !== "";
        })
    // .test(
    //     "whitesspace",
    //     "Whitespace is not allowed",
    //     (value) => !(/^\s+|\s+$/g).test(value)
    // )
    ,
    market_value: yup
        .string()
        .required(uniValidation.assets_and_liabilities.market_value.require)
        .max(14, "Market value must be less than 11 characters long")
        .test(
            "non-zero",
            uniValidation.assets_and_liabilities.market_value.nonzero,
            (value) => Number(value.replace(/,/g, "")) !== 0
        )
        // .test(
        //     'Is positive?',
        //     'Market value must be a positive number',
        //     (value) => !!value && !value.includes('-')
        // ),
        .test(
            'Is positive?',
            uniValidation.assets_and_liabilities.market_value.nonzero,
            // (value) => !!value && !value.includes('-')
            (value) => value && !(Number(value.replace(/,/g, "")) < 0)
        )
    //     .test(
    //         'checkDecimal',
    //         'Maximum two digits are allowed after the decimal point',
    //         (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)

    // )
    ,
    date_updated: yup
        .date()
        .required("Please select updated date")
    // .max(new Date(), "Future date can not be accepted"),
});

const asset_personalProperty_validationSchema = yup.object().shape({
    description: yup
        .string()
        // .matches(/^\S.*$/, "Whitespace is not allowed")
        // .min(2, uniValidation.assets_and_liabilities.description.min)
        .max(100, uniValidation.assets_and_liabilities.description.max)
        .required(uniValidation.assets_and_liabilities.description.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.description.require, (value) => {
        return value.trim() !== "";
      }),
    property_name: yup
        .string()
        // .matches(/^\S.*$/, uniValidation.assets_and_liabilities.property_name.require)
        // .min(2, uniValidation.assets_and_liabilities.property_name.min)
        .max(100, "Maximum 100 characters allowed")
        .required("Enter property type").test("is-not-only-spaces", "Enter property type", (value) => {
            return value.trim() !== "";
        }),
    market_value: yup.string()
        .required(uniValidation.assets_and_liabilities.market_value.require)
        .test(
            "non-zero",
            uniValidation.assets_and_liabilities.market_value.nonzero,
            (value) => Number(value.replace(/,/g, "")) !== 0
        )
        // .test(
        //     'Is positive?',
        //     'Market value must be a positive number',
        //     (value) => !!value && !value.includes('-')
        // ),
        .test(
            'Is positive?',
            uniValidation.assets_and_liabilities.market_value.nonzero,
            (value) => value && !(Number(value.replace(/,/g, "")) < 0)
        )
    //     .test(
    //         'checkDecimal',
    //         'Maximum two digits are allowed after the decimal point',
    //         (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)

    // )
    ,
    // date_updated: yup
    //     .date()
    //     .required("Please select updated date")
    //     .max(new Date(), "Future date can not be accepted"),
});

const asset_stockNbonds_validationSchema = yup.object().shape({
    institution: yup
        .string()
        .required(uniValidation.assets_and_liabilities.institution.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.institution.require, (value) => {
        return value.trim() !== "";
      })
        // .matches(/^\S.*$/, uniValidation.assets_and_liabilities.institution.require)
        // .min(2, uniValidation.assets_and_liabilities.institution.min)
        .max(30, uniValidation.assets_and_liabilities.institution.max),
    balance: yup.string().required(uniValidation.assets_and_liabilities.balance.require)
        .test(
            "non-zero",
            uniValidation.assets_and_liabilities.balance.nonzero,
            (value) => Number(value.replace(/,/g, "")) !== 0
        )
    // .test(
    //     'Is positive?',
    //     'Balance must be a positive number',
    //     (value) => !!value && !value.includes('-')
    // ),
    // .test(
    //     'Is positive?',
    //     'Balance must be a positive number',
    //     (value) => value && !(Number(value.replace(/,/g, "")) < 0)
    // )
    // .test(
    //     'checkDecimal',
    //     'Maximum two digits are allowed after the decimal point',
    //     (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)
    // )
    ,
    ownership: yup
        .number()
        // .positive("Ownership can't start with a minus")
        // .moreThan(0, "Ownership should not be zero")
        // .lessThan(101, "Ownership should not be more than 100")
        .required(uniValidation.assets_and_liabilities.ownership.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.ownership.require, (value) => {
            return value && value.toString().trim() !== "";
      }),
    date_updated: yup
        .date()
        .required("Please select updated date")
    // .max(new Date(), "Future date can not be accepted"),
});

const asset_savingAccount_validationSchema = yup.object().shape({
    institution: yup
        .string()
        .required(uniValidation.assets_and_liabilities.institution.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.institution.require, (value) => {
        return value.trim() !== "";
      })
        // .matches(/^\S.*$/, uniValidation.assets_and_liabilities.institution.require)
        // .min(2, uniValidation.assets_and_liabilities.institution.min)
        .max(30, uniValidation.assets_and_liabilities.institution.max),
    balance: yup.string().required(uniValidation.assets_and_liabilities.balance.require)
        .test(
            "non-zero",
            uniValidation.assets_and_liabilities.balance.nonzero,
            (value) => Number(value.replace(/,/g, "")) !== 0
        )
    // .test(
    //     'Is positive?',
    //     'Balance must be a positive number',
    //     (value) => !!value && !value.includes('-')
    // ),
    // .test(
    //     'Is positive?',
    //     'Balance must be a positive number',
    //     (value) => value && !(Number(value.replace(/,/g, "")) < 0)
    // )
    // .test(
    //     'checkDecimal',
    //     'Maximum two digits are allowed after the decimal point',
    //     (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)
    // )
    ,
    ownership: yup
        .number()
        // .positive("Ownership can't start with a minus")
        // .moreThan(0, "Ownership should not be zero")
        // .lessThan(101, "Ownership should not be more than 100")
        .required(uniValidation.assets_and_liabilities.ownership.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.ownership.require, (value) => {
            return value && value.toString().trim() !== "";
      }),
    date_updated: yup
        .date()
        .required("Please select updated date")
    // .max(new Date(), "Future date can not be accepted"),
});

const asset_retirementAccount_validationSchema = yup.object().shape({
    institution: yup
        .string()
        .required(uniValidation.assets_and_liabilities.institution.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.institution.require, (value) => {
        return value.trim() !== "";
      })
        // .matches(/^\S.*$/, uniValidation.assets_and_liabilities.institution.require)
        // .min(2, uniValidation.assets_and_liabilities.institution.min)
        .max(30, uniValidation.assets_and_liabilities.institution.max),
    balance: yup.string().required(uniValidation.assets_and_liabilities.balance.require)
        .test(
            "non-zero",
            uniValidation.assets_and_liabilities.balance.nonzero,
            (value) => Number(value.replace(/,/g, "")) !== 0
        )
    // .test(
    //     'Is positive?',
    //     'Balance must be a positive number',
    //     (value) => !!value && !value.includes('-')
    // ),
    // .test(
    //     'Is positive?',
    //     'Balance must be a positive number',
    //     (value) => value && !(Number(value.replace(/,/g, "")) < 0)
    // )
    // .test(
    //     'checkDecimal',
    //     'Maximum two digits are allowed after the decimal point',
    //     (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)
    // )
    ,
    loan_on_balance: yup.string()
        .test(
            'loan_on_balance',
            'Loan balance must be lower than the account balance',
            (value, context) => {
                if (value === null || value === undefined || value === "") {
                    return true;
                }
                return context.parent.balance && context.parent.loan_on_balance && (Number(context.parent.balance.replace(/,/g, "")) > Number(value.replace(/,/g, "")))
            }
        ),
    total: yup.string()
        // .test(
        //     '',
        //     'The Loan on Balance should not exceed the Balance',
        //     (value, context) => context.parent.balance && context.parent.loan_on_balance && (Number(context.parent.balance.replace(/,/g, "")) > Number(context.parent.loan_on_balance.replace(/,/g, "")))
        // ),
        .test(
            "non-zero",
            "Total Balance must be greater than zero",
            (value) => Number(value) !== 0
        ),
    // .when(['balance', 'loan_on_balance'], {
    //     is: (balance, loan_on_balance) => balance && loan_on_balance && (Number(balance.replace(/,/g, "")) < Number(loan_on_balance.replace(/,/g, ""))),
    //     then: yup.string().test(
    //         '',
    //         'The Loan on Balance should not exceed the Balance',
    //         (value) => !!value && !value.includes('-')
    //     ),
    // }),
    date_updated: yup
        .date()
        .required("Please select updated date")
    // .max(new Date(), "Future date can not be accepted"),
});

const asset_realEstate_validationSchema = yup.object().shape({
    address: yup
        .string()
        .min(2, "Address must be at least 2 characters")
        .required("Please enter address").test("is-not-only-spaces", "Please enter address", (value) => {
        return value.trim() !== "";
      }),
    market_value: yup.string()
        .max(15, "Market value must be greater than zero")
        .required('Enter market value')
        .test(
            "non-zero",
            "Market Value must be greater than zero",
            (value) => Number(value.replace(/,/g, "")) !== 0
        )
        .test(
            'checkDecimal',
            'Market Value must be greater than zero',
            (value) => {
                return value !== undefined ? /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value) : true;
            }
        )
        .test(
            "",
            "Market value must be greater than zero",
            (value) => !(/^0+(?:\.0+)?$/).test(value)
        )
        .test(
            'Is positive?',
            'Balance must be a positive number',
            (value) => value && !(Number(value.replace(/,/g, "")) < 0)
        ),

    // share_market_value: yup.string()
    //     .required('Please share market value'),
    ownership: yup
        .number()
        // .positive("Ownership can't start with a minus")
        // .moreThan(0, "Ownership should not be zero")
        // .lessThan(101, "Ownership should not be more than 100")
        .required(uniValidation.assets_and_liabilities.ownership.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.ownership.require, (value) => {
            return value && value.toString().trim() !== "";
      }),

    annual_income: yup
        .string()
        .optional()
    //     .test(
    //         'checkDecimal',
    //         'Maximum two digits are allowed after the decimal point',
    //         (value) => {
    //             return value !== undefined ? /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value) : true;
    //         }
    // )
    ,
    purchase_date: yup
        .date()
        .required("Enter purchase date"),
    date_updated: yup
        .date()
        .required("Enter updated date")
    // .max(date.setDate(date.getDate() + 1), "Future date can not be accepted"),
});

const asset_lifeinsurance_validationSchema = yup.object().shape({
    company: yup
        .string()
        // .matches(/^\S.*$/, "Whitespace is not allowed")
        // .min(2, uniValidation.assets_and_liabilities.company.min)
        .max(40, uniValidation.assets_and_liabilities.company.max)
        .required(uniValidation.assets_and_liabilities.company.require).test("is-not-only-spaces",uniValidation.assets_and_liabilities.company.require, (value) => {
        return value.trim() !== "";
      }),
    // type: yup.string()
    //     .required("Please enter Type")
    //     .max(40, "Type must be 40 characters maximum")
    //     .test(
    //         "whitesspace",
    //         "Whitespace is not allowed",
    //         (value) => !(/^\s+|\s+$/g).test(value)
    //     ),
    email: yup
        .string()
        // .matches(/^\S*$/, "Whitespace is not allowed")
        .email(uniValidation.email.validEmail)
        // .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, uniValidation.email.validEmail)
        .test('no-more-than-30-before-at', uniValidation.email.validEmail, value =>
            value ? value.split('@')[0].length <= 30 : true
        )
        .test('at-least-1-character-before-at', 'Enter at least 1 character before @', value =>
            value ? value.split('@')[0].length > 0 : true
        )
        .test('custom-email-format', uniValidation.email.validEmail, value => {
            return value ? uniValidation.email.allowedFormat.test(value) : true
        })
        // .test(
        //     "whitesspace",
        //     "Whitespace is not allowed",
        //     (value) => !(/^\s+|\s+$/g).test(value)
        // )
        .test('domain-acceptance', uniValidation.email.validEmail, value => {
            return checkDomainAccept(value);
        }),
    phone_number: yup.string()
        .phone("US, IN, GB", uniValidation.phone_number.valid),
    // .required("A phone number is required"),
    // insure_person_name: yup
    //     .string()
    //     .matches(/^\S.*$/, "Whitespace is not allowed")
    //     .min(2, "Name must be at least 2 characters")
    //     .max(100, "Name must be 20 characters maximum"),
    beneficiary: yup
        .string()
        .required(uniValidation.assets_and_liabilities.beneficiary.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.beneficiary.require, (value) => {
        return value.trim() !== "";
      })
        // .matches(/^\S.*$/, "Whitespace is not allowed")
        // .min(2, "Beneficiary name must be at least 2 characters")
        .max(40, uniValidation.assets_and_liabilities.beneficiary.max)
    // .test(
    //     "whitesspace",
    //     "Whitespace is not allowed",
    //     (value) => !(/^\s+|\s+$/g).test(value)
    // )
    ,
    cash_value: yup.string().required("Enter cash value")
        .test(
            "non-zero",
            uniValidation.assets_and_liabilities.cash_value.nonzero,
            (value) => Number(value.replace(/,/g, "")) !== 0
        )
        .test(
            'Is positive?',
            uniValidation.assets_and_liabilities.cash_value.nonzero,
            (value) => value && !(Number(value.replace(/,/g, "")) < 0)
        )
    //     .test(
    //         'checkDecimal',
    //         'Maximum two digits are allowed after the decimal point',
    //         (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)

    // )
    ,
    date_updated: yup
        .date()
        .required("Please select updated date"),
    // .max(new Date(), "Future date can not be accepted"),
    loan_amount: yup.string()
        .when('category', {
            is: (category) => category == 'liabilities',
            then: (schema) =>
                schema.test('is-valid-decimal', "Maximum two digits are allowed after the decimal point", value => {
                    if (value === undefined || value === null) {
                        return true;
                    }
                    return /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value);
                }).required("Enter Loan Balance"),
            otherwise: (schema) => schema.test('is-valid-decimal', "Maximum two digits are allowed after the decimal point", value => {
                if (value === undefined || value === null) {
                    return true;
                }
                return /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value);
            }),
        }),

});


// const annualRateSchema = yup
//     .number()
//     .typeError("Interest rate should be a number")
//     .when("loan_period", {
//         is: (loan_period) => !!loan_period,
//         then: yup
//             .number()
//             .required("Interest rate is required")
//             .moreThan(0, "Interest rate should be greater than zero")
//             .lessThan(101, "Interest rate should not be more than 100")
//             .test(
//                 "valid-percentage",
//                 "Interest rate should not be more than 100",
//                 (value) => {
//                     if (value && (value < 0 || value > 100)) {
//                         return false;
//                     }
//                     return true;
//                 }
//             ),
//         otherwise: yup.number(),
//     });

//-------------Add account form validation-------------//

const asset_accountNotesReceivable_validationSchema = yup.object().shape({
    property_name: yup
        .string()
        .matches(/^\S.*$/, uniValidation.assets_and_liabilities.property_name.require)
        // .min(2, uniValidation.assets_and_liabilities.property_name.min)
        .max(40, uniValidation.assets_and_liabilities.property_name.max)
        .required(uniValidation.assets_and_liabilities.property_name.require),
    loan_type: yup
        .string()
        .matches(/^\S.*$/, "Whitespace is not allowed")
        .max(40, uniValidation.assets_and_liabilities.loan_type.max)
    // .test(
    //     "whitesspace",
    //     "Whitespace is not allowed",
    //     (value) => !(/^\s+|\s+$/g).test(value)
    // ),
    ,
    debtor_email: yup
        .string()
        // .matches(/^\S*$/, "Whitespace is not allowed")
        // .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, uniValidation.email.validEmail)
        .email(uniValidation.email.validEmail)
        .test('no-more-than-30-before-at', uniValidation.email.validEmail, value =>
            value ? value.split('@')[0].length <= 30 : true
        )
        .test('at-least-1-character-before-at', 'Enter at least 1 character before @', value =>
            value ? value.split('@')[0].length > 0 : true
        )
        .test('custom-email-format', uniValidation.email.validEmail, value => {
            return value ? uniValidation.email.allowedFormat.test(value) : true
        })
        // .test(
        //     "whitesspace",
        //     "Whitespace is not allowed",
        //     (value) => !(/^\s+|\s+$/g).test(value)
        // )
        .test('domain-acceptance', uniValidation.email.validEmail, value => {
            return checkDomainAccept(value);
        }),
    loan_amount: yup.string()
        .required(uniValidation.assets_and_liabilities.loan_amount.require)
        .test(
            "non-zero",
            uniValidation.assets_and_liabilities.loan_amount.greate,
            (value) => {
                const numericValue = Number(value.replace(/,/g, ""));
                return numericValue !== 0;
            }
        ),
    balance_due: yup
        .string().required(uniValidation.assets_and_liabilities.balance_due.require)
        .test(
            "non-zero",
            uniValidation.assets_and_liabilities.balance_due.nonzero,
            (value) => Number(value.replace(/,/g, "")) !== 0
        )
        .test(
            'Is positive?',
            uniValidation.assets_and_liabilities.balance_due.positive,
            (value) => value && !(Number(value.replace(/,/g, "")) < 0)
        )
        .test(
            'checkDecimal',
            uniValidation.assets_and_liabilities.balance_due.max,
            (value) => /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)
        )
    // .test(
    //     'balanceDue',
    //     'Balance due must be less than of the Loan Amount',
    //     (value, context) => context.parent.loan_amount && value && (Number(context.parent.loan_amount.replace(/,/g, "")) >= Number(value.replace(/,/g, "")))
    // )
    ,
    annual_rate: yup
        .number()
        .typeError(uniValidation.assets_and_liabilities.annual_rate.number)
        .required(uniValidation.assets_and_liabilities.annual_rate.require)
    // .moreThan(0, uniValidation.assets_and_liabilities.annual_rate.nonzero)
    ,
    // annual_rate: yup.lazy(() => annualRateSchema),
    loan_period: yup.number()
        .moreThan(0, uniValidation.assets_and_liabilities.loan_period.nonzero)
        .test(
            'LoanPeriodInYear',
            uniValidation.assets_and_liabilities.loan_period.years,
            (value, context) => context.parent.frequency === "Yearly" && value ? Number(value) <= 40 : true)
        .test(
            'LoanPeriodInMonth',
            uniValidation.assets_and_liabilities.loan_period.months,
            (value, context) => context.parent.frequency === "Monthly" && value ? Number(value) <= 480 : true)
        .required(uniValidation.assets_and_liabilities.loan_period.require),

    // maturity_date: yup.date().when("annual_rate", {
    //     is: (annual_rate) => !!annual_rate,
    //     then: yup.date(),
    //     // .min(new Date(), "Please select a future date"),
    //     otherwise: yup.date(),
    // }),

    date_updated: yup
        .date()
        .required("Please select updated date"),
    // .max(new Date(), "Future date can not be accepted"),

});

const asset_automobiles_validationSchema = yup.object().shape({
    description: yup
        .string()
        // .matches(/^\S.*$/, "Whitespace is not allowed")
        // .min(2, uniValidation.assets_and_liabilities.description.min)
        .max(100, uniValidation.assets_and_liabilities.description.max)
        .required(uniValidation.assets_and_liabilities.description.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.description.require, (value) => {
        return value.trim() !== "";
      }),
    vin: yup
        .string()
        .max(17, "VIN must be 17 characters maximum")
        .matches(/^[a-zA-Z0-9]+$/, '* This field cannot contain white space and special character'),
    creditor_name: yup
        .string()
        // .matches(/^\S.*$/, "Whitespace is not allowed")
        // .min(2, uniValidation.assets_and_liabilities.creditor_name.min)
        .max(40, uniValidation.assets_and_liabilities.creditor_name.max)
        .required(uniValidation.assets_and_liabilities.creditor_name.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.creditor_name.require, (value) => {
        return value.trim() !== "";
      }),

    market_value: yup
        .string()
        .required(uniValidation.assets_and_liabilities.market_value.require)
        .max(14, "Market value must be less than 11 characters long")
        .test(
            "non-zero",
            uniValidation.assets_and_liabilities.market_value.nonzero,
            (value) => Number(value.replace(/,/g, "")) !== 0
        )
        // .test(
        //     'Is positive?',
        //     'Market value must be a positive number',
        //     (value) => !!value && !value.includes('-')
        // ),
        .test(
            'Is positive?',
            uniValidation.assets_and_liabilities.market_value.nonzero,
            // (value) => !!value && !value.includes('-')
            (value) => value && !(Number(value.replace(/,/g, "")) < 0)
        )
    //     .test(
    //         'checkDecimal',
    //         'Maximum two digits are allowed after the decimal point',
    //         (value) => {
    //             return value.indexOf(".") > -1 ? /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value) : true
    //         }
    // )
    ,
    payment: yup
        .string()
        .optional()
    //     .test(
    //         'checkDecimal',
    //         'Maximum two digits are allowed after the decimal point',
    //         (value) => {
    //             return value !== undefined ? /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value) : true;
    //         }
    // )
    ,

    balance_due: yup
        .string()
        .optional()
    //     .test(
    //         'checkDecimal',
    //         'Maximum two digits are allowed after the decimal point',
    //         (value) => {
    //             return value !== undefined ? /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value) : true;
    //         }
    // )
    ,

    // date_updated: yup
    //     .date()
    //     .required("Please select updated date")
    //.min("2005-4-1", "Date is too late") // Due to error in Iphone, we not checking this case.
    // .max(new Date(), "Future date can not be accepted"),
});

const validationObj = {
    asset_cash_on_hand_validationSchema: asset_cash_on_hand_validationSchema,
    asset_business_validationSchema: asset_business_validationSchema,
    asset_otherAssets_validationSchema: asset_otherAssets_validationSchema,
    asset_personalProperty_validationSchema: asset_personalProperty_validationSchema,
    asset_stockNbonds_validationSchema: asset_stockNbonds_validationSchema,
    asset_savingAccount_validationSchema: asset_savingAccount_validationSchema,
    asset_retirementAccount_validationSchema: asset_retirementAccount_validationSchema,
    asset_realEstate_validationSchema: asset_realEstate_validationSchema,
    asset_lifeinsurance_validationSchema: asset_lifeinsurance_validationSchema,
    asset_accountNotesReceivable_validationSchema: asset_accountNotesReceivable_validationSchema,
    asset_automobiles_validationSchema: asset_automobiles_validationSchema
}


export default validationObj
