import React from "react";
import { Form, Row, Col, Image } from "react-bootstrap";
import SubMenuHeader from "../SubMenuHeader";
import { Link } from "react-router-dom";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import SubMethodsList from "../../SubMethodsList";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";

// sequence No. five  (Asmita date-29th June => Do not remove this line)
const MethodIdThirtySix = (props) => {


  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Accredited Investor",
      url: `/individual/verifyinvestor/accreditedInvestor/${props.verificationType}/${props.unique_code}`,
    },
  ];

  return (
    <>
      {props.is_loader.assets === true || props.is_loader.liabilities === true ? <SkeletonAllSubMethods /> :
        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="Knowledgeable Employees"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>
              <SubMenuHeader />
            </div>
            <div className="method-section">
              <div className="default-regular-body-text-l cs-neutral-70">
                <SubMethodsList methodId={38} />
              </div>
              <div className="cs-divider"></div>
              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert}
                />
                {props.setShowTrustUploadBlock && <div>
                  <Form.Label className="default-regular-sub-heading-s cs-neutral-90">Please upload evidence of who all the equity owners of the trust are (by uploading your trust documentation or this <Link className="cs-neutral-100" to="https://s3.amazonaws.com/static-capsync.com/Verify-Investor-files/All-Owners-Accredited-Officer-Certificate.pdf" target="_blank"><b>Officer’s Certificate</b></Link> along with a document to show ownership percentages). In a revocable trust, typically, the grantors are the equity owners.</Form.Label>
                  <UploadFileBlock
                    files={props.files2}
                    setOpenModal={props.setOpenModal2}
                    mediaAlert={props.mediaAlert}
                    deleteFile={props.deleteFile2}
                    showFile={props.showFile2}
                    pfsLink={props.pfsLink}
                    pfsName={props.pfsName}
                    pfsDelete={props.pfsDelete}
                    showDeleteSpinner={props.showDeleteSpinner}
                    showPfsLink={props.showPfsLink2} />
                </div>}
                <p className="row-spacing default-regular-body-text-l cs-neutral-70">
                  Any natural person who is a “knowledgeable employee,” as defined
                  in rule 3c-5(a)(4) under the Investment Company Act of 1940 (17
                  CFR 270.3c-5(a)(4)), of the issuer of the securities being offered
                  or sold where the issuer would be an investment company, as
                  defined in section 3 of such act, but for the exclusion provided
                  by either section 3(c)(1) or section 3(c)(7) of such act;
                </p>
                <Form.Label className="default-regular-sub-heading-s">
                  <span className="cs-neutral-90">
                    Upload evidence of position claimed:
                  </span>
                  <span className="cs-neutral-70"> (e.g. Incumbency Certificate or Officer's Certificate no
                    more than 90 days old) — Sample Officer's Certificate </span>
                  <Link
                    to="https://s3.amazonaws.com/static-capsync.com/Verify-Investor-files/OfficerCertificate-including-KE-and-FO.pdf"
                    target="_blank"
                    className="cs-neutral-100"
                  >
                    Here
                  </Link>
                </Form.Label>
                <div className={props.uploadFileOneError ? "investor-error" : ""} >
                  <UploadFileBlock
                    files={props.files}
                    setOpenModal={props.setOpenModal}
                    mediaAlert={props.mediaAlert}
                    deleteFile={props.deleteFile}
                    showFile={props.showFile}
                    pfsLink={props.pfsLink}
                    pfsName={props.pfsName}
                    pfsDelete={props.pfsDelete}
                    showDeleteSpinner={props.showDeleteSpinner}
                    showPfsLink={props.showPfsLink}
                    uploadError={props.uploadError}
                    showError={props.showError}
                    uploadFileOneError={props.uploadFileOneError}
                  />

                </div>
                <Form.Group
                  controlId="validationFormik01"
                >
                  <Form.Label>
                    <p className="default-regular-sub-heading-s cs-neutral-90">
                      Please enter the investment name or deal name of the company
                      of which you are a knowledgeable employee. (Note the name you
                      entered will appear on your accreditation letter and the
                      letter will be solely for the purpose of this investment.)
                    </p>
                  </Form.Label>
                  <Row className="cs-form-group row-spacing">
                    <Col sm={12} md={6} lg={6}>
                      <Form.Control
                        type="text"
                        placeholder="Name"
                        autoComplete="off"
                        name="Name"
                        // onKeyPress={(e) =>
                        //   !/[a-zA-z]/.test(e.key) && e.preventDefault()
                        // }
                        onChange={(e) => props.getNumberValue(e.target.value)}
                        value={props.formValue}
                        className={props.formValue.trim().length > 0 ? "cs-input-field-active" : props.checkInvestorError ? "investor-error" : ""}
                        maxLength={50}
                      />
                      {props.checkInvestorError && <span className="default-regular-sub-heading-xs cs-danger">Enter the investment name or deal name of the company of which you are a knowledgeable employee.</span>}

                    </Col>
                  </Row>
                </Form.Group>
                <NotesBlock setNotesValue={props.setNotesValue} />
              </Form>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default MethodIdThirtySix;
