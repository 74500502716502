/* eslint-disable */
import React, { useEffect, useState } from "react";
import { userFindRecord } from "../slices/user";
import { useDispatch, useSelector } from "react-redux";

const UseFetchDetails = () => {
  const { user } = useSelector((state) => state.auth);

  const [id,setId] = useState();
  
  useEffect(() => {
    checkUserId();
    function checkUserId() {
      if (window.localStorage.getItem("pfs_access_id") !== null) {
        setId(window.localStorage.getItem("pfs_access_id"));
      } else {
        user && user.user && user.user.id && setId(user.user.id);
      }
    }
  
    window.addEventListener('storage', checkUserId)
    
    return () => {
        window.removeEventListener('storage', checkUserId)
    }
  }, [])

  const dispatch = useDispatch();

  const [fetchUserData, setFetchUserData] = useState({});

  function findRecord() {
    dispatch(userFindRecord({ id: id })) 
      .unwrap()
      .then((response) => {
        setFetchUserData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (id) {
      findRecord();
    }
  }, [id]);

  return { fetchUserData };
};

export default UseFetchDetails;
