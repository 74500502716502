import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import { quickBooksShareReport } from "../../slices/quickbooks.slice";
import { useDispatch, useSelector } from "react-redux";
import { uniValidation } from "../../pages/individual/liabilities/categoryForms/validationSchema";
import { checkDomainAccept } from "../../util/common";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ShareReportModal = (props) => {
  const dispatch = useDispatch();
  const { business_id } = useParams();
  const { show, modalState, category, companyName } = props;
  const { user } = useSelector((state) => state.auth);
  let id;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const [disabled, setDisabled] = useState(true);

  const initialFields = {
    email: "",
    notes: "",
    lastName: "",
    firstName: "",
    selectRadio: "PDF",
  };

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .strict()
      // .matches(/^\S*$/, "No spaces allowed")
      .matches(/^\S(?:.*\S)?$/, "No spaces allowed")
      .min(2, "Required at least 2 characters")
      .max(40, uniValidation.first_name.max)
      .required("Enter first name"),
    lastName: yup
      .string()
      .strict()
      // .matches(/^\S*$/, "No spaces allowed")
      .matches(/^\S(?:.*\S)?$/, "No spaces allowed")
      .min(2, "Required at least 2 characters")
      .max(40, uniValidation.last_name.max)
      .required("Enter last name"),
    email: yup
      .string()
      // .matches(/^\S*$/, "Whitespace is not allowed")
      .required(uniValidation.email.required).test("is-not-only-spaces", uniValidation.email.required, (value) => {
        return value.trim() !== "";
      })
      .email(uniValidation.email.validEmail)
      .test(
        "no-more-than-30-before-at",
        "Cannot accept more than 30 characters before @",
        (value) => (value ? value.split("@")[0].length <= 30 : true)
      )
      .test(
        "at-least-1-character-before-at",
        "Enter at least 1 character before @",
        (value) => (value ? value.split("@")[0].length > 0 : true)
      )
      .test("custom-email-format", uniValidation.email.validEmail, (value) => {
        return value ? uniValidation.email.allowedFormat.test(value) : true;
      })
      .test("domain-acceptance", uniValidation.email.validEmail, (value) => {
        return checkDomainAccept(value);
      }),
  });

  // capitalize first letter of the first name or lastname(string)
  function capitalize(first_name_or_last_name) {
    return (
      first_name_or_last_name[0].toUpperCase() +
      first_name_or_last_name.slice(1)
    );
  }

  const { values, resetForm, errors, handleSubmit, setValues } = useFormik({
    initialValues: initialFields,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setDisabled(true);
      values.user_id = id;
      values.business_id = business_id;
      values.category = category;
      values.companyName = companyName;
      values.displayName = capitalize(values.firstName) + " " + capitalize(values.lastName);
      dispatch(quickBooksShareReport(values))
        .unwrap()
        .then((response) => {
          toast.success("Your mail has been sent successfully.");
          console.log("quickBooksShareReport:- ", response);
          resetForm();
          modalState(false);
          // if (response.code === 200) {
          // } else {
          //     console.log('response:- ', response);
          // }
        })
        .catch((err) => {
          toast.error("Failed to send the email.");
          console.log("fetchType:- ", err);
          return false;
        });
    },
  });

  function handleChange(key, data) {
    setDisabled(false);
    setValues({ ...values, [key]: data });
  }

  function cancelButton() {
    resetForm();
    modalState(false);
  }

  return (
    <Modal
      show={show}
      onHide={() => modalState(false)}
      backdrop="static"
      keyboard={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal quickbooks-share-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">Share Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label className="">First name</Form.Label>
                <Form.Control
                  value={values.firstName}
                  type="text"
                  autoComplete="off"
                  maxLength={40}
                  name="firstName"
                  onChange={(e) => handleChange("firstName", e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.firstName && (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.firstName}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  value={values.lastName}
                  type="text"
                  autoComplete="off"
                  maxLength={40}
                  name="lastName"
                  onChange={(e) => handleChange("lastName", e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.lastName && (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.lastName}
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  autoComplete="off"
                  value={values.email}
                  name="email"
                  onKeyDown={(e) => e.code === "Space" && e.preventDefault()}
                  onChange={(e) => handleChange("email", e.target.value)}
                />

                {errors.email && (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.email}
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form.Group className="cs-form-group last-email-field">
                <Form.Label>Notes</Form.Label>
                <textarea
                  className="cs-textarea"
                  type="text"
                  autoComplete="off"
                  placeholder="Optional"
                  value={values.notes}
                  name="notes"
                  onChange={(e) => handleChange("notes", e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="signature-return-box">
            {["PDF", "Excel"].map((type, index) => {
              return (
                <div key={`default-${type}`} className="cs-radio-btn">
                  <input
                    type="radio"
                    id={`report-${index}`}
                    className="radio-button"
                    checked={type === values.selectRadio}
                    name="selectRadio"
                    onChange={() => handleChange("selectRadio", type)}
                  />
                  <label className="default-regular-body-text-m cursor-pointer">
                    {type}
                  </label>
                </div>
              );
            })}
          </div>
          <div className="cs-modal-btn">
            <Button
              onClick={() => cancelButton()}
              className="cs-btn-secondary md-btn default-regular-h5"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="cs-btn-primary md-btn default-regular-h5"
              disabled={disabled}
            >
              Send
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ShareReportModal;
