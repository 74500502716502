/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Card, Button, Dropdown, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import NumberFormat from "react-currency-format";
import DataTable from "react-data-table-component";
import Skeleton from "react-loading-skeleton";
import {
  cancel_user_subscription,
  get_billing_and_subscription_by_user,
  trasfer_billing,
} from "../../../../slices/user_subscription";
import CommonSettingHeader from "../CommonHeader/CommonSettingHeader";
import "../../../individual/settings/Billing_subscription/billing_subscription.css";
import CapsyncSectionHeader from "../../../../commonComponent/CapsyncSectionHeader";
import CommonSettingDropdown from "../CommonHeader/CommonSettingDropdown";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import SkeletonBillingSubscription from "../../../individual/settings/Billing_subscription/SkeletonBillingSubscription";
import PlanCards from "../../../../commonComponent/paymentSubscription/subscription/PlanCards";
import BillingCardDetailModal from "../../../../commonComponent/paymentSubscription/payment/BillingCardDetailModal";
import CancelPlanFeedback from "../../../individual/settings/Billing_subscription/CancelPlanFeedback";
import { useNavigate } from "react-router-dom";
import DefaultPaymentMethod from "../../../../commonComponent/paymentSubscription/payment/DefaultPaymentMethod";
import { wealth_managers_findone } from "../../../../slices/auth";
import { customStyles } from "../../../../util/common";


const BillingSubscription = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { userBillingDetail } = useSelector((state) => state.userSubscription);
  const defaultCard = Array.isArray(userBillingDetail) && userBillingDetail.find((items) => items.is_active == true)
  const { userTransactionData } = useSelector((state) => state.userSubscription);
  const { userBillingHistory } = useSelector((state) => state.userSubscription);
  const catMenu = useRef(null)

  const [transactionData, setTransactionData] = useState([]);
  const [billingDetailModal, setBillingDetailModal] = useState(false);
  const [activeSection, setActiveSection] = useState("advisor_billing");
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
  const [checkStatus, setCheckStatus] = useState({ active: false, inactive: false });
  const [disabledStatus, setDisabledStatus] = useState({ active: false, inactive: false });
  const [show, setShow] = useState(false);
  const [historyLoader, setHistoryLoader] = useState(true);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [cancelSubscriptionId, setCancelSubscriptionId] = useState();

  const id = user && user.user.id;
  const role = user && user.user_role.role;

  useEffect(() => {

    if (user.user.is_beta_user === true) {
      if (role == 2) {
        navigate("/financial-advisor");
      } else {
        navigate("/professional");
      }
    }
      
  }, [])

  useEffect(() => {
    let values = {
      user_id: role === 1 ? (window.localStorage.getItem("pfs_access_id") !== null ? window.localStorage.getItem("pfs_access_id") : id) : 0,
      wm_user_id: (role === 2 || role === 3) ? (window.localStorage.getItem("pfs_access_id") !== null ? window.localStorage.getItem("pfs_access_id") : id) : 0,
      filter_by: activeSection === 'billing details' ? "" : activeSection,
    };
    dispatch(get_billing_and_subscription_by_user(values))
      .unwrap()
      .then((response) => { setHistoryLoader(false) })
      .catch((err) => {
      });
    setCheckStatus({ active: false, inactive: false })
  }, [activeSection])

  useEffect(() => {
    let isActiveDisabled = userBillingHistory.filter((item) => item.is_subscribe === true)
    let isInactiveDisabled = userBillingHistory.filter((item) => item.is_subscribe === false)
    setDisabledStatus({ active: isActiveDisabled && isActiveDisabled.length === 0 ? true : false, inactive: isInactiveDisabled && isInactiveDisabled.length === 0 ? true : false })
    if (activeSection != "billing details") { setTransactionData(userBillingHistory) }
  }, [userBillingHistory])

  useEffect(() => {
    if (activeSection == "billing details") {
      setTransactionData(userTransactionData)
    }
  }, [userTransactionData])

  function changeFilterStatus() {
    let fetchTransactions = userBillingHistory;
    if (checkStatus.active == true && checkStatus.inactive == true) {
      let store = fetchTransactions.map((x) => x);
      setTransactionData(store);
    } else if (checkStatus.active == true) {
      let store = fetchTransactions.filter((x) => x.is_subscribe === true);
      setTransactionData(store);
    } else if (checkStatus.inactive == true) {
      let store = fetchTransactions.filter((x) => x.is_subscribe === false);
      setTransactionData(store);
    } else {
      let store = fetchTransactions.map((x) => x);
      setTransactionData(store);
    }
  }
  useEffect(() => {
    changeFilterStatus()
  }, [checkStatus])

  const closeOpenMenus = (e) => {
    if (show && !!catMenu.current && !catMenu.current.contains(e.target)) {
      setShow(false)
    }
  }

  document.addEventListener('mousedown', closeOpenMenus)

  const statusFilter = (

    <div className="wm-clientbill-filter">
      <Dropdown className="cs-form-dropdown" show={show} ref={catMenu}>
        <Dropdown.Toggle onClick={() => setShow(!show)} >
          <span className="cs-neutral-100">
            <CapsyncIcon title="filter-filled" size="18" />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu autoClose="inside">
          <Dropdown.Item
            onClick={() =>
              setCheckStatus({
                ...checkStatus,
                active: !checkStatus.active
              })
            }
            className={`${disabledStatus.active ? "disabled " : ""} ${checkStatus.active ? " client-status-active " : ""} client-status-default`}
          >
            {checkStatus.active && (
              <span
                className={
                  checkStatus.active ? "" : "cs-primary"
                }
              >
                <CapsyncIcon title="check-outlined" size="14" />
              </span>
            )}
            < Form.Check
              type="checkbox"
              disabled={disabledStatus.active}
              className="cs-form-check-box"
              onChange={(e) => {
                setCheckStatus({
                  ...checkStatus,
                  active: e.target.checked
                })
              }}
              checked={checkStatus.active}
              label={
                <span className="default-regular-body-text-m cs-neutral-90"
                >
                  Active
                </span>
              }
            />
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() =>
              setCheckStatus({
                ...checkStatus,
                inactive: !checkStatus.inactive
              })
            }
            className={`${disabledStatus.inactive ? "disabled " : ""} ${checkStatus.inactive ? " client-status-active " : ""} client-status-default`}
          >
            {checkStatus.inactive && (
              <span
                className={
                  checkStatus.inactive ? "" : "cs-primary"
                }
              >
                <CapsyncIcon title="check-outlined" size="14" />
              </span>
            )}
            < Form.Check
              type="checkbox"
              disabled={disabledStatus.inactive}
              className="cs-form-check-box"
              onChange={(e) => {
                setCheckStatus({
                  ...checkStatus,
                  inactive: e.target.checked
                })
              }}
              checked={checkStatus.inactive}
              label={
                <span className="default-regular-body-text-m cs-neutral-90"
                >
                  Inactive
                </span>
              }
            />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  const transferBilling = (params, is_transfer) => {
    setHistoryLoader(true)
    dispatch(
      trasfer_billing({
        wm_user_id: id,
        user_id: params,
        is_transfer_to_wm_user: is_transfer,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.status === "Error") {
          props.toastMessage(response.message, "error");
          setTimeout(() => setHistoryLoader(false), 1500);
        } else {
          props.toastMessage(is_transfer ? "Your clients billing has been transferred to your firm successfully." : "The billing method has been transferred to your client successfully.", "success");
          let values = {
            user_id: role === 1 ? (window.localStorage.getItem("pfs_access_id") !== null ? window.localStorage.getItem("pfs_access_id") : id) : 0,
            wm_user_id: (role === 2 || role === 3) ? (window.localStorage.getItem("pfs_access_id") !== null ? window.localStorage.getItem("pfs_access_id") : id) : 0,
            filter_by: activeSection === 'billing details' ? "" : activeSection,
          };
          dispatch(get_billing_and_subscription_by_user(values))
            .unwrap()
            .then((response) => {
              setHistoryLoader(false)
            })
            .catch((err) => {
            });
        }
      })
      .catch((err) => {
        setHistoryLoader(false)
      });
  };

  const cancelSubscription = () => {
    dispatch(cancel_user_subscription({ id: cancelSubscriptionId, role: 1 }))
      .unwrap()
      .then((response) => {
        // toast.success("User plan has been Canceled!");
        setShowConfirmModal(false)
        let values = {
          user_id: role === 1 ? (window.localStorage.getItem("pfs_access_id") !== null ? window.localStorage.getItem("pfs_access_id") : id) : 0,
          wm_user_id: (role === 2 || role === 3) ? (window.localStorage.getItem("pfs_access_id") !== null ? window.localStorage.getItem("pfs_access_id") : id) : 0,
          filter_by: activeSection === 'billing details' ? "" : activeSection,
        };
        dispatch(get_billing_and_subscription_by_user(values))
          .unwrap()
          .then((response) => {
            if (response.code === 200) {
              dispatch(wealth_managers_findone({ wm_id: id }))
                .unwrap()
            }
          })
          .catch((err) => {
          });
      })
      .catch((err) => {
        return false;
      });
  };

  const columns = [
    {
      name: "Client name",
      selector: (tr_itr) => tr_itr.displayName,
      sortable: true,
      omit: activeSection === "billing details" ? true : false
    },
    {
      name: "Date",
      selector: (tr_itr) =>
        moment(new Date(tr_itr.createdAt)).format("YYYYMMDD"),
      cell: (tr_itr) => moment(new Date(tr_itr.createdAt)).format("MM/DD/YYYY"),
      sortable: true,
    },
    {
      name: "Details",
      selector: (tr_itr) => tr_itr.description ? tr_itr.description : "-",
      sortable: true,
    },
    {
      name: "Billing period",
      selector: (tr_itr) => tr_itr.billing_period ? tr_itr.billing_period : "-",
      sortable: true,
    },
    {
      name: <>Status {statusFilter}</>,
      selector: "status",
      sortable: false,
      cell: (row) => {
        return (
          <div className="wm-status-filter">
            <span
              className={
                row.is_subscribe === false
                  ? "default-regular-body-text-xs cs-badge cs-danger"
                  : "default-regular-body-text-xs cs-badge cs-success"
              }
            >
              {row.is_subscribe === false ? "Inactive" : "Active"}
            </span>
          </div>
        );
      },
      omit: activeSection === "billing details" ? true : false
    },
    {
      name: "Amount", selector: (tr_itr) => tr_itr.transaction_response ? JSON.parse(tr_itr.transaction_response).amount : tr_itr.amount / 100,
      cell: (tr_itr) => (
        <>
          <div className="dollar-value-gap amount-dollar-width">
            <span> $&nbsp;</span>
            <NumberFormat
              value={activeSection === "billing details" ? (
                tr_itr.transaction_response
                  ? JSON.parse(tr_itr.transaction_response).amount
                    ? "$" +
                    " " +
                    JSON.parse(tr_itr.transaction_response).amount / 100
                    : "$" + " " + 0
                  : ""
              ) : (
                tr_itr.amount / 100
              )}
              displayType={"text"}
              thousandSeparator={true}
            />
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: "",
      cell: (tr_itr) => (
        <div className={activeSection === "client_billing" ? "wm-client-option" : "wm-advisor-option"}>
          <Dropdown className="cs-form-dropdown">
            <Dropdown.Toggle>
              <span className="cs-neutral-80 cursor-pointer cs-table-menu-icon">
                <CapsyncIcon title="option-horizontal-filled" size="18" />
              </span>
            </Dropdown.Toggle>
            <div className={`${transactionData.length === 1 ? "show-first-row" : "show-normal-row"}`}>
              <Dropdown.Menu className="default-regular-sub-heading-s">
                {/* {activeSection === "advisor_billing" && <Dropdown.Item className={!tr_itr.is_auto_subscription ? "disabled" : ""} onClick={() => cancelSubscription(tr_itr.id)}> */}
                {activeSection === "advisor_billing" && <Dropdown.Item className={!tr_itr.is_auto_subscription ? "cs-disabled disabled" : ""} onClick={() => {
                  setShowFeedback(true)
                  setCancelSubscriptionId(tr_itr.id)
                }}>
                  Cancel subscription
                </Dropdown.Item>}
                <Dropdown.Item className={!tr_itr.is_auto_subscription ? "cs-disabled disabled" : ""} onClick={() => transferBilling(tr_itr.id, activeSection === "client_billing" ? true : false)}>
                  {activeSection === "client_billing" ? "Transfer billing to me" : "Transfer Billing to Client"}
                </Dropdown.Item>
              </Dropdown.Menu>
            </div>
          </Dropdown>
        </div>
      ),
      omit: activeSection === "billing details" ? true : false
    },
    {
      name: "Invoices",
      selector: (tr_itr) =>
      (
        <>
          <Button
            className="wm-setting-billing-exportbtn wm-set-bill-sub cs-btn-tertiary md-btn default-regular-sub-heading-m"
            href={tr_itr.receipt_url}
            target="_blank"
          >
            Export to PDF
          </Button>
        </>
      ),
      omit: activeSection != "billing details" ? true : false
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const switchSection = (sectionName) => {
    activeSection != sectionName && setHistoryLoader(true)
    setActiveSection(sectionName);
    // Close the dropdown on section switch
    setIsMobileDropdownOpen(false);
  };

  const toggleMobileDropdown = () => {
    setIsMobileDropdownOpen(!isMobileDropdownOpen);
  };

  return (
    <>
      <section className="settings-main-section">
        <Container fluid>
          {props.isLoader === true ? (
            <div className="cs-section-topbar">
              <div className="skeleton-breadcrumb">
                <div className="skeleton-breadcrumb-1">
                  {" "}
                  <Skeleton width={50} />
                </div>
                <div className="skeleton-breadcrumb-2">
                  {" "}
                  <Skeleton width={20} />
                </div>
                <div className="skeleton-breadcrumb-3">
                  <Skeleton width={50} />
                </div>
              </div>
              <div className="cs-title">
                <h1 className="default-semi-bold-h1 cs-neutral-100">
                  <Skeleton width={200} />
                </h1>
              </div>
            </div>
          ) : (
            <div className="cs-section-topbar">
              <CapsyncSectionHeader
                mainTitle="Dashboard"
                mainUrl={`${role === 2 ? "/financial-advisor" : "/professional"}`}
                pagetitle={"Settings"}
                subTitle="Settings"
              />
            </div>
          )}

          {props.isLoader === true ? (
            <div className="setting-mobile-dropdown for-mob">
              <Skeleton width="100" height={30} />
            </div>
          ) : (
            <CommonSettingDropdown />
          )}

          <Card>
            <div className="settings-body">
              {props.isLoader === true ? (
                <div className="for-des">
                  <div className="skeleton-account-tabs">
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={100} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={100} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={80} />
                    </p>
                    <p className="skeleton-account-tab">
                      {" "}
                      <Skeleton width={150} />
                    </p>
                  </div>
                </div>
              ) : (
                <CommonSettingHeader />
              )}
              {props.isLoader === true ? (
                <SkeletonBillingSubscription />
              ) : (
                <>
                  <div className="billing-subscription-page">
                    <PlanCards intentType={3} />
                    <Container fluid>
                      <Row>
                        <Col lg={4}>
                          <div className="payment-cards">
                            <div className="payment-method">
                              <span className="default-regular-sub-heading-l cs-neutral-100">
                                Payment Method
                              </span>
                              <div className="text-button cursor-pointer">
                                <Button
                                  type="submit"
                                  className="default-regular-h5 cs-btn-icon-tertiary"
                                  onClick={() => setBillingDetailModal(true)}>
                                  <span className="cs-primary">
                                    <CapsyncIcon title="add-filled" size="16" />
                                  </span>
                                  Add/Change method
                                </Button>
                              </div>
                            </div>
                            <DefaultPaymentMethod defaultCard={defaultCard} />
                            {/* {isPaymentByWmUser === true ? (
                              <>
                                <p className="default-light-body-text-s cs-neutral-70">
                                  Note: Your subscription is paid by
                                  {" " + paymentByWmUserName}
                                </p>
                                <span
                                  onClick={changeBilling}
                                  className="default-regular-sub-heading-s cs-primary cursor-pointer"
                                >
                                  Allow billing transfer to your account
                                </span>
                              </>
                            ) : null} */}
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="wm-billing">
                            <p className="default-regular-body-text-xl cs-neutral-100">
                              Billing history
                            </p>
                            <div className="cs-tabs">
                              {/* Show buttons on larger screens */}
                              <div className="desktop-billing-dropdown">
                                <Button
                                  onClick={() =>
                                    switchSection("advisor_billing")
                                  }
                                  className={
                                    activeSection === "advisor_billing"
                                      ? "cs-stroke-default-tab default-regular-h5 cs-stroke-active"
                                      : "cs-stroke-default-tab default-regular-h5"
                                  }>
                                  Professional-billed subscriptions
                                </Button>
                                <Button
                                  onClick={() =>
                                    switchSection("client_billing")
                                  }
                                  className={
                                    activeSection === "client_billing"
                                      ? "cs-stroke-default-tab default-regular-h5 cs-stroke-active"
                                      : "cs-stroke-default-tab default-regular-h5"
                                  }>
                                  Client-billed subscriptions
                                </Button>
                                <Button
                                  onClick={() =>
                                    switchSection("billing details")
                                  }
                                  className={
                                    activeSection === "billing details"
                                      ? "cs-stroke-default-tab default-regular-h5 cs-stroke-active"
                                      : "cs-stroke-default-tab default-regular-h5"
                                  }>
                                  Billing details
                                </Button>
                              </div>

                              {/* Show dropdown on smaller screens */}
                              <div className="cs-common-add-dropdown mobile-billing-dropdown">
                                <Dropdown
                                  show={isMobileDropdownOpen}
                                  onSelect={switchSection}>
                                  <Dropdown.Toggle
                                    onClick={toggleMobileDropdown}>
                                    {activeSection === "advisor_billing"
                                      ? "Professional-billed subscriptions"
                                      : activeSection === "client_billing"
                                      ? "Client-billed subscriptions"
                                      : "Billing details"}
                                    <span className="cs-neutral-80">
                                      <CapsyncIcon
                                        title="chevron-down-outlined"
                                        size="16"
                                      />
                                    </span>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      eventKey="advisor_billing"
                                      active={
                                        activeSection === "advisor_billing"
                                      }>
                                      Professional-billed subscriptions
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="client_billing"
                                      active={
                                        activeSection === "client_billing"
                                      }>
                                      Client-billed subscriptions
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="billing details"
                                      active={
                                        activeSection === "billing details"
                                      }>
                                      Billing details
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            {historyLoader ? (
                              <span className="cs-common-spinner cs-primary-main">
                                <CapsyncIcon
                                  title="loading-outlined"
                                  size="60"
                                />
                              </span>
                            ) : (
                              <div
                                className={
                                  "cs-data-table " +
                                  (activeSection &&
                                    activeSection.split(" ").join("_"))
                                }>
                                <DataTable
                                  columns={columns}
                                  data={transactionData}
                                  pagination
                                  // in
                                  paginationComponentOptions={paginationOptions}
                                  sortIcon={
                                    <>
                                      <span className="cs-neutral-100">
                                        <CapsyncIcon
                                          title="sort-outlined"
                                          size="22"
                                        />
                                      </span>
                                    </>
                                  }
                                  customStyles={customStyles}
                                />
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <BillingCardDetailModal
                      billingDetailModal={billingDetailModal}
                      setBillingDetailModal={setBillingDetailModal}
                      intentType={3}
                    />
                    <CancelPlanFeedback
                      showFeedback={showFeedback}
                      setShowFeedback={setShowFeedback}
                      confirmCancelPlan={cancelSubscription}
                      showConfirmModal={showConfirmModal}
                      setShowConfirmModal={setShowConfirmModal}
                    />
                  </div>
                </>
              )}
            </div>
          </Card>
        </Container>
      </section>
    </>
  );
};
export default BillingSubscription;
