import React from 'react'
import SkeletonCategoryTable from '../../individual/assets/asset_dashboard/SkeletonCategoryTable'
import Skeleton from 'react-loading-skeleton'

const SkeletonIndCompany = () => {
    return (
        <div className="cs-section-topbar">
            {/* <SkeletonCapsyncHeader /> */}
            <div className='skeleton-manage-container wm-skeleton-header for-des'>
                <div><Skeleton width={180} height={30} /></div>
                <div><Skeleton width={200} height={30} /></div>
                <div><Skeleton width={100} height={30} /></div>
            </div>
            <div className='skeleton-manage-container wm-skeleton-header for-mob'>
                <div className='skeleton-financial-header'>
                    <div><Skeleton width={180} /></div>
                    <div><Skeleton width={40} height={30} /></div>
                </div>
                <div><Skeleton width="100%" height={30} /></div>
            </div>
            <SkeletonCategoryTable tableData={[1, 2, 3]} />
        </div>
    )
}

export default SkeletonIndCompany