/* eslint-disable */
import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { Modal, Form, Row, Col, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import DatePicker from "react-date-picker";
import NumberFormat from "react-currency-format";
import { useFormik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import Select from "react-select";
import axios from "axios";
import * as yup from "yup";

import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import validationObj from "./validationSchema";
import { adjustLength } from "../../../../customHooks/adjustLength";
import {
  categoryAdd,
  categoryUpdateRecord,
  fetchAutomobileMarketValue,
  fetchMakeData,
  fetchModelData,
} from "../../../../slices/category.slice";
import commonNames from "../../../../config/commonNames";
import { combine, selectOptionsArr } from "../../../../util/common";
import { yearType } from "../../../../config/automobileArray";
import VinCarModal from "./VinCarModal";
import ConfirmEditModal from "../../../../commonComponent/ConfirmEditModal";
import { validateDuplicateFileName } from "../../../../config/validateDuplicateFileName";
import CapsyncLoader from "../../../../commonComponent/CapsyncLoader";
import { error } from "pdf-lib";
import { uniValidation } from "../../liabilities/categoryForms/validationSchema";
import {
  get_user_storage,
  useProgressUploadDetails,
  useUploadStorage,
} from "../../../../slices/fileManagement.slice";

const Automobile = (props) => {
  const { user } = useSelector((state) => state.auth);
  const { onFormClose, onFormSubmit, record, showModal } = props;

  const dispatch = useDispatch();

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const [loading, setLoading] = useState(false);
  const storageData = useUploadStorage();
  const progressUploadDetails = useProgressUploadDetails();
  const [initialValues, setInitialValues] = useState({
    user_id: id,
    description: "",
    creditor_name: "",
    market_value: "",
    payment: "",
    balance_due: "",
    vin: "",
    date_updated: moment().format(),
  });
  const [storeAcceptedFiles, setStoreAcceptedFiles] = useState([]);
  const [duplicateFileError, setDuplicateFileError] = useState(""); //Duplicate files error validation
  const [storageError, setStorageError] = useState("");
  const [isVehicleDBChecked, setIsVehicleDBChecked] = useState(true); // Check for Manual or API value
  const [showConfirmEditModal, setShowConfirmEditModal] = useState(false); // To keep or update edit data

  const [vehicleDBLoader, setVehicleDBLoader] = useState(false); //Loader for API value
  const [vehicleDBMarketValue, setVehicleDBMarketValue] = useState([]);
  const [showVinModal, setShowVinModal] = useState(false); //Vin modal for Trim & condition

  const [searchBy, setSearchBy] = useState("vin"); //VIN or YMM
  const [carDetailsVin, setCarDetailsVin] = useState({
    vin: "",
    trim: "",
    condition: "",
    market_value: "",
    description: "",
  });
  const [carDetailsYMM, setCarDetailsYMM] = useState({
    ymm: { year: 0, make: "", model: "" },
    trim: "",
    condition: "",
    market_value: "",
    description: "",
  });
  const [errorYmm, setErrorYmm] = useState(""); //Error for YMM
  const [vehicleDBError, setVehicleDBError] = useState(""); //API error
  const [makeType, setMakeType] = useState([]);
  const [modelType, setModelType] = useState([]);

  //YMM dropdown settings
  const stringYears = yearType.map((year) => String(year));
  const yearOptions = selectOptionsArr(stringYears);
  const makeOptions = selectOptionsArr(makeType);
  const modelOptions = selectOptionsArr(modelType);
  const [isMarketValueFetched, setIsMarketValueFetched] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isMakeLoading, setIsMakeLoading] = useState(false);
  const [isModelLoading, setIsModelLoading] = useState(false);

  const handleFocus = (dropdown) => {
    setActiveDropdown(dropdown);
  };

  const handleDropdownBlur = () => {
    setActiveDropdown(null);
  };
  const colourStyles = {
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#eef1ff"
          : isFocused
          ? "#eef1ff"
          : undefined,
        color: isSelected ? "#0024D4" : "#424242",
      };
    },
  };

  useEffect(() => {
    if (!!record) {
      const searchBy =
        record.vin !== "" || record.api_provider === "" ? "vin" : "ymm";
      let conditionVal = "";
      let trimVal = "";
      setInitialValues({
        user_id: record.user_id,
        description: record.description,
        creditor_name: record.creditor_name,
        market_value:
          record.market_value && record.market_value.toLocaleString("en-US"),
        payment: record.payment && record.payment.toLocaleString("en-US"),
        balance_due:
          record.balance_due && record.balance_due.toLocaleString("en-US"),
        vin: record.api_provider === "vehicleDB" ? record.vin : "",
        date_updated: new Date(
          moment(record.date_updated).format("MM/DD/YYYY")
        ),
        notes: record.notes,
      });
      setIsVehicleDBChecked(record.api_provider === "vehicleDB");
      setSearchBy(searchBy); //Set vin or ymm
      if (record.api_provider === "vehicleDB") {
        if (record.trimtype_vin && record.conditiontype_vin) {
          trimVal = record.trimtype_vin;
          conditionVal = record.conditiontype_vin;
        }

        if (record.trimtype_ymm && record.conditiontype_ymm) {
          trimVal = record.trimtype_ymm;
          conditionVal = record.conditiontype_ymm;
        }
        //setting all car details
        let vinData = { vin: "", trim: "", condition: "", market_value: "" };
        let ymmData = {
          ymm: { year: 0, make: "", model: "" },
          trim: "",
          condition: "",
          market_value: "",
        };
        if (searchBy === "vin") {
          vinData = {
            vin: record.vin ? record.vin : "",
            trim: trimVal,
            condition: conditionVal,
            market_value: record.market_value,
          };
        } else {
          ymmData = {
            ymm:
              searchBy == "ymm"
                ? {
                    year: Number(record.year),
                    make: record.make,
                    model: record.model,
                  }
                : { year: 0, make: "", model: "" },
            trim: trimVal,
            condition: conditionVal,
            market_value: record.market_value,
          };
        }
        //Store data for VIN
        setCarDetailsVin(vinData);
        //store data for YMM
        setCarDetailsYMM(ymmData);
      }
    }
  }, [record]);

  useEffect(() => {
    const {
      ymm: { year, make, model },
    } = carDetailsYMM;

    // if (year !== 0 && make === '' ) {
    //     // Set make list options on year basis
    //     fetchMake(year);
    // }
    // if ( make !== '') {
    //     //Set model list options on make basis
    //     fetchModel(year, make);
    // }
    if (!isMarketValueFetched) {
      if (activeDropdown === "make") {
        fetchMake(year);
      }
      if (activeDropdown === "model") {
        fetchModel(year, make);
      }
    }

    // Reset the market value fetched state
    setIsMarketValueFetched(false);
  }, [activeDropdown]);

  //Validation for Auto
  const asset_automobiles_validationSchema = yup.object().shape({
    description: yup
      .string()
      // .matches(/^\S.*$/, "Whitespace is not allowed")
      // .min(2, uniValidation.assets_and_liabilities.description.min)
      .max(100, uniValidation.assets_and_liabilities.description.max)
      .required(uniValidation.assets_and_liabilities.description.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.description.require, (value) => {
        return value.trim() !== "";
      }),
    vin:
      searchBy === "vin" &&
      isVehicleDBChecked &&
      yup
        .string()
        .required("Enter VIN number")
        .min(17, "Enter a valid VIN number")
        .max(17, "Enter a valid VIN number")
        .matches(
          /^[a-zA-Z0-9]+$/,
          "* This field cannot contain white space and special character"
        ),

    creditor_name: yup
      .string()
      // .matches(/^\S.*$/, "Whitespace is not allowed")
      // .min(2, uniValidation.assets_and_liabilities.creditor_name.min)
      .max(40, uniValidation.assets_and_liabilities.creditor_name.max),
    // .required(uniValidation.assets_and_liabilities.creditor_name.require).test("is-not-only-spaces", uniValidation.assets_and_liabilities.creditor_name.require, (value) => {
      //   return value.trim() !== "";
      // })
    market_value:
      !isVehicleDBChecked &&
      yup
        .string()
        .required("Enter market value")
        .test(
          "non-zero",
          "Market value must be greater than zero",
          (value) => Number(value.replace(/,/g, "")) !== 0
        )
        // .test(
        //     'checkDecimal',
        //     'Maximum two digits are allowed after the decimal point',
        //     (value) => {
        //         return value.indexOf(".") > -1 ? /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value) : true
        //     }
        // )
        .test(
          "Is positive?",
          "Balance due must be greater than zero",
          (value) => {
            return value && !(Number(value.replace(/,/g, "")) < 0);
          }
        ),
    payment: yup.string().optional(),
    // .test(
    //     'checkDecimal',
    //     'Maximum two digits are allowed after the decimal point',
    //     (value) => {
    //         return value && value.indexOf(".") > -1 ? /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value) : true
    //     }
    // )
    balance_due: yup.string().optional(),
    // .test(
    //     'checkDecimal',
    //     'Maximum two digits are allowed after the decimal point',
    //     (value) => {
    //         return value && value.indexOf(".") > -1 ? /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value) : true
    //     }
    // )
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: asset_automobiles_validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const trim = searchBy === "ymm" ? carDetailsYMM.trim : carDetailsVin.trim;
      const condition =
        searchBy === "ymm" ? carDetailsYMM.condition : carDetailsVin.condition;
      const marketValue =
        searchBy === "ymm"
          ? carDetailsYMM.market_value
          : carDetailsVin.market_value;

      values.market_value = isVehicleDBChecked
        ? marketValue.toLocaleString("en-US")
        : values.market_value.toLocaleString("en-US");
      if (
        isVehicleDBChecked &&
        searchBy === "ymm" &&
        (year == "" || make == "" || model == "")
      ) {
        setErrorYmm("Enter year, make, model");
        setLoading(false);
        return false;
      }
      if (values.market_value === 0 || values.market_value === "") {
        searchBy === "vin" &&
          setVehicleDBError("Please enter valid VIN number.");
        setShowVinModal(true);
        setLoading(false);
        return false;
      }
      try {
        record && (values.id = record.id);
        values.user_id = id;
        values.pfs_access_id = id ? id : 0;
        values.is_manual = !isVehicleDBChecked; //Manual or API Value
        values.api_provider = isVehicleDBChecked ? "vehicleDB" : "";

        if (carDetailsVin.vin !== "") {
          //vin db values for trim & condition
          values.trimtype_vin = trim;
          values.conditiontype_vin = condition;
          values.vin = carDetailsVin.vin;
          //reset ymm values
          values.trimtype_ymm = "";
          values.conditiontype_ymm = "";
          values.year = "";
          values.make = "";
          values.model = "";
        } else {
          //ymm db values for trim & condition
          values.trimtype_ymm = trim;
          values.conditiontype_ymm = condition;
          values.year = year;
          values.make = make;
          values.model = model;
          //reset vin vlaues
          values.vin = "";
          values.trimtype_vin = "";
          values.conditiontype_vin = "";
        }

        if (values.is_manual) {
          values.trimtype_ymm = "";
          values.conditiontype_ymm = "";
          values.year = "";
          values.make = "";
          values.model = "";
          values.trimtype_vin = "";
          values.conditiontype_vin = "";
          values.vin = "";
        }
        const inProgressData = progressUploadDetails.length
          ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
          : 0;
        const used_space = storageData.usedSpace + inProgressData;

        let data = {
          category: "automobile",
          main_category: "assets",
          used_space: used_space,
        };

        const formdata = new FormData();
        formdata.append("user_details", JSON.stringify(values));
        formdata.append("user_id", values.user_id);
        formdata.append("used_space", used_space);
        formdata.append("institution", values.description);
        for (let i = 0; i < storeAcceptedFiles.length; i++) {
          formdata.append("attachments", storeAcceptedFiles[i]);
        }

        if (!!record) {
          let payload = {
            category: "automobile",
            main_category: "assets",
          };
          payload = { ...payload, newData: values };
          dispatch(categoryUpdateRecord(payload))
            .unwrap()
            .then((response) => {
              if (response.code === 200) {
                onFormSubmit("automobile");
                toast.success(response.message);
                props.dashboardAPi();
              }
              if (response.code === 500) {
                if (response.message != "Please increase the Storage") {
                  setVehicleDBError(response.message); //DB error
                }
              }
              setLoading(false);
            })
            .catch((err) => {
              // setModalShow(false);
              toast.error(err.message);
              setLoading(false);
            });
        } else {
          values["used_space"] = used_space;
          data = { ...data, used_space: used_space, formdata: formdata };
          dispatch(categoryAdd(data))
            .unwrap()
            .then(async (response) => {
              if (response.code === 200) {
                await dispatch(get_user_storage({ id })).unwrap();
                onFormSubmit("automobile");
                // toast.success(response.message);
                props.dashboardAPi();
              }

              if (response.code === 500) {
                // setVehicleDBError(response.message);// DB error
                setStorageError(response.message);
              }
              setLoading(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
            });
        }
      } catch (error) {
        console.log("error -> error", error);
      }
    },
  });

  const handleChange = (e) => {
    setLoading(false); //unable form input
    setFieldValue(e.target.name, e.target.value);
    setVehicleDBError(""); //reset API value issue
    if (e.target.name === "vin") {
      //Update VIN new data
      setCarDetailsVin((prevState) => ({
        ...prevState,
        vin: e.target.value,
        trim: "",
        condition: "",
        market_value: "",
        description: "",
      }));
      //Clear extisting data for YMM
      setCarDetailsYMM({
        ymm: { year: 0, make: "", model: "" },
        trim: "",
        condition: "",
        market_value: "",
        description: "",
      });
      // setFieldValue('description', '');
      setFieldValue("vin", e.target.value.trim().toUpperCase()); //Only UPPERCASE allowed
      //Call API for Market value fetch for VIN
      if (e.target.value.length === 17) {
        if (/^[a-zA-Z0-9]+$/.test(e.target.value))
          fetchAutomobileVehicleMarketValue({ vin: e.target.value });
      }
    }
  };

  const handleChangeVehicleDb = () => {
    setLoading(false); //unable form input
    setErrorYmm(""); //reset YMM errors
    setVehicleDBError(""); //reset API value issue
    if (
      record &&
      record.id &&
      record.api_provider === "vehicleDB" &&
      !isVehicleDBChecked === false
    ) {
      setShowConfirmEditModal(true);
    } else {
      setIsVehicleDBChecked((prevState) => !prevState);
    }
  };

  const handleCarSearchByChange = (e) => {
    setLoading(false); //unable form input
    setErrorYmm(""); //reset YMM errors
    setVehicleDBError(""); //reset API value issue
    setSearchBy(e.target.value);
  };

  const handleChangeModel = (type, e) => {
    setLoading(false); //unable form input
    setErrorYmm(""); //reset YMM errors
    setVehicleDBError(""); //reset API value issue
    const {
      ymm: { year, make, model },
    } = carDetailsYMM;
    //When year changed for YMM selection
    if (type === "year") {
      setMakeType([]); // Clear makeType when year is changed
      setModelType([]);
      setCarDetailsYMM((prev) => ({
        ...prev,
        ymm: {
          ...prev.ymm,
          make: "",
          model: "",
        },
        trim: "",
        condition: "",
        market_value: "",
      }));
      // setFieldValue('description', '');
    } else if (type === "make") {
      setModelType([]);
      setCarDetailsYMM((prev) => ({
        ...prev,
        ymm: {
          ...prev.ymm,
          model: "",
        },
        trim: "",
        condition: "",
        market_value: "",
      }));
      // setFieldValue('description', '');
    }
    setCarDetailsYMM((prev) => ({
      ...prev,
      ymm: { ...prev.ymm, [type]: e.value },
      trim: "",
      condition: "",
      market_value: "",
      description: "",
    }));

    //Clear esiting VIN data on change of YMM
    setCarDetailsVin({
      vin: "",
      trim: "",
      condition: "",
      market_value: "",
      description: "",
    });

    if (type === "model" && e.value !== "") {
      fetchAutomobileVehicleMarketValue({ year, make, model: e.value });
      setIsMarketValueFetched(true);
    }
  };

  async function fetchMake(year) {
    try {
      makeType.length === 0 && setIsMakeLoading(true);
      const response = await dispatch(fetchMakeData({ year })).unwrap();
      if (response.code === 200) {
        setMakeType(response.data.makes);
        setIsMakeLoading(false);
      } else {
        setIsMakeLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsMakeLoading(false);
    }
  }

  async function fetchModel(year, make) {
    try {
      modelType.length == 0 && setIsModelLoading(true);
      const response = await dispatch(fetchModelData({ year, make })).unwrap();
      if (response.code === 200) {
        setModelType(response.data.models);
        setIsModelLoading(false);
      } else {
        setIsModelLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsModelLoading(false);
    }
  }

  const fetchAutomobileVehicleMarketValue = (data) => {
    setVehicleDBLoader(true);
    dispatch(fetchAutomobileMarketValue(data))
      .unwrap()
      .then((response) => {
        setVehicleDBLoader(false);
        if (response.code === 200) {
          const {
            basic: { year, make, model },
            market_value,
          } = response.data.data;
          setVehicleDBMarketValue(market_value.market_value_data);
          setFieldValue("description", `${year} ${make} ${model}`); //ymm display format
          // setDescriptionValue(`${year} ${make} ${model}`);//storing desc val
          setShowVinModal(true);
          if (market_value.market_value_data.length === 0) {
            setVehicleDBError(
              "Vehicle Databases cannot determine a market value for this car. Please enter a manual value."
            );
          }
        } else if (response.code === 500) {
          setVehicleDBMarketValue([]);
          setVehicleDBError(
            "Vehicle Databases cannot determine a market value for this car. Please enter a manual value."
          );
        } else {
          setVehicleDBMarketValue([]);
        }
      });
  };

  const handleCancelTrimCondition = () => {
    setShowVinModal(false);
  };

  //Set trim & condition with market value
  const handleContinueTrimCondition = (carObj) => {
    const { trim, condition, market_value } = carObj;
    setShowVinModal(false);
    setLoading(false);
    if (searchBy === "ymm") {
      setCarDetailsYMM((prevState) => ({
        ...prevState,
        trim: trim,
        condition: condition,
        market_value: market_value,
      }));
    } else {
      setCarDetailsVin((prevState) => ({
        ...prevState,
        trim: trim,
        condition: condition,
        market_value: market_value,
      }));
    }

    setFieldValue("market_value", market_value);
  };

  const handleUpdateMarketValue = (isUpdate) => {
    //on user update market value in edit
    setShowConfirmEditModal(false);
    setIsVehicleDBChecked(!isUpdate);
  };

  // Upload File Handler
  const removeAcceptedFile = (params) => {
    let store = [...storeAcceptedFiles];
    store.splice(params, 1);
    setStoreAcceptedFiles(store);
    setDuplicateFileError("");
    setStorageError("");
  };

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setDuplicateFileError("");
      setStorageError("");
      if (
        validateDuplicateFileName(
          acceptedFiles,
          storeAcceptedFiles,
          setDuplicateFileError
        )
      ) {
        setStoreAcceptedFiles([...acceptedFiles, ...storeAcceptedFiles]);
      }
    },
    [storeAcceptedFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    disabled: loading,
  });

  const files = storeAcceptedFiles.map((file, i) =>
    adjustLength(file.path, () => removeAcceptedFile(i))
  );

  const {
    ymm: { year, make, model },
  } = carDetailsYMM;
  const trim = searchBy === "ymm" ? carDetailsYMM.trim : carDetailsVin.trim;
  const condition =
    searchBy === "ymm" ? carDetailsYMM.condition : carDetailsVin.condition;
  const marketValue =
    searchBy === "ymm"
      ? carDetailsYMM.market_value
      : carDetailsVin.market_value;

  return (
    <>
      <Modal
        show={showModal}
        backdrop="static"
        id="connect-your-institutions"
        aria-labelledby="contained-modal-title-vcenter"
        className="cap-dash-main-modal automobile-popup"
        centered
        keyboard={false}
        dialogClassName="cs-lg-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            {commonNames.Automobile}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Row>
              <Col xs={12} md={12}>
                <Form.Group className="cs-form-group">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder=""
                    name="description"
                    onKeyDown={(e) => {
                      if (e.key === " " && e.target.selectionStart === 0) {
                        e.preventDefault();
                      }
                    }}
                    value={values.description}
                    className={values.description && "cs-input-field-active"}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                  />
                  {errors.description && touched.description ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.description}
                    </span>
                  ) : null}
                  {/* {vehicleDBError && <span className="form-error-msg default-light-body-text-s cs-danger">
                                        {vehicleDBError}
                                    </span>} */}
                  <div className="attom-data-lpd automobile-check">
                    <div className="bottom-text-box">
                      <span className="checkboxREalestate">
                        <Form.Check
                          type="checkbox"
                          checked={isVehicleDBChecked}
                          onChange={handleChangeVehicleDb}
                          className="cs-form-check-box"
                        />
                        <div className="d-flex">
                          <p
                            className="attom-mt cursor-pointer cs-neutral-90 default-regular-body-text-m"
                            onClick={handleChangeVehicleDb}
                          >
                            Use Vehicle Databases to get the current market
                            value of your car
                          </p>
                          <Image
                            src={
                              require("../../../../../assets/images/other/vehicle-databases-logo.svg")
                                .default
                            }
                            alt="Attomdata"
                            className="Attom-icon-img cursor-pointer"
                            onClick={handleChangeVehicleDb}
                            width={80}
                            height={14}
                          />
                        </div>
                      </span>
                    </div>
                    <p className="default-light-body-text-s cs-neutral-70 text-start">
                      By selecting this box, you agree to the Vehicle
                      Databases&nbsp;
                      <Link
                        className="cs-primary-main"
                        target="_blank"
                        to="https://vehicledatabases.com/terms-and-conditions"
                      >
                        Terms of Use.
                      </Link>
                    </p>
                  </div>
                </Form.Group>
              </Col>

              {isVehicleDBChecked && (
                <Col lg={12}>
                  <div className="select-car-details-box">
                    <h4 className="default-semi-bold-h4 cs-neutral-90">
                      Enter Car Details
                    </h4>
                    <div className="search-radio default-regular-body-text-m">
                      <span className="default-regular-body-text-m cs-neutral-100">
                        Search
                      </span>
                      <div className="automobile-responsive-radio">
                        <div className="cs-radio-btn">
                          <label>
                            <input
                              inline
                              name="toggle"
                              type="radio"
                              value="vin"
                              checked={searchBy === "vin"}
                              onChange={handleCarSearchByChange}
                              className="radio-button"
                            />
                            VIN
                          </label>
                        </div>
                        <div className="cs-radio-btn">
                          <label>
                            <input
                              inline
                              name="toggle"
                              type="radio"
                              value="ymm"
                              checked={searchBy === "ymm"}
                              onChange={handleCarSearchByChange}
                              className="radio-button"
                            />
                            Year, Make and Model
                          </label>
                        </div>
                      </div>
                    </div>

                    {searchBy === "vin" && (
                      <Col lg={12} md={6}>
                        <Form.Group className="cs-form-group last-car-field">
                          <Form.Label>Vehicle Identification Number</Form.Label>
                          <Form.Control
                            type="text"
                            autoComplete="off"
                            // placeholder="Enter Car VIN number"
                            name="vin"
                            value={values.vin}
                            className={values.vin && "cs-input-field-active"}
                            onChange={handleChange}
                            // onBlur={handleBlur}
                            maxLength={17}
                            onKeyDown={(e) =>
                              !/^[0-9a-zA-Z]*$/.test(e.key) &&
                              e.preventDefault()
                            }
                          />
                          {errors.vin && touched.vin && (
                            <span className="form-error-msg default-light-body-text-s cs-danger">
                              {errors.vin}
                            </span>
                          )}
                          {!errors.vin && vehicleDBError && (
                            <span className="form-error-msg default-light-body-text-s cs-danger">
                              {vehicleDBError}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    )}

                    {searchBy === "ymm" && (
                      <Row className="select-car-dropdown">
                        <Col lg={4} md={4}>
                          <Form.Group className="cs-form-group default-regular-body-text-m">
                            <Form.Label>Car Year</Form.Label>
                            <Select
                              options={yearOptions}
                              value={
                                year !== 0
                                  ? { value: year.toString(), label: year }
                                  : { label: "Choose year" }
                              }
                              onChange={(e) => handleChangeModel("year", e)}
                              styles={colourStyles}
                              isDisabled={
                                vehicleDBLoader ||
                                isMakeLoading ||
                                isModelLoading
                              }
                              onFocus={() => handleFocus("year")}
                              onBlur={handleDropdownBlur}
                            />
                            {errorYmm && (
                              <span className="form-error-msg default-light-body-text-s cs-danger">
                                {errorYmm}
                              </span>
                            )}
                            {vehicleDBError && (
                              <span className="form-error-msg default-light-body-text-s cs-danger">
                                {vehicleDBError}
                              </span>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={4} md={4}>
                          <Form.Group className="cs-form-group default-regular-body-text-m">
                            <Form.Label>Car Make</Form.Label>
                            <div className="automobile-dropdown-box">
                              <Select
                                options={makeOptions}
                                value={
                                  make !== ""
                                    ? { value: make, label: make }
                                    : { label: "Choose make" }
                                }
                                onChange={(e) => handleChangeModel("make", e)}
                                styles={colourStyles}
                                isDisabled={vehicleDBLoader || isModelLoading}
                                onFocus={() => handleFocus("make")}
                                onBlur={handleDropdownBlur}
                              />
                              {isMakeLoading === true && (
                                <span className="cs-common-spinner cs-primary">
                                  <CapsyncIcon
                                    title="loading-outlined"
                                    size="18"
                                  />
                                </span>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col lg={4} md={4}>
                          <Form.Group className="cs-form-group default-regular-body-text-m">
                            <Form.Label>Car Model</Form.Label>
                            <div className="automobile-dropdown-box">
                              <div className="cs-common-add-dropdown">
                                <Select
                                  options={modelOptions}
                                  value={
                                    model !== ""
                                      ? { value: model, label: model }
                                      : { label: "Choose model" }
                                  }
                                  onChange={(e) =>
                                    handleChangeModel("model", e)
                                  }
                                  styles={colourStyles}
                                  isDisabled={vehicleDBLoader || isMakeLoading}
                                  onFocus={() => handleFocus("model")}
                                  onBlur={handleDropdownBlur}
                                />
                              </div>
                              {isModelLoading === true && (
                                <span className="cs-common-spinner cs-primary">
                                  <CapsyncIcon
                                    title="loading-outlined"
                                    size="18"
                                  />
                                </span>
                              )}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    )}
                    {/* <span className="default-light-body-text-s cs-neutral-70">Note: Disable the option for manual car entry located above.</span> */}
                  </div>
                </Col>
              )}

              {isVehicleDBChecked && (
                <>
                  <Col xs={12} md={6}>
                    <Form.Group className="cs-form-group cs-disabled">
                      <Form.Label>Car Trim</Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        name="trim_name"
                        value={trim}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group className="cs-form-group cs-disabled">
                      <Form.Label>Car Condition</Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        name="condition_name"
                        value={condition}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className={"market-value-input"}>
                      <Form.Group
                        className={`cs-disabled cs-form-group `}
                        data="Market Value"
                      >
                        <div className="total-value-input">
                          <NumberFormat
                            thousandSeparator={true}
                            autoComplete="off"
                            placeholder=""
                            name="market_value"
                            onKeyDown={(e) => {
                              if (
                                e.key === " " &&
                                e.target.selectionStart === 0
                              ) {
                                e.preventDefault();
                              }
                            }}
                            prefix="$ "
                            value={marketValue}
                            // onBlur={handleBlur}
                            onChange={handleChange}
                            disabled={isVehicleDBChecked}
                            className={`text-md-end form-control ${
                              marketValue
                                ? "dollar-symbol cs-input-field-active"
                                : ""
                            }`}
                          />
                          {marketValue == 0 && vehicleDBLoader && (
                            <span className="cs-common-spinner cs-primary">
                              <CapsyncIcon title="loading-outlined" size="22" />
                            </span>
                          )}
                        </div>

                        {isVehicleDBChecked && (
                          <div className="market-value-box default-light-body-text-s cs-neutral-90">
                            Powered by{" "}
                            <Image
                              src={
                                require("../../../../../assets/images/other/vehicle-databases-logo.svg")
                                  .default
                              }
                              alt="Vehicle Database Logo"
                            />
                          </div>
                        )}
                      </Form.Group>
                    </div>
                  </Col>
                </>
              )}

              <Col xs={12} md={6}>
                {/* <Form.Group className={record && record.api_provider === "plaid" ? "cs-form-group cs-disabled" : "cs-form-group"}> */}
                <Form.Group className={"cs-form-group"}>
                  <Form.Label>Name of Creditor</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Optional"
                    name="creditor_name"
                    onKeyDown={(e) => {
                      if (e.key === " " && e.target.selectionStart === 0) {
                        e.preventDefault();
                      }
                    }}
                    value={values.creditor_name}
                    className={values.creditor_name && "cs-input-field-active"}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    // disabled={record && record.api_provider === "plaid"}
                  />
                  {errors.creditor_name && touched.creditor_name ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.creditor_name}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>

              {!isVehicleDBChecked && (
                <Col xs={12} md={6}>
                  <Form.Group className="cs-form-group">
                    <Form.Label>Market Value</Form.Label>
                    <NumberFormat
                      thousandSeparator={true}
                      autoComplete="off"
                      placeholder=""
                      name="market_value"
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.selectionStart === 0) {
                          e.preventDefault();
                        }
                      }}
                      value={values.market_value}
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      maxLength={12}
                      className={`${
                        values.market_value
                          ? "dollar-symbol cs-input-field-active"
                          : ""
                      } text-md-end form-control`}
                      onPaste={(e) => {
                        const clipboardData =
                          e.clipboardData || window.clipboardData;
                        const pastedText = clipboardData.getData("Text");

                        // Check if the pasted value is negative and prevent it if it is
                        if (parseFloat(pastedText) < 0) {
                          e.preventDefault();
                        }
                      }}
                      allowNegative={false}
                      decimalScale={2}
                    />

                    {errors.market_value && touched.market_value && (
                      <span className="form-error-msg default-light-body-text-s cs-danger">
                        {errors.market_value}
                      </span>
                    )}
                  </Form.Group>
                </Col>
              )}

              <Col xs={12} md={6}>
                <Form.Group className={"cs-form-group"}>
                  <Form.Label>Payment</Form.Label>
                  <NumberFormat
                    thousandSeparator={true}
                    maxLength={12}
                    autoComplete="off"
                    placeholder="Optional"
                    name="payment"
                    value={values.payment}
                    className={
                      // newDollar1 === true && values.payment
                      values.payment
                        ? "dollar-symbol text-md-end form-control cs-input-field-active"
                        : "text-md-end form-control"
                    }
                    onPaste={(e) => {
                      const clipboardData =
                        e.clipboardData || window.clipboardData;
                      const pastedText = clipboardData.getData("Text");

                      // Check if the pasted value is negative and prevent it if it is
                      if (parseFloat(pastedText) < 0) {
                        e.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    decimalScale={2}
                    allowNegative={false}
                    disabled={record && record.api_provider === "plaid"}
                  />
                  {errors.payment && touched.payment ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.payment}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>

              <Col xs={12} md={6}>
                <Form.Group className={"cs-form-group"}>
                  <Form.Label>Balance Due</Form.Label>
                  <NumberFormat
                    className={
                      values.balance_due
                        ? "dollar-symbol text-md-end form-control cs-input-field-active"
                        : "text-md-end form-control"
                    }
                    displayType={"input"}
                    thousandSeparator={true}
                    placeholder="Optional"
                    name="balance_due"
                    autoComplete="off"
                    value={values.balance_due !== 0 ? values.balance_due : ""}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    maxLength={12}
                    decimalScale={2}
                    allowNegative={false}
                    disabled={record && record.api_provider === "plaid"}
                    onKeyDown={(e) => {
                      if (e.key === "." && e.target.value === "") {
                        e.preventDefault(); // Prevent typing another dot if there's already one
                      }
                    }}
                  />
                  {errors.balance_due && touched.balance_due ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.balance_due}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>

              <Col xs={12} md={6}>
                <Form.Group className="cs-form-group">
                  <Form.Label>{commonNames.BalanceAs}</Form.Label>
                  <DatePicker
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    name="date_updated"
                    value={values.date_updated}
                    onChange={(e) =>
                      setFieldValue("date_updated", e) && setLoading(false)
                    }
                    // onBlur={handleBlur}
                    clearIcon={null}
                    format="MM/dd/y"
                    className={
                      values.date_updated
                        ? "cs-input-field-active select-date-range cs-date-picker-calender"
                        : "cs-date-picker-calender"
                    }
                    calendarIcon={
                      <span className="cs-neutral-90">
                        <CapsyncIcon title="calender-outlined" size="18" />
                      </span>
                    }
                    maxDate={new Date()}
                  />
                  {errors.date_updated && touched.date_updated ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.date_updated}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>

              <Col xs={12} md={12}>
                <Form.Group className="cs-form-group cs-form-textarea">
                  <Form.Label>Notes</Form.Label>
                  <textarea
                    autoComplete="off"
                    type="text"
                    placeholder="Optional"
                    name="notes"
                    value={values.notes}
                    className={
                      values.notes
                        ? "cs-input-field-active cs-textarea"
                        : "cs-textarea"
                    }
                    onChange={handleChange}
                    // onBlur={handleBlur}
                  ></textarea>
                </Form.Group>
              </Col>

              {!record && (
                <>
                  <Col xs={12} md={12}>
                    <Form.Label className="default-regular-sub-heading-xs cs-neutral-100">
                      Upload Files
                    </Form.Label>
                  </Col>
                  <Col xs={12} md={12}>
                    <div className="cs-upload-box">
                      <div
                        {...getRootProps({})}
                        className={`cs-upload-files cursor-pointer ${
                          loading && "cs-neutral-60 disabled"
                        }`}
                      >
                        <input
                          {...getInputProps()}
                          disabled={loading}
                          name="attachments"
                        />
                        <span className="cs-primary">
                          <CapsyncIcon title="upload-files-filled" size="28" />
                        </span>
                        <span className="default-regular-sub-heading-s cs-neutral-90">
                          <span className="cs-primary">Browse</span>&nbsp;or
                          drag file here
                        </span>
                        <p className="default-regular-sub-heading-xxs">
                          Supports PDF, PNG, JPG and JPEG format
                        </p>
                      </div>
                      <span className="form-error-msg default-light-body-text-s cs-danger">
                        {storageError || duplicateFileError}
                      </span>
                      <aside className="category-attachments-files">
                        <ul className="attachment-files cs-files">{files}</ul>
                      </aside>
                    </div>
                  </Col>
                </>
              )}

              <Col className="text-md-end text-finish-btn-center attom-footer-text">
                <div className="cs-modal-btn">
                  <Button
                    className="cs-btn-secondary md-btn default-regular-h5"
                    onClick={onFormClose}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="cs-btn-primary md-btn default-regular-h5"
                    disabled={loading}
                  >
                    Save
                    {loading && (
                      <span className="cs-common-spinner cs-primary-main">
                        <CapsyncIcon title="loading-outlined" size="16" />
                      </span>
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        {vehicleDBLoader && <CapsyncLoader />}
      </Modal>
      <VinCarModal
        show={showVinModal && vehicleDBMarketValue.length > 0}
        marketData={vehicleDBMarketValue}
        carTrimValue={trim}
        carConditionValue={condition}
        onClickCancel={handleCancelTrimCondition}
        onClickContinue={handleContinueTrimCondition}
      />
      <ConfirmEditModal
        showModal={showConfirmEditModal}
        onUpdateMarketValue={handleUpdateMarketValue}
      />
    </>
  );
};

export default Automobile;
