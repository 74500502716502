/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import LeftPart from "./LeftPart";
import { clearMessage } from "../../slices/message";
import { sendVerificationEmail } from "../../slices/auth";
import BackToSignin from "./BackToSignin";
import CapsyncIcon from "../../commonComponent/CapsyncIcon";
import AuthHead from "./AuthHead";

const emailVerification = () => {
  const [sendEmail, setSendEmail] = useState();
  const [loading, setLoading] = useState(false);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
    resendEmail();
    setSendEmail(false);
  }, [dispatch]);

  const statusCls =
    message && message.status === "Error"
      ? "cs-msg default-regular-body-text-s cs-danger"
      : "cs-msg default-regular-body-text-s cs-success";

  const resendEmail = () => {
    setLoading(true);
    const emailVerificationCode = window.localStorage.getItem("_signup_verify");
    dispatch(sendVerificationEmail({ emailVerificationCode }))
      .unwrap()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    setSendEmail(true);
  };

  return (
    <section className="auth-section email-verify-page">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={6}>
            <LeftPart
              page="verify-email"
              img="auth-image.svg"
              title="Your New Immersive Digital Finance Hub."
              text="Sync your real-time digital Personal Financial Statement with CapSync."
            />
          </Col>
          <Col lg={6}>
            <Row>
              <Col>
                <div className="auth-right-section">
                  {message && message.status === "Error" ? (
                    <>
                      <AuthHead title="Error" />
                      <div className="auth-content-area">
                        <p className="default-regular-h5  cs-neutral-80 ">
                          There is an invalid URL and reference.
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <AuthHead title="Verify your email address" />
                      <div className="auth-content-area">
                        <p className="default-regular-sub-heading-s cs-neutral-80">
                          Check your email for a verification link. Follow the
                          link to activate your CapSync account.
                        </p>
                        <p className="default-medium-sub-heading-s cs-neutral-100">
                          Didn't receive an email yet?
                        </p>
                        <Button
                          type="submit"
                          onClick={resendEmail}
                          className="cs-btn-primary lg-btn default-regular-h5"
                          // disabled={loading}
                        >
                          Resend Email
                        </Button>
                      </div>
                    </>
                  )}
                  {sendEmail && message && message.message && (
                    <div className={statusCls}>
                      <span className="icon">
                        {/* <CapsyncIcon title="declined-outlined" /> */}
                        <CapsyncIcon
                          title={`${
                            message.status === "Error"
                              ? "declined-outlined"
                              : "verification-outlined"
                          }`}
                        />
                      </span>
                      <span>{message.message}</span>
                    </div>
                  )}
                  <BackToSignin />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default emailVerification;
