import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  CloseButton,
  Col,
  Image,
  Modal,
  Table,
  Form,
  Container,
  Dropdown,
} from "react-bootstrap";
import img from "../../../../assets/icons/svg/outlined/search-outlined.svg";
import { Link, useNavigate } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import {
  wealth_companies_list,
  wealth_companies_partners,
  wealth_company_remove,
  company_user_delete,
} from "../../../slices/wealthManager.slice";
import { useDispatch, useSelector } from "react-redux";
// import commonNames from "../../../config/commonNames";
import AddModal from "./AddModal";
import EditAddModal from "./EditAddModal";
import EditModal2 from "./AddIndividual";
import ManageFiles from "./ManageFiles";
import {
  access_indiPFS_fromWealth_without_pfsid,
  company_add_recently,
  wm_delete_individual_account_from_company,
} from "../../../slices/pfs_access";
import DeleteValidation from "../../../../assets/images/other/delete-validation.svg";
import CapsyncLoader from "../../../commonComponent/CapsyncLoader";
import CapsyncToolTip from "../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import "./Company.css";
// import CategoryTableData from "../../../commonComponent/CategoryTableData";
import CompanyTable from "../CompanyTable";
import SkeletonIndCompany from "../Individual/SkeletonIndCompany";
import "../Individual/Individual.css"
import { socket } from "../../../config/Socket";

const Company = (props) => {
  const { user } = useSelector((state) => state.auth);
  const individuals = useSelector((state) => state.manageUser.individuals)
  const user_id = user && user.user.id;


  const [search, setSearch] = useState([]);
  // const [smLoader, setsmLoader] = useState(false);
  const [fetchRecords, setFetchRecords] = useState([]);
  // editAddmodalState,
  // editAddModalState,
  //   fetchAllCompanies,
  //   toastMessage,
  //   filteredCompanies,
  const [modalState, setModalState] = useState({
    show: false,
    hide: true,
  });
  const [editAddmodalState, setEditAddmodalState] = useState({
    show: false,
    hide: true,
  });
  const [addIndividualModalState, setAddIndividualModalState] = useState({
    show: false,
    hide: true,
    commonName: "",
    companyId: "",
  });
  const [companies, setFiltered] = useState([]);
  const [editPartners, setEditPartners] = useState([
    { email: "", firstName: "", lastName: "", id: 0 },
  ]);

  const [rowStartIdx, setRowStartIdx] = useState(0);
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownItems[0]);
  const [dropdownDisplay, setDropdownDisplay] = useState(rowsPerPage);

  const [storeFiles, listFiles] = useState([]);
  const [storeAllFiles, setAllFiles] = useState("");

  const [modalState_1, setModalState_1] = useState({
    show: false,
    hide: true,
  });

  const [modalState_2, setModalState_2] = useState({
    show: false,
    hide: true,
  });

  const [comp_data, setComp_data] = useState({
    CompanyID: "",
    RowID: "",
    AccessId: "",
    WealthId: "",
    CheckStatus: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changeModalState = (s, h) => {
    setModalState({
      show: s,
      hide: h,
    });
  };
  const changeAddEditModalState = (s, h) => {
    setEditAddmodalState({
      show: s,
      hide: h,
    });
  };

  const changeAddIndividualModalState = (s, h, companyName, companyId) => {
    setAddIndividualModalState({
      show: s,
      hide: h,
      companyName: companyName,
      companyId: companyId,
    });
  };
  const changeModalState_1 = (s, h, attachments, id) => {
    window.localStorage.setItem("row_id", id);
    setModalState_1({
      show: s,
      hide: h,
    });
    if (attachments !== null) {
      const allFiles = attachments;
      setAllFiles(allFiles);
      const collectFiles = allFiles.split(",");
      listFiles(collectFiles);
    } else {
      setAllFiles("");
      listFiles([]);
    }
  };

  const changeModalState_2 = (
    s,
    h,
    rowID,
    companyID,
    accessID,
    WealthId,
    check_status
  ) => {
    setComp_data({
      RowID: rowID,
      CompanyID: companyID,
      AccessId: accessID,
      WealthId: WealthId,
      CheckStatus: check_status,
    });
    setModalState_2({
      show: s,
      hide: h,
    });
  };

  const fetchAllCompanies = () => {
    dispatch(wealth_companies_list({ user_id: user_id }))
      .unwrap()
      .then((response) => {
        setFetchRecords(response.fetchwealth_manager_companies);
        const sortedCompaniesArray = response.fetchwealth_manager_companies.sort((company1, company2) => company1.id - company2.id);
        setFiltered(sortedCompaniesArray);
      })
      .catch((err) => {

        props.toastMessage(err.message, "error");

      })
  };

  const [useParams, setUseParams] = useState("");
  const [compName, setCompName] = useState("");

  // const fetchPartners = (params) => {
  //   setsmLoader(true);
  //   let comp_name = fetchRecords.filter((itr) => itr.id === params);
  //   setCompName(comp_name[0].companyName);

  //   setUseParams(params);
  //   dispatch(wealth_companies_partners({ comp_id: params }))
  //     .unwrap()
  //     .then((response) => {
  //       setCompanyPartners(response);
  //       setsmLoader(false);
  //     })
  //     .catch((err) => {
  //       setsmLoader(false);
  //       props.toastMessage(err.message, "error");
  //     });
  // };

  useEffect(() => {
    fetchAllCompanies();
  }, [individuals]);



  function deleteFunc_2(params) {
    dispatch(wealth_company_remove({ c_id: params }))
      .unwrap()
      .then(() => {
        props.toastMessage("Your record has been deleted successfully. ", "success");
        setTimeout(() => {
          fetchAllCompanies();
        }, 2000);
      })
      .catch((err) => {
        props.toastMessage(err.message, "error");
      });
  }

  function deleteRecordEntirely(id, AccessId, WealthId, comp_id) {
    dispatch(
      wm_delete_individual_account_from_company({
        id,
        AccessId,
        WealthId,
        comp_id,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          changeModalState_2(false, true);
          props.toastMessage("Your record has been deleted successfully. ", "success");
          fetchAllCompanies();
        } else {
          props.toastMessage(response.message, "error");
        }
      })
      .catch((err) => {
        props.toastMessage(err.message, "error");
      });
  }

  function deleteRecord(id, access_id, wm_id, comp_id) {
    dispatch(company_user_delete({ id, access_id, wm_id, comp_id }))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          changeModalState_2(false, true);
          props.toastMessage("Your record has been deleted successfully. ", "success");
          fetchAllCompanies();
        }
      })
      .catch((err) => {
        props.toastMessage(err.message, "error");
      });
  }

  const searchCompanies = (params) => {
    setSearch(params);
    let findCompany = fetchRecords.filter((x) => {
      return (
        x.companyName.toLowerCase().match(params.toLowerCase()) ||
        x.companyMembers.toString().match(params)
      );
    });
    setFiltered(findCompany);
  };


  function fileAttachment(params) {
    const allFiles = params;
    setAllFiles(allFiles);
    const collectFiles = allFiles.split(",");
    listFiles(collectFiles);
  }
  const handlePaginationDropdownClick = (dropdownValue) => {
    setDropdownDisplay(dropdownValue)
    setRowStartIdx(0);
    if (dropdownValue === 'All') {
      setRowsPerPage(companies.length);
      return;
    }
    setRowsPerPage(dropdownValue);
  }
  const handleFirstPageClick = () => {
    setRowStartIdx(0);
    // Assuming your rowsPerPage is already set appropriately
  };

  const handleLastPageClick = () => {
    const totalRows = companies.length;
    const lastPageStartIdx = Math.floor((totalRows - 1) / rowsPerPage) * rowsPerPage;
    setRowStartIdx(lastPageStartIdx);
  };

  const currentNumberOfRows = (rowStartIdx, rowsPerPage, lengthOfCompanies) => {
    if (rowStartIdx + rowsPerPage <= lengthOfCompanies) {
      return rowsPerPage;
    } else {
      return lengthOfCompanies - rowStartIdx;
    }
  }

  return (
    <>
      {/* modal for deleting partners in company  */}
      <Modal
        show={modalState_2.show}
        onHide={modalState_2.hide}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">Delete Individual</Modal.Title>
          <CloseButton onClick={() => changeModalState_2(false, true)} />
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <span>
              <Image src={DeleteValidation} alt="Delete Validation" width={100} height={100} />
            </span>
            <p className="default-light-body-text-m cs-neutral-90">
              Are you sure that you want remove this user from your account?
            </p>
            {comp_data.CheckStatus === 1 && (
              <>
                <div className="">
                  <Button
                    className="cs-btn-secondary default-regular-h5"
                    onClick={() =>
                      deleteRecordEntirely(
                        comp_data.RowID,
                        comp_data.AccessId,
                        comp_data.WealthId,
                        comp_data.CompanyID
                      )
                    }
                  >
                    {window.innerWidth >= 200 && window.innerWidth <= 575
                      ? "Remove From Ind. & Company"
                      : "Remove From Individual & Company"}
                  </Button>
                  <span>
                    <CapsyncToolTip
                      Child={"info-outlined"}
                      placement={"top"}
                      message={
                        "Select this option if you want to remove the individual from your account entirely, including their association with any companies."
                      }
                      type="icon"
                      size={18}
                    />
                  </span>
                </div>
                <span className="">OR</span>
              </>
            )}

            <div className="">
              <Button
                className="cs-btn-secondary default-regular-h5"
                onClick={() =>
                  deleteRecord(
                    comp_data.RowID,
                    comp_data.AccessId,
                    comp_data.WealthId,
                    comp_data.CompanyID
                  )
                }
              >
                Remove From Company
              </Button>
              <span>
                <CapsyncToolTip
                  Child={"info-outlined"}
                  placement={"right"}
                  message={
                    "Select this option if you want to remove the individual only from the selected company, but keep their account intact."
                  }
                  type="icon"
                  size={18}
                />
              </span>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <ManageFiles
        modalState_1={modalState_1}
        changeModalState_1={changeModalState_1}
        storeFiles={storeFiles}
        storeAllFiles={storeAllFiles}
        fileAttachment={fileAttachment}
        toastMessage={props.toastMessage}
      />

      <AddModal
        modalState={modalState}
        changeModalState={changeModalState}
        fetchAllCompanies={fetchAllCompanies}
        toastMessage={props.toastMessage}
        filteredCompanies={companies}
      />
      {/* <EditModal
        changeEditModalState={changeEditModalState}
        editModalState={editModalState}
        filteredCompanies={companies}
        toastMessage={props.toastMessage}
        fetchAllCompanies={fetchAllCompanies}
        changeAddEditModalState={()=>console.log("this is callled yessss")}
      /> */}


      <EditModal2
        changeAddIndividualModalState={changeAddIndividualModalState}
        addIndividualModalState={addIndividualModalState}
        filteredCompanies={companies}
        toastMessage={props.toastMessage}
        fetchAllCompanies={fetchAllCompanies}
        partner={editPartners}
        setPartners={setEditPartners}
      // companyPartners={companyPartners}
      />

      {props.isLoader === true ? (
        <SkeletonIndCompany />
      ) : (
        <>
          <section className="dashboard-main-section">
            <Container fluid>


              <div className="cs-section-topbar financial-advisor-topbar">
                <div className="mobile-add-head-button">
                  <h1 className="default-semi-bold-h1 cs-neutral-100">
                    Companies
                  </h1>
                  <span className="cs-neutral-10 mobile-add-acc-icon" onClick={() => changeModalState(true, false)}>
                    <CapsyncIcon title="add-filled" size="18" />
                  </span>
                </div>
                <div className="cs-search-bar">
                  <Form.Group className="cs-form-group">
                    <Form.Control
                      type="text"
                      placeholder="Search by company name"
                      value={search}
                      className={search && "cs-input-field-active"}
                      onChange={(event) => searchCompanies(event.target.value)}
                    />
                    <span className="input-field-icon cs-neutral-70">
                      <CapsyncIcon title="search-outlined" size="18" />
                    </span>
                  </Form.Group>
                </div>

                <Button
                  type="submit"
                  className="default-regular-h5 cs-btn-icon-primary desktop-add-button"
                  onClick={() => changeModalState(true, false)}
                >
                  <span className="cs-neutral-10">
                    <CapsyncIcon title="add-filled" size="18" />
                  </span>
                  Create Company
                </Button>
              </div>

              <CompanyTable
                fetchAllCompanies={fetchAllCompanies}
                forDashboard={false}
                data={companies}
                deleteFunc_2={deleteFunc_2}
                toastMessage={props.toastMessage}
                deleteRecordEntirely={deleteRecordEntirely}
                changeAddEditModalState={changeAddEditModalState}
                filteredCompanies={companies}
                editAddmodalState={editAddmodalState}
                partners={editPartners}
                setPartners={setEditPartners}
                rowStartIdx={rowStartIdx}
                rowsPerPage={rowsPerPage}
                setRowStartIdx={setRowStartIdx}
                currentNumberOfRows={currentNumberOfRows}
                accessPFS={props.accessPFS}
              />
              {/*<EditAddModal
        editAddmodalState={editAddmodalState}
        changeAddEditModalState={changeAddEditModalState}
        fetchAllCompanies={fetchAllCompanies}
        toastMessage={props.toastMessage}
        filteredCompanies={companies}
      /> */}


              {/* This is the code for the companies acordian table pagination */}
              {companies.length > 0 && (<div className="cs-custom-pagination">
                <div className="cs-common-add-dropdown">
                  <span className="default-light-body-text-m cs-neutral-80">Rows per page:</span>
                  <Dropdown>
                    <Dropdown.Toggle className="default-light-body-text-m cs-neutral-80">
                      {dropdownDisplay}
                      <CapsyncIcon title="chevron-down-outlined" size="12" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu onSelect={(e) => console.log(e)}>
                      {paginationDropdownItems.map((noOfRowsPerPage) => {
                        return (
                          <Dropdown.Item
                            className={noOfRowsPerPage === dropdownDisplay && "cs-selected-dropdown"}
                            onClick={() => handlePaginationDropdownClick(noOfRowsPerPage)}
                            value={noOfRowsPerPage}>{noOfRowsPerPage}
                          </Dropdown.Item>);
                      })}
                      <Dropdown.Item onClick={() => handlePaginationDropdownClick('All')} className={"All" === dropdownDisplay && "cs-selected-dropdown"}>All</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="default-light-body-text-m cs-neutral-80">
                  <p>{rowStartIdx + 1} - {rowStartIdx + (rowStartIdx, rowsPerPage, companies.length)} of {companies.length}</p>
                </div>
                <div className="cs-pagination-arrow">
                  <Button
                    onClick={handleFirstPageClick}
                    disabled={rowStartIdx === 0}
                    className={rowStartIdx === 0 ? "cs-neutral-50" : "cs-neutral-100"}>
                    <CapsyncIcon title="chevron-left-skip-outlined" size="18" />
                  </Button>
                  <Button
                    onClick={() => setRowStartIdx(rowStartIdx - rowsPerPage)} disabled={rowStartIdx === 0}
                    className={rowStartIdx === 0 ? "cs-neutral-50" : "cs-neutral-100"}>
                    <CapsyncIcon title="chevron-left-outlined" size="18" />
                  </Button>
                  <Button onClick={() => setRowStartIdx(rowStartIdx + rowsPerPage)}
                    className={!(rowStartIdx + rowsPerPage < companies.length) ? "cs-neutral-50" : "cs-neutral-100"}
                    disabled={!(rowStartIdx + rowsPerPage < companies.length)}>
                    <CapsyncIcon title="chevron-right-outlined" size="18" />
                  </Button>
                  <Button
                    onClick={handleLastPageClick}
                    className={!(rowStartIdx + rowsPerPage < companies.length) ? "cs-neutral-50" : "cs-neutral-100"}
                    disabled={!(rowStartIdx + rowsPerPage < companies.length)}>
                    <CapsyncIcon title="chevron-right-skip-outlined" size="18" />
                  </Button>
                </div>
              </div>)}
            </Container>
          </section>
        </>
      )}
    </>
  );
};

export default Company;
