/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import React, { useEffect } from "react";
import { backAppURl, advisorSearchAPI } from "../applicationMode";
import axios from "axios";
import Cookies from "universal-cookie";
import moment from "moment";
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { usAreaCodes } from "../config/countryAreaCodes";
import * as yup from "yup";
import { uniValidation } from "../pages/individual/liabilities/categoryForms/validationSchema";
import CapsyncIcon from "../commonComponent/CapsyncIcon";
import { useState, useCallback, useRef } from "react";
import AWS from "../config/aws-config";
import { showUploadProgress } from "../slices/fileManagement.slice";
import { toast } from "react-toastify";
import commonNames from "../config/commonNames";

function combine(e, check) {
  let obj = {};
  const storeValue = e;
  obj.status = storeValue ? true : false;
  obj.value = check;
  return obj;
}

function DateFormat(dateString) {
  return moment(dateString, "YYYY-MM-DD").format("MM/DD/YYYY");
}

function fetchMinutes(params) {
  const seconds = Math.floor(
    (new Date().getTime() - new Date(params).getTime()) / 1000
  );
  let interval = seconds / 31536000;
  const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });
  if (interval > 1) {
    return rtf.format(-Math.floor(interval), "year");
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return rtf.format(-Math.floor(interval), "month");
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return rtf.format(-Math.floor(interval), "day");
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return rtf.format(-Math.floor(interval), "hour");
  }
  interval = seconds / 60;
  if (interval > 1) {
    return rtf.format(-Math.floor(interval), "minute");
  }
  return rtf.format(-Math.floor(interval), "second");
}

function datediff_day(date) {
  let currentDate = new Date().toJSON().slice(0, 10);
  if (currentDate === date) {
    return "today";
  } else {
    const dt1 = new Date(currentDate);
    const dt2 = new Date(date);
    const diffDT = Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );

    if (diffDT === 1) {
      return diffDT + " day left";
    } else {
      return diffDT + " days left";
    }
  }
}

// function calculateAmortizationNotesReceivable(Principal, rate, time, num, payFrequency) {
//   const interestRate = rate / 100; // Convert the interest rate from percentage to decimal
//   const ratePerPeriod = interestRate / num;
//   let numberOfPayments = time;
//   const numerator = 1 - Math.pow(1 + ratePerPeriod, -numberOfPayments);
//   const monthlyPaymentt = (Principal * ratePerPeriod) / numerator;
//   return monthlyPaymentt;
// }

function calculateAmortization(Principal, rate, time, num, payFrequency) {
  const interestRate = rate / 100; // Convert the interest rate from percentage to decimal
  const ratePerPeriod = interestRate / num;
  let numberOfPayments = time;
  const numerator = 1 - Math.pow(1 + ratePerPeriod, -numberOfPayments);
  const monthlyPaymentt = (Principal * ratePerPeriod) / numerator;
  return monthlyPaymentt;
}

function calculatePaymentPeriod(P, r, t, n) {
  // eslint-disable-next-line eqeqeq
  let principal = P == "" ? 0 : P;
  const interestRate = r / 100;
  const ratePerPeriod = interestRate / n;

  const numerator = 1 - Math.pow(1 + ratePerPeriod, -(n * t));

  let TotalBalance =
    typeof P == "number"
      ? principal
      : P.includes(",")
      ? principal.replace(/,/g, "")
      : principal;

  const monthlyPaymentt = (TotalBalance * ratePerPeriod) / numerator;

  let simpleInterest = monthlyPaymentt * (t * n);

  // eslint-disable-next-line eqeqeq
  if (P == 0 || r == 0 || t == 0 || n == 0) {
    return 0;
  } else {
    return simpleInterest.toFixed(2);
  }
}

function calculateAmortizationLiabilityAutomobile(
  Principal,
  rate,
  time,
  num,
  payFrequency
) {
  const interestRate = rate / 100;
  const ratePerPeriod = interestRate / num;
  let numerator = payFrequency === "Yearly" ? -(num * time) : -time;
  const monthlyPaymentt =
    (Principal * ratePerPeriod) / 1 - Math.pow(1 + ratePerPeriod, numerator);
  return monthlyPaymentt;
}

async function attomdata_address_find(address) {
  const options = {
    method: "GET",
    url: `https://api.gateway.attomdata.com/propertyapi/v1.0.0/property/address?address=${address}`,

    headers: {
      "content-type": " application/json",
      apikey: process.env.REACT_APP_ATTOMDATA_REALESTATE_KEY,
      // "X-RapidAPI-Host": "realty-mole-property-api.p.rapidapi.com	",
    },
  };
  return options;
}

async function attomdata_price_fetch(propertyDetails) {
  const optionsPrice = {
    method: "GET",
    url: `https://api.gateway.attomdata.com/propertyapi/v1.0.0/allevents/detail?id=${propertyDetails}`,

    headers: {
      "content-type": " application/json",
      // apikey: "b008e81acdddcf95c184cb25e08078e1",
      apikey: process.env.REACT_APP_ATTOMDATA_REALESTATE_KEY,
      // "X-RapidAPI-Host": "realty-mole-property-api.p.rapidapi.com	",
    },
  };
  return optionsPrice;
}

function generateString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function addHeaderInAxiosAPI() {
  let token = window.localStorage.getItem("user")
    ? JSON.parse(window.localStorage.getItem("user")).token
    : "";
  const url = axios.create({
    baseURL: backAppURl + "/api",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return url;
}

function headerAddToken() {
  let token = window.localStorage.getItem("user")
    ? JSON.parse(window.localStorage.getItem("user")).token
    : "";
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

function mongoDBAxiosAPI() {
  const url = axios.create({
    baseURL: advisorSearchAPI,
    // headers: {
    //   Authorization: `Bearer ${token}`,
    // },
  });
  return url;
}
const renderHTML = (rawHTML) =>
  React.createElement("div", {
    dangerouslySetInnerHTML: { __html: rawHTML },
  });

function loginStatusStore(role_id, user_id) {
  sessionStorage.setItem(
    "loginStatus",
    JSON.stringify({
      loginStatus: true,
      user_role: role_id,
    })
  );
  sessionStorage.setItem("id", user_id);
  localStorage.setItem(
    "loginStatus",
    JSON.stringify({
      loginStatus: true,
      user_role: role_id,
    })
  );
  localStorage.setItem("id", user_id);
  return true;
}

function deviceVerifyCookieStore(id, deviceVerify) {
  const cookies = new Cookies();
  if (deviceVerify) {
    cookies.set(`deviceAuth24Hr-${id}`, `${deviceVerify}`, {
      path: "/",
      expires: new Date(Date.now() + 86400000 * 30),
    });
  }
  return true;
}

function currencyFormat(num) {
  return Number(num)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

const getValidAddress = (placesData) => {
  let address = {
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
  };
  let isValid = {
    street_number: false,
    city: false,
  };
  placesData.forEach((component) => {
    if (component.types.includes("street_number")) {
      address["line1"] = component.long_name;
      isValid.street_number = true;
    }
    if (component.types.includes("route")) {
      address["line1"] += " " + component.long_name;
    }
    if (component.types.includes("subpremise")) {
      address["line2"] = component.short_name;
    }
    if (component.types.includes("locality")) {
      address["city"] = component.long_name;
      isValid.city = true;
    } else if (component.types.includes("sublocality") && !address["city"]) {
      address["city"] = component.long_name;
      isValid.city = true;
    }
    if (component.types.includes("administrative_area_level_1")) {
      address["state"] = component.long_name;
    }
    if (component.types.includes("administrative_area_level_1")) {
      address["state_code"] = component.short_name;
    }
    if (component.types.includes("country")) {
      address["country"] = component.short_name;
    }
    if (component.types.includes("postal_code")) {
      address["postal_code"] = component.short_name;
    }
  });
  if (!isValid.city) {
    return {
      error: "Address must have city.",
      address,
    };
  } else if (!isValid.street_number) {
    return {
      error: "Address must have street number.",
      address,
    };
  }
  return address;
};

const getAddressObject = (placesData) => {
  let address = {
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
  };

  // eslint-disable-next-line array-callback-return
  placesData.filter((component) => {
    if (component.types.includes("street_number")) {
      if (address["line1"]) {
        address["line1"] = component.long_name + " " + address["line1"];
      } else {
        address["line1"] = component.long_name;
      }
    }
    if (component.types.includes("route")) {
      if (address["line1"]) {
        address["line1"] += " " + component.long_name;
      } else {
        address["line1"] = component.long_name;
      }
    }
    if (component.types.includes("subpremise")) {
      address["line2"] = component.short_name;
    }
    if (
      component.types.includes("locality") ||
      component.types.includes("sublocality_level_1")
    ) {
      address["city"] = component.long_name;
    }
    if (component.types.includes("administrative_area_level_1")) {
      address["state"] = component.long_name;
    }
    if (component.types.includes("administrative_area_level_1")) {
      address["state_code"] = component.short_name;
    }
    if (component.types.includes("country")) {
      address["country"] = component.short_name;
    }
    if (component.types.includes("postal_code")) {
      address["postal_code"] = component.short_name;
    }
  });
  return address;
};

const setAddressString = (address) => {
  let newAddres = "";
  if (address == null) {
    return "";
  }
  if (address.line1 && address.line1.length !== 0) {
    newAddres += address.line1 + ", ";
  }
  if (address.line2 && address.line2.length !== 0) {
    newAddres += address.line2 + ", ";
  }
  return (
    newAddres + address.city + ", " + address.state + " " + address.country
  );
};

const trialExpiryDays = (date) => {
  let expDate = new Date(date);
  let curr = new Date();
  let difference = (expDate - curr) / (1000 * 3600 * 24);
  if (difference < 0) {
    return 10;
  } else {
    return parseInt(difference);
  }
};

const loanTermType = ["Yearly", "Monthly"];

const NotesPlaceholder = "Optional";

const addTimePeriod = (date, period, type) => {
  const dur = moment.duration(period, type === "Monthly" ? "M" : "y");
  return moment(date).add(dur.asDays(), "d");
};

const validCountryCodes = ["+1", "+44", "+91"];

const checkPhoneValid = (
  event,
  setPhoneNo,
  setMsg,
  setLoading,
  errMessage = "Enter a valid phone number"
) => {
  if (event) {
    if (isValidPhoneNumber(event) === true) {
      setPhoneNo(event);
      setMsg("");
      setLoading(false);
    } else {
      if (!validCountryCodes.includes(event)) {
        setPhoneNo(event);
        setMsg(errMessage);
        setLoading(true);
      } else {
        setPhoneNo("");
        setMsg("");
        setLoading(false);
      }
    }
  } else {
    setPhoneNo("");
    setMsg("");
    setLoading(false);
  }
};
/*
const phoneHandleChange = (
  val,
  setPhoneNo,
  setMsg,
  setLoading,
  errMessage = "Enter a valid phone number"
) => {
  if (val) {
    if (isValidPhoneNumber(val) === true) {
      setPhoneNo(val);
      setMsg("");
      setLoading(false);
    } else {
      if (!usAreaCodes.includes(val)) {
        setPhoneNo(val);
        setMsg(errMessage);
        setLoading(true);
      } else {
        setPhoneNo("");
        setMsg("");
        setLoading(false);
      }
    }
  } else {
    setPhoneNo("");
    setMsg("");
    setLoading(false);
  }
};
*/

// Common React data table styles
const customStyles = {
  table: {
    style: {
      borderRadius: "4px",
      border: "1px solid var(--neutral-50)",
    },
  },
  noData: {
    style: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0",
      backgroundColor: "var(--neutral-10)",
      borderRadius: " 4px",
      fontSize: window.innerWidth <= 575 ? "12px" : "14px",
      fontWeight: "300",
      lineHeight: "24px",
      width: "100%",
    },
  },
};

const phoneHandleChange = (
  val,
  setPhoneNo,
  setMsg,
  setLoading,
  errMessage = "Enter a valid phone number"
) => {
  if (val) {
    if (val.startsWith("+1")) {
      if (
        usAreaCodes.includes(Number(val.split("+1")[1].substr(0, 3))) &&
        val.split("+1")[1].length == 10
      ) {
        setPhoneNo(val);
        setMsg("");
        setLoading(false);
      } else {
        setLoading(false);
        setPhoneNo(val);
        setMsg(errMessage);
      }
    } else {
      setLoading(false);
      setPhoneNo(val);
      setMsg("");
    }
  } else {
    setLoading(false);
    setPhoneNo("");
    setMsg("");
  }
};
const selectOptionsArr = (arr) => {
  let options = [];
  // eslint-disable-next-line array-callback-return
  arr.map((data) => {
    options.push({ value: data, label: data });
  });
  return options;
};

const localStorageClear = () => {
  let webPass = process.env.REACT_APP_WEB_PASSWORD;
  window.localStorage.clear();
  window.localStorage.setItem("tempPass", webPass);
};
const shortLengthLabel = (string, length) => {
  return string > 30 ? string.slice(0, length) + "..." : string;
};

// const formatPhoneNumber = (string) => string ? formatPhoneNumberIntl(string).split(" ").join("-") : string

const formatPhoneNumber = (string) => {
  if (string) {
    const formatted = formatPhoneNumberIntl(string);
    if (formatted.startsWith("+1")) {
      return (
        "+1 " + formatted.substring(3).split(" ").join("-").replace(/-/, "-")
      );
    }
    return formatted.split(" ").join("-");
  }
  return string;
};

const useOutsideClick = (ref, handler, condition = () => true) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        condition(event)
      ) {
        handler();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler, condition]);
};

const checkDomainAccept = (email) => {
  const domainAccept = [
    "com",
    "org",
    "io",
    "net",
    "edu",
    "gov",
    "ae",
    "int",
    "dot",
    "co",
    "in",
    "ca",
    "fr",
    "br",
    "de",
    "ru",
    "it",
    "es",
    "nl",
    "jp",
    "ar",
    "uk",
    "mx",
    "id",
    "au",
    "biz",
    "info",
    "us",
    "ch",
    "se",
    "no",
    "dk",
    "fi",
    "be",
    "at",
    "cz",
    "pl",
    "pt",
    "gr",
    "hu",
    "ro",
    "tr",
    "sg",
    "hk",
    "tw",
    "vn",
    "th",
    "ph",
    "my",
    "kr",
    "cn",
    "is",
  ];
  let emailRegex =
    /^[a-zA-Z0-9.!#$%&'+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)$/;

  if (!email) {
    return true;
  }
  let lowerCaseEmail = email.toLowerCase();
  if (!emailRegex.test(lowerCaseEmail)) {
    return false;
  }
  const emailDomains =
    email && lowerCaseEmail.split(".")[lowerCaseEmail.split(".").length - 1];
  return email ? domainAccept.includes(emailDomains) : true;
};

const emailSchema = yup
  .string()
  // .matches(/^\S*$/, uniValidation.email.validEmail)
  .required(uniValidation.email.required)
  .test("is-not-only-spaces", uniValidation.email.required, (value) => {
    return value.trim() !== "";
  })
  .email(uniValidation.email.validEmail)
  .test("no-more-than-30-before-at", uniValidation.email.validEmail, (value) =>
    value ? value.split("@")[0].length <= 30 : true
  )
  .test(
    "at-least-1-character-before-at",
    "Enter at least 1 character before @",
    (value) => (value ? value.split("@")[0].length > 0 : true)
  )
  .test("custom-email-format", uniValidation.email.validEmail, (value) => {
    return value ? uniValidation.email.allowedFormat.test(value) : true;
  })
  // .test('no-capital-letters', 'Email address should not contain capital letters', value => {
  //   return !/[A-Z]/.test(value);
  // })
  .test("domain-acceptance", uniValidation.email.validEmail, (value) => {
    return checkDomainAccept(value);
  });

const fileSizeCalc = (file_size) => {
  let fileSizeInBytes = parseInt(file_size);
  if (isNaN(fileSizeInBytes)) {
    return 0;
  }
  if (fileSizeInBytes >= 1073741824) {
    return (fileSizeInBytes / 1073741824).toFixed(2) + " GB";
  } else if (fileSizeInBytes >= 1048576) {
    return (fileSizeInBytes / 1048576).toFixed(2) + " MB";
  } else if (fileSizeInBytes >= 1024) {
    return (fileSizeInBytes / 1024).toFixed(2) + " KB";
  } else {
    return fileSizeInBytes === 0 ? 0 : fileSizeInBytes + " Bytes";
  }
};

const getFileIcon = (extension, file_size = 22) => {
  let icon = null;
  // const file_size = "22";
  const docTypes = [
    {
      name: "image",
      allowed: ["jpeg", "png", "gif", "bmp", "jpg", "webp", "tiff", "svg"],
    },
    {
      name: "ai",
      allowed: ["ai"],
    },
    {
      name: "audio",
      allowed: ["mp3", "wav", "ogg", "opus", "ogv"],
    },
    {
      name: "archive",
      allowed: ["zip", "rar", "tar", "gzip"],
    },
    {
      name: "doc",
      allowed: ["doc"],
    },
    {
      name: "docx",
      allowed: ["docx"],
    },
    {
      name: "postscript",
      allowed: ["ps", "eps"],
    },
    {
      name: "video",
      allowed: [
        "webm",
        "mpeg4",
        "mpeg",
        "mp4",
        "3gpp",
        "mov",
        "avi",
        "mpegps",
        "wmv",
        "flv",
        "ogg",
        "3gp",
        "mkv",
      ],
    },
    {
      name: "photoshop",
      allowed: ["psd"],
    },
    {
      name: "pdf",
      allowed: ["pdf"],
    },
    {
      name: "ppt",
      allowed: ["ppt"],
    },
    {
      name: "pptx",
      allowed: ["pptx"],
    },
    {
      name: "ttf",
      allowed: ["ttf"],
    },
    {
      name: "xls",
      allowed: ["xls", "csv"],
    },
    {
      name: "xlsx",
      allowed: ["xlsx"],
    },
    {
      name: "doc",
      allowed: ["doc"],
    },
    {
      name: "docx",
      allowed: ["docx"],
    },
    {
      name: "txt",
      allowed: ["txt"],
    },
    {
      name: "file",
      allowed: [
        "css",
        "html",
        "php",
        "c",
        "cpp",
        "h",
        "hpp",
        "js",
        "java",
        "py",
        "dxf",
        "eps",
        "xlsb",
        "xlsm",
        "pptm",
        "potm",
        "ppsm",
        "key",
        "numbers",
      ],
    },
  ];

  const type =
    docTypes &&
    docTypes.find((types) => {
      return types.allowed.includes(extension);
    });
  const typeName = type ? type.name : "unsupported";

  // eslint-disable-next-line default-case
  switch (typeName && typeName.toLowerCase()) {
    case "ai":
      icon = <CapsyncIcon title="file-ai" size={file_size} />;
      break;
    case "doc":
      icon = <CapsyncIcon title="file-doc" size={file_size} />;
      break;
    case "docx":
      icon = <CapsyncIcon title="file-docx" size={file_size} />;
      break;
    case "postscript":
      icon = <CapsyncIcon title="file-ai" size={file_size} />;
      break;
    case "image":
      icon = <CapsyncIcon title="file-image" size={file_size} />;
      break;
    case "ppt":
      icon = <CapsyncIcon title="file-ppt" size={file_size} />;
      break;
    case "pptx":
      icon = <CapsyncIcon title="file-pptx" size={file_size} />;
      break;
    case "archive":
      icon = <CapsyncIcon title="file-archive" size={file_size} />;
      break;
    case "audio":
      icon = <CapsyncIcon title="file-audio" size={file_size} />;
      break;
    case "txt":
      icon = <CapsyncIcon title="file-txt" size={file_size} />;
      break;
    case "video":
      icon = <CapsyncIcon title="file-video" size={file_size} />;
      break;
    case "photoshop":
      icon = <CapsyncIcon title="file-photoshop" size={file_size} />;
      break;
    case "pdf":
      icon = <CapsyncIcon title="file-pdf" size={file_size} />;
      break;
    case "ttf":
      icon = <CapsyncIcon title="file-ttf" size={file_size} />;
      break;
    case "xls":
      icon = <CapsyncIcon title="file-xls" size={file_size} />;
      break;
    case "xlsx":
      icon = <CapsyncIcon title="file-xlsx" size={file_size} />;
      break;
    case "file":
      icon = <CapsyncIcon title="file-photoshop" size={file_size} />;
      break;
    case "unsupported":
      icon = <CapsyncIcon title="file-unsupported" size={file_size} />;
  }
  return icon;
};

const getFileType = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  if (["jpg", "jpeg", "gif", "png", "webp"].includes(extension)) {
    return "image";
  } else if (["pdf"].includes(extension)) {
    return "pdf";
  } else if (["csv"].includes(extension)) {
    return "csv";
  } else if (["txt"].includes(extension)) {
    return "txt";
  } else if (["xlsx"].includes(extension)) {
    return "xlsx";
  } else if (
    ["mp4", "webm", "mov", "avi", "mkv", "flv", "3gp", "asf", "m4v"].includes(
      extension
    )
  ) {
    return "video";
  } else if (["mp3", "wav", "ogg", "opus", "ogv"].includes(extension)) {
    return "audio";
  } else if (["pptx"].includes(extension)) {
    return "document";
  } else if (["docx"].includes(extension)) {
    return "docx";
  } else {
    return "unknown";
  }
};

const fileDownload = (file) => {
  const link = document.createElement("a");
  link.href = file.attachment;
  link.download = file.name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  toast.success("Ready to Download");
};

const quickbooksReportDownload = (file, reportCategory, type) => {
  let reportName = "";
  switch (reportCategory) {
    case "profit_loss":
      reportName = "ProfitandLoss";
      break;
    case "balance_sheet":
      reportName = "BalanceSheet";
      break;
    case "cash_flow":
      reportName = "StatementofCashFlows";
      break;
    case "trial_balance":
      reportName = "TrialBalance";
      break;
    default:
      reportName = "Report";
      break;
  }

  const link = document.createElement("a");
  const url = window.URL.createObjectURL(file);
  link.href = url;
  if (type === "excel") {
    link.download = `${reportName}.xlsx`;
  } else {
    link.download = `${reportName}.pdf`;
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
};

const handleS3Upload = async (fileArray, userId, dispatch) => {
  const s3 = new AWS.S3();
  const uploadPromises = [];

  try {
    fileArray.forEach((file) => {
      if (!file || !file.name || !file.size) {
        console.error("Invalid file object:", file);
        return;
      }

      const fileName = file.name.split(/\.(?=[^\.]+$)/)[0];
      const extension = file.name.split(/\.(?=[^\.]+$)/)[1];

      const pathName = `CS-USER-${userId}/others/${fileName}-${
        Date.now() + "." + extension
      }`;
      // const pathName = `CS-USER-${userId}/others/${file.name}`;
      const uId = Date.now();

      dispatch(
        showUploadProgress({
          files: [file.name],
          progress: 0,
          id: uId,
          isDelete: false,
        })
      );

      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: pathName,
        Body: file,
        // ContentType: file.type,
        // ACL: 'public-read', // Set permissions as needed
      };

      const uploadPromise = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          dispatch(
            showUploadProgress({
              files: [file.name],
              progress: parseInt((evt.loaded * 100) / evt.total),
              id: uId,
              isDelete: false,
            })
          );
          if (parseInt((evt.loaded * 100) / evt.total) === 100) {
            setTimeout(() => {
              dispatch(
                showUploadProgress({
                  files: [file.name],
                  progress: parseInt((evt.loaded * 100) / evt.total),
                  id: uId,
                  isDelete: true,
                })
              );
            }, 1000);
          }
        })
        .promise();

      uploadPromise.catch((error) => {
        dispatch(
          showUploadProgress({
            files: [file.name],
            progress: 100,
            id: uId,
            isDelete: true,
          })
        );
      });

      uploadPromises.push(uploadPromise);
    });

    const uploadResults = await Promise.allSettled(uploadPromises);

    const successfulUploads = [];
    const failedUploads = [];

    uploadResults.forEach((result, index) => {
      if (result.status === "fulfilled") {
        successfulUploads.push({
          file: fileArray[index],
          result: result.value.$response.request.params, // AWS S3 response
        });
      } else {
        failedUploads.push({
          file: fileArray[index],
          reason: result.reason, // Error or reason for failure
        });
      }
    });

    return { successfulUploads, failedUploads };
  } catch (error) {
    console.log("Upload error", error);
  }
};

const handleS3FolderUpload = async (fileArray, userId, dispatch) => {
  const s3 = new AWS.S3();
  const uploadPromises = [];
  let folderProgress = [];

  try {
    fileArray.forEach((file, index) => {
      const foldername =
        file.webkitRelativePath !== ""
          ? file.webkitRelativePath.split("/")[0]
          : file.path.split("/")[1];

      const existingFolderIndex = folderProgress.findIndex(
        (item) => item.foldername === foldername
      );

      if (existingFolderIndex === -1) {
        folderProgress.push({
          foldername: foldername,
          id: Date.now() + index,
          files: file.size > 0 ? [{ name: file.name, progress: 0 }] : [],
        });
        dispatch(
          showUploadProgress({
            files: [foldername], // Only pass the matched foldername in an array
            progress: 0,
            id: Date.now() + index,
            isDelete: false,
          })
        );
      } else if (file.size > 0) {
        folderProgress[existingFolderIndex].files.push({
          name: file.name,
          progress: 0,
        });
      }
    });
    fileArray.forEach((file) => {
      const foldername =
        file.webkitRelativePath !== ""
          ? file.webkitRelativePath.split("/")[0]
          : file.path.split("/")[1];

      const fileName = file.name.split(/\.(?=[^\.]+$)/)[0];
      const extension = file.name.split(/\.(?=[^\.]+$)/)[1];

      const pathName = `CS-USER-${userId}/others/${fileName}-${
        Date.now() + "." + extension
      }`;
      // const pathName = `CS-USER-${userId}/others/${file.name}`;

      const params = {
        Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
        Key: pathName,
        Body: file,
        // ContentType: file.type,
        // ACL: "public-read", // Set permissions as needed
      };

      const uploadPromise = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          const folderIndex = folderProgress.findIndex(
            (item) => item.foldername === foldername
          );

          if (folderIndex !== -1) {
            const fileIndex = folderProgress[folderIndex].files.findIndex(
              (f) => f.name === file.name
            );

            if (fileIndex !== -1) {
              folderProgress[folderIndex].files[fileIndex].progress = parseInt(
                (evt.loaded * 100) / evt.total
              );

              const totalFiles = folderProgress[folderIndex].files.length;
              const totalProgress = folderProgress[folderIndex].files.reduce(
                (acc, curr) => acc + curr.progress,
                0
              );
              const averageProgress = Math.round(totalProgress / totalFiles);

              dispatch(
                showUploadProgress({
                  files: [foldername], // Only pass the matched foldername in an array
                  progress: averageProgress,
                  id: folderProgress[folderIndex].id,
                  isDelete: false,
                })
              );
              if (averageProgress === 100) {
                setTimeout(() => {
                  dispatch(
                    showUploadProgress({
                      files: [foldername], // Only pass the matched foldername in an array
                      progress: averageProgress,
                      id: folderProgress[folderIndex].id,
                      isDelete: true,
                    })
                  );
                }, 1000);
              }
            } else {
              dispatch(
                showUploadProgress({
                  files: [foldername], // Only pass the matched foldername in an array
                  progress: 100,
                  id: folderProgress[folderIndex].id,
                  isDelete: false,
                })
              );
              setTimeout(() => {
                dispatch(
                  showUploadProgress({
                    files: [foldername], // Only pass the matched foldername in an array
                    progress: 100,
                    id: folderProgress[folderIndex].id,
                    isDelete: true,
                  })
                );
              }, 1000);
            }
          }
        })
        .promise();

      uploadPromises.push(uploadPromise);
    });

    const uploadResults = await Promise.allSettled(uploadPromises);

    const successfulUploads = [];
    const failedUploads = [];

    uploadResults.forEach((result, index) => {
      if (result.status === "fulfilled") {
        successfulUploads.push({
          file: fileArray[index],
          result: result.value.$response.request.params, // AWS S3 response
        });
      } else {
        failedUploads.push({
          file: fileArray[index],
          reason: result.reason, // Error or reason for failure
        });
      }
    });

    return { successfulUploads, failedUploads };
  } catch (error) {
    console.log("Upload error", error);
  }
};

const useLongPress = (
  onClick,
  { shouldPreventDefault = true, delay = 300 } = {}
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeoutRef = useRef();
  const targetRef = useRef();

  const start = useCallback(
    (event) => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener("touchend", preventDefault, {
          passive: false,
        });
        targetRef.current = event.target;
      }
      timeoutRef.current = setTimeout(() => {
        // onLongPress(event);
        setLongPressTriggered(true);
      }, delay);
    },
    [delay, shouldPreventDefault]
  );

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      shouldTriggerClick &&
        event.defaultPrevented === true &&
        !longPressTriggered &&
        onClick(event);
      setLongPressTriggered(false);
      if (shouldPreventDefault && targetRef.current) {
        targetRef.current.removeEventListener("touchend", preventDefault);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  );

  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),
    onMouseUp: (e) => clear(e),
    onMouseLeave: (e) => clear(e, false),
    onTouchEnd: (e) => clear(e),
  };
};

const isTouchEvent = (event) => "touches" in event;

const supportedExtensions = [
  ".pdf",
  ".ps",
  ".csv",
  ".epub",
  ".kml",
  ".kmz",
  ".gpx",
  ".hwp",
  ".htm",
  ".html",
  ".xls",
  ".xlsx",
  ".ppt",
  ".pptx",
  ".doc",
  ".docx",
  ".odp",
  ".ods",
  ".odt",
  ".rtf",
  ".svg",
  ".tex",
  ".txt",
  ".bas",
  ".c",
  ".cc",
  ".cpp",
  ".cxx",
  ".h",
  ".hpp",
  ".cs",
  ".java",
  ".pl",
  ".py",
  ".wml",
  ".wap",
  ".xml",
  ".bmp",
  ".gif",
  ".jpeg",
  ".png",
  ".webp",
  ".3gp",
  ".3g2",
  ".asf",
  ".avi",
  ".divx",
  ".m2v",
  ".m3u",
  ".m3u8",
  ".m4v",
  ".mkv",
  ".mov",
  ".mpeg",
  ".ogv",
  ".qvt",
  ".ram",
  ".rm",
  ".vob",
  ".webm",
  ".wmv",
  ".xap",
  ".zip",
  ".rar",
  ".tar",
  ".gzip",
  ".mp3",
  ".mp4",
  ".wav",
  ".ogg",
  ".opus",
  ".tiff",
  ".css",
  ".php",
  ".js",
  ".xlsb",
  ".xlsm",
  ".pptm",
  ".potm",
  ".ppsm",
  ".docm",
  ".key",
  ".numbers",
  ".xps",
  ".flv",
  ".dxf",
  ".ai",
  ".psd",
  ".eps",
  ".jpg",
  ".mpeg4",
  ".3gpp",
  ".mpegps",
  ".ttf",
];

const mainFoldersName = [
  commonNames.Assets,
  commonNames.Liabilities,
  commonNames.Other,
];

const replaceLastOccurrence = (inputStr, oldVal, newVal) => {
  const lastIndex = inputStr.lastIndexOf(oldVal);

  if (lastIndex === -1) {
    return inputStr;
  } else {
    return (
      inputStr.substring(0, lastIndex) +
      newVal +
      inputStr.substring(lastIndex + oldVal.length)
    );
  }
};

const preventDefault = (event) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

function isValidFileName(input) {
  const forbiddenRegex = /^[^\\\x80-\xff{}^%`"<>\[\]~#|]+$/;
  return forbiddenRegex.test(input);
}

export default useLongPress;

export {
  combine,
  calculateAmortization,
  calculatePaymentPeriod,
  attomdata_address_find,
  attomdata_price_fetch,
  generateString,
  addHeaderInAxiosAPI,
  headerAddToken,
  fetchMinutes,
  datediff_day,
  mongoDBAxiosAPI,
  renderHTML,
  loginStatusStore,
  deviceVerifyCookieStore,
  currencyFormat,
  calculateAmortizationLiabilityAutomobile,
  DateFormat,
  getAddressObject,
  getValidAddress,
  setAddressString,
  trialExpiryDays,
  loanTermType,
  NotesPlaceholder,
  addTimePeriod,
  useLongPress,
  validCountryCodes,
  checkPhoneValid,
  selectOptionsArr,
  localStorageClear,
  shortLengthLabel,
  formatPhoneNumber,
  phoneHandleChange,
  checkDomainAccept,
  emailSchema,
  useOutsideClick,
  fileSizeCalc,
  getFileIcon,
  getFileType,
  fileDownload,
  handleS3Upload,
  handleS3FolderUpload,
  supportedExtensions,
  replaceLastOccurrence,
  mainFoldersName,
  isValidFileName,
  quickbooksReportDownload,
  customStyles,
};
