import React, { useState } from "react";
import {
  Modal,
  Form,
  Col,
  Row,
  Image,
  Button,
  Dropdown,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { verify_investor_add } from "../../../../../slices/verifyInvestor";
import { useNavigate } from "react-router-dom";
import { generateString } from "../../../../../util/common";
import { useFormik } from "formik";
import * as yup from "yup";
import CapsyncLoader from "../../../../../../assets/images/capsync-loader.gif";
import { customLabelLength } from "../../../../../customHooks/adjustLength";
import CapsyncIcon from "../../../../../commonComponent/CapsyncIcon";
import CapsyncToolTip from "../../../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import { roleName } from "../../../../../config/commonRoleName";
import { uniValidation } from "../../../liabilities/categoryForms/validationSchema";

const FirstModal_VI = (props) => {
  // const [enableLoader, setEnableLoader] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [errorMsg, setErrorMsg] = useState("");
  const [showError, setShowError] = useState(false);
  let initialValues = {
    email: user && user.user.email,
    legal_name: "",
    verification_type: "",
  };

  // -----------Do not remove below code => Asmita Dt.4th Sept 2023------------//
  // const [getPlans, setGetPlans] = useState([]);
  // const [selectedPlan, setSelectedPlan] = useState("");

  // const get_vi_payment_plans = () => {
  //   dispatch(fetch_all_payment_plan({ module_name: "Verify Investor" }))
  //     .unwrap()
  //     .then((response) => {
  //       setGetPlans(response.data);
  //       setSelectedPlan(response && response.data && response.data[0].title);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   get_vi_payment_plans();
  // }, []);

  // const [formFields, setFormFields] = useState({
  //   selectType: props.getActivePlan,
  // });

  // useEffect(() => {
  //   changeFormFields(props.getActivePlan, "selectType");
  // }, [props]);

  // const changeFormFields = (params, type) => {
  //   type === "selectType" &&
  //     setFormFields({ ...formFields, selectType: params });
  // };

  // -----------Do not remove above code => Asmita Dt.4th Sept 2023------------//

  const validationSchema = yup.object().shape({
    // email: yup
    //   .string()
    //   .email(uniValidation.email.validEmail)
    //   .matches(/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/, "Email not acceptable")
    //   .matches(/^\S*$/, "Whitespace is not allowed")
    //   .required("Please enter Email"),
    legal_name: yup
      .string()
      .required("Enter legal name")
      .matches(/^\S.*$/, "Enter legal name"),
    verification_type: yup.string().required("Please select verification type"),
  });

  let user_record = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const randomString = generateString(6);
  let id;

  const email = user && user.user.email;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  function customNavigate(params) {
    setTimeout(() => {
      navigate(params);
    }, 1000);
  }
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // setEnableLoader(true);
      values.user_id = id;
      values.reference_optional = props.referenceName;
      values.unique_rowId = randomString;
      values.user_email = email;
      values.logged_displayName = window.localStorage.getItem("pfs_access_id")
        ? user && user.user.displayName
        : user && user.user.displayName;
      values.visitors_displayName = window.localStorage.getItem("pfs_access_id")
        ? user_record && user_record.user && user_record.user.displayName
        : "-";
      values.visitors_id = window.localStorage.getItem("pfs_access_id")
        ? user_record && user_record.user && user_record.user.id
        : 0;
      values.visitors_role = window.localStorage.getItem("pfs_access_id")
        ? user_record &&
          user_record.user_role &&
          user_record.user_role.role === 1
          ? roleName.individual
          : user_record.user_role.role === 2
          ? roleName.financial_advisor
          : roleName.tax_professional
        : "-";

      values.verification_type = props.selectedPlan.title;
      dispatch(verify_investor_add(values))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            props.setFirstModal(false);
            props.setGetActivePlan("");
            formik.resetForm();

            if (props.selectedPlan.title === "Accredited Investor") {
              customNavigate(
                `/individual/verifyinvestor/accreditedInvestor/individuals/${randomString}`
              );
              // setEnableLoader(false);
            } else if (props.selectedPlan.title === "Qualified Client") {
              customNavigate(
                `/individual/verifyinvestor/accreditedInvestor/qualifiedclient/${randomString}`
              );
              // setEnableLoader(false);
            } else if (props.selectedPlan.title === "Qualified Purchaser") {
              customNavigate(
                `/individual/verifyinvestor/accreditedInvestor/qualifiedpurchaser/${randomString}`
              );
              // setEnableLoader(false);
            }
          }
          if (response.code === 500) {
            if (response.message === '"email" must be a valid email') {
              setErrorMsg("Enter a valid email address");
            } else {
              setErrorMsg(
                "A verification request for this email is already exists"
              );
            }
            // setErrorMsg(response.message)
            setShowError(true);
          }
        })
        .catch((err) => {
          console.log(err);
          // setEnableLoader(false);
        });
    },
  });

  const handleCancel = () => {
    props.setGetActivePlan("");
    props.setFirstModal(false);
    formik.resetForm();
    setErrorMsg("");
    setShowError(false);
    props.setSelectedPlan({ title: "", amount: 0 });
    props.setReferenceName("");
  };

  const changePaymentType = (plan) => {
    formik.setFieldValue("verification_type", plan.title);
    props.setSelectedPlan({ title: plan.title, amount: plan.amount });
    // setShowError(false)
  };
  const handleChange = (e) => {
    setShowError(false);
    formik.handleChange(e);
  };
  return (
    <div>
      <Modal
        show={props.firstModal}
        onHide={!props.firstModal}
        backdrop="static"
        keyboard={false}
        className="request-verification-modal"
        dialogClassName="cs-md-modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header>
            <Modal.Title className="default-semi-bold-h3 ">
              Request Verification
            </Modal.Title>
            {/* Dt: 15-6-2023 - Don't remove below commented code - Asmita  */}
            {/* <CloseButton onClick={() => storeModalData("cancle")} /> */}
          </Modal.Header>

          <Modal.Body>
            <div>
              {/* {enableLoader ? (
              <div className="text-center initial-modal-loader">
                <Image
                  src={CapsyncLoader}
                  alt="loadergif"
                  className="test-demo1"
                  width={100}
                  height={100}
                />
              </div>
            ) : ( */}
              <>
                {/* <Form
                  onSubmit={formik.handleSubmit}
                  className="auth-form "
                > */}
                <Row>
                  <Col lg={12}>
                    <div className="form-label">Choose Verification Type</div>
                    {/* <Dropdown className="cs-form-dropdown">
                      <Dropdown.Toggle
                        className="dropdown-toggle"
                        variant="secondary"
                        id="dropdown-basic-accredited"
                      >
                        {(props.selectedPlan.title === "") &
                          (props.selectedPlan.amount === 0) ? (
                          <div className="default-regular-sub-heading-s ">
                            {window.innerWidth >= 300 &&
                              window.innerWidth <= 575
                              ? "Select Accredited Investor, Quali..."
                              : customLabelLength(
                                " Select Accredited Investor, Qualified Client, Qualified Purchaser", 45, 48
                              )}
                            <span className="input-field-icon">
                              <CapsyncIcon
                                title="chevron-down-outlined"
                                size="18"
                              />
                            </span>
                          </div>
                        ) : (
                          <>
                            <div className="cs-content-title default-regular-h5 cs-neutral-100">
                              <span className="title">
                                {props.selectedPlan.title}
                              </span>
                              <div className="dollar-value-width">
                                <span>$</span>
                                <span>{props.selectedPlan.amount}</span>
                              </div>
                            </div>
                          </>
                        )}
                        {(props.selectedPlan.title === "") &
                          (props.selectedPlan.amount === 0) ? (
                          ""
                        ) : (
                          <span className="input-field-icon">
                            <CapsyncIcon
                              title="chevron-down-outlined"
                              size="16"
                            />
                          </span>
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu">
                        {props.getPlans &&
                          props.getPlans.map((item) => (
                            <Dropdown.Item
                              className={
                                item.title === props.selectedPlan.title
                                  ? "dropdown-item dropdown-item-active default-regular-sub-heading-s "
                                  : "default-regular-sub-heading-s cs-content-title "
                              }
                              key={item}
                              onClick={() => changePaymentType(item)}
                            >
                              <div className="cs-content-title">
                                <span className="title">{item.title}</span>
                                <div className="dollar-value-width">
                                  <span>$</span>
                                  <span>{item.amount}</span>
                                </div>
                              </div>
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown> */}
                    <Dropdown className="cs-form-dropdown">
                      <Dropdown.Toggle
                        className="dropdown-toggle"
                        variant="secondary"
                        id="dropdown-basic-accredited"
                      >
                        {(props.selectedPlan.title === "") &
                        (props.selectedPlan.amount === 0) ? (
                          <div className="verification-dropdown">
                            <span className="cs-ellipsis">
                              Select Accredited Investor, Qualified Client,
                              Qualified Purchaser
                            </span>
                            {/* {window.innerWidth >= 300 && window.innerWidth <= 575
                              ? "Select Accredited Investor, Qua..."
                              : customLabelLength(
                                " Select Accredited Investor, Qualified Client, Qualified Purchaser",
                                45,
                                48
                              )} */}
                            <span className="input-field-icon cs-neutral-90">
                              <CapsyncIcon
                                title="chevron-down-outlined"
                                size="18"
                              />
                            </span>
                          </div>
                        ) : (
                          <div className="cs-content-title default-light-body-text-s">
                            <span className="title cs-neutral-100">
                              {props.selectedPlan.title}
                            </span>
                            <div className="dollar-value-width default-semi-bold-h6">
                              <span>$</span>
                              <span>{props.selectedPlan.amount}</span>
                            </div>
                            <span className="input-field-icon cs-neutral-90">
                              <CapsyncIcon
                                title="chevron-down-outlined"
                                size="18"
                              />
                            </span>
                          </div>
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu">
                        {props.getPlans &&
                          props.getPlans.map((item) => (
                            <Dropdown.Item
                              className={
                                item.title === props.selectedPlan.title
                                  ? "dropdown-item dropdown-item-active default-regular-sub-heading-s "
                                  : "default-regular-sub-heading-s "
                              }
                              key={item}
                              onClick={() => changePaymentType(item)}
                            >
                              <span className="title">{item.title}</span>
                              <div className="dollar-value-width default-semi-bold-h6">
                                <span>$</span>
                                <span>{item.amount}</span>
                              </div>
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    {formik.errors.verification_type &&
                    formik.touched.verification_type ? (
                      <p className="formik-form-error">
                        {formik.errors.verification_type}
                      </p>
                    ) : null}
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <Form.Group className="cs-form-group ">
                      <Form.Label className="form-label">Legal name</Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        onKeyDown={(e) => {
                          if (e.key === " " && e.target.selectionStart === 0) {
                            e.preventDefault();
                          }
                        }}
                        name="legal_name"
                        maxLength={50}
                        value={formik.values.legal_name}
                        className={
                          formik.values.legal_name && "cs-input-field-active"
                        }
                        onChange={(e) => handleChange(e)}
                        onBlur={formik.handleBlur}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <Form.Group className="cs-form-group">
                      <Form.Label className="cs-disabled">Email</Form.Label>
                      <Form.Control
                        type="email"
                        autoComplete="off"
                        name="email"
                        onKeyDown={(e) =>
                          e.code === "Space" && e.preventDefault()
                        }
                        value={email}
                        className={
                          formik.values.email && "cs-input-field-active"
                        }
                        disabled={email}

                        // onChange={(e) => handleEmailChange(e)}
                        // onBlur={formik.handleBlur}
                        // onKeyDown={(event) => {
                        //   if (event.code === "Space") event.preventDefault();
                        // }}
                      />
                      {/* {formik.errors.email && formik.touched.email ? (
                        <span className="form-error-msg default-light-body-text-s cs-danger">
                          {formik.errors.email}
                        </span>
                      ) : null} */}
                      {showError && (
                        // <p className="default-light-body-text-m cs-danger">{errorMsg}</p>}
                        <div className="cs-msg default-regular-body-text-s cs-danger">
                          <span className="icon">
                            <CapsyncIcon title="info-filled" />
                          </span>
                          <span className="txt"> {errorMsg}</span>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <Form.Group
                      className="cs-form-group last-email-field"
                      controlId="validationFormik03"
                    >
                      <Form.Label>
                        Reference
                        {/* <span className="cs-neutral-70 "> (Optional)</span> */}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        placeholder="Optional"
                        name="firstName"
                        onKeyDown={(e) => {
                          if (e.key === " " && e.target.selectionStart === 0) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={100}
                        onChange={(event) =>
                          props.setReferenceName(event.target.value)
                        }
                        value={props.referenceName}
                        className={
                          props.referenceName && "cs-input-field-active"
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {/* <div className="cs-form-group">
                </div> */}
                {/* <div>
                    <div className="req-input-bg">
                    </div>
                  </div> */}
                {/* <Row className="g-0 auth-form-main-part vi-form-box ">
                  <div className="form-div-add-account ">
                    <Col lg={12} sm={12} className="lagal-name-container">
                      
                    </Col>
                  </div>
                </Row> */}
                {/* <div className="form-div-add-account vi-secpopup-width">
                  
                </div>

                <div className="cs-form-group">
                  <Col lg={12} sm={12}>
                    
                  </Col>
                </div> */}
                <Form />
              </>
              {/* )} */}

              {/* Dt: 15-6-2023 - Don't remove below commented code - Asmita  */}
              {/* <Form>
              <Row>
                <div>Choose Verification Type</div>
                <Col>
                  <div className="test-demo">
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="secondary"
                        id="dropdown-basic-accredited"
                      >
                        {formFields.selectType}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {verificationTypeArray.map((item) => (
                          <Dropdown.Item
                            key={item}
                            onClick={() => changeFormFields(item, "selectType")}
                          >
                            {item}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
              </div>
              <div className="form-div-add-account">
                <Col lg={12} sm={12} className="mb-20-px ">
                  <Form.Label className="manageUser-formLabel mb-10-px ">
                    Legal name
                  </Form.Label>
                  <Form.Group
                    className="mb-3 cap-form-group"
                    controlId="validationFormik03"
                  >
                    <span>
                      <Image
                        src={require("../../../../../../assets/icons/svg/outlined/user-single-outlined.svg")}
                        className="cap-icon vi-firstmodal-icons"
                      />
                    </span>
                    <Form.Control
                      className="viModal-change-input-color"
                      type="text"
                      placeholder="John Deo"
                      autoComplete="off"
                      name="legalname"
                      onChange={(e) => changeFormFields(e.target.value, "name")}
                      value={formFields.legalName}
                    />
                    {validationError.nameValidation !== "" && (
                      <p className="cap-error">
                        {validationError.nameValidation}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </div>
              <div>
                <Col lg={12} sm={12} className="mb-20-px ">
                  <Form.Label className="manageUser-formLabel mb-10-px">
                    Email
                  </Form.Label>
                  <Form.Group
                    className="mb-3 cap-form-group"
                    controlId="validationFormik03"
                  >
                    <span>
                      <Image
                        src={require("../../../../../../assets/icons/svg/outlined/email-outlined.svg")}
                        className="cap-icon vi-firstmodal-icons"
                      />
                    </span>
                    <Form.Control
                      className="viModal-change-input-color"
                      type="email"
                      placeholder="example@email.com"
                      autoComplete="off"
                      name="lastName"
 onKeyDown={(e) => {
                                  if (e.key === " " && e.target.selectionStart === 0) {
                                    e.preventDefault();
                                  }
                                }}

                      onChange={(e) =>
                        changeFormFields(e.target.value, "email")
                      }
                      value={formFields.email}
                    />
                    {validationError.emailValidation !== "" && (
                      <p className="cap-error">
                        {validationError.emailValidation}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </div>
              <hr className="vi-popup-hr" />
              <div>
                <Col lg={12} sm={12} className="mb-20-px ">
                  <Form.Label className="manageUser-formLabel mb-10-px">
                    Reference
                    <span className="vi-firstModal-span">(optional)</span>
                  </Form.Label>
                  <Form.Group
                    className="mb-3 cap-form-group "
                    controlId="validationFormik03"
                  >
                    <Form.Control
                      className="viModal-change-input-color"
                      type="text"
                      autoComplete="off"
                      name="firstName" 
onKeyDown={(e) => {
                                  if (e.key === " " && e.target.selectionStart === 0) {
                                    e.preventDefault();
                                  }
                                }}

                      onChange={(event) =>
                        props.setReferenceName(event.target.value)
                      }
                      value={props.referenceName}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form> */}
            </div>

            {/* Dt: 15-6-2023 - Don't remove below commented code - Asmita  */}
            {/* <div className="vi-popup-foot">
            <Button
              className="vi-popup-btn"
              onClick={() => storeModalData("continue")}
            >
              Continue
            </Button>
            <Button
              className="ms-2 vi-popup-cancel-btn"
              // Dt: 15-6-2023 - Don't remove below commented code - Asmita
              // onClick={() => storeModalData("cancle")}
              onClick={() => closeFirstModal(false)}
            >
              Cancel
            </Button>
          </div> */}
          </Modal.Body>
          <Modal.Footer>
            <div className="cs-modal-btn">
              <Button
                className="cs-btn-secondary md-btn default-regular-h5"
                onClick={handleCancel}
              >
                Cancel
              </Button>

              <Button
                className="cs-btn-primary md-btn default-regular-h5"
                type="submit"
                disabled={!formik.isValid || !formik.dirty}
              >
                Continue
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default FirstModal_VI;
