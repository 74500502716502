import React from "react";
import DesktopMenu from "./SidebarMenu/DesktopMenu";
import MobileMenu from "./SidebarMenu/MobileMenu";
import AddModal from "../Individual/AddModal";

const WmSidebarSection = (props) => {

  const { setModalState, modalState, getIndividuals } = props;

  const changeModalState = (s) => {
    setModalState(s);
  };


  return (
    <>
      {setModalState && (
        <AddModal
          changeModalState={changeModalState}
          modalState={modalState}
          toastMessage={props.toastMessage}
          getIndividuals={getIndividuals}
        />
      )}

      {props.openSide ? (
        <DesktopMenu props={props} />
      ) : (
          <MobileMenu props={props} />
      )}
    </>
  );
};

export default WmSidebarSection;
