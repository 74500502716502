import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CapsyncIcon from "../CapsyncIcon";
import { useLocation } from "react-router-dom";

const CapsyncToolTip = ({ Child, placement, message, type, size, width, trigger }) => {
  const location = useLocation();
  const pathname = location.pathname.split("/");

  const triggers = trigger === "click" ? ["click", "focus"] : ["hover", "focus"];

  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={
        <Tooltip
          style={{ maxWidth: width }}
          className={pathname[3] === "accreditedInvestor" ? "accredited-investor-tooltip" : ""}
        >
          {message}
        </Tooltip>
      }
      trigger={triggers}
    >
      <span>
        {type === "text" ? Child : <CapsyncIcon title={Child} size={size} />}
      </span>
    </OverlayTrigger>
  );
};

export default CapsyncToolTip;
