import React from "react";
import { Row, Col } from "react-bootstrap";
import CapsyncIcon from "../../../../../../commonComponent/CapsyncIcon";

const UploadFileBlock = (props) => {
  const handleDelete = (index) => {
    props.deleteFile(index);
  };
  return (
    <Row>
      <Col lg={12}>
        <div
          className={
            props.uploadError
              ? "cs-upload-files investor-error"
              : "cs-upload-files"
          }
          onClick={() => props.setOpenModal(true)}
        >
          <span className="cs-primary">
            <CapsyncIcon title="upload-files-filled" size="28" />
          </span>
          <span className="default-regular-sub-heading-s">
            <span className="cs-primary">Browse</span>&nbsp; or drag file here
          </span>
          <p className="default-regular-sub-heading-xxs">
            Support PDF, PNG, JPG and JPEG Files
          </p>
        </div>
        {props.uploadError && (
          <span className="default-regular-sub-heading-xs cs-danger">
            {props.showError} uploadError
          </span>
        )}
        {props.uploadFileOneError && (
          <span className="default-regular-sub-heading-xs cs-danger">
            {props.showError} uploadFileOneError
          </span>
        )}
        {props.uploadFileTwoError && (
          <span className="default-regular-sub-heading-xs cs-danger">
            {props.showError} uploadFileTwoError
          </span>
        )}
        {props.uploadError && props.showError === undefined && (
          <span className="default-regular-sub-heading-xs cs-danger">
            Select the attached document or upload the required files
          </span>
        )}

        <>
          {/* {props.mediaAlert !== "" ? (
            <p className="cap-error">{props.mediaAlert}</p>
          ) : ( */}
          <div className="attachment-files cs-files">
            {props.showFile === true && (
              <>
                {props.files &&
                  props.files.map((item, index) => {
                    return (
                      <div className="cs-selected-files" key={index}>
                        <div className="cs-fileicon cs-neutral-100">
                          <span className="cs-icon cs-neutral-10">
                            <CapsyncIcon title="file-icon-outlined" size="16" />
                          </span>
                        </div>
                        <div className="cs-files-names">
                          <span className="download-filename default-regular-sub-heading-xs cs-neutral-100">
                            {item.original_file_name}
                          </span>
                        </div>
                        <div className="cs-file-icon icons">
                          {props.showDeleteSpinner === item.file_name ? (
                            <span className="cs-common-spinner cs-danger">
                              <CapsyncIcon title="loading-outlined" size="18" />
                            </span>
                          ) : (
                            <span
                              className="cs-icon-badge-danger cs-danger cursor-pointer"
                              // onClick={() => handleDelete(typeof index === "string" ? item.original_file_name : index)}
                              onClick={() => handleDelete(item.file_name)}
                            >
                              <CapsyncIcon title="delete-outlined" size="18" />
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </>
            )}
            {props.showPfsLink === true && (
              <>
                {props.pfsLink && (
                  <div className="cs-selected-files">
                    <div className="cs-fileicon cs-neutral-100">
                      <span className="cs-icon cs-neutral-10">
                        <CapsyncIcon title="file-icon-outlined" size="16" />
                      </span>
                    </div>
                    <div className="cs-files-names">
                      <span className="download-filename default-regular-sub-heading-xs cs-neutral-100">
                        {props.pfsName}'s PFS
                      </span>
                    </div>
                    <div className="cs-file-icon icons">
                      <span
                        className="cs-icon-badge-danger cs-danger cursor-pointer"
                        onClick={props.pfsDelete}
                      >
                        <CapsyncIcon title="delete-outlined" size="18" />
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </>
      </Col>
    </Row>
  );
};

export default UploadFileBlock;
