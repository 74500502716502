import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import Cookies from "universal-cookie";
import LeftPart from "./LeftPart";
import { clearMessage } from "../../slices/message";
import { mfaEmailAuthPin, mfaEmailAuth } from "../../slices/auth";
import { loginStatusStore, deviceVerifyCookieStore } from "../../util/common";
import LogoImage from "../logoImage";
import CapsyncIcon from "../../commonComponent/CapsyncIcon";
import { socket } from "../../config/Socket";
import { roleName } from "../../config/commonRoleName";
import { syncPlaidAccounts } from "../../config/plaidFunctions";
import { uniValidation } from "../individual/liabilities/categoryForms/validationSchema";

const SignInMFAuthEmailPin = () => {
  const [loading, setLoading] = useState(false);
  const [resendMsg, setResendMsg] = useState();
  const [deviceVerify, setDeviceVerify] = useState(false);
  const [msg, setMsg] = useState("");
  const [otp, setOtp] = useState();
  const { user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = user && user.user.id;
  const email = user && user.user.email;
  const cookies = new Cookies();
  useEffect(() => {
    dispatch(clearMessage());
    setResendMsg(false);
  }, [dispatch]);

  const handleChange = (otp) => {
    dispatch(clearMessage());
    setMsg("");
    setResendMsg(false);
    setOtp(otp);
  };

  const handleRemember = (e) => {
    if (e.target.checked) {
      setDeviceVerify(e.target.checked);
    } else {
      setDeviceVerify(false);
      cookies.remove(`deviceAuth24Hr-${id}`);
    }
  };

  const resendMFAEmail = () => {
    setOtp("");
    dispatch(clearMessage());
    setLoading(true);
    dispatch(mfaEmailAuth({ email: email }))
      .unwrap()
      .then((data) => {
        setResendMsg(data.message);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      dispatch(clearMessage());
      setLoading(true);
      setResendMsg(false);
      const MFAEmailOtp = otp;
      if (MFAEmailOtp) {
        const res = await dispatch(mfaEmailAuthPin({ email, id, MFAEmailOtp }))
          .unwrap()
        if (res) {
          setLoading(false);
          deviceVerifyCookieStore(id, deviceVerify);
          //if (res.res_data.user.is_subscribe) {
          loginStatusStore(res.role, res.res_data.user.id);
          if (res.role === 1) {
            syncPlaidAccounts(res.res_data.user.id)
            socket.emit("fetch_user", {
              user_id: res.res_data.user.id,
              user_type: res.role,
              user_role: roleName.individual,
              logged_out: false,
            });
            socket.emit("user_logging_out", true);
            navigate("/individual");
          } else {
            socket.emit("fetch_user", {
              user_id: res.res_data.user.id,
              user_type: res.role,
              user_role: res.role == 2 ? roleName.financial_advisor : roleName.tax_professional,
              logged_out: false,
            });
            socket.emit("user_logging_out", true);
            if (res.role == 2) {            
              navigate("/financial-advisor");
            } else {
              navigate("/professional");
            }
          }
        }
      } else {
        setLoading(false);
        setMsg(uniValidation.otp.require);
      }
    } catch (error) {
      console.log('error', error)
    }

  };


  return (
    <section className="auth-section mfa">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={6}>
            <LeftPart
              page="emailvarify"
              img="auth-image.svg"
              title="Your New Immersive Digital Finance Hub."
              text="Sync your real-time digital Personal Financial Statement with CapSync."
            />
          </Col>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <div className="auth-right-section">
                  <LogoImage />
                  <div className="cs-auth-form">
                    <h2 className="default-semi-bold-h1">Email verification</h2>
                    <hr className="head-underline" />
                    <div className="default-regular-h5">
                      <span className=" cs-neutral-80  ">
                        We have sent a code to&nbsp;
                      </span>
                      {""}
                      <b>{email}</b>
                    </div>
                    <div className="cs-otp">
                      <Form onSubmit={handleSubmit} className="auth-form">
                        <OtpInput
                          value={otp}
                          onChange={handleChange}
                          numInputs={6}
                          renderInput={(props) => <input {...props} />}
                          inputStyle="otp-txt"
                          containerStyle="otp-layout"
                          // isInputNum={true}
                          inputType="number"
                          shouldAutoFocus={true}
                        />
                        {/* {msg ? (
                          <p className="default-regular-body-text-s cs-danger">
                            {msg}</p>
                        ) : null} */}
                        {msg ? (
                          <div className="cs-msg default-regular-body-text-s cs-danger">
                            <span className="icon">
                              <CapsyncIcon title="info-filled" />
                            </span>
                            <span className="txt">{msg}</span>
                          </div>
                        ) : null}
                        <div className="default-regular-h5">
                          <span className="auth-text-color-resend">
                            Didn't receive the code?{" "}
                            <Link onClick={resendMFAEmail}>Resend</Link>
                          </span>
                        </div>
                        {resendMsg && (
                          <div className="cs-msg default-regular-body-text-s cs-success">
                            <span className="icon cs-success">
                              <CapsyncIcon title="verification-outlined" size="18" />
                            </span>
                            <span className="txt">{resendMsg}</span>
                          </div>
                        )}
                        {message && (
                          <div className="cs-msg default-regular-body-text-s cs-danger">
                            <span className="icon">
                              <CapsyncIcon title="info-filled" />
                            </span>
                            <span className="txt">{message.message || message}</span>
                          </div>
                        )}
                        <div key={`default-checkbox`}>
                          <Form.Check
                            type={"checkbox"}
                            id={`default-checkbox`}
                            feedbackType="invalid"
                            className="cs-form-check-box"
                            aria-label="option 1"
                            name="terms"
                            checked={deviceVerify}
                            onChange={handleRemember}
                            label={
                              <span className="default-light-body-text-m cursor-pointer d-flex">
                                Trust this device for 180 days
                              </span>
                            }
                          />
                        </div>
                        <Button
                          type="submit"
                          className="cs-btn-primary lg-btn default-regular-h5 cs-ver-btn"
                        // disabled={loading}
                        >
                          Verify account
                        </Button>
                      </Form>
                    </div>
                    {/* <Form>
                      <div key={`default-checkbox`}>
                        <Form.Check
                          type={"checkbox"}
                          id={`default-checkbox`}
                          feedbackType="invalid"
                          className="cs-form-check-box"
                          aria-label="option 1"
                          name="terms"
                          checked={deviceVerify}
                          onChange={handleRemember}
                          label={
                            <span className="default-light-body-text-m cursor-pointer">
                              Remember this device for 24 Hours
                            </span>
                          }
                        />
                      </div>
                    </Form> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SignInMFAuthEmailPin;
