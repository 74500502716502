import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Accordion, Col, Container, Row, Table } from "react-bootstrap";
// import { PieChart } from "react-minimal-pie-chart";

import UseFetchPermission from "../../../customHooks/UseFetchPermission";
import AmortBreadCrumb from "./AmortBreadCrumb";
import CapsyncIcon from "../../CapsyncIcon";
import { amortizationHeader, liabilitiesCategory } from "../../../config/finance";
import CategoryTableData from "../../CategoryTableData";
import { useDispatch, useSelector } from "react-redux";
import "./amortization.css"
import "../../../../app/commonComponent/CommonComponent.css"
import SkeletonAmortization from "./SkeletonAmortization";
import { categoryFindRecord } from "../../../slices/category.slice";
import InitialSetup3 from "../../../pages/individual/dashboard/InitialSetup/InitialSetup3";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import CapsyncToolTip from "../../capsyncTooltip/CapsyncToolTip";
import FinanceAllocation from "../../FinanceAllocation";

const Amoritzation = ({
  allData,
  row_id,
  startAmount,
  payFrequency,
  formattedDate,
  Data,
  defaultLabelStyle,
  simpleInterest,
  TotalPaidAmount,
  TotalInterest,
  groupedData,
  handleOpen,
  is_loader,
  setModalShow,
  modalShow,
  editAccessUser
}) => {
  const reducerData = useSelector((state) => state);
  const categoryData = useSelector((state) => state.category);
  const { lastSyncDate } = useSelector((state) => state.auth);
  const { categoryListing } = categoryData;
  const location = useLocation();
  const navigate = useNavigate();

  const { fetchUserPermission } = UseFetchPermission();
  const [urlCheck, setUrlCheck] = useState([...Array(5)].map(() => { return { url: "", category: '', title: '' }; }));

  const category1 = window.location.pathname.substring(1).split("/")[3];
  const categoryType = window.location.pathname.substring(1).split("/")[1];
  const [record, setRecord] = useState({});
  const hasData = Object.keys(groupedData).length > 0;
  const [fetchTypeCategory, setFetchTypeCategory] = useState({
    type: 0,
    category: 'Assets' || 'Liabilities'
  });
  const selectedCategory = categoryListing[categoryType].filter((category, index) => category.url == category1)[0];
  const dispatch = useDispatch();

  const modelData = (title, id) => {
    setModalShow(true);
    dispatch(
      categoryFindRecord({
        id: id,
        category: category1,
        main_category: categoryType,
      })
    )
      .unwrap()
      .then((response) => {
        setRecord(response);
      })
      .catch((err) => {
        console.log(err)
      });

    setFetchTypeCategory({
      type: selectedCategory.id,
      category: categoryType === 'assets' ? 'Assets' : 'Liabilities'
    })
  };


  useEffect(() => {
    const pathname = location.pathname;
    const { is_manual, is_amortization_link_enable } = allData;

    if (
      allData &&
      Object.keys(allData).length !== 0 &&
      (!is_amortization_link_enable || !is_manual)
    ) {
      navigate(newPathName(pathname));
    }
  }, [allData]);


  function newPathName(pathname) {
    let segments = pathname.split('/');
    if (!isNaN(segments[segments.length - 1])) {
      segments.pop();
    }
    let amortizationIndex = segments.indexOf('amortization');
    if (amortizationIndex !== -1) {
      segments.splice(amortizationIndex, 1);
    }
    let newPathname = segments.join('/');
    return newPathname;
  }

  useEffect(() => {
    setUrlCheck([
      {
        url: "/individual/liabilities/installmentsaccount",
        category: "installmentsaccount",
        title: 'Installment Account (Other)'
      },
      {
        url: "/individual/liabilities/accountpayable",
        category: "accountpayable",
        title: 'Notes Payable to Banks and Others'
      },
      {
        url: "/individual/liabilities/installmentsauto",
        category: "installmentsauto",
        title: 'Installment Account (Auto)'
      },
      {
        url: "/individual/liabilities/notespayabletobanksandothers",
        category: "notespayabletobanksandothers",
        title: 'Notes Payable to Banks and Others'
      },
      {
        url: "/individual/assets/accountnotesreceviable",
        category: 'accountnotesreceviable',
        title: 'Accounts & Notes Receivable'
      },
      {
        url: "/individual/liabilities/mortgagesonrealestate",
        category: 'mortgagesonrealestate',
        title: 'Mortgages on Real Estate'
      },
    ])
  }, [reducerData.category])


  const [tooltipData, setTooltipData] = useState(null);

  const handleMouseEnter = (data, index) => {
    setTooltipData(data);
  };

  const handleMouseLeave = () => {
    setTooltipData(null);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      let actualVal = Number(payload[0].value).toLocaleString("en-US");
      return (
        <div className="chart-custom-tooltip">
          <p>{`${payload[0].payload.title} : $ ${actualVal}`}</p>
        </div>
      );
    }
    return null;
  };
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="#FFFFFF"
        textAnchor={x > cx ? "middle" : "middle"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <>
      <Container fluid>
        {is_loader.assets === true || is_loader.liabilities === true ? <SkeletonAmortization /> :
          <>
            <div className="cs-section-topbar">
              <AmortBreadCrumb urlCheck={urlCheck} allData={allData} />
              <div className="cs-title">
                <h1 className="default-semi-bold-h1 cs-neutral-100">
                  Amortization Schedule
                </h1>
                {lastSyncDate && <span className="default-regular-body-text-m cs-neutral-80">Last sync: {lastSyncDate}</span>}
              </div>
            </div>

            <div className="amortization_headingTable">
              <Row>
                <Col lg={7}>
                  <div className="amortization-header">
                    <h2 className="default-regular-h2 cs-neutral-100">
                      Loan from {" "}
                      {
                        allData.property_name ?
                          allData.property_name && allData.property_name.length > 18
                            ? allData.property_name.slice(0, 18) + "..."
                            : allData.property_name
                          :
                          allData.creditor_name && allData.creditor_name.length > 18
                            ? allData.creditor_name.slice(0, 18) + "..."
                            : allData.creditor_name}
                    </h2>
                    {editAccessUser &&
                      <span className="amortization-icon">
                        <Link onClick={() => modelData("Update Account", row_id)}>
                          <span className="cs-primary-main">
                            <CapsyncToolTip
                              Child={"edit-outlined"}
                              placement={"top"}
                              size="22"
                              message={"Edit Details"}
                              type="icon"
                            />
                          </span>
                        </Link>
                      </span>
                    }
                  </div>

                  <div className="cs-amortization-table">
                    <Table className="cs-table">
                      <thead className="cs-thead">
                        <tr>
                          <th
                            className="default-regular-body-text-m cs-neutral-100"
                            colSpan={2}
                          >
                            Loan Values
                          </th>
                        </tr>
                      </thead>
                      <tbody className="cs-tbody">
                        <tr>
                          <td width="500">
                            <h6 className=" default-light-body-text-m cs-neutral-90">
                              Loan Amount
                            </h6>
                          </td>
                          <td width="200">
                            <div className="default-light-body-text-m cs-neutral-100">
                              <span>$ </span>
                              <span>{startAmount}</span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h6 className="default-light-body-text-m cs-neutral-90">
                              Interest Rate (%)
                            </h6>
                          </td>
                          <td>
                            <div className="default-light-body-text-m cs-neutral-100">
                              <span>{allData.annual_rate}</span>
                              <span> % </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h6 className="default-light-body-text-m cs-neutral-90">
                              Loan Term {" "}
                              ({payFrequency === "Monthly" ? "Months" : "Years"})
                            </h6>
                          </td>

                          <td>
                            <span className="default-light-body-text-m cs-neutral-100">
                              {allData.loan_period}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <h6 className="default-light-body-text-m cs-neutral-90">
                              Loan Date
                            </h6>
                          </td>
                          <td>
                            <span className="default-light-body-text-m cs-neutral-100">
                              {formattedDate}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>

                <Col lg={5}>
                  <div className="amortization-new-pie-chart">
                    <FinanceAllocation
                      pieData={Data}
                      title="Total Paid"
                      heading="Amortization"
                      width="100%"
                      height={250}
                      nameOffsetY={0}
                      valueOffsetY={0}
                      respNameOffsetY={0}
                      respValueOffsetY={0}
                      isAmoritzation={true}
                      position="right"
                    />
                  </div>
                </Col>
              </Row>
            </div>

            {/* Amortization Table */}
            <div className="amortization-table-container">
              <Row>
                <Col lg={12}>
                  <div className="assets-listing amortization-main-listing">
                    {hasData ? (
                      <Table className="cs-table" >
                        <thead
                          id="table-head-details"
                          className="cs-thead commonTable-heading"
                        >
                          <tr>
                            {amortizationHeader.map((itr) => {
                              return (
                                <>
                                  <th width={itr.width} className={itr.class}>
                                    <span>
                                      {itr.title}
                                    </span>
                                  </th>
                                </>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody className="cs-tbody">
                          {Object.entries(groupedData).map(([year, rows]) => {
                            let acc = 0;
                            let int = 0;
                            let pay = 0;
                            let tot = 0;
                            let princi = 0;
                            rows.forEach((element) => {
                              if (element.principalAmount > -1) {
                                acc += element.total;
                                int += element.Interest;
                                pay += element.monthlyPayement;
                                tot = element.Total;
                                if (element.principalAmount < 0) {
                                  princi = 0;
                                } else {
                                  princi = element.principalAmount;
                                }
                              }
                            });

                            return (
                              <tr>
                                <td
                                  className="accordion-table-collapse accordion-after"
                                  colSpan={9}
                                >

                                  <Accordion key={year}>
                                    {
                                      tot > 0 &&
                                      <Accordion.Item eventKey={year}>
                                        <Accordion.Header onClick={handleOpen}>
                                          <Table className="cs-table">
                                            <tbody className="cs-tbody default-regular-sub-heading-m">
                                              <tr>
                                                <td width="180">
                                                  <span className="mob-width text-end">
                                                    <CategoryTableData
                                                      type={"string"}
                                                      component={year}
                                                    />
                                                  </span>
                                                </td>

                                                <td
                                                  width="255"
                                                  className="amortization-principal text-end amort-alignment"
                                                >
                                                  <span className="mob-width text-end">
                                                    <CategoryTableData
                                                      type={"amortization_number"}
                                                      data={acc.toLocaleString(undefined, {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                      })}
                                                    // class="dollar-value-gap"

                                                    />
                                                  </span>

                                                </td>

                                                <td
                                                  width="26"
                                                  className="amortization-principal-icon"
                                                ></td>

                                                <td
                                                  width="255"
                                                  className="amortization-interest text-end amort-alignment"
                                                >
                                                  <span className="mob-width text-end">
                                                    <CategoryTableData
                                                      type={"amortization_number"}
                                                      data={int.toLocaleString(undefined, {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                      })}
                                                    // class="dollar-value-gap"
                                                    />
                                                  </span>
                                                </td>

                                                <td
                                                  width="26"
                                                  className="amortization-interest-icon"
                                                ></td>

                                                <td width="255" classNames="text-end">
                                                  <span className="mob-width text-end">

                                                    <CategoryTableData
                                                      type={"amortization_number"}
                                                      data={pay.toLocaleString(undefined, {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                      })}
                                                    // class="dollar-value-gap"
                                                    />
                                                  </span>
                                                </td>

                                                <td width="240" classNames="text-end">
                                                  <span className="mob-width text-end">

                                                    <CategoryTableData
                                                      type={"amortization_number"}
                                                      data={tot.toLocaleString(undefined, {
                                                        maximumFractionDigits: 2,
                                                        minimumFractionDigits: 2,
                                                      })}
                                                    // class="dollar-value-gap"
                                                    />
                                                  </span>
                                                </td>

                                                <td width="240" classNames="text-end">
                                                  <span className="mob-width text-end">

                                                    <CategoryTableData
                                                      type={"amortization_number"}
                                                      data={princi.toLocaleString(
                                                        undefined,
                                                        {
                                                          maximumFractionDigits: 2,
                                                          minimumFractionDigits: 2,
                                                        }
                                                      )}
                                                    // class="dollar-value-gap"
                                                    />
                                                  </span>
                                                </td>

                                                <td width="107"></td>
                                              </tr>
                                            </tbody>
                                          </Table>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <Table className="cs-table">
                                            <tbody className="cs-tbody">
                                              {rows.map(function (row, index) {
                                                if (row.principalAmount < -1) {
                                                  return "";
                                                } else {
                                                  return (
                                                    <tr
                                                      key={index}
                                                      className="default-regular-sub-heading-m"
                                                    >
                                                      <td width="180">
                                                        <span className="mob-width text-end">
                                                          {row.dayloop.slice(0, 3) +
                                                            ", " +
                                                            year}
                                                        </span>
                                                      </td>

                                                      <td
                                                        width="257"
                                                        className="amortization-principal text-end amort-alignment"
                                                      >
                                                        <span className="mob-width text-end">

                                                          <CategoryTableData
                                                            type={"amortization_number"}
                                                            data={row.total.toLocaleString(
                                                              undefined,
                                                              {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                              }
                                                            )}
                                                          // class="dollar-value-gap"
                                                          />
                                                        </span>
                                                      </td>

                                                      <td
                                                        width="26"
                                                        className="amortization-principal-icon"
                                                      ></td>

                                                      <td
                                                        width="255"
                                                        className="amortization-interest text-end amort-alignment"
                                                      >
                                                        <span className="mob-width text-end">

                                                          <CategoryTableData
                                                            type={"amortization_number"}
                                                            data={row.Interest.toLocaleString(
                                                              undefined,
                                                              {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                              }
                                                            )}
                                                          // class="dollar-value-gap"
                                                          />
                                                        </span>
                                                      </td>

                                                      <td
                                                        width="26"
                                                        className="amortization-interest-icon"
                                                      ></td>

                                                      <td width="255" className="text-end">
                                                        <span className="mob-width text-end">

                                                          <CategoryTableData
                                                            type={"amortization_number"}
                                                            data={row.monthlyPayement.toLocaleString(
                                                              undefined,
                                                              {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                              }
                                                            )}
                                                          // class="dollar-value-gap"
                                                          />
                                                        </span>
                                                      </td>

                                                      <td width="240" className="text-end">
                                                        <span className="mob-width text-end">

                                                          <CategoryTableData
                                                            type={"amortization_number"}
                                                            data={row.Total.toLocaleString(
                                                              undefined,
                                                              {
                                                                maximumFractionDigits: 2,
                                                                minimumFractionDigits: 2,
                                                              }
                                                            )}
                                                          // class="dollar-value-gap"
                                                          />
                                                        </span>
                                                      </td>

                                                      {row.principalAmount < 0 ? (
                                                        <td width="240" className="text-end">
                                                          <span className="mob-width text-end">

                                                            <CategoryTableData
                                                              type={"amortization_number"}
                                                              data="0"
                                                            // class="dollar-value-gap"
                                                            />
                                                          </span>
                                                        </td>
                                                      ) : (
                                                        <td width="240" className="text-end">
                                                          <span className="mob-width text-end">

                                                            <CategoryTableData
                                                              type={"amortization_number"}
                                                              data={row.principalAmount.toLocaleString(
                                                                undefined,
                                                                {
                                                                  maximumFractionDigits: 2,
                                                                  minimumFractionDigits: 2,
                                                                }
                                                              )}
                                                            // class="dollar-value-gap"
                                                            />
                                                          </span>
                                                        </td>
                                                      )}
                                                      <td width="110"></td>
                                                    </tr>
                                                  );
                                                }
                                              })}
                                            </tbody>
                                          </Table>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    }
                                  </Accordion>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    ) : (
                      <span className="cs-common-no-record">There are no records to display </span>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </>
        }
      </Container >

      {modalShow &&
        <InitialSetup3
          fetchTypeCategory={fetchTypeCategory}
          record={record}
          setShowEditModal={setModalShow}
          showModal={modalShow}
        />
      }

    </>
  );
};

export default Amoritzation;
