import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const SkeletonViSubMethods = () => {
    return (
        <>
            <div className="accreditation-footer">
                <Container fluid>
                    <Row>
                        <Col lg={6}>
                            <p className="default-medium-sub-heading-m  cs-neutral-80">
                                <Skeleton />
                            </p>
                        </Col>
                        <Col lg={6}>
                            <div className="accreditation-btn-group skeleton-accreditation-btn">
                                <Skeleton width={150} height={40} />
                                <Skeleton width={150} height={40} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default SkeletonViSubMethods
