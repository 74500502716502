import React, { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip, Offcanvas, Image } from "react-bootstrap";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import MessageModal from "./messageModal";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import { backAppURl } from "../../../applicationMode";

import { requestDecline } from "../../../slices/requests";
import { userFindRecord } from "../../../slices/user";
import { avatarTitles } from "../../../config/avatar";
import Avatar from "../../../commonComponent/Avatar";
import { formatPhoneNumber } from "../../../util/common";
import { roleName } from "../../../config/commonRoleName";
import CapsyncToolTip from "../../../commonComponent/capsyncTooltip/CapsyncToolTip";


const OffCanvas = (props) => {

  const [initials, setInitials] = useState("");
  const [mainInitials, setMainInitials] = useState("");

  const dynamicURL = backAppURl;

  const dispatch = useDispatch();
  const [permissionVal, setPermissionVal] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    OffcanvasState,
    showCanvas,
    setShowCanvas,
    userEmail,
    handleCloseModal,
    HandleOpen,
    socket,
    toastMessage,
    status,
    users,
    onCancelRequestConfirmationClick,
    mainTabName
  } = props;
  let id = users && users.user.id;
  const { authUser } = useSelector((state) => state.auth);
  const mainUserEmail = authUser && authUser.user ? authUser.user.email : authUser && authUser.email;
  const getProfileImage = () => {
    let imageUrl;
    if (OffcanvasState.request_send_by_photo) {
      if (OffcanvasState.request_send_by_role === roleName.financial_advisor || OffcanvasState.request_send_by_role === roleName.tax_professional) {
        imageUrl = `${dynamicURL}/api/wealthmanager/profile/${OffcanvasState.request_send_by}`;
      } else {
        imageUrl = `${dynamicURL}/api/user/profile/${OffcanvasState.request_send_by}`;
      }
    }

    return imageUrl || require("../../../../assets/images/other/face.png");
  };

  const getInitials = (name) => {
    const nameArray = name.split(' ');
    const firstName = nameArray[0];
    const lastName = nameArray[nameArray.length - 1];
    const initials = firstName.charAt(0) + lastName.charAt(0);

    setInitials(initials.toUpperCase())
    setMainInitials(initials.toUpperCase())
  };

  useEffect(() => {
    request_send_by_displayName && getInitials(request_send_by_displayName)
  }, [OffcanvasState]);

  const permissionBtnVal = (params) => {
    dispatch(
      requestDecline({
        row_id: OffcanvasState.row_id,
        permissionBtnVal: params,
        permissionVal: permissionVal,
        request_send_by: OffcanvasState.request_send_by,
        request_send_by_role: OffcanvasState.request_send_by_role,
        request_send_to: id,
        request_send_to_role: OffcanvasState.request_send_to_role,
        access_permission: OffcanvasState.access_permission,
        check_status: 2,
        fromPhoneNo: OffcanvasState.request_send_by_phoneNo
      })
    )
      .unwrap()
      .then((response) => {
        socket.emit("individual_request_listing", {
          id: response.sender_id,
        });
        socket.emit("notification", "message")
        // socket.emit("notification", "CapSync Notifications");
        props.fetchAllNotifications();
        setShowCanvas(false);
        toastMessage(`You have ${params === 1 ? 'accepted' : 'declined'} this invite to collaborate successfully.`, "success");
      })
      .catch((err) => {
        toastMessage(err.message, "error");
      });
  };

  const checkPermissionValue = (params) => {
    setPermissionVal(params);
  };



  const {
    FirstEmail,
    FirstUserName,
    SpouseJointName,
    SpouseJointEmail,
    check_status,
    request_type,
    request_send_by_email,
    createdAt,
    request_send_by_companyData,
    request_send_by_phoneNo,
    signature_date,
    updatedAt,
    request_send_by_displayName,
    request_send_by_role,
    requestPageType,
    licenceId,
  } = OffcanvasState;

  const userType = mainTabName == "1" ? roleName.individual : "2" ? roleName.financial_advisor : roleName.tax_professional;

  const displayEmail = SpouseJointEmail == userEmail ? FirstEmail : SpouseJointEmail;
  const displayName = SpouseJointName ? SpouseJointName : FirstUserName;
  let requestStatus = "Completed";
  if (check_status === 3) {
    requestStatus = "Pending";
  } else if (check_status === 4) {
    requestStatus = "Rejected";
  }
  else if (OffcanvasState.request_type === "Account Access Request" && check_status === 2) {
    requestStatus = "Rejected";
  }

  const hideOffcanva = () => {
    setShowCanvas(false)
  }
  const fallbackImageUrl = require('../../../../assets/images/other/face.png');



  return (
    <>
      <Offcanvas
        show={showCanvas}
        onHide={hideOffcanva}
        className="cs-offcanvas"
        placement="end"
      >
        <Offcanvas.Header>
          {/* <div className="img-container">
            <Image className="pic" src={getProfileImage()} alt="profile" width={96} height={96} />
            <span onClick={() => setShowCanvas(false)}>
              <CapsyncIcon title="close-outlined" size="18" />
            </span>
          </div> */}
          <div className="img-container">
            <div className="offcanvas-avatar">
              <>
                {(avatarTitles.includes(OffcanvasState.request_send_by_photo)) ? (
                  <>
                    <div className={`${OffcanvasState.request_send_by_photo === 'avtar-6' ? 'avatar-initials' : ''}`}>
                      <Avatar title={OffcanvasState.request_send_by_photo} size={120} />
                      {(OffcanvasState.request_send_by_photo === 'avtar-6') && (
                        <div className="initials-overlay default-regular-h1 cs-neutral-80">{mainInitials}</div>
                      )}
                    </div>
                  </>
                ) : OffcanvasState.request_send_by_photo === null ?
                  <>
                    <Avatar title="avtar-6" size={120} />
                    <div className="initials-overlay default-regular-h1 cs-neutral-80">{initials}</div>
                  </>
                  : <Image
                    className="profile-img"
                    // src={
                    //   imageExists(imageUrl)
                    //     ? imageUrl
                    //     : fallbackImageUrl
                    // }
                    src={getProfileImage()}
                    alt="profile"
                    width={120}
                    height={120}
                  />}
              </>
            </div>
            <span onClick={() => setShowCanvas(false)}>
              <CapsyncIcon title="close-outlined" size="18" />
            </span>
          </div>
          <div className="header-information">
            <div className="name-information">
              <span className="default-semi-bold-h3">
                {request_send_by_displayName}{" "}
              </span>
              {(OffcanvasState.request_send_by_role == roleName.financial_advisor) && request_type === "Account Access Request" && (
                <>
                  <span className="cs-neutral-100 offcanvas-divider"></span>
                  <span className="default-regular-sub-heading-m cs-neutral-60">  {"CRD #" + licenceId}</span>
                </>
              )}
            </div>

            {request_type === "Account Access Request" &&
              <div className="designation-information">
                <span className="default-regular-sub-heading-m cs-neutral-60">
                  {request_send_by_role && (request_send_by_role === roleName.financial_advisor ? "Financial Advisor" : request_send_by_role === roleName.tax_professional ? "Professional" : "Individual")}
                </span>
              </div>
            }


            <div className="designation-information">
              <span className="default-regular-sub-heading-m cs-neutral-60">
                {/* {request_send_by_role && request_send_by_role.toLowerCase() === "wealth manager" ? "Financial Advisor" : OffcanvasState.request_send_by_role} */}
                {OffcanvasState.request_sent_to_role}
              </span>
            </div>
          </div>
        </Offcanvas.Header>
        <div className="mob-scroller">

        <Offcanvas.Body>
          <div className="userInfo">
            <div className="userInfoSection">
              <div className="top-information-container">
                <div className="body-top-information">
                  {request_send_by_companyData && request_type === "Account Access Request" && (
                    <>
                      <p className="default-medium-sub-heading-s">Company Name</p>
                      <p className="default-regular-body-text-l d-flex align-items-center" >
                        {OffcanvasState.request_send_by_companyData}
                        {(OffcanvasState.request_send_by_role == roleName.financial_advisor) && (
                          <>
                            <span className="cs-neutral-60">
                              <span className="cs-neutral-60 offcanvas-divider"></span>
                              {'CRD #' + OffcanvasState.companyLicenceId}
                            </span>
                          </>
                        )}
                      </p>
                    </>
                  )}

                  <p className="default-medium-sub-heading-s"> Sender email</p>
                  <p className="default-regular-body-text-l offcanvas-request-email cs-ellipsis">
                    {/* {request_send_by_email} */}
                    {request_send_by_email ? (
                      request_send_by_email.length > 35 ? (
                        <CapsyncToolTip
                          trigger="click"
                          Child={request_send_by_email}
                          placement={"top"}
                          message={request_send_by_email}
                          type="text"
                        />
                      ) : (
                        request_send_by_email
                      )
                    ) : (
                      "-"
                    )}
                  </p>
                  <p className="default-medium-sub-heading-s ">Sender phone number</p>

                  <p className={`default-regular-body-text-l ${request_send_by_phoneNo ? "offcanva-phn" : ""}`} >
                    {request_send_by_phoneNo ? formatPhoneNumber(request_send_by_phoneNo) : "-"}
                  </p>

                  {request_type === "Signature Request" && OffcanvasState.spouse_email !== "" && OffcanvasState.spouse_name !== "" && OffcanvasState.id === OffcanvasState.signature_req_id && <>
                    <p className="default-medium-sub-heading-s">Cosigner Email</p>
                    <p className="default-regular-body-text-l offcanvas-request-email">
                      {/* {OffcanvasState.spouse_email} */}
                      {OffcanvasState.spouse_email ? (
                        OffcanvasState.spouse_email.length > 35 ? (
                          <CapsyncToolTip
                            trigger="click"
                            Child={OffcanvasState.spouse_email}
                            placement={"top"}
                            message={OffcanvasState.spouse_email}
                            type="text"
                          />
                        ) : (
                          OffcanvasState.spouse_email
                        )
                      ) : (
                        "-"
                      )}
                    </p>
                    <p className="default-medium-sub-heading-s ">Cosigner Name</p>

                    <p className="default-regular-body-text-l" >
                      {/* {OffcanvasState.spouse_name ? OffcanvasState.spouse_name : "-"} */}
                      {OffcanvasState.spouse_name ? (
                        OffcanvasState.spouse_name.length > 20 ? (
                          <CapsyncToolTip
                            Child={OffcanvasState.spouse_name.slice(0, 20) + "..."}
                            placement={"top"}
                            message={OffcanvasState.spouse_name}
                            type="text"
                          />
                        ) : (
                          OffcanvasState.spouse_name
                        )
                      ) : (
                        "-"
                      )}
                    </p>

                  </>}


                </div>
              </div>

              {/* -------------------------------------------------------- */}

              {request_type === "Signature Request" && <>
                <div className="top-information-container">
                  <div className="body-top-information">


                    <p className="default-medium-sub-heading-s">Recipient name</p>
                    <p className="default-regular-body-text-l">
                      {/* {OffcanvasState.receiver_name.trim() !== "" ? OffcanvasState.receiver_name : "-"} */}
                      {OffcanvasState.receiver_name ? (
                        OffcanvasState.receiver_name.length > 20 ? (
                          <CapsyncToolTip
                            Child={OffcanvasState.receiver_name.slice(0, 20) + "..."}
                            placement={"top"}
                            message={OffcanvasState.receiver_name}
                            type="text"
                          />
                        ) : (
                          OffcanvasState.receiver_name
                        )
                      ) : (
                        "-"
                      )}
                    </p>
                    <p className="default-medium-sub-heading-s ">Recipient email</p>

                    <p className="default-regular-body-text-l offcanvas-request-email cs-ellipsis" >
                      {/* {OffcanvasState.receiver_email ? OffcanvasState.receiver_email : "-"} */}
                      {OffcanvasState.receiver_email ? (
                        OffcanvasState.receiver_email.length > 35 ? (
                          <CapsyncToolTip
                            trigger="click"
                            Child={OffcanvasState.receiver_email}
                            placement={"top"}
                            message={OffcanvasState.receiver_email}
                            type="text"
                          />
                        ) : (
                          OffcanvasState.receiver_email
                        )
                      ) : (
                        "-"
                      )}
                    </p>

                    <p className="default-medium-sub-heading-s ">Notes</p>

                    <p className="default-regular-body-text-l" >
                      {OffcanvasState.receiver_note ? OffcanvasState.receiver_note : "-"}
                    </p>


                  </div>
                </div>


              </>}


              {/* --------------------------------------------------------------- */}

              <div className="top-information-container">
                {/* {displayEmail && (
                  <div className="body-top-information">
                    <p className="default-medium-sub-heading-s">Spouse Email</p>
                    <p className="default-regular-body-text-l">
                      {displayEmail}
                    </p>
                    <div className="body-top-information">
                      <p className="default-medium-sub-heading-s">
                        Spouse Name
                      </p>
                      <p className="default-regular-body-text-l">
                        {displayName ? displayName : "-"}
                      </p>
                    </div>
                  </div>
                )} */}

                <div className="body-top-information fz-18-px">
                  <p className="default-medium-sub-heading-s">Reason for request</p>
                  <p className="default-regular-body-text-l">{request_type}</p>
                  <p className="default-medium-sub-heading-s">Request status</p>

                  <span className={`cs-badge ${requestStatus === "Completed" ? "cs-success" : requestStatus === "Pending" ? "cs-warning" : "cs-danger"} default-regular-body-text-xs`} >
                    {requestStatus}
                  </span>

                  <div className="body-top-information">
                    <p className="default-medium-sub-heading-s">Date of request</p>
                    <p className="default-regular-body-text-l">
                      {moment(createdAt).format("MM/DD/YYYY")}
                    </p>
                  </div>
                  <p className="default-medium-sub-heading-s">Date of completion</p>
                  {request_type === "Account Access Request" && (check_status === 1 || check_status === 2) && (
                    <p className="default-regular-body-text-l">
                      {updatedAt ? moment(updatedAt).format("MM/DD/YYYY") : "-"}
                    </p>
                  )}
                  {OffcanvasState.request_type === "Account Access Request" && OffcanvasState.check_status === 3 && (
                    <p className="default-regular-body-text-l">
                      {"-"}
                    </p>
                  )}
                  {request_type === "Signature Request" && (
                    <p className="default-regular-body-text-l">
                      {signature_date ? moment(signature_date).format("MM/DD/YYYY") : "-"}
                    </p>
                  )}
                </div>

                {(
                  request_type != "Signature Request" &&
                  check_status === 3 && (
                    <p className="default-medium-sub-heading-s cs-neutral-60">Permissions</p>
                  )
                )}

                {request_type === "Account Access Request" && check_status === 3 && (
                  <div className="d-flex">
                    <OverlayTrigger
                      key={"top"}
                      placement={"top"}
                      overlay={
                        <Tooltip
                          id={`tooltip-top`}
                          className="cs-tooltip default-regular-body-text-m"
                        >
                          Viewing privilege only, No editing privileges.
                        </Tooltip>
                      }
                    >
                      <div className="cs-radio-btn cursor-pointer"
                        onClick={() => checkPermissionValue(1)}

                      >
                        <input
                          className="radio-button"
                          type="radio"
                          label="1"
                          name="access_permission"
                          checked={permissionVal === 1}
                          onClick={() => checkPermissionValue(1)}
                        /><span className="default-light-body-text-l cs-neutral-100">
                          View Only
                        </span>
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      key={"top"}
                      placement={"top"}
                      overlay={
                        <Tooltip
                          id={`tooltip-top`}
                          className="cs-tooltip default-regular-body-text-m"
                        >
                          Viewing and limited editing privileges.
                        </Tooltip>
                      }
                    >
                      <div className="cs-radio-btn cursor-pointer"
                        onClick={() => checkPermissionValue(2)}
                      >
                        <input
                          className="radio-button"
                          type="radio"
                          name="access_permission"
                          label="2"
                          checked={permissionVal === 2}
                          onClick={() => checkPermissionValue(2)}
                        /><span className="default-light-body-text-l cs-neutral-100">
                          Standard
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Offcanvas.Body>

        {/* {OffcanvasState.request_send_by_email != userEmail && ( */}
        {request_type == "Account Access Request" && check_status === 3 && (
          <div className="cs-btn-group">
            <Button
              className="cs-btn-success md-btn default-regular-h5"
              onClick={() => permissionBtnVal(1)}
            >
              Approve
            </Button>
            <Button
              className="cs-btn-danger md-btn default-regular-h5"
              onClick={() => permissionBtnVal(2)}
            >
              Decline
            </Button>
          </div>
        )}


        {(OffcanvasState.requestPageType === "Signature To" && OffcanvasState.secondUserEmail === OffcanvasState.firstUserEmail) && (OffcanvasState.check_status === 3 && OffcanvasState.id === OffcanvasState.signature_req_id && OffcanvasState.signStatus === false) ? (<div className="cs-btn-group">
          <Button
            // className="cs-btn-danger md-btn decline-request-btn default-regular-h5"
            className={`md-btn decline-request-btn default-regular-h5 ${OffcanvasState.secondUserEmail === OffcanvasState.firstUserEmail || ((OffcanvasState.request_sent_by_role == roleName.financial_advisor || OffcanvasState.request_sent_by_role == roleName.tax_professional) && mainUserEmail === OffcanvasState.secondUserEmail) ? "cs-btn-secondary" : "cs-btn-danger"}`}
            onClick={() =>
              HandleOpen(
                OffcanvasState.row_id,
                OffcanvasState.flag,
                OffcanvasState.request_send_by_email,
                OffcanvasState.request_send_by,
                OffcanvasState.signature_req_id
              )
            }
          >
            {`${(OffcanvasState.secondUserEmail === OffcanvasState.firstUserEmail || ((OffcanvasState.request_sent_by_role === roleName.financial_advisor || OffcanvasState.request_sent_by_role === roleName.tax_professional) && mainUserEmail === OffcanvasState.secondUserEmail)) ? "Cancel" : " Decline"}`}
          </Button>
        </div>) :
          (OffcanvasState.requestPageType === "Signature To") && (OffcanvasState.check_status === 3 && OffcanvasState.id !== OffcanvasState.signature_req_id && OffcanvasState.signStatus === true) ? (<div className="cs-btn-group">
            <Button
              // className="cs-btn-danger md-btn decline-request-btn default-regular-h5"
              className="md-btn decline-request-btn default-regular-h5 cs-btn-secondary"
              onClick={() =>
                HandleOpen(
                  OffcanvasState.row_id,
                  OffcanvasState.flag,
                  OffcanvasState.request_send_by_email,
                  OffcanvasState.request_send_by,
                  OffcanvasState.signature_req_id
                )
              }
            >
              {/* {`${(OffcanvasState.secondUserEmail === OffcanvasState.firstUserEmail || (OffcanvasState.request_sent_by_role === "Wealth Manager" && mainUserEmail === OffcanvasState.secondUserEmail)) ? "Cancel" : " Decline"}`} */}
              Cancel
            </Button>
          </div>)
            : (OffcanvasState.requestPageType === "Signature From" && mainUserEmail === OffcanvasState.spouse_email) && (OffcanvasState.check_status === 3 && OffcanvasState.id === OffcanvasState.signature_req_id && OffcanvasState.signStatus === false) ? (<div className="cs-btn-group">

            </div>)

              : (OffcanvasState.requestPageType === "Signature From" && OffcanvasState.secondUserEmail !== OffcanvasState.firstUserEmail) && (OffcanvasState.check_status === 3 && OffcanvasState.id === OffcanvasState.signature_req_id && OffcanvasState.signStatus === false) ? (<div className="cs-btn-group">
                <Button
                  // className="cs-btn-danger md-btn decline-request-btn default-regular-h5"
                  className={`md-btn decline-request-btn default-regular-h5 ${OffcanvasState.secondUserEmail === OffcanvasState.firstUserEmail || ((OffcanvasState.request_sent_by_role === roleName.financial_advisor || OffcanvasState.request_sent_by_role === roleName.tax_professional) && mainUserEmail === OffcanvasState.secondUserEmail) ? "cs-btn-secondary" : "cs-btn-danger"}`}
                  onClick={() =>
                    HandleOpen(
                      OffcanvasState.row_id,
                      OffcanvasState.flag,
                      OffcanvasState.request_send_by_email,
                      OffcanvasState.request_send_by,
                      OffcanvasState.signature_req_id
                    )
                  }
                >
                  {`${(OffcanvasState.secondUserEmail === OffcanvasState.firstUserEmail || ((OffcanvasState.request_sent_by_role === roleName.financial_advisor || OffcanvasState.request_sent_by_role === roleName.tax_professional) && mainUserEmail === OffcanvasState.secondUserEmail)) ? "Cancel" : " Decline"}`}
                </Button>
              </div>) :
                (OffcanvasState.check_status === 3 && OffcanvasState.id === OffcanvasState.signature_req_id && OffcanvasState.signStatus === false) ? (<div className="cs-btn-group">
                  <Button
                    // className="cs-btn-danger md-btn decline-request-btn default-regular-h5"
                    className={`md-btn decline-request-btn default-regular-h5 ${OffcanvasState.secondUserEmail === OffcanvasState.firstUserEmail || ((OffcanvasState.request_sent_by_role === roleName.financial_advisor || OffcanvasState.request_sent_by_role === roleName.tax_professional) && mainUserEmail === OffcanvasState.secondUserEmail) ? "cs-btn-secondary" : "cs-btn-danger"}`}
                    onClick={() =>
                      HandleOpen(
                        OffcanvasState.row_id,
                        OffcanvasState.flag,
                        OffcanvasState.request_send_by_email,
                        OffcanvasState.request_send_by,
                        OffcanvasState.signature_req_id
                      )
                    }
                  >
                    {`${(OffcanvasState.secondUserEmail === OffcanvasState.firstUserEmail || ((OffcanvasState.request_sent_by_role === roleName.financial_advisor || OffcanvasState.request_sent_by_role === roleName.tax_professional) && mainUserEmail === OffcanvasState.secondUserEmail)) ? "Cancel" : " Decline"}`}
                  </Button>
                </div>) :


                  OffcanvasState.check_status === 3 && OffcanvasState.id !== OffcanvasState.signature_req_id && OffcanvasState.signStatus === true && (<div className="cs-btn-group">
                    <Button
                      // className="cs-btn-danger md-btn decline-request-btn default-regular-h5"
                      className={`md-btn decline-request-btn default-regular-h5 ${OffcanvasState.secondUserEmail === OffcanvasState.firstUserEmail || ((OffcanvasState.request_sent_by_role === roleName.financial_advisor || OffcanvasState.request_sent_by_role === roleName.tax_professional) && mainUserEmail === OffcanvasState.secondUserEmail) ? "cs-btn-secondary" : "cs-btn-danger"}`}
                      onClick={() =>
                        HandleOpen(
                          OffcanvasState.row_id,
                          OffcanvasState.flag,
                          OffcanvasState.request_send_by_email,
                          OffcanvasState.request_send_by,
                          OffcanvasState.signature_req_id
                        )
                      }
                    >
                      {/* Decline */}
                      {`${(OffcanvasState.secondUserEmail === OffcanvasState.firstUserEmail || ((OffcanvasState.request_sent_by_role === roleName.financial_advisor || OffcanvasState.request_sent_by_role === roleName.tax_professional) && mainUserEmail === OffcanvasState.secondUserEmail)) ? "Cancel" : " Decline"}`}
                    </Button>
                  </div>)}


        </div>

      </Offcanvas>
      <MessageModal show={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default OffCanvas;
