import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import wealthmanager from "../services/wealthmanager.service";
import { localDataRemove } from "../config/finance";
import { headerAddToken } from "../util/common";
import API from "../util/api";
import { useNavigate } from "react-router-dom";

export const wealth_companies_add = createAsyncThunk(
  "/financial-advisor/companies/add",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        "/wealthmanager/companies/add",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

// update company name
export const wealth_companies_update = createAsyncThunk(
  "/financial-advisor/companies/update",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        `/wealthmanager/companies/update`,
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const wealth_companies_add_partner = createAsyncThunk(
  "/financial-advisor/companies/add_partner",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        `/wealthmanager/companies/add_partner`,
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const comomon_partner_delete = createAsyncThunk(
  "/financial-advisor/companies/pending_company_user_delete",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.comomon_partner_delete(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_companies_list = createAsyncThunk(
  "/financial-advisor/companies/list",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        "/wealthmanager/companies/list",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const company_user_delete = createAsyncThunk(
  "/financial-advisor/companies/company_user_delete",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        "/wealthmanager/companies/company_user_delete",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_companies_count = createAsyncThunk(
  "/financial-advisor/companies/count",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        "/wealthmanager/companies/count",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_companies_partners = createAsyncThunk(
  "/financial-advisor/companies/partners",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        "/wealthmanager/companies/partners",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_manager_decrypt = createAsyncThunk(
  "/financial-advisor/companies/decrypt",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        "/wealthmanager/companies/decrypt",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      const message = error.response && error.response.data.message;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const wealth_manager_fetchIndividuals = createAsyncThunk(
  "/financial-advisor/companies/fetchIndividuals",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        "/wealthmanager/companies/fetchIndividuals",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_manager_fetchIndividuals_count = createAsyncThunk(
  "/financial-advisor/companies/fetchIndividuals_count",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        "/wealthmanager/companies/fetchIndividuals_count",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_individuals_delete = createAsyncThunk(
  "/financial-advisor/companies/delete/individual",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        "/wealthmanager/companies/delete/individual",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_company_remove = createAsyncThunk(
  "/financial-advisor/companies/delete",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        "/wealthmanager/companies/delete",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_individuals_exist = createAsyncThunk(
  "/financial-advisor/companies/check_exist/individual",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        "/wealthmanager/companies/check_exist/individual",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_find_search = createAsyncThunk(
  "/financial-advisor/companies/check_exist/individual",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        "/wealthmanager/companies/search/company",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_manage_files = createAsyncThunk(
  "/financial-advisor/companies/manage_files",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        "/wealthmanager/companies/manage_files",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_manage_files_remove = createAsyncThunk(
  "/financial-advisor/companies/file/remove",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const response = await API.post(
        `/wealthmanager/companies/file/remove`,
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
//`/wealthmanager/companies/pending_company_user_delete/${comp_id}/${request_id}`
export const pending_company_user_delete = createAsyncThunk(
  "/financial-advisor/companies/pending_company_user_delete",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.pending_company_user_delete(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_submission_history_add = createAsyncThunk(
  "/financial-advisor/submission/add",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.wealth_submission_history_add(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_submission_history_list = createAsyncThunk(
  "/financial-advisor/submission/findAll",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.wealth_submission_history_list(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_submission_history_update = createAsyncThunk(
  "/financial-advisor/submission/update",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.wealth_submission_history_update(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_submission_history_delete = createAsyncThunk(
  "/financial-advisor/submission/delete",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.wealth_submission_history_delete(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_submission_history_findone = createAsyncThunk(
  "/financial-advisor/submission/findone",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.wealth_submission_history_findone(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_managers_findone = createAsyncThunk(
  "/financial-advisor/single",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.wealth_managers_findone(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wmmfaEmailUpdateRecord = createAsyncThunk(
  "/financial-advisor/wmmfaemailupdate",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.wmmfaEmailUpdateRecord(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      // if (error.response.data.code === 401) {
      //   window.location.href = "/";
      localDataRemove();
      // } else {
      const message = error.response && error.response.data.message;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
  // }
);
export const wealth_managers_update = createAsyncThunk(
  "/financial-advisor/update",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.wealth_managers_update(values);
      // thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        const navigate = useNavigate();
        navigate("/");
        // window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const wealth_managers_avtar_update = createAsyncThunk(
  "/financial-advisor/wealth_managers_avtar_update",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.wealth_managers_avtar_update(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        const navigate = useNavigate();
        navigate("/");
        // window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
export const wm_image_update = createAsyncThunk(
  "/financial-advisor/imageupdate",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.wealth_managers_image_update(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_managers_mfaPhoneUpdateRecord = createAsyncThunk(
  "/financial-advisor/mfaphoneupdate",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.wealth_managers_mfaPhoneUpdateRecord(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
// This API will call when user reset phone mfs from wm settings section.
export const wm_setreset_phone_mfa = createAsyncThunk(
  "/financial-advisor/wmphoneupdatemfa",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.wm_setreset_phone_mfa(values);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_managers_mfaPhoneAuthPin = createAsyncThunk(
  "/financial-advisor/mfaSetupPhoneAuthPinWm",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.wealth_managers_mfaPhoneAuthPin(
        values
      );
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wealth_managers_findall = createAsyncThunk(
  "/financial-advisor/all",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.wealth_managers_findall(values);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const wmChangePhoneNumber = createAsyncThunk(
  "/financial-advisor/wmchangephonenumber",
  async (values, thunkAPI) => {
    try {
      const response = await wealthmanager.wmChangePhoneNumber(values);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return message;
      }
      // return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  mfaSetupPhone: {},
};

const wealthManagerSlice = createSlice({
  name: "wealthManager",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      wealth_managers_mfaPhoneAuthPin.fulfilled,
      (state, action) => {
        state.mfaSetupPhone = action.payload;
      }
    );
  },
});

const { reducer } = wealthManagerSlice;
export default reducer;
