import { createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import CommonUsersService from "../services/commonUser.service";
import { localDataRemove } from "../config/finance";

export const fetch_user_from_email = createAsyncThunk(
  "/userinfo/fetch_user_from_email",
  async (values, thunkAPI) => {
    try {
      const response = await CommonUsersService.fetch_user_from_email(values);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
