import React from "react";
import { Modal, Button } from "react-bootstrap";
import UseDownload from "../../../customHooks/UseDownload";
import { backAppURl } from "../../../applicationMode";

const ExportPdfExcelModal = ({ exportPdfExcelModal, setExportPdfExcelModal, exportUserId }) => {
  let dynamicURL = backAppURl;

  const downloadExcel = (params) => {
    UseDownload(`${dynamicURL}/api/export/excel/${params}`, "PFS.xlsx");
  };

  return (
    <>
      <Modal
        show={exportPdfExcelModal}
        onHide={!exportPdfExcelModal}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">Export</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Download</h6>
          <div>
            <p onClick={() => downloadExcel(exportUserId)}>Export to Excel</p>
          </div>
          {/* <div className="wm-dashbrd-action-export">
            <p
              className="p-1 m-0 cursor_pointer export-to-excel"
              onClick={() => downloadPdf(exportUserId)}
            >
              Export to PDF
            </p>
          </div> */}
        </Modal.Body>
        <Modal.Footer>
          <div className="cs-modal-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={() => setExportPdfExcelModal(false)}
            >
              Cancel
            </Button>
            <Button className="cs-btn-primary md-btn default-regular-h5">
              Continue
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExportPdfExcelModal;
