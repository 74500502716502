import React, { useState } from "react";
import {
    Button,
    Form,
    Image,
    Modal
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { socket } from '../../../config/Socket'
import { CancelSignatureRequest, decline_by_main_user } from "../../../slices/requests";
import { backAppURl } from "../../../applicationMode";
import { roleName } from "../../../config/commonRoleName";

const DeclineSignatureModal = (props) => {
    const [declineMessage, setDeclineMessage] = useState("");
    const [declineMessageError, setDeclineMessageError] = useState(false);
    const { authUser } = useSelector((state) => state.auth);
    const mainUserEmail = authUser && authUser.user ? authUser.user.email : authUser && authUser.email;

    const dispatch = useDispatch();
    const { showSignatureRequestDecline,
        setShowSignatureRequestDecline,
        onSignatureRequestDecline,
        signatureRequestId,
        sendUser,
        toastMessage,
        OffcanvasState,
        current_user_token
    } = props;
    const { user } = useSelector((state) => state.auth);
    const role = user.user_role.role
    async function emailCancellation(signatureRequestId) {

        let flag = `${(role == 2 || role == 3) ? "w_m" : "indi"}`;
        let flag2 = `${role === 1 && OffcanvasState.firstUserEmail === OffcanvasState.secondUserEmail ? "same" : ""}`

        let cancellationApi = `${backAppURl}/api/hellosign/cancel_signature`;
        // await fetch(cancellationApi, {
        //     method: "post",
        //     headers: {
        //         Accept: "application/json",
        //         "Content-Type": "application/json",
        //         Authorization: `Bearer ${user.token}`,
        //     },
        //     body: JSON.stringify({
        //         values: OffcanvasState.signature_hello_id,
        //         user_id: OffcanvasState.request_send_by,
        //         firstUserEmail: OffcanvasState.firstUserEmail,
        //         secondUserEmail: OffcanvasState.secondUserEmail,
        //         username: OffcanvasState.username,
        //         wealthManagerName: OffcanvasState.wealthManagerName,
        //         flag: flag,
        //         flag2: flag2,
        //         request_sent_by_role: OffcanvasState.request_sent_by_role,
        //         decline_msg: declineMessage
        //         // id: OffcanvasState.id
        //     }),
        // });
        let requestBody;

        // if ((OffcanvasState.secondUserEmail === OffcanvasState.firstUserEmail ||
        //     ((OffcanvasState.request_sent_by_role === roleName.financial_advisor || OffcanvasState.request_sent_by_role === roleName.tax_professional)
        //         && mainUserEmail === OffcanvasState.secondUserEmail))) {
        //     requestBody = {
        //         values: OffcanvasState.signature_hello_id,
        //         user_id: OffcanvasState.request_send_by,
        //         firstUserEmail: OffcanvasState.firstUserEmail,
        //         secondUserEmail: OffcanvasState.secondUserEmail,
        //         username: OffcanvasState.username,
        //         wealthManagerName: OffcanvasState.wealthManagerName,
        //         flag: flag,
        //         flag2: flag2,
        //         request_sent_by_role: OffcanvasState.request_sent_by_role,

        //     };
        // } else {
        requestBody = {
            values: OffcanvasState.signature_hello_id,
            user_id: OffcanvasState.request_send_by,
            firstUserEmail: OffcanvasState.firstUserEmail,
            secondUserEmail: OffcanvasState.secondUserEmail,
            username: OffcanvasState.username,
            wealthManagerName: OffcanvasState.wealthManagerName,
            flag: flag,
            flag2: flag2,
            request_sent_by_role: OffcanvasState.request_sent_by_role,
            decline_msg: declineMessage
        };
        // }

        await fetch(cancellationApi, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.token}`,
            },
            body: JSON.stringify(requestBody),
        });

        // toastMessage("Your signature request was declined successfully.", "success");
    }
    const handleSignatureRequestDecline = () => {

        // if (!((OffcanvasState.secondUserEmail === mainUserEmail && OffcanvasState.requestPageType === "Signature To")
        //     || OffcanvasState.secondUserEmail === OffcanvasState.firstUserEmail ||
        //     ((OffcanvasState.request_sent_by_role == roleName.financial_advisor || OffcanvasState.request_sent_by_role == roleName.tax_professional)
        //         && mainUserEmail === OffcanvasState.secondUserEmail))) {
        //     if (declineMessage.trim() === "") {
        //         setDeclineMessageError(true);
        //         return;
        //     }

        // }
        // if (declineMessage.trim() === "") {
        //     setDeclineMessageError(true);
        //     return;
        // }


        let flag = `${role === 3 ? roleName.tax_professional : role === 2 ? roleName.financial_advisor : roleName.individual}`;
        dispatch(CancelSignatureRequest({ id: OffcanvasState.id, userEmail: sendUser, flag: flag, decline_msg: declineMessage }))
            .unwrap()
            .then((response) => {
                toastMessage("Your signature request was declined successfully.", "success");
                socket.emit("notification", "message")
                onSignatureRequestDecline();
                emailCancellation(signatureRequestId);
            })
            .catch((err) => {
                toastMessage('Failed to Decline Signature request', "error");
            });
    };
    const handleDeclineMessage = (e) => {
        setDeclineMessage(e.target.value)
    }
    const handleCancelReasonModal = () => {
        setShowSignatureRequestDecline(false)
        setDeclineMessageError(false);
    }

    return (
        <Modal
            dialogClassName="cs-md-modal"
            show={showSignatureRequestDecline}
            onHide={!showSignatureRequestDecline}
            backdrop="static"
            keyboard={false}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header>
                {(OffcanvasState.secondUserEmail === mainUserEmail && OffcanvasState.requestPageType === "Signature To") || OffcanvasState.secondUserEmail === OffcanvasState.firstUserEmail ||
                    ((OffcanvasState.request_sent_by_role === roleName.financial_advisor || OffcanvasState.request_sent_by_role === roleName.tax_professional)
                        && mainUserEmail === OffcanvasState.secondUserEmail) ? <Modal.Title className="default-semi-bold-h3">
                    Cancel signature request
                </Modal.Title> : <Modal.Title className="default-semi-bold-h3">
                    Decline signature request
                </Modal.Title>}
            </Modal.Header>
            <Modal.Body>
                <div className="decline-signature-request">

                    <Form.Group className="cs-form-group">
                        {(OffcanvasState.secondUserEmail === mainUserEmail && OffcanvasState.requestPageType === "Signature To") || OffcanvasState.secondUserEmail === OffcanvasState.firstUserEmail ||
                            ((OffcanvasState.request_sent_by_role === roleName.financial_advisor || OffcanvasState.request_sent_by_role === roleName.tax_professional)
                                && mainUserEmail === OffcanvasState.secondUserEmail) ?
                            <Form.Label className="default-regular-sub-heading-xs cs-neutral-100">Reason for cancelling (optional)</Form.Label>
                            : <Form.Label className="default-regular-sub-heading-xs cs-neutral-100">Reason for declining (optional)</Form.Label>}

                        <textarea
                            autoComplete="off"
                            type="text"
                            className={declineMessage ? "cs-input-field-active cs-textarea" : "cs-textarea"}
                            onChange={(e) => handleDeclineMessage(e)}
                        ></textarea>
                        {/* {declineMessageError && <>
                            {(OffcanvasState.secondUserEmail === mainUserEmail && OffcanvasState.requestPageType === "Signature To") || OffcanvasState.secondUserEmail === OffcanvasState.firstUserEmail ||
                                ((OffcanvasState.request_sent_by_role === roleName.financial_advisor || OffcanvasState.request_sent_by_role === roleName.tax_professional)
                                    && mainUserEmail === OffcanvasState.secondUserEmail) ?
                                <span className="form-error-msg default-light-body-text-s cs-danger">
                                    Tell us the reason for cancelling
                                </span> :
                                <span className="form-error-msg default-light-body-text-s cs-danger">
                                    Tell us the reason for declining
                                </span>}
                        </>

                        } */}
                    </Form.Group>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="cs-modal-btn">
                    <Button className="cs-btn-secondary md-btn default-regular-h5" onClick={() => handleCancelReasonModal()} >
                        Cancel
                    </Button>
                    <Button className="cs-btn-primary md-btn default-regular-h5" onClick={() => handleSignatureRequestDecline()} >
                        Submit
                    </Button>
                </div>
            </Modal.Footer>




        </Modal>
    )
};

export default DeclineSignatureModal;