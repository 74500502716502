import React, { useState, useEffect } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { add_audit_log } from "../../slices/auditLogs";
import ErrorValidation from "../../../assets/images/other/error-validation.svg";
import "../../../app/commonComponent/component/Component.css";
import { socket } from "../../config/Socket";
import { localStorageClear } from "../../util/common";
import { signout } from "../../slices/auth";
import { change_edit_permission } from "../../slices/requests";
import commonNames from "../../config/commonNames";
import { roleName } from "../../config/commonRoleName";
import { useNavigate } from "react-router-dom";

const InactivityLogoutModal = ({ remainingSeconds, onStay }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, authUser } = useSelector((state) => state.auth);
  const auth_user_role = (authUser && authUser.role) || "";
  const role = user && user.user_role.role;
  const id = user && user.user.id;

  useEffect(() => {
    if (remainingSeconds === 0) {
      // Call logout function after countdown reaches zero
      if (window.localStorage.pfs_access_obj) {
        // update user active flag
        socket.emit("remove_edit_permission", {
          user_id: JSON.parse(window.localStorage.getItem("pfs_access_id")),
          advisor_user_id: window.localStorage.getItem("id"),
          flag:
            auth_user_role === 1
              ? roleName.individual
              : auth_user_role === 2
              ? roleName.financial_advisor
              : roleName.tax_professional,
        });
      }
      handleLogout();
    }
  }, [remainingSeconds]);
  const handleLogout = async () => {
    dispatch(add_audit_log({ signout: true }));
    // let user_id;
    // let user_role;
    // if (window.localStorage.pfs_access_obj) {
    //   user_id = Number(JSON.parse(window.localStorage.getItem("id")));
    //   user_role = Number(
    //     JSON.parse(window.localStorage.getItem("pfs_access_obj"))
    //       .pfs_access_role
    //   );
    // } else {
    //   user_id = id;
    //   user_role = user_role;
    // }

    // socket.emit("fetch_user", {
    //   user_id: user_id,
    //   user_type: user_role,
    //   user_role:
    //     user_role === 1
    //       ? roleName.individual
    //       : user_role === 2
    //         ? roleName.financial_advisor
    //         : roleName.tax_professional,
    //   logged_out: true,
    // });
    const response = await dispatch(
      signout({
        user_id: authUser ? authUser?.user?.id : user?.user?.id,
        user_role: authUser ? authUser?.user_role?.role : user?.user_role?.role,
      })
    ).unwrap();
    if (response && response.status == "Success") {
      socket.disconnect();
      localStorageClear();
      navigate("/", { replace: true });
      onStay();
      window.location.reload();
    }
    // navigate("/", { replace: true });
    // Close the modal
  };

  return (
    <>
      <Modal
        show
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
        className="cs-common-modal-overlay"
        onHide={handleLogout}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            No activity detected
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="log-out-popup">
            <span>
              <Image
                src={ErrorValidation}
                alt="Error Validation"
                width={100}
                height={100}
              />
            </span>
            <p className="default-light-body-text-m cs-neutral-90 text-center">
              To ensure the security of your account and data, you will be
              automatically logged out in&nbsp;
              <span className="cs-neutral-100">
                {Math.floor(remainingSeconds / 60)}:
                {remainingSeconds % 60 < 10 ? "0" : ""}
              </span>
              {remainingSeconds % 60} due to inactivity.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="cs-modal-btn cs-center-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={handleLogout}
              variant="secondary"
            >
              Log out
            </Button>
            <Button
              className="cs-btn-primary md-btn default-regular-h5"
              onClick={onStay}
              variant="primary"
            >
              Stay on CapSync
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InactivityLogoutModal;
