import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';

const ConfirmationModel = ({
    showConfirmation,
    setShowConfirmation,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    let store = location.pathname.split("/");
    const unique_row_id = store[store.length - 2];
    const verificationType = store[store.length - 3];

    const handleCancel = () => {
        setShowConfirmation(false);
        navigate("/individual/verifyinvestor/pending");
    }

    const handleContinue = () => {
        setShowConfirmation(false);
        if (verificationType === "individuals" || verificationType === "trusts" || verificationType === "entities" || verificationType === "qualifiedclient" || verificationType === "qualifiedpurchaser") {
            navigate(`/individual/verifyinvestor/accreditedInvestor/${verificationType}/${unique_row_id}`);
        }

    }
    const handleverificationType = (verificationType, unique_row_id) => {
        if (verificationType === "qualifiedpurchaser" || unique_row_id === "qualifiedpurchaser") {
            return "a qualified purchaser"
        } else if (verificationType === "qualifiedclient" || unique_row_id === "qualifiedclient") {
            return "a qualified client"
        } else {
            return "an accredited investor"
        }
    }
    return (
        <Modal
            show={showConfirmation}
            onHide={!showConfirmation}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="cs-md-modal"
            className="confirmation-modal"
        >
            <Modal.Header>
                <Modal.Title className="default-semi-bold-h3">
                    Confirmation
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="delete-modal-content">
                    <p className="default-medium-sub-heading-m cs-neutral-100 text-center">
                        Do not cancel if you need more time to finish this request. If you do not complete the verification within 45 days of the original request, we will assume that you have cancelled this verification request and report that we were unable to verify you. Please contact support before your request expires for on extension.
                    </p>
                    <p className="default-regular-body-text-m cs-neutral-90 text-center">
                        Please confirm that you are not {`${handleverificationType(verificationType, unique_row_id)}`} investor or wish to cancel this verification request. If you are not {`${handleverificationType(verificationType, unique_row_id)}`} or wish to cancel this verification request, the verification process will be completed with the result that you are not verified {`${handleverificationType(verificationType, unique_row_id)}`}.
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>

                <Button className="cs-btn-secondary xl-btn default-regular-h5" onClick={handleCancel}>
                    I am not verified or wish to cancel</Button>
                <Button className="cs-btn-primary xl-btn default-regular-h5" onClick={handleContinue}>
                    I do not wish to cancel, continue</Button>

            </Modal.Footer>
        </Modal>
    )
}

export default ConfirmationModel