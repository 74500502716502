/* eslint-disable */
import React, { useCallback, useState, useEffect } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  Dropdown,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import DatePicker from "react-date-picker";
import NumberFormat from "react-currency-format";
import { useFormik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";

import "../liability_dashboard/LiabilityDashboard.css";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import validationObj from "./validationSchema";
import { adjustLength } from "../../../../customHooks/adjustLength";
import {
  categoryAdd,
  categoryUpdateRecord,
} from "../../../../slices/category.slice";
import commonNames from "../../../../config/commonNames";
import {
  NotesPlaceholder,
  addTimePeriod,
  calculatePaymentPeriod,
  checkPhoneValid,
  combine,
  getAddressObject,
  loanTermType,
  setAddressString,
  phoneHandleChange,
} from "../../../../util/common";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import useDebounce from "../../../../customHooks/useDebounce";
import { validateDuplicateFileName } from "../../../../config/validateDuplicateFileName";
import AddressSearch from "../../../../commonComponent/AddressSearch";
import {
  handleInterestValue,
  handleOwnershipKeyDown,
  handleOwnershipPaste,
  handleOwnershipValue,
} from "../../../../config/handleOwnershipValue";
import {
  get_user_storage,
  useProgressUploadDetails,
  useUploadStorage,
} from "../../../../slices/fileManagement.slice";

const MortgagesOnRealEstate = (props) => {
  const {
    onFormClose,
    onFormSubmit,
    record,
    showModal,
    linkAddressObj = null,
  } = props;

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [storeAcceptedFiles, setStoreAcceptedFiles] = useState([]);

  // Initial value for Forms
  const [address, setAddress] = useState("");
  const [addressObj, setAddressObj] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
  });
  const [amortization, setAmortization] = useState(false);
  const [autocompletePredictions, setAutocompletePredictions] = useState([]);
  const [initialValues, setInitialValues] = useState({
    user_id: id,
    creditor_name: "",
    creditor_email: "",
    creditor_phonenumber: "",
    property_address: "",
    loan_amount: "",
    ownership: "",
    date_Loan: moment().format(),
    loan_period: "",
    frequency: loanTermType[0],
    annual_rate: "",
    maturity_date: "",
    payment: "",
    balance_due: "",
    mortgage_balance: "",
    date_updated: moment().format(),
    loan_type: "",
    collateral: "",
    notes: "",
    is_manual: true,
    is_manual_address: false,
    city: "",
    street_name: "",
    zip_code: "",
  });

  const [errorMessage, setErrorMessage] = useState("");

  const [phoneNo, setPhoneNo] = useState("");
  const [msg, setMsg] = useState("");
  const [newDollar, setDollar] = useState(false);
  const [newDollar1, setDollar1] = useState(false);
  const [newDollar2, setDollar2] = useState(false);
  const [duplicateFileError, setDuplicateFileError] = useState("");
  const [duplicateAddressError, setDuplicateAddressError] = useState("");
  const [storageError, setStorageError] = useState("");
  const [isManualAddress, setIsManualAddress] = useState(false); //Is Manual address
  const storageData = useUploadStorage();
  const progressUploadDetails = useProgressUploadDetails();
  const check = (para, a) => {
    const common = combine(para, a);
    if (common.value === "dollar") {
      setDollar(common.status);
    } else if (common.value === "dollar_one") {
      setDollar1(common.status);
    } else if (common.value === "dollar_two") {
      setDollar2(common.status);
    } else if (common.value === "dollar_three") {
      // setDollar3(common.status);
    }
  };

  useEffect(() => {
    if (!!record) {
      setInitialValues({
        user_id: record.user_id || "",
        creditor_name: record.creditor_name || "",
        creditor_email: record.creditor_email || "",
        creditor_phonenumber: record.creditor_phonenumber || "",
        property_address: record.property_address
          ? setAddressString(record.property_address)
          : "",
        loan_amount:
          (record.loan_amount && record.loan_amount.toLocaleString("en-US")) ||
          "",
        date_Loan: record.date_Loan
          ? new Date(moment(record.date_Loan).format("MM/DD/YYYY"))
          : record && record.api_provider !== null
          ? ""
          : moment().format(),
        annual_rate:
          (record.annual_rate && record.annual_rate.toLocaleString("en-US")) ||
          "",

        ownership: record.ownership && record.ownership.toLocaleString("en-US"),
        loan_period:
          (record.loan_period && record.loan_period.toLocaleString("en-US")) ||
          "",
        frequency: record.frequency || "",
        collateral: record.collateral || "",
        balance_due:
          (record.balance_due && record.balance_due.toLocaleString("en-US")) ||
          "",
        mortgage_balance:
          (record.mortgage_balance &&
            record.mortgage_balance.toLocaleString("en-US")) ||
          "",
        date_updated: record.date_updated
          ? new Date(moment(record.date_updated).format("MM/DD/YYYY"))
          : moment().format(),
        loan_type: record.loan_type || "",
        collateral: record.collateral || "",
        notes: record.notes || "",
        is_manual: record.is_manual || "",
        city: record.property_address && record.property_address.city,
        state: record.property_address && record.property_address.state,
        zip_code:
          record.property_address && record.property_address.postal_code,
        street_name: record.property_address && record.property_address.line1,
      });
      setAmortization(record.is_amortization_link_enable || false);
      record.property_address &&
        setAddress(setAddressString(record.property_address));
      record.creditor_phonenumber && setPhoneNo(record.creditor_phonenumber);
      //Manual Address
      setIsManualAddress(record.is_manual_address);
    }
  }, [record]);

  useEffect(() => {
    if (linkAddressObj) {
      setAddress(setAddressString(linkAddressObj));
      setFieldValue("property_address", setAddressString(linkAddressObj));
    }
  }, [linkAddressObj]);

  let validationSchema =
    validationObj.liability_realEstateMortgage_validationSchema;

  const {
    values,
    errors,
    touched,
    status,
    setErrors,
    setValues,
    setStatus,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, { setErrors }) => {
      if (values.mortgage_balance === "." || values.mortgage_balance === "0.") {
        setErrors({ mortgage_balance: "Enter a numeric value" });
        return;
      }
      if (
        !!status &&
        (!!status.street_name ||
          !!status.city ||
          !!status.state ||
          !!status.zip_code)
      ) {
        return;
      }
      if (phoneNo && (!isValidPhoneNumber(phoneNo) || msg)) {
        setMsg("Enter a valid phone number");
        return true;
      }
      setLoading(true);
      setSubmitLoading(true);
      const monthlyPayment = calculatePaymentPeriod(
        values.loan_amount,
        values.annual_rate,
        values.frequency === "Yearly"
          ? values.loan_period
          : values.loan_period / 12,
        12
      );
      if (!!record && !values.is_manual) {
        if (values.date_Loan && values.loan_period && values.frequency)
          values.maturity_date = addTimePeriod(
            values.date_Loan,
            values.loan_period,
            values.frequency
          );
        const data = {
          category: "mortgagesonrealestate",
          main_category: "liabilities",
          newData: {
            creditor_email: values.creditor_email,
            creditor_name: values.creditor_name,
            mortgage_balance: values.mortgage_balance,
            balance_due:
              values.mortgage_balance && values.ownership
                ? (
                    (parseFloat(
                      values.mortgage_balance.toString().replace(/,/g, "")
                    ) *
                      parseFloat(values.ownership)) /
                    100
                  ).toString()
                : "",
            date_updated: values.date_updated,
            creditor_phonenumber: phoneNo,
            property_address: addressObj,
            loan_type: values.loan_type,
            ownership: values.ownership,
            notes: values.notes,
            id: record.id,
            user_id: id,
            is_manual_address: isManualAddress,
            collateral: values.collateral,
            loan_amount: values.loan_amount,
            date_Loan: values.date_Loan,
            loan_period: values.loan_period,
            annual_rate: values.annual_rate,
            maturity_date: values.maturity_date,
            frequency: values.frequency,
            payment: monthlyPayment,
          },
        };

        dispatch(categoryUpdateRecord(data))
          .unwrap()
          .then((response) => {
            if (response.code === 200) {
              onFormSubmit("mortgagesonrealestate");
              props.dashboardAPi();
              toast.success(response.message);
            } else if (response.code === 500 || response.code === 403) {
              !isManualAddress &&
                setFieldValue("property_address", setAddressString(addressObj));
              setAddressObj(values.property_address);
              setValues({
                ...values,
                street_name: values.property_address.line1,
                city: values.property_address.city,
                state: values.property_address.state,
                zip_code: values.property_address.postal_code,
                ownership: values.ownership,
              });
              if (response.code === 403) {
                setDuplicateAddressError(response.message);
              } else {
                setErrorMessage(response.message);
                setStorageError(response.message);
              }
              const isToastDisplay =
                response && response.toast ? response.toast : false;
              if (isToastDisplay) {
                toast.error(response.message);
              }
            }
            setLoading(false);
            setSubmitLoading(false);
          })
          .catch((err) => {
            // setModalShow(false);
            toast.error(err.message);
            setLoading(false);
            setSubmitLoading(false);
          });
      } else {
        values.creditor_phonenumber = phoneNo;
        record && (values.id = record.id);
        values.user_id = id;

        values.balance_due =
          values.mortgage_balance && values.ownership
            ? (
                (parseFloat(
                  values.mortgage_balance.toString().replace(/,/g, "")
                ) *
                  parseFloat(values.ownership)) /
                100
              ).toString()
            : "";
        values.pfs_access_id = id ? id : 0;
        values.is_amortization_link_enable = amortization;
        values.property_address = addressObj;
        values.apr_percentage && values.apr_percentage.toLocaleString("en-US");
        values.payment = monthlyPayment;
        values.is_manual_address = isManualAddress;
        delete values.is_manual;

        delete values.city;
        delete values.street_name;
        delete values.state;
        delete values.zip_code;
        delete values.country;

        if (values.date_Loan && values.loan_period && values.frequency)
          values.maturity_date = addTimePeriod(
            values.date_Loan,
            values.loan_period,
            values.frequency
          );
        const formdata = new FormData();
        const inProgressData = progressUploadDetails.length
          ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
          : 0;
        const used_space = storageData.usedSpace + inProgressData;
        formdata.append("user_details", JSON.stringify(values));
        formdata.append("user_id", values.user_id);
        formdata.append("used_space", used_space);
        formdata.append("institution", values.creditor_name);

        for (let i = 0; i < storeAcceptedFiles.length; i++) {
          formdata.append("attachments", storeAcceptedFiles[i]);
        }
        let data = {
          category: "mortgagesonrealestate",
          main_category: "liabilities",
          used_space: used_space,
        };

        if (!!record) {
          data = { ...data, newData: values };
          dispatch(categoryUpdateRecord(data))
            .unwrap()
            .then((response) => {
              if (response.code === 200) {
                onFormSubmit("mortgagesonrealestate");
                toast.success(response.message);
                props.dashboardAPi();
              } else if (response.code === 500 || response.code === 403) {
                values.is_manual = true;
                // setFieldValue("property_address", setAddressString(addressObj));
                setAddressObj(values.property_address);
                setValues({
                  ...values,
                  street_name: values.property_address.line1,
                  city: values.property_address.city,
                  state: values.property_address.state,
                  zip_code: values.property_address.postal_code,
                  ownership: values.ownership,
                });
                if (response.code === 403) {
                  setDuplicateAddressError(response.message);
                } else {
                  setErrorMessage(response.message);
                  setStorageError(response.message);
                }
                const isToastDisplay =
                  response && response.toast ? response.toast : false;
                if (isToastDisplay) {
                  toast.error(response.message);
                }
              }
              setLoading(false);
            })
            .catch((err) => {
              // setModalShow(false);
              toast.error(err.message);
              setLoading(false);
            });
        } else {
          data = { ...data, formdata: formdata };
          dispatch(categoryAdd(data))
            .unwrap()
            .then(async (response) => {
              if (response.code === 200) {
                const response = await dispatch(
                  get_user_storage({ id })
                ).unwrap();
                onFormSubmit("mortgagesonrealestate");
                // toast.success(response.message);
                props.dashboardAPi();
              } else if (response.code === 500 || response.code === 403) {
                values.is_manual = true;
                // setFieldValue("property_address", setAddressString(addressObj));
                setAddressObj(values.property_address);
                setValues({
                  ...values,
                  street_name: values.property_address.line1,
                  city: values.property_address.city,
                  state: values.property_address.state,
                  zip_code: values.property_address.postal_code,
                  ownership: values.ownership,
                });
                if (response.code === 403) {
                  setDuplicateAddressError(response.message);
                } else {
                  setErrorMessage(response.message);
                  setStorageError(response.message);
                }
                const isToastDisplay =
                  response && response.toast ? response.toast : false;
                if (isToastDisplay) {
                  toast.error(response.message);
                }
              }
              setLoading(false);
              setSubmitLoading(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
              setSubmitLoading(false);
            });
        }
      }
    },
  });

  const handleChange = (e) => {
    setLoading(false);
    // setFieldValue(e.target.name, e.target.value);
    if (
      (e.target.name === "ownership" || e.target.name === "annual_rate") &&
      e.target.value > 100
    ) {
      setFieldValue(e.target.name, "");
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
    setDuplicateFileError("");
  };

  // Upload File Handler
  const removeAcceptedFile = (params) => {
    let store = [...storeAcceptedFiles];
    store.splice(params, 1);
    setStoreAcceptedFiles(store);
    setDuplicateFileError("");
    setStorageError("");
  };
  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setDuplicateFileError("");
      setStorageError("");
      if (
        validateDuplicateFileName(
          acceptedFiles,
          storeAcceptedFiles,
          setDuplicateFileError
        )
      ) {
        setStoreAcceptedFiles([...acceptedFiles, ...storeAcceptedFiles]);
      }
    },
    [storeAcceptedFiles]
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    disabled: loading,
    onDropAccepted,
  });
  const files = storeAcceptedFiles.map((file, i) =>
    adjustLength(file.path, () => removeAcceptedFile(i))
  );

  return (
    <Modal
      show={showModal}
      backdrop="static"
      id="connect-your-institutions"
      aria-labelledby="contained-modal-title-vcenter"
      className="cap-dash-main-modal"
      centered
      keyboard={false}
      dialogClassName="cs-lg-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          {commonNames.mortgageOnRealestate}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="personal-inform-form mortgages-on-real-estate-form"
        >
          <Row>
            <Col xs={12} md={12}>
              <Form.Group
                className={
                  !values.is_manual
                    ? "cs-form-group cs-disabled"
                    : "cs-form-group"
                }
              >
                <Form.Label>Name of Creditor</Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  name="creditor_name"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.creditor_name}
                  className={values.creditor_name && "cs-input-field-active"}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  onKeyPress={(e) =>
                    !/^[a-zA-Z_ ]*$/.test(e.key) && e.preventDefault()
                  }
                  disabled={!values.is_manual}
                />
                {errors.creditor_name && touched.creditor_name && (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.creditor_name}
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="email"
                  placeholder="Optional"
                  name="creditor_email"
                  onKeyDown={(e) => e.code === "Space" && e.preventDefault()}
                  defaultValue={values.creditor_email}
                  className={values.creditor_email && "cs-input-field-active"}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                />
                {errors.creditor_email && touched.creditor_email ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.creditor_email}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col lg={6} sm={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Phone Number (Optional)</Form.Label>
                <PhoneInput
                  limitMaxLength={15}
                  international
                  countryCallingCodeEditable={false}
                  addInternationalOption={false}
                  placeholder="Optional"
                  autoComplete="off"
                  defaultCountry="US"
                  name="creditor_phonenumber"
                  withCountryCallingCode={false}
                  value={values.creditor_phonenumber}
                  // className={values.phoneNo && "cs-input-field-active"}
                  className="form-control"
                  countries={["US"]}
                  // onChange={(e) => checkPhoneValid(e, setPhoneNo, setMsg, setLoading)}
                  onChange={(e) => {
                    phoneHandleChange(e, setPhoneNo, setMsg, setLoading);
                  }}
                />
                {msg !== "" ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {msg}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <AddressSearch
            handleChange={handleChange}
            setErrorMessage={setErrorMessage}
            duplicateAddressError={duplicateAddressError}
            values={values}
            errors={errors}
            touched={touched}
            record={
              linkAddressObj
                ? linkAddressObj
                : record && record.property_address
            }
            setAddressObj={setAddressObj}
            addressObj={addressObj}
            setFieldValue={setFieldValue}
            setErrors={setErrors}
            setStatus={setStatus}
            status={status}
            setFieldTouched={setFieldTouched}
            setIsAddressChange={(bool) => {
              setLoading(!bool);
            }}
            label={"Property Address"}
          />
          {/* {errorMessage !== "" && (
            <span className={`default-light-body-text-s cs-danger `}>
              {errorMessage}
            </span>
          )} */}
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className={"cs-form-group"}>
                <Form.Label>Loan Amount</Form.Label>
                <NumberFormat
                  type="text"
                  thousandSeparator={true}
                  autoComplete="off"
                  placeholder=""
                  name="loan_amount"
                  maxLength={12}
                  value={values.loan_amount}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  className={
                    values.loan_amount
                      ? "dollar-symbol text-md-end form-control cs-input-field-active"
                      : "text-md-end form-control"
                  }
                  allowNegative={false}
                  // disabled={!values.is_manual}
                  decimalScale={2}
                  onKeyDown={(e) => {
                    if (e.key === "." && e.target.value === "") {
                      e.preventDefault(); // Prevent typing another dot if there's already one
                    }
                  }}
                />
                {errors.loan_amount && touched.loan_amount ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.loan_amount}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className={"cs-form-group"}>
                <Form.Label>Loan Date</Form.Label>
                <DatePicker
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  name="date_Loan"
                  value={values.date_Loan}
                  onChange={(e) => {
                    setLoading(false);
                    setFieldValue("date_Loan", e);
                  }}
                  // onBlur={handleBlur}
                  clearIcon={null}
                  className={
                    values.date_Loan
                      ? "cs-input-field-active select-date-range cs-date-picker-calender"
                      : "cs-date-picker-calender"
                  }
                  calendarIcon={
                    <span className="cs-neutral-90">
                      <CapsyncIcon title="calender-outlined" size="18" />
                    </span>
                  }
                  format="MM/dd/y"
                  maxDate={new Date()}
                  // disabled={!values.is_manual}
                />
                {errors.date_Loan && touched.date_Loan ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.date_Loan}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className={"cs-form-group"}>
                <Form.Label>Loan Term</Form.Label>
                <>
                  <div className="loan-term-box">
                    <div className="loan-term-input">
                      <NumberFormat
                        type="text"
                        thousandSeparator={true}
                        autoComplete="off"
                        placeholder=""
                        name="loan_period"
                        onKeyDown={(e) => {
                          if (e.key === " " && e.target.selectionStart === 0) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={3}
                        decimalScale={0}
                        value={values.loan_period}
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        className={
                          values.loan_period
                            ? "form-control cs-input-field-active"
                            : "form-control"
                        }
                        onPaste={(e) => {
                          const clipboardData =
                            e.clipboardData || window.clipboardData;
                          const pastedText = clipboardData.getData("Text");

                          // Check if the pasted value is negative and prevent it if it is
                          if (parseFloat(pastedText) < 0) {
                            e.preventDefault();
                          }
                        }}
                        allowNegative={false}
                        // disabled={!values.is_manual}
                      />
                    </div>
                    <div className="loan-term-dropdown cs-common-add-dropdown">
                      <Dropdown>
                        <Dropdown.Toggle>
                          {/* {values.frequency || "Choose Term"} */}
                          {values.frequency}
                          <span className="cs-neutral-90">
                            <CapsyncIcon
                              title="chevron-down-outlined"
                              size="18"
                            />
                          </span>
                        </Dropdown.Toggle>
                        {
                          <Dropdown.Menu>
                            {loanTermType.map((dateItr) => (
                              <Dropdown.Item
                                className={
                                  dateItr === values.frequency
                                    ? "cs-selected-dropdown"
                                    : ""
                                }
                                key={dateItr}
                                onClick={() => {
                                  setLoading(false);
                                  setFieldValue("frequency", dateItr);
                                }}
                              >
                                {dateItr}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        }
                      </Dropdown>
                    </div>
                  </div>
                </>
                {errors.loan_period && touched.loan_period ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.loan_period}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className={"cs-form-group"}>
                <Form.Label>Interest Rate (%)</Form.Label>
                <NumberFormat
                  type="text"
                  autoComplete="off"
                  placeholder=""
                  name="annual_rate"
                  maxLength={5}
                  // onKeyDown={handleInterestValue}
                  value={values.annual_rate}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  className={
                    values.annual_rate
                      ? "form-control percentage-symbol cs-input-field-active"
                      : "form-control"
                  }
                  onKeyUp={(e) => check(e.target.value, "percentage")}
                  // onPaste={(e) => {
                  //   const clipboardData =
                  //     e.clipboardData || window.clipboardData;
                  //   const pastedText = clipboardData.getData("Text");

                  //   // Check if the pasted value is negative and prevent it if it is
                  //   if (parseFloat(pastedText) < 0) {
                  //     e.preventDefault();
                  //   }
                  // }}
                  onKeyDown={handleOwnershipKeyDown}
                  onPaste={handleOwnershipPaste}
                  allowNegative={false}
                  // disabled={!values.is_manual}
                  decimalScale={2}
                />
                {errors.annual_rate && touched.annual_rate ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.annual_rate}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col Col xs={12} md={6}>
              <Form.Group className="cs-form-group cs-disabled">
                <Form.Label>Maturity Date</Form.Label>
                <DatePicker
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  name="maturity_date"
                  value={
                    values.date_Loan && values.loan_period && values.frequency
                      ? addTimePeriod(
                          values.date_Loan,
                          values.loan_period,
                          values.frequency
                        )
                      : ""
                  }
                  onChange={(e) => {
                    setLoading(false);
                    setFieldValue("maturity_date", e);
                  }}
                  // onBlur={handleBlur}
                  clearIcon={null}
                  className={
                    values.maturity_date
                      ? "cs-input-field-active select-date-range cs-date-picker-calender"
                      : "cs-date-picker-calender"
                  }
                  calendarIcon={
                    <span className="cs-neutral-90">
                      <CapsyncIcon title="calender-outlined" size="18" />
                    </span>
                  }
                  format="MM/dd/y"
                  disabled={true}
                />
                {errors.maturity_date && touched.maturity_date ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.maturity_date}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Ownership (%)</Form.Label>
                <NumberFormat
                  autoComplete="off"
                  className={
                    values.ownership
                      ? "form-control percentage-symbol cs-input-field-active"
                      : "form-control"
                  }
                  type="text"
                  placeholder=""
                  name="ownership"
                  maxLength={5}
                  value={values.ownership}
                  onKeyDown={handleOwnershipKeyDown}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  allowNegative={false}
                  decimalScale={2}
                />
                {errors.ownership && touched.ownership ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.ownership}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group cs-disabled">
                <Form.Label>Monthly Payment</Form.Label>
                <NumberFormat
                  className={
                    "dollar-symbol text-md-end form-control cs-input-field-active"
                  }
                  thousandSeparator={true}
                  autoComplete="off"
                  placeholder=""
                  name="payment"
                  maxLength={12}
                  value={
                    values.loan_amount &&
                    values.annual_rate &&
                    values.annual_rate > 0 &&
                    values.loan_period
                      ? calculatePaymentPeriod(
                          values.loan_amount,
                          values.annual_rate,
                          values.frequency === "Yearly"
                            ? values.loan_period
                            : values.loan_period / 12,
                          12
                        ) /
                        (values.frequency === "Yearly"
                          ? values.loan_period * 12
                          : values.loan_period)
                      : values.loan_amount
                  }
                  onChange={handleChange}
                  onKeyUp={(e) => check(e.target.value, "dollar")}
                  // onBlur={handleBlur}
                  // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  disabled={true}
                  decimalScale={2}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group
                className={
                  !values.is_manual
                    ? "cs-form-group cs-disabled"
                    : "cs-form-group"
                }
              >
                <Form.Label>Mortgage Balance</Form.Label>
                <NumberFormat
                  className={
                    values.mortgage_balance
                      ? "dollar-symbol text-md-end form-control cs-input-field-active"
                      : "text-md-end form-control"
                  }
                  thousandSeparator={true}
                  autoComplete="off"
                  placeholder=""
                  name="mortgage_balance"
                  maxLength={12}
                  value={values.mortgage_balance}
                  onChange={handleChange}
                  onKeyUp={(e) => check(e.target.value, "dollar")}
                  // onBlur={handleBlur}
                  // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  onPaste={(e) => {
                    const clipboardData =
                      e.clipboardData || window.clipboardData;
                    const pastedText = clipboardData.getData("Text");

                    // Check if the pasted value is negative and prevent it if it is
                    if (parseFloat(pastedText) < 0) {
                      e.preventDefault();
                    }
                  }}
                  allowNegative={false}
                  disabled={!values.is_manual}
                  decimalScale={2}
                  onKeyDown={(e) => {
                    if (e.key === "." && e.target.value === "") {
                      e.preventDefault(); // Prevent typing another dot if there's already one
                    }
                  }}
                />
                {errors.mortgage_balance && touched.mortgage_balance && (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.mortgage_balance}
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col Col xs={12} md={6}>
              <Form.Group
                className={
                  !values.is_manual
                    ? "cs-form-group cs-disabled"
                    : "cs-form-group"
                }
              >
                <Form.Label>Date Updated</Form.Label>
                <DatePicker
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  name="date_updated"
                  value={values.date_updated}
                  onChange={(e) => {
                    setLoading(false);
                    setFieldValue("date_updated", e);
                  }}
                  // onBlur={handleBlur}
                  clearIcon={null}
                  className={
                    values.date_updated
                      ? "cs-input-field-active select-date-range cs-date-picker-calender"
                      : "cs-date-picker-calender"
                  }
                  calendarIcon={
                    <span className="cs-neutral-90">
                      <CapsyncIcon title="calender-outlined" size="18" />
                    </span>
                  }
                  format="MM/dd/y"
                  maxDate={new Date()}
                  disabled={!values.is_manual}
                />
                {errors.date_updated && touched.date_updated && (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.date_updated}
                  </span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  placeholder="Optional"
                  name="loan_type"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.loan_type}
                  className={values.loan_type && "cs-input-field-active"}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  onKeyPress={(e) =>
                    !/^[a-zA-Z_ ]*$/.test(e.key) && e.preventDefault()
                  }
                />
                {errors.loan_type && touched.loan_type ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.loan_type}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className={"cs-form-group"}>
                <Form.Label>Collateral</Form.Label>
                <Form.Control
                  autoComplete="off"
                  type="text"
                  placeholder="Optional"
                  name="collateral"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  value={values.collateral}
                  className={values.collateral && "cs-input-field-active"}
                  onChange={handleChange}
                  // onBlur={handleBlur}
                  onKeyPress={(e) =>
                    !/^[a-zA-Z_ ]*$/.test(e.key) && e.preventDefault()
                  }
                  // disabled={!values.is_manual}
                />
                {errors.collateral && touched.collateral ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {errors.collateral}
                  </span>
                ) : null}
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="cs-form-group cs-disabled">
                <Form.Label>Balance Due</Form.Label>
                <NumberFormat
                  className={
                    values.balance_due && values.ownership
                      ? "dollar-symbol text-md-end form-control cs-input-field-active"
                      : "text-md-end form-control"
                  }
                  thousandSeparator={true}
                  autoComplete="off"
                  placeholder=""
                  name="balance_due"
                  maxLength={12}
                  value={
                    values.mortgage_balance && values.ownership
                      ? (parseFloat(
                          values.mortgage_balance.toString().replace(/,/g, "")
                        ) *
                          parseFloat(values.ownership)) /
                        100
                      : 0
                  }
                  onChange={handleChange}
                  onKeyUp={(e) => check(e.target.value, "dollar")}
                  // onBlur={handleBlur}
                  // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  onPaste={(e) => {
                    const clipboardData =
                      e.clipboardData || window.clipboardData;
                    const pastedText = clipboardData.getData("Text");

                    // Check if the pasted value is negative and prevent it if it is
                    if (parseFloat(pastedText) < 0) {
                      e.preventDefault();
                    }
                  }}
                  allowNegative={false}
                  disabled={true}
                  decimalScale={2}
                />
              </Form.Group>
            </Col>
          </Row>
          {(record && record.api_provider === null) || record === undefined ? (
            <Row>
              <Col xs={12} md={12}>
                <Form className="cs-form-group amortization-schedule-box">
                  <Form.Check
                    id="amortizationadd"
                    type="checkbox"
                    aria-label="amortization"
                    checked={amortization}
                    onChange={(e) => {
                      setLoading(false);
                      setAmortization(e.target.checked);
                    }}
                    className="cs-form-check-box"
                    label={
                      <span
                        htmlFor="amortizationadd"
                        className="cursor-pointer default-regular-h5 cs-primary"
                      >
                        Create Amortization Schedule
                      </span>
                    }
                  />
                </Form>
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Notes</Form.Label>
                <textarea
                  autoComplete="off"
                  type="text"
                  placeholder={NotesPlaceholder}
                  name="notes"
                  value={values.notes}
                  className={
                    values.notes
                      ? "cs-input-field-active cs-textarea"
                      : "cs-textarea"
                  }
                  onChange={handleChange}
                  // onBlur={handleBlur}
                ></textarea>
              </Form.Group>
            </Col>
          </Row>
          <>
            {!record && (
              <>
                <Row>
                  <Col>
                    <Form.Label className="default-regular-sub-heading-xs cs-neutral-100">
                      Upload Files
                    </Form.Label>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="cs-upload-box">
                      <div
                        {...getRootProps({})}
                        className={`cs-upload-files cursor-pointer ${
                          loading && "cs-neutral-60 disabled"
                        }`}
                      >
                        <input
                          {...getInputProps()}
                          disabled={loading}
                          name="attachments"
                        />
                        <span className="cs-primary">
                          <CapsyncIcon title="upload-files-filled" size="28" />
                        </span>
                        <span className="default-regular-sub-heading-s cs-neutral-90">
                          <span className="cs-primary">Browse</span>&nbsp;or
                          drag file here
                        </span>
                        <p className="default-regular-sub-heading-xxs">
                          Supports PDF, PNG, JPG and JPEG format
                        </p>
                      </div>
                      <span className="form-error-msg default-light-body-text-s cs-danger">
                        {storageError || duplicateFileError}
                      </span>
                      <aside className="category-attachments-files">
                        <ul className="attachment-files cs-files">{files}</ul>
                      </aside>
                    </div>
                  </Col>
                </Row>
              </>
            )}

            <div className="cs-modal-btn">
              <Button
                className="cs-btn-secondary md-btn default-regular-h5"
                onClick={onFormClose}
                disabled={submitLoading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className="cs-btn-primary md-btn default-regular-h5"
                disabled={loading || submitLoading || errorMessage}
              >
                Save
                {submitLoading && (
                  <span className="cs-common-spinner cs-primary-main">
                    <CapsyncIcon title="loading-outlined" size="16" />
                  </span>
                )}
              </Button>
            </div>
          </>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MortgagesOnRealEstate;
