import React, { useState, useEffect } from "react";
import UserSingleFilled from "../../../../assets/images/other/user-single-filled.svg";
import CompanyFilled from "../../../../assets/images/other/company-filled.svg";
import UserMultipleFilled from "../../../../assets/images/other/user-multiple-filled.svg";
import { wealth_companies_count } from "../../../slices/wealthManager.slice";
import { access_invitation_Pfs } from "../../../slices/pfs_access";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SubCardComponent from "./SubCardComponent";
import { Col, Row } from "react-bootstrap";
import "./Dashboard.css"

const CardComponent = ({ toastMessage }) => {
  const [companiesNum, setCompaniesNum] = useState(0);
  const [individualsNum, setIndividualsNum] = useState(0);
  const { user, authUser } = useSelector((state) => state.auth);
  const role = user.user_role.role;
  const loggedUser = authUser && authUser.user ? authUser.user : authUser;

  // const user_id = user && user.user.id;
  const dispatch = useDispatch();

  function fetchCompaniesCount() {
    dispatch(wealth_companies_count({ user_id: loggedUser.id }))
      .unwrap()
      .then((response) => {
        setCompaniesNum(response.count);
      })
      .catch((err) => {
        toastMessage("wealth_companies_count :" + err.message, "error");
      });
  }


  const countAndSetAcceptedIndividual = (individuals) => {
    let numOfIndividuals = 0;
    for (let i = 0; i < individuals.length; i++) {
      const singleIndividual = individuals[i];
      if (singleIndividual.to_status === 1) {
        numOfIndividuals += 1;
      }
    }
    setIndividualsNum(numOfIndividuals)

  }

  function fetchIndividualsCount() {
    dispatch(access_invitation_Pfs({ email: loggedUser.email }))
      .unwrap()
      .then((response) => {
        countAndSetAcceptedIndividual(response)
      })
      .catch((err) => {
        toastMessage("access_invitation_Pfs :" + err.message, "error");
      });
  }

  useEffect(() => {
    fetchCompaniesCount();
    fetchIndividualsCount();
  }, [user]);
  return (
    <>
      <div className="financial-advisor-cards category-cards">
        <Row>
          <Col md={4} lg={4}>
            <Link
              to={`${
                role === 2
                  ? "/financial-advisor/individuals"
                  : "/professional/individuals"
              }  `}
              className="category-card">
              <SubCardComponent
                class1={"card"}
                img={UserSingleFilled}
                data={individualsNum}
                title={"Individuals"}
              />
            </Link>
          </Col>
          <Col md={4} lg={4}>
            <Link to={`${role === 2 ? "/financial-advisor/companies" : "/professional/companies"}`} className="category-card">
              <SubCardComponent
                class1={"card"}
                img={CompanyFilled}
                data={companiesNum}
                title={"Companies"}
              />
            </Link>
          </Col>
          <Col md={4} lg={4}>
            <div className="category-card">
              <SubCardComponent
                class1={"card"}
                img={UserMultipleFilled}
                data={individualsNum + companiesNum}
                title={"Total Clients"}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CardComponent;
