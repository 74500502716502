import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { backAppURl } from "../../../applicationMode";

import HelloSignProgress from "../../../commonComponent/component/HelloSignProgress";
import SharePFSModal from "./SharePFSModal";
import MyPFSModal from "./MyPFSModal";
import SelectPFSTypeModal from "./SelectPFSTypeModal";
import { Signature_Requests_Add } from "../../../slices/requests";

const SharePFS = props => {

  const [showSharePFS, setShowSharePFS] = useState(false);
  const [sharePFSFormDetails, setSharePFSFormDetails] = useState({});
  const [showMyPFS, setShowMyPFS] = useState(false);
  const [showSharePFSType, setShowSharePFSType] = useState(false);
  const [sharePFSTypeDetails, setSharePFSTypeDetails] = useState({});
  const [loader, setIsLoader] = useState(false);

  const dispatch = useDispatch();

  const { dataLiability, paymentInstallAuto,
    dataAsset, getCategoryTotalLib, getCategoryTotal, pfsSection,
    toastMessage
  } = props;

  const { user } = useSelector((state) => state.auth);
  const current_user_token = user && user.token;
  let id;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  useEffect(() => {
    //Submit share pfs form
    setShowSharePFS(props.openSharePFS);
  }, [props.openSharePFS]);

  const handleSubmitSharePFSForm = (formData) => {
    //Share PFS form details from user that this send for Sign request
    setSharePFSFormDetails(formData);
    setShowSharePFS(false);
    setShowMyPFS(true);
  }

  const handleSubmitSend = (formData) => {
    //Select type of user selected for sending the Signature requets flow
    setSharePFSTypeDetails(formData);
    signatureRequest();
  }

  const signatureRequest = () => {
    //submit of signature request
    try {
      setIsLoader(false);

      const requestData = {

      }
      // const response = dispatch(Signature_Requests_Add(requestData));

      // if (response.payload.code === 200) {
      //   toastMessage("Your Personal Financial Statement was sent successfully.", "success");
      // }
    } catch (err) {
      toastMessage("Your signature request has been sent successfully. ", "error");
    }
  }

  //export PDF
  const downloadPdf = async (flag, nameValue) => {
    await axios({
      url: `${backAppURl}/api/pdf/export`,
      method: "post",
      responseType: "blob",
      data: { user_id: id, flag: "true", sec: flag, secondName: nameValue },
      headers: { Authorization: `Bearer ${current_user_token}` },
    })
      .then((response) => {
      })
      .catch((err) => {
        console.log("err", err);
        return false;
      });
  };

  return (
    <>
      {loader && <HelloSignProgress />}

      {pfsSection === "navBarSection" &&
        <SharePFSModal
          showSharePFS={showSharePFS}
          setShowSharePFS={setShowSharePFS}
          onSubmitSharePFSForm={handleSubmitSharePFSForm}
        />}

      <MyPFSModal
        pfsSection={pfsSection}
        showMyPFS={showMyPFS}
        setShowMyPFS={setShowMyPFS}
        dataLiability={dataLiability}
        paymentInstallAuto={paymentInstallAuto}
        dataAsset={dataAsset}
        getCategoryTotalLib={getCategoryTotalLib}
        getCategoryTotal={getCategoryTotal}
        toastMessage={toastMessage}
        onSharePFSTypeToggle={setShowSharePFSType}
      />

      <SelectPFSTypeModal
        showSharePFSType={showSharePFSType}
        onSendSharePFS={handleSubmitSend}
      />

    </>
  )

};

export default SharePFS;