/* eslint-disable */
import React from "react";
import { Form, Row, Col, Image } from "react-bootstrap";
import SubMenuHeader from "../SubMenuHeader";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import SubMethodsList from "../../SubMethodsList";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import { Link } from "react-router-dom";
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";

// sequence No. one  (Asmita date-29th June => Do not remove this line)
const MethodIdThirty = (props) => {
  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Qualified Purchaser",
      url: `/individual/verifyinvestor/accreditedInvestor/qualifiedpurchaser/${props.unique_code}`,
    },
  ];

  return (
    <>
      {props.is_loader.assets === true ||
      props.is_loader.liabilities === true ? (
        <SkeletonAllSubMethods />
      ) : (
        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="$5M+ Investments Individual"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>
              <SubMenuHeader />
            </div>
            <div className="method-section">
              <div className="default-regular-body-text-l cs-neutral-70">
                <SubMethodsList methodId={32} />
              </div>

              <div className="cs-divider"></div>
              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert}
                />
                <div className="row-spacing">
                  <p className="default-regular-sub-heading-m cs-neutral-70">
                    In order to verify your accreditation we need you to upload
                    evidence. Please upload the appropriate files below and
                    black-out any sensitive information.
                  </p>
                  <Link
                    to="https://s3.amazonaws.com/static-capsync.com/Verify-Investor-files/Net-investments.pdf"
                    target="_blank"
                    className="vi-link-color"
                  >
                    <u className="default-regular-sub-heading-m cs-neutral-100">
                      Examples of Net Investments and how these are calculated.
                    </u>
                  </Link>
                </div>
                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Upload evidence of ownership of at least $5,000,000 USD in Net
                  Investments:
                </Form.Label>
                <UploadFileBlock
                  files={props.files}
                  setOpenModal={props.setOpenModal}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile}
                  showFile={props.showFile}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink}
                  uploadError={props.uploadError}
                  showError={props.showError}
                />
                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Upload other evidence (optional):
                </Form.Label>
                <UploadFileBlock
                  files={props.files2}
                  setOpenModal={props.setOpenModal2}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile2}
                  showFile={props.showFile2}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink2}
                />
                <NotesBlock setNotesValue={props.setNotesValue} />
              </Form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MethodIdThirty;
