import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    Accordion,
    Col,
    Table
} from "react-bootstrap";

import CapsyncIcon from "../../commonComponent/CapsyncIcon";
import { formatPhoneNumber } from "../../util/common";
import CapsyncToolTip from "../../commonComponent/capsyncTooltip/CapsyncToolTip";
import { useSelector } from "react-redux";

const IndividualTable = (props) => {


    const { data, accessIndividualPFS } = props;
    const { user } = useSelector((state) => state.auth);
    const role = user.user_role.role;
    const handlePFS = (e, row) => {
        const { is_subscribe, id } = row
        if (is_subscribe === true) {
            console.log(is_subscribe, id)
            accessIndividualPFS(id)
        }
    }

    return (
      <>
        {data.length > 0 && (
          <>
            <div className="assets-table-container financial-recent-dashboard">
              <Table className="cs-table">
                <thead className="cs-thead">
                  <tr>
                    <th className="default-medium-sub-heading-m cs-neutral-100">
                      First name
                    </th>
                    <th className="default-medium-sub-heading-m cs-neutral-100">
                      Last name
                    </th>
                    <th className="default-medium-sub-heading-m cs-neutral-100">
                      Email
                    </th>
                    <th className="default-medium-sub-heading-m cs-neutral-100">
                      Phone No.
                    </th>
                    <th className="default-medium-sub-heading-m cs-neutral-100"></th>
                  </tr>
                </thead>
                <tbody className="cs-tbody default-regular-sub-heading-m cs-neutral-90">
                  {data.map((companyDetails) => {
                    const {
                      id,
                      firstName,
                      lastName,
                      phoneNo,
                      email,
                      is_subscribe,
                    } = companyDetails;
                    return (
                      <tr
                        onClick={(e) => handlePFS(e, companyDetails)}
                        className={is_subscribe && "cursor-pointer"}>
                        <td className="default-regular-sub-heading-m cs-neutral-90">
                          {/* {firstName} */}
                          {firstName ? (
                            firstName.length > 25 ? (
                              <CapsyncToolTip
                                Child={firstName.slice(0, 25) + "..."}
                                placement={"top"}
                                message={firstName}
                                type="text"
                              />
                            ) : (
                              firstName
                            )
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="default-regular-sub-heading-m cs-neutral-90">
                          {/* {lastName} */}
                          {lastName ? (
                            lastName.length > 25 ? (
                              <CapsyncToolTip
                                Child={lastName.slice(0, 25) + "..."}
                                placement={"top"}
                                message={lastName}
                                type="text"
                              />
                            ) : (
                              lastName
                            )
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="default-regular-sub-heading-m cs-neutral-90">
                          {/* {email} */}
                          {email ? (
                            email.length > 25 ? (
                              <CapsyncToolTip
                                Child={email.slice(0, 25) + "..."}
                                placement={"top"}
                                message={email}
                                type="text"
                              />
                            ) : (
                              email
                            )
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="default-regular-sub-heading-m cs-neutral-90">
                          {formatPhoneNumber(phoneNo)}
                        </td>
                        <td className="default-regular-sub-heading-m cs-neutral-90 wm-ind-dashboard-action">
                          <span
                            className="cs-neutral-80"
                            onClick={(e) => {
                              if (is_subscribe === true) {
                                accessIndividualPFS(id);
                              }
                              e.stopPropagation();
                            }}>
                            {is_subscribe === false ? (
                              <span className="cs-neutral-80 cs-disabled-icon">
                                <CapsyncToolTip
                                  Child={"chevron-right-outlined"}
                                  placement={"top"}
                                  size="18"
                                  message={
                                    "There’s an issue with this users account"
                                  }
                                  type="icon"
                                />
                              </span>
                            ) : (
                              <CapsyncIcon
                                title="chevron-right-outlined"
                                size="18"
                              />
                            )}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <span className="wm-view-all">
              {role ===2 ? <Link to={"/financial-advisor/individuals"}>View all</Link> : <Link to={"/professional/individuals"}>View all</Link>}
              
            </span>
          </>
        )}

        {data.length == 0 && (
          <span className="cs-common-no-record">
            There are no records to display
          </span>
        )}
      </>
    );
};

export default IndividualTable;