import React from 'react'
import Skeleton from 'react-loading-skeleton';
import { Table } from "react-bootstrap";

const SkeletonCategoryTable = ({ tableData }) => {
    return (
        <>
            <div className='for-des skeleton-common-table'>
                <Table className="cs-table skeleton-table">
                    <thead className="cs-thead">
                        <tr>
                            <th className="default-medium-sub-heading-m" width="840">
                                <Skeleton width={200} />
                            </th>
                            <th className="default-medium-sub-heading-m" width="380">
                                <Skeleton width={200} />
                            </th>
                            {/* <th className="header-gap-width"></th> */}
                            <th
                                className="default-medium-sub-heading-m"
                                width="260"
                            >
                                <Skeleton width={200} />
                            </th>
                            <th width="104"></th>
                            <th width="104"></th>
                        </tr>
                    </thead>
                    <tbody className="cs-tbody default-regular-sub-heading-m">
                        {tableData.map((category, index) => {
                            const { title, balance, pay, date, url } = category;
                            return (
                                <tr key={index}>
                                    <td>
                                        <Skeleton width={500} />
                                    </td>
                                    <td>
                                        <Skeleton width={200} />
                                    </td>
                                    <td>
                                        <Skeleton width={150} />
                                    </td>
                                    <td
                                        className="go-next-page-div"
                                    >
                                        <Skeleton />
                                    </td>
                                    <td></td>
                                </tr>
                            );
                        })}
                        <tr
                        >
                            <td>
                                <Skeleton width={500} />
                            </td>
                            <td>
                                <Skeleton width={200} />
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <div className='for-mob skeleton-common-table'>
                <Table className="cs-table skeleton-table">
                    <thead className="cs-thead">
                        <tr>
                            <th className="default-medium-sub-heading-m" width="840">
                                <Skeleton width={70} />
                            </th>
                            <th className="default-medium-sub-heading-m" width="380">
                                <Skeleton width={50} />
                            </th>
                            {/* <th className="header-gap-width"></th> */}
                            <th
                                className="default-medium-sub-heading-m"
                                width="260"
                            >
                                <Skeleton width={50} />
                            </th>
                            {/* <th width="104"></th> */}
                        </tr>
                    </thead>
                    <tbody className="cs-tbody default-regular-sub-heading-m">
                        {tableData.map((category, index) => {
                            const { title, balance, pay, date, url } = category;
                            return (
                                <tr key={index}>
                                    <td>
                                        <Skeleton width={100} />
                                    </td>
                                    <td>
                                        <Skeleton width={50} />
                                    </td>
                                    <td>
                                        <Skeleton width={50} />
                                    </td>
                                    {/* <td
                                    className="go-next-page-div"
                                >
                                    <Skeleton />
                                </td> */}
                                </tr>
                            );
                        })}
                        <tr
                        >
                            <td>
                                <Skeleton width={50} />
                            </td>
                            <td>
                                <Skeleton width={50} />
                            </td>
                            <td></td>
                            {/* <td></td> */}
                        </tr>
                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default SkeletonCategoryTable