import API from "../util/api";
import { headerAddToken } from "../util/common";

const verify_investor_add = async (values) => {
  let header = await headerAddToken();
  return API.post("/verify_investor/add", values, header);
};

const verify_investor_findUser = async (values) => {
  let header = await headerAddToken();
  return API.post("/verify_investor/find", values, header);
};

const vi_findUser_by_UniqueID = async (values) => {
  let header = await headerAddToken();
  return API.post("/verify_investor/findUser_by_UniqueID", values, header);
};
const add_update_verification_methodId = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/verify_investor/add_update_verification_methodId",
    values,
    header
  );
};
const upload_media = async (values) => {
  let header = await headerAddToken();
  const { formdata, drop_box_id } = values;
  return API.post(`/verify_investor/upload_media/${drop_box_id}`, formdata, header);
};

const update_investor_type = async (values) => {
  let header = await headerAddToken();
  return API.post("/verify_investor/update_investor_type", values, header);
};

const fetch_vi_methods_typewise = async (values) => {
  let header = await headerAddToken();
  return API.post("/verify_investor/fetch_vi_methods_typewise", values, header);
};

const verify_request_and_send = async (values) => {
  let header = await headerAddToken();
  return API.post("/verify_investor/verify_request_and_send", values, header);
};
const download_certificate_from_vi = async (values) => {
  let header = await headerAddToken();
  return API.get(
    `/verify_investor/download_certificate_from_vi/${values.vi_id}/${values.user_id}`, header
  );
};

const upload_required_document = async (values) => {
  let header = await headerAddToken();
  return API.post("/verify_investor/upload_req_doc", values, header);
};

const remove_pending_req = async (values) => {
  let header = await headerAddToken();
  return API.post("/verify_investor/delete_vi_request", values, header);
};
const vi_legal_name = async (values) => {
  let header = await headerAddToken();
  return API.post("/verify_investor/find_legal_name", values, header);
};

const vi_fetch_attachments = async (values) => {
  let header = await headerAddToken();
  return API.get(`/verify_investor/fetch_attachments/${values.user_id}`, header);
};

const store_pfs_link = async (values) => {
  let header = await headerAddToken();
  return API.post("/verify_investor/store_pfs_link", values, header);
};
const store_notes_value = async (values) => {
  let header = await headerAddToken();
  return API.post("/verify_investor/store_notes_value", values, header);
};
const vi_fetch_media = async (values) => {
  let header = await headerAddToken();
  return API.get(`/verify_investor/fetch_media/${values.unique_code}/${values.vi_method_id}`, header);
};
const vi_remove_attachment = async (values) => {
  let header = await headerAddToken();
  return API.post("/verify_investor/remove_attachment", values, header);
};
const submit_investors_api = async (values) => {
  let header = await headerAddToken();
  return API.post("/verify_investor/submit_investors_api", values, header);
};
const verifyInvestorService = {
  verify_investor_add,
  verify_investor_findUser,
  fetch_vi_methods_typewise,
  vi_findUser_by_UniqueID,
  update_investor_type,
  add_update_verification_methodId,
  upload_media,
  verify_request_and_send,
  download_certificate_from_vi,
  upload_required_document,
  remove_pending_req,
  vi_fetch_attachments,
  vi_legal_name,
  store_pfs_link,
  store_notes_value,
  vi_fetch_media,
  vi_remove_attachment,
  submit_investors_api
};

export default verifyInvestorService;
