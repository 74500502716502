import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTabState: "",
};

const activeTabSlice = createSlice({
  name: "ActiveTab",
  initialState,
  reducers: {
    setActiveTabState: (state, action) => {
      state.activeTabState = action.payload;
    },
  },
});

export const { setActiveTabState } = activeTabSlice.actions;

export default activeTabSlice.reducer;
