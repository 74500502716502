import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import LeftPart from "./LeftPart";
import { mfaGoogleAuthFirstPin } from "../../slices/auth";
import Cookies from "universal-cookie";
import { loginStatusStore, deviceVerifyCookieStore } from "../../util/common";
import CapsyncIcon from "../../commonComponent/CapsyncIcon";
import LogoImage from "../logoImage";
import { socket } from "../../config/Socket";
import { roleName } from "../../config/commonRoleName";
import { syncPlaidAccounts } from "../../config/plaidFunctions";
import { uniValidation } from "../individual/liabilities/categoryForms/validationSchema";

const SignInAuthMFGooglePin = () => {
  const [loading, setLoading] = useState(false);
  const [deviceVerify, setDeviceVerify] = useState(false);
  const [otp, setOtp] = useState();
  const [msg, setMsg] = useState("");
  const { user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const email = user && user.user.email;

  const handleChange = (otp) => {
    setMsg("");
    setOtp(otp);
  };

  const handleRemember = (e) => {
    if (e.target.checked) {
      setDeviceVerify(e.target.checked);
    } else {
      setDeviceVerify(false);
      cookies.remove(`deviceAuth24Hr-${id}`);
    }
  };

  const id = user && user.user.id;
  useEffect(() => {
    const broadcastChannel = new BroadcastChannel("signin_channel");
    broadcastChannel.onmessage = (event) => {
      if (event.data === "signin") {
        setTimeout(() => {
          // window.location.reload();
        }, 500);
      }
    };
    return () => {
      broadcastChannel.close();
    };
  }, []);


  const handleSignIn = () => {
    const broadcastChannel = new BroadcastChannel("signin_channel");
    broadcastChannel.postMessage("signin");
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const MFAGoogleOtp = otp;
      if (MFAGoogleOtp) {
        const res = await dispatch(mfaGoogleAuthFirstPin({ email, id, MFAGoogleOtp }))
          .unwrap()
        if (res) {
          setMsg(res.message);
          setLoading(false);
          if (res.code === 200) {
            handleSignIn()
            deviceVerifyCookieStore(id, deviceVerify);
            loginStatusStore(res.role, res.res_data.user.id);
            if (res.role === 1) {
              syncPlaidAccounts(res.res_data.user.id)
              navigate("/individual");
            } else if (res.role == 2) {
              navigate("/financial-advisor");
            } else if (res.role == 3) {
              navigate("/professional");
            }
            socket.emit("fetch_user", {
              user_id: res.res_data.user.id,
              user_type: res.role,
              user_role: res.role === 1 ? roleName.individual : res.role === 2 ? roleName.financial_advisor : roleName.tax_professional,
              logged_out: false,
            });
            socket.emit("user_logging_out", true);
          } else {
            setMsg(res.message);
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
        setMsg(uniValidation.otp.require);
      }
    } catch (error) {
      console.log('error', error)
    }
    // handleSwitchAccount()
  };

  return (
    <section className="auth-section mfa-pin-google">
      <Container fluid>
        <Row className="align-items-center">
          <Col lg={6}>
            <LeftPart
              page="mfa-google-pin"
              img="auth-image.svg"
              title="Your New Immersive Digital Finance Hub."
              text="Sync your real-time digital Personal Financial Statement with CapSync."
            />
          </Col>
          <Col lg={6}>
            <Row>
              <Col>
                <div className="auth-right-section">
                  <LogoImage />
                  <div className="cs-auth-form">
                    <h1 className="default-semi-bold-h1 cs-neutral-100">
                      Authenticator app verification
                    </h1>
                    <hr className="head-underline" />
                    <div className="auth-cs-logo">
                      <span className="cs-neutral-80 default-regular-h5">
                        Enter the 6-digit code from your authenticator app.
                      </span>
                    </div>
                    <div className="auth-form">
                      <Form onSubmit={handleSubmit}>
                        <OtpInput
                          value={otp}
                          onChange={handleChange}
                          numInputs={6}
                          renderInput={(props) => <input {...props} />}
                          inputStyle="otp-txt"
                          containerStyle="otp-layout"
                          // isInputNum={true}
                          inputType="number"
                          shouldAutoFocus={true}
                        />
                        {msg ? (
                          <div className="cs-msg default-regular-body-text-s cs-danger">
                            <span className="icon">
                              <CapsyncIcon title="info-filled" />
                            </span>
                            <span className="formik-form-error"> {msg}</span>
                          </div>
                        ) : null}
                        {message && message.message && (
                          <div className="cs-msg default-regular-body-text-s cs-danger">
                            <span className="icon">
                              <CapsyncIcon title="info-filled" />
                            </span>
                            <span className="formik-form-error">
                              {" "}
                              {message.message}
                            </span>
                          </div>
                        )}
                        <div key={`default-checkbox`}>
                          <Form.Check
                            type={"checkbox"}
                            id={`default-checkbox`}
                            feedbackType="invalid"
                            className="cs-form-check-box"
                            aria-label="option 1"
                            name="terms"
                            checked={deviceVerify}
                            onChange={handleRemember}
                            label={
                              <span className="default-light-body-text-m cs-neutral-90 cursor-pointer d-flex">
                                Trust this device for 180 days
                              </span>
                            }
                          />
                        </div>
                        <Button
                          type="submit"
                          className="cs-btn-primary lg-btn default-regular-h5 cs-ver-btn"
                        // disabled={loading}
                        >
                          Verify account
                        </Button>
                      </Form>
                      {/* <Form>
                        <div key={`default-checkbox`}>
                          <Form.Check
                            type={"checkbox"}
                            id={`default-checkbox`}
                            feedbackType="invalid"
                            className="cs-form-check-box"
                            aria-label="option 1"
                            name="terms"
                            checked={//deviceVerify}
                            onChange={handleRemember}
                            label={
                              <span className="default-light-body-text-m cs-neutral-90 cursor-pointer">
                                Remember this device for 24 Hours
                              </span>
                            }
                          />
                        </div>
                      </Form> */}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SignInAuthMFGooglePin;
