import React from "react";
import { Image } from "react-bootstrap";
import defaultImg from "../../../../../assets/images/institution_logo/ins_129570.svg";

const DefaultImg = ({ params }) => {
  try {
    return (
      <>
        <Image
          src={require(`../../../../../assets/images/institution_logo/${params}`)}
          alt="loadergif"
          className="plaid-img-anotherbank plaid-logo-circle"
        />
      </>
    );
  } catch (error) {
    return (
      <>
        <Image
          src={defaultImg}
          alt="loadergif"
          className="plaid-img-anotherbank plaid-logo-circle"
        />
      </>
    );
  }
};

export default DefaultImg;
