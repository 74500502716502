import React from "react";
import CapsyncIcon from "../commonComponent/CapsyncIcon";

function adjustLength(params, removeAcceptedFile) {
  return (
    <li key={params} className="cs-selected-files">
      <div className="cs-fileicon cs-neutral-100">
        <span className="cs-icon cs-neutral-10">
          <CapsyncIcon title="file-icon-outlined" size="16" />
        </span>
      </div>
      <div className="cs-files-names">
        <span className="download-filename default-regular-sub-heading-xs cs-neutral-100">
          {params.length > 30 ? params.substring(0, 30) + "..." : params}
        </span>
      </div>
      <div className="cs-file-icon icons">
        <span
          className="cs-icon-badge-danger cs-danger cursor-pointer"
          onClick={removeAcceptedFile}
        >
          <CapsyncIcon title="delete-outlined" size="18" />
        </span>
      </div>
    </li>
  );
}

function adjustLengthVi(params) {
  if (params && params.length > 30) {
    return <p key={params}>{params.substring(0, 30) + "..."}</p>;
  } else {
    return <p key={params}>{params}</p>;
  }
}

function customLabelLength(label, limit, maxLimit) {
  if (label) {
    if (label.length > limit) {
      return label.substring(0, maxLimit) + "...";
    } else {
      return label;
    }
  }
}

export { adjustLength, adjustLengthVi, customLabelLength };
