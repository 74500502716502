import React, { useState } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import "../PaymentSubscription.css";
import ErrorValidation from "../../../../assets/images/other/error-validation.svg";

const MicroDepositeVerificationModal = () => {
  const [show, setShow] = useState(true);

  return (
    <>
      <Modal
        className="cs-common-modal-overlay"
        show={show}
        onHide={!show}
        style={{ zIndex: "99999999" }}
        centered
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3 card-modal-head">
            Verify Account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <span>
            <Image
              src={ErrorValidation}
              alt="Error Validation"
              width={100}
              height={100}
            />
          </span>
          <p>
            You have not verified your account, please check your inbox and
            verify your bank account first to continue with Capsync.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <div className="cs-modal-btn cs-center-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MicroDepositeVerificationModal;
