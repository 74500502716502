import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { userFindRecord, mfaphoneotpupdatewithotp } from "../../../slices/user";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import IndiPhonePinVerification from "./IndiPhonePinVerification";
import { uniValidation } from "../liabilities/categoryForms/validationSchema";

function PhoneVerificationIndi() {
  const [phoneValue, setPhoneValue] = useState();
  const [phoneNo, setPhoneNo] = useState();
  const [msg, setMsg] = useState("");
  const [finalMsg, setFinalMsg] = useState("");
  const [formData, setFormData] = useState(true);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  useEffect(() => {
    if (id) {
      dispatch(userFindRecord({ id }))
        .unwrap()
        .then((response) => {
          setPhoneNo(response.phoneNo);
        })
        .catch(() => { });
    }
  }, [id]);

  const handleChange = (val) => {
    if (val) {
      setFinalMsg();
      if (isValidPhoneNumber(val) === true) {
        setPhoneNo(val);
        setMsg("");
      } else {
        setPhoneNo(val);
        setMsg("Please enter valid number.");
      }
    } else {
      setPhoneNo();
      setMsg();
    }
  };

  const handleSubmit = async () => {
    if (phoneNo && msg === "") {
      const response = await dispatch(
        mfaphoneotpupdatewithotp({ id, phoneNo })
      );
      if (response.payload.code === 200) {
        setFormData(false);
        setPhoneValue(phoneNo);
        setMsg(response.message);
      } else {
        setFinalMsg(response.payload);
      }
    } else {
      setMsg(uniValidation.phone_number.valid);
    }
  };
  return (
    <>
      {formData ? (
        <Form>
          <Row>
            <Col lg={12} sm={12}>
              <Form.Group
                className="mb-3 cap-form-group"
                controlId="validationFormik04"
              >
                <PhoneInput
                  limitMaxLength={15}
                  international
                  countryCallingCodeEditable={false}
                  placeholder="Phone Number"
                  autoComplete="off"
                  name="phoneNo"
                  defaultCountry="US"
                  withCountryCallingCode={false}
                  value={phoneNo}
                  className="form-control"
                  onChange={handleChange}
                  countries={["US"]}
                />
                {msg ? <p className="formik-form-error">{msg}</p> : null}
                {finalMsg ? (
                  <p className="formik-form-error">{finalMsg}</p>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          <div className="ind-setting-email-mfa">
            <div>
              <Button
                className="cs-pri-sm-btn"
                type="button"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        <Row>
          <Col lg={12} sm={12}>
            <IndiPhonePinVerification phoneValue={phoneValue} />
          </Col>
        </Row>
      )}
    </>
  );
}

export default PhoneVerificationIndi;
