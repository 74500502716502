import React, { useState } from "react";
import { Row, Col, Form, Button, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import MfaPhonePinVerification from "./MfaPhonePinVerification";
import { mfaPhoneAuthSetup } from "../../../slices/auth";
import { formatPhoneNumber } from "../../../util/common";
import { uniValidation } from "../liabilities/categoryForms/validationSchema";
import { useLocation } from "react-router-dom";

function MfaPhoneVerification({ CapsyncMessage, closeModal }) {
  const [msg, setMsg] = useState("");
  const [mFAPhone, setmFAPhone] = useState(false);
  const { user, authUser } = useSelector((state) => state.auth);

  const { pathname } = useLocation();
  const isMySettings = pathname?.split("/")?.[2] === "my-settings";

  const phoneNo = isMySettings ? authUser.user.phoneNo : user.user.phoneNo;
  const id = isMySettings ? authUser.user.id : user.user.id;
  const email = isMySettings ? authUser.user.email : user.user.email;

  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (phoneNo && msg === "") {
      dispatch(mfaPhoneAuthSetup({ id, email, phoneNo }))
        .then((res) => {})
        .catch((err) => {});
      setmFAPhone(true);
    } else {
      setMsg(uniValidation.phone_number.valid);
    }
  };

  return (
    <>
      {!mFAPhone && (
        <>
          <span className="default-regular-h5 cs-neutral-80 text-center mobile-verification-title">
            Enter your phone number below to complete the mobile verification
            process.
          </span>
          <Form.Group className="cs-form-group mobile-verification-box">
            <FormControl
              className=""
              placeholder="Phone Number"
              name="phoneNo"
              value={formatPhoneNumber(phoneNo)}
              disabled={true}
            />
            {msg ? <p className="formik-form-error">{msg}</p> : null}
          </Form.Group>
          <div className="cs-modal-btn">
            <Button
              type="button"
              className="cs-btn-secondary md-btn default-regular-h5"
              variant="secondary"
              onClick={() => closeModal()}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={handleSubmit}
              className="cs-btn-primary md-btn default-regular-h5"
              variant="primary"
            >
              Send SMS
            </Button>
          </div>
        </>
      )}
      {!!mFAPhone && (
        <Row>
          <Col lg={12} sm={12}>
            <MfaPhonePinVerification
              CapsyncMessage={CapsyncMessage}
              closeModal={closeModal}
            />
          </Col>
        </Row>
      )}
    </>
  );
}

export default MfaPhoneVerification;
