import { createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import API from "../util/api";
import { localDataRemove } from "../config/finance";
import { headerAddToken } from "../util/common";

export const show_categories = createAsyncThunk(
  "/master_table/all",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post("/master_table/all", values, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);
