import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
  CloseButton,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import Dropzone from "react-dropzone";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { requestsAdd, requestBulkAdd } from "../../../slices/requests";
import { wm_to_ind_resend_email } from "../../../slices/requests";
import { socket } from "../../../config/Socket";
import { values } from "pdf-lib";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import CapsyncToolTip from "../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import AssociateCompany from '../Common Components/AssociateCompany'
import SampleFile from '../../../../assets/csv/SampleFile.csv'
import { roleName } from "../../../config/commonRoleName";
import { checkDomainAccept } from "../../../util/common";
import { uniValidation } from "../../individual/liabilities/categoryForms/validationSchema";

const AddModal = ({ changeModalState, modalState, toastMessage, getIndividuals }) => {
  const { user } = useSelector((state) => state.auth);
  const role = user && user.user_role.role
  const user_id = user && user.user.id;
  const user_displayName = user && user.user.displayName;
  const user_email = user && user.user.email;
  const [bulkImport, setBulkImport] = useState(0)
  const [selectedFile, setSelectedFile] = useState(null);
  const [validationMessage, setValidationMessage] = useState(null);
  const [fileLoader, setFileLoader] = useState(false)
  const [isSaveEnabled, setSaveEnabled] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedCompanyName, setSelectedCompanyName] = useState("");
  const [dropdownValidation, setDropdownValidation] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    socket.on("connection", () => { });
  }, []);

  const [validateIndividual, setValidateIndividual] = useState({
    msg: "",
    color: "",
    msg_no: "",
    row_id: "",
  });

  const dispatch = useDispatch();
  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      // .matches(/^\S*$/, "Whitespace is not allowed")
      .required(uniValidation.email.required).test("is-not-only-spaces", uniValidation.email.required, (value) => {
        return value.trim() !== "";
      })
      .email(uniValidation.email.validEmail)
      .test('no-more-than-30-before-at', 'Cannot accept more than 30 characters before @', value =>
        value ? value.split('@')[0].length <= 30 : true
      )
      .test('at-least-1-character-before-at', 'Enter at least 1 character before @', value =>
        value ? value.split('@')[0].length > 0 : true
      )
      .test('custom-email-format', uniValidation.email.validEmail, value => {
        return value ? uniValidation.email.allowedFormat.test(value) : true
      })
      // .test('no-capital-letters', 'Email address should not contain capital letters', value => {
      //   return !/[A-Z]/.test(value);
      // })
      .test('domain-acceptance', uniValidation.email.validEmail, value => {
        return checkDomainAccept(value);
      }),
    firstName: yup
      .string()
      .strict()
      // .matches(/^\S*$/, "No spaces allowed")
      .max(40, uniValidation.first_name.max)
      .required(uniValidation.first_name.require).test("is-not-only-spaces", uniValidation.first_name.require, (value) => {
        return value.trim() !== "";
      }),
    lastName: yup
      .string()
      .strict()
      // .matches(/^\S*$/, "No spaces allowed")
      .max(40, uniValidation.last_name.max)
      .required(uniValidation.last_name.require).test("is-not-only-spaces", uniValidation.last_name.require, (value) => {
        return value.trim() !== "";
      }),
  });

  const [loader, setLoader] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    //---------This will be call on form submit---------//
    onSubmit: async (values) => {

      if (isChecked === true && !selectedCompanyName) {
        setDropdownValidation("Please select company");
        return;
      }

      setLoader(true);
      values.request_send_by = user_id;
      values.request_send_by_role = role == 2 ? roleName.financial_advisor : roleName.tax_professional;
      // values.request_send_by_role = roleName.tax_professional;
      values.request_send_to_role = roleName.individual;
      values.access_permission = 1;
      values.module_name = "wealth manager individual";
      values.request_send_by_displayName = user_displayName;
      values.request_send_by_email = user_email;
      values.request_type = "Account Access Request";
      values.request_for = 1;


      if (selectedCompanyName) {
        values.is_comp_checked = true;
        values.company_name = selectedCompanyName
      }

      dispatch(requestsAdd(values))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            if ([1, 2, 3, 4].includes(response.message)) {
              setValidateIndividual({
                msg: response.msgCap,
                color: "cs-danger",
                msg_no: response.message,
                row_id: (response.message === 1 || response.message === 3) ? response.row_id : (response.message === 2) ? response.indi_id : ""
              });
              setLoader(false);
            } else {
              socket.emit("notification", "message")
              setIsChecked(false)
              toastMessage("Your invite to collaborate was sent successfully.", "success");
              setLoader(false);
              changeModalState(false, true);
              formik.resetForm();
              socket.emit("notification", "CapSync Notifications");
              getIndividuals()
            }
          } else if (response.code === 500 && response.message) {
            setValidateIndividual({
              msg: response.message,
              color: "cs-danger",
              msg_no: response.message,
            });
            setLoader(false);
          }
        })
        .catch((err) => {
          setIsChecked(false)
          setLoader(false);
          toastMessage("requestsAdd :" + err.message, "error");

        });
    },
  });

  const reSendEmailInvitation = (id) => {
    dispatch(wm_to_ind_resend_email({ id: id }))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          toastMessage(response.message, "success");
        }
      })
      .catch((err) => {
        toastMessage("reSendEmailInvitation :" + err.message, "error");
      });
  };
  const closeAddModal = (close, open) => {
    setValidateIndividual({
      msg: "",
      color: "",
      msg_no: "",
      row_id: "",
    });
    changeModalState(close, open);
    formik.resetForm();
    formik.values.firstName = "";
    formik.values.lastName = "";
    formik.values.email = "";
  };

  const checkImportValue = (params) => {
    setBulkImport(params)
  }


  const removeFile = () => {
    setSelectedFile(null)
  }

  const handleDrop = (acceptedFiles) => {
    setTimeout(() => {
      setFileLoader(true)
    }, 1500)
    if (acceptedFiles.length !== 1) {
      setValidationMessage("Only single CSV upload allowed.");
      setSaveEnabled(false);
      return;
    }
    const file = acceptedFiles[0];

    // Validate the file before setting it as selectedFile
    const isValidFile = validateFile(file);

    if (isValidFile) {
      setSelectedFile(file);
      setSelectedFileName(file.name); // Store the selected file name
      setValidationMessage(null);
      setSaveEnabled(true)
      setFileLoader(false)
    } else {
      setSelectedFile(null);
      setSelectedFileName(""); // Reset the selected file name on invalid file selection
    }



  };

  const validateFile = (file) => {

    const allowedTypes = ["text/csv"];

    if (!allowedTypes.includes(file.type)) {
      setValidationMessage("Format not supported");
      setSaveEnabled(false);
      return false;
    }

    setValidationMessage(null);
    setSaveEnabled(true);
    return true;
  };
  function showStarNumber(params) {
    let newContent = "";
    newContent += params;
    if (newContent.length > 45) {
      return newContent.substring(0, 45) + "...";
    } else {
      return newContent;
    }
  }




  const handleBulkSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    let ReqBody = {}
    ReqBody.request_send_by = user_id;
    ReqBody.request_send_by_role = role == 2 ? roleName.financial_advisor : roleName.tax_professional;
    // ReqBody.request_send_by_role = roleName.tax_professional
    ReqBody.request_send_to_role = roleName.individual;
    ReqBody.access_permission = 1;
    ReqBody.module_name = "wealth manager individual";
    ReqBody.request_send_by_displayName = user_displayName;
    ReqBody.request_send_by_email = user_email;
    ReqBody.request_type = "Account Access Request";
    ReqBody.request_for = 1;

    const formdata = new FormData();
    formdata.append("attachments", selectedFile);
    formdata.append("wm_details", JSON.stringify(ReqBody));
    dispatch(requestBulkAdd(formdata))
      .unwrap()
      .then((response) => {
        setSelectedFile(null)
        if (response.code === 200) {
          if ([1, 2, 3, 4].includes(response.message)) {
            toastMessage("requestBulkAdd: " + response.message, "error")
            setLoader(false);
          }
          else {
            socket.emit("notification", "message")
            setIsChecked(false);
            toastMessage("Your invite to collaborate was sent successfully.", "success");
            setLoader(false);
            changeModalState(false, true);
            socket.emit("notification", "CapSync Notifications");
            getIndividuals()
            setBulkImport(0)
          }
        } else if (response.code === 500 && (response.invalid_record.length > 0 || response.existing_requests.length > 0 || response.invalid_name.length > 0)) {
          let invalid_records = response.invalid_record.length > 0 ? response.invalid_record.join(", ") : null;
          let existing_requests = response.existing_requests.length > 0 ? response.existing_requests.join(", ") : null;
          let invalid_name = response.invalid_name.length > 0 ? response.invalid_name.join(", ") : null;
          if (invalid_records) {
            toastMessage("This emails are invalid: " + invalid_records, "error");
          }
          if (existing_requests) {
            toastMessage("This emails are already exist: " + existing_requests, "error");
          }
          if (invalid_name) {
            toastMessage("First name or last name missing in this email: " + invalid_name, "error");
          }
          socket.emit("notification", "message")
          setIsChecked(false);
          setLoader(false);
          changeModalState(false, true);
          socket.emit("notification", "CapSync Notifications");
          getIndividuals()
          setBulkImport(0)
        } else {
          setIsChecked(false);
          setLoader(false);
          toastMessage(response.message, "error")
        }
      }).catch((error) => {
        setIsChecked(false)
        setLoader(false);
        toastMessage("requestBulkAdd: " + error.message, "error");
      })
  }

  const handleCancel = (e) => {
    closeAddModal(false, true)
    setBulkImport(0)
    setIsChecked(false)
    setValidationMessage(null)
    setSelectedFile(null)
  }

  return (
    <>
      <Modal
        show={modalState}
        onHide={!modalState}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
      >
        <Form onSubmit={formik.handleSubmit}>
          <Modal.Header>
            <Modal.Title className="default-semi-bold-h3">
              Add Individual
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="financial-initial-modal">
              <Row>
                <Col lg={12}>
                  <Form.Group className="cs-form-group">
                    <div className="d-flex">
                      <div className="cs-radio-btn" onClick={() => { checkImportValue(0) }}>
                        <input
                          className="radio-button"
                          type="radio"
                          label="1"
                          name="import"
                          checked={bulkImport === 0}
                        />
                        <span className="default-regular-body-text-m cs-neutral-90" onClick={() => { checkImportValue(0) }}>Single entry</span>
                      </div>
                      <div className="cs-radio-btn" onClick={() => { checkImportValue(1) }}>
                        <input
                          className="radio-button"
                          type="radio"
                          label="2"
                          name="import"
                          checked={bulkImport === 1}
                        />
                        <span className="default-regular-body-text-m cs-neutral-90" onClick={() => { checkImportValue(1) }}>Bulk import</span>
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              {bulkImport == 0 ? (
                <Fragment>
                  <Row>
                    <Col xs={6} md={6}>
                      <Form.Group className="cs-form-group">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          autoComplete="off"
                          type="text"
                          name="firstName"
                          onKeyDown={(e) => {
                            if (e.key === " " && e.target.selectionStart === 0) {
                              e.preventDefault();
                            }
                          }}

                          defaultValue={formik.values.firstName}
                          className={
                            formik.values.firstName && "cs-input-field-active"
                          }
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          maxLength={40}
                        />
                        {formik.errors.firstName && formik.touched.firstName ? (
                          <span className="form-error-msg default-light-body-text-s cs-danger">
                            {formik.errors.firstName}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={6} md={6}>
                      <Form.Group className="cs-form-group">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          autoComplete="off"
                          type="text"
                          name="lastName"
                          onKeyDown={(e) => {
                            if (e.key === " " && e.target.selectionStart === 0) {
                              e.preventDefault();
                            }
                          }}

                          defaultValue={formik.values.lastName}
                          className={
                            formik.values.lastName && "cs-input-field-active"
                          }
                          onChange={formik.handleChange}
                          // onBlur={formik.handleBlur}
                          maxLength={40}
                        />
                        {formik.errors.lastName && formik.touched.lastName ? (
                          <span className="form-error-msg default-light-body-text-s cs-danger">
                            {formik.errors.lastName}
                          </span>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={12}>
                      <Form.Group className="cs-form-group last-email-field">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          autoComplete="off"
                          type="email"
                          name="email"
                          onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                          defaultValue={formik.values.email}
                          className={formik.values.email && "cs-input-field-active"}
                          onChange={(e) => {
                            setValidateIndividual({ msg: "", color: "", msg_no: "", row_id: "", })
                            formik.handleChange(e);
                          }}
                        // onBlur={formik.handleBlur}
                        />
                        {formik.errors.email && formik.touched.email ? (
                          <span className="form-error-msg default-light-body-text-s cs-danger">
                            {formik.errors.email}
                          </span>
                        ) : validateIndividual.msg ? (<span
                          className={
                            validateIndividual.color &&
                            "form-error-msg default-light-body-text-s cs-danger"
                          }
                        >
                          {validateIndividual.msg}
                        </span>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <AssociateCompany
                    setSelectedCompanyName={setSelectedCompanyName}
                    setDropdownValidation={setDropdownValidation}
                    dropdownValidation={dropdownValidation}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                  />
                </Fragment>
              ) : (
                <Fragment>
                  <div className="financial-initial-bulk-upload">
                    <span className="default-regular-sub-heading-xs cs-neutral-80">
                      You can upload file with columns like <b className="cs-neutral-100">First name</b>, <b className="cs-neutral-100">Last name</b> and <b className="cs-neutral-100">Email</b> or
                      download <Link to={SampleFile} download="Sample-CSV-File.csv" target="_blank" rel="noreferrer">sample file</Link> here
                    </span>
                  </div>
                  <Form.Label className="default-regular-sub-heading-xs cs-neutral-100">Upload Files</Form.Label>
                  <Dropzone onDrop={handleDrop} accept="text/csv">
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className="cs-upload-files">
                        <input {...getInputProps()} name="attachments" />
                        <span className="cs-primary">
                          <CapsyncIcon title="upload-files-filled" size="28" />
                        </span>
                        <p className="default-regular-sub-heading-s cs-neutral-90">
                          <span>Browse</span>&nbsp;or drag file here
                        </p>
                        <p className="default-regular-sub-heading-xxs">
                          Support CSV files
                        </p>
                      </div>
                    )}

                  </Dropzone>
                  <Fragment>
                    <div className="cs-files">
                      <div className="cs-scroller">
                        {selectedFile && (<Fragment>
                          <div className="cs-selected-files">
                            <div className="cs-fileicon cs-neutral-60">
                              <CapsyncToolTip
                                Child={"file-icon-outlined"}
                                placement={"top"}
                                type="icon"
                                message={showStarNumber(selectedFile.path)}
                              />
                            </div>
                            <div className="cs-files-names">
                              <span className="download-filename default-regular-sub-heading-xs cs-neutral-100">
                                {showStarNumber(selectedFile.path)}
                              </span>
                              <span className="download-filename default-light-body-text-s cs-last-date cs-neutral-80">
                                {selectedFile.size / 1000} Kb
                              </span>
                            </div>
                            {fileLoader === false ? (
                              <span className="cs-common-spinner cs-primary">
                                <CapsyncIcon title="loading-outlined" size="22" />
                              </span>
                            ) : (
                              <span
                                className="cs-icon-badge-danger cs-danger cursor-pointer"
                                onClick={() => removeFile()}
                              >
                                <CapsyncIcon title="delete-outlined" size="18" />
                              </span>
                            )}


                          </div>
                        </Fragment>
                        )}
                        {validationMessage && (
                          <span className="default-light-body-text-s cs-danger">
                            {validationMessage}
                          </span>
                        )}

                      </div>
                    </div>
                  </Fragment>
                </Fragment>
              )
              }
            </div>

            {/* <div>
              {validateIndividual.msg_no === 3 ? (
                <p
                  onClick={() =>
                    reSendEmailInvitation(validateIndividual.row_id)
                  }
                >
                  Click here to Re-send
                </p>
              ) : (
                ""
              )}
            </div> */}
          </Modal.Body>
          <Modal.Footer>
            <div className="cs-modal-btn">
              <Button
                onClick={handleCancel}
                className="cs-btn-secondary md-btn default-regular-h5"
              >
                Cancel
              </Button>
              {
                bulkImport == 0 &&
                <Button
                  type="submit"
                  disabled={loader}
                  className="cs-btn-primary md-btn default-regular-h5"
                >
                  Send Invitation
                </Button>
              }
              {bulkImport != 0 &&
                <Button
                  disabled={!isSaveEnabled || loader || !selectedFile}
                  onClick={handleBulkSubmit}
                  className="cs-btn-primary md-btn default-regular-h5"
                >
                  Submit
                </Button>
              }

            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddModal;
