import React, { useState } from 'react';
import { Form } from "react-bootstrap";

const NotesBlock = (props) => {
  const [value, setValue] = useState("")
  const handleChange = (e) => {
    setValue(e.target.value)
    props.setNotesValue(e.target.value)
  }
  return (
    <>
      <div className="row-spacing">
        <span className="default-regular-body-text-l cs-neutral-90"> Type or edit a note, comment, or response to the reviewer here (Optional):
        </span>

        <p><i className="default-regular-body-text-l cs-neutral-70">
          Kindly note that for audit/compliance reasons, all communication between yourself and your reviewing attorney can only be done by entering any questions or comments in the box below. Note that this is optional.
        </i></p>
        <Form.Group className="cs-form-group">
          <textarea
            value={value}
            autoComplete="off"
            type="text"
            placeholder="Enter your Note"
            className={
              value
                ? "cs-input-field-active cs-textarea"
                : "cs-textarea"
            }
            onChange={handleChange}
          ></textarea>
        </Form.Group>
      </div>

      <div className="cs-divider"></div>

      <p className="default-medium-sub-heading-m  cs-danger">
        Kindly note the verification letters are generally good for 90 days per the SEC rules.
      </p>
    </>
  )
}

export default NotesBlock