import React from "react";
import { Button } from "react-bootstrap";

const TrialExpireComponent = ({ data, changeExpireState, daysLeft }) => {
  return (
    <>
      {data !== undefined && data.is_auto_subscription === false && daysLeft < 6 && (
        <div className="upgrade-navbar">
          <span className="default-regular-sub-heading-xs">
            Trial expires in&nbsp;
            {`${daysLeft} ${daysLeft === 1 ? 'day' : 'days'}`}
          </span>
          <Button
            className="cs-btn-primary default-regular-h6"
            onClick={() => changeExpireState(true)}
          >
            Upgrade
          </Button>
        </div>
      )}
    </>
  );
};

export default TrialExpireComponent;
