import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { headerAddToken } from "../util/common";
import API from "../util/api";
import {
  localDataRemove,
  assetsCategory,
  liabilitiesCategory,
} from "../config/finance";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export const categoryAdd = createAsyncThunk(
  "category/add",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const { formdata, category, main_category, } = values;
      const response = await API.post(
        `/${main_category}/${category}/add`,
        formdata,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const categoryGetAll = createAsyncThunk(
  "category/all/",
  async (values, thunkAPI) => {
    try {
      const { id, category, main_category } = values;
      let header = headerAddToken();
      const response = await API.get(
        `/${main_category}/${category}/all/${id}`,
        header
      );
      const ins_ids = [];
      for (let i = 0; i < response.data.entries.length; i++) {
        if (response.data.entries[i].institution_id) {
          ins_ids.push(response.data.entries[i].institution_id);
        }
      }
      if (ins_ids.length) {
        const insIds = await getTransactionsLoaderData(id, ins_ids);

        if (insIds.length) {
          const newCategoriesData = response.data.entries.map((data) => {
            return {
              ...data,
              is_transaction_loader: insIds.includes(data.institution_id),
            };
          });
          thunkAPI.dispatch(setMessage(response.data.data));
          return {
            data: {
              ...response.data,
              entries: newCategoriesData,
            },
            category,
          };
        }
      }
      thunkAPI.dispatch(setMessage(response.data.data));
      return { data: response.data, category };
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

const getTransactionsLoaderData = async (user_id, ins_id) => {
  const loaderResponse = await API.post(`/transactions/init-loader/get`, {
    user_id,
    ins_id,
  });
  if (loaderResponse.data.length) {
    const insIds = loaderResponse.data.map((data) => data.institution_id);
    return insIds;
  }
  return [];
};

export const getAllTransactionsLoaderData = createAsyncThunk(
  "ptransactions/loader/",
  async (body, thunkAPI) => {
    try {
      const { user_id, ins_ids, category } = body;

      const insIds = await getTransactionsLoaderData(user_id, ins_ids);
      // thunkAPI.dispatch(setMessage(response.data.data));
      return { data: insIds, category };
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const getCategoryUnlinkAll = createAsyncThunk(
  "category/unlink/all/",
  async (values, thunkAPI) => {
    try {
      const { id, category, main_category, end_point } = values;
      let header = headerAddToken();
      const response = await API.get(
        `/${main_category}/${category}/${end_point}/${id}`,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return { data: response.data, category };
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const categoryLinkRecord = createAsyncThunk(
  "category/link",
  async (linkObj, thunkAPI) => {
    try {
      const { main_category, category, id, linkId, idType, linkIdType } =
        linkObj;
      let header = headerAddToken();

      const response = await API.post(
        `/${main_category}/${category}/update_address`,
        {
          [idType]: id,
          [linkIdType]: linkId,
        },
        header
      );
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const categoryDeleteRecord = createAsyncThunk(
  "category/delete/",
  async (payload, thunkAPI) => {
    try {
      let header = headerAddToken();
      const { id, category, main_category } = payload;
      const response = await API.delete(
        `/${main_category}/${category}/delete/${id}`,
        // payload,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const categoryUnlinkRecord = createAsyncThunk(
  "category/unlink/",
  async (payload, thunkAPI) => {
    try {
      let header = headerAddToken();
      const { id, category, main_category } = payload;
      const response = await API.delete(
        `/${main_category}/${category}/delete_address/${id}`,
        // payload,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const categoryFindRecord = createAsyncThunk(
  "category/find/",
  async (values, thunkAPI) => {
    try {
      const { id, category, main_category } = values;
      let header = headerAddToken();
      const response = await API.get(
        `/${main_category}/${category}/find/${id}`,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const categoryUpdateRecord = createAsyncThunk(
  "category/update",
  async (updateObj, thunkAPI) => {
    try {
      const { category, main_category, newData } = updateObj;
      let header = headerAddToken();

      const response = await API.post(
        `/${main_category}/${category}/update`,
        newData,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const categoryFileListing = createAsyncThunk(
  "category/file/update",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const { category, main_category, formdata } = values;
      const response = await API.post(
        `/${main_category}/${category}/file/listing`,
        formdata,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const categoryFilesUpdate = createAsyncThunk(
  "category/file/update",
  async (values, thunkAPI) => {
    try {
      let header = headerAddToken();
      const { formdata, category, main_category } = values;
      const response = await API.post(
        `/${main_category}/${category}/file/update`,
        formdata,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const categoryFilesRemove = createAsyncThunk(
  "category/file/remove",
  async (values, thunkAPI) => {
    try {
      const { category, main_category } = values;
      let header = headerAddToken();
      const response = await API.post(
        `/${main_category}/${category}/file/remove`,
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      const message = error.response && error.response.data.message;
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const notespayable_loan_details = createAsyncThunk(
  "liability/notespayable/loan/find",
  async ({ id }, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.get(
        `/liabilities/notespayabletobanksandothers/loan/find/${id}`,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const realEstateMortgageUpdateAddress = createAsyncThunk(
  "/liability/mortgages/updateaddress",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        `/liabilities/mortgages/updateaddress`,
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const realEstateMortgageDeleteAddress = createAsyncThunk(
  "/liability/mortgages/deleteaddress/",
  async (id, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.get(
        `/liabilities/mortgages/deleteaddress/${id}`,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const notesrevievable_loan_details = createAsyncThunk(
  "assets/notesreceivable/loan/find",
  async ({ id }, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.get(
        `assets/accountnotesreceviable/loan/find/${id}`,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const categoryTotal = createAsyncThunk(
  "/category/categorytotal/",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const { id, main_category } = values;
      const response = await API.get(
        `/${main_category}/categorytotal/${id}`,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const categoryTotalNew = createAsyncThunk(
  "/dashboard/categorytotal",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const { id } = values;
      const response = await API.get(`/dashboard/categorytotal/${id}`, header);
      return response.data;
    } catch (error) { }
  }
);

export const autoMobileGetAllExternalManual = createAsyncThunk(
  "/assets/automobile/external_manual/",
  async ({ id }, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.get(
        `/assets/automobile/external_manual/${id}`,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const categoryUnlink = createAsyncThunk(
  "/assets/automobile/external_manual/",
  async (values, thunkAPI) => {
    try {
      const { category, main_category, id, api } = values;
      let header = headerAddToken();
      const response = await API.delete(
        `/${main_category}/${category}/${api}/${id}`,
        // values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const categoryLink = createAsyncThunk(
  "/assets/automobile/external_manual/",
  async (values, thunkAPI) => {
    try {
      const { category, main_category, newData, api } = values;
      let header = headerAddToken();
      const response = await API.post(
        `/${main_category}/${category}/${api}`,
        newData,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const plaidStocksData = createAsyncThunk(
  "/assets/automobile/external_manual/",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        `/plaid/holdings/stocks/data`,
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const show_categories = createAsyncThunk(
  "/master_table/all",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post("/master_table/all", values, header);
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const fetchRealestatePropertyPrice = createAsyncThunk(
  "/asset_real_estate_property_price_fetch",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        "/assets/realestate/asset_real_estate_property_price_fetch",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

export const fetchAutomobileMarketValue = createAsyncThunk(
  "/asset_automobile_fetch_market_value",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        "/assets/automobile/asset_automobile_fetch_market_value",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);


export const fetchMakeData = createAsyncThunk(
  "/fetchMakeData",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        "/assets/automobile/asset_automobile_fetch_make",
        values,
        header
      );
      return response.data;
    } catch (error) {
     console.log(error)
    }
  }
);
export const fetchModelData = createAsyncThunk(
  "/fetchModelData",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        "/assets/automobile/asset_automobile_fetch_model",
        values,
        header
      );
      return response.data;
    } catch (error) {
     console.log(error)
    }
  }
);



export const fetchCategoryDataPFSTable = createAsyncThunk(
  "/fetch_assets_category_pfs",
  async (values, thunkAPI) => {
    const { user_id, category } = values;
    try {
      let header = await headerAddToken();
      const response = await API.post(
        `/${category}/category_dashboard`,
        { user_id },
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return { category, data: response.data };
    } catch (error) {
      if (error.response.data.code === 401) {
        // window.location.href = "/";
        // localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);

// export const fetchLiabilitiesCategoryDataPFSTable = createAsyncThunk(
//   "/fetch_liabilities_category_pfs",
//   async (values, thunkAPI) => {
//     try {
//       let header = await headerAddToken();
//       const response = await API.post("/liabilities/category_dashboard", values, header);
//       console.log(response);
//       thunkAPI.dispatch(setMessage(response.data.data));
//       return response.data;
//     } catch (error) {
//       if (error.response.data.code === 401) {
//         // window.location.href = "/";
//         // localDataRemove();
//       } else {
//         const message = error.response && error.response.data.message;
//         thunkAPI.dispatch(setMessage(message));
//         return thunkAPI.rejectWithValue();
//       }
//     }
//   }
// );

const initialState = {
  categoryListing: {
    assets: [],
    liabilities: [],
  },
  categoryData: {},
  categoryDashboardListing: {},
  categoryDataPFS: {
    assetsListArr: [],
    liabilitiesListArr: [],
    assetsTotalFields: {
      totalBalance: 0,
      totalDue: 0,
      totalShareValue: 0,
      totalShareMarketValue: 0,
      totalMarketValue: 0,
      totalCashValue: 0,
      total: 0,
    },
  },
  emptyCategory: false,
  newCategoryData: {},
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(categoryTotalNew.fulfilled, (state, action) => {
      state.newCategoryData = action.payload.data;
    });
    builder.addCase(categoryGetAll.fulfilled, (state, action) => {
      state.categoryData = {
        categoryName: action.payload.category,
        data: action.payload.data.entries,
      };
      state.categoryDashboardListing = {
        ...state.categoryDashboardListing,
        [action.payload.category]: {
          data: action.payload.data.entries,
          chart_data: action.payload.data.chart_data,
        },
      };
    });
    builder.addCase(getAllTransactionsLoaderData.fulfilled, (state, action) => {
      state.categoryData = {
        ...state.categoryData,
        data: state.categoryData.data.map((data) => {
          return {
            ...data,
            is_transaction_loader: action.payload.data.includes(
              data.institution_id
            ),
          };
        }),
      };
      state.categoryDashboardListing = {
        ...state.categoryDashboardListing,
        [action.payload.category]: {
          ...state.categoryDashboardListing[action.payload.category],
          data: state.categoryData.data.map((data) => {
            return {
              ...data,
              is_transaction_loader: action.payload.data.includes(
                data.institution_id
              ),
            };
          }),
        },
      };
    });
    builder.addCase(categoryDeleteRecord.fulfilled, (state, action) => {
      state.emptyCategory = !current(state).emptyCategory;
    });
    builder.addCase(show_categories.fulfilled, (state, action) => {
      // #### ALL ASSETS CATEGORY
      const assetsCategoryList = assetsCategory;
      assetsCategoryList.map((asset, index) => {
        const resultAssetsData = action.payload.assets.filter(
          (assetData, index) => assetData.sub_category === asset.title
        )[0];
        asset.id = resultAssetsData.id;
        asset.sub_category = resultAssetsData.sub_category;
        asset.order = resultAssetsData.order_id;
      });
      //in ascending order for Assets
      assetsCategoryList.sort((a, b) => (a.order > b.order ? 1 : -1));

      // #### ALL LIABILITIES CATEGORY
      const liabilitiesCategoryList = liabilitiesCategory;
      liabilitiesCategoryList.map((liabilities, index) => {
        const resultAssetsData = action.payload.liabilities.filter(
          (liabilitiesData, index) =>
            liabilitiesData.sub_category === liabilities.title
        )[0];
        liabilities.id = resultAssetsData.id;
        liabilities.sub_category = resultAssetsData.sub_category;
        liabilities.order = resultAssetsData.order_id;
      });
      //in Ascending order for liabilities
      liabilitiesCategoryList.sort((a, b) => (a.order > b.order ? 1 : -1));

      action.payload.assets = assetsCategoryList;
      action.payload.liabilities = liabilitiesCategoryList;
      state.categoryListing = action.payload;
    });
    builder.addCase(fetchCategoryDataPFSTable.fulfilled, (state, action) => {
      const assetsList = current(state).categoryListing.assets;
      const liabilitiesList = current(state).categoryListing.liabilities;
      const ctaegoryDataObj = action.payload.data;
      const displayDataArr = [];
      if (action.payload.category === "assets") {
        //Formating data as per the keys for display in Share PFS modal(ASSETS)
        assetsList.map((category) => {
          displayDataArr.push({
            categoryType: category.url,
            categoryTitle: category.title,
            data: ctaegoryDataObj[category.url],
          });
        });
        state.categoryDataPFS = {
          ...state.categoryDataPFS,
          assetsListArr: displayDataArr,
        };
      } else {
        //Formating data as per the keys for display in Share PFS modal(LIABILITIES)
        liabilitiesList.map((category) => {
          displayDataArr.push({
            categoryType: category.url,
            categoryTitle: category.title,
            data: ctaegoryDataObj[category.url],
          });
        });
        state.categoryDataPFS = {
          ...state.categoryDataPFS,
          liabilitiesListArr: displayDataArr,
        };
      }
    });
  },
});

const { reducer } = categorySlice;
export default reducer;

const selectTotalCategories = (state) => state.category.newCategoryData
export const useTotalCategories = () => {
  const newCategoryData = useSelector(selectTotalCategories)
  return useMemo(() => newCategoryData, [newCategoryData])
}
