import React from "react";
import { Link } from "react-router-dom";
import CapsyncIcon from "../../CapsyncIcon";

const AmortBreadCrumb = ({ urlCheck, allData }) => {
  return (
    <>
      <div className="cs-breadcrumb">
        <Link
          to={
            window.location.pathname.includes("liabilities")
              ? "/individual/liabilities"
              : "/individual/assets"
          }
        >
          <span className="default-light-body-text-s cs-neutral-80">
            {window.location.pathname.includes("assets")
              ? "Assets"
              : "Liabilities"}
          </span>
        </Link>
        <span className="cs-neutral-70">
          <CapsyncIcon title="chevron-right-outlined" size="12" />
        </span>
        <Link
          to={
            urlCheck.filter((x) => window.location.pathname.includes(x.category))[0]
              .url
          }
        >
          <span className="default-light-body-text-s cs-neutral-80">
            {
              urlCheck.filter((x) =>
                window.location.pathname.includes(x.category)
              )[0].title
            }
          </span>
        </Link>
        <span className="cs-neutral-70">
          <CapsyncIcon title="chevron-right-outlined" size="12" />
        </span>
        <span className="default-light-body-text-s cs-neutral-100">{allData.creditor_name ? allData.creditor_name : allData.property_name}</span>

      </div>
    </>
  );
};

export default AmortBreadCrumb;
