import axios from "axios";
import { saveAs } from "file-saver";
import { Buffer } from 'buffer';

import { PDFDocument } from "pdf-lib";
const handleDownload = async (values) => {
  if (values) {
    const username = process.env.REACT_APP_HELLO_SIGN_USER_NAME;
    const password = process.env.REACT_APP_HELLO_SIGN_PASSWORD;
    const token = Buffer.from(`${username}:${password}`, "utf8").toString(
      "base64"
    );

    const headers = { Authorization: `Basic ${token}` };

    const response = await axios.get(values, {
      headers,
      responseType: "arraybuffer",
    });
    const fileData = response.data;
    const pdfDoc = await PDFDocument.load(fileData);
    const modifiedPdfData = await pdfDoc.save();
    // Create a Blob object from the modified PDF data
    const blob = new Blob([modifiedPdfData], { type: "application/pdf" });

    // Use FileSaver.js to save the blob as a file
    saveAs(blob, "downloaded_file.pdf");
  }
};
export default handleDownload;
