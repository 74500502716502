import React, { useState } from "react";
import { Form, Button, Modal, Row, Col, ModalFooter } from "react-bootstrap";
import "../../../../app/pages/individual/sharePfsModals/SharePfsModal.css";

const SharePFSModal = (props) => {
  const { showSharePFS, setShowSharePFS, onSubmitSharePFSForm } = props;
  const [inputField, setInputField] = useState({});
  const [emailError, setEmailError] = useState('');
  // let id;
  // if (window.localStorage.getItem("pfs_access_id") !== null) {
  //   id = window.localStorage.getItem("pfs_access_id");
  // } else {
  // }
  const handleChange = (event) => {
    setInputField({
      ...inputField,
      [event.target.name]: event.target.value
    })
  }

  const handleReviewAndSend = (e) => {
    e.preventDefault();
    if ((Object.keys(inputField).length === 0 && inputField.constructor === Object) || inputField.email === "") {
      setEmailError('Enter a valid email address');
      return false;
    } else {
      setEmailError('');
      onSubmitSharePFSForm(inputField);
    }
  }

  return (
    <>
      <Modal
        show={showSharePFS}
        onHide={!showSharePFS}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">Share PFS </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label className="">First name</Form.Label>
                <Form.Control
                  className={inputField.firstName && "cs-input-field-active"}
                  name="firstName"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  required
                  type="text"
                  autoComplete="off"
                  onChange={handleChange}
                  maxLength={40}
                // onChange={(e) => props.handleNameChange(e.target.value)}
                // onBlur={(e) => props.handleNameBlur(e.target.value)}
                />
                {/* {props.emailError ? (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {props.changeNameError}
                  </span>
                ) : null} */}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="cs-form-group">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  className={inputField.lastName && "cs-input-field-active"}
                  name="lastName"
                  onKeyDown={(e) => {
                    if (e.key === " " && e.target.selectionStart === 0) {
                      e.preventDefault();
                    }
                  }}
                  type="text"
                  autoComplete="off"
                  onChange={handleChange}
                  maxLength={40}
                // onChange={handleChange}
                // onChange={(e) => props.handleLastNameChange(e.target.value)}
                />

              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  // className={props.toUserEmail && "cs-input-field-active"}
                  className={inputField.email && "cs-input-field-active"}
                  name="email"
                  onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                  type="email"
                  autoComplete="off"
                  onChange={handleChange}
                // onChange={(e) => props.setToUserEmail(e.target.value)}
                // onChange={(e) => props.handleEmailChange(e.target.value)}
                />

                {emailError !== "" && (
                  <span className="form-error-msg default-light-body-text-s cs-danger">
                    {emailError}
                  </span>)}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form.Group className="cs-form-group">
                <Form.Label>Notes</Form.Label>
                <textarea
                  className={`${inputField.notes ? 'cs-input-field-active' : ''} cs-textarea`}
                  name="notes"
                  type="text"
                  autoComplete="off"
                  placeholder="Optional"
                  onChange={handleChange}
                // onChange={(e) => props.handleNotesChange(e.target.value)}
                // className="cs-textarea"
                />
              </Form.Group>
            </Col>
          </Row>
          {/* {id ? (
            <div className="signature-return-box">
              <Form.Check
                type="checkbox"
                checked={props.isCheckedWealth}
                onChange={props.handleCheckboxChange}
                className="cs-form-check-box"
                label={
                  <span className="default-regular-body-text-m cs-neutral-90" onClick={() => props.isCheckedWealth}>
                    Return to Me
                  </span>
                }
              />
            </div>
          ) : (
            ""
          )} */}
        </Modal.Body>
        <ModalFooter>
          <div className="cs-modal-btn">
            <Button
              onClick={() => setShowSharePFS(false)}
              // onClick={() => props.changeFirstModel(false)}
              className="cs-btn-secondary md-btn default-regular-h5"
            >
              Cancel
            </Button>
            <Button
              type="submit"
              // onClick={() => props.checkFirstEmailValidation(props.toUserEmail)}
              onClick={handleReviewAndSend}
              className="cs-btn-primary md-btn default-regular-h5"
            >
              Review & Send
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default SharePFSModal;
