import API from "../util/api";
import { headerAddToken } from "../util/common";

const add_subscription = async (values) => {
  let header = await headerAddToken();
  return API.post("/usersubscription/add-user-subscription", values, header);
};

const create_payment_intent = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/usersubscription/create-user-payment-intent",
    values,
    header
  );
};

const get_billing_and_subscription_by_user = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/usersubscription/get-billing-and-subscription",
    values,
    header
  );
};

const get_user_active_payment_method = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/usersubscription/get_user_active_payment_method",
    values,
    header
  );
};

const remove_user_subscription_method = async (id) => {
  let header = await headerAddToken();
  return API.delete(
    "/usersubscription/delete-user-payment-method/" + id,
    header
  );
};

const update_user_payment_method = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/usersubscription/update-user-payment-method/",
    values,
    header
  );
};

const update_user_default_payment_method = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/usersubscription/update-user-default-payment-method/",
    values,
    header
  );
};

const cancel_user_subscription = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/usersubscription/cancel-subscription",
    values,
    header
  );
};
const subscription_plan_cancel_reason = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/usersubscription/subscription_plan_cancel_reason",
    values,
    header
  );
};

const trasfer_billing = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/usersubscription/transfer-billing",
    values,
    header
  );
};

const add_user_payment = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/usersubscription/add-user-payment-manually",
    values,
    header
  );
};

const update_user_subscription_plan = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/usersubscription/update-user-subscription-plan",
    values,
    header
  );
}

const add_payment_billing = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/usersubscription/payment-billing-add",
    values,
    header
  );
}

const purchase_user_storage_subscription = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/usersubscription/purchase-user-storage-subscription",
    values,
    header
  );
}

const add_default_payment_billing = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/usersubscription/payment-default-billing-add",
    values,
    header
  );
}

const change_active_payment_method = async (values) => {
  let header = await headerAddToken();
  return API.post(
    "/usersubscription/change-active-payment-method",
    values,
    header
  );
}

const userSubscriptions = {
  add_subscription,
  create_payment_intent,
  get_billing_and_subscription_by_user,
  get_user_active_payment_method,
  remove_user_subscription_method,
  update_user_payment_method,
  update_user_default_payment_method,
  cancel_user_subscription,
  trasfer_billing,
  add_user_payment,
  update_user_subscription_plan,
  add_payment_billing,
  purchase_user_storage_subscription,
  change_active_payment_method,
  add_default_payment_billing,
  subscription_plan_cancel_reason
};

export default userSubscriptions;
