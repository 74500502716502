import React, { useState } from "react";
import { Form } from "react-bootstrap";
import SubMenuHeader from "../SubMenuHeader";
import { combine } from "../../../../../../util/common";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import SubMethodsList from "../../SubMethodsList";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";

// sequence No. one  (Asmita date-29th June => Do not remove this line)
const MethodIdEleven = (props) => {
  const [newDollar, setDollar] = useState(false);

  let common;

  const check = (para, a) => {
    common = combine(para, a);
    if (common.value === "dollar") {
      setDollar(common.status);
    }
  };

  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Accredited Investor",
      url: `/individual/verifyinvestor/accreditedInvestor/${props.verificationType}/${props.unique_code}`,
    },
  ];

  return (
    <>
      {props.is_loader.assets === true ||
      props.is_loader.liabilities === true ? (
        <SkeletonAllSubMethods />
      ) : (
        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="3rd Party Letter"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>
              <SubMenuHeader />
            </div>
            <div className="method-section">
              <div className="default-regular-body-text-l cs-neutral-70">
                <SubMethodsList methodId={13} />
              </div>

              <div className="cs-divider"></div>

              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert}
                />

                <div className="row-spacing">
                  <p className="default-regular-body-text-l cs-neutral-70">
                    In order to verify your accreditation we need you to upload
                    evidence. Please upload the appropriate files below and
                    black-out any sensitive information.
                  </p>
                  <p className="default-regular-body-text-l cs-danger">
                    Upon a successful verification, the expiration date of the
                    verification letter will be based on the evidence provided
                    to reviewers, e.g. if a 3rd party letter or a monthly
                    statement is 2 months old at the time of submission for
                    review the verification letter will expire in 1 month.
                  </p>
                </div>
                <div className="default-regular-body-text-l row-spacing">
                  <p className="row-spacing cs-danger">
                    Kindly please note that the 3rd party professional letters
                    must meet the following criteria:
                  </p>
                  <ul className="cs-neutral-90 ">
                    <li className="row-spacing ">
                      Name- The named Investor on the form should be exactly as
                      the name entered in the “Legal Name” being verified box on
                      the verification application. This should generally match
                      the investor name on the subscription agreement (e.g.- the
                      individual investor’s name, name on the Trust document, or
                      the Operating Agreement, etc.).
                    </li>
                    <li className="row-spacing">
                      Date- The date on the letter must be no older than 90
                      days.
                    </li>
                    <li className="row-spacing">
                      Signer Information- The letter cannot be self-certified
                      and must be completed by any of the following 3rd party
                      professionals: a licensed attorney, CPA, registered
                      broker-dealer, or SEC-registered investment adviser. There
                      must be sufficient information to identify the signer. It
                      must be exact enough to allow the reviewer the ability to
                      make a positive identification, based on their name and
                      licensing information provided. Please note that for
                      audit/compliance reasons, If the signer is doing so on
                      behalf of a company that has a CRD, they should include
                      their individual name and the name of the entity they are
                      signing on behalf of. However, if the individual signing
                      the form is doing so on behalf of a company that has a
                      CRD, then they do not need to be registered or licensed.
                    </li>
                    <li className="row-spacing">
                      Language- The letter must contain language affirmatively
                      stating that the investor has been verified as an
                      accredited investor (e.g.- I verify this Investor is an
                      “accredited investor”, as defined in Rule 501 of
                      Regulation D of the Securities Act of 1933.) Additionally,
                      no language that expresses limitation of reviewer use or
                      disclaiming of responsibility of the verification can be
                      made in the letter.
                    </li>
                  </ul>
                </div>
                <div className="row-spacing">
                  <p className="default-regular-body-text-l cs-neutral-90">
                    Upload a signed letter from a{" "}
                    <span className="cs-danger">registered broker-dealer</span>,
                    an&nbsp;
                    <span className="cs-danger">
                      SEC-registered investment adviser
                    </span>
                    , a <span className="cs-danger">licensed attorney</span>, or
                    a&nbsp;
                    <span className="cs-danger">
                      certified public accountant
                    </span>{" "}
                    showing that they have already verified you as an accredited
                    investor within the last three months:
                  </p>

                  <p className="default-regular-body-text-l cs-danger ">
                    IF YOUR LETTER IS NOT COMPLETED BY ANY OF THE NOTED
                    PROFESSIONALS, PLEASE USE THE NET WORTH VERIFICATION METHOD
                  </p>
                </div>
                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  <span className="cs-neutral-90">Sample Letter</span>
                  <Link
                    to={
                      "https://s3.amazonaws.com/static-capsync.com/Verify-Investor-files/Sample-3rd-Party-Verification-Letter.pdf"
                    }
                    target="_blank"
                  >
                    {" "}
                    <u className="cs-neutral-100">Here</u>
                  </Link>
                </Form.Label>
                <UploadFileBlock
                  files={props.files}
                  setOpenModal={props.setOpenModal}
                  showFile={props.showFile}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink}
                  uploadError={props.uploadError}
                  fetchedMedia={props.fetchedMedia}
                  unique_code={props.unique_code}
                />
                <NotesBlock setNotesValue={props.setNotesValue} />
              </Form>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default MethodIdEleven;
