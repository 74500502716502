/* eslint-disable */
import { useEffect, useState } from "react";
import { vi_findUser_by_UniqueID } from "../slices/verifyInvestor";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

const vi_fetchUser_byUnique = () => {
  const [storeResponse, setStoreResponse] = useState("");
  const dispatch = useDispatch();
  const VI_location = useLocation();
  let store = VI_location.pathname.split("/");

  useEffect(() => {
    dispatch(vi_findUser_by_UniqueID({ unique_rowID: store[5] }))
      .unwrap()
      .then((response) => {
        setStoreResponse(response.allData.pending[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return { storeResponse };
};

export default vi_fetchUser_byUnique;
