import { createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { localDataRemove } from "../config/finance";
import { headerAddToken } from "../util/common";
import API from "../util/api";

export const externalAttachmentSharePfs = createAsyncThunk(
  "/share_pfs/upload/attachments",
  async (values, thunkAPI) => {
    try {
      let header = await headerAddToken();
      const response = await API.post(
        "/share_pfs/upload/attachments",
        values,
        header
      );
      thunkAPI.dispatch(setMessage(response.data.data));
      return response.data;
    } catch (error) {
      if (error.response.data.code === 401) {
        window.location.href = "/";
        localDataRemove();
      } else {
        const message = error.response && error.response.data.message;
        thunkAPI.dispatch(setMessage(message));
        return thunkAPI.rejectWithValue();
      }
    }
  }
);