import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Table, Button, Dropdown } from "react-bootstrap";
import CommonViHeader from "./CommonViHeader";
import commonNames from "../../../../config/commonNames";
import {
  verify_investor_find,
  download_certificate_from_vi,
} from "../../../../slices/verifyInvestor";
import { DateFormat, datediff_day } from "../../../../util/common";
import UseFetchPermission from "../../../../customHooks/UseFetchPermission";
import PermissionPrevent from "../../../PermissionPrevent";
// import CapsyncLoader from "../../../../commonComponent/CapsyncLoader";
import CapsyncToolTip from "../../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import SkeletonAccreditation from "./SkeletonAccreditation";

const VI_Completed = (props) => {
  const [storeComplete, setStoreComplete] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const dispatch = useDispatch();
  const { fetchUserPermission } = UseFetchPermission();
  const { user } = useSelector((state) => state.auth);

  let id;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const verify_investor_completedData = () => {
    if (id) {
      setIsLoader(true);
      dispatch(verify_investor_find({ user_id: id }))
        .unwrap()
        .then((response) => {
          setStoreComplete(
            response &&
              response.data.filter(
                (obj) => obj.status === 2 || obj.status === 3
              )
          );
          setIsLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoader(false);
        });
    }
  };

  useEffect(() => {
    verify_investor_completedData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const downloadCertificate = (vi_id) => {
    if (vi_id) {
      setIsLoader(true);
      dispatch(
        download_certificate_from_vi({ vi_id: vi_id, status: 2, user_id: id })
      )
        .unwrap()
        .then(async (response) => {
          if (response.url) {
            const a = document.createElement("a");
            a.href = response.url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
          setIsLoader(false);
          props.CapsyncMessage(
            "",
            "Certificate downloaded successfully.",
            "success"
          );
        })
        .catch((err) => {
          props.CapsyncMessage("", err.message, "error");
          setIsLoader(false);
        });
    }
  };

  const [rowStartIdx, setRowStartIdx] = useState(0);
  const paginationDropdownItems = [10, 15, 20, 25, 30];
  const [rowsPerPage, setRowsPerPage] = useState(paginationDropdownItems[0]);
  const [dropdownDisplay, setDropdownDisplay] = useState(rowsPerPage);

  const handlePaginationDropdownClick = (dropdownValue) => {
    setDropdownDisplay(dropdownValue);
    setRowStartIdx(0);
    if (dropdownValue === "All") {
      setRowsPerPage(storeComplete.length);
      return;
    }
    setRowsPerPage(dropdownValue);
  };
  const handleFirstPageClick = () => {
    setRowStartIdx(0);
    // Assuming your rowsPerPage is already set appropriately
  };

  const handleLastPageClick = () => {
    const totalRows = storeComplete.length;
    const lastPageStartIdx =
      Math.floor((totalRows - 1) / rowsPerPage) * rowsPerPage;
    setRowStartIdx(lastPageStartIdx);
  };

  const currentNumberOfRows = (rowStartIdx, rowsPerPage, lengthOfCompanies) => {
    if (rowStartIdx + rowsPerPage <= lengthOfCompanies) {
      return rowsPerPage;
    } else {
      return lengthOfCompanies - rowStartIdx;
    }
  };

  return (
    <>
      <>
        {props.is_loader.assets === true ||
        props.is_loader.liabilities === true ? (
          <SkeletonAccreditation />
        ) : (
          <section className="accreditation-main-section accreditation-completed-section">
            <Container fluid>
              <CommonViHeader storeComplete={storeComplete} />
              {storeComplete === undefined || storeComplete.length === 0 ? (
                <span className="cs-common-no-record">
                  There are no records to display
                </span>
              ) : (
                <Row className="verify-invester-table-style">
                  <Col className="verify-invester-col">
                    <div className="listing-request commonTable">
                      <Table responsive className="cs-table">
                        <thead id="table-head-details" className="cs-thead">
                          <tr>
                            <th
                              className="default-medium-sub-heading-m"
                              width="263"
                            >
                              Legal name
                            </th>
                            <th
                              className="default-medium-sub-heading-m"
                              width="163"
                            >
                              Requested
                            </th>
                            <th
                              className="default-medium-sub-heading-m"
                              width="263"
                            >
                              Type
                            </th>
                            <th
                              className="default-medium-sub-heading-m"
                              width="263"
                            >
                              Status
                            </th>
                            <th
                              className="default-medium-sub-heading-m"
                              width="220"
                            >
                              Reference
                            </th>
                            <th
                              className="default-medium-sub-heading-m"
                              width="189"
                            >
                              Expires
                            </th>
                            <th
                              className="default-medium-sub-heading-m"
                              width="223"
                            >
                              {commonNames.Actions}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="cs-tbody ">
                          {storeComplete &&
                            storeComplete
                              .slice(rowStartIdx, rowStartIdx + rowsPerPage)
                              .map((complete_itr, key) => {
                                let complete_referenceStore = "";
                                if (complete_itr.reference_optional === "") {
                                  complete_referenceStore += "-";
                                } else {
                                  complete_referenceStore +=
                                    complete_itr.reference_optional;
                                }

                                return (
                                  <tr key={key} id={complete_itr.id}>
                                    <td className="default-regular-sub-heading-m">
                                      {complete_itr.legal_name}
                                    </td>
                                    <td className="text-transform-capital default-regular-sub-heading-m">
                                      {DateFormat(complete_itr.createdAt)}
                                    </td>
                                    <td className="default-regular-sub-heading-m">
                                      {complete_itr.verification_type}
                                    </td>
                                    <td className="default-regular-sub-heading-m">
                                      <div className="cs-progress-indicator">
                                        <div className="cs-indicator  cs-success">
                                          <span>
                                            <CapsyncToolTip
                                              Child={"information-outlined"}
                                              placement={"top"}
                                              message={"Information received"}
                                              type="icon"
                                              size="22"
                                            />
                                          </span>
                                          <span className="cs-indicator-line"></span>
                                        </div>
                                        <div className="cs-indicator  cs-success">
                                          <span>
                                            <CapsyncToolTip
                                              Child={"review-outlined"}
                                              placement={"top"}
                                              message={"Review done"}
                                              type="icon"
                                              size="22"
                                            />
                                          </span>
                                          {/* <span className="cs-indicator-line"></span> */}
                                          <span
                                            className={`cs-indicator-line ${
                                              complete_itr.status === 3
                                                ? "cs-danger"
                                                : ""
                                            }`}
                                          ></span>
                                        </div>
                                        {complete_itr.status === 3 ? (
                                          <div className="cs-indicator cs-danger">
                                            <span>
                                              <CapsyncToolTip
                                                Child={"declined-outlined"}
                                                placement={"top"}
                                                message={"Rejected"}
                                                type="icon"
                                                size="22"
                                              />
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="cs-indicator cs-success">
                                            <span>
                                              <CapsyncToolTip
                                                Child={"verification-outlined"}
                                                placement={"top"}
                                                message={"Completed"}
                                                type="icon"
                                                size="22"
                                              />
                                            </span>
                                          </div>
                                        )}

                                        {/*
                                    Once the backend will add the column to validate if form is rejected we can add the condition here - Mokarram
                                    <div className="cs-indicator ">
                                      <span className="cs-danger">
                                        <CapsyncToolTip
                                          Child={"declined-outlined"}
                                          placement={"top"}
                                          message={"Completed"}
                                          type="icon"
                                        />
                                      </span>
                                    </div> */}
                                      </div>
                                    </td>
                                    <td className="default-regular-sub-heading-m">
                                      {complete_referenceStore}
                                    </td>
                                    <td className="default-regular-sub-heading-m">
                                      <span className="d-inline-block">
                                        {complete_itr.vi_verified_expires_at ? (
                                          <span className="request-pending">
                                            {/* {datediff_day(
                                          complete_itr.vi_verified_expires_at
                                        )} */}
                                            {DateFormat(
                                              complete_itr.vi_verified_expires_at
                                            )}
                                          </span>
                                        ) : (
                                          "-"
                                        )}
                                      </span>
                                    </td>
                                    <td>
                                      <div className="table-icons-container cs-file-icon">
                                        <span
                                          // className="cs-icon-badge-neutral-20 svg-icon cs-neutral-80"
                                          className={`"cs-icon-badge-neutral-20 svg-icon cs-neutral-80 ${
                                            complete_itr.status === 3
                                              ? "cs-disabled"
                                              : ""
                                          }`}
                                          onClick={() => {
                                            if (complete_itr.status !== 3) {
                                              downloadCertificate(
                                                complete_itr.response_id
                                              );
                                            }
                                          }}
                                        >
                                          <CapsyncIcon
                                            title="files-outlined"
                                            size="18"
                                          />
                                        </span>
                                      </div>
                                      {/* 
                                     Once the backend will add the column to validate if form is rejected we can add the condition here - Mokarram
                                  <div className="table-icons-container cs-file-icon">
                                    <span
                                      onClick={() =>
                                        downloadCertificate(
                                          complete_itr.response_id
                                        )
                                      }
                                      className="cs-icon-badge-danger cs-danger svg-icon"
                                    >
                                      <CapsyncIcon
                                        title="error-doc-outlined"
                                        size="18"
                                      />
                                    </span>
                                  </div> */}
                                      {/*  <div className="cs-badge">
                                    <Image
                                      src={manage_file_icon}
                                      className="cs-icon-badge-neutral-20"
                                      onClick={() =>
                                        downloadCertificate(
                                          complete_itr.response_id
                                        )
                                      }
                                    /> 
                                     <span>Verification done</span>
                                  </div>*/}
                                    </td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                  {storeComplete.length > 0 && (
                    <div className="cs-custom-pagination">
                      <div className="cs-common-add-dropdown">
                        <span className="default-light-body-text-m cs-neutral-80">
                          Rows per page:
                        </span>
                        <Dropdown>
                          <Dropdown.Toggle className="default-light-body-text-m cs-neutral-80">
                            {dropdownDisplay}
                            <CapsyncIcon
                              title="chevron-down-outlined"
                              size="12"
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu onSelect={(e) => console.log(e)}>
                            {paginationDropdownItems.map((noOfRowsPerPage) => {
                              return (
                                <Dropdown.Item
                                  onClick={() =>
                                    handlePaginationDropdownClick(
                                      noOfRowsPerPage
                                    )
                                  }
                                  value={noOfRowsPerPage}
                                >
                                  {noOfRowsPerPage}
                                </Dropdown.Item>
                              );
                            })}
                            <Dropdown.Item
                              onClick={() =>
                                handlePaginationDropdownClick("All")
                              }
                            >
                              All
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="default-light-body-text-m cs-neutral-80">
                        <p>
                          {rowStartIdx + 1} -{" "}
                          {rowStartIdx +
                            currentNumberOfRows(
                              rowStartIdx,
                              rowsPerPage,
                              storeComplete.length
                            )}{" "}
                          of {storeComplete.length}
                        </p>
                      </div>
                      <div className="cs-pagination-arrow">
                        <Button
                          onClick={handleFirstPageClick}
                          disabled={rowStartIdx === 0}
                          className={
                            rowStartIdx === 0
                              ? "cs-neutral-50"
                              : "cs-neutral-100"
                          }
                        >
                          <CapsyncIcon
                            title="chevron-left-skip-outlined"
                            size="18"
                          />
                        </Button>
                        <Button
                          onClick={() =>
                            setRowStartIdx(rowStartIdx - rowsPerPage)
                          }
                          disabled={rowStartIdx === 0}
                          className={
                            rowStartIdx === 0
                              ? "cs-neutral-50"
                              : "cs-neutral-100"
                          }
                        >
                          <CapsyncIcon
                            title="chevron-left-outlined"
                            size="18"
                          />
                        </Button>
                        <Button
                          onClick={() =>
                            setRowStartIdx(rowStartIdx + rowsPerPage)
                          }
                          className={
                            !(rowStartIdx + rowsPerPage < storeComplete.length)
                              ? "cs-neutral-50"
                              : "cs-neutral-100"
                          }
                          disabled={
                            !(rowStartIdx + rowsPerPage < storeComplete.length)
                          }
                        >
                          <CapsyncIcon
                            title="chevron-right-outlined"
                            size="18"
                          />
                        </Button>
                        <Button
                          onClick={handleLastPageClick}
                          className={
                            !(rowStartIdx + rowsPerPage < storeComplete.length)
                              ? "cs-neutral-50"
                              : "cs-neutral-100"
                          }
                          disabled={
                            !(rowStartIdx + rowsPerPage < storeComplete.length)
                          }
                        >
                          <CapsyncIcon
                            title="chevron-right-skip-outlined"
                            size="18"
                          />
                        </Button>
                      </div>
                    </div>
                  )}
                </Row>
              )}
            </Container>
          </section>
        )}
      </>

      {/* {isLoader && <CapsyncLoader />} */}
    </>
  );
};

export default VI_Completed;
