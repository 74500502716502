import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NumberFormat from "react-currency-format";
import moment from "moment";
import { BsCircleFill } from "react-icons/bs";
import { Dropdown, Image, Button } from "react-bootstrap";

import CapsyncIcon from "./CapsyncIcon";
import commonNames from "../config/commonNames";
import { EncryptReact } from "../customHooks/EncryptDecryptReact";
import CapsyncToolTip from "./capsyncTooltip/CapsyncToolTip";
import CategoryDropDown from "../pages/individual/dashboard/InitialSetup/CategoryDropDown";
import CapsyncLoaderSpin from "../../assets/images/capsync-loader.gif";
import { useSelector } from "react-redux";

const CategoryTableData = (props) => {
  const {isSharePFS=false}=props
  const { user } = useSelector((state) => state.auth);
  const reportDisplayPermission = user && user.user && user.user.permission;
  const [isPermissionObject,setIsPermissionObject] = useState(false)
 
  const navigation = useNavigate();

  useEffect(()=>{
    if(reportDisplayPermission){
      let ifPermission = Object.keys(reportDisplayPermission).length > 0 ? true : false
      setIsPermissionObject(ifPermission)
    }
  },[reportDisplayPermission])
  
  const isAccountInErrorFreeAccounts = (account) => {
    return props.plaidErrorAccounts?.error_free_accounts.some(errorFreeAccount => {
      return errorFreeAccount?.accounts.some(errorFreeAccountItem => errorFreeAccountItem.institution_id === account.institution_id);
    });
  };

  const goToNavigate = (params, ins_id) => {
    const fetchData = EncryptReact(ins_id);
    window.localStorage.setItem("trans_ins_id", fetchData);
    navigation(`${params}/${fetchData}`);
  };

  let allCategoryTableDataTD;
  if (props.item && (props.category === "realestate" || props.category === "automobile")) {
    allCategoryTableDataTD = (["share_value", "total", "cash_value", "share_market_value",].includes(props.fieldName))
  } else {
    allCategoryTableDataTD = (["share_value", "market_value", "total", "balance_due", "cash_value", "share_market_value", "loan_amount"].includes(props.fieldName))
  }

  switch (props.type) {
    case "number":
      let messageValue = props.category == 'automobile' && props.item.api_provider === 'vehicleDB' ? 'Verified by Vehicles Database' : '';
      messageValue = props.category == 'realestate' && props.item.is_manual === false ? 'Verified by Attom Data' : messageValue;
      return (
        <div
          className={`verified-value-icon ${props.item && (allCategoryTableDataTD) && "share-value-td"} 
          ${props.category === "realestate" && props.fieldName === "market_value" && messageValue === '' && 'market-verified-td'}
          ${props.category === "automobile" && props.fieldName === "market_value" && messageValue === '' && 'market-verified-td'}`
          }>
          <div className="dollar-value-gap">
            <span> $&nbsp;</span>
            <NumberFormat
              value={Number(props.component).toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
          </div>
          {props.item && "market_value" === props.fieldName && messageValue !== '' &&
            <span className="cs-success">
              <CapsyncToolTip
                Child={"verification-outlined"}
                placement={"top"}
                message={props.category === 'realestate' ? "Verified by Attom Data" : "Verified by Vehicles Database"}
                type="icon"
                size="18"
              />
            </span>
          }
        </div>
      );

    case "string":
      return <div
        className={
          props.item && (props.fieldName === "ownership" || props.fieldName === "apr_percentage") ? "" : "common-tooltip-margin"
        }>
        {props.component || '-'} {props.is_display_tooltip && props.rowFieldIndex === 0 &&
          <CapsyncToolTip
            Child={"info-outlined"}
            placement={"top"}
            message={
              "This is a manual entry."
            }
            type="icon"
            size={22}
          />}{props.linkedTooltip && <span className="cs-primary"><CapsyncToolTip
            Child={"link-outlined"}
            placement={"top"}
            message={
              "This " + (props.mainCategory || 'category') + " is linked with \t" + props.linkedTooltip
            }
            type="icon"
            size={22}
        /></span>}
        {(props.fieldName==='institution' || props.fieldName==='description' || props.fieldName==='creditor_name') && !isAccountInErrorFreeAccounts(props.item) && props.item.is_manual===false && props.item.api_provider!=='vehicleDB' && isSharePFS===false && <CapsyncToolTip
            Child={"info-outlined"}
            placement={"top"}
            message={
              "There is an error with your account connections."
            }
            type="icon"
            size={22}
          />}
      
      </div>
    case "date":
      return <div className="common-tooltip-margin">{props.component ? moment(props.component).format("MM/DD/YYYY") : '-'}</div>

    case "action":
      return (
        <>
          <div className="table-icons-container cs-file-icon">
            {props.isLinkToggleVIsible &&
              <span
                className="cs-icon-badge-neutral-20 cs-neutral-80"
                onClick={() => {
                  if (props.item[props.linkType]) {
                    props.OnUnlinkButtonClick && props.OnUnlinkButtonClick(
                      ['asset_real_estate', "asset_automobile"].includes(props.linkType) ? props.item['id'] : props.item[props.linkType].id
                    )
                  } else {
                    props.OnLinkButtonClick && props.OnLinkButtonClick(
                      props.item.id
                    )
                  }
                }
                }
              >
                {props.item &&
                  props.item[props.linkType] ? <CapsyncToolTip
                  Child={"unlink-outlined"}
                  placement={"top"}
                  message={"Unlink"}
                  type="icon"
                /> : <CapsyncToolTip
                  Child={"link-outlined"}
                  placement={"top"}
                  message={"Link"}
                  type="icon"
                />}
              </span>
            }
            <span
              className="cs-icon-badge-neutral-20 cs-neutral-80"
              onClick={() => props.changeStatus(true, props.item.id)}
              id="file-icon"
            >
              <CapsyncToolTip
                Child={"files-outlined"}
                placement={"top"}
                message={"Manage files"}
                type="icon"
              />
            </span>
            <span
              className="cs-icon-badge-neutral-20 cs-neutral-80"
              onClick={() => props.modelData("Update Account", props.item.id)}
              id="edit-icon"
            >
              <CapsyncToolTip
                Child={"edit-outlined"}
                placement={"top"}
                message={"Edit details"}
                type="icon"
              />
            </span>
            <span
              className="cs-icon-badge-danger cs-danger"
              onClick={() => props.deleteRecord(props.item.id)}
              id="delete-icon"
            >
              <CapsyncToolTip
                Child={"delete-outlined"}
                placement={"top"}
                message={"Delete"}
                type="icon"
              />
            </span>
          </div >
        </>
      );

    case "no_transactions":
      return (
        <>
          <div className="common-tooltip-margin last-td-content cs-neutral-60">
            <p>{commonNames.Transactions}</p>
            <CapsyncToolTip
              Child={"info-outlined"}
              placement={"top"}
              message={"Data not available for this account"}
              type="icon"
            />
          </div>
        </>
      );

    case "attachments":
      return (
        <>
          <div className="table-icons-container cs-file-icon">
            <span
              className="cs-icon-badge-neutral-20 cs-neutral-80"
              onClick={() =>
                props.changeStatus(
                  true,
                  false,
                  props.item.attachments,
                  props.item.id
                )
              }
            >
              <CapsyncIcon title="files-outlined" size="18" />
            </span>
          </div>
        </>
      );

    case "amortization_number":
      return (
        <div className={props.class}>
          <span>$&nbsp;</span>
          <span>{props.data}</span>
        </div>
      );

    case "amortization_data":
      return (
        <>
          <span className="amortization-icon">
            <BsCircleFill className={props.class} color={props.color} />
          </span>
          <div className="amortization-content">
            <span className="cs-neutral-90 default-regular-sub-heading-xs">
              {props.htmlData}
            </span>
            <h6 className="value1 cs-neutral-100 default-medium-sub-heading-s">
              {props.balance}
            </h6>
          </div>
        </>
      );

    case "wealth_company_action":
      return (
        <>
          <span onClick={props.clickFunc}>
            <CapsyncIcon title={props.icon} size="18" />
          </span>
        </>
      );

    case "category_button":
      return (
        <>
          <Button
            onClick={props.checkCategory}
            className={
              props.category === props.params
                ? "cs-btn-primary default-regular-h5"
                : "cs-btn-secondary default-regular-h5"
            }
          >
            {props.mainName}
          </Button>
        </>
      );

    case "add_manual_category_dropdown":
      return (
        <>
          <div className="choose-category">
            <div className="category-dropdown">
              <p className="default-regular-sub-heading-xs cs-neutral-100 text-start">
                {props.catType === "" ? `Which ${props.message} class does this account belong to?` : props.catType}
              </p>
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="main-filter default-regular-sub-heading-s"
                >
                  Choose {props.message} class
                  <span className="cs-neutral-90 cs-dropdown-icon">
                    <CapsyncIcon
                      title="chevron-down-outlined"
                      size="18"
                    />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="cs-scroller">
                  <CategoryDropDown
                    financeType={props.financeType}
                    checkType={props.checkType}
                    data={props.data}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <span>
              <Image
                src={props.src}
                alt="Capsync Category"
                width={96}
                height={96}
              />
            </span>
          </div>
        </>
      );

    case 'other_actions':
      let displayActions = [];
      const {
        category, main_category, isPortfolio, isAmortization, isTransaction, isTransactionEnable,
        isQuickBook, changeQuickBookModal, 
        item: {
          is_amortization_link_enable,
          plaid_mask, institution_id,
          is_manual, id,
          is_report_true
        }
      } = props;

      

      if (isAmortization) {
        displayActions.push({
          isAmortization, url: `/individual/${main_category}/amortization/${category}/${id}`,
          title: commonNames.AmortizationSchedule,
          // isEnable: is_amortization_link_enable
          isEnable: is_manual && is_amortization_link_enable
        })
      }

      if (isPortfolio) {
        displayActions.push({
          url: '',
          title: commonNames.Portfolio,
          isEnable: !is_manual
        })
      }

      if (isQuickBook) {
        displayActions.push({
          url: '',
          title: commonNames.Reports,
          isEnable: !is_manual
        })
      }

      if (isTransaction) {
        displayActions.push({
          url: `/individual/${main_category}/${category}/transaction/${plaid_mask}`,
          title: commonNames.Transactions,
          // isEnable: isTransactionEnable
          isEnable: !is_manual
        });
      }

      const handleClick = (title) => {
        
        if (title === commonNames.Portfolio) {
          let encryptedRowId = EncryptReact(`${id}`);
          window.localStorage.setItem('portfolio_row_id', encryptedRowId);

          goToNavigate(`/individual/assets/${category}/portfolio/${plaid_mask}`, institution_id)
        }
        else if (title === commonNames.AmortizationSchedule) {
          navigation(`/individual/${main_category}/amortization/${category}/${id}`)
        }
        else if (title === commonNames.Reports) {
          navigation(`/individual/assets/business/reports/${props.item.id}`)
        }
        else {
          goToNavigate(`/individual/${main_category}/${category}/transaction/${plaid_mask}`, institution_id)
        }
      }

      return (
        <div className="action-box">
          <div className="cs-common-add-dropdown plaid-transaction-mini-loader">
            {displayActions.length > 1 &&
              <Dropdown>
                <Dropdown.Toggle variant="link" id="option-icons">
                  <span className="icon cs-neutral-80">
                    <CapsyncIcon
                      title="option-horizontal-filled"
                      size="18"
                    />
                  </span>
                </Dropdown.Toggle>
                <div className={props.arrlength == "1" ? "upper-dropdown" : ""}>
                  <Dropdown.Menu >
                    {displayActions.map((dropdown, index) => {
                      const { title, isEnable, url } = dropdown;
                      return (
                        <Dropdown.Item
                          key={index}
                          onClick={(e) => isEnable && handleClick(title, url)}
                          className={isEnable ? '' : 'disable-amortization cs-disabled'}
                          id={isEnable && `option-${title.toLowerCase()}`}
                        >
                          {
                            (props.transactionLoader && commonNames.Portfolio !== title) && [commonNames.Portfolio,commonNames.Transactions].includes(title) ?
                              <div className="ira-portfolio-tooltip">
                                <CapsyncToolTip
                                  Child={<React.Fragment>{title}<div className="text-center">
                                    <Image
                                      src={CapsyncLoaderSpin}
                                      alt="CapsyncLoaderSpin"
                                    />
                                  </div></React.Fragment>}
                                  placement={"top"}
                                  message={"We are fetching your data"}
                                  type="text"
                                  size={22} />
                              </div> : <React.Fragment>
                                {title}
                                {!isEnable && <CapsyncToolTip Child={"info-outlined"} placement={"top"} message={"Data not available for this account"} type="icon" size={22} />}
                              </React.Fragment>
                          }
                        </Dropdown.Item>)
                    })}
                  </Dropdown.Menu>
                </div>
              </Dropdown >}
            {displayActions.length === 1 && displayActions[0].title === "Reports" && isPermissionObject && reportDisplayPermission.is_allow_qbo === '1' &&
              <>
              <div onClick={() => is_report_true === true ? handleClick(displayActions[0].title) : changeQuickBookModal(true, props.item.id)}
                className={'common-tooltip-margin last-td-content'} id="transaction">
                <p>{commonNames.Reports}</p>
                <span className="cs-neutral-70">
                  <CapsyncIcon title="chevron-right-outlined" size="18" />
                </span>
              </div>
            </>
            }

            {displayActions.length === 1 && displayActions[0].title === "Transactions" &&
              <div onClick={() => (is_manual === false && !props.transactionLoader) && handleClick(displayActions[0].title)}
                className={is_manual || props.transactionLoader ? 'common-tooltip-margin disable-amortization cs-disabled' : 'common-tooltip-margin last-td-content'} id="transaction">
                <p>{commonNames.Transactions}</p>
                {is_manual === false || props.transactionLoader ?
                  <span className="cs-neutral-70">
                    <CapsyncIcon title="chevron-right-outlined" size="18" />
                  </span> :
                  <CapsyncToolTip Child={"info-outlined"} placement={"top"} message={"Data not available for this account"} type="icon" size={22} />
                }
              </div>
            }

            {displayActions.length === 1 && displayActions[0].title === "Portfolio" &&
              <div onClick={(e) => displayActions[0].isEnable === true && displayActions[0].isEnable && handleClick(displayActions[0].title)}
                className={displayActions[0].isEnable ? 'common-tooltip-margin last-td-content' : 'common-tooltip-margin disable-amortization cs-disabled'} id="portfolio">
                <p>{displayActions[0].title}</p>
                {displayActions[0].isEnable === true ?
                  <span className="cs-neutral-70">
                    <CapsyncIcon title="chevron-right-outlined" size="18" />
                  </span> :
                  <CapsyncToolTip Child={"info-outlined"} placement={"top"} message={"Data not available for this account"} type="icon" size={22} />
                }
              </div>
            }

            {displayActions.length === 1 && displayActions[0].title === "Amortization Schedule" &&
              <div onClick={(e) => displayActions[0].isEnable === true && displayActions[0].isEnable && handleClick(displayActions[0].title)}
                className={displayActions[0].isEnable ? 'common-tooltip-margin last-td-content' : 'common-tooltip-margin disable-amortization cs-disabled'} id="amortizationschedule">
                <p>{displayActions[0].title}</p>
                {displayActions[0].isEnable === true ?
                  <span className="cs-neutral-70">
                    <CapsyncIcon title="chevron-right-outlined" size="18" />
                  </span> :
                  <CapsyncToolTip Child={"info-outlined"} placement={"top"} message={"Data not available for this account"} type="icon" size={22} />
                }
              </div>
            }
            {/* {displayActions.length === 1 && displayActions[0].isEnable === false &&
              <div className="common-tooltip-margin last-td-content cs-neutral-60">
                <p>{commonNames.Transactions}</p>
                <CapsyncToolTip
                  Child={"info-outlined"}
                  placement={"top"}
                  message={"Data not available for this account"}
                  type="icon"
                />
              </div>
            } */}
            {
              displayActions.length <= 1 && !props.item.is_manual && props.transactionLoader &&
              <CapsyncToolTip
                Child={<div className="text-center">
                  <Image
                    src={CapsyncLoaderSpin}
                    alt="CapsyncLoaderSpin"
                  />
                </div>}
                placement={"top"}
                message={"We are fetching your data"}
                type="text"
                size={22} />
            }
          </div>
        </div >
      );

    default:
      break;
  }
  return props;
};

export default CategoryTableData;
