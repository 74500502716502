import React, { useEffect, useState } from 'react'
import { Modal, Form, Row, Col, Button, Dropdown, Image } from 'react-bootstrap';
import CapsyncIcon from '../../../../commonComponent/CapsyncIcon';
import { shortLengthLabel } from '../../../../util/common';

const VinCarModal = (props) => {

    const { onClickContinue, onClickCancel, marketData,
        show,
        carConditionValue, carTrimValue, record
    } = props;

    const [carCondition, setCarCondition] = useState('');
    const [carTrim, setCarTrim] = useState('');
    const [carReturnObj, setCarReturnObj] = useState({ trim: '', condition: '', market_value: 0 });
    const [isContinue, setIsContinue] = useState(true);

    useEffect(() => {
        return () => { setCarReturnObj({ trim: '', condition: '', market_value: 0 }) }
    }, [])

    useEffect(() => {
        let trimData = window.innerWidth <= 575 ? shortLengthLabel(carTrimValue, 30) : shortLengthLabel(carTrimValue, 45);
    }, [carConditionValue, carTrimValue, record]);

    useEffect(() => {
        if (carCondition !== '' && carTrim !== '') {
            setIsContinue(false);
        }
    }, [carCondition, carTrim])

    useEffect(() => {
        setCarCondition('');
        setCarTrim('');
        setIsContinue(true);
    }, [show])

    const handleChange = (event, type) => {
        if (type === "condition") {
            setCarReturnObj(prevState => ({
                ...prevState,
                market_value: event['Dealer Retail'].slice(1),
                condition: event.Condition
            }))
            setCarCondition(event.Condition);
        } else {
            setCarReturnObj(prevState => ({
                ...prevState,
                trim: event
            }))
            setCarTrim(event);
        }

        if (carCondition !== '' && carTrim !== '') {
            setIsContinue(false);
        }
    }

    const handleContinue = () => {
        onClickContinue(carReturnObj)
    }

    let conditionsListArr = marketData.length == 1 ? marketData[0]["market value"] : [];
    const marketValueList = marketData.find((market, index) => carTrim === market.trim);
    if (marketValueList) {
        conditionsListArr = marketValueList["market value"];
    }

    return (
        <>
            <Modal
                className="cs-common-modal-overlay trim-modal"
                show={show}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName="cs-md-modal"
            >
                <Modal.Header>
                    <Modal.Title className="default-semi-bold-h3">
                        Select car's TRIM and condition
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={12} >
                            <Form.Group className="cs-form-group">
                                <Form.Label>Trim</Form.Label>
                                <div className="cs-common-add-dropdown">
                                    <Dropdown className="trim-dropdown-styling">
                                        <Dropdown.Toggle>
                                            <span className='trim-content'>{carTrim === '' ? "Select trim" : carTrim}</span>
                                            <span className="cs-neutral-90">
                                                <CapsyncIcon
                                                    title="chevron-down-outlined"
                                                    size="18"
                                                />
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {marketData.map((trimItr) => (
                                                <Dropdown.Item
                                                    key={trimItr.trim}
                                                    className={trimItr.trim == carTrim ? "cs-selected-dropdown " : "cs-stroke-default-tab cs-tabs-height"}
                                                    onClick={() => handleChange(trimItr.trim, 'trim')}
                                                >
                                                    {trimItr.trim}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Form.Group>
                        </Col>
                        <Col lg={12}>
                            <Form.Group className="cs-form-group last-condition-field">
                                <Form.Label>Condition</Form.Label>
                                <div className="cs-common-add-dropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle >
                                            {carCondition === '' ? "Select condition" : carCondition}
                                            <span className="cs-neutral-90">
                                                <CapsyncIcon
                                                    title="chevron-down-outlined"
                                                    size="18"
                                                />
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {conditionsListArr.length !== 0 && conditionsListArr.map((value, index) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    onClick={() =>
                                                        handleChange(value, 'condition')
                                                    }
                                                    className={value.Condition == carCondition ? "cs-selected-dropdown"
                                                        : "default-regular-sub-heading-s"}
                                                >
                                                    {value.Condition}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <div className="cs-modal-btn">
                        <Button
                            className="cs-btn-secondary md-btn default-regular-h5"
                            onClick={() => onClickCancel()}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="cs-btn-primary md-btn default-regular-h5"
                            onClick={handleContinue}
                            disabled={isContinue}
                        >
                            Continue
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default VinCarModal