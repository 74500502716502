import React from "react";
import { Button, Modal, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ErrorValidation from "../../../assets/images/other/error-validation.svg";
import "../../../app/commonComponent/component/Component.css";

const ExpirePopup = ({ changeExpireState, expireModal, role }) => {
  const navigate = useNavigate();

  const goToBilling = () => {
    navigate(`/${role}/billing-subscription`);
    changeExpireState(false);
  };

  const confirmNot = () => {
    changeExpireState(false);
  };

  return (
    <>
      <Modal
        show={expireModal}
        onHide={() => changeExpireState(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            You can't add account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="log-out-popup">
            <span>
              <Image
                src={ErrorValidation}
                alt="Error Validation"
                width={100}
                height={100}
              />
            </span>
            <p className="default-light-body-text-m cs-neutral-90 text-center">
              You are on free trial so you are allowed only limited features.
              Upgrade plan now to access all the features.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="cs-modal-btn cs-center-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={confirmNot}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              className="cs-btn-primary md-btn default-regular-h5"
              onClick={goToBilling}
              variant="primary"
            >
              Upgrade now
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ExpirePopup;
