const setPfsAccessUser = (userObj) => { };

const viewOnlyAccess = ['/individual/collaboration',
  '/individual/request',
  '/individual/verifyinvestor/pending',
  '/individual/verifyinvestor/completed',
  '/individual/verifyinvestor/expired',
  '/individual/file-encryption',
  '/individual/mfa-setup',
  '/individual/billing-subscription',
  '/individual/credit-manager',
  '/individual/identity-protect',
  '/individual/identity-protect/identity-dashboard',
  '/individual/identity-protect/identity-protection-services',
  '/individual/identity-protect/identity-alerts',
  '/individual/identity-protect/identity-monitored-info',
  '/individual/identity-protect/identity-financial-accounts',
  '/individual/credit-manager/credit-report',
  '/individual/credit-manager-v2',
  '/individual/identity-protect-v2',
];

// const viewOnlyAccess = ['/individual',
//   '/individual/assets',
//   '/individual/liabilities'
// ];

const userPermissionView = ['/individual/account-details', '/individual/audit-logs'];
const userPermissionStandard = ['/individual/account-details', '/individual/billing-subscription', '/individual/audit-logs'];

const userPermissionAll = ['/individual/account-details', '/individual/audit-logs',
  '/individual/billing-subscription',
  '/individual/file-encryption',
  '/individual/mfa-setup'
];
const standardPermissionUrl = ['/individual/mfa-setup', '/individual/file-encryption']

const filemanagementPermissionAccess = [
'/individual/file-management',
  '/individual/archive',
  '/individual/trash'
]
const creditManagerPermissionAccess = [
  '/individual/credit-manager/credit-report',
  '/individual/credit-manager-v2',
  '/individual/credit-manager',
  ]
  const identityProtectPermissionAccess = [
    '/individual/identity-protect',
    '/individual/identity-protect/identity-dashboard',
    '/individual/identity-protect/identity-protection-services',
    '/individual/identity-protect/identity-alerts',
    '/individual/identity-protect/identity-monitored-info',
    '/individual/identity-protect/identity-financial-accounts',
    '/individual/identity-protect-v2',
]
const verifyInvestorPermissionAccess = [
  '/individual/verifyinvestor/pending',
  '/individual/verifyinvestor/completed',
  '/individual/verifyinvestor/expired',
  ]
const sidebarMenuPermissionAccess = [
  ...filemanagementPermissionAccess,
  ...creditManagerPermissionAccess,
  ...identityProtectPermissionAccess,
  ...verifyInvestorPermissionAccess

];

export {
  viewOnlyAccess,
  userPermissionView,
  userPermissionStandard,
  userPermissionAll,
  standardPermissionUrl,
  sidebarMenuPermissionAccess,
  filemanagementPermissionAccess,
  verifyInvestorPermissionAccess,
  identityProtectPermissionAccess,
  creditManagerPermissionAccess

}