import React from "react";
import { Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import NumberFormat from "react-currency-format";

import ContingentLiabilities from "./ContingentLiabilities";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import { financeType } from "../../../config/finance";
import { getFinanceData } from "../../../util/finance";

const FinanceTable = (props) => {
  const { data, grandTotal, type, paymentInstallAuto } = props;
  const financeData = getFinanceData(data, type, paymentInstallAuto);

  const navigation = useNavigate();
  return (
    <div className="finance-data-container">
      <div className="finance-data">
        <Link to={props.isSharePFS ? '' : `/individual/${type}`} id={type}>
          <div
            className={`default-semi-bold-h3 cs-neutral-100 finance-heading ${type === "assets" ? "heading-asset" : "heading-liability"
              }`}
          >
            {financeType[type]}
            <span
              className={
                type === "assets" ? "asset-table-icon" : "liability-table-icon"
              }
            >
              <CapsyncIcon title="redirect-outlined" size="24px" />
            </span>
          </div>
        </Link>
        <Table>
          <thead className="finance-thead">
            <tr>
              <th width="486" className="default-medium-sub-heading-l">Category</th>
              <th className="default-medium-sub-heading-l">Balance</th>
              {/* <th></th> */}
            </tr>
          </thead>
          <tbody className="finance-tbody default-regular-sub-heading-m">
            {financeData.map((category, index) => {
              const { title, url, balance, pay } = category;

              return (
                <tr key={index} className={!props.isSharePFS && "cursor-pointer"} onClick={() => {
                  if (!props.isSharePFS) {
                    navigation(props.isSharePFS ? '' : `/individual/${type}/${url}`)
                  }
                }}>
                  <td width="486">

                    {title}
                  </td>
                  <td width="180">
                    <div className="dollar-value-gap">
                      <span>$&nbsp;</span>
                      <NumberFormat
                        className="dash-bal-padding"
                        value={Math.round(balance)}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </div>
                  </td>
                  <td width="50" className="go-next-page-div">
                    <CapsyncIcon title="chevron-right-outlined" size="16" />
                  </td>
                </tr>
              );
            })}
            <tr className="default-medium-sub-heading-m dashboard-tfoot">
              <td className="style-tfoot"> Total {financeType[type]}</td>
              <td>
                <div className="dollar-value-gap">
                  <span>$&nbsp;</span>
                  <NumberFormat
                    value={Math.round(grandTotal).toFixed(0)}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </div>
              </td>
              <td className="go-next-page-div style-tfoot"></td>
            </tr>
          </tbody>
        </Table>
      </div>
      {type === "liabilities" && <ContingentLiabilities {...props} />}
    </div>
  );
};

export default FinanceTable;
