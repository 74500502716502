import React, { useEffect, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { clearMessage } from "../../slices/message";
import CapsyncIcon from "../CapsyncIcon";
export const Themeprovider = createContext(null);

const AuthMFASetUpModal = (props) => {
  const { onChangeMFAOption, submitMfaOption, mfaType, record } = props;
  const { user } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const { modalTitle } = props;

  // const MFAoptionValue = user && user.user.firstSignInSteps === 1;

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const MFAoptionValue = record && record.firstSignInSteps === 1;
  const MFAEmail = record.MFAEmail;
  const MFAPhone = record.MFAPhone;
  const MFAGoogle = record.MFAGoogle;

  return (
    <>
      <Modal
        show={props.mfaModal}
        onHide={!props.mfaModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        dialogClassName="cs-md-modal"
        className="profile-update-popup"
      >
        <Modal.Header>
          <Modal.Title className="m-auto cs-neutral-100 default-semi-bold-h3">
            {modalTitle}
          </Modal.Title>
          {/* <CloseButton onClick={() => setModalShow(false)} /> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={12}>
              <div className="auth-right">
                <div className="formArea">
                  <div className="text-center">
                    <p className="cs-neutral-80 default-regular-h5">
                      Choose your preferred authentication method
                    </p>
                  </div>
                  <div>
                    {MFAoptionValue && MFAEmail && (
                      <div
                        className={
                          "auth-mfa-item " +
                          (mfaType === "1" ? "active-method" : "")
                        }
                        onClick={() => onChangeMFAOption("1")}
                      >
                        <div className="auth-mfa-inner">
                          <div className="auth-mfa-options cs-radio-btn">
                            <input
                              type="radio"
                              name="mfOption"
                              id="email"
                              value="1"
                              className="radio-button"
                              checked={mfaType === "1"}
                              // onClick={(e) => mfOptionEmail(e)}
                            />
                          </div>
                          <div className="auth-mfa-options">
                            <h4 className="cs-neutral-100 default-regular-h4 ">
                              Email verification
                            </h4>
                            <span className="default-regular-h6 cs-neutral-60 ">
                              Get a code sent to your email verification
                            </span>
                          </div>
                        </div>
                        <div className="mfa-options cs-neutral-80">
                          <CapsyncIcon title="email-outlined" size="22" />
                        </div>
                      </div>
                    )}
                    {MFAoptionValue && MFAPhone && (
                      <div
                        className={
                          "auth-mfa-item " +
                          (mfaType === "2" ? "active-method" : "")
                        }
                        onClick={() => onChangeMFAOption("2")}
                      >
                        <div className="auth-mfa-inner">
                          <div className="auth-mfa-options cs-radio-btn">
                            <input
                              type="radio"
                              name="mfOption"
                              id="mobile"
                              value="2"
                              className="radio-button"
                              checked={mfaType === "2"}
                              // onClick={(e) => mfOptionEmail(e)}
                            />
                          </div>
                          <div className="auth-mfa-options">
                            <h4 className="cs-neutral-100 default-regular-h4">
                              Mobile verification
                            </h4>
                            <span className="default-regular-h6 cs-neutral-60">
                              Get a code sent to your phone via SMS
                            </span>
                          </div>
                        </div>
                        <div className="mfa-options cs-neutral-80">
                          <CapsyncIcon title="mobile-outlined" size="22" />
                        </div>
                      </div>
                    )}
                    {MFAoptionValue && MFAGoogle && (
                      <div
                        className={
                          "auth-mfa-item " +
                          (mfaType === "3" ? "active-method" : "")
                        }
                        onClick={() => onChangeMFAOption("3")}
                      >
                        <div className="auth-mfa-inner">
                          <div className="auth-mfa-options cs-radio-btn">
                            <input
                              type="radio"
                              name="mfOption"
                              id="auth"
                              value="3"
                              className="radio-button"
                              checked={mfaType === "3"}
                              // onClick={(e) => mfOptionEmail(e)}
                            />
                          </div>
                          <div className="auth-mfa-options">
                            <h4 className="cs-neutral-100 default-regular-h4 ">
                              Authentication App
                            </h4>
                            <span className="default-regular-h6 cs-neutral-60">
                              Connect your authenticator app
                            </span>
                          </div>
                        </div>
                        <div className="mfa-options cs-neutral-80">
                          <CapsyncIcon title="qr-code-outlined" size="22" />
                        </div>
                      </div>
                    )}
                    {/* <div className="auth-mfa-item" >
                      <div className="auth-mfa-inner">
                        <div className="auth-mfa-options cs-radio-btn">
                          <input
                            type="radio"
                            name="mfOption"
                            id="email"
                            value="1"
                            className="radio-button"
                            onClick={(e) => mfOptionEmail(e)}
                          />
                        </div>
                        <div>
                          <h4 className="cs-neutral-100 default-regular-h4 ">
                            Email verification
                          </h4>
                          <span className="default-regular-h6 cs-neutral-60 ">
                            Get a code sent to your email verification
                          </span>
                        </div>
                      </div>
                      <CapsyncIcon title="email-outlined" size="22" />
                    </div> */}

                    {/* 
                    <div className="auth-mfa-item">
                      <div className="auth-mfa-inner">
                        <div className="auth-mfa-options cs-radio-btn">
                          <input
                            type="radio"
                            name="mfOption"
                            id="mobile"
                            value="2"
                            className="radio-button"
                            onClick={(e) => mfOptionPhone(e)}
                          />
                        </div>
                        <div>
                          <h4 className="cs-neutral-100 default-regular-h4 ">
                            Mobile verification
                          </h4>
                          <span className="default-regular-h6 cs-neutral-60 ">
                            Get a code sent to your phone via SMS
                          </span>
                        </div>
                      </div>
                      <CapsyncIcon title="mobile-outlined" size="22" />
                    </div>

                    <div className="auth-mfa-item">
                      <div className="mfitemInner">
                        <div className="mfa-options">
                          <input
                            type="radio"
                            name="mfOption"
                            id="auth"
                            value="3"
                            onClick={(e) => onChangeMFAOption(e)}
                          />
                        </div>
                        <div className="mfa-options cnt2 text-start">
                          <h4 className="cs-neutral-100 default-regular-h4 ">
                            Authentication App
                          </h4>
                          <span className="default-regular-h6 cs-neutral-60">
                            Connect your authenticator app
                          </span>
                        </div>
                      </div>
                      <CapsyncIcon title="qr-code-outlined" size="22" />
                    </div> */}
                  </div>
                </div>
                {message && (
                  <div className="cs-msg default-regular-body-text-s cs-danger">
                    <span className="icon">
                      <CapsyncIcon title="info-filled" />
                    </span>
                    <span className="txt"> {message}</span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="cs-modal-btn">
            <Button
              type="button"
              className="cs-btn-secondary md-btn default-regular-h5"
              variant="secondary"
              onClick={() => props.closeModal()}
            >
              Cancel
            </Button>
            <Button
              type="button"
              className="cs-btn-primary md-btn default-regular-h5"
              variant="primary"
              disabled={!mfaType ? true : false}
              onClick={submitMfaOption}
            >
              Next
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AuthMFASetUpModal;
