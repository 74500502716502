import React, { useRef } from "react";
import { Image, Modal, Button } from "react-bootstrap";
import deleteIcon from "../../../assets/images/other/delete-validation.svg";
import CapsyncToolTip from "../../commonComponent/capsyncTooltip/CapsyncToolTip";
const DeletePartner = (props) => {
  const {
    showDeletePartnerModal,
    setShowDeletePartnerModal,
    removePartnerCommon,
  } = props;

  const refSubmit = useRef(null);

  const handleClick = () => {
    refSubmit.current.click();
  };

  //   const handleChange = ({ target }) => {
  //     // console.log(target.value)
  //     record[target.name] = target.value;
  //   };
  const handleRemoveIndividualAndCompany = () => {
    removePartnerCommon(2);
    setShowDeletePartnerModal(false)
  }
  const handleRemoveIndividual = () => {
    removePartnerCommon(1);
    setShowDeletePartnerModal(false)
  };

  return (
    <Modal
      show={showDeletePartnerModal}
      onHide={() => setShowDeletePartnerModal(false)}
      // dialogClassName={"modal-dialog-border"}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal"
      className="delete-individual-modal"

    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
          Delete Individual
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delete-modal-content">
          <Image src={deleteIcon} alt="popup_delete" className="action-icon" />
          <p className="default-light-body-text-m cs-neutral-90">
            Are you sure that you want remove this user from your account?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="cs-modal-btn cs-center-btn">
          <div className="cs-dflex-btn">
            <Button
              onClick={handleRemoveIndividualAndCompany}
              className="cs-btn-secondary lg-btn default-regular-h5">
              Remove Individual & Company
            </Button>
            <span className="cs-neutral-80">
              <CapsyncToolTip
                Child={"info-outlined"}
                placement={"top"}
                message={"Select this option if you want to remove the individual from your account entirely, including their association with any companies."}
                type="icon"
                size="18"
                width="420px"
              />
            </span>
          </div>
          <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>
          <div className="cs-dflex-btn">
            <Button
              onClick={handleRemoveIndividual}
              className="cs-btn-secondary lg-btn default-regular-h5">
              Remove from Company
            </Button>
            <span className="cs-neutral-80">
              <CapsyncToolTip
                Child={"info-outlined"}
                placement={"top"}
                message={"Select this option if you want to remove the individual only from the selected company, but keep their account intact."}
                type="icon"
                size="18"
                width="420px"

              />
            </span>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DeletePartner;
