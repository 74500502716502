/* eslint-disable */
import React, { useCallback, useState, useRef, useEffect } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Button,
  Dropdown,
  FormControl,
  Image,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import DatePicker from "react-date-picker";
import NumberFormat from "react-currency-format";
import { useFormik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import * as yup from "yup";

import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import validationObj from "./validationSchema";
import { adjustLength } from "../../../../customHooks/adjustLength";
import {
  categoryAdd,
  categoryUpdateRecord,
  fetchRealestatePropertyPrice,
} from "../../../../slices/category.slice";
import commonNames from "../../../../config/commonNames";
import { setAddressString } from "../../../../util/common";
import ConfirmEditModal from "../../../../commonComponent/ConfirmEditModal";
import { validateDuplicateFileName } from "../../../../config/validateDuplicateFileName";
import AddressSearch from "../../../../commonComponent/AddressSearch";
import useDebounce from "../../../../customHooks/useDebounce";
import { uniValidation } from "../../liabilities/categoryForms/validationSchema";
// import ManualAddress from "../../../../commonComponent/ManualAddress";
import {
  handleOwnershipKeyDown,
  handleOwnershipValue,
} from "../../../../config/handleOwnershipValue";
import {
  get_user_storage,
  useProgressUploadDetails,
  useUploadStorage,
} from "../../../../slices/fileManagement.slice";

const RealEstate = (props) => {
  const {
    onFormClose,
    onFormSubmit,
    record,
    showModal,
    linkAddressObj = null,
  } = props;

  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  let id;
  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }
  const storageData = useUploadStorage();
  const progressUploadDetails = useProgressUploadDetails();
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [storeAcceptedFiles, setStoreAcceptedFiles] = useState([]);
  const [IsAttomDataChecked, setIsAttomDataChecked] = useState(true);
  const [isAttomDataLoad, setIsAttomDataLoad] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [attomChange, setAttomChange] = useState(false);
  const [showConfirmEditModal, setShowConfirmEditModal] = useState(false);
  const [addressSelected, setAddressSelected] = useState(false);
  const [attomRealEstateData, setAttomRealEstateData] = useState({
    start_date: null,
    api_details: null,
    avmhistory: null,
  });
  const [addressObj, setAddressObj] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    country: "",
    postal_code: "",
  });

  const [initialValues, setInitialValues] = useState({
    user_id: id,
    address: "",
    market_value: "",
    share_market_value: "",
    ownership: "",
    annual_income: "",
    notes: "",
    purchase_date: moment().format("MM/DD/YYYY"),
    date_updated: moment().format("MM/DD/YYYY"),
    street_name: "",
    zip_code: "",
    city: "",
  });
  const [duplicateFileError, setDuplicateFileError] = useState("");
  const [storageError, setStorageError] = useState("");
  const [duplicateAddressError, setDuplicateAddressError] = useState("");

  const [isManualAddress, setIsManualAddress] = useState(false); //Is Manual address
  const [isAddressChange, setIsAddressChange] = useState(false);

  useEffect(() => {
    try {
      if (!!record) {
        const attomDataValue = !record.is_manual
          ? record.api_value
          : record.market_value;
        setInitialValues({
          user_id: record.user_id,
          address: setAddressString(record.address),
          ownership:
            record.ownership && record.ownership.toLocaleString("en-US"),
          share_market_value:
            record.share_market_value &&
            record.share_market_value.toLocaleString("en-US"),
          market_value: attomDataValue,
          annual_income: record.annual_income,
          notes: record.notes,
          date_updated: new Date(
            moment(record.date_updated).format("MM/DD/YYYY")
          ),
          purchase_date: record.purchase_date
            ? new Date(moment(record.purchase_date).format("MM/DD/YYYY"))
            : null,
          city: record.address && record.address.city,
          zip_code: record.address && record.address.postal_code,
          street_name: record.address && record.address.line1,
        });
        // setAddressObj(record.address)
        record.address && setAddress(setAddressString(record.address));
        setIsAttomDataChecked(!record.is_manual);
        setAddressSelected(true);
        setIsManualAddress(record.is_manual_address);

        // setAddressSelected(!record.is_manual);
      }
    } catch (error) {
      console.log(error);
    }
  }, [record]);

  useEffect(() => {
    if (linkAddressObj) {
      // setAddressObj(linkAddressObj);
      setAddress(setAddressString(linkAddressObj));
      setFieldValue("address", setAddressString(linkAddressObj));
      realEstatePropertyPrice(linkAddressObj);
    }
  }, [linkAddressObj]);

  useEffect(() => {
    if (IsAttomDataChecked && attomChange) {
      realEstatePropertyPrice(addressObj);
      setAttomChange(false);
    }
  }, [IsAttomDataChecked]);

  useEffect(() => {
    if (
      isAddressChange &&
      addressObj.line1 !== "" &&
      addressObj.state !== "" &&
      addressObj.country !== ""
    ) {
      realEstatePropertyPrice(addressObj);
      setAddressSelected(true);
      setAttomChange(false);
    }
  }, [addressObj]);

  const asset_realEstate_validationSchema = yup.object().shape({
    // address: isManualAddress === false && yup
    //     .string()
    //     .min(2, "Address must be at least 2 characters")
    //     .required("Please enter address"),
    market_value:
      IsAttomDataChecked === false &&
      yup
        .string()
        .max(15, "Market value must be 20 characters maximum")
        .required("Enter market value")
        .test(
          "non-zero",
          "Market Value must be greater than zero",
          (value) => Number(value.replace(/,/g, "")) !== 0
        )
        .test(
          "checkDecimal",
          "Market Value must be greater than zero",
          (value) => {
            return value.indexOf(".") > -1
              ? /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)
              : true;
          }
        )
        .test(
          "",
          "Market Value should be greater than 0",
          (value) => !/^0+(?:\.0+)?$/.test(value)
        )
        .test(
          "Is positive?",
          "Balance must be a positive number",
          (value) => value && !(Number(value.replace(/,/g, "")) < 0)
        ),

    // share_market_value: yup.string()
    //     .required('Please share market value'),
    ownership: yup
      .number()
      // .positive("Ownership can't start with a minus")
      // .moreThan(0, "Ownership should not be zero")
      // .lessThan(101, "Ownership should not be more than 100")
      .required(uniValidation.assets_and_liabilities.ownership.require),

    annual_income: yup
      .string()
      .optional()
      .test(
        "checkDecimal",
        "Market Value must be greater than zero",
        (value) => {
          return value && value.indexOf(".") > -1
            ? /^(\d{1,3}(,\d{3})*(\.\d{0,2})?)?$/.test(value)
            : true;
        }
      ),
    purchase_date: yup.date().required("Please select purchase date"),
    date_updated: yup.date().required("Please select updated date"),
    // .max(date.setDate(date.getDate() + 1), "Future date can not be accepted"),
    street_name: yup
      .string()
      .required(uniValidation.street_name.require)
      .matches(/(?=.*?\d)/, uniValidation.street_name.valid)
      .matches(/[A-Za-z]{2}/, uniValidation.street_name.valid),
    city: yup
      .string()
      .required(uniValidation.city.require)
      // .matches(/^\S.*$/, "Whitespace is not allowed")
      // .test(
      //     "whitesspace",
      //     "Whitespace is not allowed",
      //     (value) => !(/^\s+|\s+$/g).test(value)
      // )
      .min(2, uniValidation.city.valid),
    state: yup
      .string()
      .matches(/^\S(?:.*\S)?$/, "No spaces allowed")
      .min(2, "Required at least 2 characters"),
    zip_code: yup
      .string()
      .required(uniValidation.zip_code.require)
      .matches(/^[0-9]+$/, uniValidation.zip_code.valid)
      .min(5, uniValidation.zip_code.valid)
      .max(5, uniValidation.zip_code.valid),
  });

  const realEstatePropertyPrice = useDebounce(async (addressObjData) => {
    setIsAttomDataLoad(true);
    dispatch(
      fetchRealestatePropertyPrice({
        line1: addressObjData.line1,
        line2: addressObjData.postal_code,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          setIsAttomDataLoad(false);
          setIsAttomDataChecked(true);
          if (!!response.data.market_value) {
            setErrorMessage("");
            setFieldValue("market_value", response.data.market_value);
            response.data.api_details.property[0].sale.saleTransDate &&
              setFieldValue(
                "purchase_date",
                moment(
                  response.data.api_details.property[0].sale.saleTransDate
                ).format("MM/DD/YYYY")
              );
            setAttomRealEstateData(response.data);
            getAttomMarketValue(response.data.property[0].identifier.Id);
          } else {
            setIsAttomDataLoad(false);
            setIsAttomDataChecked(false);
            setFieldValue("market_value", "");
            setFieldValue("ownership", "");
            setErrorMessage(
              "Attom Databases cannot determine a market value for this address. Please enter a manual value."
            );
          }
        }

        if (response.code === 500) {
          setFieldValue("market_value", "");
          setFieldValue("ownership", "");
          setIsAttomDataLoad(false);
          setIsAttomDataChecked(false);
          setErrorMessage(
            "Attom Databases cannot determine a market value for this address. Please enter a manual value."
          );
        }
      })
      .catch((err) => {
        // setLoading(false);
        // setModalShow(false);
        // toast.error(err.message);
      });
  }, 500);

  const {
    values,
    errors,
    touched,
    status,
    handleBlur,
    setValues,
    handleSubmit,
    setFieldValue,
    resetForm,
    setStatus,
    setFieldTouched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: asset_realEstate_validationSchema,
    onSubmit: async (values) => {
      try {
        if (
          !!isAddressChange &&
          !!status &&
          (!!status.street_name ||
            !!status.city ||
            !!status.state ||
            !!status.zip_code)
        ) {
          return;
        }
        setLoading(true);
        record && (values.id = record.id);
        values.user_id = id;
        values.api_provider = IsAttomDataChecked;
        values.api_value = IsAttomDataChecked ? values.market_value : 0;
        values.is_manual = IsAttomDataChecked ? false : true;
        values.market_value = values.market_value.toString();
        values.address = addressObj;
        values.share_market_value = parseFloat(
          (values.market_value.toString().replace(/,/g, "") *
            values.ownership) /
            100
        ).toString();
        values.is_manual_address = isManualAddress;

        delete values.city;
        delete values.street_name;
        delete values.state;
        delete values.zip_code;
        delete values.country;

        const inProgressData = progressUploadDetails.length
          ? progressUploadDetails.reduce((acc, item) => acc + item.size, 0)
          : 0;
        const used_space = storageData.usedSpace + inProgressData;

        const formdata = new FormData();
        let realEstateData = { ...attomRealEstateData, ...values };
        formdata.append("user_details", JSON.stringify(realEstateData));
        formdata.append("user_id", realEstateData.user_id);
        formdata.append("used_space", used_space);
        formdata.append(
          "institution",
          realEstateData.address.line2 === ""
            ? realEstateData.address.line1
            : realEstateData.address.line1 + "-" + realEstateData.address.line2
        );
        for (let i = 0; i < storeAcceptedFiles.length; i++) {
          formdata.append("attachments", storeAcceptedFiles[i]);
        }
        let data = {
          category: "realestate",
          main_category: "assets",
          used_space: used_space,
        };
        if (!!record) {
          data = { ...data, newData: { ...attomRealEstateData, ...values } };
          dispatch(categoryUpdateRecord(data))
            .unwrap()
            .then((response) => {
              if (response.code === 200) {
                onFormSubmit("realestate");
                resetForm();
                toast.success(response.message);
                props.dashboardAPi();
              } else if (response.code === 500 || response.code === 403) {
                // isManualAddress === false && setFieldValue("address", setAddressString(addressObj));
                setAddressObj(values.address);
                setValues({
                  ...values,
                  street_name: values.address.line1,
                  city: values.address.city,
                  state: values.address.state,
                  zip_code: values.address.postal_code,
                  ownership: values.ownership,
                });
                if (response.code === 403) {
                  setDuplicateAddressError(response.message);
                } else {
                  setStorageError(response.message);
                }
                const isToastDisplay =
                  response && response.toast ? response.toast : false;
                if (isToastDisplay) {
                  toast.error(response.message);
                }
              }
              setLoading(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
            });
        } else {
          data = { ...data, formdata: formdata };

          dispatch(categoryAdd(data))
            .unwrap()
            .then(async (response) => {
              if (response.code === 200) {
                const response = await dispatch(
                  get_user_storage({ id })
                ).unwrap();
                onFormSubmit("realestate");
                resetForm();
                // toast.success(response.message);
                props.dashboardAPi();
              } else if (response.code === 500 || response.code === 403) {
                // setFieldValue("address", setAddressString(addressObj));
                setAddressObj(values.address);
                setValues({
                  ...values,
                  street_name: values.address.line1,
                  city: values.address.city,
                  state: values.address.state,
                  zip_code: values.address.postal_code,
                  ownership: values.ownership,
                });
                // setInitialValues({ ...initialValues, street_name: values.address.line1, city: values.address.city, state: values.address.state, zip_code: values.address.postal_code, ownership: "" })
                // setIsAttomDataChecked(true)
                handleCheckChange();
                setAddressSelected(true);
                if (response.code === 403) {
                  setDuplicateAddressError(response.message);
                } else {
                  setStorageError(response.message);
                }
                const isToastDisplay =
                  response && response.toast ? response.toast : false;
                if (isToastDisplay) {
                  toast.error(response.message);
                }
              }
              setLoading(false);
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleChange = (e) => {
    setLoading(false);
    if (e.target.name === "market_value" || e.target.name === "street_name") {
      setErrorMessage("");
    }
    // setFieldValue(e.target.name, e.target.value);
    if (
      (e.target.name === "city" || e.target.name === "zip_code") &&
      e.target.value == ""
    ) {
      setErrorMessage("");
    }
    if (e.target.name === "ownership" && e.target.value > 100) {
      setFieldValue(e.target.name, "");
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
    setDuplicateFileError("");
  };

  // Upload File Handler
  const removeAcceptedFile = (params) => {
    let store = [...storeAcceptedFiles];
    store.splice(params, 1);
    setStoreAcceptedFiles(store);
    setDuplicateFileError("");
    setStorageError("");
  };
  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setDuplicateFileError("");
      setStorageError("");
      if (
        validateDuplicateFileName(
          acceptedFiles,
          storeAcceptedFiles,
          setDuplicateFileError
        )
      ) {
        setStoreAcceptedFiles([...acceptedFiles, ...storeAcceptedFiles]);
      }
    },
    [storeAcceptedFiles]
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    disabled: loading,
    onDropAccepted,
  });
  const files = storeAcceptedFiles.map((file, i) =>
    adjustLength(file.path, () => removeAcceptedFile(i))
  );
  const handleCheckChange = () => {
    if (values.street_name && values.zip_code.length === 5 && values.city) {
      setLoading(false);
      setAttomChange(true);
      if (
        record &&
        record.id &&
        !record.is_manual &&
        !IsAttomDataChecked === false
      ) {
        setShowConfirmEditModal(true);
      } else {
        setIsAttomDataChecked((prevState) => !prevState);
      }
    }
  };

  const handleUpdateMarketValue = (isUpdate) => {
    //on user update market value in edit
    setShowConfirmEditModal(false);
    setIsAttomDataChecked(!isUpdate);
    if (isUpdate === true) {
      // setFieldValue('address', '');
      // setFieldValue('market_value', '');
      // setFieldValue('ownership', '');
      // setFieldValue('share_market_value', '');
      // setFieldValue('annual_income', '');
      // setFieldValue('purchase_date', '');
      setErrorMessage("");
    }
  };

  useEffect(() => {
    if (isAttomDataLoad) {
      setErrorMessage("");
    }
  }, [values, isAttomDataLoad]);

  useEffect(() => {
    if (isAddressChange === true) {
      setLoading(false);
    }
  }, [isAddressChange]);

  return (
    <>
      <Modal
        show={showModal}
        backdrop="static"
        id="connect-your-institutions"
        aria-labelledby="contained-modal-title-vcenter"
        className="cap-dash-main-modal"
        centered
        keyboard={false}
        dialogClassName="cs-lg-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            {commonNames.RealEstate}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={handleSubmit}
            encType="multipart/form-data"
            className="realestate-form"
          >
            <Row>
              <Col xs={12} md={12}>
                <div className="cs-realestate-form">
                  <AddressSearch
                    errors={errors}
                    touched={touched}
                    values={values}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    setAddressObj={setAddressObj}
                    record={
                      linkAddressObj ? linkAddressObj : record && record.address
                    }
                    setStatus={setStatus}
                    status={status}
                    setFieldTouched={setFieldTouched}
                    addressObj={addressObj}
                    setIsAddressChange={setIsAddressChange}
                    setDuplicateAddressError={setDuplicateAddressError}
                    duplicateAddressError={duplicateAddressError}
                  />

                  {/* {errorMessage !== '' && (
                                        <span className={`form-error-msg default-light-body-text-s cs-danger ${isManualAddress ? 'cs-manual-address' : 'cs-google-address'}`}>
                                            {errorMessage}
                                        </span>)
                                    } */}
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div className="attom-data-lpd">
                  <div className="bottom-text-box">
                    <span className="checkboxREalestate">
                      <Form.Check
                        type="checkbox"
                        checked={IsAttomDataChecked}
                        onChange={handleCheckChange}
                        className="cs-form-check-box"
                        disabled={!addressSelected}
                        name="attomData"
                      />
                      <div className="d-flex">
                        <p
                          className="attom-mt cursor-pointer cs-neutral-90 default-regular-body-text-m"
                          onClick={handleCheckChange}
                        >
                          Use Attom Data to get the current market value of your
                          property
                        </p>
                        <Image
                          src={
                            require("../../../../../assets/images/other/attom-logo.svg")
                              .default
                          }
                          alt="Attomdata"
                          className="Attom-icon-img cursor-pointer"
                          onClick={handleCheckChange}
                          width={80}
                          height={14}
                        />
                      </div>
                    </span>
                  </div>

                  <p className="default-light-body-text-s cs-neutral-70 text-start">
                    By selecting this box, you agree to the Attom Data&nbsp;
                    <Link
                      className="cs-primary-main"
                      target="_blank"
                      to="https://www.attomdata.com/terms-of-use/"
                    >
                      Terms of Use.
                    </Link>
                    {errorMessage !== "" && (
                      <span
                        className={`form-error-msg default-light-body-text-s cs-danger `}
                      >
                        {errorMessage}
                      </span>
                    )}
                  </p>
                </div>
              </Col>
              <Col xs={12} md={`${IsAttomDataChecked ? 12 : 6}`}>
                <div
                  className={`${
                    IsAttomDataChecked ? "market-value-input" : ""
                  }`}
                >
                  <Form.Group
                    className={`${
                      IsAttomDataChecked ? "cs-disabled" : ""
                    } cs-form-group `}
                    data="Market Value"
                  >
                    {!IsAttomDataChecked && (
                      <Form.Label>Market Value</Form.Label>
                    )}
                    <div
                      className={
                        IsAttomDataChecked ? "total-value-input" : "value-input"
                      }
                    >
                      {IsAttomDataChecked ? (
                        <NumberFormat
                          thousandSeparator={true}
                          autoComplete="off"
                          placeholder=""
                          name="market_value"
                          onKeyDown={(e) => {
                            if (e.key === "." && e.target.value === "") {
                              e.preventDefault(); // Prevent typing another dot if there's already one
                            }
                          }}
                          prefix={"$ "}
                          value={
                            !isAttomDataLoad && values.market_value
                              ? values.market_value.toLocaleString(0)
                              : ""
                          }
                          // onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={IsAttomDataChecked}
                          maxLength={14}
                          allowNegative={false}
                          decimalScale={2}
                          className={
                            "dollar-symbol text-md-end form-control cs-input-field-active"
                          }
                        />
                      ) : (
                        <NumberFormat
                          thousandSeparator={true}
                          autoComplete="off"
                          placeholder=""
                          name="market_value"
                          onKeyDown={(e) => {
                            if (e.key === "." && e.target.value === "") {
                              e.preventDefault(); // Prevent typing another dot if there's already one
                            }
                          }}
                          value={
                            values.market_value
                              ? values.market_value.toLocaleString(0)
                              : ""
                          }
                          // onBlur={handleBlur}
                          onChange={handleChange}
                          disabled={IsAttomDataChecked}
                          maxLength={14}
                          allowNegative={false}
                          decimalScale={2}
                          className={
                            values.market_value
                              ? "dollar-symbol text-md-end form-control cs-input-field-active"
                              : "text-md-end form-control"
                          }
                        />
                      )}
                      {IsAttomDataChecked && isAttomDataLoad && (
                        <span className="cs-common-spinner cs-primary">
                          <CapsyncIcon title="loading-outlined" size="22" />
                        </span>
                      )}
                    </div>

                    {IsAttomDataChecked && (
                      <div className="market-value-box default-light-body-text-s cs-neutral-90">
                        Powered by{" "}
                        <Image
                          src={
                            require("../../../../../assets/images/other/attom-icon.svg")
                              .default
                          }
                          alt="Attomdata Logo"
                          className="attom-data-image"
                          width={16}
                          height={16}
                        />{" "}
                        Attomdata
                      </div>
                    )}

                    {!IsAttomDataChecked &&
                      errors.market_value &&
                      touched.market_value && (
                        <span className="form-error-msg default-light-body-text-s cs-danger">
                          {errors.market_value}
                        </span>
                      )}
                  </Form.Group>
                </div>
              </Col>

              <Col xs={12} md={6}>
                <Form.Group className="cs-form-group">
                  <Form.Label>Ownership (%)</Form.Label>
                  <NumberFormat
                    autoComplete="off"
                    className={
                      values.ownership
                        ? "form-control percentage-symbol cs-input-field-active"
                        : "form-control"
                    }
                    type="text"
                    placeholder=""
                    name="ownership"
                    onKeyDown={handleOwnershipKeyDown}
                    maxLength={5}
                    value={values.ownership}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    allowNegative={false}
                    decimalScale={2}
                  />
                  {errors.ownership && touched.ownership ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.ownership}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="cs-form-group cs-disabled">
                  <Form.Label>Share Market Value</Form.Label>
                  <NumberFormat
                    className={
                      values.market_value && values.ownership
                        ? "dollar-symbol text-md-end form-control cs-input-field-active"
                        : "text-md-end form-control"
                    }
                    placeholder=""
                    displayType={"input"}
                    thousandSeparator={true}
                    maxLength={14}
                    name="share_market_value"
                    value={
                      values.market_value && values.ownership
                        ? parseFloat(
                            (values.market_value.toString().replace(/,/g, "") *
                              values.ownership) /
                              100
                          ).toFixed(2)
                        : ""
                    }
                    disabled={true}
                    allowNegative={false}
                  />
                  {errors.share_market_value && touched.share_market_value && (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.share_market_value}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="cs-form-group">
                  <Form.Label>Annual Income</Form.Label>
                  <NumberFormat
                    thousandSeparator={true}
                    autoComplete="off"
                    placeholder="Optional"
                    name="annual_income"
                    maxLength={14}
                    className={
                      values.annual_income
                        ? "dollar-symbol text-md-end form-control cs-input-field-active"
                        : "text-md-end form-control"
                    }
                    value={values.annual_income}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    allowNegative={false}
                    decimalScale={2}
                    onKeyDown={(e) => {
                      if (e.key === "." && e.target.value === "") {
                        e.preventDefault(); // Prevent typing another dot if there's already one
                      }
                    }}
                  />
                  {errors.annual_income && touched.annual_income ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.annual_income}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="cs-form-group">
                  <Form.Label>{commonNames.PurchaseDate}</Form.Label>
                  <DatePicker
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    name="purchase_date"
                    value={values.purchase_date}
                    onChange={(e) => {
                      setFieldValue("purchase_date", e);
                      setLoading(false);
                    }}
                    // onBlur={handleBlur}
                    clearIcon={null}
                    className={
                      values.purchase_date
                        ? "cs-input-field-active select-date-range cs-date-picker-calender"
                        : "cs-date-picker-calender"
                    }
                    calendarIcon={
                      <span className="cs-neutral-90">
                        <CapsyncIcon title="calender-outlined" size="18" />
                      </span>
                    }
                    format="MM/dd/y"
                    maxDate={new Date()}
                    disabled={IsAttomDataChecked && values.purchase_date}
                    // maxDate={new Date()}
                  />
                  {errors.purchase_date && touched.purchase_date ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.purchase_date}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group className="cs-form-group">
                  <Form.Label>{commonNames.BalanceAs}</Form.Label>
                  <DatePicker
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    name="date_updated"
                    value={values.date_updated}
                    onChange={(e) =>
                      setFieldValue("date_updated", e) && setLoading(false)
                    }
                    // onBlur={handleBlur}
                    clearIcon={null}
                    className={
                      values.date_updated
                        ? "cs-input-field-active select-date-range cs-date-picker-calender"
                        : "cs-date-picker-calender"
                    }
                    calendarIcon={
                      <span className="cs-neutral-90">
                        <CapsyncIcon title="calender-outlined" size="18" />
                      </span>
                    }
                    format="MM/dd/y"
                    maxDate={new Date()}
                    disabled={IsAttomDataChecked}
                  />
                  {errors.date_updated && touched.date_updated ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.date_updated}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>
              <Col xs={12} md={12}>
                <Form.Group className="cs-form-group">
                  <Form.Label>Notes</Form.Label>
                  <textarea
                    autoComplete="off"
                    type="text"
                    placeholder="Optional"
                    name="notes"
                    value={values.notes}
                    className={
                      values.notes
                        ? "cs-input-field-active cs-textarea"
                        : "cs-textarea"
                    }
                    onChange={handleChange}
                    // onBlur={handleBlur}
                  ></textarea>
                </Form.Group>
              </Col>

              {!record && (
                <Col xs={12} md={12}>
                  <div className="realestate-upload-file">
                    <Form.Label className="default-regular-sub-heading-xs cs-neutral-100">
                      Upload Files
                    </Form.Label>
                    <div className="cs-upload-box">
                      <div
                        {...getRootProps({})}
                        className={`cs-upload-files cursor-pointer ${
                          loading && "cs-neutral-60 disabled"
                        }`}
                      >
                        <input
                          {...getInputProps()}
                          disabled={loading}
                          name="attachments"
                        />
                        <span className="cs-primary">
                          <CapsyncIcon title="upload-files-filled" size="28" />
                        </span>
                        <span className="default-regular-sub-heading-s cs-neutral-90">
                          <span className="cs-primary">Browse</span>&nbsp;or
                          drag file here
                        </span>
                        <p className="default-regular-sub-heading-xxs">
                          Supports PDF, PNG, JPG and JPEG format
                        </p>
                      </div>
                      <span className="form-error-msg default-light-body-text-s cs-danger">
                        {storageError || duplicateFileError}
                      </span>
                      <aside className="category-attachments-files">
                        <ul className="attachment-files cs-files">{files}</ul>
                      </aside>
                    </div>
                  </div>
                </Col>
              )}
              {/* <Row>
                                <Col className="text-md-end text-finish-btn-center attom-footer-text"> */}
              <div className="cs-modal-btn">
                <Button
                  className="cs-btn-secondary md-btn default-regular-h5"
                  onClick={onFormClose}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="cs-btn-primary md-btn default-regular-h5"
                  disabled={
                    loading ||
                    duplicateAddressError ||
                    storageError ||
                    duplicateFileError
                  }
                >
                  Save
                  {loading && (
                    <span className="cs-common-spinner cs-primary-main">
                      <CapsyncIcon title="loading-outlined" size="16" />
                    </span>
                  )}
                </Button>
              </div>
              {/* </Col>
                            </Row> */}
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
      <ConfirmEditModal
        showModal={showConfirmEditModal}
        onUpdateMarketValue={handleUpdateMarketValue}
      />
    </>
  );
};

export default RealEstate;
