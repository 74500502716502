import React, { useEffect } from "react";
import { useState } from "react";
import {
    Button,
    Row,
    Col,
    Container,
    Modal,
    Form,
    OverlayTrigger,
    Tooltip,
    Dropdown,
    ModalFooter,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
    access_pfs_add_user,
} from "../../../slices/pfs_access";
import { backAppURl } from "../../../applicationMode";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import { socket } from "../../../config/Socket";
import { Link } from "react-router-dom";
import { roleName } from "../../../config/commonRoleName";
import { checkDomainAccept } from "../../../util/common";
import { uniValidation } from "../liabilities/categoryForms/validationSchema";

const DATA_TYPE = {
    INDIVIDUAL: "Individual",
    FINANCIAL_ADVIRSOR: "Financial Advisor",
    TAX_PROFESSIONAL: "Professional",
    INDIVIDUAL_OR_FINANCIAL_ADVIRSOR: "Individual or Professional"
}

const AddModalCollaboration = ({ modalShow, setModalShow, fetchAllUsers, toastMessage }) => {
    const { user } = useSelector((state) => state.auth);
    const storeReduxData = useSelector((state) => state.message);
    const [showMessage, setShowMessage] = useState(false);
    const [dropdownErr, setDropdownErr] = useState("");
    const [type, setType] = useState(DATA_TYPE.INDIVIDUAL_OR_FINANCIAL_ADVIRSOR);
    const [loading, setLoading] = useState(false);
    const [makeDisable, setMakeDisable] = useState(false);
    const [accessPermission, setAccessPermission] = useState(1);
    const [validationError, setValidationError] = useState("");

    const dispatch = useDispatch(false);
    let dateType = [DATA_TYPE.INDIVIDUAL, DATA_TYPE.TAX_PROFESSIONAL];

    let id;
    if (window.localStorage.getItem("pfs_access_id") !== null) {
        id = window.localStorage.getItem("pfs_access_id");
    } else {
        id = user && user.user.id;
    }

    let from_user_role = localStorage.getItem("pfs_access_id")
        ? 1
        : user && user.user_role.role;
    let user_email = "";
    let dynamicURL = backAppURl;

    useEffect(() => {
        if (modalShow === false) {
            setValidationError("");
        }
    }, [modalShow])

    const validationSchema = yup.object().shape({
        to_firstName: yup
            .string()
            .strict()
            // .min(2, "Required at least 2 characters")
            .max(40, uniValidation.first_name.max)
            .required("Enter first name")
        // .matches(
        //     /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        //     'Name can only contain Latin letters.'
        // )
        // .matches(/^\S*$/, "Enter first name")
        //     .matches(
        //         /^\S(.*\S)?$/,
        //         "Avoid leading or trailing whitespace"
        // )
        ,
        to_lastName: yup
            .string()
            .strict()
            // .min(2, "Required at least 2 characters")
            .max(40, uniValidation.last_name.max)
            .required("Enter last name")
        // .matches(
        //     /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        //     'Name can only contain Latin letters.'
        // )
        // .matches(/^\S*$/, "Enter last name")
        //     .matches(
        //         /^\S(.*\S)?$/,
        //         "Avoid leading or trailing whitespace"
        // )
        ,
        // to_email: yup
        //     .string()
        //     .matches(/^\S*$/, "Whitespace is not allowed")
        //     .matches(/^[a-z0-9._%+-]{1,30}@[a-z0-9.-]+\.[a-z]{2,}$/, "Email not acceptable")
        //     .required(uniValidation.email.required).test("is-not-only-spaces", uniValidation.email.required, (value) => {
    //     return value.trim() !== "";
    //   })
        //     .email(uniValidation.email.validEmail)

        to_email: yup.string()
            // .matches(/^\S*$/, "Enter email address")
            .required(uniValidation.email.required).test("is-not-only-spaces", uniValidation.email.required, (value) => {
        return value.trim() !== "";
      })
            .email(uniValidation.email.validEmail)
            .test('no-more-than-30-before-at', 'Cannot accept more than 30 characters before @', value =>
                value ? value.split('@')[0].length <= 30 : true
            )
            .test('at-least-1-character-before-at', 'Enter at least 1 character before @', value =>
                value ? value.split('@')[0].length > 0 : true
            )
            .test('custom-email-format', uniValidation.email.validEmail, value => {
                return value ? uniValidation.email.allowedFormat.test(value) : true
            })
            .test('domain-acceptance', uniValidation.email.validEmail, value => {
                return checkDomainAccept(value);
            }),
    });

    const checkType = (event) => {
        setType(event);
        setDropdownErr("");
    };

    const initialValues = {
        to_firstName: "",
        to_lastName: "",
        to_email: "",
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        //---------This will be call on form submit---------//
        onSubmit: async (values) => {

            if (type === DATA_TYPE.INDIVIDUAL_OR_FINANCIAL_ADVIRSOR) {
                setDropdownErr("Select user type");
            } else {
                setLoading(true);
                setMakeDisable(true);
                setDropdownErr("");
                // values.from_user_id = id;
                // values.request_send_by_displayName = user.user.displayName
                // values.to_access_permission = accessPermission;
                // values.from_user_role = from_user_role;
                // values.to_user_role = type;
                const updatedValues = {
                    ...values,
                    from_user_id: id,
                    request_send_by_displayName: user.user.displayName,
                    to_access_permission: accessPermission,
                    from_user_role: from_user_role,
                    to_user_role: type == "Individual" ? roleName.individual : type === "Financial Advisor" ? roleName.financial_advisor : type === "Professional" ? roleName.tax_professional : ""
                };



                try {
                    const response = await dispatch(access_pfs_add_user(updatedValues));
                    if (response.payload.status === 'Error') {
                        setValidationError(response.payload.message);
                    } else {
                        setModalShow(false);
                        fetchAllUsers();
                        formik.resetForm();
                        setType(DATA_TYPE.INDIVIDUAL_OR_FINANCIAL_ADVIRSOR); // Reset the type state here
                        toastMessage(response.payload.message, "success");
                        socket.emit("notification", "message")
                        setMakeDisable(false);
                    }
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    setShowMessage(true);
                    setMakeDisable(false);
                }
            }
        },
    });


    const closeModalFunc = () => {
        setModalShow(false);
        setShowMessage(false);
        setType(DATA_TYPE.INDIVIDUAL_OR_FINANCIAL_ADVIRSOR)
        formik.resetForm();
    };


    const fetchPermission = (params) => {
        setAccessPermission(params);
    };

    return (
        <Modal
            className="manage-user-input-modal"
            show={modalShow}
            onHide={!modalShow}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            dialogClassName="cs-md-modal"
            centered
        >
            <Modal.Header>
                <Modal.Title className="default-semi-bold-h3">
                    Add New User
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={formik.handleSubmit}>
                    <Row>
                        <Col lg={12} sm={12}>
                            <Form.Group>
                                <Form.Label className="default-regular-sub-heading-xs cs-neutral-100">
                                    Select user type
                                </Form.Label>

                                <Dropdown className="cs-form-dropdown">
                                    <Dropdown.Toggle
                                        className="default-regular-sub-heading-s cs-neutral-70"
                                    >
                                        {type === "" ? DATA_TYPE.INDIVIDUAL : type}
                                        <span className="cs-neutral-90">
                                            <CapsyncIcon
                                                title="chevron-down-outlined"
                                                size="18"
                                            />
                                        </span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {dateType.map((dateItr) => (
                                            <Dropdown.Item
                                                key={dateItr}
                                                onClick={() => checkType(dateItr)}
                                                className={
                                                    dateItr === type
                                                        ? "dropdown-item dropdown-item-active default-regular-sub-heading-s cs-neutral-90"
                                                        : "default-regular-sub-heading-s cs-neutral-90 cs-content-title "
                                                }
                                            >
                                                {dateItr}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                    <span className="form-error-msg default-light-body-text-s cs-danger">
                                        {dropdownErr}
                                    </span>
                                    {validationError && (
                                        <div className="cs-msg default-regular-body-text-s cs-danger">
                                            <span className="icon">
                                                <CapsyncIcon title="info-filled" />
                                            </span>
                                            <span className="txt"> {validationError}</span>
                                        </div>
                                    )}
                                </Dropdown>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col className="col" lg={6} sm={6}>
                            <Form.Group
                                className="cs-form-group "
                                controlId="validationFormik03"
                            >
                                <Form.Label>First name</Form.Label>
                                <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    name="to_firstName"
                                    onKeyDown={(e) => {
                                        if (e.key === " " && e.target.selectionStart === 0) {
                                            e.preventDefault();
                                        }
                                    }}
                                    onChange={formik.handleChange}
                                    defaultValue={formik.values.to_firstName}
                                    // onBlur={formik.handleBlur}
                                    className={
                                        formik.values.to_firstName &&
                                        "cs-input-field-active"
                                    }
                                    // onKeyDown={(e) =>
                                    //     !/[a-zA-z]/.test(e.key) && e.preventDefault()
                                    // }
                                    maxLength={40}
                                />
                                {formik.errors.to_firstName &&
                                    formik.touched.to_firstName ? (
                                    <span className="form-error-msg default-light-body-text-s cs-danger">
                                        {formik.errors.to_firstName}
                                    </span>
                                ) : null}
                            </Form.Group>
                            {/* <div className="manageuser-firstname-input"> 
                            </div> */}
                        </Col>
                        <Col className="col" lg={6} sm={6}>
                            <Form.Group
                                className="cs-form-group"
                                controlId="validationFormik03"
                            >
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    type="text"
                                    autoComplete="off"
                                    name="to_lastName"
                                    onKeyDown={(e) => {
                                        if (e.key === " " && e.target.selectionStart === 0) {
                                            e.preventDefault();
                                        }
                                    }}
                                    onChange={formik.handleChange}
                                    defaultValue={formik.values.to_lastName}
                                    // onBlur={formik.handleBlur}
                                    className={
                                        formik.values.to_lastName &&
                                        "cs-input-field-active"
                                    }
                                    // onKeyDown={(e) =>
                                    //     !/[a-zA-z]/.test(e.key) && e.preventDefault()
                                    // }
                                    maxLength={40}
                                />
                                {formik.errors.to_lastName &&
                                    formik.touched.to_lastName ? (
                                    <span className="form-error-msg default-light-body-text-s cs-danger">
                                        {formik.errors.to_lastName}
                                    </span>
                                ) : null}
                            </Form.Group>
                            {/* <div className="manageuser-lastname-input">
                            </div> */}
                        </Col>
                    </Row>
                    {/* <div className="form-div-add-account manageuser-firstname-lastname">
                      </div> */}

                    <Row>
                        <Col lg={12} sm={12}>
                            <Form.Group
                                className="cs-form-group"
                                controlId="validationFormik03"
                            >
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    autoComplete="off"
                                    name="to_email"
                                    onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                                    onChange={formik.handleChange}
                                    // defaultValue={formik.values.to_email}
                                    value={formik.values.to_email}
                                    className={
                                        formik.values.to_email && "cs-input-field-active"
                                    }
                                />
                                {formik.errors.to_email && formik.touched.to_email && (
                                    <span className="form-error-msg default-light-body-text-s cs-danger">
                                        {formik.errors.to_email}
                                    </span>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* <div className="form-div-add-account manage-user-email-box ">
                      </div> */}

                    <Row>
                        <Col lg={12} sm={12}>
                            <Form.Group
                                className="cs-form-group"
                                controlId="validationFormik03"
                            >
                                <Form.Label>Permissions</Form.Label>
                                <div className="cs-manage-user-radio ">
                                    <OverlayTrigger
                                        key={"top"}
                                        placement={"top"}
                                        overlay={
                                            <Tooltip
                                                id={`tooltip-top`}
                                                className="cs-tooltip default-regular-body-text-m"
                                            >
                                                Viewing privilege only, no editing privileges.
                                            </Tooltip>
                                        }
                                    >
                                        <label className="cs-radio-btn-label">
                                            <input
                                                inline
                                                name="toggle"
                                                type="radio"
                                                defaultChecked
                                                onChange={() => fetchPermission(1)}
                                                onClick={() => setAccessPermission(1)}
                                                className="radio-button"
                                            />
                                            <span className="default-light-body-text-m cursor-pointer"
                                                onClick={() => setAccessPermission(1)}
                                            >
                                                View Only
                                            </span>
                                        </label>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        key={"top"}
                                        placement={"top"}
                                        overlay={
                                            <Tooltip
                                                id={`tooltip-top`}
                                                className="cs-tooltip default-regular-body-text-m"
                                            >
                                                Viewing and limited editing privileges.
                                            </Tooltip>
                                        }
                                    >
                                        <label className="cs-radio-btn-label">
                                            <input
                                                inline
                                                name="toggle"
                                                type="radio"
                                                // checked={accessPermission === 2}
                                                onChange={() => fetchPermission(2)}
                                                className="radio-button"
                                                onClick={() => setAccessPermission(2)}
                                            />
                                            <span className="default-light-body-text-m cursor-pointer"
                                                onClick={() => setAccessPermission(2)}

                                            >
                                                Standard
                                            </span>
                                        </label>
                                    </OverlayTrigger>
                                </div>
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* <div className="form-div-add-account manage-user-permission-box">
                      </div> */}
                </Form>

                {/* <div className="cs-form-group">
                  </div> */}
            </Modal.Body>
            <ModalFooter>
                <div className="cs-modal-btn">
                    {validationError.indexOf('plan') > -1 &&
                        <Link to='/individual/billing-subscription' class="cs-btn-tertiary default-regular-h5">Upgrade</Link>}
                    <div className="cs-btn-group">
                        <Button
                            className="cs-btn-secondary md-btn default-regular-h5"
                            onClick={closeModalFunc}
                        >
                            Cancel
                        </Button>{" "}
                        <Button
                            type="submit"
                            onClick={formik.handleSubmit}
                            className="cs-btn-primary md-btn default-regular-h5"
                            disabled={!formik.values.to_firstName || !formik.values.to_lastName || !formik.values.to_email || loading}
                        >
                            Send Invitation
                            {loading && (
                                <>
                                    &nbsp;
                                    <span className="spinner-border spinner-border-sm"></span>
                                </>
                            )}
                        </Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    )
}

export default AddModalCollaboration;