/* eslint-disable */
import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ModalTable from "./ModalTable";
import {
  wealth_companies_add,
  wealth_individuals_exist,
} from "../../../slices/wealthManager.slice";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import "./Company.css";
import ChooseIndiDropdown from "./ChooseIndiDropdown";
import { access_invitation_Pfs } from '../../../slices/pfs_access'
import useDebounce from "../../../customHooks/useDebounce";
import { socket } from "../../../config/Socket";
import { emailSchema } from "../../../util/common";

const AddModal = ({
  modalState,
  changeModalState,
  fetchAllCompanies,
  toastMessage,
  filteredCompanies,
}) => {
  const { user } = useSelector((state) => state.auth);
  const user_id = user && user.user.id;
  const user_displayName = user && user.user.displayName;
  const user_email = user && user.user.email;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [compName, setCompName] = useState("");
  const [partners, setPartners] = useState([
    { email: "", firstName: "", lastName: "", id: 0 },
  ]);
  const [submitted, setSubmitted] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [individuals, setIndividuals] = useState([])
  const [dropdownValidation, setDropdownValidation] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [companyValidation, setCompanyValidation] = useState({
    msg: "",
    color: "",
  });
  const [validation, setValidation] = useState({
    msg: "",
    color: "",
  });
  const [selectedIndividualFromCompany, setSelectedIndividualFromCompany] = useState([])
  const [selectedIndividualEmailArray, setSelectedIndividualEmailArray] = useState([])

  useEffect(() => {
    if (modalState.show) {
      getIndividuals();
    }
    if (!isChecked) {
      setSelectedIndividualFromCompany([]);
      setSelectedIndividualEmailArray([]);
      setDropdownValidation("");
    }
  }, [modalState.show, isChecked]);


  const companyName = (event) => {
    setValidation({
      msg: "",
      color: "",
    });
    setCompanyValidation("");
    setCompName(event.target.value);
  };

  const addFields = () => {
    setShowLoader(true);
    setSubmitted(false)
    setPartners([
      ...partners,
      { email: "", firstName: "", lastName: "", id: 0 },
    ]);
    // const isAllFieldsFilled = partners.every(
    //   (partner) =>
    //   (partner.email.trim() !== "" &&
    //     partner.firstName.trim() !== "" &&
    //     partner.lastName.trim() !== "")

    // );

    // setTimeout(() => {
    //   if (isAllFieldsFilled) {
    //     setShowLoader(false);
    // setPartners([
    //   ...partners,
    //   { email: "", firstName: "", lastName: "", id: 0 },
    // ]);
    //   } else {
    //     setValidation({
    //       msg: "*Please fill all partner details",
    //       color: "text-danger",
    //     });
    //   }
    // }, 1000);
  };
  const isEmailAlreadyInIndividualList = (currEmail) => {
    for (let j = 0; j < individuals.length; j++) {
      const individual = individuals[j]
      const email = individual.email ? individual.email : ""
      if (currEmail == email) {
        return true
      }
    }
    return false
  }

  const acceptedIndividuals = individuals.filter((individual) => individual.to_status === 1);
  const handleCheckboxClick = () => {
    if (acceptedIndividuals && acceptedIndividuals.length > 0) {
      setIsChecked(!isChecked)
    }
  }

  const handleEmailChange = (event, index) => {
    const value = event.target.value;
    fieldChange(value, index, "email");
  }

  const fieldChange = async (val, params, type) => {
    setSubmitted(false);
    let inputData = [...partners];
    setValidation({
      msg: "",
      color: "",
    });
    if (type === "email") {
      inputData[params].email = val;
      // Check email format validity
      let isValidEmail = false
      try {
        await emailSchema.validate(val);
        isValidEmail = true
        inputData[params].emailError = ""
        setPartners(inputData);
      } catch (error) {
        isValidEmail = false
        inputData[params].emailError = error.message
        setPartners(inputData);
      }
      if (isValidEmail && val === user_email) {
        inputData[params].emailError = "Can not send invitation to yourself";
      } else if (isValidEmail && isEmailAlreadyInIndividualList(val)) {
        inputData[params].emailError = "User Already Exists in Individuals list";
      }
      if (isValidEmail) {
        checkUserExist(val, params)
      }
    } else if (type === "firstName") {

      inputData[params].firstName = val;
      // const hasLeadingTrailingWhitespace = /^\s|\s$/.test(val);
      // const hasNonWhitespaceCharacter = /\S/.test(val);
      // const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(val);
      if (val === "") {
        inputData[params].fnameError = "";
      }
      // else if (hasLeadingTrailingWhitespace || !hasNonWhitespaceCharacter) {
      //   inputData[params].fnameError =
      //     "Avoid leading or trailing whitespace";
      // }
      // else if (hasSpecialCharacter) {
      //   inputData[params].fnameError =
      //     "Special characters are not allowed";
      // } 
      else {
        inputData[params].fnameError = "";
      }
      setPartners(inputData);
    } else if (type === "lastName") {
      inputData[params].lastName = val;
      // const hasLeadingTrailingWhitespace = /^\s|\s$/.test(val);
      // const hasNonWhitespaceCharacter = /\S/.test(val);
      // const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(val);
      if (val === "") {
        inputData[params].lnameError = "";
      }
      // else if (hasLeadingTrailingWhitespace || !hasNonWhitespaceCharacter) {
      //   inputData[params].lnameError =
      //     "Enter last name";
      // }
      // else if (hasSpecialCharacter) {
      //   inputData[params].lnameError =
      //     "Special characters are not allowed";
      // }
      else {
        inputData[params].lnameError = "";
      }
      setPartners(inputData);
    } else {
      inputData[params].lastName = val;
      setPartners(inputData);
    }
    const isAllFieldsFilled = partners.every(
      (partner) =>
      (partner.email.trim() !== "" &&
        partner.firstName.trim() !== "" &&
        partner.lastName.trim() !== "")
    );
    const isAnyFieldFilled = partners.some(
      (partner) =>
        partner.email.trim() !== "" ||
        partner.firstName.trim() !== "" ||
        partner.lastName.trim() !== ""
    );
    if (!isAllFieldsFilled && isChecked && !isAnyFieldFilled) {
      const removeError = partners.map((val) => {
        return {
          ...val,
          fnameError: "",
          lnameError: "",
          emailError: "",
        }
      })
      setPartners(removeError);
    }
  };

  useEffect(() => {
    const isAllFieldsFilled = partners.every(
      (partner) =>
      (partner.email.trim() !== "" &&
        partner.firstName.trim() !== "" &&
        partner.lastName.trim() !== "")
    );
    const isAnyFieldFilled = partners.some(
      (partner) =>
        partner.email.trim() !== "" ||
        partner.firstName.trim() !== "" ||
        partner.lastName.trim() !== ""
    );
    if (!isAllFieldsFilled && isChecked && !isAnyFieldFilled) {
      const removeError = partners.map((val) => {
        return {
          ...val,
          fnameError: "",
          lnameError: "",
          emailError: "",
        }
      })
      setPartners(removeError);
    }
  }, [isChecked])

  const checkUserExist = useCallback((val, params) => {
    let inputData = [...partners];
    if (val !== "") {
      dispatch(wealth_individuals_exist({ val: val }))
        .unwrap()
        .then((response) => {
          inputData[params].id = response.user_id;
          setPartners(inputData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
    [partners]
  );

  const removeField = (params) => {
    if (partners.length > 1) {
      let fetchData = [...partners];
      fetchData.splice(params, 1);
      setPartners(fetchData);
    }
  };

  const sendInvitation = () => {
    setSubmitted(true);
    const isAllFieldsFilled = partners.every(
      (partner) =>
      (partner.email.trim() !== "" &&
        partner.firstName.trim() !== "" &&
        partner.lastName.trim() !== "")
    );

    if (isChecked && selectedIndividualFromCompany.length === 0) {
      setDropdownValidation("Please select atleast one individual");
      return;
    }
    const isAnyFieldFilled = partners.some(
      (partner) =>
        partner.email.trim() !== "" ||
        partner.firstName.trim() !== "" ||
        partner.lastName.trim() !== ""
    );

    if ((!isAllFieldsFilled && !isChecked) || (isAnyFieldFilled && isChecked && !isAllFieldsFilled)) {
      const error = partners.map((val) => {
        return {
          ...val,
          fnameError: val.firstName.trim() === "" ? "Enter first name" : val.fnameError || "",
          lnameError: val.lastName.trim() === "" ? "Enter last name" : val.lnameError || "",
          emailError: val.email.trim() === "" ? "Enter email" : val.emailError || "",
        }
      })
      setPartners(error);
      return
    }
    let flag = 0;
    let fetchRawPartners = [];

    partners.forEach((new_itr) => {
      fetchRawPartners.push(new_itr.email);
    });

    let dummyfetchRawPartners = new Set(fetchRawPartners);
    if (Array.from(dummyfetchRawPartners).length !== fetchRawPartners.length) {
      setValidation({
        msg: "*You cannot add same email more than once.",
        color: "text-danger",
      });
      return false;
    }

    partners.forEach((itr, index) => {
      if ((itr.email === "" || itr.firstName === "" || itr.lastName === "") && index > 0) {
        flag++;
      }
    });

    if (compName === "") {
      flag++;
      setCompanyValidation({
        msg: "Enter company name",
        color: "text-danger",
      });
    } else {
      setCompanyValidation({
        msg: "",
        color: "",
      });
      // if (
      //   // /^\s|\s$/.test(compName) ||
      //   // /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(compName)
      //   /^\s|\s$/.test(compName) ||
      //   /^[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(compName) ||
      //   /[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]\s/.test(compName) ||  // Check for special characters followed by a space
      //   /\s[!@#$%^&*()_+{}\[\]:;<>,.?~\\\-=/]/.test(compName) ||  // Check for space followed by special characters
      //   /(\.\s|\s\.)/.test(compName) ||  // Check for dot followed by space or space followed by dot
      //   /^(\.|\s)|(\.|\s)$/.test(compName)  // Check for dot or space at the start or end
      // ) {
      //   flag++;
      //   setCompanyValidation({
      //     msg: "*Special characters and space not allowed",
      //     color: "text-danger",
      //   });
      // } 
      // else {
      //   setCompanyValidation({
      //     msg: "",
      //     color: "",
      //   });
      // }
    }

    // Check for invalid emails
    const invalidEmails = partners.some(
      (partner) => partner.emailError && partner.emailError !== ""
    );

    if (invalidEmails) {
      flag++;
    }
    // Check for invalid First Name
    const invalidFName = partners.some(
      (partner) => partner.fnameError && partner.fnameError !== ""
    );

    if (invalidFName) {
      flag++;
    }
    // Check for invalid Last Name
    const invalidLName = partners.some(
      (partner) => partner.lnameError && partner.lnameError !== ""
    );

    if (invalidLName) {
      flag++;
    }

    const isPartnerEmpty = () => {
      if (partners.length === 1 && (partners[0].email === "" && partners[0].firstName === "" && partners[0].lastName === "")) {
        return true;
      }
      return false;
    }
    let storeData = {
      partners: isPartnerEmpty() === true ? [] : partners,
      company_name: compName,
      user_id: user_id,
      request_send_by_displayName: user_displayName,
      request_send_by_email: user_email,
      selected_individual: selectedIndividualEmailArray
    };

    let checkCompName = filteredCompanies.filter(
      (record) => record.companyName === compName
    );

    if (checkCompName.length !== 0) {
      flag++;
      setCompanyValidation({
        msg: "A company with this name already exists",
        color: "text-danger",
      });
    } else {
      // setValidation({
      //   msg: "*Some of the fields are empty",
      //   color: "text-danger",
      // });
    }

    if (flag === 0 && !invalidEmails) {
      setValidation({
        msg: "",
        color: "",
      });
      setLoading(true);
      dispatch(wealth_companies_add(storeData))
        .unwrap()
        .then((response) => {
          if (response.code === 200) {
            changeModalState(false, true);
            socket.emit("notification", "message")
            fetchAllCompanies();
            toastMessage("This company has been created successfully.", "success");
            setPartners([{ email: "", firstName: "", lastName: "" }]);
            setCompName("");
            setValidation({
              msg: "",
              color: "",
            });
            setLoading(false);
            setSelectedIndividualEmailArray([]);
            setSelectedIndividualFromCompany([]);
            setIsChecked(false)
          } else if (response.code === 500 && response.invalid_emails && response.invalid_emails.length > 0) {
            let data = partners.map((val) => {
              return {
                ...val,
                emailError: response.invalid_emails.includes(val.email) ? response.message : ""
              }
            })
            setPartners(data)
            setLoading(false)
          } else {
            changeModalState(false, true);
            setLoading(false);
            setPartners([{ email: "", firstName: "", lastName: "" }]);
            setCompName("");
            setValidation({
              msg: "",
              color: "",
            });
            setSelectedIndividualEmailArray([]);
            setSelectedIndividualFromCompany([]);
            setIsChecked(false)
            toastMessage(response.message, "error");
          }
        })
        .catch((err) => {
          setLoading(false);
          toastMessage(err.message, "error");
        });
    }
  };

  const closeModal = () => {
    changeModalState(false, true);
    setSelectedIndividualFromCompany([]);
    setSelectedIndividualEmailArray([]);
    setIsChecked(false);
    setPartners([{ email: "", firstName: "", lastName: "" }]);
    setValidation({
      msg: "",
      color: "",
    });
    setCompName("");
    setCompanyValidation({
      msg: "",
      color: "",
    });
  };

  const getIndividuals = () => {
    dispatch(
      access_invitation_Pfs({
        email: user && user.user.email,
      })
    )
      .unwrap()
      .then((response) => {
        setIndividuals(response ? response : []);
      })
      .catch((err) => {
        toastMessage("access_invitation_Pfs :" + err.message, "error");
      });
  };

  const handleChooseIndiDropdown = () => {
    console.log("dropdown click")
    getIndividuals()
  }

  const everyFieldShouldBeFilled = (partner) => partner.email === "" || partner.firstName === "" || partner.lastName === ""
  const everyFieldShouldBeEmpty = (partner) => {
    const result = (partner.email === "" && partner.firstName === "" && partner.lastName === "") && isChecked && selectedIndividualFromCompany.length > 0
    return result == true ? false : true
  }
  return (
    <Fragment>
      <Modal
        show={modalState.show}
        onHide={!modalState.show}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal company-add-popup"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            Create Company
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Fragment>
            <div className="add-company-form">
              <Row>
                <Col md={12}>
                  <Form.Label>Company name</Form.Label>
                  <Form.Group className="cs-form-group">
                    <Form.Control
                      type="text"
                      onChange={companyName}
                      maxLength={70}
                      value={compName}
                      onKeyDown={(e) => {
                        if (e.key === " " && e.target.selectionStart === 0) {
                          e.preventDefault();
                        }
                      }}
                      className={compName && "cs-input-field-active"}
                      autoComplete="off"
                    />
                    <span
                      className={
                        companyValidation.color &&
                        "form-error-msg default-light-body-text-s cs-danger"
                      }
                    >
                      {companyValidation.msg}
                    </span>
                  </Form.Group>
                </Col>
              </Row>
              {partners.map((iterator, index) => {
                return (
                  <Fragment>
                    <Row>
                      <span className="company-modal-line">
                        {partners.length > 1 && index !== 0 && (
                          <hr className="cs-neutral-60" />
                        )}
                      </span>
                      {partners.length > 1 && index !== 0 && (
                        <div className="company-popup-remove">
                          <span
                            className="cs-danger"
                            onClick={() => removeField(index)}
                          >
                            <CapsyncIcon title="delete-filled" size="16" />
                          </span>
                          <span
                            className="default-regular-h5 cs-danger"
                            onClick={() => removeField(index)}
                          >
                            Remove
                          </span>
                        </div>
                      )}

                      <span className="default-regular-body-text-m cs-neutral-80 partner-detail">Enter partners details</span>

                      <Col className="col" md={6}>
                        <Form.Group
                          className={`cs-form-group${iterator.fnameError ? "" : ""
                            }`}
                          controlId={`validationFormik03-${index}`}
                        >
                          <Form.Label>First name</Form.Label>
                          <Form.Control
                            type="text"
                            autoComplete="off"
                            value={iterator.firstName}
                            className={
                              iterator.firstName && "cs-input-field-active"
                            }
                            onChange={(event) =>
                              fieldChange(event.target.value, index, "firstName")
                            }
                            onKeyPress={(e) =>
                              /[0-9]/.test(e.key) && e.preventDefault()
                            }
                            onKeyDown={(e) => {
                              if (e.key === " " && e.target.selectionStart === 0) {
                                e.preventDefault();
                              }
                            }}
                            maxLength={40}
                          />
                          {submitted && iterator.fnameError && (
                            <span className="form-error-msg default-light-body-text-s cs-danger">
                              {iterator.fnameError}
                            </span>
                          )}
                          {submitted && index > 0 && iterator.firstName == "" && ("Enter first name") && !iterator.fnameError && (
                            <span className="form-error-msg default-light-body-text-s cs-danger">
                              Enter first name
                            </span>
                          )}

                        </Form.Group>
                      </Col>
                      <Col className="col" md={6}>
                        <Form.Group
                          className={`cs-form-group ${iterator.lnameError ? "" : ""
                            }`}
                          controlId={`validationFormik03-${index}`}
                        >
                          <Form.Label>Last name</Form.Label>
                          <Form.Control
                            type="text"
                            value={iterator.lastName}
                            className={
                              iterator.lastName && "cs-input-field-active"
                            }
                            autoComplete="off"
                            onChange={(event) =>
                              fieldChange(event.target.value, index, "lastName")
                            }
                            onKeyPress={(e) =>
                              /[0-9]/.test(e.key) && e.preventDefault()
                            }
                            onKeyDown={(e) => {
                              if (e.key === " " && e.target.selectionStart === 0) {
                                e.preventDefault();
                              }
                            }}
                            maxLength={40}
                          />
                          {submitted && iterator.lnameError && (
                            <span className="form-error-msg default-light-body-text-s cs-danger">
                              {iterator.lnameError}
                            </span>
                          )}
                          {submitted && index > 0 && iterator.lastName == "" && !iterator.lnameError && (
                            <span className="form-error-msg default-light-body-text-s cs-danger">
                              Enter last name
                            </span>
                          )}

                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <Form.Group
                          className={`cs-form-group last-email-field${iterator.emailError ? "" : ""
                            }`}
                          controlId={`validationFormik03-${index}`}
                        >
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="text"
                            autoComplete="off"
                            maxLength={100}
                            value={iterator.email}
                            onKeyDown={(e) => e.code === 'Space' && e.preventDefault()}
                            className={iterator.email && "cs-input-field-active"}
                            onChange={(event) => handleEmailChange(event, index)}
                          // onBlur={(event) =>
                          //   checkUserExist(event.target.value, index)
                          // }
                          />
                          {submitted && iterator.emailError && (
                            <span className="form-error-msg default-light-body-text-s cs-danger">
                              {iterator.emailError}
                            </span>
                          )}
                          {submitted && index > 0 && iterator.email === "" && !iterator.emailError && (
                            <span className="form-error-msg default-light-body-text-s cs-danger">
                              Enter email
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Fragment>
                );
              })}
            </div>

            <Fragment>
              {partners.every(
                (partner) =>
                  partner.email.trim() !== "" &&
                  partner.firstName.trim() !== "" &&
                  partner.lastName.trim() !== "" &&
                  partner.emailError === "" &&
                  partner.fnameError === "" &&
                  partner.lnameError === ""

              )}
            </Fragment>

            <div className="associate-add-more">
              <Button
                type="submit"
                // disabled={validation.msg.length != 0}
                className="default-regular-h5 cs-btn-icon-tertiary"
                onClick={addFields}
              >
                <span className="cs-primary">
                  <CapsyncIcon title="add-filled" size="16" />
                  Add more partners
                </span>
              </Button>
              <span
                className={
                  validation.color &&
                  "form-error-msg default-light-body-text-s cs-danger"
                }
              >
                {validation.msg}
              </span>
              <Form.Group onClick={handleCheckboxClick} >
                <Form.Check
                  type="checkbox"
                  checked={isChecked}
                  disabled={acceptedIndividuals && acceptedIndividuals.length === 0}
                  label="Associate already added individual with company"
                  className="cs-form-check-box "
                />
              </Form.Group>
              {isChecked && <ChooseIndiDropdown data={individuals}
                selectedIndividualFromCompany={selectedIndividualFromCompany}
                setSelectedIndividualFromCompany={setSelectedIndividualFromCompany}
                setSelectedIndividualEmailArray={setSelectedIndividualEmailArray}
                selectedIndividualEmailArray={selectedIndividualEmailArray}
                dropdownValidation={dropdownValidation}
                setDropdownValidation={setDropdownValidation}
              />}
              {isChecked && <ModalTable tableLabel="Partners Details" dataArray={selectedIndividualFromCompany} />}
            </div>


          </Fragment>

        </Modal.Body>
        <Modal.Footer>
          <div className="cs-modal-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              className="cs-btn-primary md-btn default-regular-h5"
              onClick={sendInvitation}
              // disabled={compName.length == 0 || partners.every(
              //   (partner) => {
              //     return everyFieldShouldBeEmpty(partner) && everyFieldShouldBeFilled(partner) //=== true ? (isChecked && selectedIndividualFromCompany > 0) : true
              //   })}
              disabled={loading || compName.length == 0}
            >
              Send Invitation
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default AddModal;
