import React from "react";
import { Form, Row, Col, Image } from "react-bootstrap";
import SubMenuHeader from "../SubMenuHeader";
import { Link } from "react-router-dom";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import SubMethodsList from "../../SubMethodsList";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";

// sequence No. two  (Asmita date-29th June => Do not remove this line)
const MethodIdTwo = (props) => {


  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Accredited Investor",
      url: `/individual/verifyinvestor/accreditedInvestor/${props.verificationType}/${props.unique_code}`,
    },
  ];



  return (
    <>
      {props.is_loader.assets === true || props.is_loader.liabilities === true ? <SkeletonAllSubMethods /> :
        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="Issuer Insider"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>
              <SubMenuHeader />
            </div>
            <div className="method-section">
              <div className="default-regular-body-text-l cs-neutral-70"> <SubMethodsList methodId={2} /></div>
              <div className="cs-divider"></div>
              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert} />

                <div className="row-spacing">
                  <p className="default-regular-body-text-l cs-neutral-70">
                    In order to verify your accreditation we need you to upload
                    evidence. Please upload the appropriate files below and black-out
                    any sensitive information.
                  </p>
                  <p className="default-medium-sub-heading-m cs-danger">
                    Upon a successful verification, the expiration date of the
                    verification letter will be based on the evidence provided to
                    reviewers, e.g. if a 3rd party letter or a monthly statement is 2
                    months old at the time of submission for review the verification
                    letter will expire in 1 month.
                  </p>
                </div>
                <Form.Group controlId="formFile" >
                  {props.setShowTrustUploadBlock && <div>
                    <Form.Label className="default-regular-sub-heading-s cs-neutral-90">Please upload evidence of who all the equity owners of the trust are (by uploading your trust documentation or this <Link className="cs-neutral-100" to="https://s3.amazonaws.com/static-capsync.com/Verify-Investor-files/All-Owners-Accredited-Officer-Certificate.pdf" target="_blank"><b>Officer’s Certificate</b></Link> along with a document to show ownership percentages). In a revocable trust, typically, the grantors are the equity owners. In an irrevocable trust, determination of the equity owners can be complicated. Please contact  <Link target="_blank" to="mailto:accreditation@capsync.com">accreditation@capsync.com</Link> for assistance.</Form.Label>
                    <UploadFileBlock
                      files={props.files2}
                      setOpenModal={props.setOpenModal2}
                      mediaAlert={props.mediaAlert}
                      deleteFile={props.deleteFile2}
                      showFile={props.showFile2}
                      pfsLink={props.pfsLink}
                      pfsName={props.pfsName}
                      pfsDelete={props.pfsDelete}
                      showDeleteSpinner={props.showDeleteSpinner}
                      showPfsLink={props.showPfsLink2}
                    />
                  </div>}
                  <Form.Label>
                    <div>
                      <p className="default-regular-sub-heading-s cs-neutral-90">
                        Upload evidence of position claimed: &nbsp;
                        <span className="default-regular-sub-heading-s cs-neutral-70">
                          (e.g. Incumbency Certificate or Officer's Certificate no
                          more than 90 days old) — Sample Officer's Certificate
                          &nbsp;
                          <Link
                            to="https://s3.amazonaws.com/static-capsync.com/Verify-Investor-files/OfficerCertificate-including-KE-and-FO.pdf"
                            target="_blank"
                            className="default-regular-sub-heading-s cs-neutral-100"
                          >
                            Here
                          </Link>
                        </span>
                      </p>
                    </div>
                  </Form.Label>
                  {/* <Row>
                <Col className="ml-vi-10-px">
                  <div
                    className="py-4 sub-method-open-modal cursor-pointer vi-font-size-pdf"
                    onClick={() => props.setOpenModal(true)}
                  >
                    <Image
                      alt=""
                      className="mb-8-px"
                      src={require("../../../../../../../assets/icons/svg/outlined/upload-outlined.svg")}
                    />
                    <p className="mb-5-px cap-drag-drop-color">
                      Drag and Drop Files, or&nbsp;
                      <span className="cap-main-color">Browse</span>
                    </p>
                    <p className="cap-primary-color">
                      Support PDF, PNG, JPG and JPEG Files
                    </p>
                    {props.mediaAlert !== "" ? (
                      <p className="cap-error">{props.mediaAlert}</p>
                    ) : (
                      <aside>
                        <ul className="cap-list-style-type">{props.files}</ul>
                      </aside>
                    )}
                  </div>
                </Col>
              </Row> */}
                  <div className={props.uploadFileOneError ? "investor-error" : ""} >
                    <UploadFileBlock
                      files={props.files}
                      setOpenModal={props.setOpenModal}
                      mediaAlert={props.mediaAlert}
                      deleteFile={props.deleteFile}
                      showFile={props.showFile}
                      pfsLink={props.pfsLink}
                      pfsName={props.pfsName}
                      pfsDelete={props.pfsDelete}
                      showDeleteSpinner={props.showDeleteSpinner}
                      showPfsLink={props.showPfsLink}
                      uploadError={props.uploadError}
                      showError={props.showError}
                      uploadFileOneError={props.uploadFileOneError}

                    />

                  </div>
                </Form.Group>
                <Form.Group

                  controlId="validationFormik01"
                >
                  <Form.Label >
                    <p className="default-regular-sub-heading-s cs-neutral-90">
                      Please enter the investment name or deal name of the company
                      of which you are an officer. (Note the name you entered will
                      appear on your accreditation letter and the letter will be
                      solely for the purpose of this investment.)
                    </p>
                  </Form.Label>
                  <Row className="cs-form-group row-spacing">
                    <Col lg={5}>
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        name="Name"
                        // onKeyPress={(e) =>
                        //   !/[a-zA-z]/.test(e.key) && e.preventDefault()
                        // }
                        onChange={(e) => props.getNumberValue(e.target.value)}
                        value={props.formValue}
                        className={
                          props.formValue.trim().length > 0 ? "cs-input-field-active" : props.checkInvestorError ? "investor-error" : ""}
                        maxLength={50}
                      />
                      {props.checkInvestorError && <span className="default-regular-sub-heading-xs cs-danger">Enter the investment name or deal name of the company of which you are an officer.
                      </span>}

                    </Col>
                  </Row>

                </Form.Group>
                <NotesBlock setNotesValue={props.setNotesValue} />
              </Form>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default MethodIdTwo;
