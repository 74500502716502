import React from 'react'
import { Row, Col } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import "./QuickBooks.css"

const SkeletonQuickBook = () => {
    return (
        <>
            <div className='skeleton-breadcrumb'>
                <div className='skeleton-breadcrumb-1'> <Skeleton width={50} /></div>
                <div className='skeleton-breadcrumb-2'> <Skeleton width={20} /></div>
                <div className='skeleton-breadcrumb-3'><Skeleton width={50} /></div>
            </div>

            <div className="cs-section-topbar">
                <div className="cs-title">
                    <h1><Skeleton width={200} height={30} /></h1>
                    <div><Skeleton width={100} /></div>
                </div>
            </div>

            <div className="category-cards for-des">
                <Row>
                    {Array(4).fill(0).map((item, idx) => (<Col key={idx} md={3} lg={3}>
                        <div className="category-card skeleton-cards">
                            <div className="card" >
                                <div>
                                    <Skeleton width={72} height={72} circle />
                                </div>
                                <div className="card-item">
                                    <span className="default-regular-h4 cs-neutral-90" >  <Skeleton width={150} /></span>
                                </div>
                            </div>
                        </div>
                    </Col>))}
                </Row>
            </div>

            <div className="category-cards for-mob">
                <Row>
                    {Array(4).fill(0).map((item, idx) => (<Col key={idx} md={3} lg={3}>
                        <div className="category-card skeleton-cards">
                            <div className="card" >
                                <div>
                                    <Skeleton width={50} height={50} circle />
                                </div>
                                <div className="card-item">
                                    <span className="default-regular-h4 cs-neutral-90" >  <Skeleton width={55} /></span>
                                </div>
                            </div>
                        </div>
                    </Col>))}
                </Row>
            </div>

            <div className="transaction-search-bar skeleton-qbo">
                <Row className="align-items-center">
                    <Col lg={5}>
                        <div className="transaction-period-select">
                            <div className="cs-report-period">
                                <div className="cs-common-add-dropdown for-des">
                                    <Skeleton width={180} height={30} />
                                </div>
                                <div className="cs-common-add-dropdown for-mob">
                                    <Skeleton width="100" height={30} />
                                </div>
                                <div className='for-des'>
                                    <div className="showdate">
                                        <Skeleton width={180} height={30} />
                                        <span className="default-regular-h6 cs-neutral-70"><Skeleton width={20} height={20} /></span>
                                        <Skeleton width={180} height={30} />
                                    </div>
                                </div>
                                <div className='for-mob'>
                                    <div className="showdate">
                                        <Skeleton width={100} height={30} />
                                        <span className="default-regular-h6 cs-neutral-70"><Skeleton width={20} height={20} /></span>
                                        <Skeleton width={100} height={30} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5}>
                        <div className="qbo-select-row cs-common-add-dropdown for-des">
                            <div className="cs-qbo-display">
                                <Skeleton width={200} height={30} />
                            </div>
                        </div>
                        <div className="cs-qbo-display for-mob">
                            <Skeleton width="100" height={30} />
                        </div>
                        <div className="cs-qbo-display for-mob">
                            <Skeleton width="100" height={30} />
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="text-end">
                            <Skeleton width={150} height={30} />
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default SkeletonQuickBook
