import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import CapsyncIcon from './CapsyncIcon';

const Pagination = ({ rowStartIdx, setRowStartIdx, rowsPerPage, setRowsPerPage, paginationDropdownItems, paginationList }) => {
    const [dropdownDisplay, setDropdownDisplay] = useState(rowsPerPage);
    const currentNumberOfRows = (rowStartIdx, rowsPerPage, lengthOfCompanies) => {
        if (rowStartIdx + rowsPerPage <= lengthOfCompanies) {
            return rowsPerPage;
        } else {
            return lengthOfCompanies - rowStartIdx;
        }
    }

    useEffect(() => {
        if (currentNumberOfRows(rowStartIdx, rowsPerPage, paginationList ? paginationList.length : 0) < 1 && rowStartIdx > 0) {
            setRowStartIdx(rowStartIdx - rowsPerPage);
        }
    }, [rowStartIdx, rowsPerPage, paginationList])

    const handlePaginationDropdownClick = (dropdownValue) => {
        setDropdownDisplay(dropdownValue)
        setRowStartIdx(0);
        if (dropdownValue === 'All') {
            setRowsPerPage(paginationList.length);
            return;
        }
        setRowsPerPage(dropdownValue);
    }

    const handleFirstPageClick = () => {
        setRowStartIdx(0);
        // Assuming your rowsPerPage is already set appropriately
    };

    const handleLastPageClick = () => {
        const totalRows = paginationList.length;
        const lastPageStartIdx = Math.floor((totalRows - 1) / rowsPerPage) * rowsPerPage;
        setRowStartIdx(lastPageStartIdx);
    };
    return (
        <div className="cs-custom-pagination">
            <div className="cs-common-add-dropdown">
                <span className="default-light-body-text-m cs-neutral-80">Rows per page:</span>
                <Dropdown>
                    <Dropdown.Toggle className="default-light-body-text-m cs-neutral-80">
                        {dropdownDisplay}
                        <CapsyncIcon title="chevron-down-outlined" size="12" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu onSelect={(e) => console.log(e)}>
                        {paginationDropdownItems.map((noOfRowsPerPage) => {
                            return (
                                <Dropdown.Item
                                    className={noOfRowsPerPage === dropdownDisplay && "cs-selected-dropdown"}
                                    key={noOfRowsPerPage}
                                    onClick={() => handlePaginationDropdownClick(noOfRowsPerPage)}
                                    value={noOfRowsPerPage}>{noOfRowsPerPage}
                                </Dropdown.Item>);
                        })}
                        <Dropdown.Item onClick={() => handlePaginationDropdownClick('All')} className={"All" === dropdownDisplay && "cs-selected-dropdown"}>All</Dropdown.Item>
                    </Dropdown.Menu>

                </Dropdown>
            </div>
            <div className="default-light-body-text-m cs-neutral-80">
                <p>{rowStartIdx + 1} - {rowStartIdx + currentNumberOfRows(rowStartIdx, rowsPerPage, paginationList.length)} of {paginationList.length}</p>

            </div>

            <div className="cs-pagination-arrow">
                <Button
                    onClick={handleFirstPageClick}
                    disabled={rowStartIdx === 0}
                    className={rowStartIdx === 0 ? "cs-neutral-50" : "cs-neutral-100"}>
                    <CapsyncIcon title="chevron-left-skip-outlined" size="18" />
                </Button>
                <Button
                    onClick={() => setRowStartIdx(rowStartIdx - rowsPerPage)} disabled={rowStartIdx === 0}
                    className={rowStartIdx === 0 ? "cs-neutral-50" : "cs-neutral-100"}>
                    <CapsyncIcon title="chevron-left-outlined" size="18" />
                </Button>
                <Button onClick={() => setRowStartIdx(rowStartIdx + rowsPerPage)}
                    className={!(rowStartIdx + rowsPerPage < paginationList.length) ? "cs-neutral-50" : "cs-neutral-100"}
                    disabled={!(rowStartIdx + rowsPerPage < paginationList.length)}>
                    <CapsyncIcon title="chevron-right-outlined" size="18" />
                </Button>
                <Button
                    onClick={handleLastPageClick}
                    className={!(rowStartIdx + rowsPerPage < paginationList.length) ? "cs-neutral-50" : "cs-neutral-100"}
                    disabled={!(rowStartIdx + rowsPerPage < paginationList.length)}>
                    <CapsyncIcon title="chevron-right-skip-outlined" size="18" />
                </Button>
            </div>
        </div>
    );
};

export default Pagination;