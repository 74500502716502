import React from "react";
import { Form, Row, Col, Image } from "react-bootstrap";
import SubMenuHeader from "../SubMenuHeader";
import CapsyncToolTip from "../../../../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import SubMethodsList from "../../SubMethodsList";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";

// sequence No. five  (Asmita date-29th June => Do not remove this line)
const MethodIdThirtyFour = (props) => {


  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Qualified Purchaser",
      url: `/individual/verifyinvestor/accreditedInvestor/qualifiedpurchaser/${props.unique_code}`,
    },
  ];



  return (

    <>
      {props.is_loader.assets === true || props.is_loader.liabilities === true ? <SkeletonAllSubMethods /> :
        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="Entity with all owners QP"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>
              <SubMenuHeader />
            </div>
            <div className="method-section">
              <div className="default-regular-body-text-l cs-neutral-70">
                <SubMethodsList methodId={36} />
              </div>

              <div className="cs-divider"></div>
              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert}
                />
                <div className="row-spacing">
                  <p className="default-regular-sub-heading-m cs-neutral-70">
                    In order to verify your accreditation we need you to upload evidence. Please upload the appropriate files below and black-out any sensitive information.
                  </p>
                </div>
                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Upload documents evidencing the identity of each beneficial owner of the entity:
                </Form.Label>
                <div className={props.uploadFileOneError ? "investor-error" : ""} >
                  <UploadFileBlock
                    files={props.files}
                    setOpenModal={props.setOpenModal}
                    mediaAlert={props.mediaAlert}
                    deleteFile={props.deleteFile}
                    showFile={props.showFile}
                    pfsLink={props.pfsLink}
                    pfsName={props.pfsName}
                    pfsDelete={props.pfsDelete}
                    showPfsLink={props.showPfsLink}
                    uploadError={props.uploadError}
                    uploadFileOneError={props.uploadFileOneError}
                    showError={props.showError}
                    showDeleteSpinner={props.showDeleteSpinner}
                  />

                </div>
                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Upload Qualified Purchaser verification letter for each beneficial owner of the investing entity. If you don’t have this, you may ask your issuer to issue new qualified purchaser verification request(s) for you:
                </Form.Label>
                <div className={props.uploadFileTwoError ? "investor-error" : ""} >
                  <UploadFileBlock
                    files={props.files2}
                    setOpenModal={props.setOpenModal2}
                    mediaAlert={props.mediaAlert}
                    deleteFile={props.deleteFile2}
                    showFile={props.showFile2}
                    pfsLink={props.pfsLink}
                    pfsName={props.pfsName}
                    pfsDelete={props.pfsDelete}
                    showPfsLink={props.showPfsLink2}
                    uploadFileOneError={props.uploadFileTwoError}
                    showError={props.showError}
                    showDeleteSpinner={props.showDeleteSpinner}
                  />
                </div>
                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">
                  Upload other evidence (optional):
                </Form.Label>
                <UploadFileBlock
                  files={props.files3}
                  setOpenModal={props.setOpenModal3}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile3}
                  showFile={props.showFile3}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showPfsLink={props.showPfsLink3}
                  showError={props.showError}
                  showDeleteSpinner={props.showDeleteSpinner}
                />
                <NotesBlock setNotesValue={props.setNotesValue} />
              </Form>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default MethodIdThirtyFour;
