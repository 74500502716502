import React, { useState, useEffect } from "react";
import { Form, Dropdown, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { wealth_companies_list } from "../../../slices/wealthManager.slice";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";

const SelectCompanyDropdown = ({
    companies,
    setSelectedCompanies,
    selectedCompanies,
}) => {
    const [search, setSearch] = useState("");

    const searchResultArray = companies.filter((company) => {
        return company.companyName.toLowerCase().match(search.toLowerCase());
    });

    const handleDropdownItemClick = (company) => {
        setSelectedCompanies([company]);
        setSearch("");
    };

    return (
        <div className="cs-common-add-dropdown cs-create-company">
            <div className="default-regular-sub-heading-xs cs-neutral-100">
                Choose company
            </div>
            <Dropdown>
                <Dropdown.Toggle>
                    <p
                        className={`main-filter default-light-body-text-m ${selectedCompanies.length === 0 && "cs-neutral-70"
                            }`}>
                        {selectedCompanies.length > 0
                            ? selectedCompanies[selectedCompanies.length - 1].companyName
                            : "Select company"}
                    </p>
                    <span className="cs-neutral-90">
                        <CapsyncIcon title="chevron-down-outlined" size="18" />
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Form.Group className="cs-form-group">
                        <Form.Control
                            type="text"
                            placeholder="Search..."
                            value={search}
                            className={search && "cs-input-field-active"}
                            onChange={(event) => setSearch(event.target.value)}
                        />
                        <span className="input-field-icon cs-neutral-70">
                            <CapsyncIcon title="search-outlined" size="18" />
                        </span>
                    </Form.Group>
                    {searchResultArray.map((company, index) => {
                        return (
                            <Dropdown.Item
                                key={index}
                                onClick={() => handleDropdownItemClick(company)}
                                className={
                                    selectedCompanies.includes(company) && "cs-selected-dropdown"
                                }>
                                {company.companyName}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

const SelectedCompanyTable = ({ selectedCompanies }) => {
    return (
        selectedCompanies &&
        selectedCompanies.length !== 0 && (
            <div className="partners-details-table">
                <p className="default-regular-body-text-m cs-neutral-80">
                    Company details
                </p>
                {
                    <Table className="cs-table" responsive>
                        <thead className="cs-thead">
                            <tr>
                                <th className="default-medium-sub-heading-m cs-neutral-100">
                                    Company name
                                </th>
                                <th className="default-medium-sub-heading-m cs-neutral-100">
                                    No. of partners
                                </th>
                            </tr>
                        </thead>
                        <tbody className="cs-tbody">
                            {selectedCompanies &&
                                selectedCompanies.map((company, rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td
                                            key={rowIndex + 1}
                                            className="default-regular-sub-heading-m cs-neutral-90">
                                            {company.companyName}
                                        </td>
                                        <td
                                            key={rowIndex + 2}
                                            className="default-regular-sub-heading-m cs-neutral-90">
                                            <span>{company.companyMembers}</span>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </Table>
                }
            </div>
        )
    );
};

const AssociateCompany = ({ setSelectedCompanyName, isChecked, setIsChecked, dropdownValidation, setDropdownValidation }) => {
    const { user } = useSelector((state) => state.auth);
    const user_id = user && user.user.id;
    const dispatch = useDispatch();

    const [companies, setCompanies] = useState([]);
    const [selectedCompanies, setSelectedCompanies] = useState([]);

    const handleCheckboxClick = () => {
        if (companies && companies.length > 0) {
            setIsChecked(!isChecked);
        }
    };

    const fetchAllCompanies = () => {
        dispatch(wealth_companies_list({ user_id: user_id }))
            .unwrap()
            .then((response) => {
                const sortedCompaniesArray =
                    response.fetchwealth_manager_companies.sort(
                        (company1, company2) => company1.id - company2.id
                    );
                setCompanies(sortedCompaniesArray);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchAllCompanies();
    }, []);

    useEffect(() => {
        if (isChecked === false) {
            setSelectedCompanies([]);
            setDropdownValidation("")
        }
    }, [isChecked]);

    useEffect(() => {
        if (selectedCompanies.length > 0) {
            const lastCompanyName = selectedCompanies[selectedCompanies.length - 1].companyName;
            setSelectedCompanyName(lastCompanyName);
        } else {
            setSelectedCompanyName("");
        }
        setDropdownValidation("");
    }, [selectedCompanies])

    return (
        <div className="associate-add-more">
            <Form.Group onClick={handleCheckboxClick} className={`associate-add-more ${companies && companies.length === 0 && "cs-disabled"}`}>
                <Form.Check
                    type="checkbox"
                    checked={isChecked}
                    disabled={companies && companies.length === 0}
                    label="Associate already created company with individual"
                    className="cs-form-check-box "
                />
            </Form.Group>
            {isChecked && (
                <>
                    <SelectCompanyDropdown
                        companies={companies}
                        setSelectedCompanies={setSelectedCompanies}
                        selectedCompanies={selectedCompanies}
                    />
                    {dropdownValidation && (
                        <span className="form-error-msg default-light-body-text-s cs-danger">
                            {dropdownValidation}
                        </span>
                    )}
                    <SelectedCompanyTable selectedCompanies={selectedCompanies} />
                </>
            )}
        </div>
    );
};

export default AssociateCompany;
