import React from "react";
import { Modal, Image } from "react-bootstrap";

// COMPONENT IMPORTS
import CapsyncLoaderSpin from "../../../../assets/images/capsync-loader.gif";

const MultipleDeleteLoader = ({
  deleteLoading,
  isMultipleFiles,
  isDelete = false,
}) => {
  return (
    <Modal
      show={deleteLoading}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal cs-delete-modal multiple-delete-modal"
      className="cs-common-modal-overlay"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          {isDelete ? "Deleting " : "Archiving "}
          {isMultipleFiles ? "Multiple Files" : "File"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delete-modal-content">
          <Image
            src={CapsyncLoaderSpin}
            alt="Capsync Loader"
            width="120"
            height="120"
          />
          <p className="default-regular-body-text-s cs-neutral-90">
            This may take a few moments. Please wait...
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MultipleDeleteLoader;
