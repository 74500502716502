import React from "react";
import { Image, Button, Modal, ModalFooter } from "react-bootstrap";
// import deleteIcon from "../../../../../assets/images/other/delete-validation.svg";
import deleteIcon from "../../../../../assets/images/other/error-validation.svg";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";

const ConfirmEditProfie = (props) => {
  const {
    onContinueEmailUpdate,
    showConfirmEmailUpdate,
    setShowConfirmEmailUpdate,
  } = props;

  return (
    <Modal
      id="confirm-edit-profile"
      show={showConfirmEmailUpdate}
      onHide={() => setShowConfirmEmailUpdate(false)}
      backdrop="static"
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal cs-delete-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
          Are you Sure?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="delete-modal-content">
          <Image src={deleteIcon} alt="popup_delete" className="action-icon" />
          <p className="default-light-body-text-m cs-neutral-90">
            All pending requests will be cancelled on continue and no action will be taken on cancel
          </p>
        </div>
      </Modal.Body>
      <ModalFooter className="cs-modal-btn cs-center-btn">
        <Button
          className="cs-btn-secondary md-btn default-regular-h5"
          type="button"
          onClick={() => setShowConfirmEmailUpdate(false)}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          className="cs-btn-primary md-btn default-regular-h5"
          onClick={onContinueEmailUpdate}
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmEditProfie;
