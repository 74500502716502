import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Dropdown,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import NumberFormat from "react-currency-format";
import { combine } from "../../../util/common";
import { wealth_submission_history_add } from "../../../slices/wealthManager.slice";
import DatePicker from "react-date-picker";
import { useDispatch, useSelector } from "react-redux";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";

const AddModal = ({
  modalState,
  changeModalState,
  getSubmissionHistory,
  toastMessage,
}) => {
  const [select_status, setSelectStatus] = useState("");

  const [newDollar, setDollar] = useState(false);

  const { user } = useSelector((state) => state.auth);
  let user_id;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    user_id = window.localStorage.getItem("pfs_access_id");
  } else {
    user_id = user && user.user.id;
  }
  let wm_id = user && user.user.id;
  const dispatch = useDispatch();

  //----------Used in add and update model for sign------------//
  let common;

  const check = (para, a) => {
    common = combine(para, a);
    if (common.value === "dollar") {
      setDollar(common.status);
    }
  };
  //----------------------*********----------------//

  let StatusType = ["Under Review", "Completed", "Rejected"];

  const date = new Date();

  //----------fields that will be stored in DB------------//

  const initialValues = {
    financial_institution: "",
    application_type: "",
    amount_applied_for: "",
    date: date,
    submission_status: "Select status",
    loan_reference: "",
    notes: "",
  };
  //----------------------*********----------------//

  //-------------Add account form validation-------------//
  const validationSchema = yup.object().shape({
    financial_institution: yup
      .string()
      // .trim()
      // .strict(true)
      // .matches(/^[^\s\d]+$/, "Only letters are allowed")
      .matches(
        /^[^\s].*[^\s]$/,
        "Whitespace is not allowed at the start or end"
      )
      .min(2, "Institution must be at least 2 characters")
      .max(100, "Institution must be 20 characters maximum")
      .required("Please enter Financial institution"),
    application_type: yup
      .string()
      // .trim()
      // .strict(true)
      // .matches(/^[^\s\d]+$/, "Only letters are allowed")
      .matches(
        /^[^\s].*[^\s]$/,
        "Whitespace is not allowed at the start or end"
      )
      .min(2, "Loan type must be at least 2 characters")
      .max(100, "Loan type must be 20 characters maximum")
      .required("Please enter Application type"),
    amount_applied_for: yup
      .string()
      .required("Please enter Amount applied for")
      .test(
        "nonZero",
        "Amount cannot be zero",
        (value) => parseFloat(value) !== 0
      ),
    date: yup.date().required("Please select Date"),
    loan_reference: yup
      .string()
      .trim()
      .strict(true)
      .required("Please enter Loan reference #")
      .min(2, "Loan reference must be at least 2 characters")
      .max(100, "Loan reference must be 20 characters maximum"),
    notes: yup
      .string()
      .trim()
      .strict(true)
      .required("Please enter notes")
      .min(2, "Notes must be at least 2 characters")
      .max(100, "Notes must be 20 characters maximum"),
  });

  //----------------------*********----------------//

  const [makeDisable, setMakeDisable] = useState(false);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: validationSchema,
    //---------This will be call on form submit---------//
    onSubmit: async (values) => {
      if (values.submission_status === "Select status") {
        setSelectStatus("Please select a Status");
      } else {
        setSelectStatus("");
        setMakeDisable(true);
        values.wm_id = wm_id;
        values.individual_id = user_id;
        dispatch(wealth_submission_history_add(values))
          .unwrap()
          .then((response) => {
            if (response.code === 200) {
              getSubmissionHistory();
              setMakeDisable(false);
              toastMessage(response.message, "success");
              changeModalState(false, true);
              resetForm();
            }
          })
          .catch((err) => {
            setMakeDisable(false);
            toastMessage(err.message, "error");
          });
      }
    },
  });

  const closeModal = (close, open) => {
    changeModalState(close, open);
    resetForm();
  };

  return (
    <>
      <Modal
        show={modalState.show}
        onHide={modalState.hide}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-lg-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">Add submission history</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body >
            <Row>
              <Col lg={6} md={6} sm={12}>
                <Form.Group className="cs-form-group">
                  <Form.Label>Date</Form.Label>
                  <DatePicker
                    dayPlaceholder="DD"
                    monthPlaceholder="MM"
                    yearPlaceholder="YYYY"
                    name="date"
                    value={values.date}
                    onChange={(e) => setFieldValue("date", e)}
                    // onBlur={handleBlur}
                    clearIcon={null}
                    className={
                      values.date
                        ? "cs-input-field-active select-date-range cs-date-picker-calender"
                        : "cs-date-picker-calender"
                    }
                    calendarIcon={
                      <span className="cs-neutral-90">
                        <CapsyncIcon title="calender-outlined" size="18" />
                      </span>
                    }
                    format="MM/dd/y"
                    required
                  />
                </Form.Group>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Form.Group className="cs-form-group">
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="text"
                    placeholder="Personal loan"
                    name="application_type"

                    onKeyDown={(e) => {
                      if (e.key === " " && e.target.selectionStart === 0) {
                        e.preventDefault();
                      }
                    }}
                    defaultValue={values.application_type}
                    className={values.application_type && "cs-input-field-active"}

                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  {errors.application_type && touched.application_type ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.application_type}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={6} sm={12}>
                <Form.Group className="cs-form-group">
                  <Form.Label>Financial institution</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="National Bank of America"
                    name="financial_institution"
                    defaultValue={values.financial_institution}
                    className={values.financial_institution && "cs-input-field-active"}

                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.financial_institution &&
                    touched.financial_institution ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.financial_institution}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Form.Group className="cs-form-group">
                  <Form.Label>Amount applied for</Form.Label>
                  <NumberFormat
                    className={
                      newDollar === true && values.amount_applied_for
                        ? "dollar-symbol text-md-end form-control cs-input-field-active"
                        : "text-md-end form-control"
                    }
                    autoComplete="off"
                    placeholder="$ 2,000"
                    displayType={"input"}
                    thousandSeparator={true}
                    name="amount_applied_for"
                    value={values.amount_applied_for}
                    onKeyUp={(e) => check(e.target.value, "dollar")}
                    maxLength={11}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onKeyPress={(e) =>
                      !/[0-9]/.test(e.key) && e.preventDefault()
                    }
                  />
                  {errors.amount_applied_for && touched.amount_applied_for ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">
                      {errors.amount_applied_for}
                    </span>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg={6} md={6} sm={12}>
                <Form.Group className="cs-form-group">
                  <Form.Label>Status</Form.Label>
                  <div className="cs-common-add-dropdown">
                    <Dropdown >
                      <Dropdown.Toggle
                      >
                        {values.submission_status}
                        <span className="cs-neutral-90">
                          <CapsyncIcon title="chevron-down-outlined" size="18" />
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu >
                        {StatusType.map((status) => (
                          <Dropdown.Item
                            className={status === values.submission_status && "cs-selected-dropdown"}
                            key={status}
                            onClick={() =>
                              setFieldValue("submission_status", status)
                            }
                          >
                            {status}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  {select_status !== "" ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">{select_status}</span>
                  ) : null}
                </Form.Group>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Form.Group className="cs-form-group">
                  <Form.Label>Loan reference #</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="2017123123123SGD"
                    name="loan_reference"
                    defaultValue={values.loan_reference}
                    className={values.loan_reference && "cs-input-field-active"}

                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.loan_reference && touched.loan_reference ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">{errors.loan_reference}</span>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Form.Group className="cs-form-group last-notes-field">
                  <Form.Label>Notes</Form.Label>
                  <textarea
                    autoComplete="off"
                    type="text"
                    placeholder="Optional"
                    name="notes"
                    defaultValue={values.notes}
                    className={
                      values.notes
                        ? "cs-input-field-active cs-textarea"
                        : "cs-textarea"
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                  {errors.notes && touched.notes ? (
                    <span className="form-error-msg default-light-body-text-s cs-danger">{errors.notes}</span>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer >
            <div className="cs-modal-btn">

              <Button
                className="cs-btn-secondary md-btn default-regular-h5"
                type="submit"
                onClick={() => closeModal(false, true)}
              >
                Cancel
              </Button>
              <Button
                className="cs-btn-primary md-btn default-regular-h5"
                type="submit"
                disabled={makeDisable}
              >
                Save Changes
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddModal;
