
import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Image } from "react-bootstrap";
import SuccessValidation from "../../../../../assets/images/other/success-validation.svg";

function UserSuccessModel({ showChildModal, setShowModal, setShowChildModal }) {

  const { user } = useSelector((state) => state.auth);
  let id;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = window.localStorage.getItem("pfs_access_id");
  } else {
    id = user && user.user.id;
  }

  const closeModal = () => {
    setShowChildModal(false);
  };

  const AddModalButton = () => {
    setShowChildModal(false);
    setShowModal(true);
  };
  return (
    <Modal
      show={showChildModal}
      onHide={!showChildModal}
      backdrop="static"
      keyboard={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="cs-md-modal"
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          User added successfully
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="succes-smodal-body default-regular-sub-heading-m cs-neutral-90">
          <span className="account-success-img">
            <Image
              src={SuccessValidation}
              alt="Success Validation"
              width={84}
              height={84}
            />
          </span>
          <p className="text-center default-light-body-text-m cs-neutral-90">
            Congratulations! You've successfully added the first users. You may
            proceed with adding users, or alternatively,
            bypass this step.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="cs-modal-btn cs-center-btn">
          <Button
            className="cs-btn-secondary md-btn default-regular-h5 "
            onClick={closeModal}
          >
            Not now
          </Button>
          <Button
            className="cs-btn-primary md-btn default-regular-h5"
            onClick={AddModalButton}
          >
            Add Another
          </Button>
        </div>
      </Modal.Footer>

    </Modal>
  )
}

export default UserSuccessModel

