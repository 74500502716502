/* eslint-disable */
import React, { useCallback, useState } from "react";
import { Modal, CloseButton, Button, Image } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import {
  wealth_manage_files,
  wealth_manage_files_remove,
} from "../../../slices/wealthManager.slice";
import { useDispatch } from "react-redux";
import UseZipDownload from "../../../customHooks/UseZipDownload";
import UseDownload from "../../../customHooks/UseDownload";
import Download from "../../../../assets/icons/svg/outlined/download-outlined.svg";
import { backAppURl } from "../../../applicationMode";
import deleteicon from "../../../../assets/icons/svg/outlined/delete-outlined.svg";
import CapsyncLoader from "../../../../assets/images/capsync-loader.gif";
import CapsyncToolTip from "../../../commonComponent/capsyncTooltip/CapsyncToolTip";

const ManageFiles = ({
  modalState_1,
  changeModalState_1,
  storeFiles,
  storeAllFiles,
  fileAttachment,
  toastMessage,
}) => {
  const dispatch = useDispatch();
  let storeFileArr = [];

  let url = backAppURl;

  const [is_deleted, setIs_deleted] = useState(false);

  //------------Download all attchments---------------//
  const downloadZip = (url, params) => {
    UseZipDownload(
      `${url}/api/wealthmanager/companies/zip/${params}/${window.localStorage.getItem(
        "row_id"
      )}`
    );
  };
  //---------------**************------------------//

  //------------------Download single attchments----------------//
  const downloadFile = (url, params) => {
    UseDownload(
      `${url}/api/wealthmanager/companies/${params}/${window.localStorage.getItem(
        "row_id"
      )}`,
      params
    );
  };
  //-----------------*****************------------------//

  const onDropAccepted = useCallback((acceptedFiles) => {
    setIs_deleted(true);
    const formData = new FormData();
    acceptedFiles.forEach((itr) => {
      formData.append("attachments", itr);
    });
    formData.append("id", window.localStorage.getItem("row_id"));
    dispatch(wealth_manage_files(formData))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          fileAttachment(response.data);
          setIs_deleted(false);
          acceptedFiles.length = 0;
        }
      })
      .catch((err) => {
        setIs_deleted(false);
        console.log(err);
        return err;
      });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'application/pdf': ['.pdf'],
    },
    onDropAccepted,
  });

  const supportfiles = acceptedFiles.map((file) => (
    <li key={file.path}>{file.path}</li>
  ));

  const removeFile = (params) => {
    setIs_deleted(true);
    let rowId = window.localStorage.getItem("row_id");

    dispatch(wealth_manage_files_remove({ rowId: rowId, fileName: params }))
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          fileAttachment(response.data);
          setIs_deleted(false);
          toastMessage("Your file has been deleted successfully.", "success");
        }
      })
      .catch((err) => {
        console.log(err);
        setIs_deleted(false);
        toastMessage(err.message, "error");
      });
  };

  function showStarNumber(params) {
    let newContent = "";
    newContent += params;
    if (newContent.length > 25) {
      return newContent.substring(0, 25) + "...";
    } else {
      return newContent;
    }
  }

  return (
    <>
      <Modal
        className="wm-modal-doc"
        dialogClassName="wm-company-managefiles-popup"
        show={modalState_1.show}
        onHide={modalState_1.hide}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title className="m-auto">Manage Files</Modal.Title>
          <CloseButton
            onClick={() => changeModalState_1(false, true, null, 0)}
          />
        </Modal.Header>
        <Modal.Body>
          {is_deleted === true ? (
            <>
              <div className="text-center initial-modal-loader">
                <Image src={CapsyncLoader} alt="loadergif" className="w-25 " />
              </div>
            </>
          ) : (
            <>
              <div className="file-attachments">
                <div className="download-file-list">
                  {storeFiles[0] !== "" &&
                    storeFiles.map((iter) => {
                      let new_dummyfileStr = "";

                      storeFileArr = iter.split("_@");

                      new_dummyfileStr = storeFileArr[1];

                      return (
                        <div className="download-popup-file">
                          <CapsyncToolTip
                            Child={"file-icon-outlined"}
                            placement={"top"}
                            message={new_dummyfileStr.replaceAll("_", " ")}
                            type="icon"
                          />
                          <span className="download-filename">
                            {showStarNumber(new_dummyfileStr)}
                          </span>
                          <Image
                            src={Download}
                            alt="Download"
                            className="download-single-img cursor_pointer"
                            onClick={() => downloadFile(url, iter)}
                          />

                          <Image
                            alt="Delete"
                            className="delete-single-img"
                            src={deleteicon}
                            onClick={() => removeFile(iter)}
                          />
                        </div>
                      );
                    })}
                </div>
                <h6 className="text-left upload-doc-manage-files">
                  Upload supportive documents
                </h6>
                <section className="my-dropzone-data">
                  <div
                    {...getRootProps({
                      className: "dropzone cursor_pointer file-upload-styling",
                    })}
                  >
                    <input {...getInputProps()} name="attachments" />
                    <Image
                      alt=""
                      className="mb-8-px"
                      src={require("../../../../assets/icons/svg/outlined/upload-outlined.svg")}
                    />
                    <p className="mb-5-px cap-drag-drop-color">
                      Drag and Drop Files, or{" "}
                      <span className="cap-main-color">Browse</span>
                    </p>
                    <p className="cap-primary-color">
                      Support PDF, PNG, JPG and JPEG Files
                    </p>
                  </div>
                  <aside>
                    <ul className="cap-list-style-type">{supportfiles}</ul>
                  </aside>
                </section>
              </div>

              {storeFiles[0] === "" || storeFiles.length === 0 ? (
                <Button
                  className="cs-pri-lg-btn manage-file-download new-modal-download"
                  disabled
                  title="disabled"
                >
                  Download all
                </Button>
              ) : (
                <Button
                  className="cs-pri-lg-btn manage-file-download new-modal-download"
                  onClick={() => downloadZip(url, storeAllFiles)}
                >
                  Download all
                </Button>
              )}
              <Button
                className="cs-sm-cancel-btn ms-2 manage-file-cancel new-modal-cancel"
                onClick={() => changeModalState_1(false, true, null, 0)}
              >
                Cancel
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManageFiles;
