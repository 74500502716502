import React from "react";
import { Form, Dropdown } from "react-bootstrap";
import CapsyncIcon from "../CapsyncIcon";

const IndividualJoint = ({ props, IsChecked, userRole }) => {
  const IJArr = [
    { name: "Individual", key: 1 },
    { name: "Joint", key: 2 },
  ];

  return (
    <>
      <div className="select-pfs-form">
        <Form.Group className="cs-form-group">
          {/* <Form.Label>You want to share PFS as </Form.Label> */}
          {userRole == 1 && IsChecked != undefined ? (
            <Form.Select
              aria-label="Default select example"
              className="cap-select-role select-user-role select-select"
              onChange={(e) => props.setSelectValue(e.target.value)}
            >
              <option value={1}>Individual</option>
            </Form.Select>
          ) : (
            <Dropdown onSelect={(key) => props.setSelectValue(key)}>
              <Dropdown.Toggle
                variant="primary"
                id="dropdown-basic"
                className="indi-sharepfs-dropdown main-filter default-regular-sub-heading-s"
              >
                {props.selectValue === undefined
                  ? "Individual or Joint"
                  : props.selectValue === "1"
                    ? "Individual"
                    : "Joint"}
                <span className="cs-neutral-90 cs-dropdown-icon">
                  <CapsyncIcon title="chevron-down-outlined" size="18" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="cs-scroller">
                {IJArr.map((itr) => {
                  return (
                    <>
                      <Dropdown.Item
                        // className="indi-sharepfs-drop-item"
                        className={`${props.selectValue == itr.key ? 'selected-nav-item' : ''} default-regular-sub-heading-s`}
                        eventKey={itr.key}
                      >
                        {itr.name}
                      </Dropdown.Item>
                    </>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Form.Group>
      </div>
    </>
  );
};

export default IndividualJoint;
