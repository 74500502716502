import React from "react";
import { ProgressBar, Image } from "react-bootstrap";
import CapsyncLoader from "../../../../../assets/images/capsync-loader.gif";

const PlaidProgressBar = ({ apiName, progessPlaid, updateFlagClassName }) => {
  return (
    <>
      <div
        className={`text-center plaid-callApi-prog-bar`}
      >
        <div className="text-center">
          <Image src={CapsyncLoader} alt="CapsyncLoader" className="w-25" />
        </div>
        <div className="plaid-head new-plaid-progress-bar">
          <h2 className={`${updateFlagClassName} default-light-sub-heading-xs cs-neutral-100 plaid-prog-bar-heading`}>{apiName}</h2>
        </div>
        <div className="cs-progress-bar">
          <ProgressBar
            now={100 - 100 / progessPlaid}
            className="plaid-prog-bar"
          />
        </div>
      </div>
    </>
  );
};

export default PlaidProgressBar;
