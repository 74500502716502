import React, { useState, useEffect } from "react";
import { Button, Image, Modal } from "react-bootstrap";
import ErrorValidation from "../../../../assets/images/other/error-validation.svg"
import CapsyncLoader from "../../../commonComponent/CapsyncLoader";

const StorageLimitWarningModal = (props) => {
  const { showWarning, warningMessage, setShowWarning } = props;

  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(false);
  })

  const continueFun = () => {
    setShowLoader(true);

    setShowWarning(false)
  };

  return (
    <>

      <Modal
        show={showWarning}
        onHide={!showWarning}
        centered
        size="md"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="cs-md-modal payment-success"
      >
        {
          showLoader ? (
            <div>
              <CapsyncLoader />
            </div>
          ) : (
            <>
              <Modal.Header>
                <Modal.Title className="default-semi-bold-h3">
                  Attention needed
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="success-head">
                  <span>
                    <Image
                      src={ErrorValidation}
                      alt="Error Validation"
                      width={100}
                      height={100}
                    />
                  </span>
                  <p className="default-light-body-text-m cs-neutral-90">
                    {warningMessage}
                  </p>

                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="cs-btn-primary lg-btn default-regular-h5"
                  onClick={() => continueFun()}
                >
                  Continue
                </Button>
              </Modal.Footer>
            </>
          )}

      </Modal >
    </>

  );
};

export default StorageLimitWarningModal;
