import Dropdown from "react-bootstrap/Dropdown";
import React, { useState } from "react";
import CapsyncIcon from "../../../commonComponent/CapsyncIcon";
import { Form } from "react-bootstrap";
function ChooseIndiDropdown({ data, setSelectedIndividualFromCompany, selectedIndividualFromCompany, setSelectedIndividualEmailArray, selectedIndividualEmailArray, companyPartners, dropdownValidation, setDropdownValidation }) {
  const [search, setSearch] = useState("");
  const searchResultArray = data.filter((individual) => {
    return (individual.to_status === 1 && individual.firstName.toLowerCase().match(search.toLowerCase())) || (individual.to_status === 1 && individual.lastName.toLowerCase().match(search.toLowerCase()))
  })

  const handleIndividualSelection = (individual) => {
    if (companyPartners) {
      const individualEmail = individual.email;
      for (let i = 0; i < companyPartners.length; i++) {
        if (companyPartners[i].email === individualEmail) {
          setDropdownValidation && setDropdownValidation("User Already Exists in Members")
          return
        }
      }
    }

    if (selectedIndividualFromCompany.includes(individual)) {
      setDropdownValidation && setDropdownValidation("You Already Selected this Member")
      return

    }
    setDropdownValidation && setDropdownValidation("")
    const selectedIndividualArray = [...selectedIndividualFromCompany, individual];
    const emailArray = [...selectedIndividualEmailArray, individual.email];
    setSelectedIndividualFromCompany(selectedIndividualArray);
    setSelectedIndividualEmailArray(emailArray);
    console.log(emailArray)
  }

  return (
    <div className="cs-common-add-dropdown cs-create-company">
      <div className="default-regular-sub-heading-xs cs-neutral-100">Choose Individual</div>
      <Dropdown>
        <Dropdown.Toggle >
          <p className="main-filter cs-neutral-70">Select Individual</p>
          <span className="cs-neutral-90">
            <CapsyncIcon
              title="chevron-down-outlined"
              size="18"
            />
          </span>
        </Dropdown.Toggle>
        {dropdownValidation && (
          <span className="form-error-msg default-light-body-text-s cs-danger">
            {dropdownValidation}
          </span>
        )}
        <Dropdown.Menu >
          <Form.Group className="cs-form-group">
            <Form.Control
              type="text"
              placeholder="Search..."
              value={search}
              className={search && "cs-input-field-active"}
              onChange={(event) => setSearch(event.target.value)}
            />
            <span className="input-field-icon cs-neutral-70">
              <CapsyncIcon title="search-outlined" size="18" />
            </span>
          </Form.Group>
          {searchResultArray.map((individual, index) => {
            return <Dropdown.Item key={index} onClick={() => handleIndividualSelection(individual)}>{individual.firstName} {individual.lastName}</Dropdown.Item>
          })
          }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default ChooseIndiDropdown;
