import React, { useState, useEffect, useRef, useCallback } from "react";
import { Nav, Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { requestsNotifications, userRequestList } from "../../../../slices/requests";
import LogoImage from "../../../logoImage";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import plusIcon from "../../../../../assets/images/other/plus.svg";
import { roleName } from "../../../../config/commonRoleName";
import { FA_MENU_ITEMS, PRO_MENU_ITEMS } from "./MenuItems";
import { socket } from "../../../../config/Socket";

const DesktopMenu = ({ props, isMobile }) => {
  const [countNotiFrom, setCountNotiFrom] = useState(0)
  const [countNotiTo, setCountNotiTo] = useState(0)
  const activeTabState = useSelector(state => state.activeTab.activeTabState)
  const handleClose = () => {
    props.toggle()
    props.setOpenSide(false)
  }


  const handleclick = () => {
    if (props.isMobile) {
      handleClose();
    }
  };

  const [notifications, setNotifications] = useState(0);

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const user_id = user && user.user.id;
  const role = user && user.user_role.role
  const wm_email = user && user.user.email;
  const fetchAllNotifications = useCallback(() => {
    if (role == 2 || role == 3) {
      dispatch(
        requestsNotifications({ user_id: user_id, module_name: role == 2 ? roleName.financial_advisor : roleName.tax_professional })
      )
        .unwrap()
        .then((response) => {
          setNotifications(response.length);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user_id]);

  useEffect(() => {
    let use_timer = fetchAllNotifications; //setInterval(fetchAllNotifications, 1000);
    return () => clearInterval(use_timer);
  }, [fetchAllNotifications]);

  const handleAddClient = () => {
    props.setShowModal(true);
    props.setShowInitialModal(true)
  }
  useEffect(() => {
    const handleNotification = (msg) => {
      fetchSignatureRequests();
    };

    socket.on("newNotification", handleNotification);
    socket.on("signature_request_status_update", handleNotification);
    socket.on("signature_request_status_update_individual", handleNotification);
    socket.on("signature_request_status_update_spouse", handleNotification);

    return () => {
      socket.off("newNotification", handleNotification);
      socket.off("signature_request_status_update", handleNotification);
      socket.off("signature_request_status_update_individual", handleNotification);
      socket.off("signature_request_status_update_spouse", handleNotification);
    };
  }, []);
  const fetchSignatureRequests = () => {
    dispatch(userRequestList({ wm_id: user_id, wm_email: wm_email })).unwrap()
      .then((response) => {
        const countFrom = response.from.filter(data => data.is_completed === false)
        setCountNotiFrom(countFrom.length)
        const countTo = response.to.filter(data => data.is_completed === false)
        setCountNotiTo(countTo.length)
      })
      .catch((err) => {
        console.log("error", err);
      });
  }
  useEffect(() => {
    if (user && user.user && user.user.email && user.user.id) fetchSignatureRequests()
  }, [user])
  let total = notifications + countNotiFrom + countNotiTo;

  return (
    <>
      <div
        className={classNames(
          "cs-main-sidebar",
          {
            "is-open": props.isOpen,
          },
          { isMobile } ? "is-open" : ""
        )}>
        <div className="sidebar-header">
          <LogoImage />

          <Button variant="link" onClick={handleClose} className="close-btn">
            <CapsyncIcon title="close-outlined" size="18" />
          </Button>
          {/* <Button
            variant="link"
            onClick={props.toggle}
            className="cap-main-color"
          >
            text
          </Button> */}
        </div>
        <div className="sidebar-nav">
          <Nav>
            {role == 2
              ? FA_MENU_ITEMS.map((val, idx) => {
                  return (
                    <Link
                      key={idx}
                      to={val.link}
                      onClick={() => handleclick()}
                      className={`cs-main-nav-item ${
                        activeTabState === val.activeKey && "active"
                      }`}>
                      <div className="cs-menu-icon">
                        <CapsyncIcon
                          title={`${val.icon}-${
                            activeTabState === val.activeKey
                              ? "filled"
                              : "outlined"
                          }`}
                          size="22"
                        />
                      </div>
                      <div
                        className={`default-regular-sub-heading-m ${
                          val.isNotifications ? "req-notification-num" : ""
                        }`}>
                        {val.title}
                      </div>
                      {val.isNotifications &&
                        (notifications > 0 ||
                          countNotiFrom > 0 ||
                          countNotiTo > 0) && (
                          <span className="request-pending-number default-regular-body-text-xxs">
                            {total}
                          </span>
                        )}
                    </Link>
                  );
                })
              : PRO_MENU_ITEMS.map((val, idx) => {
                  return (
                    <Link
                      key={idx}
                      to={val.link}
                      onClick={() => handleclick()}
                      className={`cs-main-nav-item ${
                        activeTabState === val.activeKey && "active"
                      }`}>
                      <div className="cs-menu-icon">
                        <CapsyncIcon
                          title={`${val.icon}-${
                            activeTabState === val.activeKey
                              ? "filled"
                              : "outlined"
                          }`}
                          size="22"
                        />
                      </div>
                      <div
                        className={`default-regular-sub-heading-m ${
                          val.isNotifications ? "req-notification-num" : ""
                        }`}>
                        {val.title}
                      </div>
                      {val.isNotifications &&
                        (notifications > 0 ||
                          countNotiFrom > 0 ||
                          countNotiTo > 0) && (
                          <span className="request-pending-number default-regular-body-text-xxs">
                            {total}
                          </span>
                        )}
                    </Link>
                  );
                })}
          </Nav>
        </div>
        <div className="sidebar-add-account">
          <Button
            className="cs-btn-primary default-regular-h5"
            onClick={() => handleAddClient()}>
            <Image src={plusIcon} className="add-account-plus-icon" />
            Add Client
          </Button>
        </div>
      </div>
    </>
  );
};

export default DesktopMenu;
