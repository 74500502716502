/* eslint-disable */
import React, { useState, useRef, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  access_indiPFS_fromWealth,
  useUser,
  wealth_managers_findone,
} from "../../slices/auth";
import NavbarSection from "./utilities/NavbarSection";
import SidebarSection from "./utilities/SidebarSection";
import MessageModal from "../../commonComponent/component/MessageModal";
import DeleteModal from "../../commonComponent/modalPopup/deleteModal/DeleteModal";
import LogoutModal from "../../commonComponent/component/LogoutConfirmationModal";
import { socket } from "../../config/Socket";
import {
  add_advisors,
  requestEmailLink,
  requestsNotifications,
} from "../../slices/requests";
import SkeletonSidebar from "./utilities/SkeletonSidebar";
import SkeletonNavbar from "./utilities/SkeletonNavbar";
import AddIndividualModals from "./dashboard/InitialModals/AddIndividualModals";
import { access_invitation_Pfs, setIndividuals } from "../../slices/pfs_access";
import { userRequestList } from "../../slices/requests";
import { fetchSignatureFrom } from "../../util/request";
import RequestDashboard from "./Requests/RequestDashboard";
import MicroDepositeVerificationModal from "../../commonComponent/paymentSubscription/subscription/MicroDepositeVerificationModal";
import SubscribeModal from "./dashboard/SubscribeModal";
import SharePFSModal from "./Modals/SharePfsModal";
import PfsPreviewModal from "./Modals/PfsPreviewModal";
import ExportPdfExcelModal from "./Modals/ExportPdfExcelModal";
import CompleteProfile from "./dashboard/InitialModals/CompanyProfile";
import { setActiveTabState } from "../../slices/dashboard";
import { roleName } from "../../config/commonRoleName";
const WmLayout = ({ page: Page }) => {
  const reducerData = useSelector((state) => state);
  const individuals = useSelector((state) => state.manageUser.individuals);
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    localStorage.removeItem("pfs_access_obj");
    localStorage.removeItem("pfs_comp_name");
    localStorage.removeItem("pfs_access_comp_first");
    localStorage.removeItem("pfs_access_id");
    localStorage.removeItem("pfs_access_comp");
    let reactClass = window.location.pathname.substring(1).replaceAll("/", "-");
    document.body.classList.add(reactClass);
    return () => {
      document.body.classList.remove(reactClass);
    };
  }, []);
  const loginStatus = localStorage.getItem("loginStatus");

  const { user, authUser } = useSelector((state) => state.auth);
  const loggedUser = authUser && authUser.user ? authUser.user : authUser;
  const loggedUserRole =
    authUser && authUser.user_role ? authUser.user_role : authUser;
  const user_id = loggedUser && loggedUser.id;
  const user_role = loggedUserRole && loggedUserRole.role;
  const user_email = loggedUser && loggedUser.email;
  const role = JSON.parse(window.localStorage.getItem("loginStatus")).user_role;
  const navigateBack = useNavigate();
  const dispatch = useDispatch();
  if (user_id && loggedUserRole === 1) {
    navigateBack("/individual");
  }
  const getSwitchUserList = async () => {
    try {
      const payload = {
        advisor_user_id: authUser ? authUser?.user?.id : user?.user?.id,
        user_id: authUser ? authUser?.user?.id : user?.user?.id,
        advisor_user_role: role,
        flag: true,
      };
      await dispatch(add_advisors(payload)).unwrap();
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  useEffect(async () => {
    getSwitchUserList();
    socket.emit("fetch_user", {
      user_id: user_id,
      user_role:
        user_role === 2
          ? roleName.financial_advisor
          : user_role === 1
          ? roleName.individual
          : roleName.tax_professional,
      user_type: user_role,
    });
    const handleNewNotification = (msg) => {
      getNotificationRequests();
    };
    socket.on("newNotification", handleNewNotification);

    return () => {
      socket.off("newNotification", handleNewNotification);
    };
  }, []);

  const show = "showsidebar";

  const individual = useRef(false);
  const wealthmanager = useRef(false);
  const company = useRef(false);
  const requestactive = useRef(false);

  const [isOpen, setIsOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  const [wmDetails, setWmDetails] = useState([]);

  const [isMsgModalOpen, setMsgModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [customImgSrc, setCustomImgSrc] = useState("");
  const [customLoginImgSrc, setCustomLoginImgSrc] = useState("");
  const [profileState, setProfileState] = useState(false);
  const [isLoader, setIsLoader] = useState(true);
  const [storeAllNotifications, setStoreAllNotifications] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showInitialModal, setShowInitialModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const [modalState, setModalState] = useState(false);

  const [deleteStatus, setDeleteStatus] = useState({
    show: false,
    message: "",
    title: "",
    custom: "",
  });

  // const [openSide, setOpenSide] = useState(!isMobile ? true : false);
  const [openSide, setOpenSide] = useState(true);
  let sideBarStyle = null;
  const [width, setWidth] = useState(window.innerWidth);

  const [showSharePFSModal, setShowSharePFSModal] = useState(false);
  const [shareEmail, setShareEmail] = useState({
    email: "",
    sharelink: "",
    firstName: "",
    lastName: "",
    displayName: "",
    id: "",
  });
  const [row_user_id, setRow_user_id] = useState(1); // doubt
  const [previewModal, setPreviewModal] = useState(false);
  const [exportPdfExcelModal, setExportPdfExcelModal] = useState(false);

  useEffect(() => {
    if (!isLoader && (!individuals || individuals.length === 0)) {
      setShowModal(true);
      setShowInitialModal(true);
    }
  }, [individuals]);

  useEffect(() => {
    setOpenSide(window.innerWidth <= 991 ? false : true);
  }, [isMobile]);

  useEffect(() => {
    setIsMobile(window.innerWidth <= 991);
  }, [window.innerWidth]);

  function toastMessage(params, type) {
    toast[type](params);
  }
  const callThisFun = (s) => {
    // setShowModalTrue(s);
    setModalState(s);
  };

  function toggleSidebar(params) {
    setOpenSide(params);
  }

  function changeSrcStatus(params) {
    setCustomImgSrc(params);
  }

  function changeProfileState(params) {
    setProfileState(params);
  }
  function CapsyncDeleteMessage(showDeleteModal, msg, title, func) {
    if (!showDeleteModal) {
      setDeleteStatus({
        ...deleteStatus,
        show: false,
      });
    } else {
      setDeleteStatus({
        show: showDeleteModal,
        message: msg,
        title: title,
        custom: func,
      });
    }
  }
  const CapsyncMessage = (title, message, type) => {
    setMsgModalOpen(true);
    setModalTitle(title);
    setModalMessage(message);
    setModalType(type);
  };

  // logout confirmation popup modal state
  const [logoutStatus, setlogoutStatus] = useState({
    show: false,
  });

  function CapsyncLogoutModal(s) {
    setlogoutStatus({
      show: s,
    });
  }
  const fetchSignatureRequests = () => {
    dispatch(userRequestList({ wm_id: user_id, wm_email: user_email }))
      .unwrap()
      .then((response) => {})
      .catch((err) => {
        CapsyncMessage("Error", "userRequestList :" + err, "error");
      });
  };

  useEffect(() => {
    setTimeout(() => {
      setMsgModalOpen(false);
    }, 2000);
  }, [CapsyncMessage]);

  useEffect(() => {
    if (!loginStatus) {
      navigateBack("/");
    }
  }, [loginStatus]);

  const getNotificationRequests = async () => {
    try {
      if (user_role == 2 || user_role == 3) {
        const response = await dispatch(
          requestsNotifications({
            user_id: user_id,
            module_name:
              user_role == 2
                ? roleName.financial_advisor
                : roleName.tax_professional,
          })
        ).unwrap();
        if (response) {
          setStoreAllNotifications(response);
        }
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  useEffect(() => {
    getNotificationRequests();
    updateWidth();
    window.addEventListener("resize", updateWidth.bind(this));
    return () => {
      window.removeEventListener("resize", updateWidth.bind(this));
    };
  }, []);

  const previousWidth = useRef();

  const changeToggle = () => {
    setIsOpen(true);
  };

  function wealthManagerData() {
    dispatch(wealth_managers_findone({ wm_id: user_id }))
      .unwrap()
      .then((response) => {
        setWmDetails(response);
        setIsLoader(false);
      })
      .catch((err) => {
        CapsyncMessage(
          "Error",
          "wealth_managers_findone :" + err.message,
          "error"
        );
      });
  }

  const getIndividuals = async () => {
    try {
      const response = await dispatch(
        access_invitation_Pfs({
          email: user && loggedUser.email,
        })
      ).unwrap();
      const res = response.length > 0 ? [...response] : [];
      const sortedIndi = res.sort((ind1, ind2) => ind2.id - ind1.id);
      // dispatch(setIndividuals(sortedIndi));
      if (response && response.length === 0) {
        setShowInitialModal(true);
      }
    } catch (error) {
      console.log("error", error);
      toastMessage("access_invitation_Pfs :" + error.message, "error");
    }
  };

  useEffect(() => {
    wealthManagerData();
  }, [profileState]);

  // Saquib New Sidebar closing and opening in Mobile and Tablet
  const handleclick = () => {
    individual.current = false;
    wealthmanager.current = false;
    company.current = false;
    requestactive.current = false;
  };

  const updateWidth = useCallback(() => {
    const width = window.innerWidth;
    const widthLimit = 1900;

    if (width <= widthLimit) {
      if (width < 1900) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    } else {
      setIsOpen(true);
    }
  }, [isOpen]);

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  });

  function accessPFS(access_id, user_id) {
    dispatch(
      access_indiPFS_fromWealth({
        access_id: access_id,
        user_id: user_id,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code === 200) {
          let user_id = window.localStorage.getItem("user")
            ? JSON.parse(window.localStorage.getItem("user")).user.id
            : "";
          let role = window.localStorage.getItem("user")
            ? JSON.parse(window.localStorage.getItem("user")).user_role.role
            : "";
          if (user_id != response.user_id || role === 2 || role === 3) {
            let pfs_access_obj = {
              pfs_access_id: response.user_id,
              pfs_access_role: role == 2 ? 2 : 3,
              pfs_access_comp: roleName.individual,
              pfs_comp_id: "",
              pfs_comp_name: "",
              pfs_access_comp_first: "FirstINDIVIDUAL",
            };
            window.localStorage.setItem(
              "pfs_access_obj",
              JSON.stringify(pfs_access_obj)
            );
            window.localStorage.setItem(
              "pfs_access_email",
              response.data.email
            );

            window.localStorage.setItem("pfs_access_id", response.user_id);
            window.localStorage.setItem("pfs_access_comp", roleName.individual);
            // window.localStorage.setItem("pfs_comp_id", "");
            window.localStorage.setItem("pfs_comp_name", "");
            window.localStorage.setItem(
              "pfs_access_comp_first",
              "FirstINDIVIDUAL"
            );
          }
          window.location.href = "/individual";
          // navigateBack("/individual");
          // setIs_loader(false);
        } else {
          // setIs_loader(false);
          toastMessage(response.message, "error");
          // getIndividuals();
        }
      })
      .catch((err) => {
        // setIs_loader(false);
        toastMessage(" access_indiPFS_fromWealth:" + err.message, "error");
      });
  }

  if (width <= 991) {
    sideBarStyle = { paddingLeft: "0px", paddingRight: "0px" };
  } else {
    sideBarStyle = openSide
      ? { paddingLeft: "272px", paddingRight: "0px", transition: "all 0.3s" }
      : { paddingLeft: "72px", paddingRight: "0px", transition: "all 0.3s" };
  }
  useEffect(() => {
    getIndividuals();
  }, []);

  const sharePFS = (params) => {
    setShowSharePFSModal(false);
    if (params !== undefined) {
      dispatch(
        requestEmailLink({
          email: params,
        })
      )
        .unwrap()
        .then((response) => {
          const { email, link, firstName, lastName, displayName, id } =
            response;
          setShareEmail({
            email: email,
            sharelink: link,
            firstName,
            lastName,
            displayName,
            id,
          });
        })
        .catch((err) => {
          toastMessage("SharePFSModal :" + err.message, "error");
        });
    }
  };

  // useEffect(() => {
  //   socket.on("get_updated_list", (msg) => {
  //     getIndividuals();
  //   });
  //   socket.on('signature_request_status_update', (msg) => {
  //     fetchSignatureRequests();
  //   });
  //   socket.on("get_updated_list", (msg) => {
  //     getIndividuals();
  //   });

  // }, []);
  useEffect(() => {
    const handleUpdatedList = (msg) => {
      getIndividuals();
    };
    const handleSignatureRequestStatusUpdate = (msg) => {
      fetchSignatureRequests();
    };
    socket.on("get_updated_list", handleUpdatedList);
    socket.on(
      "signature_request_status_update",
      handleSignatureRequestStatusUpdate
    );
    return () => {
      socket.off("get_updated_list", handleUpdatedList);
      socket.off(
        "signature_request_status_update",
        handleSignatureRequestStatusUpdate
      );
    };
  }, []);

  useEffect(() => {
    if (user && !user.user.address && user.user.initial_setup_done == false) {
      setShowProfileModal(true);
      setIsLoader(true);
    } else {
      setShowProfileModal(false);
      setIsLoader(false);
    }
  }, [user]);

  useEffect(() => {
    const activeTab = pathname.split("/")[2] || "dashboard";
    dispatch(setActiveTabState(activeTab));
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("storage", (event) => {
      if (event.key === null) {
        navigateBack("/");
      }
    });
  }, []);

  return (
    <>
      <div
        className={`cs-main-layout ${
          openSide ? "cs-nav-toggled" : "menuClosed"
        }`}>
        {isLoader === false &&
        (loggedUser.is_subscribe == true ||
          loggedUser.is_beta_user === true) ? (
          <SidebarSection
            setModalState={setModalState}
            modalState={modalState}
            getIndividuals={getIndividuals}
            toggle={toggle}
            isOpen={isOpen}
            handleclick={handleclick}
            individual={individual}
            wealthmanager={wealthmanager}
            company={company}
            requestactive={requestactive}
            show={show}
            changeToggle={changeToggle}
            toastMessage={toastMessage}
            CapsyncMessage={CapsyncMessage}
            wmDetails={wmDetails}
            CapsyncLogoutModal={CapsyncLogoutModal}
            customImgSrc={customImgSrc}
            isMobile={isMobile}
            openSide={openSide}
            setOpenSide={setOpenSide}
            isLoader={isLoader}
            setShowModal={setShowModal}
            setShowInitialModal={setShowInitialModal}
          />
        ) : (
          !isMobile && <SkeletonSidebar />
        )}
        {/* <SkeletonSidebar /> */}
        <div className="cs-main-area">
          <Container fluid style={sideBarStyle}>
            {isLoader === false &&
            (loggedUser.is_subscribe == true ||
              loggedUser.is_beta_user === true) ? (
              <NavbarSection
                isMobile={isMobile}
                callThisFun={callThisFun}
                getIndividuals={getIndividuals}
                toggle={toggle}
                storeAllNotifications={storeAllNotifications}
                getNotificationRequests={getNotificationRequests}
                wealthmanager={wealthmanager}
                wmDetails={wmDetails}
                CapsyncMessage={CapsyncMessage}
                toastMessage={toastMessage}
                CapsyncLogoutModal={CapsyncLogoutModal}
                customImgSrc={customImgSrc}
                profileState={profileState}
                toggleSidebar={toggleSidebar}
                openSide={openSide}
                isLoader={isLoader}
                user={user}
                setShowModal={setShowModal}
                setShowInitialModal={setShowInitialModal}
                accessPFS={accessPFS}
              />
            ) : (
              <SkeletonNavbar />
            )}

            {/* <div className="cs-context"> */}
            <div
              className={
                loggedUser.is_subscribe == true ||
                loggedUser.is_beta_user === true
                  ? "cs-context"
                  : "cs-context skeleton-for-mob"
              }>
              <Page
                handleclick={handleclick}
                modal={toggle}
                CapsyncMessage={CapsyncMessage}
                toastMessage={toastMessage}
                CapsyncDeleteMessage={CapsyncDeleteMessage}
                changeSrcStatus={changeSrcStatus}
                changeProfileState={changeProfileState}
                customImgSrc={customImgSrc}
                customLoginImgSrc={customLoginImgSrc}
                setCustomLoginImgSrc={setCustomLoginImgSrc}
                profileState={profileState}
                wealthManagerData={wmDetails}
                getIndividuals={getIndividuals}
                isLoader={isLoader}
                user={user}
                accessPFS={accessPFS}
              />
            </div>
          </Container>
        </div>

        <CompleteProfile
          showModal={showProfileModal}
          toastMessage={toastMessage}
          changeSrcStatus={changeSrcStatus}
          changeProfileState={changeProfileState}
          customImgSrc={customImgSrc}
          profileState={profileState}
          wealthManagerData={wealthManagerData}
          CapsyncLogoutModal={CapsyncLogoutModal}
          customLoginImgSrc={customLoginImgSrc}
          setCustomLoginImgSrc={setCustomLoginImgSrc}
        />

        {user &&
        user.payment_method &&
        user.payment_method.length > 0 &&
        loggedUser.is_subscribe == true &&
        loggedUser.is_beta_user === false ? (
          <MicroDepositeVerificationModal />
        ) : (
          user &&
          loggedUser.is_subscribe == false &&
          loggedUser.is_beta_user === false &&
          loggedUser.address && (
            <SubscribeModal CapsyncLogoutModal={CapsyncLogoutModal} />
          )
        )}

        {/* {(user && loggedUser.is_subscribe=== true && loggedUser.initial_setup_done === true) && (filteredIndividuals.length===0 && !loading)&&
          <AddIndividualModals modal={modal} toastMessage={toastMessage} getIndividuals={getIndividuals}/>
      } */}

        <SharePFSModal
          showSharePFSModal={showSharePFSModal}
          setShowSharePFSModal={setShowSharePFSModal}
          sharePFS={sharePFS}
          shareEmail={shareEmail}
          changeToPreviewModal={setPreviewModal}
        />

        <PfsPreviewModal
          previewModal={previewModal}
          shareEmail={shareEmail}
          changeToPreviewModal={setPreviewModal}
        />

        <ExportPdfExcelModal
          exportPdfExcelModal={exportPdfExcelModal}
          changeexportPdfExcelModal={setExportPdfExcelModal}
          exportUserId={row_user_id}
        />

        <DeleteModal
          CapsyncDeleteMessage={CapsyncDeleteMessage}
          deleteStatus={deleteStatus}
        />
        <LogoutModal
          logoutStatus={logoutStatus}
          CapsyncLogoutModal={CapsyncLogoutModal}
        />

        {user &&
          (user.user.is_subscribe === true ||
            user.user.is_beta_user === true) &&
          user.user.initial_setup_done === true &&
          !!showModal &&
          !isLoader && (
            <AddIndividualModals
              modal={toggle}
              toastMessage={toastMessage}
              getIndividuals={getIndividuals}
              showModal={showInitialModal}
              setShowModal={setShowInitialModal}
              filtered={individuals}
              CapsyncLogoutModal={CapsyncLogoutModal}
            />
          )}
      </div>
    </>
  );
};

export default WmLayout;
