import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";

import img1 from "../../../../../assets/images/other/asset-group.svg";
import img2 from "../../../../../assets/images/other/liability-group.svg";
import commonNames from "../../../../config/commonNames";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import "../../../../../app/pages/individual/dashboard/InitialSetup/initial-setup.css";
import CategoryTableData from "../../../../commonComponent/CategoryTableData";
import { useSelector } from "react-redux";

const InitialSetup2 = (props) => {
  const { storeTypeCategory, fetchTypeCategory, dataAsset, dataLiability, previous, next, showModal, checkCategory, checkType, inputTarget, closeTheModal } = props;
  const reducerData = useSelector((state) => state);
  useEffect(() => {
    storeTypeCategory('category', '');
    storeTypeCategory('type', 0);
  }, [])

  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  // const fetchFun = useContext(Tree);


  window.localStorage.setItem("type", type);
  window.localStorage.setItem("category", category);
  const fetchCategory = window.localStorage.getItem("category");

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => closeTheModal(false)}
        size="cs-s-modal"
        backdrop="static"
        id="connect-your-institutions"
        aria-labelledby="contained-modal-title-vcenter"
        className={`cap-dash-main-modal plaid-modal plaid-progress-modal`}
        centered
        keyboard={false}
        dialogClassName="cs-s-modal"
      >
        {dataAsset !== "0.00" || dataLiability !== "0.00" ? (
          <Modal.Header>
            <span className="cs-neutral-70 cs-previous-btn" onClick={previous}>
              <CapsyncIcon
                title="chevron-left-outlined"
                size="24px"
                alt="LeftIcon"
              />
            </span>
            <Modal.Title className="default-semi-bold-h3 cs-neutral-100 card-modal-head">
              {commonNames.Add_Account}
            </Modal.Title>
            <span className="cs-neutral-70 cs-close-btn" onClick={() => closeTheModal(false)}><CapsyncIcon title="close-outlined" size="20" /></span>
          </Modal.Header>
        ) : (
          <Modal.Header>
            <span className="cs-neutral-70 cs-previous-btn" onClick={previous}>
              <CapsyncIcon
                title="chevron-left-outlined"
                size="24px"
                alt="LeftIcon"
              />
            </span>
            <Modal.Title className="default-semi-bold-h3 cs-neutral-100 card-modal-head">
              {commonNames.Add_Account}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <div className="text-center">
            <Row>
              <Col lg={12}>
                <span className="default-regular-h4 cs-neutral-90">You need to enter your transactions manually for this account</span>
                <div className="account-popup-setup2 default-regular-sub-heading-m cs-neutral-90">
                  <div
                    className={
                      fetchTypeCategory.category === "Assets" || fetchTypeCategory.category === "Liability"
                        ? "choose-account-category"
                        : "choose-account-category selected-category"
                    }
                  >
                    <h4 className="default-semi-bold-h4 cs-neutral-100">
                      Choose manual account type
                    </h4>

                    <CategoryTableData
                      params='Assets'
                      category={fetchTypeCategory.category}
                      mainName={commonNames.Assets}
                      checkCategory={() => checkCategory('Assets')}
                      type='category_button'
                    />

                    <div className="cs-divider">
                      <span className="default-regular-body-text-s cs-neutral-50">
                        OR
                      </span>
                    </div>

                    <CategoryTableData
                      params='Liability'
                      category={fetchTypeCategory.category}
                      mainName={commonNames.Liabilities}
                      checkCategory={() => checkCategory('Liability')}
                      type='category_button'
                    />
                  </div>

                  {fetchTypeCategory.category === "Assets" && (
                    <>
                      <CategoryTableData
                        financeType="assets"
                        checkType={checkType}
                        data={reducerData.category.categoryListing.assets}
                        src={img1}
                        catType={type}
                        message='asset'
                        type='add_manual_category_dropdown'
                      />
                    </>
                  )}
                  {fetchTypeCategory.category === "Liability" && (
                    <>
                      <CategoryTableData
                        financeType="liability"
                        checkType={checkType}
                        data={reducerData.category.categoryListing.liabilities}
                        src={img2}
                        catType={type}
                        message='liability'
                        type='add_manual_category_dropdown'
                      />
                    </>
                  )}
                </div>

                <div className="cap-modal-fotter bottom-section-modal">
                  <Button
                    className="cs-sec-sm-btn-grey select-add-account default-regular-h5"
                    onClick={next}
                    ref={inputTarget}
                    style={{ display: "none" }}
                  >
                    Continue
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InitialSetup2;
