import React from 'react'
import Skeleton from 'react-loading-skeleton';
import {
    Row,
    Col,
    Form,
    Button,
} from "react-bootstrap";

const SkeletonFileEncryption = () => {
    return (
        <div className="file-encryption-page">
            <div className="text">
                <h3 className="skeleton-file-encryption-heading">
                    <Skeleton height={28} />
                </h3>
                <p className="skeleton-file-encryption-para">
                    <Skeleton />

                </p>
            </div>
            <Form className="form-input-button">
                <Row>
                    <Col lg={5} md={9} sm={9}>
                        <Form.Group
                            className="cs-form-group skeleton-file-encryption-input"
                        >
                            <Skeleton height={40} />
                        </Form.Group>

                    </Col>
                </Row>

            </Form>

            <div className="change-password-button">
                <Skeleton width={195} height={40} />
            </div>
        </div>
    )
}

export default SkeletonFileEncryption