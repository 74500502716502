import React, { createContext, useState } from "react";
import {
    Modal,
    Button,
    ModalFooter,
} from "react-bootstrap";

import CommonMfEmailPin from "../../pages/individual/settings/commonMfEmailPin";
import CommonMfPhonePin from "../../pages/individual/settings/commonMfPhonePin";
import CommonMfGooglePin from "../../pages/individual/settings/commonMfGooglePin";

import CapsyncIcon from "../CapsyncIcon";

export const Themeprovider = createContext(null);

const AuthMFAOptionModal = (props) => {

    const { modalShow, setModalShow, mfaType, record, MFAoptionValue,
        MFAEmail, mfOptionEmail, MFAPhone, mfOptionPhone,
        MFAGoogle, mfOptionGoogle, message, commonMfaSetup, handleAccountProfileUpdate, save, saveData, forFileEncryption
    } = props;
    const [selectedOption, setSelectedOption] = useState(null);

    return (
        <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="md"
            centered
            backdrop="static"
            dialogClassName="cs-md-modal"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header>
                <Modal.Title className="default-semi-bold-h3">
                    {mfaType === '1' ? "Email Verification" : mfaType === '2' ? "Mobile Verification" : mfaType === '3' ? "Authentication Verification" : "Profile Update"}
                </Modal.Title>
                {/* <CloseButton onClick={() => setModalShow(false)} /> */}
            </Modal.Header>
            <Modal.Body>
                {mfaType === 0 && record && (
                    <div className="choose-password-section">
                        <h5 className="default-regular-h5 cs-neutral-80">
                            Choose your preferred authentication method
                        </h5>
                        {MFAoptionValue && MFAEmail && (
                            <div
                                // className="email-verification cursor-pointer"
                                className={`email-verification cursor-pointer ${selectedOption === "email" ? "active" : ""
                                    }`}
                                onClick={() => mfOptionEmail()}
                            >
                                <div className="radio-text">
                                    <div className="cs-radio-btn">
                                        <input
                                            type="radio"
                                            name="mfOption"
                                            id="email"
                                            value="1"
                                            className="radio-button cursor-pointer"
                                            checked={selectedOption === "email"}
                                            onChange={() => { }}
                                        />
                                    </div>
                                    <div className="choose-pass-text">
                                        <h4 className="default-regular-h4 cs-neutral-90">
                                            Email verification
                                        </h4>
                                        <h6 className="default-regular-h6 cs-neutral-70">
                                            Get a code sent to your email verification
                                        </h6>
                                    </div>
                                </div>
                                <span className="cs-neutral-80">
                                    <CapsyncIcon title="email-outlined" size="22" />
                                </span>
                            </div>
                        )}
                        {MFAoptionValue && MFAPhone && (
                            <div
                                // className="mobile-verification cursor-pointer"
                                className={`mobile-verification cursor-pointer ${selectedOption === "phone" ? "active" : ""
                                    }`}
                                onClick={() => mfOptionPhone()}
                            >
                                <div className="radio-text">
                                    <div className="cs-radio-btn">
                                        <input
                                            type="radio"
                                            name="mfOption"
                                            id="mobile"
                                            value="2"
                                            className="radio-button cursor-pointer"
                                            checked={selectedOption === "phone"}
                                            onChange={() => { }}
                                        />
                                    </div>
                                    <div className="choose-mob-text">
                                        <h4 className="default-regular-h4 cs-neutral-90">
                                            Mobile verification
                                        </h4>
                                        <h6 className="default-regular-h6 cs-neutral-70">
                                            Get a code sent to your phone via SMS
                                        </h6>
                                    </div>
                                </div>
                                <span className="cs-neutral-80">
                                    <CapsyncIcon title="mobile-outlined" size="22" />
                                </span>
                            </div>
                        )}
                        {MFAoptionValue &&
                            MFAGoogle &&
                            record.MFAGoogleSecret !== false && (
                                <div
                                    // className="authentication-app cursor-pointer"
                                    className={`authentication-app cursor-pointer ${selectedOption === "google" ? "active" : ""
                                        }`}
                                    onClick={() => mfOptionGoogle()}
                                >
                                    <div className="radio-text">
                                        <div className="cs-radio-btn">
                                            <input
                                                type="radio"
                                                name="mfOption"
                                                id="auth"
                                                value="3"
                                                className="radio-button cursor-pointer"
                                                checked={selectedOption === "google"}
                                                onChange={() => { }}
                                            />
                                        </div>
                                        <div className="choose-auth-text">
                                            <h4 className="default-regular-h4 cs-neutral-90">
                                                Authentication App
                                            </h4>
                                            <h6 className="default-regular-h6 cs-neutral-70">
                                                Connect your authenticator app
                                            </h6>
                                        </div>
                                    </div>
                                    <span className="cs-neutral-80">
                                        <CapsyncIcon title="qr-code-outlined" size="22" />
                                    </span>
                                </div>
                            )}
                        {/* {message && (
                            <div className="cs-msg default-regular-body-text-s cs-danger">
                                <span className="icon">
                                    <CapsyncIcon title="info-filled" />
                                </span>
                                <span className="txt"> {message}</span>
                            </div>
                        )} */}
                    </div>
                )}
                <Themeprovider.Provider
                    value={{ closeModal: () => commonMfaSetup(false) }}
                >
                    {mfaType === '1' && <CommonMfEmailPin setModalShow={setModalShow} save={save} saveData={saveData} notitle={true} {...props} />}

                    {mfaType === '2' && <CommonMfPhonePin setModalShow={setModalShow} save={save} saveData={saveData} notitle={true} {...props} />}

                    {mfaType === '3' && <CommonMfGooglePin setModalShow={setModalShow} save={save} saveData={saveData} notitle={true} {...props} />}
                </Themeprovider.Provider>
            </Modal.Body>

        </Modal>
    );
}

export default AuthMFAOptionModal;