import React from "react";
import {
  Button,
  Image,
  Modal
} from "react-bootstrap";
import { useDispatch } from "react-redux";

// import { CancelSignatureRequest } from "../../../slices/requests";

const CancelRequestConfirmation = (props) => {
  const dispatch = useDispatch();
  const { showSignatureRequestCancel,
    setShowSignatureRequestCancel,
    onSignatureRequestCancelConfirm,
    signatureRequestId,
    sendUser,
    toastMessage
  } = props;


  const handleSignatureRequestDecline = () => {
    // dispatch(CancelSignatureRequest({ id: signatureRequestId, userEmail: sendUser }))
    //   .unwrap()
    //   .then((response) => {
    //     onSignatureRequestDecline();
    //   })
    //   .catch((err) => {
    //     toastMessage('Failed to Decline Signature request', "error");
    //   });
  };

  return (
    <Modal
      dialogClassName="cs-md-modal"
      show={showSignatureRequestCancel}
      onHide={!showSignatureRequestCancel}
      backdrop="static"
      keyboard={false}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title className="default-semi-bold-h3">
          Cancel request confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="log-out-popup">
          <span>
            <Image
              src={require("../../../../assets/images/other/error-validation.svg")}
              alt="popup_error"
              className="action-icon"
              width={100}
            />
          </span>
          <p className="default-regular-body-text-m cs-neutral-90">
            You are cancelling the signature, are you sure? you want to cancel this request.
          </p>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <div className="cs-modal-btn cs-center-btn">
          <Button className="cs-btn-secondary md-btn default-regular-h5" onClick={() => setShowSignatureRequestCancel(false)} >
            Cancel
          </Button>
          <Button className="cs-btn-primary md-btn default-regular-h5" onClick={() => onSignatureRequestCancelConfirm()}>
            Yes
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
};

export default CancelRequestConfirmation;