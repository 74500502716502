import React from "react";
import { Image } from "react-bootstrap";
import "./Dashboard.css"

const SubCardComponent = ({ class1, img, class3, data, title }) => {
  return (
    <>
      <div className={class1}>
        <div className="card-icon">
          <Image src={img} alt="Icons" width={40} height={40} />
        </div>
        <div className="card-item">
          <span className="default-regular-h4 cs-neutral-90">{title}</span>
          <h3 className="default-semi-bold-h2 cs-neutral-100">{data}</h3>
        </div>
      </div>
    </>
  );
};

export default SubCardComponent;
