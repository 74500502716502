import React, { useEffect, useState } from "react";
import {
  Row,
  Form,
  Button,
  Modal,
  FormLabel,
  Image,
  ModalFooter,
  Col,
  Dropdown,
} from "react-bootstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { subscription_plan_cancel_reason } from "../../../../slices/user_subscription";
import ErrorValidation from "../../../../../assets/images/other/error-validation.svg";
import CapsyncIcon from "../../../../commonComponent/CapsyncIcon";
import { Rating } from "react-simple-star-rating";
import CapsyncLoader from "../../../../commonComponent/CapsyncLoader";
import { toast } from "react-toastify";

const CancelPlanFeedback = (props) => {
  const {
    setShowFeedback,
    showFeedback,
    confirmCancelPlan,
    showConfirmModal,
    setShowConfirmModal,
    toastMessage,
  } = props;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  let id;
  let role;

  if (window.localStorage.getItem("pfs_access_id") !== null) {
    id = Number(window.localStorage.getItem("pfs_access_id"));
    role = 1;
  } else {
    id = user && user.user.id;
    role = user && user.user_role.role;
  }

  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(0);
  const [showError, setShowError] = useState(false);
  const handleRating = (rate) => {
    setRating(rate);
  };
  const cancellationReasonArray = [
    "CapSync is too expensive for my budget.",
    "I have found a similar software with better features or pricing.",
    " CapSync is missing essential features or functionality that I need.",
    " I have experienced technical problems that were not resolved satisfactorily.",
    " My needs have changed, and the software is no longer suitable.",
    " I have had unsatisfactory experiences with customer support.",
    "I am concerned about the security or privacy of my data.",
    " Other",
  ];
  const [cancellationReason, setCancellationReason] = useState("Select Option");

  const handleClick = (param) => {
    setCancellationReason(param);
  };

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      notes: "",
    },

    onSubmit: async (values) => {
      if (cancellationReason !== "Select Option") {
        setShowError(false);
        setLoading(true);
        let data = {
          array: [
            {
              reason_question:
                "How would you rate your experience with CapSync?",
              reason_answer: rating ? rating : "",
            },
            {
              reason_question:
                "What is the reason for the cancellation of the plan?",
              reason_answer:
                cancellationReason !== "Select Option"
                  ? cancellationReason
                  : "",
            },
            {
              reason_question: "Any feedbacks or suggestions?",
              reason_answer: values.notes ? values.notes : "",
            },
          ],
          user_id: id,
          role: role,
        };
        dispatch(subscription_plan_cancel_reason(data))
          .then((response) => {
            setLoading(false);
            if (response.payload && response.payload.code === 200) {
              setShowFeedback(false);
              setShowConfirmModal(true);
              setCancellationReason("Select Option");
              handleReset();
              setRating(0);
              toast.success("Thank you for your feedback!");
            }
          })
          .catch((err) => {
            setLoading(false);
            toastMessage(err.message || "An error occurred", "error");
          });
      } else {
        setShowError(true);
      }
    },
  });

  const handleCancel = () => {
    setShowFeedback(false);
    handleReset();
    setCancellationReason("Select Option");
    setRating(0);
    setShowError(false);
  };

  const handleSkip = () => {
    setShowFeedback(false);
    setShowConfirmModal(true);
    handleReset();
    setCancellationReason("Select Option");
    setRating(0);
    setShowError(false);
  };
  // const onPointerMove = (value) => console.log("onPoint", value)
  const customStarSvgFilled = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
    >
      <path
        d="M11.5734 1.39057C12.0224 0.00860596 13.9776 0.00861001 14.4266 1.39058L16.2555 7.01925C16.4563 7.63729 17.0322 8.05573 17.682 8.05573H23.6004C25.0535 8.05573 25.6576 9.91515 24.4821 10.7693L19.694 14.248C19.1683 14.6299 18.9483 15.307 19.1491 15.925L20.978 21.5537C21.427 22.9357 19.8453 24.0848 18.6697 23.2307L13.8817 19.752C13.3559 19.3701 12.6441 19.3701 12.1183 19.752L7.33028 23.2307C6.15471 24.0849 4.57299 22.9357 5.02202 21.5537L6.85089 15.925C7.0517 15.307 6.83171 14.6299 6.30598 14.248L1.51794 10.7693C0.34237 9.91515 0.946536 8.05573 2.39962 8.05573H8.31796C8.9678 8.05573 9.54374 7.63729 9.74455 7.01925L11.5734 1.39057Z"
        fill="#EA780E"
      />
    </svg>
  );
  const customStarSvg = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
    >
      <path
        d="M11.5734 1.39057C12.0224 0.00860596 13.9776 0.00861001 14.4266 1.39058L16.2555 7.01925C16.4563 7.63729 17.0322 8.05573 17.682 8.05573H23.6004C25.0535 8.05573 25.6576 9.91515 24.4821 10.7693L19.694 14.248C19.1683 14.6299 18.9483 15.307 19.1491 15.925L20.978 21.5537C21.427 22.9357 19.8453 24.0848 18.6697 23.2307L13.8817 19.752C13.3559 19.3701 12.6441 19.3701 12.1183 19.752L7.33028 23.2307C6.15471 24.0849 4.57299 22.9357 5.02202 21.5537L6.85089 15.925C7.0517 15.307 6.83171 14.6299 6.30598 14.248L1.51794 10.7693C0.34237 9.91515 0.946536 8.05573 2.39962 8.05573H8.31796C8.9678 8.05573 9.54374 7.63729 9.74455 7.01925L11.5734 1.39057Z"
        fill="#E0E0E0"
      />
    </svg>
  );
  return (
    <>
      <Modal
        show={showFeedback}
        onHide={() => setShowFeedback(false)}
        backdrop="static"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
        className="cs-cancelling-popup cs-common-modal-overlay"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
            Tell us why are you leaving
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <div className="cs-rating">
                  <label className="default-regular-sub-heading-xs cs-neutral-100">
                    How would you rate your experience with CapSync?
                  </label>
                  <Rating
                    onClick={handleRating}
                    // onPointerMove={onPointerMove}
                    transition={true}
                    size="32"
                    fillColor={"#EA780E"}
                    emptyIcon={customStarSvg}
                    fillIcon={customStarSvgFilled}
                    starSpacing="12"
                  />
                </div>

                <Form.Group className="cs-form-group">
                  <FormLabel>Reason for cancellation</FormLabel>
                  {/* <Form.Control
                    type="text"
                    name="experience"
                    value={values.experience}
                    onChange={handleChange}
                    className={values.experience && "cs-input-field-active"}
                    onBlur={handleBlur}
                  /> */}
                  <div className="cs-common-add-dropdown">
                    <Dropdown className="cs-form-dropdown">
                      <Dropdown.Toggle className="default-regular-sub-heading-s dropdown-toggle cs-neutral-70">
                        {/* {cancellationReason} */}
                        {cancellationReason === ""
                          ? "Select trim"
                          : window.innerWidth <= 575
                          ? cancellationReason.length > 30
                            ? cancellationReason.slice(0, 30) + "..."
                            : cancellationReason
                          : cancellationReason.length > 50
                          ? cancellationReason.slice(0, 50) + "..."
                          : cancellationReason}

                        <span className="cs-neutral-90">
                          <CapsyncIcon
                            title="chevron-down-outlined"
                            size="18"
                          />
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {cancellationReasonArray.map((item, key) => (
                          <Dropdown.Item
                            className={
                              item === cancellationReason
                                ? "dropdown-item dropdown-item-active default-regular-sub-heading-s"
                                : "default-regular-sub-heading-s cs-neutral-70 cs-content-title"
                            }
                            key={key}
                            onClick={() => handleClick(item)}
                            onChange={(event) =>
                              setCancellationReason(event.target.value)
                            }
                          >
                            {item}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                      {cancellationReason === "Select Option" && showError ? (
                        <span className="form-error-msg default-light-body-text-s cs-danger">
                          Please Select the reason for cancellation
                        </span>
                      ) : null}
                    </Dropdown>
                  </div>
                </Form.Group>

                <Form.Group className="cs-form-group">
                  <Form.Label>Enter feedback</Form.Label>
                  <textarea
                    autoComplete="off"
                    type="text"
                    placeholder="Optional"
                    name="notes"
                    defaultValue={values.notes}
                    className={
                      values.notes
                        ? "cs-input-field-active cs-textarea"
                        : "cs-textarea"
                    }
                    onChange={handleChange}
                    // onBlur={handleBlur}
                  ></textarea>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <ModalFooter>
          <a
            onClick={handleSkip}
            className="cs-btn-icon-tertiary cs-main-primary default-regular-h5"
          >
            Skip
          </a>
          <div className="cs-modal-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              type="button"
              onClick={handleCancel}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              className="cs-btn-primary md-btn default-regular-h5"
              onClick={handleSubmit}
              disabled={rating === 0}
            >
              Continue
            </Button>
          </div>
        </ModalFooter>
        {loading ? (
          <>
            <CapsyncLoader />
          </>
        ) : (
          ""
        )}
      </Modal>

      <Modal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        backdrop="static"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
        className="cs-common-modal-overlay"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3 cs-neutral-100">
            Are you sure?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-modal-content cs-cancelling-popup">
            <Image
              src={ErrorValidation}
              alt="Error Validation"
              width={100}
              height={100}
            />
            <h4 className="default-regular-sub-heading-m cs-neutral-100">
              You are cancelling subscription
            </h4>
            <p className="default-light-body-text-m cs-neutral-90">
              You are about to cancel your subscription, after that are no
              longer able to access CapSync and you have loss all your data
              access after expire this subscription.
            </p>
          </div>
        </Modal.Body>
        <ModalFooter>
          <div className="cs-modal-btn cs-center-btn">
            <Button
              className="cs-btn-secondary md-btn default-regular-h5"
              type="button"
              onClick={() => {
                setShowConfirmModal(false);
                handleReset();
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="cs-btn-primary md-btn default-regular-h5"
              onClick={confirmCancelPlan}
            >
              Continue
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CancelPlanFeedback;
