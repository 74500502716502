import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router";
import "../../../../pages/individual/settings/CommonHeader/common_setting.css";
import { useSelector } from "react-redux";

const CommonSettingHeader = () => {
  const { user, authUser } = useSelector((state) => state.auth);
  const role = user && user.user_role && user.user_role.role;
  const navigate = useNavigate();
  function goToTab(params) {
    navigate(params);
  }

  return (
    <>
      <div className="settings-tab-btn cs-tabs">
        <Button
          onClick={() => {
            role == 2
              ? goToTab("/financial-advisor/account-details")
              : goToTab("/professional/account-details");
          }}
          className={
            window.location.pathname === "/financial-advisor/account-details" ||
            window.location.pathname === "/professional/account-details"
              ? "cs-stroke-bottom-tab default-regular-h5 cs-stroke-bottom-active"
              : "cs-stroke-bottom-tab default-regular-h5"
          }
        >
          Account Details
        </Button>
        {/* <Button
          onClick={() => {
            role == 2
              ? goToTab("/financial-advisor/file-encryption")
              : goToTab("/professional/file-encryption");
          }}
          className={
            window.location.pathname === "/financial-advisor/file-encryption" ||
            window.location.pathname === "/professional/file-encryption"
              ? "cs-stroke-bottom-tab default-regular-h5 cs-stroke-bottom-active"
              : "cs-stroke-bottom-tab default-regular-h5"
          }>
          File Encryption
        </Button> */}
        <Button
          onClick={() => {
            role == 2
              ? goToTab("/financial-advisor/mfa-setup")
              : goToTab("/professional/mfa-setup");
          }}
          className={
            window.location.pathname === "/financial-advisor/mfa-setup" ||
            window.location.pathname === "/professional/mfa-setup"
              ? "cs-stroke-bottom-tab default-regular-h5 cs-stroke-bottom-active"
              : "cs-stroke-bottom-tab default-regular-h5"
          }
        >
          MFA Setup
        </Button>
        {user.user.is_beta_user === false && (
          <Button
            onClick={() => {
              role == 2
                ? goToTab("/financial-advisor/billing-subscription")
                : goToTab("/professional/billing-subscription");
            }}
            className={
              window.location.pathname ===
                "/financial-advisor/billing-subscription" ||
              window.location.pathname === "/professional/billing-subscription"
                ? "cs-stroke-bottom-tab default-regular-h5 cs-stroke-bottom-active"
                : "cs-stroke-bottom-tab default-regular-h5"
            }
          >
            Billing & Subscription
          </Button>
        )}
        <Button
          onClick={() => {
            role == 2
              ? goToTab("/financial-advisor/audit-logs")
              : goToTab("/professional/audit-logs");
          }}
          className={
            window.location.pathname === "/financial-advisor/audit-logs" ||
            window.location.pathname === "/professional/audit-logs"
              ? "cs-stroke-bottom-tab default-regular-h5 cs-stroke-bottom-active"
              : "cs-stroke-bottom-tab default-regular-h5"
          }
        >
          Audit Logs
        </Button>
      </div>
    </>
  );
};

export default CommonSettingHeader;
