/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CommonMethodsHeader from "./CommonMethodsHeader";
import commonNames from "../../../../../config/commonNames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { add_update_verification_methodId } from "../../../../../slices/verifyInvestor";
import UseFetchPermission from "../../../../../customHooks/UseFetchPermission";
import PermissionPrevent from "../../../../PermissionPrevent";
import CapsyncLoader from "../../../../../commonComponent/CapsyncLoader";
import SubMethodsList from "../SubMethodsList";
import { Modal } from "react-bootstrap";
import CapsyncIcon from "../../../../../commonComponent/CapsyncIcon";
import WarningModal_VI from "../Modals/WarningModal_VI";
import CapsyncToolTip from "../../../../../commonComponent/capsyncTooltip/CapsyncToolTip";
import { toast } from "react-toastify";
import SkeletonMethodsDashboard from "./SkeletonMethodsDashboard";
import ConfirmationModel from "../Modals/ConfirmationModel";

const EntityMethods = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fetchUserPermission } = UseFetchPermission();
  // const [enableLoader, setEnableLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showEntityTwoOwners, setShowEntityTwoOwners] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false)

  // const [selectInverstorType, setSelectInverstorType] = useState("Select Option");

  const Vi_Location = useLocation();
  let unique_string = Vi_Location.pathname.split("/").slice(-1).toString();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleConfirmationModal = () => {
    setShowConfirmation(true);
  }

  const GoToSubMethods = (type, methodID) => {
    // if (selectInverstorType !== "Select Option" ){
    // setEnableLoader(true);
    setShowPopup(false)
    setShowWarning(false);
    dispatch(
      add_update_verification_methodId({
        unique_rowID: unique_string,
        verification_method_id: methodID,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.code == 200) {
          setTimeout(() => {
            navigate(
              `/individual/verifyinvestor/accreditedInvestor/${type}/${unique_string}/${methodID}`
            );
          }, 1000);
          // setEnableLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }else {
    //   toast.error("Select an investor type.")
    // }

  };
  const handlePopup = () => {
    setShowPopup(true);
  }
  const handleClickEntity = () => {
    setShowEntityTwoOwners(true);
    setShowPopup(false);
  }

  return (
    <>

      <section className="accreditation-main-section accreditation-inner-section">
        {/* {enableLoader ? (
            <CapsyncLoader />
          ) : ( */}
        <Container fluid>
          {props.is_loader.assets === true || props.is_loader.liabilities === true ? < SkeletonMethodsDashboard investorType="individualMethod" /> :
            <>
              <CommonMethodsHeader investorType="individualMethod"
              // selectInverstorType={selectInverstorType} setSelectInverstorType={setSelectInverstorType} 
              />
              {!showEntityTwoOwners &&
                <div className="verification-method-box">
                  <div className="accredited-method">
                    <Row>
                      <Col lg={12}>
                        <span className="cs-neutral-100 default-medium-sub-heading-m">Verification Method</span>
                      </Col>
                    </Row>
                  </div>
                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={14} /></div>
                      </Col>
                      <Col xl={1} md={3}>
                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5"
                          onClick={() => GoToSubMethods("entities", "14")}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>
                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={4} /></div>
                      </Col>
                      <Col xl={1} md={3}>
                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5"
                          onClick={() => GoToSubMethods("entities", "4")}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={5} /></div>
                      </Col>
                      <Col xl={1} md={3}>
                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5"
                          onClick={() => GoToSubMethods("entities", "5")}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={6} /></div>
                      </Col>
                      <Col xl={1} md={3}>
                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5"
                          onClick={() => GoToSubMethods("entities", "6")}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={7} /></div>
                      </Col>
                      <Col xl={1} md={3}>
                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5"
                          onClick={() => GoToSubMethods("entities", "7")}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={8} /></div>
                      </Col>
                      <Col xl={1} md={3}>
                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5"
                          onClick={() => GoToSubMethods("entities", "8")}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90">An entity in which all the equity owners are accredited investors.</div>
                      </Col>
                      <Col xl={1} md={3}>
                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5"
                          onClick={() => handlePopup(true)}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>
                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={10} /></div>
                      </Col>
                      <Col xl={1} md={3}>
                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5"
                          onClick={() => GoToSubMethods("entities", "10")}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={39} /></div>
                      </Col>
                      <Col xl={1} md={3}>
                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5"
                          onClick={() => GoToSubMethods("entities", "39")}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={40} /></div>
                      </Col>
                      <Col xl={1} md={3}>
                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5"
                          onClick={() => GoToSubMethods("entities", "40")}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>

                  <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        {/* <div className="default-regular-sub-heading-m cs-neutral-90"> The Special Situation verification method is rarely applicable as it either requires that either the individual be notably wealthy, such as being on the Forbes Wealthiest individuals list, or for the company being verified to be a publicly-traded company. If you believe you meet these criteria, please contact <Link target="_blank" to="mailto:accreditation@capsync.com">accreditation@capsync.com</Link> for guidance, if not, then kindly please select another verification method.</div> */}
                        <div className="default-regular-sub-heading-m cs-neutral-90"> <SubMethodsList methodId={15} /></div>
                      </Col>
                      <Col xl={1} md={3}>
                        <Button
                          className="cs-btn-danger sm-btn default-regular-h5"
                          onClick={() => setShowWarning(true)}
                        >
                          {commonNames.Select}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>}
              {showEntityTwoOwners &&
                <div className="verification-method-box">
                  <div className="accredited-method">
                    <Row>
                      <Col lg={12}>
                        <span className="cs-neutral-100 default-medium-sub-heading-m">Verification Method</span>
                      </Col>
                    </Row>
                  </div>
                  <div className="vi-innerArea">
                    <Row>
                      <Col xl={11} md={9}>
                        <div className="default-regular-sub-heading-m cs-neutral-90">
                          Are the two owners a married couple or  <CapsyncToolTip
                            Child={<u>spousal equivalent</u>}
                            placement={"top"}
                            width="250px"
                            message={
                              "A cohabitant occupying a relationship generally equivalent to that of a spouse."
                            }
                            type="text"
                          />
                        </div>
                      </Col>
                      <Col xl={1} md={3}>
                        <Button
                          className="cs-btn-primary sm-btn default-regular-h5 trust-btn"
                          onClick={() => navigate(`/individual/verifyinvestor/accreditedInvestor/individuals/${unique_string}`)}
                        >
                          Yes
                        </Button>

                        <Button
                          className="sm-btn default-regular-h5 cs-btn-danger"
                          onClick={() => GoToSubMethods("entities", "9")}
                        >
                          No
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>}
              <p className="default-regular-sub-heading-m">
                If you are not an accredited investor or wish to cancel this
                verification request, &nbsp;
                <Link
                  onClick={handleConfirmationModal}
                  className="vi-link-color "
                >
                  click here
                </Link>
              </p>
            </>
          }
        </Container>
        {/* )} */}
      </section>

      <Modal
        show={showPopup}
        onHide={!showPopup}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="cs-md-modal"
      >
        <Modal.Header>
          <Modal.Title className="default-semi-bold-h3">
            Verification Method
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-modal-content row-spacing">
            <p>How many equity owners are there?</p>
            <Button className="cs-btn-secondary xl-btn default-regular-h5" onClick={() => navigate(`/individual/verifyinvestor/accreditedInvestor/individuals/${unique_string}`)}>
              1 Owner (who is a natural person)
            </Button>
            <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

            <Button className="cs-btn-secondary xl-btn default-regular-h5" onClick={() => GoToSubMethods("entities", "9")}>
              1 Owner (That is an entity)</Button>
            <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

            <Button className="cs-btn-secondary xl-btn default-regular-h5" onClick={() => handleClickEntity()}>
              2 Owners</Button>
            <div className="cs-divider"><span className="default-regular-body-text-s cs-neutral-50">OR</span></div>

            <Button className="cs-btn-secondary xl-btn default-regular-h5" onClick={() => GoToSubMethods("entities", "9")}>
              More than 2 Owners</Button>
            <div className="cs-divider">
              <span className="default-regular-body-text-s cs-neutral-50">OR</span>
            </div>

            <Button className="cs-btn-secondary xl-btn default-regular-h5" onClick={() => setShowPopup(false)}>
              Cancel</Button>

          </div>
        </Modal.Body>
      </Modal>
      <WarningModal_VI showWarning={showWarning} onClose={() => setShowWarning(false)} onButtonClick={() => GoToSubMethods("entities", "16")} />
      <ConfirmationModel
        setShowConfirmation={setShowConfirmation}
        showConfirmation={showConfirmation}

      />

    </>
  );
};

export default EntityMethods;
