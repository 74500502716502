import React from "react";
import { ToastContainer } from "react-toastify";
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const MessageModal = ({ isOpen, title, message, type, setMsgModalOpen }) => {
  return (
    <>
      <div className="cs-toast">
        <ToastContainer 
        theme="light"
        position="top-right"
        autoClose={3000}
        pauseOnFocusLoss={false}
        newestOnTop
        hideProgressBar={true}
        transition={Flip}
        />
      </div>
    </>
  );
};

export default MessageModal;