import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import SubMenuHeader from "../SubMenuHeader";
import { combine } from "../../../../../../util/common";
import NumberFormat from "react-currency-format";
import LegalNameBlock from "../commonMethodBlocks/LegalNameBlock";
import CapsyncBreadcrumb from "../../../../../../commonComponent/CapsyncBreadcrumb";
import SubMethodsList from "../../SubMethodsList";
import ChangeLegalNameBlock from "../commonMethodBlocks/ChangeLegalNameBlock";
import UploadFileBlock from "../commonMethodBlocks/UploadFileBlock";
import NotesBlock from "../commonMethodBlocks/NotesBlock";
import { Link } from "react-router-dom";
import SkeletonAllSubMethods from "../SkeletonAllSubMethods";

// sequence No. one  (Asmita date-29th June => Do not remove this line)
const MethodIdOne = (props) => {
  const [newDollar, setDollar] = useState(false);


  let common;
  const check = (para, a) => {
    common = combine(para, a);
    if (common.value === "dollar") {
      setDollar(common.status);
    }
  };
  const mainLevelArr = [
    {
      title: "Investor Accreditation",
      url: "/individual/verifyinvestor/pending",
    },
    {
      title: "Accredited Investor",
      url: `/individual/verifyinvestor/accreditedInvestor/${props.verificationType}/${props.unique_code}`,
    },
  ];



  return (
    <>
      {props.is_loader.assets === true || props.is_loader.liabilities === true ? <SkeletonAllSubMethods /> :

        <>
          <div className="container-fluid">
            <div className="cs-section-topbar">
              <div className="cs-breadcrumb">
                <CapsyncBreadcrumb
                  mainTitle="Investor Accreditation"
                  mainUrl="/individual/verifyinvestor/pending"
                  subTitle="Income"
                  multiLevelFlag="true"
                  mainLevelArr={mainLevelArr}
                />
              </div>
              <SubMenuHeader />
            </div>
            <div className="method-section">

              <div className="default-regular-body-text-l cs-neutral-70"> <SubMethodsList methodId={1} /></div>

              <div className="cs-divider"></div>

              <LegalNameBlock />
              <Form>
                <ChangeLegalNameBlock
                  legalName={props.legalName}
                  changeLegalName={props.changeLegalName}
                  nameAlert={props.nameAlert} />

                <div className="row-spacing">
                  <p className="default-regular-body-text-l cs-neutral-70"> In order to verify your accreditation we need you to upload evidence. Please upload the appropriate files below and black-out any sensitive information.
                  </p>
                  <p className="default-regular-body-text-l cs-danger"> Upon a successful verification, the expiration date of the
                    verification letter will be based on the evidence provided to reviewers, e.g. if a 3rd party letter or a monthly statement is 2 months old at the time of submission for review the verification letter will expire in 1 month.
                  </p>
                </div>
                {props.setShowTrustUploadBlock && <div>
                  <Form.Label className="default-regular-sub-heading-s cs-neutral-90">Please upload evidence of who all the equity owners of the trust are (by uploading your trust documentation or this <Link className="cs-neutral-100" to="https://s3.amazonaws.com/static-capsync.com/Verify-Investor-files/All-Owners-Accredited-Officer-Certificate.pdf" target="_blank"><b>Officer’s Certificate</b></Link> along with a document to show ownership percentages). In a revocable trust, typically, the grantors are the equity owners. In an irrevocable trust, determination of the equity owners can be complicated. Please contact  <Link target="_blank" to="mailto:accreditation@capsync.com">accreditation@capsync.com</Link> for assistance.</Form.Label>
                  <UploadFileBlock
                    files={props.files3}
                    setOpenModal={props.setOpenModal3}
                    mediaAlert={props.mediaAlert}
                    deleteFile={props.deleteFile3}
                    showFile={props.showFile3}
                    pfsLink={props.pfsLink}
                    pfsName={props.pfsName}
                    pfsDelete={props.pfsDelete}
                    showDeleteSpinner={props.showDeleteSpinner}
                    showPfsLink={props.showPfsLink3}
                    uploadError={props.uploadError}

                  />
                </div>}
                <div className="row-spacing">
                  <span className="default-regular-sub-heading-s cs-neutral-90"> Upload the past two years' W-2, K-1, 1099 tax forms or the past two years' tax returns (just the first two pages will do).
                  </span>
                  &nbsp;
                  <span className="default-regular-sub-heading-s cs-neutral-70">
                    If you don't have these forms, you can upload other evidence below.
                  </span>
                </div>

                <div ></div>
                <Form.Check
                  type="checkbox"
                  id="default-checkbox"
                  aria-label="option 1"
                  name="terms"
                  className="cs-form-check-box"
                  feedbackType="invalid"
                  label={
                    <p className="default-regular-sub-heading-s cs-neutral-90">
                      The investor is presenting information showing joint
                      income with a spouse or spousal equivalent.
                    </p>
                  }
                />
                <UploadFileBlock
                  files={props.files}
                  setOpenModal={props.setOpenModal}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile}
                  showFile={props.showFile}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink}
                  uploadError={props.uploadError}
                  showError={props.showError}
                  uploadFileOneError={props.uploadFileOneError}
                  fetchedMedia={props.fetchedMedia}
                  unique_code={props.unique_code}
                />

                <Form.Label className="default-regular-sub-heading-s cs-neutral-90">Upload other evidence:</Form.Label>
                <UploadFileBlock
                  files={props.files2}
                  setOpenModal={props.setOpenModal2}
                  mediaAlert={props.mediaAlert}
                  deleteFile={props.deleteFile2}
                  showFile={props.showFile2}
                  pfsLink={props.pfsLink}
                  pfsName={props.pfsName}
                  pfsDelete={props.pfsDelete}
                  showDeleteSpinner={props.showDeleteSpinner}
                  showPfsLink={props.showPfsLink2}
                  uploadError={props.uploadError}
                />
                <div className="row-spacing">

                  <Form.Check
                    className={props.isCheckedError ? "cs-form-check-box investor-error" : "cs-form-check-box"}
                    type="checkbox"
                    id="default-checkbox-1"
                    aria-label="option 1"
                    name="terms"
                    feedbackType="invalid"
                    checked={props.isChecked}
                    onChange={props.handleCheckbox}
                    label={
                      <span className="default-regular-sub-heading-s cs-neutral-90 ">
                        I (or my spouse/spousal equivalent and I, as applicable)
                        have a reasonable expectation of reaching the income level
                        necessary to qualify as an accredited investor during the
                        current year.
                      </span>
                    }
                  />
                  {props.isCheckedError && <span className="default-regular-sub-heading-xs cs-danger"> The law governing this type of verification requires the investor to have a reasonable expectation of the same income level in the current year.</span>}

                </div>



                <div >
                  <p className="default-regular-body-text-l cs-neutral-90"> Please enter the amount below if your previous year's income evidence is not yet available:
                  </p>
                  <p className="default-regular-body-text-l row-spacing cs-neutral-70">
                    An Internal Revenue Service form that reports my(our) income
                    for the recently completed year is not available. The amount
                    of income I(we) received for the previous year is
                  </p>
                </div>


                <div>
                  <Row>
                    <Col lg={5}>
                      <Form.Group
                        className="cs-form-group"
                        controlId="validationFormik01"
                      >
                        <NumberFormat
                          displayType={"input"}
                          thousandSeparator={true}
                          className={
                            newDollar === true && props.formValue
                              ? "income-input form-control cs-input-field-active"
                              : "form-control cs-neutral-70 default-regular-sub-heading-s"
                          }
                          placeholder="$ 0.00 USD"
                          autoComplete="off"
                          name="Name"
                          onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                          onChange={(e) => props.getNumberValue(e.target.value)}
                          onKeyUp={(e) => check(e.target.value, "dollar")}
                          value={props.formValue}
                          maxLength={8}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <NotesBlock setNotesValue={props.setNotesValue} />
              </Form>

            </div>
          </div>
        </>
      }
    </>
  );
};

export default MethodIdOne;